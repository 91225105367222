import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

import { CustomSvgGradient, InfoCircle, Loader, RechartsCustomizedAxisTick, TableDataNotFound } from 'components';

import { ITreasuryCompositionGraph } from '../../../../../../../../types/interfaces';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CustomTooltip } from './components';
import { useMediaQuery } from '@material-ui/core';
import { AreaChartGradient } from '../FundsGraph/constants';
import { CustomLegend } from './components/CustomLegend';
import { TREASURY_TAB, TREASURY_TAB_DESCRIPTIONS } from '../../../../../../../../constants';

interface ITrendsTableProps {
    organizationTreasuryComposition: ITreasuryCompositionGraph | null;
    loading: boolean;
}

export const TreasuryCompositionGraph = ({
    organizationTreasuryComposition,
    loading,
}: ITrendsTableProps): ReactElement => {
    const isTablet = useMediaQuery('(max-width: 767.98px)');

    const data = organizationTreasuryComposition?.data.map((t) => ({ ...t, month: t.date.slice(0, 7) }));

    return (
        <div className={styles.graphCard}>
            <div className={styles.dataWrapper}>
                <div className={styles.titleWrapper}>
                    <h4>Treasury Composition Percentage</h4>
                    <InfoCircle
                        tooltipText={TREASURY_TAB_DESCRIPTIONS[TREASURY_TAB.COMPOSITION_PERCENTAGE]}
                        className={styles.infoIcon}
                    />
                </div>
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        <ResponsiveContainer height={isTablet ? 270 : 335}>
                            <LineChart data={data}>
                                {CustomSvgGradient({
                                    id: 'AreaChartGradient',
                                    rotate: 90,
                                    data: AreaChartGradient,
                                })}

                                <XAxis
                                    dataKey="month"
                                    tick={<RechartsCustomizedAxisTick offsetY={10} className={styles.axisTick} />}
                                    tickLine={false}
                                    axisLine={false}
                                />

                                <YAxis
                                    yAxisId="left"
                                    tick={
                                        <RechartsCustomizedAxisTick
                                            offsetX={-13}
                                            className={styles.axisTick}
                                            formatValue={true}
                                        />
                                    }
                                    tickLine={false}
                                    width={40}
                                    axisLine={false}
                                />

                                <Tooltip cursor={{ fill: '#DDEEFF' }} content={<CustomTooltip />} />

                                <Legend content={<CustomLegend />} />

                                <Line
                                    yAxisId="left"
                                    type="linear"
                                    dataKey="votingTokenPercentage"
                                    stroke="#337BFF"
                                    strokeWidth={2}
                                    dot={false}
                                />

                                <Line
                                    yAxisId="left"
                                    type="linear"
                                    dataKey="stablecoinsPercentage"
                                    stroke="#32CD32"
                                    strokeWidth={2}
                                    dot={false}
                                />

                                <Line
                                    yAxisId="left"
                                    type="linear"
                                    dataKey="ethBtcPercentage"
                                    stroke="#FFD700"
                                    strokeWidth={2}
                                    dot={false}
                                />

                                <Line
                                    yAxisId="left"
                                    type="linear"
                                    dataKey="otherTokensPercentage"
                                    stroke="#D3D3D3"
                                    strokeWidth={2}
                                    dot={false}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    {!organizationTreasuryComposition?.data.length && !loading && (
                        <TableDataNotFound forGraph removeMargins />
                    )}
                    {loading && <Loader className={styles.loader} />}
                </div>
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import { Button } from '@material-ui/core';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import styles from './styles.module.scss';

import { FormInput, FormTextarea } from 'components';
import { TopicSelect } from './components';

import { useAppDispatch } from 'store';
import { FeedbackActions } from 'store/actions';

export const FeedbackForm: FC = (): ReactElement => {
    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            message: '',
            topic: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            fullName: Yup.string().required('Required'),
            email: Yup.string().email().required('Required'),
            message: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            dispatch(FeedbackActions.sendFeedbackForm.request(values));
            formik.resetForm();
        },
    });

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
            <div className={styles.selectFieldContainer}>
                <TopicSelect setSelectedValue={formik.setFieldValue} value={formik.values.topic} />
            </div>
            <div className={styles.userInfoFields}>
                <FormInput
                    placeholder="Full Name*"
                    name="fullName"
                    value={formik.values.fullName}
                    touched={formik.touched.fullName}
                    error={formik.errors.fullName}
                    onChange={formik.handleChange}
                    onTouch={formik.setFieldTouched}
                />
                <FormInput
                    placeholder="Email*"
                    name="email"
                    value={formik.values.email}
                    touched={formik.touched.email}
                    error={formik.errors.email}
                    onChange={formik.handleChange}
                    onTouch={formik.setFieldTouched}
                />
            </div>
            <FormTextarea
                placeholder="Your Message*"
                name="message"
                value={formik.values.message}
                touched={formik.touched.message}
                error={formik.errors.message}
                onChange={formik.handleChange}
                onTouch={formik.setFieldTouched}
            />
            <div className={styles.buttonsWrapper}>
                <Button className={styles.cancelButton} onClick={() => formik.resetForm()}>
                    cancel
                </Button>
                <Button type="submit" className={styles.submitButton} disabled={!(formik.isValid && formik.dirty)}>
                    submit
                </Button>
            </div>
        </form>
    );
};

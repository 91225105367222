import React from 'react';

import { useMediaQuery } from '@material-ui/core';

import styles from './styles.module.scss';

import { CarouselWrapper, Loader } from 'components';
import { OrganizationTool } from './components';

import { useAppSelector } from 'store';

import { IOrganizationTool } from 'types/interfaces';

export const DAOToolsSection = (): JSX.Element => {
    const { organizationTools, organizationToolsDataLoading } = useAppSelector((store) => store.organization);

    const showСarousel = useMediaQuery('(max-width:1199.98px)');

    return (
        <section className={styles.wrapper}>
            {!showСarousel && (
                <header>
                    <h3 className={styles.header}>DAO Tools</h3>
                </header>
            )}

            <div className={styles.toolsWrapper}>
                {organizationToolsDataLoading && (
                    <div className={styles.loaderBox}>
                        <Loader size={40} className={styles.loader} />
                    </div>
                )}
                {showСarousel ? (
                    <CarouselWrapper
                        data={organizationTools}
                        loading={false}
                        title="Recommended Tools for You"
                        wrapperClassName={styles.carouselWrapper}
                        containerClassName={styles.carouselContainerClassName}
                    >
                        {organizationTools.map(({ id, logo, name, description, mainUrl }: IOrganizationTool) => (
                            <OrganizationTool
                                key={id}
                                logo={logo}
                                name={name}
                                description={description}
                                mainUrl={mainUrl}
                                isTablet={showСarousel}
                            />
                        ))}
                    </CarouselWrapper>
                ) : (
                    organizationTools.map(({ id, logo, name, description, mainUrl }: IOrganizationTool) => (
                        <OrganizationTool
                            key={id}
                            logo={logo}
                            name={name}
                            description={description}
                            mainUrl={mainUrl}
                            isTablet={showСarousel}
                        />
                    ))
                )}
            </div>
        </section>
    );
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { FormatUtils } from 'utils';

import { IAumTooltipProps } from 'types/interfaces';

export const AumTooltip: FC<IAumTooltipProps> = ({ liquidValue, vestingValue }: IAumTooltipProps): ReactElement => {
    const formattedLiquidValue = !FormatUtils.checkIfDataNotExist(liquidValue)
        ? `$${FormatUtils.formatNumberShort(liquidValue as number, 1, true)}`
        : DATA_NOT_FOUND;
    const formattedVestingValue = !FormatUtils.checkIfDataNotExist(vestingValue)
        ? `$${FormatUtils.formatNumberShort(vestingValue as number, 1, true)}`
        : DATA_NOT_FOUND;

    return (
        <div className={styles.tooltipContent}>
            <div>
                <span>liquid</span>
                <span>{formattedLiquidValue}</span>
            </div>
            <div>
                <span>vesting</span>
                <span>{formattedVestingValue}</span>
            </div>
        </div>
    );
};

import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services';
import { getPremiumUserRefreshTokenFromStorage, getPremiumUserTokenFromStorage } from 'web3/storageHelper';

import {
    IApiKey,
    IPaypalSubscribeData,
    ISubscription,
    IBluesnapSubscribeData,
    IPremiumUserData,
    IPremiumUserInvoiceData,
} from 'types/interfaces';

export const PremiumSubscription = {
    getPaypalSubscription: (id: string): Promise<AxiosResponse<ISubscription>> =>
        axiosInstance.get<ISubscription>(`paypal/subscription/${id}`),
    getBluesnapSubscription: (id: string): Promise<AxiosResponse<ISubscription>> =>
        axiosInstance.get<ISubscription>(`bluesnap/subscription/${id}`),
    paypalSubscribe: ({
        plan_id,
        given_name,
        surname,
        email_address,
    }: IPaypalSubscribeData): Promise<AxiosResponse<IPaypalSubscribeData>> =>
        axiosInstance.post<IPaypalSubscribeData>('paypal/subscription', {
            plan_id,
            quantity: 1,
            auto_renewal: true,
            subscriber: {
                name: {
                    given_name,
                    surname,
                },
                email_address,
            },
            application_context: {
                user_action: 'SUBSCRIBE_NOW',
                payment_method: {
                    payer_selected: 'PAYPAL',
                    payee_preferred: 'IMMEDIATE_PAYMENT_REQUIRED',
                },
            },
        }),
    bluesnapSubscribe: ({
        firstName,
        lastName,
        email,
        planId,
        pfToken,
    }: IBluesnapSubscribeData): Promise<AxiosResponse<IBluesnapSubscribeData>> =>
        axiosInstance.post<IBluesnapSubscribeData>('bluesnap/subscription', {
            payerInfo: {
                firstName,
                lastName,
                email,
            },
            paymentSource: {
                pfToken,
            },
            planId,
        }),
    createApiKey: (subscriptionId: string): Promise<AxiosResponse<IApiKey>> =>
        axiosInstance.post<IApiKey>(`client/apikey`, { subscriptionId }),
    getApiKey: (): Promise<AxiosResponse<IApiKey>> =>
        axiosInstance.get<IApiKey>('/client/apikey', {
            headers: { Authorization: `Bearer ${getPremiumUserTokenFromStorage()}` },
        }),
    premiumEmailLogin: (email: string): Promise<AxiosResponse<unknown>> =>
        axiosInstance.post<unknown>(`client/otp/${email}`),
    premiumOTPLogin: (email: string, otp: string): Promise<AxiosResponse<any>> =>
        axiosInstance.get<any>(`client/otp/${email}?otp=${otp}`),
    premiumEmailUpdate: (newEmail: string): Promise<AxiosResponse<any>> =>
        axiosInstance.put<any>(
            `client/email`,
            { newEmail },
            {
                headers: { Authorization: `Bearer ${getPremiumUserTokenFromStorage()}` },
            },
        ),
    getPremiumUserInvoices: (): Promise<AxiosResponse<IPremiumUserInvoiceData[]>> =>
        axiosInstance.get<IPremiumUserInvoiceData[]>(`client/invoices`, {
            headers: { Authorization: `Bearer ${getPremiumUserTokenFromStorage()}` },
        }),
    getPremiumUserToken: (): Promise<AxiosResponse<any>> =>
        axiosInstance.post<any>(`client/refresh`, {
            refreshToken: getPremiumUserRefreshTokenFromStorage(),
        }),
    getPremiumUserData: (): Promise<AxiosResponse<IPremiumUserData>> =>
        axiosInstance.get<IPremiumUserData>(`client/subscription/details`, {
            headers: { Authorization: `Bearer ${getPremiumUserTokenFromStorage()}` },
        }),
    cancelPremiumBluesnapSubscription: (subscriptionId: string, data: any): Promise<AxiosResponse<any>> =>
        axiosInstance.put<any>(`bluesnap/subscription/${subscriptionId}`, data, {
            headers: { Authorization: `Bearer ${getPremiumUserTokenFromStorage()}` },
        }),
    cancelPremiumPaypalSubscription: (subscriptionId: string): Promise<AxiosResponse<any>> =>
        axiosInstance.post<any>(`paypal/subscription/${subscriptionId}/cancel`, {
            headers: { Authorization: `Bearer ${getPremiumUserTokenFromStorage()}` },
        }),
    getPremiumPFToken: (): Promise<AxiosResponse<any>> => axiosInstance.post<any>(`bluesnap/payment-fields-token`),
    premiumCreditCardUpdate: (
        subscriptionId: string,
        pfToken: string,
    ): Promise<AxiosResponse<IBluesnapSubscribeData>> =>
        axiosInstance.put<IBluesnapSubscribeData>(`bluesnap/subscription/${subscriptionId}`, {
            paymentSource: {
                creditCardInfo: {
                    pfToken,
                },
            },
        }),
};

import React, { FC, ReactElement, useEffect } from 'react';

import styles from './styles.module.scss';

import { DAOTreasuryInvestmentsTables, StatsCard } from 'components';

import { DAO_TREASURY_INVESTMENTS_APIS, DAO_TREASURY_INVESTMENTS_TYPES } from 'constants/daoTreasuryInvestments';

import { DaoTreasuryInvestmentsActions } from 'store/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { mixpanelService } from 'services';

export const DAOTreasuryInvestmentsPage: FC = (): ReactElement => {
    const { lastUpdate, enrichedOrganizationsCount, organizationsCount } = useAppSelector(
        ({ organizations }) => organizations,
    );
    const { refreshToken } = useAppSelector(({ auth }) => auth);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            DaoTreasuryInvestmentsActions.getDaoTreasuryInvestments.request({
                fieldName: DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_TYPE,
                daoTreasuryAssetsApi: DAO_TREASURY_INVESTMENTS_APIS.DAO_TRASURY_INVESTMENTS,
            }),
        );
        dispatch(
            DaoTreasuryInvestmentsActions.getDaoTreasuryInvestments.request({
                fieldName: DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_PROTOCOL_AND_TYPE,
                daoTreasuryAssetsApi: DAO_TREASURY_INVESTMENTS_APIS.DAO_TRASURY_INVESTMENTS,
            }),
        );
        dispatch(
            DaoTreasuryInvestmentsActions.getDaoTreasuryInvestments.request({
                fieldName: DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_PROTOCOL_AND_TYPE_BORROWING,
                daoTreasuryAssetsApi: DAO_TREASURY_INVESTMENTS_APIS.DAO_TRASURY_INVESTMENTS,
            }),
        );
        dispatch(
            DaoTreasuryInvestmentsActions.getDaoTreasuryInvestments.request({
                fieldName: DAO_TREASURY_INVESTMENTS_TYPES.INDIVIDUAL_ORGANIZATION_INVESTMENTS,
                daoTreasuryAssetsApi: DAO_TREASURY_INVESTMENTS_APIS.DAO_TREASURY_INVESTMENTS_ASSETS,
            }),
        );
        dispatch(
            DaoTreasuryInvestmentsActions.getDaoTreasuryInvestments.request({
                fieldName: DAO_TREASURY_INVESTMENTS_TYPES.TOP_20_INDIVIDUAL_ORGANIZATION_INVESTMENTS,
                daoTreasuryAssetsApi: DAO_TREASURY_INVESTMENTS_APIS.DAO_TREASURY_TOP_AUM_ORGANIZATIONS,
            }),
        );
    }, [refreshToken]);

    useEffect(() => {
        mixpanelService.track(mixpanelService.eventsGenerator.daoTreasuryInvestmentsPage());
    }, []);

    const updateDaoTreasuryInvestmentsData = ({
        fieldName,
        daoTreasuryAssetsApi,
        queryParams,
    }: {
        queryParams: { [key: string]: string | number };
        fieldName: DAO_TREASURY_INVESTMENTS_TYPES;
        daoTreasuryAssetsApi: DAO_TREASURY_INVESTMENTS_APIS;
    }) => {
        dispatch(
            DaoTreasuryInvestmentsActions.getDaoTreasuryInvestments.request({
                fieldName,
                queryParams,
                daoTreasuryAssetsApi,
            }),
        );
    };

    useEffect(
        () => () => {
            dispatch({ type: DaoTreasuryInvestmentsActions.CLEAR_DAO_TREASURY_INVESTMENTS_DATA });
        },
        [dispatch],
    );

    return (
        <>
            <StatsCard
                lastUpdateDate={lastUpdate}
                organizationsCount={organizationsCount}
                enrichedOrganizationsCount={enrichedOrganizationsCount}
                title="DAOs & DeFi"
                isPeoplePage
                className={styles.statsCardWrapper}
                externalLink
                mobileBanner
            />

            <div className={styles.pageContentWrapper}>
                <DAOTreasuryInvestmentsTables updateDaoTreasuryInvestmentsData={updateDaoTreasuryInvestmentsData} />
            </div>
        </>
    );
};

import { Reducer } from '@reduxjs/toolkit';

import { SocialFeaturesActions } from 'store/actions';
import type {
    ISocialFeaturesActionType,
    IFollowPeopleData,
    IFollowOrganizationData,
    ISocialFeaturesState,
    IFollowingAmountData,
} from 'types/interfaces';

const defaultState = {
    loading: true,
    followingOrganizationsData: [],
    followingPeopleData: [],
    followedPeopleData: [],
    followingAmount: 0,
    followersAmount: 0,
    followingPeopleAmount: 0,
    followingOrganizationsAmount: 0,
    followingPeopleLoading: false,
    followingOrganizationsLoading: false,
};

export const socialFeaturesReducer: Reducer<ISocialFeaturesState, ISocialFeaturesActionType> = (
    state: ISocialFeaturesState = defaultState,
    action: ISocialFeaturesActionType,
): ISocialFeaturesState => {
    const { type, payload } = <ISocialFeaturesActionType>action;
    switch (type) {
        case SocialFeaturesActions.GET_FOLLOWED_PEOPLE: {
            return {
                ...state,
                loading: true,
            };
        }

        case SocialFeaturesActions.GET_FOLLOWED_PEOPLE_SUCCESS: {
            return {
                ...state,
                followedPeopleData: payload as IFollowPeopleData[],
                loading: false,
            };
        }

        case SocialFeaturesActions.GET_FOLLOWED_PEOPLE_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case SocialFeaturesActions.GET_FOLLOWING_ORGANIZATIONS: {
            return {
                ...state,
                followingOrganizationsLoading: true,
            };
        }

        case SocialFeaturesActions.GET_FOLLOWING_ORGANIZATIONS_SUCCESS: {
            const followingOrganizationsData = (payload as IFollowOrganizationData[]).map((org) => ({
                ...org,
                isFollowedBack: true,
            }));
            return {
                ...state,
                followingOrganizationsData,
                followingOrganizationsLoading: false,
            };
        }

        case SocialFeaturesActions.GET_FOLLOWING_ORGANIZATIONS_FAILURE: {
            return {
                ...state,
                followingOrganizationsLoading: false,
            };
        }

        case SocialFeaturesActions.GET_FOLLOWING_DATA: {
            return {
                ...state,
                loading: true,
            };
        }

        case SocialFeaturesActions.GET_FOLLOWING_DATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...(payload as IFollowingAmountData),
            };
        }

        case SocialFeaturesActions.GET_FOLLOWING_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case SocialFeaturesActions.GET_FOLLOWING_PEOPLE: {
            return {
                ...state,
                followingPeopleLoading: true,
            };
        }

        case SocialFeaturesActions.GET_FOLLOWING_PEOPLE_SUCCESS: {
            const followingPeopleData = (payload as IFollowPeopleData[]).map((person) => ({
                ...person,
                isFollowedBack: true,
            }));
            return {
                ...state,
                followingPeopleData,
                followingPeopleLoading: false,
            };
        }

        case SocialFeaturesActions.GET_FOLLOWING_PEOPLE_FAILURE: {
            return {
                ...state,
                followingPeopleLoading: false,
            };
        }

        case SocialFeaturesActions.UNFOLLOW_ORGANIZATION:
        case SocialFeaturesActions.FOLLOW_ORGANIZATION: {
            return {
                ...state,
                loading: true,
                followingPeopleLoading: true,
                followingOrganizationsLoading: true,
            };
        }

        case SocialFeaturesActions.UNFOLLOW_ORGANIZATION_SUCCESS:
        case SocialFeaturesActions.FOLLOW_ORGANIZATION_SUCCESS: {
            const index = state.followingOrganizationsData.findIndex((org) => org.organizationId === payload);
            const updatedFollowingData = [...state.followingOrganizationsData];
            updatedFollowingData[index].isFollowedBack = !updatedFollowingData[index].isFollowedBack;

            return {
                ...state,
                loading: false,
                followingOrganizationsData: updatedFollowingData,
                followingPeopleLoading: false,
                followingOrganizationsLoading: false,
            };
        }

        case SocialFeaturesActions.UNFOLLOW_ORGANIZATION_FAILURE:
        case SocialFeaturesActions.FOLLOW_ORGANIZATION_FAILURE: {
            return {
                ...state,
                loading: false,
                followingPeopleLoading: false,
                followingOrganizationsLoading: false,
            };
        }

        case SocialFeaturesActions.UNFOLLOW_PERSON:
        case SocialFeaturesActions.FOLLOW_PERSON: {
            return {
                ...state,
                loading: true,
                followingPeopleLoading: true,
                followingOrganizationsLoading: true,
            };
        }

        case SocialFeaturesActions.UNFOLLOW_PERSON_SUCCESS:
        case SocialFeaturesActions.FOLLOW_PERSON_SUCCESS:
            const index = state.followingPeopleData.findIndex((person) => person.memberAddress === payload);
            const updatedFollowingData = [...state.followingPeopleData];
            updatedFollowingData[index].isFollowedBack = !updatedFollowingData[index].isFollowedBack;
            return {
                ...state,
                loading: false,
                followingPeopleData: updatedFollowingData,
                followingPeopleLoading: false,
                followingOrganizationsLoading: false,
            };

        case SocialFeaturesActions.UNFOLLOW_PERSON_FAILURE:
        case SocialFeaturesActions.FOLLOW_PERSON_FAILURE: {
            return {
                ...state,
                loading: false,
                followingPeopleLoading: false,
                followingOrganizationsLoading: false,
            };
        }

        case SocialFeaturesActions.TOGGLE_PERSON_NOTIFICATION: {
            return {
                ...state,
                loading: true,
                followingPeopleLoading: true,
                followingOrganizationsLoading: true,
            };
        }

        case SocialFeaturesActions.TOGGLE_PERSON_NOTIFICATION_FAILURE: {
            return {
                ...state,
                loading: false,
                followingPeopleLoading: false,
                followingOrganizationsLoading: false,
            };
        }

        case SocialFeaturesActions.TOGGLE_PERSON_NOTIFICATION_SUCCESS: {
            const index = state.followingPeopleData.findIndex((person) => person.memberAddress === payload);
            const updatedFollowingData = [...state.followingPeopleData];
            updatedFollowingData[index].enabledNotification = !updatedFollowingData[index].enabledNotification;
            return {
                ...state,
                loading: false,
                followingPeopleData: updatedFollowingData,
                followingPeopleLoading: false,
                followingOrganizationsLoading: false,
            };
        }

        case SocialFeaturesActions.TOGGLE_ORGANIZATION_NOTIFICATION: {
            return {
                ...state,
                loading: true,
                followingPeopleLoading: true,
                followingOrganizationsLoading: true,
            };
        }

        case SocialFeaturesActions.TOGGLE_ORGANIZATION_NOTIFICATION_FAILURE: {
            return {
                ...state,
                loading: false,
                followingPeopleLoading: false,
                followingOrganizationsLoading: false,
            };
        }

        case SocialFeaturesActions.TOGGLE_ORGANIZATION_NOTIFICATION_SUCCESS: {
            const index = state.followingOrganizationsData.findIndex((org) => org.organizationId === payload);
            const updatedFollowingData = [...state.followingOrganizationsData];
            updatedFollowingData[index].enabledNotification = !updatedFollowingData[index].enabledNotification;
            return {
                ...state,
                loading: false,
                followingOrganizationsData: updatedFollowingData,
                followingPeopleLoading: false,
                followingOrganizationsLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

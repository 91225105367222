import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TooltipWrapper, ImageLoader } from 'components';

import { FormatUtils } from 'utils';
import { defaultTokenPlaceholderImage } from 'assets';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';
import { IMAGE_TYPES } from 'constants/imageTypes';

import { IOrganizationAssetsTable } from 'types/interfaces';

export const AssetRowItems: FC<IOrganizationAssetsTable> = ({
    rank,
    protocolName,
    protocolType,
    protocolChainTitle,
    assetLogo,
    totalValue,
    protocolValuePercentage,
}: IOrganizationAssetsTable): ReactElement => {
    const currencyValue = FormatUtils.checkIfDataNotExist(totalValue)
        ? DATA_NOT_FOUND
        : `$${FormatUtils.formatNumberWithCommas(Number(totalValue), 1)}`;
    const currencyPercentageValue = FormatUtils.checkIfDataNotExist(protocolValuePercentage)
        ? DATA_NOT_FOUND
        : (protocolValuePercentage as number).toFixed(1);

    return (
        <div className={styles.tableRow}>
            <div className={styles.rank}>
                <span>{rank}</span>
            </div>
            <div className={styles.protocol}>
                <ImageLoader
                    src={assetLogo || defaultTokenPlaceholderImage}
                    className={styles.avatar}
                    imageType={IMAGE_TYPES.TOKEN_IMAGE}
                />
                <div className={styles.protocolInfo}>
                    <TooltipWrapper title={protocolName}>
                        <h5 className={styles.protocolName}>{protocolName}</h5>
                    </TooltipWrapper>
                    <span className={styles.chain}>{protocolChainTitle}</span>
                </div>
            </div>
            <div className={styles.type}>
                <h5 className={styles.protocolType}>{protocolType}</h5>
            </div>
            <div className={styles.protocolValue}>
                {!FormatUtils.checkIfDataNotExist(0) ? (
                    <>
                        <TooltipWrapper title={`${currencyValue}`}>
                            <div className={styles.value}>
                                <div>
                                    <span>{currencyValue}</span>
                                </div>
                            </div>
                        </TooltipWrapper>

                        <span className={styles.percentage}>{currencyPercentageValue} %</span>
                    </>
                ) : (
                    <span>{DATA_NOT_FOUND}</span>
                )}
            </div>
        </div>
    );
};

import React, { FC, ReactElement, useCallback, useMemo } from 'react';

import { Link } from 'react-router-dom';

import moment from 'moment';

import { useMediaQuery } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';

import styles from './styles.module.scss';

import { ImageLoader, TooltipWrapper, DiscussionElementOptions } from 'components';
import {
    CommentsSection,
    DescriptionSection,
    DiscussionTimeframe,
    TokensStats,
    VotingResult,
    VotingSection,
    SubstormsSection,
} from './components';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { FormatUtils, LinkUtils } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { OrganizationActions } from 'store/actions';

import { IDiscussionCardItemsProps } from 'types/interfaces';

export const DiscussionCardItems: FC<IDiscussionCardItemsProps> = ({
    id,
    title,
    text,
    userLogo,
    createdAt,
    creator,
    countOfComments,
    comments,
    voted,
    loggedUserAddress,
    isAuthenticatedUser,
    endDate,
    isDiscussionSingle = false,
    userName,
    idxName,
    ensName,
    idxImageUrl,
    ensImageUrl,
    votingAgainst = 0,
    votingFor = 0,
    organizationId,
    tokensFor = 0,
    tokensAgainst = 0,
    substorms = [],
    tokenVoters,
}: IDiscussionCardItemsProps): ReactElement => {
    const { userOrganizationRole, allowInteractionWithDiscussions } = useAppSelector(
        ({ organization }) => organization,
    );

    const { loggedUserData } = useAppSelector(({ auth }) => auth);

    const dispatch = useAppDispatch();

    const commentsCount = useMemo(() => Number(comments?.length), [countOfComments]);

    const createdAtDate = useMemo(() => FormatUtils.getFormattedLastUpdateDate(createdAt), [createdAt]);

    const creatorLink = useMemo(() => LinkUtils.createDynamicLink(creator, 'user'), [creator]);

    const formattedAddress = useMemo(() => FormatUtils.formatUserAddress(creator), [creator]);

    const isUserAdminOrEditor = useMemo(
        () => Boolean(userOrganizationRole === 'editor' || userOrganizationRole === 'admin'),
        [userOrganizationRole],
    );

    const isMobile = useMediaQuery('(max-width:575.98px)');

    const { timeLeftValue, isDiscussionFinished, finishedDiscussionDate, isDiscussionWithTimeframe } = useMemo(() => {
        const now = moment().format('YYYY-MM-DDTHH:mm:ss');
        const timeframe = moment(endDate).utc().format('YYYY-MM-DDTHH:mm:ss');
        const diff = moment.duration(moment(timeframe).diff(moment(now)));
        const days = diff.days() >= 10 ? diff.days() : '0' + diff.days();
        const hours = diff.hours() >= 10 ? diff.hours() : '0' + diff.hours();
        const minutes = diff.minutes() >= 10 ? diff.minutes() : '0' + diff.minutes();

        return {
            timeLeftValue: { days, hrs: hours, min: minutes },
            isDiscussionFinished: diff.asMilliseconds() < 0 ? true : false,
            finishedDiscussionDate: moment(endDate).format('MM.DD.YYYY, h:mm'),
            isDiscussionWithTimeframe: Boolean(endDate),
        };
    }, [endDate]);

    const onBrainstormBanClick = useCallback(
        (forDiscussion, type, data?) => {
            dispatch(
                OrganizationActions.banDiscussionElement.request({
                    organizationId,
                    discussionId: id,
                    dataForBan: {
                        banned: true,
                        isDiscussion: forDiscussion,
                    },
                    type,
                    ...data,
                }),
            );
        },
        [organizationId, id],
    );

    const isCommentingAvaible = useMemo(
        () => Boolean(isAuthenticatedUser && allowInteractionWithDiscussions),
        [isAuthenticatedUser, allowInteractionWithDiscussions],
    );

    return (
        <div className={styles.card}>
            <div className={styles.cardHeader}>
                <div className={styles.userInfo}>
                    <Link to={creatorLink} className={styles.imageLinkWrapper}>
                        <ImageLoader
                            src={userLogo || idxImageUrl || ensImageUrl}
                            className={styles.avatar}
                            imageType={IMAGE_TYPES.USER_IMAGE}
                        />
                    </Link>

                    <div className={styles.userDetails}>
                        {substorms.length > 0 && <SubstormsSection substorms={substorms} />}

                        <div className={styles.options}>
                            {!isDiscussionSingle && (
                                <Link to={`/brainstorm/${organizationId}/${id}`} className={styles.brainstormLink}>
                                    <LinkIcon className={styles.linkIcon} />
                                </Link>
                            )}

                            <p className={styles.date}>{createdAtDate}</p>
                        </div>

                        <TooltipWrapper title={creator}>
                            <Link to={creatorLink} className={styles.user}>
                                {userName || idxName || ensName || formattedAddress}
                            </Link>
                        </TooltipWrapper>
                    </div>
                </div>

                <div className={styles.info}>
                    {voted && !isMobile && isDiscussionFinished && <VotingResult voted={voted} />}

                    {isDiscussionWithTimeframe && (
                        <DiscussionTimeframe
                            isDiscussionFinished={isDiscussionFinished}
                            timeLeftValue={timeLeftValue}
                            finishedDiscussionDate={finishedDiscussionDate}
                        />
                    )}

                    {isAuthenticatedUser &&
                        !isDiscussionSingle &&
                        (isUserAdminOrEditor || creator === loggedUserData?.address) && (
                            <DiscussionElementOptions
                                discussionId={id}
                                onRemove={() => onBrainstormBanClick(true, 'discussion')}
                                className={!isDiscussionWithTimeframe && styles.elementBlocker}
                                allowEditing={Boolean(votingAgainst === 0 && votingFor === 0)}
                            />
                        )}
                </div>
            </div>

            <div className={styles.cardBody}>
                <div className={styles.textInfo}>
                    <h4 className={styles.title}>{title}</h4>

                    <DescriptionSection description={text} />
                </div>
                <div className={styles.mainInfo}>
                    <div className={styles.tokensInfo}>
                        <TokensStats
                            title="by members"
                            votedFor={votingFor || 0}
                            votedAgainst={votingAgainst || 0}
                            tokenVoters={tokenVoters}
                        />

                        <TokensStats
                            title="by tokens"
                            votedFor={tokensFor || 0}
                            votedAgainst={tokensAgainst || 0}
                            tokenVoters={tokenVoters}
                        />
                    </div>
                    {!isMobile && (
                        <>
                            {commentsCount > 0 && (
                                <div className={styles.commentsInfo}>
                                    <span className={styles.commentsCount}>
                                        {commentsCount === 1 ? `${commentsCount} comment` : `${commentsCount} comments`}
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                </div>

                {(!isDiscussionFinished || isMobile) && (
                    <VotingSection
                        loggedUserAddress={loggedUserAddress}
                        discussionId={id}
                        organizationId={organizationId}
                        voted={voted}
                        isAuthenticatedUser={isAuthenticatedUser}
                        isDiscussionSingle={isDiscussionSingle}
                        comments={comments}
                        isMobile={isMobile}
                        isDiscussionFinished={isDiscussionFinished}
                    />
                )}

                <CommentsSection
                    comments={comments}
                    loggedUserAddress={loggedUserAddress}
                    discussionId={id}
                    isCommentingAvaible={isCommentingAvaible}
                    commentsCount={commentsCount}
                    onBrainstormBanClick={onBrainstormBanClick}
                    isDiscussionSingle={isDiscussionSingle}
                    isUserAdminOrEditor={isUserAdminOrEditor || creator === loggedUserData?.address}
                    isAuthenticatedUser={isAuthenticatedUser}
                    tokenVoters={tokenVoters}
                    organizationId={organizationId}
                />
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { IScoreStatsProps } from 'types/interfaces';

export const ScoreStats: FC<IScoreStatsProps> = ({
    title,
    mainCounter,
    firstDate,
    latestDate,
    showDates = true,
}: IScoreStatsProps): ReactElement => (
    <div className={styles.scoreStatsContainer}>
        <div className={styles.mainInfo}>
            <h4 className={styles.counter}>{mainCounter}</h4>
            <p className={styles.description}>{title}</p>
        </div>
        {showDates && (
            <div className={styles.datesContainer}>
                <div className={styles.dateInfo}>
                    <h6 className={styles.period}>first:</h6>
                    <p className={styles.date}>{firstDate}</p>
                </div>
                <div className={styles.dateInfo}>
                    <h6 className={styles.period}>latest:</h6>
                    <p className={styles.date}>{latestDate}</p>
                </div>
            </div>
        )}
    </div>
);

import { createApiAction } from 'helpers';

// general
export const GET_ORGANIZATION_TABS = 'GET_ORGANIZATION_TABS';
export const GET_ORGANIZATION_TABS_SUCCESS = 'GET_ORGANIZATION_TABS_SUCCESS';
export const GET_ORGANIZATION_TABS_FAILURE = 'GET_ORGANIZATION_TABS_FAILURE';

export const getOrganizationTabs = createApiAction(GET_ORGANIZATION_TABS);

// delegation insights tab
export const GET_ORGANIZATION_ACTIVE_DELEGATES = 'GET_ORGANIZATION_ACTIVE_DELEGATES';
export const GET_ORGANIZATION_ACTIVE_DELEGATES_SUCCESS = 'GET_ORGANIZATION_ACTIVE_DELEGATES_SUCCESS';
export const GET_ORGANIZATION_ACTIVE_DELEGATES_FAILURE = 'GET_ORGANIZATION_ACTIVE_DELEGATES_FAILURE';

export const GET_ORGANIZATION_TOP_DELEGATES = 'GET_ORGANIZATION_TOP_DELEGATES';
export const GET_ORGANIZATION_TOP_DELEGATES_SUCCESS = 'GET_ORGANIZATION_TOP_DELEGATES_SUCCESS';
export const GET_ORGANIZATION_TOP_DELEGATES_FAILURE = 'GET_ORGANIZATION_TOP_DELEGATES_FAILURE';

export const getOrganizationActiveDelegates = createApiAction(GET_ORGANIZATION_ACTIVE_DELEGATES);
export const getOrganizationTopDelegates = createApiAction(GET_ORGANIZATION_TOP_DELEGATES);

// voter decentralization tab
export const GET_ORGANIZATION_ACTIVE_VOTERS = 'GET_ORGANIZATION_ACTIVE_VOTERS';
export const GET_ORGANIZATION_ACTIVE_VOTERS_SUCCESS = 'GET_ORGANIZATION_ACTIVE_VOTERS_SUCCESS';
export const GET_ORGANIZATION_ACTIVE_VOTERS_FAILURE = 'GET_ORGANIZATION_ACTIVE_VOTERS_FAILURE';

export const GET_ORGANIZATION_TOP_VOTERS = 'GET_ORGANIZATION_TOP_VOTERS';
export const GET_ORGANIZATION_TOP_VOTERS_SUCCESS = 'GET_ORGANIZATION_TOP_VOTERS_SUCCESS';
export const GET_ORGANIZATION_TOP_VOTERS_FAILURE = 'GET_ORGANIZATION_TOP_VOTERS_FAILURE';

export const getOrganizationActiveVoters = createApiAction(GET_ORGANIZATION_ACTIVE_VOTERS);
export const getOrganizationTopVoters = createApiAction(GET_ORGANIZATION_TOP_VOTERS);

// voters trends
export const GET_ORGANIZATION_PROPOSALS_TRENDS = 'GET_ORGANIZATION_PROPOSALS_TRENDS';
export const GET_ORGANIZATION_PROPOSALS_TRENDS_SUCCESS = 'GET_ORGANIZATION_PROPOSALS_TRENDS_SUCCESS';
export const GET_ORGANIZATION_PROPOSALS_TRENDS_FAILURE = 'GET_ORGANIZATION_PROPOSALS_TRENDS_FAILURE';

export const GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS = 'GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS';
export const GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS_SUCCESS =
    'GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS_SUCCESS';
export const GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS_FAILURE =
    'GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS_FAILURE';

export const GET_ORGANIZATION_VOTES_TRENDS = 'GET_ORGANIZATION_VOTES_TRENDS';
export const GET_ORGANIZATION_VOTES_TRENDS_SUCCESS = 'GET_ORGANIZATION_VOTES_TRENDS_SUCCESS';
export const GET_ORGANIZATION_VOTES_TRENDS_FAILURE = 'GET_ORGANIZATION_VOTES_TRENDS_FAILURE';

export const GET_ORGANIZATION_VOTERS_TRENDS = 'GET_ORGANIZATION_VOTERS_TRENDS';
export const GET_ORGANIZATION_VOTERS_TRENDS_SUCCESS = 'GET_ORGANIZATION_VOTERS_TRENDS_SUCCESS';
export const GET_ORGANIZATION_VOTERS_TRENDS_FAILURE = 'GET_ORGANIZATION_VOTERS_TRENDS_FAILURE';

export const GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS = 'GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS';
export const GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS_SUCCESS =
    'GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS_SUCCESS';
export const GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS_FAILURE =
    'GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS_FAILURE';

export const GET_ORGANIZATION_YEAR_BY_YEAR_VOTES = 'GET_ORGANIZATION_YEAR_BY_YEAR_VOTES';
export const GET_ORGANIZATION_YEAR_BY_YEAR_VOTES_SUCCESS = 'GET_ORGANIZATION_YEAR_BY_YEAR_VOTES_SUCCESS';
export const GET_ORGANIZATION_YEAR_BY_YEAR_VOTES_FAILURE = 'GET_ORGANIZATION_YEAR_BY_YEAR_VOTES_FAILURE';

// voter interests
export const GET_ORGANIZATION_VOTER_INTERESTS = 'GET_ORGANIZATION_VOTER_INTERESTS';
export const GET_ORGANIZATION_VOTER_INTERESTS_SUCCESS = 'GET_ORGANIZATION_VOTER_INTERESTS_SUCCESS';
export const GET_ORGANIZATION_VOTER_INTERESTS_FAILURE = 'GET_ORGANIZATION_VOTER_INTERESTS_FAILURE';

export const GET_ORGANIZATION_VOTER_GROUPS = 'GET_ORGANIZATION_VOTER_GROUPS';
export const GET_ORGANIZATION_VOTER_GROUPS_SUCCESS = 'GET_ORGANIZATION_VOTER_GROUPS_SUCCESS';
export const GET_ORGANIZATION_VOTER_GROUPS_FAILURE = 'GET_ORGANIZATION_VOTER_GROUPS_FAILURE';

// finance tab
export const GET_ORGANIZATION_TREASURY_TRENDS = 'GET_ORGANIZATION_TREASURY_TRENDS';
export const GET_ORGANIZATION_TREASURY_TRENDS_SUCCESS = 'GET_ORGANIZATION_TREASURY_TRENDS_SUCCESS';
export const GET_ORGANIZATION_TREASURY_TRENDS_FAILURE = 'GET_ORGANIZATION_TREASURY_TRENDS_FAILURE';

export const GET_ORGANIZATION_TREASURY_COMPOSITION = 'GET_ORGANIZATION_TREASURY_COMPOSITION';
export const GET_ORGANIZATION_TREASURY_COMPOSITION_SUCCESS = 'GET_ORGANIZATION_TREASURY_COMPOSITION_SUCCESS';
export const GET_ORGANIZATION_TREASURY_COMPOSITION_FAILURE = 'GET_ORGANIZATION_TREASURY_COMPOSITION_FAILURE';

export const GET_ORGANIZATION_TREASURY_COMPOSITION_GRAPH = 'GET_ORGANIZATION_TREASURY_COMPOSITION_GRAPH';
export const GET_ORGANIZATION_TREASURY_COMPOSITION_GRAPH_SUCCESS = 'GET_ORGANIZATION_TREASURY_COMPOSITION_GRAPH_SUCCESS';
export const GET_ORGANIZATION_TREASURY_COMPOSITION_GRAPH_FAILURE = 'GET_ORGANIZATION_TREASURY_COMPOSITION_GRAPH_FAILURE';

// token tab
export const GET_ORGANIZATION_VOTING_TOKEN = 'GET_ORGANIZATION_VOTING_TOKEN';
export const GET_ORGANIZATION_VOTING_TOKEN_SUCCESS = 'GET_ORGANIZATION_VOTING_TOKEN_SUCCESS';
export const GET_ORGANIZATION_VOTING_TOKEN_FAILURE = 'GET_ORGANIZATION_VOTING_TOKEN_FAILURE';

export const GET_ORGANIZATION_AVG_VOTES_AND_TOKEN_PRICE = 'GET_ORGANIZATION_AVG_VOTES_AND_TOKEN_PRICE';
export const GET_ORGANIZATION_AVG_VOTES_AND_TOKEN_PRICE_SUCCESS = 'GET_ORGANIZATION_AVG_VOTES_AND_TOKEN_PRICE_SUCCESS';
export const GET_ORGANIZATION_AVG_VOTES_AND_TOKEN_PRICE_FAILURE = 'GET_ORGANIZATION_AVG_VOTES_AND_TOKEN_PRICE_FAILURE';

export const GET_ORGANIZATION_HOLDING_TOKEN = 'GET_ORGANIZATION_HOLDING_TOKEN';
export const GET_ORGANIZATION_HOLDING_TOKEN_SUCCESS = 'GET_ORGANIZATION_HOLDING_TOKEN_SUCCESS';
export const GET_ORGANIZATION_HOLDING_TOKEN_FAILURE = 'GET_ORGANIZATION_HOLDING_TOKEN_FAILURE';

export const getOrganizationProposalsTrends = createApiAction(GET_ORGANIZATION_PROPOSALS_TRENDS);
export const getOrganizationSuccessfulProposalsTrends = createApiAction(GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS);
export const getOrganizationVotesTrends = createApiAction(GET_ORGANIZATION_VOTES_TRENDS);
export const getOrganizationVotersTrends = createApiAction(GET_ORGANIZATION_VOTERS_TRENDS);
export const getOrganizationAverageVotesPerProposalTrends = createApiAction(GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS,);
export const getOrganizationYearByYearVotes = createApiAction(GET_ORGANIZATION_YEAR_BY_YEAR_VOTES);
export const getOrganizationVoterInterests = createApiAction(GET_ORGANIZATION_VOTER_INTERESTS);
export const getOrganizationTreasuryTrends = createApiAction(GET_ORGANIZATION_TREASURY_TRENDS);
export const getOrganizationVotingToken = createApiAction(GET_ORGANIZATION_VOTING_TOKEN);
export const getOrganizationAvgVotesAndTokenPriceGraph = createApiAction(GET_ORGANIZATION_AVG_VOTES_AND_TOKEN_PRICE);
export const getOrganizationTreasuryComposition = createApiAction(GET_ORGANIZATION_TREASURY_COMPOSITION);
export const getOrganizationTreasuryCompositionGraph = createApiAction(GET_ORGANIZATION_TREASURY_COMPOSITION_GRAPH);
export const getOrganizationHoldingToken = createApiAction(GET_ORGANIZATION_HOLDING_TOKEN);
export const getOrganizationVoterGroups = createApiAction(GET_ORGANIZATION_VOTER_GROUPS);

import { IFollowOrganizationData } from 'types/interfaces';

export interface IOrganizationTableProps {
    loading: boolean;
    data: IFollowOrganizationData[];
}

export interface IOrganizationTableRowProps {
    item: IFollowOrganizationData;
}

export enum ORGANIZATION_PARAMS {
    ORGANIZATION = 'name',
    TREASURY = 'treasury',
    ACTIVE_MEMBERS = 'activeMembers',
}

import createSagaMiddleware from 'redux-saga';

import { createStore, compose, applyMiddleware } from '@reduxjs/toolkit';

import { rootReducer } from './reducers';
import { rootSaga } from './sagas';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export { store };

import React, { FC, ReactElement, useEffect, useMemo } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { isEmpty } from 'lodash';

import styles from './styles.module.scss';

import { Loader, PremiumLoginForm } from 'components';

import { useAppDispatch, useAppSelector } from 'store';
import { PremiumSubscriptionActions } from 'store/actions';
import { useHistoryPush } from 'hooks';

export const PremiumEmailUpdatePage: FC = (): ReactElement => {
    const dispatch = useAppDispatch();
    const { premiumLoginError, premiumLoginLoading, premiumUserData, premiumUserDataLoading } = useAppSelector(
        ({ premiumSubscription }) => premiumSubscription,
    );

    const { navigateToAccountSettings } = useHistoryPush();

    useEffect(() => {
        if (isEmpty(premiumUserData)) dispatch(PremiumSubscriptionActions.getPremiumUserData.request());
    }, []);

    const formik = useFormik({
        initialValues: {
            newEmail: '',
            repeatedNewEmail: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            newEmail: Yup.string().email().required('Required'),
            repeatedNewEmail: Yup.string()
                .email()
                .required('Required')
                .when('newEmail', (newEmail, schema) =>
                    schema.test({
                        test: (repeatedNewEmail: string) => repeatedNewEmail === newEmail,
                        message: 'Repeated email does not match',
                    }),
                ),
        }),
        onSubmit: (values) => {
            dispatch(
                PremiumSubscriptionActions.premiumEmailUpdate.request({
                    newEmail: values.newEmail,
                    navigate: navigateToAccountSettings,
                }),
            );
        },
    });

    const formValues = useMemo(
        () => [
            {
                id: 1,
                name: 'newEmail',
                placeholder: 'New email',
                value: formik.values.newEmail,
                onChange: formik.handleChange,
                touched: formik.touched?.newEmail,
                error: formik.errors?.newEmail,
                onTouch: formik.setFieldTouched,
            },
            {
                id: 2,
                name: 'repeatedNewEmail',
                placeholder: 'Repeat new email',
                value: formik.values.repeatedNewEmail,
                onChange: formik.handleChange,
                touched: formik.touched?.repeatedNewEmail,
                error: formik.errors?.repeatedNewEmail,
                onTouch: formik.setFieldTouched,
            },
        ],
        [formik],
    );

    return (
        <>
            {premiumUserDataLoading ? (
                <Loader />
            ) : (
                <PremiumLoginForm
                    title="Update your email"
                    description={
                        <p>Your current email: {<span className={styles.boldText}>{premiumUserData.email}</span>}</p>
                    }
                    submitButtonText="Submit"
                    formValues={formValues}
                    onSubmit={formik.handleSubmit}
                    error={premiumLoginError}
                    loading={premiumLoginLoading}
                    disabled={!(formik.isValid && formik.dirty)}
                    isNavigationBackAvailable
                />
            )}
        </>
    );
};

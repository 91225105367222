import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { IContactFormWrapperProps } from 'types/interfaces';

export const ContactFormWrapper: FC<IContactFormWrapperProps> = ({
    title,
    children,
    description,
}: IContactFormWrapperProps): ReactElement => (
    <div className={styles.wrapper}>
        <div className={styles.blurBox} />

        <div className={styles.container}>
            <div className={styles.textInfo}>
                <h2 className={styles.title}>{title}</h2>
                {description && <h5 className={styles.description}>{description}</h5>}
            </div>

            {children}
        </div>
    </div>
);

import { createApiAction } from 'helpers';

export const SEARCH_USER_ADDRESS = 'SEARCH_USER_ADDRESS';
export const SEARCH_USER_ADDRESS_SUCCESS = 'SEARCH_USER_ADDRESS_SUCCESS';
export const SEARCH_USER_ADDRESS_FAILURE = 'SEARCH_USER_ADDRESS_FAILURE';

export const SEARCH = 'SEARCH';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';

export const SEARCH_TOP_THREE = 'SEARCH_TOP_THREE';
export const SEARCH_TOP_THREE_SUCCESS = 'SEARCH_TOP_THREE_SUCCESS';
export const SEARCH_TOP_THREE_FAILURE = 'SEARCH_TOP_THREE_FAILURE';

export const CLEAR_USER_ADDRESS = 'CLEAR_USER_ADDRESS';

export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const search = createApiAction(SEARCH);
export const searchUserAddress = createApiAction(SEARCH_USER_ADDRESS);
export const searchTopThree = createApiAction(SEARCH_TOP_THREE);

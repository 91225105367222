import { createApiAction } from 'helpers';

export const FETCH_PAYPAL_PLANS = 'FETCH_PAYPAL_PLANS';
export const FETCH_PAYPAL_PLANS_SUCCESS = 'FETCH_PAYPAL_PLANS_SUCCESS';
export const FETCH_PAYPAL_PLANS_FAILURE = 'FETCH_PAYPAL_PLANS_FAILURE';

export const GET_PAYPAL_PLAN = 'GET_PAYPAL_PLAN';
export const GET_PAYPAL_PLAN_SUCCESS = 'GET_PAYPAL_PLAN_SUCCESS';
export const GET_PAYPAL_PLAN_FAILURE = 'GET_PAYPAL_PLAN_FAILURE';

export const FETCH_BLUESNAP_PLANS = 'FETCH_BLUESNAP_PLANS';
export const FETCH_BLUESNAP_PLANS_SUCCESS = 'FETCH_BLUESNAP_PLANS_SUCCESS';
export const FETCH_BLUESNAP_PLANS_FAILURE = 'FETCH_BLUESNAP_PLANS_FAILURE';

export const GET_BLUESNAP_PLAN = 'GET_BLUESNAP_PLAN';
export const GET_BLUESNAP_PLAN_SUCCESS = 'GET_BLUESNAP_PLAN_SUCCESS';
export const GET_BLUESNAP_PLAN_FAILURE = 'GET_BLUESNAP_PLAN_FAILURE';

export const fetchPaypalPremiumPlans = createApiAction(FETCH_PAYPAL_PLANS);
export const getPaypalPlan = createApiAction(GET_PAYPAL_PLAN);
export const fetchBluesnapPremiumPlans = createApiAction(FETCH_BLUESNAP_PLANS);
export const getBluesnapPlan = createApiAction(GET_BLUESNAP_PLAN);

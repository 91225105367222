import React, { FC, ReactElement, memo } from 'react';

import { Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

import styles from './styles.module.scss';

import { ISelectedFilterElementProps } from 'types/interfaces';

export const DesktopFilterSelectedElement: FC<ISelectedFilterElementProps> = memo(
    ({ text, onRemove }: ISelectedFilterElementProps): ReactElement => (
        <div className={styles.selectedElement}>
            <span className={styles.title}>{text}</span>

            <Button className={styles.removeBtn} disableRipple onClick={onRemove}>
                <CancelIcon className={styles.removeIcon} />
            </Button>
        </div>
    ),
);

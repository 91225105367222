import React, { FC, ReactElement, memo } from 'react';

import { Button } from '@material-ui/core';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { IHeaderFollowingButtonProps } from 'types/interfaces';

export const HeaderFollowingButton: FC<IHeaderFollowingButtonProps> = memo(
    ({ navigateToMyFollowings, isFollowingPageOpen }: IHeaderFollowingButtonProps): ReactElement => (
        <div className={styles.followingButtonWrapper}>
            <Button
                className={classNames(styles.followingButton, {
                    [styles.followingPageOpen]: isFollowingPageOpen,
                })}
                disableRipple
                onClick={navigateToMyFollowings}
            >
                <FavoriteBorder className={styles.followingIcon} />
            </Button>
        </div>
    ),
);

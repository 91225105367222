import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { DAO_TREASURY_INVESTMENTS_APIS, DAO_TREASURY_INVESTMENTS_TYPES } from 'constants/daoTreasuryInvestments';

import { useAppSelector } from 'store';
import { InvestmentsMockupData, InvestmentsRealData } from './components';
import { RestrictedBanner } from 'components';

interface IDAOTreasuryInvestmentsTablesProps {
    updateDaoTreasuryInvestmentsData: (data: {
        queryParams: { [key: string]: string | number };
        fieldName: DAO_TREASURY_INVESTMENTS_TYPES;
        daoTreasuryAssetsApi: DAO_TREASURY_INVESTMENTS_APIS;
    }) => void;
}

export const DAOTreasuryInvestmentsTables: FC<IDAOTreasuryInvestmentsTablesProps> = ({
    updateDaoTreasuryInvestmentsData,
}: IDAOTreasuryInvestmentsTablesProps): ReactElement => {
    const { topInvestmentsType } = useAppSelector(({ daoTreasuryInvestments }) => daoTreasuryInvestments);

    return (
        <div className={styles.tablesWrapper}>
            {topInvestmentsType?.error?.statusCode === 403 ? (
                <>
                    <RestrictedBanner
                        title="DAOs & DeFi is a DeepDAO premium feature"
                        descriptions={[
                            'Available only for DAO Analytics subscribers.',
                        ]}
                        bulletPointsList={[
                            'DeFi protocols used by DAOs',
                            'Protocol types used by DAOs',
                            'DAOs borrowing protocols ',
                        ]}
                        connectWalletButtonTitle="Subscribe Here"
                    />
                    <InvestmentsMockupData />
                </>
            ) : (
                <InvestmentsRealData updateDaoTreasuryInvestmentsData={updateDaoTreasuryInvestmentsData} />
            )}
        </div>
    );
};

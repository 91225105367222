import React, { FC, ReactElement, memo } from 'react';

import styles from './styles.module.scss';

import { MembersWrapper, SearchField } from './components';

import { IMembersFilterProps } from 'types/interfaces';

export const MembersFilter: FC<IMembersFilterProps> = memo(
    ({
        membersSearch,
        setMembersSearch,
        isInputOpen,
        onInputClick,
        discussionsFilterMembers,
        handleUpdateMembersFilter,
        filter,
    }: IMembersFilterProps): ReactElement => (
        <div className={styles.membersFilter}>
            <div className={styles.membersFilterHeader}>
                <SearchField
                    membersSearch={membersSearch}
                    isInputOpen={isInputOpen}
                    setMembersSearch={setMembersSearch}
                    onInputClick={onInputClick}
                />
            </div>

            <MembersWrapper
                isInputOpen={isInputOpen}
                discussionsFilterMembers={discussionsFilterMembers}
                handleUpdateMembersFilter={handleUpdateMembersFilter}
                filter={filter}
            />
        </div>
    ),
);

import { createApiAction } from 'helpers';

export const GET_ORGANIZATION_DATA = 'GET_ORGANIZATION_DATA';
export const GET_ORGANIZATION_DATA_SUCCESS = 'GET_ORGANIZATION_DATA_SUCCESS';
export const GET_ORGANIZATION_DATA_FAILURE = 'GET_ORGANIZATION_DATA_FAILURE';

export const GET_ORGANIZATION_SIMILAR_DAOS_DATA = 'GET_ORGANIZATION_SIMILAR_DAOS_DATA';
export const GET_ORGANIZATION_SIMILAR_DAOS_DATA_SUCCESS = 'GET_ORGANIZATION_SIMILAR_DAOS_DATA_SUCCESS';
export const GET_ORGANIZATION_SIMILAR_DAOS_DATA_FAILURE = 'GET_ORGANIZATION_SIMILAR_DAOS_DATA_FAILURE';

export const GET_ORGANIZATION_TOP_SHAREHOLDERS_BY_HOLDINGS = 'GET_ORGANIZATION_TOP_SHAREHOLDERS_BY_HOLDINGS';
export const GET_ORGANIZATION_TOP_SHAREHOLDERS_BY_HOLDINGS_SUCCESS =
    'GET_ORGANIZATION_TOP_SHAREHOLDERS_BY_HOLDINGS_SUCCESS';
export const GET_ORGANIZATION_TOP_SHAREHOLDERS_BY_HOLDINGS_FAILURE =
    'GET_ORGANIZATION_TOP_SHAREHOLDERS_BY_HOLDINGS_FAILURE';

export const GET_ORGANIZATION_TOP_ACTIVE_SHAREHOLDERS = 'GET_ORGANIZATION_TOP_ACTIVE_SHAREHOLDERS';
export const GET_ORGANIZATION_TOP_ACTIVE_SHAREHOLDERS_SUCCESS = 'GET_ORGANIZATION_TOP_ACTIVE_SHAREHOLDERS_SUCCESS';
export const GET_ORGANIZATION_TOP_ACTIVE_SHAREHOLDERS_FAILURE = 'GET_ORGANIZATION_TOP_ACTIVE_SHAREHOLDERS_FAILURE';

export const GET_ORGANIZATION_AUM_GRAPH_DATA = 'GET_ORGANIZATION_AUM_GRAPH_DATA';
export const GET_ORGANIZATION_AUM_GRAPH_DATA_SUCCESS = 'GET_ORGANIZATION_AUM_GRAPH_DATA_SUCCESS';
export const GET_ORGANIZATION_AUM_GRAPH_DATA_FAILURE = 'GET_ORGANIZATION_AUM_GRAPH_DATA_FAILURE';

export const GET_ORGANIZATION_DAO_ASSETS = 'GET_ORGANIZATION_DAO_ASSETS';
export const GET_ORGANIZATION_DAO_ASSETS_SUCCESS = 'GET_ORGANIZATION_DAO_ASSETS_SUCCESS';
export const GET_ORGANIZATION_DAO_ASSETS_FAILURE = 'GET_ORGANIZATION_DAO_ASSETS_FAILURE';

export const GET_ORGANIZATION_DAO_ASSETS_TABLE = 'GET_ORGANIZATION_DAO_ASSETS_TABLE';
export const GET_ORGANIZATION_DAO_ASSETS_TABLE_SUCCESS = 'GET_ORGANIZATION_DAO_ASSETS_TABLE_SUCCESS';
export const GET_ORGANIZATION_DAO_ASSETS_TABLE_FAILURE = 'GET_ORGANIZATION_DAO_ASSETS_TABLE_FAILURE';

export const GET_ORGANIZATION_CURRENCIES_DATA = 'GET_ORGANIZATION_CURRENCIES_DATA';
export const GET_ORGANIZATION_CURRENCIES_DATA_SUCCESS = 'GET_ORGANIZATION_CURRENCIES_DATA_SUCCESS';
export const GET_ORGANIZATION_CURRENCIES_DATA_FAILURE = 'GET_ORGANIZATION_CURRENCIES_DATA_FAILURE';

export const GET_ORGANIZATION_TOOLS_DATA = 'GET_ORGANIZATION_TOOLS_DATA';
export const GET_ORGANIZATION_TOOLS_DATA_SUCCESS = 'GET_ORGANIZATION_TOOLS_DATA_SUCCESS';
export const GET_ORGANIZATION_TOOLS_DATA_FAILURE = 'GET_ORGANIZATION_TOOLS_DATA_FAILURE';

export const GET_ORGANIZATION_PROJECT_TOKEN_DATA = 'GET_ORGANIZATION_PROJECT_TOKEN_DATA';
export const GET_ORGANIZATION_PROJECT_TOKEN_DATA_SUCCESS = 'GET_ORGANIZATION_PROJECT_TOKEN_DATA_SUCCESS';
export const GET_ORGANIZATION_PROJECT_TOKEN_DATA_FAILURE = 'GET_ORGANIZATION_PROJECT_TOKEN_DATA_FAILURE';

export const GET_ORGANIZATION_DAOS_DATA = 'GET_ORGANIZATION_DAOS_DATA';
export const GET_ORGANIZATION_DAOS_DATA_SUCCESS = 'GET_ORGANIZATION_DAOS_DATA_SUCCESS';
export const GET_ORGANIZATION_DAOS_DATA_FAILURE = 'GET_ORGANIZATION_DAOS_DATA_FAILURE';

export const GET_ORGANIZATION_SUBSTORMS_DATA = 'GET_ORGANIZATION_SUBSTORMS_DATA';
export const GET_ORGANIZATION_SUBSTORMS_DATA_SUCCESS = 'GET_ORGANIZATION_SUBSTORMS_DATA_SUCCESS';
export const GET_ORGANIZATION_SUBSTORMS_DATA_FAILURE = 'GET_ORGANIZATION_SUBSTORMS_DATA_FAILURE';

export const GET_ORGANIZATION_DECISIONS_DATA = 'GET_ORGANIZATION_DECISIONS_DATA';
export const GET_ORGANIZATION_DECISIONS_DATA_SUCCESS = 'GET_ORGANIZATION_DECISIONS_DATA_SUCCESS';
export const GET_ORGANIZATION_DECISIONS_DATA_FAILURE = 'GET_ORGANIZATION_DECISIONS_DATA_FAILURE';

export const GET_ORGANIZATION_MEMBERS_DATA = 'GET_ORGANIZATION_MEMBERS_DATA';
export const GET_ORGANIZATION_MEMBERS_DATA_SUCCESS = 'GET_ORGANIZATION_MEMBERS_DATA_SUCCESS';
export const GET_ORGANIZATION_MEMBERS_DATA_FAILURE = 'GET_ORGANIZATION_MEMBERS_DATA_FAILURE';

export const GET_ORGANIZATION_COALITIONS_DATA = 'GET_ORGANIZATION_COALITIONS_DATA';
export const GET_ORGANIZATION_COALITIONS_DATA_SUCCESS = 'GET_ORGANIZATION_COALITIONS_DATA_SUCCESS';
export const GET_ORGANIZATION_COALITIONS_DATA_FAILURE = 'GET_ORGANIZATION_COALITIONS_DATA_FAILURE';

export const GET_ORGANIZATION_ACTIVITY_DATA = 'GET_ORGANIZATION_ACTIVITY_DATA';
export const GET_ORGANIZATION_ACTIVITY_DATA_SUCCESS = 'GET_ORGANIZATION_ACTIVITY_DATA_SUCCESS';
export const GET_ORGANIZATION_ACTIVITY_DATA_FAILURE = 'GET_ORGANIZATION_ACTIVITY_DATA_FAILURE';

export const GET_ORGANIZATION_DISCUSSIONS = 'GET_ORGANIZATION_DISCUSSIONS';
export const GET_ORGANIZATION_DISCUSSIONS_SUCCESS = 'GET_ORGANIZATION_DISCUSSIONS_SUCCESS';
export const GET_ORGANIZATION_DISCUSSIONS_FAILURE = 'GET_ORGANIZATION_DISCUSSIONS_FAILURE';

export const CREATE_DISCUSSION = 'CREATE_DISCUSSION';
export const CREATE_DISCUSSION_SUCCESS = 'CREATE_DISCUSSION_SUCCESS';
export const CREATE_DISCUSSION_FAILURE = 'CREATE_DISCUSSION_FAILURE';

export const CREATE_DISCUSSION_VOTE = 'CREATE_DISCUSSION_VOTE';
export const CREATE_DISCUSSION_VOTE_SUCCESS = 'CREATE_DISCUSSION_VOTE_SUCCESS';
export const CREATE_DISCUSSION_VOTE_FAILURE = 'CREATE_DISCUSSION_VOTE_FAILURE';

export const CREATE_DISCUSSION_COMMENT_VOTE = 'CREATE_DISCUSSION_COMMENT_VOTE';
export const CREATE_DISCUSSION_COMMENT_VOTE_SUCCESS = 'CREATE_DISCUSSION_COMMENT_VOTE_SUCCESS';
export const CREATE_DISCUSSION_COMMENT_VOTE_FAILURE = 'CREATE_DISCUSSION_COMMENT_VOTE_FAILURE';

export const CREATE_DISCUSSION_COMMENT = 'CREATE_DISCUSSION_COMMENT';
export const CREATE_DISCUSSION_COMMENT_SUCCESS = 'CREATE_DISCUSSION_COMMENT_SUCCESS';
export const CREATE_DISCUSSION_COMMENT_FAILURE = 'CREATE_DISCUSSION_COMMENT_FAILURE';

export const GET_ORGANIZATION_BALANCE = 'GET_ORGANIZATION_BALANCE';
export const GET_ORGANIZATION_BALANCE_SUCCESS = 'GET_ORGANIZATION_BALANCE_SUCCESS';
export const GET_ORGANIZATION_BALANCE_FAILED = 'GET_ORGANIZATION_BALANCE_FAILED';

export const TOGGLE_DISSCUSION_MODAL_OPEN = 'TOGGLE_DISSCUSION_MODAL_OPEN';

export const GET_USER_ORGANIZATION_ROLE = 'GET_USER_ORGANIZATION_ROLE';
export const GET_USER_ORGANIZATION_ROLE_SUCCESS = 'GET_USER_ORGANIZATION_ROLE_SUCCESS';
export const GET_USER_ORGANIZATION_ROLE_FAILURE = 'GET_USER_ORGANIZATION_ROLE_FAILURE';

export const GET_USER_ORGANIZATION_DISCUSSIONS_MEMBERS = 'GET_USER_ORGANIZATION_DISCUSSIONS_MEMBERS';
export const GET_USER_ORGANIZATION_DISCUSSIONS_MEMBERS_SUCCESS = 'GET_USER_ORGANIZATION_DISCUSSIONS_MEMBERS_SUCCESS';
export const GET_USER_ORGANIZATION_DISCUSSIONS_MEMBERS_FAILURE = 'GET_USER_ORGANIZATION_DISCUSSIONS_MEMBERS_SUCCESS';

export const BAN_DISCUSSION_ELEMENT = 'BAN_DISCUSSION_ELEMENT';
export const BAN_DISCUSSION_ELEMENT_SUCCESS = 'BAN_DISCUSSION_ELEMENT_SUCCESS';
export const BAN_DISCUSSION_ELEMENT_FAILURE = 'BAN_DISCUSSION_ELEMENT_FAILURE';

export const GET_ORGANIZATION_DISCUSSION = 'GET_ORGANIZATION_DISCUSSION';
export const GET_ORGANIZATION_DISCUSSION_SUCCESS = 'GET_ORGANIZATION_DISCUSSION_SUCCESS';
export const GET_ORGANIZATION_DISCUSSION_FAILURE = 'GET_ORGANIZATION_DISCUSSION_FAILURE';

export const UPDATE_ORGANIZATION_DISCUSSION = 'UPDATE_ORGANIZATION_DISCUSSION';
export const UPDATE_ORGANIZATION_DISCUSSION_SUCCESS = 'UPDATE_ORGANIZATION_DISCUSSION_SUCCESS';
export const UPDATE_ORGANIZATION_DISCUSSION_FAILURE = 'UPDATE_ORGANIZATION_DISCUSSION_FAILURE';

export const GET_ORGANIZATION_IS_FOLLOWING_DATA = 'GET_ORGANIZATION_IS_FOLLOWING_DATA';
export const GET_ORGANIZATION_IS_FOLLOWING_DATA_SUCCESS = 'GET_ORGANIZATION_IS_FOLLOWING_DATA_SUCCESS';
export const GET_ORGANIZATION_IS_FOLLOWING_DATA_FAILURE = 'GET_ORGANIZATION_IS_FOLLOWING_DATA_FAILURE';

export const FOLLOW_UNFOLLOW_ORGANIZATION = 'FOLLOW_UNFOLLOW_ORGANIZATION';
export const FOLLOW_UNFOLLOW_ORGANIZATION_SUCCESS = 'FOLLOW_UNFOLLOW_ORGANIZATION_SUCCESS';
export const FOLLOW_UNFOLLOW_ORGANIZATION_FAILURE = 'FOLLOW_UNFOLLOW_ORGANIZATION_FAILURE';

export const CLEAR_ORGANIZATION_DECISIONS_DATA = 'CLEAR_ORGANIZATION_DECISIONS_DATA';
export const CLEAR_ORGANIZATION_COALITIONS_DATA = 'CLEAR_ORGANIZATION_COALITIONS_DATA';
export const CLEAR_ORGANIZATION_MEMBERS_DATA = 'CLEAR_ORGANIZATION_MEMBERS_DATA';
export const CLEAR_ORGANIZATION_DATA = 'CLEAR_ORGANIZATION_DATA';
export const CLEAR_DISCUSSIONS_ERROR = 'CLEAR_DISCUSSIONS_ERROR';
export const CLEAR_DISCUSSION_TOKENLESS_ERROR = 'CLEAR_DISCUSSION_TOKENLESS_ERROR';

export const getOrganizationData = createApiAction(GET_ORGANIZATION_DATA);
export const getOrganizationSimilarDaosData = createApiAction(GET_ORGANIZATION_SIMILAR_DAOS_DATA);
export const getOrganizationTopShareholdersByHoldings = createApiAction(GET_ORGANIZATION_TOP_SHAREHOLDERS_BY_HOLDINGS);
export const getOrganizationTopActiveShareholders = createApiAction(GET_ORGANIZATION_TOP_ACTIVE_SHAREHOLDERS);
export const getOrganizationAumGraphData = createApiAction(GET_ORGANIZATION_AUM_GRAPH_DATA);
export const getOrganizationDaoAssets = createApiAction(GET_ORGANIZATION_DAO_ASSETS);
export const getOrganizationDaoAssetsTable = createApiAction(GET_ORGANIZATION_DAO_ASSETS_TABLE);
export const getOrganizationCurrenciesData = createApiAction(GET_ORGANIZATION_CURRENCIES_DATA);
export const getOrganizationToolsData = createApiAction(GET_ORGANIZATION_TOOLS_DATA);
export const getOrganizationProjectTokenData = createApiAction(GET_ORGANIZATION_PROJECT_TOKEN_DATA);
export const getOrganizationDaosData = createApiAction(GET_ORGANIZATION_DAOS_DATA);
export const getOrganizationSubstormsData = createApiAction(GET_ORGANIZATION_SUBSTORMS_DATA);
export const getOrganizationDecisionsData = createApiAction(GET_ORGANIZATION_DECISIONS_DATA);
export const getOrganizationMembersData = createApiAction(GET_ORGANIZATION_MEMBERS_DATA);
export const getOrganizationCoalitionsData = createApiAction(GET_ORGANIZATION_COALITIONS_DATA);
export const getOrganizationActivityData = createApiAction(GET_ORGANIZATION_ACTIVITY_DATA);
export const createDiscussion = createApiAction(CREATE_DISCUSSION);
export const createDiscussionVote = createApiAction(CREATE_DISCUSSION_VOTE);
export const createDiscussionComment = createApiAction(CREATE_DISCUSSION_COMMENT);
export const getOrganizationBalance = createApiAction(GET_ORGANIZATION_BALANCE);
export const getOrganizationDiscussions = createApiAction(GET_ORGANIZATION_DISCUSSIONS);
export const getUserOrganizationRole = createApiAction(GET_USER_ORGANIZATION_ROLE);
export const getUserOrganizationDiscussionsMembers = createApiAction(GET_USER_ORGANIZATION_DISCUSSIONS_MEMBERS);
export const banDiscussionElement = createApiAction(BAN_DISCUSSION_ELEMENT);
export const getOrganizationDiscussion = createApiAction(GET_ORGANIZATION_DISCUSSION);
export const updateOrganizationDiscussion = createApiAction(UPDATE_ORGANIZATION_DISCUSSION);
export const createDiscussionCommentVote = createApiAction(CREATE_DISCUSSION_COMMENT_VOTE);
export const getOrganizationIsFollowingData = createApiAction(GET_ORGANIZATION_IS_FOLLOWING_DATA);
export const followUnfollowOrganization = createApiAction(FOLLOW_UNFOLLOW_ORGANIZATION);

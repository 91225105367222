import React, { ReactElement, ReactNode, useMemo, useState } from 'react';

import styles from './styles.module.scss';

import { InfoCircle, Loader, TableDataNotFound } from 'components';
import { TableHeader, TableRow } from './components';

import { generateUniqId, tableDataSort } from 'helpers';
import { Sortings } from 'constants/sortings';
import { FormatUtils } from 'utils';

import { ISortedParam, ISortingParams } from 'types/interfaces';
import { ICommonTableHeader } from './types';

interface ICommonTableProps<T, K> {
    title: string;
    lastUpdate?: string | null;
    infoText?: string;
    tableHeaderData: ICommonTableHeader<T, K>[];
    tableRowsData: T[];
    sortedParamName: K;
    loading?: boolean;
    error?: string | null;
    headContent?: ReactNode;
    maxRowsLength?: number;
    onSortingChange?: (data: ISortingParams) => void;
}

export const CommonTable = <T, K extends string>({
    title,
    lastUpdate,
    infoText,
    tableHeaderData,
    tableRowsData,
    sortedParamName,
    loading,
    headContent,
    maxRowsLength,
    onSortingChange,
}: ICommonTableProps<T, K>): ReactElement => {
    const [sortedParam, setSortedParam] = useState<ISortedParam>({
        paramName: sortedParamName,
        descending: true,
    });

    const sortByParam = (param: K) => {
        const sortingData = {
            paramName: param,
            descending: sortedParam.paramName === param ? !sortedParam.descending : true,
        };

        setSortedParam(sortingData);
        onSortingChange?.({
            paramName: sortingData.paramName,
            order: sortingData.descending ? Sortings.DESCENDING : Sortings.ASCENDING,
        });
    };

    const rowsData = useMemo(() => {
        const sortedData = onSortingChange ? tableRowsData : tableDataSort(tableRowsData, sortedParam);

        return maxRowsLength ? sortedData.slice(0, maxRowsLength) : sortedData;
    }, [tableRowsData, sortedParam, maxRowsLength]);

    return (
        <div className={styles.content}>
            <div className={styles.headSection}>
                <div className={styles.textSection}>
                    <h4>{title}</h4>
                    <InfoCircle tooltipText={infoText} className={styles.infoIcon} />
                    {lastUpdate && <h5>Last Update: {FormatUtils.getFormattedDate(lastUpdate)}</h5>}
                </div>
                {headContent}
            </div>
            {rowsData.length > 0 && (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        <TableHeader
                            tableHeaderData={tableHeaderData}
                            sortByParam={sortByParam}
                            sortedParamName={sortedParam.paramName as K}
                        />
                        {rowsData.map((rowItems: T) => (
                            <TableRow
                                key={generateUniqId()}
                                tableHeaderData={tableHeaderData}
                                rowItems={rowItems}
                                columnSortedParamName={sortedParam.paramName}
                            />
                        ))}
                    </div>
                </div>
            )}
            {loading && tableRowsData.length === 0 && <Loader size={40} className={styles.loaderBox} />}
            {tableRowsData.length === 0 && !loading && <TableDataNotFound forTable overflowMarginsSecondVariant />}
        </div>
    );
};

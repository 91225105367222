import React, { useCallback, useState } from 'react';

import { Helmet } from 'react-helmet';
import { uniqBy } from 'lodash';

import { HelmetContext } from 'context/helmetContext';
import { defaultHead } from 'constants/metaTags';

export const AppHelmet = (props: { children: JSX.Element[] | JSX.Element }): JSX.Element => {
    const [metaTags, setMetaTags] = useState(defaultHead);

    const updateMetaTags = useCallback(
        (tags: JSX.Element | JSX.Element[]) => {
            const shallowTags = Array.isArray(tags) ? [...tags, ...metaTags] : [tags, ...metaTags];
            const newTags = uniqBy(shallowTags, 'key');

            setMetaTags(newTags);
        },
        [metaTags, setMetaTags],
    );

    return (
        <HelmetContext.Provider value={{ metaTags, setMetaTags: updateMetaTags }}>
            <Helmet>{metaTags}</Helmet>
            {props.children}
        </HelmetContext.Provider>
    );
};

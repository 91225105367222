import React, { FC, ReactElement } from 'react';

import { Link, useHistory } from 'react-router-dom';

import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import styles from './styles.module.scss';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { useAppSelector } from 'store';
import { LinkUtils } from 'utils';

export const FeedProposalHeader: FC = (): ReactElement => {
    const {
        feedProposalData: { organizationLogo, organizationName, organizationId },
    } = useAppSelector(({ feedProposal }) => feedProposal);

    const history = useHistory();

    return (
        <div className={styles.feedHeader}>
            <div className={styles.contentWrapper}>
                <div className={styles.link} onClick={history.goBack}>
                    <ArrowForwardOutlinedIcon className={styles.arrowIcon} />
                    <span className={styles.goBackText}>back</span>
                </div>
                <div className={styles.divider} />
                <Link to={LinkUtils.createDynamicLink(organizationId, 'organization')} className={styles.feedInfo}>
                    <img
                        className={styles.organizationImage}
                        src={LinkUtils.getOrganizationLogo(organizationLogo || '')}
                        alt="organizationLogo"
                    />
                    <h2 className={styles.organizationName}>{organizationName || DATA_NOT_FOUND}</h2>
                </Link>
            </div>
        </div>
    );
};

import React, { FC, ReactElement, useMemo, useState, useEffect, useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'store';

import { ISortedParam } from 'types/interfaces';
import { tableDataSort, tableSearch } from 'helpers';

import { FollowingPeopleTable } from './components';
import { TabsController } from './components/TabsController';
import { FOLLOWING_TABLE_TAB_TYPES } from 'components/FollowingPageBody/constants';
import { PEOPLE_PARAMS } from '../PeopleTable/constants';
import { OrganizationTable } from '../OrganizationTable';
import { ORGANIZATION_PARAMS } from '../OrganizationTable/constants';
import { SocialFeaturesActions } from 'store/actions';

export const FollowingData: FC = (): ReactElement => {
    const [tab, setTab] = useState<string>(FOLLOWING_TABLE_TAB_TYPES.PEOPLE);
    const [tabSearchValue, setTabSearchValue] = useState<string>('');

    const [organizationsSortedParam, setOrganizationsSortedParam] = useState<ISortedParam>({
        paramName: ORGANIZATION_PARAMS.TREASURY,
        descending: true,
    });

    const [peopleSortedParam, setPeopleSortedParam] = useState<ISortedParam>({
        paramName: PEOPLE_PARAMS.DAO_SCORE,
        descending: true,
    });

    const {
        followingPeopleData,
        followingOrganizationsData,
        followingOrganizationsAmount,
        followingPeopleAmount,
        followingOrganizationsLoading,
        followingPeopleLoading,
    } = useAppSelector(({ socialFeatures }) => socialFeatures);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(SocialFeaturesActions.getFollowingPeopleData.request());
    }, []);

    useEffect(() => {
        dispatch(SocialFeaturesActions.getFollowingOrganizationsData.request());
    }, []);

    const sortOrganizationsDataByParam = useCallback(
        (param: string) => {
            setOrganizationsSortedParam({
                paramName: param,
                descending: organizationsSortedParam.paramName === param ? !organizationsSortedParam.descending : true,
            });
        },
        [organizationsSortedParam],
    );

    const sortPeopleDataByParam = useCallback(
        (param: string) => {
            setPeopleSortedParam({
                paramName: param,
                descending: peopleSortedParam.paramName === param ? !peopleSortedParam.descending : true,
            });
        },
        [peopleSortedParam],
    );

    const peopleFollowingData = useMemo(
        () => tableSearch(tableDataSort(followingPeopleData, peopleSortedParam), tabSearchValue),
        [followingPeopleData, peopleSortedParam, tabSearchValue],
    );

    const organizationsFollowingData = useMemo(
        () => tableSearch(tableDataSort(followingOrganizationsData, organizationsSortedParam), tabSearchValue),
        [followingOrganizationsData, organizationsSortedParam, tabSearchValue],
    );

    return (
        <>
            <TabsController
                tab={tab}
                changeTab={setTab}
                tabSearchValue={tabSearchValue}
                onTabSearch={setTabSearchValue}
                followingOrganizationsCount={followingOrganizationsAmount}
                followingPeopleCount={followingPeopleAmount}
            />
            {tab === FOLLOWING_TABLE_TAB_TYPES.PEOPLE ? (
                <FollowingPeopleTable
                    loading={followingPeopleLoading}
                    sortByParam={sortPeopleDataByParam}
                    sortedParamName={peopleSortedParam.paramName}
                    data={peopleFollowingData}
                />
            ) : (
                <OrganizationTable
                    loading={followingOrganizationsLoading}
                    sortByParam={sortOrganizationsDataByParam}
                    sortedParamName={organizationsSortedParam.paramName}
                    data={organizationsFollowingData}
                />
            )}
        </>
    );
};

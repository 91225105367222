import React, { ReactElement, ReactNode } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { FormatUtils } from 'utils';

import { COMMON_TABLE_FIELD_TYPES } from 'components/CommonTable/types';
import { generateUniqId } from 'helpers';

// TODO: rewrite it
export const TableRow = ({ rowItems, tableHeaderData, priorData }: any): ReactElement => (
    <div className={styles.tableRow}>
        {tableHeaderData.map((headerItems: any) => {
            const { rowFieldKey, fieldType, settings } = headerItems;
            const uniqKey = generateUniqId();

            const rowCellData = rowItems[rowFieldKey];

            const fieldValue =
                typeof rowCellData === 'number'
                    ? FormatUtils.formatNumberWithCommas(rowCellData as number, 0)
                    : rowCellData;

            const renderRowFieldByType = (): ReactNode => {
                let fieldStyles = styles.rowCellText;

                // Determine styles based on comparison with prior data
                if (priorData && fieldType === COMMON_TABLE_FIELD_TYPES.COLOR_TEXT) {
                    const priorValue = priorData[rowFieldKey];
                    const currentValue = rowItems[rowFieldKey];

                    if (typeof currentValue === 'number' && typeof priorValue === 'number') {
                        fieldStyles = currentValue < priorValue ? styles.redText : styles.greenText;
                    }
                }

                switch (fieldType) {
                    case COMMON_TABLE_FIELD_TYPES.BOLD_TEXT:
                        return (
                            <span
                                className={classNames(styles.rowCellText, {
                                    [styles.rowCellBoldText]: fieldType === COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
                                })}
                            >
                                {fieldValue}
                            </span>
                        );

                    case COMMON_TABLE_FIELD_TYPES.COLOR_TEXT:
                        return <span className={classNames(fieldStyles, styles.rowCellBoldText)}>{fieldValue}</span>;

                    default:
                        return (
                            <div className={styles.rowCellContainer}>
                                <span className={styles.rowCellText}>{fieldValue}</span>
                            </div>
                        );
                }
            };

            return (
                <div style={{ width: settings.width.desktop }} key={uniqKey} className={styles.rowCellWrapper}>
                    {renderRowFieldByType()}
                </div>
            );
        })}
    </div>
);

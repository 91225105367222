import React, { FC, ReactElement } from 'react';

import { mockupData } from './mockupData';
import styles from '../../styles.module.scss';
import { DaoTokensTableHeader } from '../DaoTokensTableHeader';
import { IOrganizationTokensItems } from '../../../../types/interfaces';
import { DaoTokensItems } from '../DaoTokensItems';

export const DaoTokensMockupData: FC = (): ReactElement => (
    <>
        <div className={styles.tableWrapper}>
            <div className={styles.tableContainer}>
                <DaoTokensTableHeader sortedParamName={'Desc'} sortByParam={() => 'tokenName'} />

                <div className={styles.rowsWrapper}>
                    {mockupData.map((tokensInfo: IOrganizationTokensItems) => (
                        <DaoTokensItems key={tokensInfo.tokenName} {...tokensInfo} sortedParamName={'Desc'} />
                    ))}
                </div>
            </div>
        </div>
    </>
);

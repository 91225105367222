import React, { FC, ReactElement, useEffect } from 'react';

import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles.module.scss';

import { IConfirmationModalProps } from 'types/interfaces';

export const ConfirmationModal: FC<IConfirmationModalProps> = ({
    confirmationModalOpen,
    textInfo,
    onClose,
    onConfirm,
}: IConfirmationModalProps): ReactElement => {
    useEffect(() => {
        document.body.style.overflow = confirmationModalOpen ? 'hidden' : 'unset';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [confirmationModalOpen]);

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modal}>
                <div className={styles.modalHeader}>
                    <Button className={styles.closeBtn} onClick={onClose}>
                        <CloseIcon className={styles.closeIcon} />
                    </Button>
                </div>

                <div className={styles.modalBody}>
                    <p className={styles.text}>{textInfo.title}</p>
                    <div className={styles.optionsButton}>
                        {textInfo.cancelText && (
                            <Button className={styles.cancelBtn} onClick={onClose}>
                                {textInfo.cancelText}
                            </Button>
                        )}
                        <Button className={styles.confirmBtn} onClick={onConfirm}>
                            {textInfo.confirmationText}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

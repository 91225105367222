import { IUserPersonalInfo, SocialLink } from 'types/interfaces';

export const isPersonalInfoEmpty = (personalInfo: IUserPersonalInfo): boolean => {
    if (!personalInfo) return true;

    const { people_contact: peopleContact, skills: skills, interests: interests, resources: resources } = personalInfo;

    const isPeopleContactEmpty = !peopleContact || !peopleContact.about;
    const areSkillsEmpty = !skills || !skills.length;
    const areInterestsEmpty = !interests || !interests.length;
    const areResourcesEmpty =
        !resources ||
        !resources.length ||
        !Boolean(resources?.filter((resource: SocialLink) => resource.handle).length);

    return isPeopleContactEmpty && areSkillsEmpty && areInterestsEmpty && areResourcesEmpty;
};

export const getAbsoluteUrl = (url: string): string => {
    if (url.startsWith('https://') || url.startsWith('http://')) return url;

    return `//${url}`;
};

import React, { FC, ReactElement, memo } from 'react';

import styles from './styles.module.scss';

import { HeaderItemWithSorting } from 'components';
import { MEMBERS_PARAMS } from '../../constants';

import { ITableHeaderWithSortingProps } from 'types/interfaces';

export const TableHeader: FC<ITableHeaderWithSortingProps> = memo(
    ({ sortedParamName, sortByParam }: ITableHeaderWithSortingProps): ReactElement => (
        <div className={styles.tableHeader}>
            <div className={styles.rank}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`G-score ${'\n'}ranking`}
                    sortedParamName={sortedParamName}
                    paramName={MEMBERS_PARAMS.RANK}
                />
            </div>
            <div className={styles.usernameAddress}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={'username/address'}
                    sortedParamName={sortedParamName}
                    paramName={MEMBERS_PARAMS.ADDRESS_USERNAME}
                />
            </div>
            <div className={styles.voted}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`voted`}
                    sortedParamName={sortedParamName}
                    paramName={MEMBERS_PARAMS.VOTED_PARAM}
                />
            </div>
            <div className={styles.votedWin}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`win %`}
                    sortedParamName={sortedParamName}
                    paramName={MEMBERS_PARAMS.VOTED_WIN_PARAM}
                />
            </div>
            <div className={styles.votedLost}>
                <div>
                    <span>won/lost</span>
                </div>
            </div>
            <div className={styles.proposalsCreated}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`proposals ${'\n'}created`}
                    sortedParamName={sortedParamName}
                    paramName={MEMBERS_PARAMS.PROPOSALS_CREATED_PARAM}
                />
            </div>
            <div className={styles.proposalsWin}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`win %`}
                    sortedParamName={sortedParamName}
                    paramName={MEMBERS_PARAMS.PROPOSAL_WIN_PARAM}
                />
            </div>
            <div className={styles.proposalsLost}>
                <div>
                    <span>won/lost</span>
                </div>
            </div>
            <div className={styles.tokensShare}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`tokens ${'\n'}share`}
                    sortedParamName={sortedParamName}
                    paramName={MEMBERS_PARAMS.TOKENS_SHARE_PARAM}
                />
            </div>
            <div className={styles.sharePercent}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`share ${'\n'}percent`}
                    sortedParamName={sortedParamName}
                    paramName={MEMBERS_PARAMS.SHARE_PERCENT_PARAM}
                />
            </div>
        </div>
    ),
);

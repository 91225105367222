import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    tooltip: {
        borderColor: '#BECDD7 !important',
        padding: '10px !important',
        width: '140px',
    },
    arrow: {
        fontSize: '16px !important',
        width: '16px !important',

        '&::before': {
            borderColor: '#BECDD7 !important',
        },
    },
}));

import React, { FC, ReactElement, useState } from 'react';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { defaultOrganizationPlaceholderImage, defaultTokenPlaceholderImage, defaultUserPlaceholderImage } from 'assets';

import { IImageLoaderProps } from 'types/interfaces';

export const ImageLoader: FC<IImageLoaderProps> = ({ src, className, imageType }: IImageLoaderProps): ReactElement => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const showDefaultImageByType = () => {
        switch (imageType) {
            case IMAGE_TYPES.ORGANIZATION_IMAGE: {
                return defaultOrganizationPlaceholderImage;
            }

            case IMAGE_TYPES.TOKEN_IMAGE: {
                return defaultTokenPlaceholderImage;
            }

            case IMAGE_TYPES.USER_IMAGE: {
                return defaultUserPlaceholderImage;
            }
        }
    };

    const showImageAltByType = () => {
        switch (imageType) {
            case IMAGE_TYPES.ORGANIZATION_IMAGE: {
                return 'organization logo';
            }

            case IMAGE_TYPES.TOKEN_IMAGE: {
                return 'token logo';
            }

            case IMAGE_TYPES.USER_IMAGE: {
                return 'user logo';
            }
        }
    };

    const placeholderSrc = showDefaultImageByType();
    const alt = showImageAltByType();

    if (!src) {
        return <img src={placeholderSrc} alt={alt} style={{ display: 'inline-block' }} className={className} />;
    }

    const handleImageLoaded = () => setIsImageLoaded(true);

    return (
        <>
            <img
                src={placeholderSrc}
                alt={alt}
                style={{ display: !isImageLoaded ? 'inline-block' : 'none' }}
                className={className}
            />
            <img
                src={src}
                alt={alt}
                style={{ display: isImageLoaded ? 'inline-block' : 'none' }}
                className={className}
                onLoad={handleImageLoaded}
            />
        </>
    );
};

export default React.memo(ImageLoader);

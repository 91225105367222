import React, { FC, ReactElement } from 'react';

import { ITableHeaderWithSortingProps, IFollowPeopleTableProps } from 'types/interfaces';
import { Loader, TableDataNotFound } from 'components';

import { TableHeader, TableRow } from './components';

import styles from './styles.module.scss';

export const PeopleTable: FC<IFollowPeopleTableProps & ITableHeaderWithSortingProps> = ({
    isFollowersTable,
    data,
    sortedParamName,
    sortByParam,
    loading,
}): ReactElement => {
    return (
        <>
            {loading && <Loader className={styles.loader} />}

            {data.length > 0 && !loading && (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        <TableHeader
                            isFollowersTable={isFollowersTable}
                            sortByParam={sortByParam}
                            sortedParamName={sortedParamName}
                        />
                        <div className={styles.rowsWrapper}>
                            {data?.map((item) => (
                                <TableRow key={item.memberAddress} isFollowersTable={isFollowersTable} item={item} />
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {!loading && data.length === 0 && <TableDataNotFound forTable />}
        </>
    );
};

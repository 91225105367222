import { tableDataSort } from 'helpers';
import { useAppSelector } from 'store';

import { IOrganizationsGraphData, IOrganizationsStats } from 'types/interfaces';

const organizationsOverThresholdRanges: { [key: string]: string } = {
    over1m: '> $1M',
    over10m: '> $10M',
    over100m: '> $100M',
};

const organizationsActiveVotersRanges: { [key: string]: string } = {
    over100: '> 100',
    over1k: '> 1000',
    over10k: '> 10,000',
};

export const useStatsDataCreator = (): any => {
    const {
        organizationsTotalTreasury,
        organizationsOverThresholdAum,
        organizationsPotentialVoters,
        organizationsOverThresholdActiveMembers,
        organizationsActiveMembers,
    } = useAppSelector(({ organizations }) => organizations);
    const potentialVotersData: IOrganizationsStats = {
        total: organizationsPotentialVoters?.graph?.data?.totalTokenHolders || 0,
        data: [
            {
                id: 1,
                value: organizationsPotentialVoters?.graph?.data?.changeWeek || 0,
                period: '1 week',
            },
            {
                id: 2,
                value: organizationsPotentialVoters?.graph?.data?.changeMonth || 0,
                period: '1 month',
            },
        ],
    };

    const activeVotersProposalsData: IOrganizationsStats = {
        total: organizationsActiveMembers?.graph?.data?.activeTokenHolders || 0,
        data: [
            {
                id: 3,
                value: organizationsActiveMembers?.graph?.data?.changeWeek || 0,
                period: '1 week',
            },
            {
                id: 4,
                value: organizationsActiveMembers?.graph?.data?.changeMonth || 0,
                period: '1 month',
            },
        ],
    };

    const totalTreasuryData = {
        graphData: organizationsTotalTreasury?.treasuryGraph?.length
            ? tableDataSort(
                  organizationsTotalTreasury.treasuryGraph.map((data: IOrganizationsGraphData) => ({
                      label: data.month,
                      value: data.aum,
                  })),
                  {
                      paramName: 'label',
                      descending: false,
                  },
              )
            : [],
        headerData: organizationsTotalTreasury?.graph?.data || {},
        headerValues: organizationsTotalTreasury?.aum?.length ? [...organizationsTotalTreasury?.aum] : [],
    };

    const organizationsOverThresholdAumData = {
        graphData: organizationsOverThresholdAum?.graph?.data
            ? Object.entries(organizationsOverThresholdRanges).map(([key, label]: any) => ({
                  value: organizationsOverThresholdAum?.graph?.data?.[key] || 0,
                  label,
              }))
            : [],
        totalValue: organizationsOverThresholdAum?.graph?.data?.totalOrganizations || 0,
    };

    const organizationsOverThresholdActiveMembersData = {
        graphData: organizationsOverThresholdActiveMembers?.graph?.data
            ? Object.entries(organizationsActiveVotersRanges).map(([key, label]: any) => ({
                  value: organizationsOverThresholdActiveMembers?.graph?.data?.[key] || 0,
                  label,
              }))
            : [],
        totalValue: organizationsOverThresholdActiveMembers?.graph?.data?.totalOrganizations || 0,
    };

    return {
        potentialVotersData,
        activeVotersProposalsData,
        totalTreasuryData,
        organizationsOverThresholdAumData,
        organizationsOverThresholdActiveMembersData,
    };
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { DAOExperienceTable, TopTokensValuesTable, UserAbout } from './components';

import { IUserPageBodyProps } from 'types/interfaces';

export const UserVerifiedDaoExperience: FC<IUserPageBodyProps> = ({
    isAuthenticatedUser,
}: IUserPageBodyProps): ReactElement => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.mainContent}>
                    <div className={styles.leftSideContainer}>
                        <UserAbout isAuthenticatedUser={isAuthenticatedUser} />

                        <DAOExperienceTable />
                    </div>

                    <div className={styles.rightSideContainer}>
                        <TopTokensValuesTable />
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { FC, ReactElement, useEffect } from 'react';

import classNames from 'classnames';

import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles.module.scss';

import { setIconByType } from './utils';
import { ALERT_TYPES } from 'constants/alert';

import { useAppDispatch, useAppSelector } from 'store';
import { AlertActions } from 'store/actions';

import { IAlertProviderProps } from 'types/interfaces';

export const AlertProvider: FC<IAlertProviderProps> = ({ children }: IAlertProviderProps): ReactElement => {
    const { isAlertOpen, title, type, description } = useAppSelector(({ alert }) => alert);
    const dispatch = useAppDispatch();

    const onCloseBtnClick = () => {
        dispatch({ type: AlertActions.HIDE_ALERT });
    };

    useEffect(() => {
        if (isAlertOpen) setTimeout(() => dispatch({ type: AlertActions.HIDE_ALERT }), 5000);
    }, [isAlertOpen]);

    return (
        <>
            {isAlertOpen && (
                <div className={styles.alertWrapper}>
                    <div className={styles.alertContainer}>
                        <div
                            className={classNames(styles.alertBox, {
                                [styles.alertSuccess]: type === ALERT_TYPES.SUCCESS,
                                [styles.alertError]: type === ALERT_TYPES.ERROR,
                                [styles.alertInfo]: type === ALERT_TYPES.INFO,
                                [styles.alertNotification]: type === ALERT_TYPES.NOTIFICATION,
                            })}
                        >
                            <div className={styles.mainContent}>
                                <div>
                                    <img src={setIconByType(type as ALERT_TYPES)} className={styles.alertIcon} alt="" />
                                    <p className={styles.title}>{title}</p>
                                </div>

                                {description && <h6 className={styles.description}>{description}</h6>}
                            </div>

                            <Button className={styles.closeButton} onClick={onCloseBtnClick}>
                                <CloseIcon className={styles.closeIcon} />
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {children}
        </>
    );
};

import React, { FC, ReactElement, memo } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import ExitToApp from '@material-ui/icons/ExitToApp';

import styles from './styles.module.scss';

import { IPlansOptionsProps } from 'types/interfaces';

export const PlansOptions: FC<IPlansOptionsProps> = memo(
    ({ navigateToPremiumLogin, isUserWithPremiumIsLogged, premiumLogout }: IPlansOptionsProps): ReactElement => (
        <div className={styles.optionsWrapper}>
            {isUserWithPremiumIsLogged ? (
                <div className={styles.premiumUserOptions}>
                    <Link to="/products" className={styles.link}>
                        Plans
                    </Link>
                    <Link to="/premium_account_settings" className={styles.link}>
                        Settings
                    </Link>
                    <div className={styles.logoutOptionWrapper} onClick={premiumLogout}>
                        <ExitToApp className={styles.logoutIcon} />
                        <span className={styles.logoutText}>logout</span>
                    </div>
                </div>
            ) : (
                <div>
                    <Link to="/products" className={styles.link}>
                        Plans
                    </Link>
                    <div className={styles.premiumLoginButtonWrapper}>
                        <Button className={styles.premiumLoginButton} disableRipple onClick={navigateToPremiumLogin}>
                            login
                        </Button>
                    </div>
                </div>
            )}
        </div>
    ),
);

import React, { FC, ReactElement, useState, useMemo, useCallback } from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { DiscussionCommentTextarea, ImageLoader } from 'components';
import { ReplyInfo } from './components';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { IDicussionComment, IRepliesSectionProps } from 'types/interfaces';

const DEFAULT_REPLIES_COUNT = 3;
const MORE_REPLIES_COUNT = 10;

export const RepliesSection: FC<IRepliesSectionProps> = ({
    comments = [],
    creatorLink,
    avatar,
    createComment,
    id,
    discussionId,
    replyInputOpen,
    isReplyingAvailable,
    onBrainstormBanClick,
    isDiscussionSingle,
    isUserAdminOrEditor,
}: IRepliesSectionProps): ReactElement => {
    const [visibleRepliesCount, setVisibleRepliesCount] = useState<number>(DEFAULT_REPLIES_COUNT);
    const [value, setValue] = useState<string>('');

    const repliesCount = useMemo(() => Number(comments.length), [comments]);

    const { showRepliesOptions, avaibleRepliesCount } = useMemo(
        () => ({
            showRepliesOptions: repliesCount > visibleRepliesCount,
            avaibleRepliesCount:
                repliesCount - visibleRepliesCount >= MORE_REPLIES_COUNT
                    ? MORE_REPLIES_COUNT
                    : repliesCount - visibleRepliesCount,
        }),
        [visibleRepliesCount, repliesCount],
    );

    const showMoreReplies = useCallback(
        (numberOfReplies: number) => setVisibleRepliesCount(numberOfReplies + visibleRepliesCount),
        [visibleRepliesCount],
    );

    const visibleReplies = useMemo(
        () => (repliesCount ? comments.slice(0, visibleRepliesCount) : []),
        [visibleRepliesCount, comments, repliesCount],
    );

    return (
        <>
            {(replyInputOpen || visibleReplies.length > 0) && (
                <div className={styles.repliesSectionWrapper}>
                    {replyInputOpen && (
                        <div className={styles.inputSection}>
                            <Link to={creatorLink} className={styles.link}>
                                <ImageLoader
                                    src={avatar}
                                    className={styles.userLogo}
                                    imageType={IMAGE_TYPES.USER_IMAGE}
                                />
                            </Link>

                            <DiscussionCommentTextarea
                                placeholder="Reply"
                                createComment={createComment('reply', id, discussionId)}
                                value={value}
                                setValue={setValue}
                                brainstormId={discussionId}
                            />
                        </div>
                    )}

                    <div className={styles.repliesList}>
                        {visibleReplies.map((comment: IDicussionComment) => (
                            <ReplyInfo
                                key={comment.id}
                                {...comment}
                                onBrainstormBanClick={onBrainstormBanClick}
                                commentId={id}
                                isReplyingAvailable={isReplyingAvailable}
                                isDiscussionSingle={isDiscussionSingle}
                                isUserAdminOrEditor={isUserAdminOrEditor}
                            />
                        ))}
                    </div>

                    {showRepliesOptions && (
                        <div className={styles.showMoreRepliesOptions}>
                            <div className={styles.title} onClick={() => showMoreReplies(MORE_REPLIES_COUNT)}>
                                See {avaibleRepliesCount} more {avaibleRepliesCount === 1 ? 'reply' : 'replies'}
                            </div>
                            <div className={styles.line} />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

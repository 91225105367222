import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { MainSocialLinks } from 'components';

export const NewsLetterForm: FC = (): ReactElement => (
    <div className={styles.infoWrapper}>
        <h3 className={styles.title}>Stay Informed</h3>
        <h6 className={styles.description}>Register to receive our newsletter</h6>
        <MainSocialLinks className={styles.linksContainer} />
    </div>
);

import React, { FC, ReactElement, memo } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { IAccountSettingBlockProps } from 'types/interfaces';

export const AccountSettingBlock: FC<IAccountSettingBlockProps> = memo(
    ({ sectionTitle, description, option, className }: IAccountSettingBlockProps): ReactElement => (
        <div className={classNames(styles.blockWrapper, className)}>
            <div className={styles.textInfo}>
                {sectionTitle}
                {description && <div className={styles.descriptionBlock}>{description}</div>}
            </div>

            {option && <div className={styles.optionWrapper}>{option}</div>}
        </div>
    ),
);

import { ICustomGradient } from 'types/interfaces';

const chartLineGradientPrimary: ICustomGradient[] = [
    {
        color: 'rgba(255, 255, 255, 0.3)',
        offset: '100%',
    },
];

export { chartLineGradientPrimary };

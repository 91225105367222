import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TooltipWrapper, ImageLoader } from 'components';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { FormatUtils, LinkUtils } from 'utils';
import { defaultTokenPlaceholderImage } from 'assets';

import { ITopTokensValuesRowData } from 'types/interfaces';

export const TableRow: FC<ITopTokensValuesRowData> = ({
    index,
    name,
    usdVolumePercent,
    value,
    usd,
    logo,
    symbol,
}: ITopTokensValuesRowData): ReactElement => {
    const tokenLogo = LinkUtils.isValidUrl(logo)
        ? logo
        : LinkUtils.getDaoLogoLinkFromBucket(logo, defaultTokenPlaceholderImage);

    return (
        <div className={styles.tableRow}>
            <div className={styles.number}>{index + 1}</div>
            <div className={styles.name}>
                <TooltipWrapper title={symbol}>
                    <div className={styles.tokenLogoWrapper}>
                        <ImageLoader src={tokenLogo} className={styles.tokenLogo} imageType={IMAGE_TYPES.TOKEN_IMAGE} />
                    </div>
                </TooltipWrapper>
                <TooltipWrapper title={name}>
                    <span>{name}</span>
                </TooltipWrapper>
            </div>
            <div className={styles.holding}>
                <TooltipWrapper title={`Percent of wallet: ${usdVolumePercent}%`}>
                    <span>{FormatUtils.formatNumberShort(Number(value), 1)}</span>
                </TooltipWrapper>
            </div>
            <div className={styles.usdValue}>${FormatUtils.formatNumberShort(Number(usd), 1)}</div>
        </div>
    );
};

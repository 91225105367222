import React, { FC, ReactElement, useState, useEffect } from 'react';

import { PeopleTable } from './components';

import styles from './styles.module.scss';

import { PEOPLE_PARAMS, OFFSET, LIMIT } from './constants';

import { useAppSelector, useAppDispatch } from 'store';
import { PeopleActions } from 'store/actions';

export const PeopleTables: FC = (): ReactElement => {
    const { peopleData, hasMore } = useAppSelector(({ people }) => people);
    const dispatch = useAppDispatch();

    const [offset, setOffset] = useState(OFFSET);
    const [sortOrder, setSortOrder] = useState('DESC');

    const [peopleSortedParam, setPeopleSortedParam] = useState<string>(PEOPLE_PARAMS.PARTICIPATION_SCORE_PARAM);

    useEffect(() => {
        dispatch(PeopleActions.getAllPeopleData.request({ limit: LIMIT, offset: OFFSET, sortBy: peopleSortedParam }));
    }, [dispatch]);

    useEffect(
        () => () => {
            dispatch({ type: PeopleActions.CLEAR_ALL_PEOPLE_DATA });
        },
        [dispatch],
    );

    const sortPeopleByParam = (param: string) => {
        setPeopleSortedParam(param);

        let order;

        if (param === peopleSortedParam) {
            order = sortOrder === 'DESC' ? 'ASC' : 'DESC';
            setSortOrder(order);
        } else {
            order = 'DESC';
            setSortOrder(order);
        }

        dispatch({ type: PeopleActions.CLEAR_PEOPLE_DATA });

        dispatch(
            PeopleActions.getPeopleSortedData.request({
                limit: LIMIT,
                offset: OFFSET,
                sortBy: param,
                sortOrder: order,
            }),
        );
    };

    const loadMoreData = () => {
        if (!peopleData.length) return;
        dispatch(
            PeopleActions.getPeopleSortedData.request({
                limit: LIMIT,
                offset: offset + LIMIT,
                sortBy: peopleSortedParam,
            }),
        );
        setOffset(offset + LIMIT);
    };

    return (
        <div className={styles.mainContentWrapper}>
            <div className={styles.tableContent}>
                <PeopleTable
                    data={peopleData}
                    sortByParam={sortPeopleByParam}
                    sortedParam={peopleSortedParam}
                    loadMoreData={loadMoreData}
                    hasMore={hasMore}
                />
            </div>
        </div>
    );
};

import React, { FC, ReactElement, useState, useMemo, useCallback, useEffect } from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { DiscussionCommentTextarea, DiscussionTooltip, ImageLoader } from 'components';
import { CommentInfo } from './components';

import { IMAGE_TYPES } from 'constants/imageTypes';
import { DISCUSSIONS_NOTIFICATIONS } from 'constants/discussionsElements';

import { useAppDispatch, useAppSelector } from 'store';
import { OrganizationActions } from 'store/actions';
import { LinkUtils } from 'utils';

import { ICommentsSectionProps, IDicussionComment } from 'types/interfaces';

const DEFAULT_COMMENTS_COUNT = 3;
const MORE_COMMENTS_COUNT = 10;

export const CommentsSection: FC<ICommentsSectionProps> = ({
    comments = [],
    loggedUserAddress,
    discussionId,
    isCommentingAvaible,
    onBrainstormBanClick,
    isDiscussionSingle,
    isUserAdminOrEditor,
    isAuthenticatedUser,
    organizationId,
}: ICommentsSectionProps): ReactElement => {
    const {
        loggedUserData: { avatar, address },
    } = useAppSelector(({ auth }) => auth);

    const { discussionsIdsWithCommentError } = useAppSelector(({ organization }) => organization);

    const [visibleCommentsCount, setVisibleCommentsCount] = useState<number>(DEFAULT_COMMENTS_COUNT);
    const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (discussionsIdsWithCommentError.includes(discussionId) && !notificationOpen) setNotificationOpen(true);
    }, [discussionsIdsWithCommentError]);

    const createComment = useCallback(
        (type: string, id: string, discussionId: string) => (value: string) =>
            dispatch(
                OrganizationActions.createDiscussionComment.request({
                    id,
                    type,
                    discussionId,
                    isDiscussionSingle,
                    organizationId,
                    data: {
                        creator: loggedUserAddress,
                        text: value,
                        discussionThreshold: 1,
                        votingThreshold: 1,
                    },
                }),
            ),
        [loggedUserAddress, organizationId],
    );

    const commentsCount = useMemo(() => Number(comments.length), [comments]);

    const creatorLink = useMemo(() => LinkUtils.createDynamicLink(address, 'user'), [address]);

    const visibleComments = useMemo(
        () => (commentsCount ? comments.slice(0, visibleCommentsCount) : []),
        [visibleCommentsCount, comments, commentsCount],
    );

    const { showCommentsOptions, avaibleCommentsCount } = useMemo(
        () => ({
            showCommentsOptions: commentsCount > visibleCommentsCount,
            avaibleCommentsCount:
                commentsCount - visibleCommentsCount >= MORE_COMMENTS_COUNT
                    ? MORE_COMMENTS_COUNT
                    : commentsCount - visibleCommentsCount,
        }),
        [visibleCommentsCount, commentsCount],
    );

    const showMoreComments = useCallback(
        (numberOfComments: number) => setVisibleCommentsCount(numberOfComments + visibleCommentsCount),
        [visibleCommentsCount],
    );

    const clearNotificationError = useCallback(() => {
        setNotificationOpen(false);
        dispatch({
            type: OrganizationActions.CLEAR_DISCUSSION_TOKENLESS_ERROR,
            payload: {
                discussionsIdsWithCommentError: discussionsIdsWithCommentError.filter(
                    (itemId: string) => itemId !== discussionId,
                ),
            },
        });
    }, [notificationOpen, discussionsIdsWithCommentError]);

    return (
        <div className={styles.commentsSectionWrapper}>
            <div className={styles.inputSection}>
                {notificationOpen && (
                    <DiscussionTooltip
                        onClose={clearNotificationError}
                        className={styles.notificationTooltip}
                        text={DISCUSSIONS_NOTIFICATIONS.COMMENT}
                    />
                )}

                {address ? (
                    <Link to={creatorLink} className={styles.link}>
                        <ImageLoader src={avatar} className={styles.userLogo} imageType={IMAGE_TYPES.USER_IMAGE} />
                    </Link>
                ) : (
                    <ImageLoader src={avatar} className={styles.userLogo} imageType={IMAGE_TYPES.USER_IMAGE} />
                )}

                {isCommentingAvaible && !notificationOpen ? (
                    <DiscussionCommentTextarea
                        createComment={createComment('comment', discussionId, discussionId)}
                        placeholder="Comment"
                        value={value}
                        setValue={setValue}
                        brainstormId={discussionId}
                    />
                ) : (
                    <div className={styles.commentBlock} onClick={() => setNotificationOpen(true)}>
                        <span>Comment</span>
                    </div>
                )}
            </div>

            {visibleComments?.map((comment: IDicussionComment) => (
                <CommentInfo
                    key={comment.id}
                    {...comment}
                    creatorLink={creatorLink}
                    avatar={avatar}
                    createComment={createComment}
                    isReplyingAvailable={isCommentingAvaible}
                    onBrainstormBanClick={onBrainstormBanClick}
                    isDiscussionSingle={isDiscussionSingle}
                    isUserAdminOrEditor={isUserAdminOrEditor}
                    discussionId={discussionId}
                    loggedUserAddress={loggedUserAddress}
                    isAuthenticatedUser={isAuthenticatedUser}
                    organizationId={organizationId}
                />
            ))}

            {showCommentsOptions && (
                <div className={styles.showMoreCommentsOptions}>
                    <div className={styles.title} onClick={() => showMoreComments(MORE_COMMENTS_COUNT)}>
                        See {avaibleCommentsCount} more {avaibleCommentsCount === 1 ? 'comment' : 'comments'}
                    </div>
                    <div className={styles.line} />
                </div>
            )}
        </div>
    );
};

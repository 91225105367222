import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';

import _ from 'lodash';

import moment, { Moment } from 'moment';

import { DesktopFilters, MobileFilters } from './components';

import { DEFAULT_FILTER_STATE } from './constants';

import { useAppSelector, useAppDispatch } from 'store';
import { OrganizationActions } from 'store/actions';
import { mixpanelService } from 'services';

import {
    IFilterState,
    IDateRangePicker,
    IDiscussionsFilterMember,
    IDiscussionsFilterProps,
    IDateFilterBreakPoint,
    IOrganizationSubstorm,
} from 'types/interfaces';

export const DiscussionsFilter: FC<IDiscussionsFilterProps> = ({
    handleSetDiscussionsFilter,
    isLogged,
}: IDiscussionsFilterProps): ReactElement => {
    const [filter, setFilter] = useState<IFilterState>(DEFAULT_FILTER_STATE);
    const [isDateRangePickerOpen, setDateRangePickerOpen] = useState<boolean>(false);
    const [datesListOpen, setDatesListOpen] = useState<boolean>(false);
    const [membersSearch, setMembersSearch] = useState<string>('');
    const [isInputOpen, setIsInputOpen] = useState<boolean>(false);
    const [substormsListOpen, setSubstormsListOpen] = useState<boolean>(false);
    const [dateRangePickerSelectedDates, setDateRangePickerSelectedDates] = useState<IDateRangePicker>({
        label: null,
        from: null,
        to: null,
    });

    const dispatch = useAppDispatch();

    const {
        organizationData: { id: organizationId },
        discussionsFilterMembers,
        substormsList,
    } = useAppSelector((store) => store.organization);

    const isMobile = useMediaQuery('(max-width:575.98px)');

    const dateFilterBreakPoints: IDateFilterBreakPoint[] = useMemo(() => {
        const breakdowns: IDateFilterBreakPoint[] = [];

        const now = moment();

        const pastDays30 = moment().clone().subtract(30, 'days').startOf('day');
        breakdowns.push({ label: 'Past 30 days', from: pastDays30, to: now.clone().endOf('day') });

        for (const monthBackward of [1, 2, 3]) {
            const pastMonth = now.clone().subtract(monthBackward, 'months');
            const startOfMonth = pastMonth.clone().startOf('month');
            const endOfMonth = pastMonth.clone().endOf('month');
            const label = pastMonth.clone().format('MMMM YYYY');

            breakdowns.push({ label, from: startOfMonth, to: endOfMonth });
        }

        return breakdowns;
    }, []);

    const handleUpdateDatesFilter = useCallback(
        (data: IDateRangePicker) => {
            setFilter({ ...filter, ..._.omit(data ? data : dateRangePickerSelectedDates, ['label']) });

            mixpanelService.track(mixpanelService.eventsGenerator.brainStormFilter('dates'));
        },
        [setFilter, filter, dateRangePickerSelectedDates],
    );

    const getFormattedDate = useCallback(
        (date?: string | Moment, format?: string) => date && moment(date).format(format),
        [moment],
    );

    const handleUpdateFilterDates = useCallback(
        (label?: string, from?: Moment, to?: Moment): void => {
            if (label && from && to) {
                if (label === 'Custom') {
                    setDateRangePickerOpen(false);
                    setDateRangePickerSelectedDates({ label, from, to });
                    handleUpdateDatesFilter({ label, from, to });
                } else {
                    if (label === dateRangePickerSelectedDates.label) {
                        setDateRangePickerSelectedDates({ label: null, from: null, to: null });
                        handleUpdateDatesFilter({ label: null, from: null, to: null });
                    } else {
                        setDateRangePickerSelectedDates({ label, from, to });
                        handleUpdateDatesFilter({ label, from, to });
                    }
                }
            }

            if (!label) {
                setDateRangePickerSelectedDates({ label: null, from: null, to: null });
                handleUpdateDatesFilter({ label: null, from: null, to: null });
            }
        },
        [isDateRangePickerOpen, isDateRangePickerOpen, handleUpdateDatesFilter],
    );

    const handleOpenDateRangePicker = useCallback(
        () => setDateRangePickerOpen(!isDateRangePickerOpen),
        [setDateRangePickerOpen, isDateRangePickerOpen],
    );

    const handleUpdateOrderFilter = useCallback(
        (order: string) => {
            setFilter({
                ...filter,
                order,
                orderByTotalVoters: null,
            });

            mixpanelService.track(mixpanelService.eventsGenerator.brainStormFilter('orderByCreatedAt'));
        },
        [setFilter, filter],
    );

    const handleUpdateOrderByTotalVotersFilter = useCallback(
        (order: string) => {
            setFilter({
                ...filter,
                orderByTotalVoters: order,
                order: null,
            });

            mixpanelService.track(mixpanelService.eventsGenerator.brainStormFilter('filterByTotalVoters'));
        },
        [setFilter, filter],
    );

    const handleUpdateOnlyMyFilter = useCallback(() => {
        setFilter({ ...filter, isOnlyMyDiscussions: !filter.isOnlyMyDiscussions });

        mixpanelService.track(mixpanelService.eventsGenerator.brainStormFilter('onlyMyFilter'));
    }, [setFilter, filter]);

    const handleUpdateOrderByNumberOfMembersFilter = useCallback(
        (filterName: keyof IFilterState) => {
            mixpanelService.track(mixpanelService.eventsGenerator.brainStormFilter('filterByNumberOfMembersFilter'));

            if (filter[filterName])
                return setFilter({
                    ...filter,
                    filterByNumberOfMembersSupport: false,
                    filterByNumberOfMembersAgainst: false,
                });
            const fieldToDefault =
                filterName === 'filterByNumberOfMembersSupport'
                    ? 'filterByNumberOfMembersAgainst'
                    : 'filterByNumberOfMembersSupport';
            if (!filter[filterName])
                return setFilter({
                    ...filter,
                    [filterName]: true,
                    [fieldToDefault]: false,
                });
        },
        [setFilter, filter],
    );

    const handleUpdateOrderByNumberOfTokensFilter = useCallback(
        (filterName: keyof IFilterState) => {
            mixpanelService.track(mixpanelService.eventsGenerator.brainStormFilter('filterByNumberOfTokensFilter'));

            if (filter[filterName])
                return setFilter({
                    ...filter,
                    filterByTokenBalanceSupport: false,
                    filterByTokenBalanceAgainst: false,
                });
            const fieldToDefault =
                filterName === 'filterByTokenBalanceSupport'
                    ? 'filterByTokenBalanceAgainst'
                    : 'filterByTokenBalanceSupport';
            if (!filter[filterName])
                return setFilter({
                    ...filter,
                    [filterName]: true,
                    [fieldToDefault]: false,
                });
        },
        [setFilter, filter],
    );

    const handleUpdateMembersFilter = useCallback(
        ({ address, logo, name }: { address: string; logo: string | null; name: string | null }) => {
            mixpanelService.track(mixpanelService.eventsGenerator.brainStormFilter('filterByDiscussionsCreators'));

            const isMemberAlreadyIncluded = filter.members.find(
                (obj: IDiscussionsFilterMember) => obj.address === address,
            );

            if (isMemberAlreadyIncluded)
                return setFilter({
                    ...filter,
                    members: filter.members.filter((member: IDiscussionsFilterMember) => member.address !== address),
                });
            setFilter({ ...filter, members: _.uniq([...filter.members, { address, logo, name }]) });
        },
        [setFilter, filter],
    );

    const handleUpdateSubstormsFilter = useCallback(
        (substormData: IOrganizationSubstorm) => {
            const isSubstormAlreadyIncluded = filter.substorms.find(
                (obj: IOrganizationSubstorm) => obj.id === substormData.id,
            );

            if (isSubstormAlreadyIncluded)
                return setFilter({
                    ...filter,
                    substorms: filter.substorms.filter(
                        (substorm: IOrganizationSubstorm) => substorm.id !== substormData.id,
                    ),
                });
            setFilter({ ...filter, substorms: _.uniq([...filter.substorms, substormData]) });
        },
        [setFilter, filter],
    );

    const handleSearchMembers = useCallback(
        _.debounce((value: string) => {
            dispatch(
                OrganizationActions.getUserOrganizationDiscussionsMembers.request({
                    organizationId,
                    search: value,
                }),
            );
        }, 700),
        [dispatch, OrganizationActions, organizationId],
    );

    useEffect(() => {
        handleSearchMembers(membersSearch);
    }, [membersSearch]);

    useEffect(() => {
        handleSetDiscussionsFilter(filter);
    }, [filter]);

    const onInputClick = useCallback(
        (inputOpen: boolean) => {
            setIsInputOpen(inputOpen);
            if (datesListOpen) {
                setDatesListOpen(false);
            }
        },
        [datesListOpen, isInputOpen],
    );

    const onDateListClick = useCallback(
        (datesListOpen: boolean) => {
            setDatesListOpen(datesListOpen);
            if (isInputOpen) {
                setIsInputOpen(false);
            }
        },
        [isInputOpen, datesListOpen],
    );

    return isMobile ? (
        <MobileFilters
            handleUpdateOrderFilter={handleUpdateOrderFilter}
            filter={filter}
            handleUpdateOrderByTotalVotersFilter={handleUpdateOrderByTotalVotersFilter}
            isLogged={isLogged}
            handleUpdateOnlyMyFilter={handleUpdateOnlyMyFilter}
            dateRangePickerSelectedDates={dateRangePickerSelectedDates}
            handleUpdateFilterDates={handleUpdateFilterDates}
            handleOpenDateRangePicker={handleOpenDateRangePicker}
            isDateRangePickerOpen={isDateRangePickerOpen}
            membersSearch={membersSearch}
            setMembersSearch={setMembersSearch}
            isInputOpen={isInputOpen}
            discussionsFilterMembers={discussionsFilterMembers}
            onInputClick={onInputClick}
            handleUpdateMembersFilter={handleUpdateMembersFilter}
            handleUpdateOrderByNumberOfMembersFilter={handleUpdateOrderByNumberOfMembersFilter}
            handleUpdateOrderByNumberOfTokensFilter={handleUpdateOrderByNumberOfTokensFilter}
            getFormattedDate={getFormattedDate}
            dateFilterBreakPoints={dateFilterBreakPoints}
            substormsList={substormsList}
            handleUpdateSubstormsFilter={handleUpdateSubstormsFilter}
            substormsListOpen={substormsListOpen}
            setSubstormsListOpen={() => setSubstormsListOpen(!substormsListOpen)}
        />
    ) : (
        <DesktopFilters
            handleUpdateOrderFilter={handleUpdateOrderFilter}
            filter={filter}
            handleUpdateOrderByTotalVotersFilter={handleUpdateOrderByTotalVotersFilter}
            isLogged={isLogged}
            handleUpdateOnlyMyFilter={handleUpdateOnlyMyFilter}
            onDateListClick={onDateListClick}
            datesListOpen={datesListOpen}
            dateRangePickerSelectedDates={dateRangePickerSelectedDates}
            handleUpdateFilterDates={handleUpdateFilterDates}
            handleOpenDateRangePicker={handleOpenDateRangePicker}
            isDateRangePickerOpen={isDateRangePickerOpen}
            dateFilterBreakPoints={dateFilterBreakPoints}
            membersSearch={membersSearch}
            setMembersSearch={setMembersSearch}
            isInputOpen={isInputOpen}
            discussionsFilterMembers={discussionsFilterMembers}
            onInputClick={onInputClick}
            handleUpdateMembersFilter={handleUpdateMembersFilter}
            handleUpdateOrderByNumberOfMembersFilter={handleUpdateOrderByNumberOfMembersFilter}
            handleUpdateOrderByNumberOfTokensFilter={handleUpdateOrderByNumberOfTokensFilter}
            getFormattedDate={getFormattedDate}
            substormsList={substormsList}
            handleUpdateSubstormsFilter={handleUpdateSubstormsFilter}
            substormsListOpen={substormsListOpen}
            setSubstormsListOpen={() => setSubstormsListOpen(!substormsListOpen)}
        />
    );
};

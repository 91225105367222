import React, { FC, ReactElement } from 'react';

import { isEmpty } from 'lodash';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { DotsLoader } from 'components';

import { PAGES_TYPES } from 'constants/pagesTypes';

import { useAppSelector } from 'store';
import { LinkUtils } from 'utils';
import { arrowPurpleIcon, defaultOrganizationPlaceholderImage } from 'assets';

import { IFeedOrganizationSearchedData, IFeedSelectedItem, IOrganizationsAutocompleteProps } from 'types/interfaces';

export const OrganizationsAutocomplete: FC<IOrganizationsAutocompleteProps> = ({
    organizationSelect,
    selectedItems,
    removeSelectedOrganization,
    inputActive,
}: IOrganizationsAutocompleteProps): ReactElement => {
    const { feedOrganizations, loading } = useAppSelector(({ feed }) => feed);

    return (
        <div className={classNames(styles.autocompleteWrapper, { [styles.autocompleteVisible]: inputActive })}>
            <div className={styles.autocompleteContainer}>
                {loading && <DotsLoader />}
                {!loading && !feedOrganizations.length && <p className={styles.notFoundFeeds}>not found</p>}
                {feedOrganizations.map(({ id, logo, title }: IFeedOrganizationSearchedData) => {
                    const organizationLogo = LinkUtils.isValidUrl(logo)
                        ? logo
                        : LinkUtils.getDaoLogoLinkFromBucket(logo, defaultOrganizationPlaceholderImage);

                    const isOrganizationSelected = !isEmpty(
                        selectedItems.find((organization: IFeedSelectedItem) => organization.id === id),
                    );

                    return (
                        <div
                            key={id}
                            className={styles.autocompleteItem}
                            onClick={() =>
                                isOrganizationSelected
                                    ? removeSelectedOrganization(id)
                                    : organizationSelect({ id, name: title, type: PAGES_TYPES.ORGANIZATION }, true)
                            }
                        >
                            {isOrganizationSelected ? (
                                <div className={styles.hoveredCircle}>
                                    <img src={arrowPurpleIcon} className={styles.arrowImage} alt="" />
                                </div>
                            ) : (
                                <img src={organizationLogo} alt="logo" className={styles.organizationLogo} />
                            )}
                            <h5 className={styles.organizationTitle}>{title}</h5>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

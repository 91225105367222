import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { HeaderItemWithSorting } from 'components/index';

import { ORGANIZATIONS_TOKENS_PARAMS } from 'components/DaoTokensTable/constants';

import { ITableHeaderWithSortingProps } from 'types/interfaces';

export const DaoTokensTableHeader: FC<ITableHeaderWithSortingProps> = ({
    sortedParamName,
    sortByParam,
}: ITableHeaderWithSortingProps): ReactElement => (
    <div className={styles.tableHeader}>
        <div className={styles.rank}>
            <div>
                <span>rank</span>
            </div>
        </div>
        <div className={styles.tokenName}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="token name"
                sortedParamName={sortedParamName}
                paramName={ORGANIZATIONS_TOKENS_PARAMS.TOKEN_NAME_PARAM}
            />
        </div>
        <div className={styles.symbol}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="symbol"
                sortedParamName={sortedParamName}
                paramName={ORGANIZATIONS_TOKENS_PARAMS.SYMBOL_PARAM}
            />
        </div>
        <div className={styles.balance}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="balance USD"
                sortedParamName={sortedParamName}
                paramName={ORGANIZATIONS_TOKENS_PARAMS.BALANCE_USD_PARAM}
            />
        </div>
        <div className={styles.organizations}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="organizations"
                sortedParamName={sortedParamName}
                paramName={ORGANIZATIONS_TOKENS_PARAMS.ORGANIZATIONS_PARAM}
            />
        </div>
    </div>
);

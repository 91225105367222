import { axiosInstance } from 'services';
import { AxiosResponse } from 'axios';

import { API_KEY } from 'constants/apis';

import {
    IAvgVotesAndTokenPrice,
    IOrganizationActiveDelegate,
    IOrganizationActiveVoter,
    IOrganizationHoldingToken,
    IOrganizationTab,
    IOrganizationTabResponse,
    IOrganizationTopDelegate,
    IOrganizationTopVoter,
    IOrganizationTrends,
    IOrganizationVoterGroups,
    IOrganizationVoterInterestsResponse,
    ITreasuryComposition,
    ITreasuryCompositionGraph,
    IVotingToken,
} from '../../types/interfaces';

export const OrganizationTabsApi = {
    getOrganizationTabs: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTab[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTab[]>>(
            `organization/${API_KEY}/${organizationId}/tabs`,
        ),
    getOrganizationActiveDelegates: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationActiveDelegate[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationActiveDelegate[]>>(
            `organization/${API_KEY}/${organizationId}/active_delegates`,
        ),
    getOrganizationTopDelegates: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTopDelegate[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTopDelegate[]>>(
            `organization/${API_KEY}/${organizationId}/top_delegates`,
        ),
    getOrganizationActiveVoters: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationActiveVoter[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationActiveVoter[]>>(
            `organization/${API_KEY}/${organizationId}/active_voters`,
        ),
    getOrganizationTopVoters: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTopVoter[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTopVoter[]>>(
            `organization/${API_KEY}/${organizationId}/top_voters`,
        ),
    getOrganizationProposalsTrends: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTrends[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTrends[]>>(
            `organization/${API_KEY}/${organizationId}/governance_trends/proposals`,
        ),
    getOrganizationSuccessfulProposalsTrends: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTrends[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTrends[]>>(
            `organization/${API_KEY}/${organizationId}/governance_trends/successful_proposals`,
        ),
    getOrganizationVotesTrends: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTrends[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTrends[]>>(
            `organization/${API_KEY}/${organizationId}/governance_trends/votes`,
        ),
    getOrganizationVotersTrends: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTrends[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTrends[]>>(
            `organization/${API_KEY}/${organizationId}/governance_trends/voters`,
        ),
    getOrganizationAverageVotesPerProposalTrends: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTrends[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTrends[]>>(
            `organization/${API_KEY}/${organizationId}/governance_trends/average_votes_per_proposal`,
        ),
    getOrganizationYearByYearVotes: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationTrends[]>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationTrends[]>>(
            `organization/${API_KEY}/${organizationId}/governance_trends/year_by_year`,
        ),
    getOrganizationVoterInterests: (
        organizationId: string,
        tableType: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationVoterInterestsResponse>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationVoterInterestsResponse>>(
            `organization/${API_KEY}/${organizationId}/voter_interests?tableType=${tableType}`,
        ),
    getOrganizationTreasuryTrends: (organizationId: string): Promise<AxiosResponse<IOrganizationTabResponse<any>>> =>
        axiosInstance.get<IOrganizationTabResponse<any>>(`organization/${API_KEY}/${organizationId}/treasury_trends`),
    getOrganizationVotingToken: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IVotingToken>>> =>
        axiosInstance.get<IOrganizationTabResponse<IVotingToken>>(
            `organization/${API_KEY}/${organizationId}/voting_token`,
        ),
    getOrganizationAvgVotesAndTokenPriceGraph: (
        organizationId: string,
    ): Promise<AxiosResponse<IAvgVotesAndTokenPrice>> =>
        axiosInstance.get<IAvgVotesAndTokenPrice>(
            `organization/${API_KEY}/${organizationId}/token_price_vs_votes_graph`,
        ),
    getOrganizationTreasuryComposition: (organizationId: string): Promise<AxiosResponse<ITreasuryComposition[]>> =>
        axiosInstance.get<ITreasuryComposition[]>(`organization/${API_KEY}/${organizationId}/treasury_breakdown`),
    getOrganizationTreasuryCompositionGraph: (
        organizationId: string,
    ): Promise<AxiosResponse<ITreasuryCompositionGraph>> =>
        axiosInstance.get<ITreasuryCompositionGraph>(
            `organization/${API_KEY}/${organizationId}/treasury_composition_graph`,
        ),
    getOrganizationHoldingToken: (organizationId: string): Promise<AxiosResponse<IOrganizationHoldingToken[]>> =>
        axiosInstance.get<IOrganizationHoldingToken[]>(
            `organization/${API_KEY}/${organizationId}/organizations_holding_token`,
        ),
    getOrganizationVoterGroups: (
        organizationId: string,
    ): Promise<AxiosResponse<IOrganizationTabResponse<IOrganizationVoterGroups>>> =>
        axiosInstance.get<IOrganizationTabResponse<IOrganizationVoterGroups>>(
            `organization/${API_KEY}/${organizationId}/voter_groups`,
        ),
};

import React, { FC, ReactElement, useEffect } from 'react';

import styles from './styles.module.scss';

import { StatsBarGraph, StatsCard, StatsGraphCard, PeopleTables, Loader, TopPeopleAUMCard } from 'components';

import { GRAPH_TYPES } from 'constants/organizationsGraphNames';

import { useAppSelector } from 'store';
import { mixpanelService } from 'services';
import { IPeopleStatsProps } from 'types/interfaces';

const PEOPLE_AUM_GRAPH_INFO = 'Top 100 People';
const TOP_TOKENS_GRAPH_INFO = 'By number of holders';

export const PeoplePage: FC = (): ReactElement => {
    const peopleAppData = useAppSelector(({ people }) => people);

    const { loading, peopleData, lastUpdateDate, organizationsCount, enrichedOrganizationsCount } = peopleAppData;

    useEffect(() => mixpanelService.track(mixpanelService.eventsGenerator.peopleEvent()), []);

    return (
        <>
            {!loading || (!peopleData.length && <Loader />)}

            <>
                <StatsCard
                    lastUpdateDate={lastUpdateDate}
                    organizationsCount={organizationsCount}
                    enrichedOrganizationsCount={enrichedOrganizationsCount}
                    title="People"
                    isPeoplePage
                    className={styles.peopleStatsCard}
                    externalLink
                    mobileBanner
                />

                <PeopleTables />
            </>
        </>
    );
};

const PeopleStats = (props: IPeopleStatsProps) => {
    return (
        <div className={styles.contentWrapper}>
            <div className={styles.statsGraphCardsWrapper}>
                <TopPeopleAUMCard data={props.totalPeopleAumChanges} />

                {!props.isMobile && (
                    <StatsGraphCard
                        title="People AUM"
                        titleComplement="By deciles"
                        graphType={GRAPH_TYPES.BAR_GRAPH}
                        className={styles.aumGraph}
                        titleFontIncreased
                    >
                        <StatsBarGraph
                            data={props.peopleAUMbyDeciles}
                            headerText={PEOPLE_AUM_GRAPH_INFO}
                            options={{
                                yAxisLabel: 'Assets in USD',
                                xAxisLabel: 'Deciles ',
                                barSize: 28,
                                heigth: 170,
                                barLabelStyles: styles.barLabelStyles,
                                xAxisTickStyles: styles.xAxisTickStyles,
                                customLabel: true,
                            }}
                        />
                    </StatsGraphCard>
                )}

                <StatsGraphCard
                    title="Top tokens"
                    graphType={GRAPH_TYPES.BAR_GRAPH}
                    className={styles.topTokensGraph}
                    titleFontIncreased
                >
                    <StatsBarGraph
                        data={props.topTokensByPeople}
                        headerText={TOP_TOKENS_GRAPH_INFO}
                        options={{
                            yAxisLabel: 'USD Value',
                            barSize: 32,
                            heigth: 185,
                            barLabelStyles: styles.barLabelStyles,
                            xAxisTickStyles: styles.xAxisTickStyles,
                            customLabel: true,
                        }}
                    />
                </StatsGraphCard>
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { HeaderItemWithSorting } from 'components';

import { DAO_EXPERIENCE_PARAMS } from '../../constants';

import { ITableHeaderWithSortingProps } from 'types/interfaces';

export const TableHeader: FC<ITableHeaderWithSortingProps> = ({
    sortedParamName,
    sortByParam,
}: ITableHeaderWithSortingProps): ReactElement => (
    <div className={styles.tableHeaderWrapper}>
        <div className={styles.titleSection}>
            <p className={styles.tableTitle}>Verified DAO Experience</p>
        </div>
        <div className={styles.tableHeaderContainer}>
            <div className={styles.organization}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="organization"
                    sortedParamName={sortedParamName}
                    paramName={DAO_EXPERIENCE_PARAMS.USER_ORGANIZATION_PARAM}
                />
            </div>
            {/* <div className={styles.votingPower}>
            voting
            <br />
            power
        </div> */}
            <div className={styles.votes}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="votes"
                    sortedParamName={sortedParamName}
                    paramName={DAO_EXPERIENCE_PARAMS.USER_VOTES_PARAM}
                />
            </div>
            <div className={styles.successfulVotes}>
                <div>
                    <span>
                        successful
                        <br />
                        votes
                    </span>
                </div>
            </div>
            <div className={styles.proposals}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="proposals"
                    sortedParamName={sortedParamName}
                    paramName={DAO_EXPERIENCE_PARAMS.USER_PROPOSALS_PARAM}
                />
            </div>
            <div className={styles.successProposals}>
                <div>
                    <span>
                        successful
                        <br />
                        proposals
                    </span>
                </div>
            </div>
        </div>
    </div>
);

import React, { ReactElement, FC, useContext, useEffect } from 'react';

import { useMediaQuery } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import styles from './styles.module.scss';

import { DaoElementsCardItems, CarouselWrapper } from 'components';
import { AssetsTable, FundsGraph, CurrenciesTable } from './components';

import { RESEARCH } from 'constants/organizationTabs';

import { useAppDispatch, useAppSelector } from 'store';
import { LinkUtils, FormatUtils } from 'utils';
import { defaultOrganizationPlaceholderImage } from 'assets';
import { mixpanelService } from 'services';
import { OrganizationContext } from 'context/organizationContext';

import { IOrganizationSimilarDao } from 'types/interfaces';
import { OrganizationActions } from 'store/actions';

const MAX_DESCRIPTION_LENGTH = 150;

export const OrganizationFinance: FC = (): ReactElement => {
    const isTablet = useMediaQuery('(max-width: 767.98px)');
    const {
        organizationSimilarDaos,
        similarDaosLoading,
        organizationData: { description, id: organizationId },
    } = useAppSelector(({ organization }) => organization);

    const { changeOrganizationDataTab } = useContext(OrganizationContext);

    const handleSimilarOrganizations = () =>
        mixpanelService.track(mixpanelService.eventsGenerator.similarOrganizationsClickEvent());

    const shortDescription = FormatUtils.truncateString(description || '', MAX_DESCRIPTION_LENGTH);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(OrganizationActions.getOrganizationSimilarDaosData.request({ organizationId }));
    }, [organizationId]);

    return (
        <div className={styles.container}>
            {description && isTablet && (
                <div className={styles.mobileDescriptionSection}>
                    <div className={styles.textDescription} dangerouslySetInnerHTML={{ __html: shortDescription }} />
                    {description.length > MAX_DESCRIPTION_LENGTH && (
                        <div className={styles.readMore} onClick={() => changeOrganizationDataTab(RESEARCH)}>
                            <span className={styles.text}>Read More</span>
                            <ArrowForwardIcon className={styles.arrowIcon} />
                        </div>
                    )}
                </div>
            )}

            <div className={styles.carouselsWrapper} onClick={handleSimilarOrganizations}>
                <CarouselWrapper
                    data={organizationSimilarDaos}
                    loading={similarDaosLoading}
                    title="Similar organizations by number of mutual voters"
                    wrapperClassName={styles.carouselWrapper}
                >
                    {organizationSimilarDaos.map((data: IOrganizationSimilarDao) => (
                        <DaoElementsCardItems
                            key={data.secondOrganizationId}
                            logo={
                                LinkUtils.isValidUrl(data.secondOrganizationLogo || '')
                                    ? data.secondOrganizationLogo
                                    : LinkUtils.getDaoLogoLinkFromBucket(
                                          data.secondOrganizationLogo,
                                          defaultOrganizationPlaceholderImage,
                                      )
                            }
                            link={LinkUtils.createDynamicLink(data.secondOrganizationId, 'organization')}
                            name={data.secondOrganizationTitle}
                            members={data.numberOfCommonVoters || 0}
                        />
                    ))}
                </CarouselWrapper>
            </div>

            <div className={styles.daoInfo}>
                <div className={styles.currenciesContainer}>
                    <CurrenciesTable />
                </div>

                <div className={styles.daosCardsContainer}>
                    <div className={styles.graphContainer}>
                        <FundsGraph />
                    </div>
                    <div className={styles.assetsInfoContainer}>
                        <AssetsTable />
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import { FormatUtils } from 'utils';

import { IRechartsCustomizedLabelProps } from 'types/interfaces';

const MAX_VALUE_SYMBOLS = 8;

export const RechartsCustomizedAxisTick: FC<IRechartsCustomizedLabelProps> = (
    props: IRechartsCustomizedLabelProps,
): ReactElement => {
    const { x, y, offsetY, offsetX, payload, className, formatValue } = props;

    const value = payload?.value || 0;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={offsetY} dx={offsetX} textAnchor="middle" className={className}>
                {formatValue
                    ? FormatUtils.formatNumberShort(value, 1)
                    : value.toString().length >= MAX_VALUE_SYMBOLS
                    ? FormatUtils.truncateString(value, MAX_VALUE_SYMBOLS)
                    : value}
            </text>
        </g>
    );
};

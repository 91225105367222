import React, { FC, ReactElement, useCallback, useContext } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { OrganizationData, OrganizationDiscussions, TabsController } from './components';

import { ORGANIZATION_DATA, DISCUSSIONS, RESEARCH } from 'constants/organizationTabs';

import { OrganizationContext } from 'context/organizationContext';

export const OrganizationPageBody: FC = (): ReactElement => {
    const { mainTabSelected, organizationDataTabSelected } = useContext(OrganizationContext);

    const renderOrganizationComponent = useCallback(() => {
        switch (mainTabSelected) {
            case ORGANIZATION_DATA:
                return <OrganizationData />;

            case DISCUSSIONS:
                return <OrganizationDiscussions />;

            default:
                return <OrganizationData />;
        }
    }, [mainTabSelected]);

    return (
        <>
            <TabsController />

            <div
                className={classNames(styles.wrapper, {
                    [styles.backgroundWhite]: organizationDataTabSelected === RESEARCH,
                })}
            >
                {renderOrganizationComponent()}
            </div>
        </>
    );
};

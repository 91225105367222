import React, { FC, ReactElement, memo } from 'react';

import classNames from 'classnames';

import { Button } from '@material-ui/core';

import styles from './styles.module.scss';

import { Loader } from 'components';

import { CIRCLE_BUTTON_BACKGROUNDS } from 'constants/buttonsContent';

import { ICircleButtonProps } from 'types/interfaces';

export const CircleButton: FC<ICircleButtonProps> = memo(
    ({
        background,
        imageSrc,
        imageStyles,
        title,
        wrapperClass,
        onClick,
        loading,
    }: ICircleButtonProps): ReactElement => (
        <div className={classNames(styles.buttonWrapper, wrapperClass)}>
            <Button
                className={classNames(styles.circleButton, {
                    [styles.backgroundGradient]: background === CIRCLE_BUTTON_BACKGROUNDS.GRADIENT,
                    [styles.backgroundGreen]: background === CIRCLE_BUTTON_BACKGROUNDS.GREEN,
                })}
                onClick={onClick}
            >
                {loading ? (
                    <Loader className={styles.loader} size={30} />
                ) : (
                    <img src={imageSrc} className={imageStyles} alt="" />
                )}
            </Button>

            {title && <h6 className={styles.title}>{title}</h6>}
        </div>
    ),
);

enum ALERT_TYPES {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    INFO = 'INFO',
    NOTIFICATION = 'NOTIFICATION',
}

enum SUCCESS_MESSAGES {
    FEEDBACK_SUCCESS = 'Thank you, Feedback form sent successfully',
    SUCCESFULLY_COPIED = 'Successfully copied',
    EMAIL_SUCCESSFULLY_UPDATED = 'Email successfully updated',
    CREDIT_CARD_SUCCESSFULLY_UPDATED = 'Credit card successfully updated ',
    SUBSCRIPTION_CANCELED = 'Subscription has been cancelled',
    PREMIUM_LOGOUT_SUCCESS = 'Signed out of the account',
}

enum ERROR_MESSAGES {
    AVATAR_SIZE_LIMIT = 'Error: image size exceeds limit of 2MB',
    SOMETHING_WENT_WRONG = 'Something went wrong',
    PAYMENT_FAILED = 'Payment failed',
}

enum NOTIFICATION_MESSAGES {
    NOT_MY_ORGANIZATION = 'To participate in brainstorming you must be logged in, and hold the organization token',
}

export { ALERT_TYPES, SUCCESS_MESSAGES, ERROR_MESSAGES, NOTIFICATION_MESSAGES };

import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services';

import { IFollowPeopleData, IFollowOrganizationData, IFollowingAmountData } from 'types/interfaces';
import { getDeepDAOTokenFromStorage } from 'web3/storageHelper';

export const SocialFeaturesApi = {
    getFollowedPeople: (): Promise<AxiosResponse<IFollowPeopleData>> =>
        axiosInstance.get<IFollowPeopleData>('social-features/followed-people', {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    getFollowingOrganizations: (): Promise<AxiosResponse<IFollowOrganizationData>> =>
        axiosInstance.get<IFollowOrganizationData>('social-features/following-organizations', {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    getFollowingPeople: (): Promise<AxiosResponse<IFollowPeopleData>> =>
        axiosInstance.get<IFollowPeopleData>('social-features/following-people', {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    followPerson: (following: string): Promise<AxiosResponse> =>
        axiosInstance.post(`social-features/follows-person/${following}`, null, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    followOrganization: (organizationId: string): Promise<AxiosResponse> =>
        axiosInstance.post(`social-features/follows-organization/${organizationId}`, null, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    unfollowPerson: (following: string): Promise<AxiosResponse> =>
        axiosInstance.delete(`social-features/unfollows-person/${following}`, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    unfollowOrganization: (organizationId: string): Promise<AxiosResponse> =>
        axiosInstance.delete(`social-features/unfollows-organization/${organizationId}`, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    getFollowingAmountData: (): Promise<AxiosResponse<IFollowingAmountData>> =>
        axiosInstance.get('social-features/data', {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    removePerson: (following: string): Promise<AxiosResponse> =>
        axiosInstance.put(`social-features/blocks/${following}`, null, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    togglePersonNotification: (organizationId: string, enabledNotification: boolean): Promise<AxiosResponse> =>
        axiosInstance.put(
            `social-features/following-notifications/${organizationId}?enabledNotification=${enabledNotification}`,
            null,
            {
                headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
            },
        ),
    toggleOrganizationNotification: (organizationId: string, enabledNotification: boolean): Promise<AxiosResponse> =>
        axiosInstance.put(
            `social-features/organization-notifications/${organizationId}?enabledNotification=${enabledNotification}`,
            null,
            {
                headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
            },
        ),
};

import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

import { ICommonTableHeader } from 'components/CommonTable/types';

interface ITableHeaderProps<T, K extends string> {
    tableHeaderData: ICommonTableHeader<T, K>[];
}

export const TableHeader = <T, K extends string>({ tableHeaderData }: ITableHeaderProps<T, K>): ReactElement => {
    return (
        <div className={styles.tableHeader}>
            {tableHeaderData.map((headerItems: ICommonTableHeader<T, K>) => (
                <div
                    key={headerItems.title + headerItems.sortedParamName}
                    style={{ width: headerItems.settings.width.desktop }}
                >
                    <div>
                        <span>{headerItems.title}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';

import { AutocompleteField } from './components';

import { debounce } from 'lodash';
import { useAppDispatch } from 'store';
import { SearchActions } from 'store/actions';
import { mixpanelService } from 'services';

import { ISearchContainerProps } from 'types/interfaces';
import { ISearchSelectOptions, searchSelectsList } from './components/AutocompleteField/constants';

export const SearchContainer: FC<ISearchContainerProps> = ({
    inputOpen,
    setInputOpen,
}: ISearchContainerProps): ReactElement => {
    const [inputValue, setInputValue] = useState('');
    const [selectedSearchType, setSelectedSearchType] = useState<ISearchSelectOptions>(searchSelectsList[0]);
    const dispatch = useAppDispatch();

    const debounceSearch = useCallback(
        debounce((inputValue: string, paramName: string | undefined) => {
            if (inputValue) {
                dispatch(
                    SearchActions.search.request({
                        value: inputValue,
                        include: paramName,
                    }),
                );

                mixpanelService.track(mixpanelService.eventsGenerator.searchInputEvent(inputValue));
            } else dispatch({ type: SearchActions.CLEAR_SEARCH });
        }, 700),
        [],
    );

    useEffect(() => {
        debounceSearch(
            inputValue,
            Array.isArray(selectedSearchType) ? selectedSearchType[0].value : selectedSearchType.value,
        );
    }, [inputValue, selectedSearchType]);

    return (
        <AutocompleteField
            inputValue={inputValue}
            setInputValue={setInputValue}
            inputOpen={inputOpen}
            setInputOpen={setInputOpen}
            selectedSearchType={selectedSearchType}
            setSelectedSearchType={setSelectedSearchType}
        />
    );
};

import { createApiAction } from 'helpers';

export const GET_SELF_LISTING_DATA = 'GET_SELF_LISTING_DATA';
export const GET_SELF_LISTING_DATA_SUCCESS = 'GET_SELF_LISTING_DATA_SUCCESS';
export const GET_SELF_LISTING_DATA_FAILURE = 'GET_SELF_LISTING_DATA_FAILURE';

export const LIST_ORGANIZATION = 'LIST_ORGANIZATION';
export const LIST_ORGANIZATION_SUCCESS = 'LIST_ORGANIZATION_SUCCESS';
export const LIST_ORGANIZATION_FAILURE = 'LIST_ORGANIZATION_FAILURE';

export const GET_LISTED_ORGANIZATION_DATA = 'GET_LISTED_ORGANIZATION_DATA';
export const GET_LISTED_ORGANIZATION_DATA_SUCCESS = 'GET_LISTED_ORGANIZATION_DATA_SUCCESS';
export const GET_LISTED_ORGANIZATION_DATA_FAILURE = 'GET_LISTED_ORGANIZATION_DATA_FAILURE';

export const TOGGLE_SELF_LISTING_MODAL_OPEN = 'TOGGLE_SELF_LISTING_MODAL_OPEN';
export const CLEAR_SELF_LISTING_ERROR = 'CLEAR_SELF_LISTING_ERROR';

export const getSelfListingData = createApiAction(GET_SELF_LISTING_DATA);
export const listOrganization = createApiAction(LIST_ORGANIZATION);
export const getListedOrganizationData = createApiAction(GET_LISTED_ORGANIZATION_DATA);

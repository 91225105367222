import React, { FC, ReactElement, useEffect } from 'react';

import styles from './styles.module.scss';

import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { DELEGATES, ORGANIZATION_VOTER_INTERESTS_TYPES } from '../../../../../../constants/organizationTabs';
import { PremiumTab } from '../../../../../PremiumTab';
import { OrganizationActions, OrganizationTabsActions } from 'store/actions';
import { OVICommonTable } from './components/OVICommonTable';
import {
    organizationVIBreakdownOrgHeaders,
    organizationVICompetitorTokenHoldersHeaders,
    organizationVICompetitorVotersHeaders,
    organizationVIVoterEcosystemParticipationHeaders,
    organizationVIVoterRanksHeaders,
} from './constants/organization-voter-interests.constants';
import { IOrganizationSimilarDao } from '../../../../../../types/interfaces';
import { DaoElementsCardItems } from '../../../../../DaoElementsCardItems';
import { LinkUtils } from '../../../../../../utils';
import { defaultOrganizationPlaceholderImage } from '../../../../../../assets';
import { mixpanelService } from '../../../../../../services';
import { InfoCircle } from 'components';
import { CarouselWrapperWithNewDesign } from '../../../../../CarouselWrapperWithNewDesign';
import { VoterGroupsTable } from './components/VoterGroupsTable';
import { ECOSYSTEM_TAB, ECOSYSTEM_TAB_DESCRIPTIONS } from '../../../../../../constants';

export const OrganizationEcosystem: FC = (): ReactElement => {
    const {
        organizationTabs,
        oviBreakdownOrg,
        oviCompetitorDaosForOrgVoters,
        oviCompetitorDaosForOrgTokenHolders,
        oviVoterEcosystemParticipation,
        oviVoterRanks,
        organizationVoterGroups,
    } = useAppSelector(({ organizationTabs }) => organizationTabs);

    const isTabBlocked = organizationTabs.data.find((t) => t.tab.name === DELEGATES)?.isBlocked;
    const { organizationData, organizationSimilarDaos, similarDaosLoading } = useAppSelector(
        ({ organization }) => organization,
    );
    const organizationId = organizationData.id;

    const handleSimilarOrganizations = () =>
        mixpanelService.track(mixpanelService.eventsGenerator.similarOrganizationsClickEvent());

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(
            OrganizationTabsActions.getOrganizationVoterInterests.request({
                organizationId,
                tableType: ORGANIZATION_VOTER_INTERESTS_TYPES.VOTER_RANKS,
            }),
        );
        dispatch(
            OrganizationTabsActions.getOrganizationVoterInterests.request({
                organizationId,
                tableType: ORGANIZATION_VOTER_INTERESTS_TYPES.VOTER_ECOSYSTEM_PARTICIPATION,
            }),
        );
        dispatch(
            OrganizationTabsActions.getOrganizationVoterInterests.request({
                organizationId,
                tableType: ORGANIZATION_VOTER_INTERESTS_TYPES.BREAKDOWN_ECOSYSTEM,
            }),
        );
        dispatch(
            OrganizationTabsActions.getOrganizationVoterInterests.request({
                organizationId,
                tableType: ORGANIZATION_VOTER_INTERESTS_TYPES.BREAKDOWN_ORG,
            }),
        );
        dispatch(
            OrganizationTabsActions.getOrganizationVoterInterests.request({
                organizationId,
                tableType: ORGANIZATION_VOTER_INTERESTS_TYPES.COMPETITOR_DAOS_FOR_ORG_VOTERS,
            }),
        );
        dispatch(
            OrganizationTabsActions.getOrganizationVoterInterests.request({
                organizationId,
                tableType: ORGANIZATION_VOTER_INTERESTS_TYPES.COMPETITOR_DAOS_FOR_ORG_TOKEN_HOLDERS,
            }),
        );
        dispatch(OrganizationActions.getOrganizationSimilarDaosData.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationVoterGroups.request({ organizationId }));
    }, [organizationId]);

    return (
        <div className={styles.container}>
            {isTabBlocked ? (
                <div className={styles.premiumWindow}>
                    <PremiumTab title="Voter Interests is a DeepDAO premium tab for this organization" />
                </div>
            ) : (
                <div className={styles.content}>
                    <div className={styles.carouselContainer} onClick={handleSimilarOrganizations}>
                        <div className={styles.title}>
                            <h4 className={styles.titleText}>{'Similar organizations by number of mutual voters'}</h4>
                            <InfoCircle
                                tooltipText={ECOSYSTEM_TAB_DESCRIPTIONS[ECOSYSTEM_TAB.SIMILAR_ORGS]}
                                className={styles.infoIcon}
                            />
                        </div>
                        <div className={styles.carousel}>
                            <CarouselWrapperWithNewDesign
                                data={organizationSimilarDaos}
                                loading={similarDaosLoading}
                                wrapperClassName={styles.carouselWrapper}
                            >
                                {organizationSimilarDaos.map((data: IOrganizationSimilarDao) => (
                                    <DaoElementsCardItems
                                        key={data.secondOrganizationId}
                                        logo={
                                            LinkUtils.isValidUrl(data.secondOrganizationLogo || '')
                                                ? data.secondOrganizationLogo
                                                : LinkUtils.getDaoLogoLinkFromBucket(
                                                      data.secondOrganizationLogo,
                                                      defaultOrganizationPlaceholderImage,
                                                  )
                                        }
                                        link={LinkUtils.createDynamicLink(data.secondOrganizationId, 'organization')}
                                        name={data.secondOrganizationTitle}
                                        members={data.numberOfCommonVoters || 0}
                                    />
                                ))}
                            </CarouselWrapperWithNewDesign>
                        </div>
                    </div>
                    <div className={styles.voterGroupsTable}>
                        <VoterGroupsTable
                            organizationVoterGroups={organizationVoterGroups.data}
                            loading={organizationVoterGroups.loading}
                        ></VoterGroupsTable>
                    </div>
                    <div className={styles.tablesWrapper}>
                        <OVICommonTable
                            title="Voter Ecosystem Participation"
                            infoText={ECOSYSTEM_TAB_DESCRIPTIONS[ECOSYSTEM_TAB.VOTER_ECOSYSTEM_PARTICIPATION]}
                            lastUpdate={oviVoterEcosystemParticipation.lastUpdateDate}
                            tableRowsData={oviVoterEcosystemParticipation.data}
                            loading={oviVoterEcosystemParticipation.loading}
                            tableHeaderData={organizationVIVoterEcosystemParticipationHeaders}
                        />
                        <OVICommonTable
                            title={`Voter Interests Breakdown`}
                            infoText={ECOSYSTEM_TAB_DESCRIPTIONS[ECOSYSTEM_TAB.VOTERS_INTERESTS_BREAKDOWN]}
                            lastUpdate={oviBreakdownOrg.lastUpdateDate}
                            tableRowsData={oviBreakdownOrg.data}
                            loading={oviBreakdownOrg.loading}
                            tableHeaderData={organizationVIBreakdownOrgHeaders}
                        />

                        <OVICommonTable
                            title="Voter Ranks"
                            infoText={ECOSYSTEM_TAB_DESCRIPTIONS[ECOSYSTEM_TAB.VOTER_RANKS]}
                            lastUpdate={oviVoterRanks.lastUpdateDate}
                            tableRowsData={oviVoterRanks.data}
                            loading={oviVoterRanks.loading}
                            tableHeaderData={organizationVIVoterRanksHeaders}
                        />
                        <OVICommonTable
                            title={`Competitor DAOs for ${organizationData.name} Voters`}
                            infoText={ECOSYSTEM_TAB_DESCRIPTIONS[ECOSYSTEM_TAB.COMPETITOR_DAOS_FOR_VOTERS]}
                            lastUpdate={oviCompetitorDaosForOrgVoters.lastUpdateDate}
                            tableRowsData={oviCompetitorDaosForOrgVoters.data}
                            loading={oviCompetitorDaosForOrgVoters.loading}
                            tableHeaderData={organizationVICompetitorVotersHeaders}
                        />

                        <OVICommonTable
                            title={`Competitor DAOs for ${organizationData.name} Token Holders`}
                            infoText={ECOSYSTEM_TAB_DESCRIPTIONS[ECOSYSTEM_TAB.COMPETITOR_DAOS_FOR_TOKEN_HOLDERS]}
                            lastUpdate={oviCompetitorDaosForOrgTokenHolders.lastUpdateDate}
                            tableRowsData={oviCompetitorDaosForOrgTokenHolders.data}
                            loading={oviCompetitorDaosForOrgTokenHolders.loading}
                            tableHeaderData={organizationVICompetitorTokenHoldersHeaders}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

import React, { FC, useRef } from 'react';

import { Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { Loader, TableDataNotFound } from 'components';

import { DEFAULT_SCROLL_DISTANCE, DEFAULT_SCROLL_SPEED, DEFAULT_STEP } from './constants';

import { useSideScroll } from 'hooks';

import { ICarouselWrapperProps } from 'types/interfaces';

export const CarouselWrapper: FC<ICarouselWrapperProps> = ({
    children,
    data,
    loading,
    title,
    wrapperClassName,
    containerClassName,
}: ICarouselWrapperProps) => {
    const carouselRef = useRef<null | HTMLDivElement>(null);
    const wrapperRef = useRef<null | HTMLDivElement>(null);

    return (
        <div className={classNames(styles.carouselWrapper, wrapperClassName)}>
            <h4 className={styles.title}>{title}</h4>
            {data.length > 0 && (
                <>
                    <Button
                        onClick={() => {
                            useSideScroll(
                                carouselRef.current,
                                DEFAULT_SCROLL_SPEED,
                                DEFAULT_SCROLL_DISTANCE,
                                -DEFAULT_STEP,
                            );
                        }}
                        className={classNames(styles.arrowButton, styles.previous)}
                    >
                        <ExpandMoreIcon />
                    </Button>
                    <Button
                        onClick={() => {
                            useSideScroll(
                                carouselRef.current,
                                DEFAULT_SCROLL_SPEED,
                                DEFAULT_SCROLL_DISTANCE,
                                DEFAULT_STEP,
                            );
                        }}
                        className={classNames(styles.arrowButton, styles.next)}
                    >
                        <ExpandMoreIcon />
                    </Button>
                </>
            )}
            {data.length > 0 && (
                <div className={classNames(styles.carouselContainer, containerClassName)} ref={wrapperRef}>
                    <div className={styles.shadowBlock} />
                    <div className={classNames(styles.shadowBlock, styles.shadowBlockRight)} />
                    <div className={styles.itemsList} ref={carouselRef}>
                        {children}
                    </div>
                </div>
            )}
            {loading && data.length === 0 && (
                <div className={styles.loaderBox}>
                    <Loader size={40} className={styles.similarUsersLoader} />
                </div>
            )}
            {data.length === 0 && !loading && <TableDataNotFound forTable overflowMarginsSecondVariant />}
        </div>
    );
};

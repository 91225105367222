import React, { FC, ReactElement, memo } from 'react';

import { useMediaQuery } from '@material-ui/core';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ImageLoader } from 'components';
import { DesktopFilterSelectedElement } from '../../../DesktopFilterSelectedElement';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { IDiscussionsFilterMember, IMembersWrapperProps } from 'types/interfaces';

export const MembersWrapper: FC<IMembersWrapperProps> = memo(
    ({
        isInputOpen,
        discussionsFilterMembers,
        handleUpdateMembersFilter,
        filter,
    }: IMembersWrapperProps): ReactElement => {
        const isMobile = useMediaQuery('(max-width:575.98px)');

        return (
            <div className={styles.membersWrapper}>
                {isInputOpen && (
                    <>
                        <p className={styles.header}>Most active</p>
                        <div className={styles.membersList}>
                            {discussionsFilterMembers.map(({ address, logo, name }: IDiscussionsFilterMember) => (
                                <div
                                    className={classNames(styles.member, {
                                        [styles.bold]: Boolean(
                                            filter.members.find(
                                                (member: IDiscussionsFilterMember) => member.address === address,
                                            ),
                                        ),
                                    })}
                                    key={address}
                                    onClick={() => handleUpdateMembersFilter({ address, logo, name })}
                                >
                                    <ImageLoader
                                        src={logo}
                                        className={styles.avatar}
                                        imageType={IMAGE_TYPES.USER_IMAGE}
                                    />
                                    <span className={styles.name}>{name || address}</span>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                {!isInputOpen &&
                    !isMobile &&
                    filter.members.map((member: IDiscussionsFilterMember) => (
                        <DesktopFilterSelectedElement
                            key={member.address}
                            text={member.name || member.address}
                            onRemove={() => handleUpdateMembersFilter(member)}
                        />
                    ))}
            </div>
        );
    },
);

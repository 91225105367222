import React, { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';

import { Link, useParams } from 'react-router-dom';

import LinkIcon from '@material-ui/icons/Link';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import copy from 'copy-to-clipboard';

import styles from './styles.module.scss';

import { DiscussionCardItems, Loader } from 'components';

import { ALERT_TYPES, SUCCESS_MESSAGES } from 'constants/alert';
import { getAddressFromStorage } from 'web3/storageHelper';

import { useAppDispatch, useAppSelector } from 'store';
import { OrganizationActions } from 'store/actions';
import { AlertActions } from 'store/actions';

export const OrganizationDiscussion: FC = (): ReactElement => {
    const {
        token,
        loggedUserData: { address },
    } = useAppSelector(({ auth }) => auth);
    const { discussionLoading, organizationDiscussion } = useAppSelector(({ organization }) => organization);

    const dispatch = useAppDispatch();

    const { organizationId, brainstormId } = useParams<{ organizationId: string; brainstormId: string }>();

    const isAuthenticatedUser = useMemo(() => Boolean(token) && Boolean(address), [token, address]);

    useEffect(() => {
        const isLogged = Boolean(token);

        dispatch(
            OrganizationActions.getOrganizationDiscussion.request({
                organizationId,
                brainstormId,
                address: getAddressFromStorage(),
            }),
        );

        dispatch(OrganizationActions.getOrganizationBalance.request({ organizationId, isLogged }));
    }, [token]);

    const onCopyClick = useCallback(() => {
        copy(window.location.href);

        dispatch({
            type: AlertActions.SHOW_ALERT,
            payload: {
                title: SUCCESS_MESSAGES.SUCCESFULLY_COPIED,
                type: ALERT_TYPES.SUCCESS,
            },
        });
    }, []);

    return (
        <>
            {discussionLoading ? (
                <Loader />
            ) : (
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <div className={styles.options}>
                            <Link to={`/organization/${organizationId}/brainstorm`} className={styles.brainstormLink}>
                                <ArrowForwardIcon className={styles.arrowIcon} />
                                <span className={styles.title}>brainstorm</span>
                            </Link>

                            <div className={styles.copy} onClick={onCopyClick}>
                                <LinkIcon className={styles.linkIcon} />
                                <span className={styles.title}>copy url</span>
                            </div>
                        </div>

                        <DiscussionCardItems
                            {...organizationDiscussion}
                            loggedUserAddress={address}
                            isAuthenticatedUser={isAuthenticatedUser}
                            isDiscussionSingle
                        />
                    </div>
                </div>
            )}
        </>
    );
};

import { Reducer, AnyAction } from '@reduxjs/toolkit';
import { SidebarActions } from 'store/actions';

interface ISidebarState {
    sidebarIsExpanded: boolean;
    aboutSectionOpen: boolean;
}

const defaultState: ISidebarState = {
    sidebarIsExpanded: true,
    aboutSectionOpen: false,
};

export const sidebarReducer: Reducer<ISidebarState, AnyAction> = (
    state: ISidebarState = defaultState,
    action: AnyAction,
): ISidebarState => {
    const { type } = <AnyAction>action;

    switch (type) {
        case SidebarActions.TOGGLE_SIDEBAR_OPEN: {
            return {
                ...state,
                sidebarIsExpanded: !state.sidebarIsExpanded,
            };
        }

        case SidebarActions.TOGGLE_ABOUT_OPEN: {
            return {
                ...state,
                aboutSectionOpen: !state.aboutSectionOpen,
            };
        }

        default: {
            return state;
        }
    }
};

import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';

import styles from './styles.module.scss';

import { DiscussionTooltip, ImageLoader } from 'components';

import { IMAGE_TYPES } from 'constants/imageTypes';
import { BRAINSTORM_ACTION_TYPE, DISCUSSIONS_NOTIFICATIONS } from 'constants/discussionsElements';

import { useAppSelector, useAppDispatch } from 'store';
import { OrganizationActions } from 'store/actions';

import { IStartDiscussionCardProps } from 'types/interfaces';

export const StartDiscussionCard: FC<IStartDiscussionCardProps> = ({
    disabledModal,
}: IStartDiscussionCardProps): ReactElement => {
    const [notificationOpen, setNotificationOpen] = useState<boolean>(false);

    const {
        loggedUserData: { avatar },
    } = useAppSelector(({ auth }) => auth);

    const { discussionCreatingError } = useAppSelector(({ organization }) => organization);

    const dispatch = useAppDispatch();

    const toggleModalOpen = useCallback(() => {
        if (disabledModal) {
            setNotificationOpen(true);
        } else
            dispatch({
                type: OrganizationActions.TOGGLE_DISSCUSION_MODAL_OPEN,
                payload: {
                    brainstormActionType: BRAINSTORM_ACTION_TYPE.ADDING,
                },
            });
    }, [disabledModal, dispatch]);

    useEffect(() => {
        if (discussionCreatingError && !notificationOpen) {
            setNotificationOpen(true);
        }
    }, [discussionCreatingError]);

    const clearNotificationError = useCallback(() => {
        setNotificationOpen(false);
        dispatch({
            type: OrganizationActions.CLEAR_DISCUSSION_TOKENLESS_ERROR,
            payload: {
                discussionCreatingError: false,
            },
        });
    }, [notificationOpen]);

    return (
        <>
            <div className={styles.cardWrapper}>
                {notificationOpen && (
                    <DiscussionTooltip
                        className={styles.notificationTooltip}
                        onClose={clearNotificationError}
                        text={DISCUSSIONS_NOTIFICATIONS.BRAINSTORM}
                    />
                )}

                <ImageLoader src={avatar} imageType={IMAGE_TYPES.USER_IMAGE} className={styles.image} />
                <div className={styles.contentWrapper} onClick={() => toggleModalOpen()}>
                    <span>Lets brainstorm ...</span>
                </div>
            </div>
        </>
    );
};

import React, { FC, ReactElement, useCallback, useEffect, useState, useRef } from 'react';

import classNames from 'classnames';

import { Button } from '@material-ui/core';
import NotificationIcon from '@material-ui/icons/NotificationsNone';

import styles from './styles.module.scss';

import { NotificationsListWrapper } from './components';

import { getDeepDAOTokenFromStorage } from 'web3/storageHelper';
import { NOTIFICATIONS_ACTIONS_TYPES } from 'constants/notificationsActions';

import { useOutsideClick } from 'hooks';
import { useAppSelector, useAppDispatch } from 'store';
import { UserActions } from 'store/actions';

import { IHeaderNotificationsProps } from 'types/interfaces';

const OFFSET = 0;
const LIMIT = 10;

export const HeaderNotifications: FC<IHeaderNotificationsProps> = ({
    toggleConnection,
    isLogged,
}: IHeaderNotificationsProps): ReactElement => {
    const { userNotificationsData, hasMoreNotifications, notificationsActionType } = useAppSelector(({ user }) => user);
    const dispatch = useAppDispatch();

    const ref = useRef(null);
    useOutsideClick(ref, () => setNotificationsListOpen(false));

    const [notificationsListOpen, setNotificationsListOpen] = useState<boolean>(false);
    const [offset, setOffset] = useState(OFFSET);

    const onNotificationButtonClick = useCallback(
        () => (isLogged ? setNotificationsListOpen(!notificationsListOpen) : toggleConnection()),
        [isLogged, notificationsListOpen, toggleConnection],
    );

    const loadMoreNotifications = useCallback(() => {
        const token = getDeepDAOTokenFromStorage();
        dispatch(
            UserActions.getUserNotifications.request({
                token,
                notificationsOffset: offset + LIMIT,
                notificationsActionType: NOTIFICATIONS_ACTIONS_TYPES.LAZY_LOADING_REQUEST,
            }),
        );
        setOffset(offset + LIMIT);
    }, [getDeepDAOTokenFromStorage, offset, LIMIT]);

    useEffect(() => {
        if (notificationsActionType === NOTIFICATIONS_ACTIONS_TYPES.INITIAL_REQUEST && offset !== 0) setOffset(0);
    }, [notificationsActionType]);

    return (
        <div className={styles.wrapper} ref={ref}>
            <div className={styles.notificationsButtonWrapper}>
                <Button className={styles.notificationsButton} disableRipple onClick={onNotificationButtonClick}>
                    <NotificationIcon
                        className={classNames(styles.notificationsIcon, {
                            [styles.notificationsOpen]: notificationsListOpen,
                        })}
                    />
                    {userNotificationsData.newNotifications.length > 0 && <span className={styles.circle} />}
                </Button>

                {notificationsListOpen && (
                    <NotificationsListWrapper
                        setNotificationsListOpen={setNotificationsListOpen}
                        newNotifications={userNotificationsData.newNotifications}
                        oldNotifications={userNotificationsData.oldNotifications}
                        loadMoreNotifications={loadMoreNotifications}
                        hasMoreNotifications={hasMoreNotifications}
                    />
                )}
            </div>
        </div>
    );
};

import {
    SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS,
    SEARCHED_ORGANIZATION_FILTERS_TITLES,
} from 'constants/organizationsSearch';

import {
    ISearchedOrganizationFilter,
    ISearchedOrganizationCategory,
    ISearchedOrganizationFilterQueryData,
    ISearchedOrganizationFilterTransformedQueryData,
} from 'types/interfaces';

const createOrganizationsCategoriesFilterData = (
    organizationsCategoriesData: ISearchedOrganizationCategory[],
): ISearchedOrganizationFilter[] =>
    organizationsCategoriesData.map((category: ISearchedOrganizationCategory) => ({
        id: category.category_id,
        title: category.name,
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.CATEGORIES_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.CATEGORY,
        queryData: [
            {
                key: 'category',
                value: category.category_id,
            },
        ],
    }));

const transformSelectedFiltersData = (
    selectedFiltersData: ISearchedOrganizationFilter[],
): ISearchedOrganizationFilterTransformedQueryData =>
    selectedFiltersData.reduce(
        (prevFilter: ISearchedOrganizationFilterTransformedQueryData, currentFilter: ISearchedOrganizationFilter) => {
            const formattedData = currentFilter.queryData.reduce(
                (
                    prevValue: ISearchedOrganizationFilterTransformedQueryData,
                    currentValue: ISearchedOrganizationFilterQueryData,
                ) =>
                    ({
                        ...prevValue,
                        [currentValue.key]: currentValue.value,
                    } as ISearchedOrganizationFilterTransformedQueryData),
                {},
            );

            return { ...prevFilter, ...formattedData };
        },
        {},
    );

export { createOrganizationsCategoriesFilterData, transformSelectedFiltersData };

import React, { FC, ReactElement } from 'react';

import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { FormatUtils } from 'utils';

import { ISearchedOrganization, ISearchedOrganizationFilter } from 'types/interfaces';

import styles from './styles.module.scss';

interface IHeadSectionProps {
    selectedFiltersData: ISearchedOrganizationFilter[];
    removeSelectedFilter: (filterId: string) => void;
    organizationsSearchedData: ISearchedOrganization[];
}

export const HeadSection: FC<IHeadSectionProps> = ({
    selectedFiltersData,
    removeSelectedFilter,
    organizationsSearchedData,
}: IHeadSectionProps): ReactElement => {
    return (
        <div className={styles.headWrapper}>
            <div className={styles.titleSection}>
                <h1 className={styles.title}>DAO Search</h1>
            </div>
            <div className={styles.selectedFiltersWrapper}>
                {selectedFiltersData.map((filter: ISearchedOrganizationFilter) => (
                    <div className={styles.selectedFilter} key={filter.id}>
                        <h6 className={styles.filterName}>{`${filter.relatedFieldTitle}: ${filter.title}`}</h6>
                        <Button className={styles.removeFilterButton} onClick={() => removeSelectedFilter(filter.id)}>
                            <CloseIcon className={styles.removeFilterIcon} />
                        </Button>
                    </div>
                ))}
                <div className={styles.searchResultsBlock}>
                    <h5 className={styles.searchResultsCount}>{`${FormatUtils.formatNumberWithCommas(
                        organizationsSearchedData.length,
                        0,
                    )} results`}</h5>
                </div>
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import { Link } from '@material-ui/core';

import styles from './styles.module.scss';

import { getSocialByType } from 'helpers';

import { ISocialsContainerProps, IOrganizationSocial } from 'types/interfaces';

export const SocialsContainer: FC<ISocialsContainerProps> = ({ socials }: ISocialsContainerProps): ReactElement => (
    <div className={styles.socialsContainer}>
        {socials.map((social: IOrganizationSocial) => (
            <Link key={social.id} href={social.url} target="_blank" rel="noreferrer">
                <img src={getSocialByType(social.type)} className={styles.socialLogo} alt="" />
            </Link>
        ))}
    </div>
);

import React, { FC, ReactElement, useEffect } from 'react';

import { ContactFormWrapper, FeedbackForm } from 'components';

import { mixpanelService } from 'services';

export const FeedbackPage: FC = (): ReactElement => {
    useEffect(() => mixpanelService.track(mixpanelService.eventsGenerator.feedbackEvent()), []);

    return (
        <ContactFormWrapper
            title="Feedback"
            description="Something about how we always strive to improve and are looking forward to feedback from our users. Anything else you may want to add here."
        >
            <FeedbackForm />
        </ContactFormWrapper>
    );
};

import React, { FC, ReactElement } from 'react';

import { Button } from '@material-ui/core';

import copy from 'copy-to-clipboard';

import styles from './styles.module.scss';

import { TooltipWrapper } from 'components';

import { ALERT_TYPES, SUCCESS_MESSAGES } from 'constants/alert';

import { FormatUtils } from 'utils';
import { contentCopyIcon } from 'assets';
import { useAppDispatch } from 'store';
import { AlertActions } from 'store/actions';

import { IPersonalInfoProps } from 'types/interfaces';

export const PersonalInfo: FC<IPersonalInfoProps> = ({ address, tagline }: IPersonalInfoProps): ReactElement => {
    const dispatch = useAppDispatch();

    const onButtonClick = () => {
        copy(address as string);
        dispatch({
            type: AlertActions.SHOW_ALERT,
            payload: {
                title: SUCCESS_MESSAGES.SUCCESFULLY_COPIED,
                type: ALERT_TYPES.SUCCESS,
            },
        });
    };

    return (
        <>
            {address && (
                <div className={styles.addressOptions}>
                    <h6 className={styles.address}>{FormatUtils.formatUserAddress(address)}</h6>
                    <TooltipWrapper title="click to copy">
                        <Button className={styles.copyButton} onClick={onButtonClick}>
                            <img src={contentCopyIcon} className={styles.copyIcon} alt="" />
                        </Button>
                    </TooltipWrapper>
                </div>
            )}

            {tagline && <h4 className={styles.tagLineText}>{tagline}</h4>}
        </>
    );
};

import { Reducer } from '@reduxjs/toolkit';

import { BRAINSTORM_ACTION_TYPE } from 'constants/discussionsElements';

import {
    organizationFeeds,
    shareholdersList,
    similarPeople,
    organizationMembers,
    votingCoalitions,
    assetsData,
    fundsGraphData,
    proposalsChartData,
    activityStats,
    votingStats,
    researchData,
    organizationInfo,
} from 'moockup_data';

import { OrganizationActions } from 'store/actions';

import {
    IOrganizationState,
    IOrganizationActionTypes,
    IOrganizationCurrency,
    IOrganizationDecision,
    IOrganizationSimilarDao,
    IOrganizationActivityData,
    IOrganizationInfoData,
    IGetOrganizationSuccessDataPayload,
    ICoalitionsTableData,
    IDiscussionItems,
    IGetDiscussionDataAction,
    IDiscussionsFilterMember,
    ICreateDiscussionFailureAction,
    ICreateDiscussionVoteFailureAction,
    ICreateDiscussionCommentFailureAction,
    ICreateDiscussionCommentVoteFailureAction,
    IClearDiscussionTokenlessErrorAction,
    IOrganizationMemberDataWithId,
    IOrganizationTopShareholder,
    IAumGraphItems,
    IAssetInfo,
    IOrganizationTool,
    IProjectToken,
    IOrganizationDao,
    IOrganizationSubstorm,
    IOrganizationErrorAction,
    IOrganizationAssetsTable,
    ICoalitionItem,
} from 'types/interfaces';

const defaultState: IOrganizationState = {
    loading: true,
    similarDaosLoading: false,
    organizationInfo,
    feeds: organizationFeeds,
    shareholders: shareholdersList,
    similarPeople,
    organizationMembers,
    votingCoalitions,
    assetsData,
    fundsGraphData,
    proposalsChartData,
    activityStats,
    votingStats,
    researchData,
    organizationData: {} as IOrganizationInfoData,
    organizationSimilarDaos: [],
    organizationActivityData: {
        proposalActivity: {},
        voteActivity: {},
        monthlyActivity: [],
    } as IOrganizationActivityData,
    daoAssets: [],
    socialAssets: [],
    organizationCurrenciesData: [],
    organizationDecisionsData: [],
    decisionsDataLoading: false,
    organizationMembersData: [],
    membersDataLoading: false,
    organizationCoalitionsData: [],
    coalitionsDataLoading: false,
    organizationTopShareholdersByHoldings: [],
    organizationTopActiveShareholders: [],
    aumGraphData: [],
    organizationDaos: [],
    activityDataLoading: false,
    organizationDiscussions: [],
    discussionModalOpen: false,
    createDiscussionLoading: false,
    discussionsLoading: false,
    userOrganizationRole: null,
    projectToken: {},
    organizationTools: [],
    discussionsFilterMembers: [],
    organizationDiscussion: {} as IDiscussionItems,
    discussionLoading: false,
    discussionsError: false,
    discussionForEditingId: null,
    brainstormActionType: null,
    substormsList: [],
    isOrganizationFollowingLoader: false,
    isOrganizationFollowing: false,
    discussionsIdsWithCommentError: [],
    commentsIdsWithReplyError: [],
    discussionsIdsWithVoteError: [],
    commentsIdsWithVoteError: [],
    discussionCreatingError: false,
    allowInteractionWithDiscussions: false,
    topShareholdersByHoldingsLoading: false,
    topActiveShareholdersLoading: false,
    aumGraphDataLoading: false,
    daoAssetsDataLoading: false,
    organizationCurrenciesDataLoading: false,
    organizationToolsDataLoading: false,
    substormsDataLoading: false,
    daoAssetsError: null,
    organizationDaoAssetsTable: [],
    organizationDaoAssetsTableLoading: true,
    organizationDaoAssetsTableError: null,
};

export const organizationReducer: Reducer<IOrganizationState, IOrganizationActionTypes> = (
    state: IOrganizationState = defaultState,
    action: IOrganizationActionTypes,
): IOrganizationState => {
    const { type, payload } = <IOrganizationActionTypes>action;

    switch (type) {
        case OrganizationActions.GET_ORGANIZATION_DATA: {
            return {
                ...state,
                organizationDaos: [],
                loading: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DATA_SUCCESS: {
            const { ...rest } = payload as IGetOrganizationSuccessDataPayload;

            return {
                ...state,
                ...rest,
                loading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_SIMILAR_DAOS_DATA: {
            return {
                ...state,
                similarDaosLoading: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_SIMILAR_DAOS_DATA_SUCCESS: {
            const { organizationSimilarDaos } = payload as { organizationSimilarDaos: IOrganizationSimilarDao[] };

            return {
                ...state,
                organizationSimilarDaos,
                similarDaosLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_SIMILAR_DAOS_DATA_FAILURE: {
            return {
                ...state,
                similarDaosLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_TOP_SHAREHOLDERS_BY_HOLDINGS: {
            return {
                ...state,
                topShareholdersByHoldingsLoading: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_TOP_SHAREHOLDERS_BY_HOLDINGS_SUCCESS: {
            const { organizationTopShareholdersByHoldings } = payload as {
                organizationTopShareholdersByHoldings: IOrganizationTopShareholder[];
            };

            return {
                ...state,
                organizationTopShareholdersByHoldings,
                topShareholdersByHoldingsLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_TOP_SHAREHOLDERS_BY_HOLDINGS_FAILURE: {
            return {
                ...state,
                topShareholdersByHoldingsLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_TOP_ACTIVE_SHAREHOLDERS: {
            return {
                ...state,
                topActiveShareholdersLoading: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_TOP_ACTIVE_SHAREHOLDERS_SUCCESS: {
            const { organizationTopActiveShareholders } = payload as {
                organizationTopActiveShareholders: IOrganizationTopShareholder[];
            };

            return {
                ...state,
                organizationTopActiveShareholders,
                topActiveShareholdersLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_TOP_ACTIVE_SHAREHOLDERS_FAILURE: {
            return {
                ...state,
                topActiveShareholdersLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_AUM_GRAPH_DATA: {
            return {
                ...state,
                aumGraphDataLoading: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_AUM_GRAPH_DATA_SUCCESS: {
            const { aumGraphData } = payload as {
                aumGraphData: IAumGraphItems[];
            };

            return {
                ...state,
                aumGraphData,
                aumGraphDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_AUM_GRAPH_DATA_FAILURE: {
            return {
                ...state,
                aumGraphDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DAO_ASSETS: {
            return {
                ...state,
                daoAssetsDataLoading: true,
                daoAssetsError: null,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DAO_ASSETS_SUCCESS: {
            const { daoAssets } = payload as {
                daoAssets: IAssetInfo[];
            };

            return {
                ...state,
                daoAssets,
                daoAssetsDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DAO_ASSETS_FAILURE: {
            const { error } = action as IOrganizationErrorAction;

            return {
                ...state,
                daoAssets: [],
                daoAssetsDataLoading: false,
                daoAssetsError: error,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DAO_ASSETS_TABLE: {
            return {
                ...state,
                organizationDaoAssetsTableLoading: true,
                organizationDaoAssetsTableError: null,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DAO_ASSETS_TABLE_SUCCESS: {
            const { organizationDaoAssetsTable } = payload as {
                organizationDaoAssetsTable: IOrganizationAssetsTable[];
            };

            return {
                ...state,
                organizationDaoAssetsTable,
                organizationDaoAssetsTableLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DAO_ASSETS_TABLE_FAILURE: {
            const { error } = action as IOrganizationErrorAction;

            return {
                ...state,
                organizationDaoAssetsTable: [],
                organizationDaoAssetsTableLoading: false,
                organizationDaoAssetsTableError: error,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_CURRENCIES_DATA: {
            return {
                ...state,
                organizationCurrenciesDataLoading: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_CURRENCIES_DATA_SUCCESS: {
            const { organizationCurrenciesData } = payload as {
                organizationCurrenciesData: IOrganizationCurrency[];
            };

            return {
                ...state,
                organizationCurrenciesData,
                organizationCurrenciesDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_CURRENCIES_DATA_FAILURE: {
            return {
                ...state,
                organizationCurrenciesDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_TOOLS_DATA: {
            return {
                ...state,
                organizationToolsDataLoading: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_TOOLS_DATA_SUCCESS: {
            const { organizationTools } = payload as {
                organizationTools: IOrganizationTool[];
            };

            return {
                ...state,
                organizationTools,
                organizationToolsDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_TOOLS_DATA_FAILURE: {
            return {
                ...state,
                organizationToolsDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_PROJECT_TOKEN_DATA: {
            return {
                ...state,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_PROJECT_TOKEN_DATA_SUCCESS: {
            const { projectToken } = payload as {
                projectToken: IProjectToken;
            };

            return {
                ...state,
                projectToken,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_PROJECT_TOKEN_DATA_FAILURE: {
            return {
                ...state,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DAOS_DATA: {
            return {
                ...state,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DAOS_DATA_SUCCESS: {
            const { organizationDaos } = payload as {
                organizationDaos: IOrganizationDao[];
            };

            return {
                ...state,
                organizationDaos,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DAOS_DATA_FAILURE: {
            return {
                ...state,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_SUBSTORMS_DATA: {
            return {
                ...state,
                substormsDataLoading: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_SUBSTORMS_DATA_SUCCESS: {
            const { substormsList } = payload as {
                substormsList: IOrganizationSubstorm[];
            };

            return {
                ...state,
                substormsList,
                substormsDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_SUBSTORMS_DATA_FAILURE: {
            return {
                ...state,
                substormsDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DECISIONS_DATA: {
            return {
                ...state,
                decisionsDataLoading: true,
                organizationDecisionsData: [],
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DECISIONS_DATA_SUCCESS: {
            const { organizationDecisionsData } = payload as { organizationDecisionsData: IOrganizationDecision[] };

            return {
                ...state,
                organizationDecisionsData: organizationDecisionsData,
                decisionsDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DECISIONS_DATA_FAILURE: {
            return {
                ...state,
                decisionsDataLoading: false,
                organizationDecisionsData: [],
            };
        }

        case OrganizationActions.GET_ORGANIZATION_MEMBERS_DATA: {
            return {
                ...state,
                membersDataLoading: true,
                organizationMembersData: [],
            };
        }

        case OrganizationActions.GET_ORGANIZATION_MEMBERS_DATA_SUCCESS: {
            const { organizationMembersData } = payload as { organizationMembersData: IOrganizationMemberDataWithId[] };

            return {
                ...state,
                organizationMembersData: organizationMembersData,
                membersDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_MEMBERS_DATA_FAILURE: {
            return {
                ...state,
                membersDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_COALITIONS_DATA: {
            return {
                ...state,
                coalitionsDataLoading: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_COALITIONS_DATA_SUCCESS: {
            const { organizationCoalitionsData } = payload as { organizationCoalitionsData: ICoalitionItem[] };

            return {
                ...state,
                organizationCoalitionsData,
                coalitionsDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_COALITIONS_DATA_FAILURE: {
            return {
                ...state,
                coalitionsDataLoading: false,
            };
        }

        case OrganizationActions.CLEAR_ORGANIZATION_COALITIONS_DATA: {
            return {
                ...state,
                organizationCoalitionsData: [],
            };
        }

        case OrganizationActions.GET_ORGANIZATION_ACTIVITY_DATA: {
            return {
                ...state,
                activityDataLoading: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_ACTIVITY_DATA_SUCCESS: {
            const { organizationActivityData } = payload as { organizationActivityData: IOrganizationActivityData };

            return {
                ...state,
                organizationActivityData,
                activityDataLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_ACTIVITY_DATA_FAILURE: {
            return {
                ...state,
                activityDataLoading: false,
            };
        }

        case OrganizationActions.CLEAR_ORGANIZATION_DECISIONS_DATA: {
            return {
                ...state,
                organizationDecisionsData: [],
            };
        }

        case OrganizationActions.CLEAR_ORGANIZATION_MEMBERS_DATA: {
            return {
                ...state,
                organizationMembersData: [],
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DISCUSSIONS: {
            return {
                ...state,
                discussionsLoading: !(payload as IGetDiscussionDataAction).skipLoading,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DISCUSSIONS_SUCCESS: {
            return {
                ...state,
                organizationDiscussions: payload as IDiscussionItems[],
                discussionsLoading: false,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DISCUSSIONS_FAILURE: {
            return {
                ...state,
                discussionsLoading: false,
                discussionsError: true,
            };
        }

        case OrganizationActions.CREATE_DISCUSSION: {
            return {
                ...state,
                createDiscussionLoading: true,
            };
        }

        case OrganizationActions.CREATE_DISCUSSION_SUCCESS: {
            return {
                ...state,
                organizationDiscussions: payload as IDiscussionItems[],
                createDiscussionLoading: false,
            };
        }

        case OrganizationActions.CREATE_DISCUSSION_FAILURE: {
            const { error } = action as ICreateDiscussionFailureAction;

            return {
                ...state,
                createDiscussionLoading: false,
                ...error,
            };
        }

        case OrganizationActions.CREATE_DISCUSSION_VOTE: {
            return {
                ...state,
            };
        }

        case OrganizationActions.CREATE_DISCUSSION_VOTE_SUCCESS: {
            return {
                ...state,
                organizationDiscussions: payload as IDiscussionItems[],
            };
        }

        case OrganizationActions.CREATE_DISCUSSION_VOTE_FAILURE: {
            const { error } = action as ICreateDiscussionVoteFailureAction;

            return {
                ...state,
                ...error,
            };
        }

        case OrganizationActions.CREATE_DISCUSSION_COMMENT: {
            return {
                ...state,
            };
        }

        case OrganizationActions.CREATE_DISCUSSION_COMMENT_SUCCESS: {
            return {
                ...state,
                organizationDiscussions: payload as IDiscussionItems[],
            };
        }

        case OrganizationActions.CREATE_DISCUSSION_COMMENT_FAILURE: {
            const { error } = action as ICreateDiscussionCommentFailureAction;

            return {
                ...state,
                ...error,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_BALANCE: {
            return {
                ...state,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_BALANCE_SUCCESS: {
            return {
                ...state,
                allowInteractionWithDiscussions: payload as boolean,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_BALANCE_FAILED: {
            return {
                ...state,
            };
        }

        case OrganizationActions.GET_USER_ORGANIZATION_ROLE: {
            return {
                ...state,
            };
        }

        case OrganizationActions.GET_USER_ORGANIZATION_ROLE_SUCCESS: {
            return {
                ...state,
                ...(payload as any),
            };
        }

        case OrganizationActions.GET_USER_ORGANIZATION_ROLE_FAILURE: {
            return {
                ...state,
            };
        }

        case OrganizationActions.TOGGLE_DISSCUSION_MODAL_OPEN: {
            return {
                ...state,
                discussionModalOpen: !state.discussionModalOpen,
                ...(payload as {
                    payload: {
                        brainstormActionType: BRAINSTORM_ACTION_TYPE | null;
                        discussionForEditingId: string | null;
                    };
                }),
            };
        }

        case OrganizationActions.GET_USER_ORGANIZATION_DISCUSSIONS_MEMBERS_SUCCESS: {
            return {
                ...state,
                discussionsFilterMembers: payload as IDiscussionsFilterMember[],
            };
        }

        case OrganizationActions.BAN_DISCUSSION_ELEMENT: {
            return {
                ...state,
            };
        }

        case OrganizationActions.BAN_DISCUSSION_ELEMENT_SUCCESS: {
            return {
                ...state,
                organizationDiscussions: payload as IDiscussionItems[],
            };
        }

        case OrganizationActions.BAN_DISCUSSION_ELEMENT_FAILURE: {
            return {
                ...state,
                discussionsError: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DISCUSSION: {
            return {
                ...state,
                discussionLoading: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DISCUSSION_SUCCESS: {
            return {
                ...state,
                organizationDiscussion: payload as IDiscussionItems,
                brainstormActionType: null,
                discussionLoading: false,
            };
        }

        case OrganizationActions.UPDATE_ORGANIZATION_DISCUSSION: {
            return {
                ...state,
                createDiscussionLoading: true,
            };
        }

        case OrganizationActions.UPDATE_ORGANIZATION_DISCUSSION_SUCCESS: {
            return {
                ...state,
                organizationDiscussions: payload as IDiscussionItems[],
                createDiscussionLoading: false,
            };
        }

        case OrganizationActions.UPDATE_ORGANIZATION_DISCUSSION_FAILURE: {
            return {
                ...state,
                createDiscussionLoading: false,
                discussionsError: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_DISCUSSION_FAILURE: {
            return {
                ...state,
                discussionLoading: false,
            };
        }

        case OrganizationActions.CREATE_DISCUSSION_COMMENT_VOTE: {
            return {
                ...state,
            };
        }

        case OrganizationActions.CREATE_DISCUSSION_COMMENT_VOTE_SUCCESS: {
            return {
                ...state,
                organizationDiscussions: payload as IDiscussionItems[],
            };
        }

        case OrganizationActions.CREATE_DISCUSSION_COMMENT_VOTE_FAILURE: {
            const { error } = action as ICreateDiscussionCommentVoteFailureAction;

            return {
                ...state,
                ...error,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_IS_FOLLOWING_DATA: {
            return {
                ...state,
                isOrganizationFollowingLoader: true,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_IS_FOLLOWING_DATA_SUCCESS: {
            const { isOrganizationFollowing } = payload as {
                isOrganizationFollowing: boolean;
            };

            return {
                ...state,
                isOrganizationFollowingLoader: false,
                isOrganizationFollowing,
            };
        }

        case OrganizationActions.GET_ORGANIZATION_IS_FOLLOWING_DATA_FAILURE: {
            return {
                ...state,
                isOrganizationFollowingLoader: false,
            };
        }

        case OrganizationActions.FOLLOW_UNFOLLOW_ORGANIZATION: {
            return {
                ...state,
                isOrganizationFollowingLoader: true,
            };
        }

        case OrganizationActions.FOLLOW_UNFOLLOW_ORGANIZATION_SUCCESS: {
            const { isOrganizationFollowing } = payload as {
                isOrganizationFollowing: boolean;
            };

            return {
                ...state,
                isOrganizationFollowingLoader: false,
                isOrganizationFollowing,
            };
        }

        case OrganizationActions.FOLLOW_UNFOLLOW_ORGANIZATION_FAILURE: {
            return {
                ...state,
                isOrganizationFollowingLoader: false,
            };
        }

        case OrganizationActions.CLEAR_DISCUSSIONS_ERROR: {
            return {
                ...state,
                discussionsError: false,
            };
        }

        case OrganizationActions.CLEAR_DISCUSSION_TOKENLESS_ERROR: {
            return {
                ...state,
                ...(payload as IClearDiscussionTokenlessErrorAction),
            };
        }

        case OrganizationActions.CLEAR_ORGANIZATION_DATA: {
            return {
                ...defaultState,
            };
        }

        default: {
            return state;
        }
    }
};

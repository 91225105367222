import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

export const TableHeader: FC = (): ReactElement => (
    <div className={styles.tableHeader}>
        <div className={styles.number}>#</div>
        <div className={styles.name}>name</div>
        <div className={styles.holding}>holding</div>
        <div className={styles.usdValue}>usd value</div>
    </div>
);

import { STATUSES } from 'constants/statuses';

export const convertStatus = (status: STATUSES | null): STATUSES | null => {
    switch (status) {
        case STATUSES.ACCEPTED:
        case STATUSES.REJECTED: {
            return STATUSES.EXECUTED;
        }

        case STATUSES.NOT_FOUND: {
            return STATUSES.ONGOING;
        }

        default: {
            return status;
        }
    }
};

import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

export const TableHeader = (): ReactElement => (
    <div className={styles.tableHeader}>
        <div className={styles.totalSupply}>Total supply</div>
        <div className={styles.circulatingSupply}>Circulating supply</div>
        <div className={styles.usdValue}>In Treasury</div>
        <div className={styles.circulatingSupplyPercentage}>% circ. supply in treasury</div>
        <div className={styles.totalSupplyPercentage}>% total supply in treasury</div>
        <div className={styles.holders}>Holders</div>
    </div>
);

import React, { FC } from 'react';

import { Button } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';

import classNames from 'classnames';

import copy from 'copy-to-clipboard';

import styles from './styles.module.scss';

import { TooltipWrapper } from 'components';

import { useAppDispatch } from 'store';
import { AlertActions } from 'store/actions';

import { ALERT_TYPES, SUCCESS_MESSAGES } from 'constants/alert';

export const ShareButton: FC<{ className?: string }> = ({ className }: { className?: string }) => {
    const dispatch = useAppDispatch();

    const onButtonClick = () => {
        copy(window.location.href);
        dispatch({
            type: AlertActions.SHOW_ALERT,
            payload: {
                title: SUCCESS_MESSAGES.SUCCESFULLY_COPIED,
                type: ALERT_TYPES.SUCCESS,
            },
        });
    };

    return (
        <div className={classNames(styles.shareButtonWrapper, className)}>
            <TooltipWrapper title="click to copy">
                <Button className={styles.shareButton} onClick={onButtonClick}>
                    <ShareIcon className={styles.shareIcon} />
                </Button>
            </TooltipWrapper>
        </div>
    );
};

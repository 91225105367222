import React, { FC, ReactElement, useMemo, useState } from 'react';

import styles from './styles.module.scss';

import { CommonTable, Select } from 'components';

import {
    DAO_TREASURY_INVESTMENTS_TABLE_PARAMS,
    daoTreasuryInvestmentProtocolTableHeaderData,
    daoTreasuryInvestmentTypeTableHeaderData,
    daoTreasuryInvestmentProtocolAndTypeTableHeaderData,
    daoTreasuryIndividualOrganizationTableHeaderData,
} from './constants';
import {
    DAO_TREASURY_INVESTMENTS_APIS,
    DAO_TREASURY_INVESTMENTS_MAX_VISIBLE_FIELDS,
    DAO_TREASURY_INVESTMENTS_TYPES,
} from 'constants/daoTreasuryInvestments';

import { useAppSelector } from 'store';
import {
    convertOrderDataToQueryParam,
    getIndividualOrganizationSelectData,
    getIndividualOrganizationTableData,
    getLastUpdateDate,
} from './utils';
import {
    IDaoTreasuryIndividualOrganization,
    IDaoTreasuryInvestmentProtocol,
    IDaoTreasuryInvestmentProtocolAndType,
    ISelectOptions,
    ISortingParams,
} from 'types/interfaces';

interface IDAOTreasuryInvestmentsTablesProps {
    updateDaoTreasuryInvestmentsData: (data: {
        queryParams: { [key: string]: string | number };
        fieldName: DAO_TREASURY_INVESTMENTS_TYPES;
        daoTreasuryAssetsApi: DAO_TREASURY_INVESTMENTS_APIS;
    }) => void;
}

export const InvestmentsRealData: FC<IDAOTreasuryInvestmentsTablesProps> = ({
    updateDaoTreasuryInvestmentsData,
}: IDAOTreasuryInvestmentsTablesProps): ReactElement => {
    const {
        topInvestmentsType,
        topInvestmentsProtocolAndType,
        topInvestmentsProtocolAndTypeBorrowing,
        individualOrganizationInvestments,
        top20IndividualOrganizationInvestments,
    } = useAppSelector(({ daoTreasuryInvestments }) => daoTreasuryInvestments);

    const [individualOrganizationInvestmentsSelectedOption, setIndividualOrganizationInvestmentsSelectedOption] =
        useState<ISelectOptions | undefined>();

    const top20IndividualOrganizationInvestmentsSelectData = useMemo(
        () =>
            getIndividualOrganizationSelectData(
                top20IndividualOrganizationInvestments.data as unknown as IDaoTreasuryIndividualOrganization[],
            ),
        [top20IndividualOrganizationInvestments],
    );

    const onIndividualOrganizationInvestmentsSelect = (option: ISelectOptions) => {
        setIndividualOrganizationInvestmentsSelectedOption(option);

        updateDaoTreasuryInvestmentsData({
            fieldName: DAO_TREASURY_INVESTMENTS_TYPES.INDIVIDUAL_ORGANIZATION_INVESTMENTS,
            queryParams: {
                organizationId: option.id,
            },
            daoTreasuryAssetsApi: DAO_TREASURY_INVESTMENTS_APIS.DAO_TREASURY_INVESTMENTS_ASSETS,
        });
    };

    const individualOrganizationInvestmentsTableData = useMemo(
        () =>
            getIndividualOrganizationTableData(
                individualOrganizationInvestments.data as unknown as IDaoTreasuryIndividualOrganization[],
            ),
        [individualOrganizationInvestments, individualOrganizationInvestmentsSelectedOption],
    );

    return (
        <>
            <CommonTable
                title="Top Protocols"
                infoText="The top protocols used by DAOs, sorted by number of DAOs, or total USD value."
                lastUpdate={getLastUpdateDate(
                    topInvestmentsProtocolAndType.data as IDaoTreasuryInvestmentProtocolAndType[],
                )}
                tableRowsData={topInvestmentsProtocolAndType.data as IDaoTreasuryInvestmentProtocolAndType[]}
                loading={topInvestmentsProtocolAndType.loading}
                tableHeaderData={daoTreasuryInvestmentProtocolAndTypeTableHeaderData}
                sortedParamName={DAO_TREASURY_INVESTMENTS_TABLE_PARAMS.ORGANIZATIONS_COUNT}
                onSortingChange={(sortingData: ISortingParams) => {
                    updateDaoTreasuryInvestmentsData({
                        fieldName: DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_PROTOCOL_AND_TYPE,
                        queryParams: { ...convertOrderDataToQueryParam(sortingData) },
                        daoTreasuryAssetsApi: DAO_TREASURY_INVESTMENTS_APIS.DAO_TRASURY_INVESTMENTS,
                    });
                }}
            />
            <CommonTable
                title="Top protocol types"
                infoText="The top protocol types used by DAOs, sorted by number of DAOs, or total USD value."
                lastUpdate={getLastUpdateDate(topInvestmentsType.data as IDaoTreasuryInvestmentProtocolAndType[])}
                tableRowsData={topInvestmentsType.data as IDaoTreasuryInvestmentProtocolAndType[]}
                loading={topInvestmentsType.loading}
                tableHeaderData={daoTreasuryInvestmentTypeTableHeaderData}
                sortedParamName={DAO_TREASURY_INVESTMENTS_TABLE_PARAMS.ORGANIZATIONS_COUNT}
                onSortingChange={(sortingData: ISortingParams) => {
                    updateDaoTreasuryInvestmentsData({
                        fieldName: DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_TYPE,
                        queryParams: { ...convertOrderDataToQueryParam(sortingData) },
                        daoTreasuryAssetsApi: DAO_TREASURY_INVESTMENTS_APIS.DAO_TRASURY_INVESTMENTS,
                    });
                }}
            />
            <CommonTable
                title="DAOs borrowing protocols"
                infoText="Protocols used by DAOs for borrowing"
                lastUpdate={getLastUpdateDate(
                    topInvestmentsProtocolAndTypeBorrowing.data as IDaoTreasuryInvestmentProtocolAndType[],
                )}
                tableRowsData={topInvestmentsProtocolAndTypeBorrowing.data as IDaoTreasuryInvestmentProtocolAndType[]}
                loading={topInvestmentsProtocolAndTypeBorrowing.loading}
                tableHeaderData={daoTreasuryInvestmentProtocolAndTypeTableHeaderData}
                sortedParamName={DAO_TREASURY_INVESTMENTS_TABLE_PARAMS.ORGANIZATIONS_COUNT}
                onSortingChange={(sortingData: ISortingParams) => {
                    updateDaoTreasuryInvestmentsData({
                        fieldName: DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_PROTOCOL_AND_TYPE_BORROWING,
                        queryParams: { ...convertOrderDataToQueryParam(sortingData) },
                        daoTreasuryAssetsApi: DAO_TREASURY_INVESTMENTS_APIS.DAO_TRASURY_INVESTMENTS,
                    });
                }}
            />
            <CommonTable
                title="Organization investments"
                infoText="Protocols used by individual DAOs"
                tableRowsData={individualOrganizationInvestmentsTableData}
                loading={individualOrganizationInvestments.loading}
                tableHeaderData={daoTreasuryIndividualOrganizationTableHeaderData}
                sortedParamName={DAO_TREASURY_INVESTMENTS_TABLE_PARAMS.TOTAL_AUM}
                headContent={
                    <Select
                        options={top20IndividualOrganizationInvestmentsSelectData}
                        placeholder="Select Organization"
                        selectedOption={individualOrganizationInvestmentsSelectedOption}
                        onSelect={onIndividualOrganizationInvestmentsSelect}
                        className={styles.selectWrapper}
                    />
                }
                onSortingChange={(sortingData: ISortingParams) => {
                    updateDaoTreasuryInvestmentsData({
                        fieldName: DAO_TREASURY_INVESTMENTS_TYPES.INDIVIDUAL_ORGANIZATION_INVESTMENTS,
                        queryParams: {
                            ...convertOrderDataToQueryParam(sortingData),
                            ...(individualOrganizationInvestmentsSelectedOption && {
                                organizationId: individualOrganizationInvestmentsSelectedOption.id,
                            }),
                        },
                        daoTreasuryAssetsApi: DAO_TREASURY_INVESTMENTS_APIS.DAO_TREASURY_INVESTMENTS_ASSETS,
                    });
                }}
                maxRowsLength={DAO_TREASURY_INVESTMENTS_MAX_VISIBLE_FIELDS}
            />
        </>
    );
};

import { ISearchedOrganizationFilter, ISortedParam } from 'types/interfaces';

export enum SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS {
    NAME_DATA = 'NAME_DATA',
    TREASURY_SIZE_DATA = 'treasurySizeData',
    PROPOSALS_DATA = 'proposalsData',
    VOTES_DATA = 'votesData',
    CATEGORIES_DATA = 'categoriesData',
}

export enum SEARCHED_ORGANIZATION_FILTERS_TITLES {
    NAME = 'Name',
    TREASURY_SIZE = 'Treasury Size',
    NUMBER_PROPOSALS = 'Number Proposals',
    NUMBER_VOTES = 'Number Votes',
    CATEGORY = 'Category',
}

export enum SEARCHED_ORGANIZATION_FILTERS_TYPE {
    INPUT = 'INPUT',
    SELECT = 'SELECT',
}

const DEFAULT_ORGANIZATIONS_DATA_LIMIT = 50;
const DEFAULT_OFFSET_VALUE = 1;

const SELECTED_FILTERS_LOCAL_STORAGE_KEY = 'organizatiosSearchSelectedFilters';
const ORGANIZATIONS_SEACRHED_DATA_SORTED_PARAM: ISortedParam = {
    paramName: 'treasurySize',
    descending: true,
};

const organizationsNamesFilterData: ISearchedOrganizationFilter[] = [
    {
        id: '600',
        title: 'Name',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.NAME_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.NAME,
        queryData: [
            {
                key: 'name',
                value: null,
            },
        ],
    },
];

const organizationsTreasurySizesData: ISearchedOrganizationFilter[] = [
    {
        id: '100',
        title: '0 to 500k',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.TREASURY_SIZE_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.TREASURY_SIZE,
        queryData: [
            {
                key: 'treasurySizeMin',
                value: 0,
            },
            {
                key: 'treasurySizeMax',
                value: 500000,
            },
        ],
    },
    {
        id: '101',
        title: '500k to 1M',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.TREASURY_SIZE_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.TREASURY_SIZE,
        queryData: [
            {
                key: 'treasurySizeMin',
                value: 500000,
            },
            {
                key: 'treasurySizeMax',
                value: 1000000,
            },
        ],
    },
    {
        id: '102',
        title: '1M to 10M',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.TREASURY_SIZE_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.TREASURY_SIZE,
        queryData: [
            {
                key: 'treasurySizeMin',
                value: 1000000,
            },
            {
                key: 'treasurySizeMax',
                value: 10000000,
            },
        ],
    },
    {
        id: '103',
        title: '10M to 50M',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.TREASURY_SIZE_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.TREASURY_SIZE,
        queryData: [
            {
                key: 'treasurySizeMin',
                value: 10000000,
            },
            {
                key: 'treasurySizeMax',
                value: 50000000,
            },
        ],
    },
    {
        id: '104',
        title: '50M plus',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.TREASURY_SIZE_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.TREASURY_SIZE,
        queryData: [
            {
                key: 'treasurySizeMin',
                value: 50000000,
            },
            {
                key: 'treasurySizeMax',
                value: null,
            },
        ],
    },
];

const organizationsProposalsFilterData: ISearchedOrganizationFilter[] = [
    {
        id: '200',
        title: '1 to 10',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.PROPOSALS_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.NUMBER_PROPOSALS,
        queryData: [
            {
                key: 'minProposals',
                value: 1,
            },
            {
                key: 'maxProposals',
                value: 10,
            },
        ],
    },
    {
        id: '201',
        title: '10 to 25',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.PROPOSALS_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.NUMBER_PROPOSALS,
        queryData: [
            {
                key: 'minProposals',
                value: 10,
            },
            {
                key: 'maxProposals',
                value: 25,
            },
        ],
    },
    {
        id: '202',
        title: '25 to 50',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.PROPOSALS_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.NUMBER_PROPOSALS,
        queryData: [
            {
                key: 'minProposals',
                value: 25,
            },
            {
                key: 'maxProposals',
                value: 50,
            },
        ],
    },
    {
        id: '203',
        title: '50 to 100',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.PROPOSALS_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.NUMBER_PROPOSALS,
        queryData: [
            {
                key: 'minProposals',
                value: 50,
            },
            {
                key: 'maxProposals',
                value: 100,
            },
        ],
    },
    {
        id: '204',
        title: '100 plus',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.PROPOSALS_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.NUMBER_PROPOSALS,
        queryData: [
            {
                key: 'minProposals',
                value: 100,
            },
            {
                key: 'maxProposals',
                value: null,
            },
        ],
    },
];

const organizationsVotesFilterData: ISearchedOrganizationFilter[] = [
    {
        id: '205',
        title: '1 to 100',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.VOTES_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.NUMBER_VOTES,
        queryData: [
            {
                key: 'minVoters',
                value: 1,
            },
            {
                key: 'maxVoters',
                value: 100,
            },
        ],
    },
    {
        id: '206',
        title: '100 to 250',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.VOTES_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.NUMBER_VOTES,
        queryData: [
            {
                key: 'minVoters',
                value: 100,
            },
            {
                key: 'maxVoters',
                value: 250,
            },
        ],
    },
    {
        id: '207',
        title: '250 to 500',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.VOTES_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.NUMBER_VOTES,
        queryData: [
            {
                key: 'minVoters',
                value: 250,
            },
            {
                key: 'maxVoters',
                value: 500,
            },
        ],
    },
    {
        id: '208',
        title: '500 plus',
        relatedFieldKey: SEARCHED_ORGANIZATION_FILTERS_RELATED_FIELD_KEYS.VOTES_DATA,
        relatedFieldTitle: SEARCHED_ORGANIZATION_FILTERS_TITLES.NUMBER_VOTES,
        queryData: [
            {
                key: 'minVoters',
                value: 500,
            },
            {
                key: 'maxVoters',
                value: null,
            },
        ],
    },
];

export {
    organizationsTreasurySizesData,
    organizationsNamesFilterData,
    organizationsProposalsFilterData,
    organizationsVotesFilterData,
    DEFAULT_ORGANIZATIONS_DATA_LIMIT,
    DEFAULT_OFFSET_VALUE,
    SELECTED_FILTERS_LOCAL_STORAGE_KEY,
    ORGANIZATIONS_SEACRHED_DATA_SORTED_PARAM,
};

import React, { ChangeEvent, FC, ReactElement, useContext } from 'react';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import styles from './styles.module.scss';

import { SelfListingInput, SelfListingTitle } from 'components';

import { SelfListingContext } from 'context/selfListingContext';
import { getPlaceholderNameByType } from './utils';

import { ISelfListingSocial, SocialTypes } from 'types/interfaces';

export const SocialsContainer: FC = (): ReactElement => {
    const { formik } = useContext(SelfListingContext);

    const addSocialsFieds = () => {
        formik.setFieldValue('contactDetails.socials', [
            ...formik.values.contactDetails.socials,
            {
                url: '',
                type: SocialTypes.url,
            },
        ]);
    };

    return (
        <div className={styles.socialsContainer}>
            <SelfListingTitle title="Social presence" />

            <div className={styles.socialsList}>
                {formik.values.contactDetails.socials.map((items: ISelfListingSocial, index: number) => (
                    <SelfListingInput
                        key={index}
                        placeholder={getPlaceholderNameByType(items.type) as string}
                        placeholderCapitalize
                        name={`contactDetails.socials[${index}].url`}
                        value={formik.values.contactDetails.socials[index].url}
                        touched={formik.touched?.contactDetails?.socials?.[index]?.url}
                        error={formik.errors?.contactDetails?.socials?.[index]?.url}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            formik.setFieldValue(`contactDetails.socials[${index}].url`, event.target.value)
                        }
                        onTouch={formik.setFieldTouched}
                    />
                ))}
            </div>

            <Button className={styles.addSocialsButton} onClick={addSocialsFieds}>
                <AddIcon className={styles.addIcon} />
                <span className={styles.title}>add</span>
            </Button>
        </div>
    );
};

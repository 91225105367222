import React, { FC, ReactElement } from 'react';

import { TooltipProps } from 'recharts';

import styles from './styles.module.scss';

import { FormatUtils } from 'utils';

export const CustomTooltip: FC<TooltipProps<number, string>> = ({
    payload,
}: TooltipProps<number, string>): ReactElement => {
    const { aum } = payload?.[0]?.payload || {};

    return (
        <div className={styles.tooltip}>
            <h6 className={styles.text}>
                AUM: <span className={styles.value}>{FormatUtils.formatNumberShort(aum || 0, 1)}</span>
            </h6>
        </div>
    );
};

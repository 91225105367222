import React, { FC, ReactElement } from 'react';

import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';

import styles from './styles.module.scss';

import { RechartsCustomizedAxisTick } from 'components/index';
import { CustomTooltip } from './components';

import { IOrganizationMonthlyActivity } from 'types/interfaces';

export const ProposalsBarChart: FC<{ data: IOrganizationMonthlyActivity[] }> = ({
    data,
}: {
    data: IOrganizationMonthlyActivity[];
}): ReactElement => {
    return (
        <div className={styles.content}>
            <ResponsiveContainer height={240}>
                <BarChart
                    data={data}
                    barSize={12}
                    barGap={3}
                    margin={{
                        right: 30,
                    }}
                >
                    <XAxis
                        dataKey="createdAt"
                        tickLine={false}
                        stroke="#E5EBEF"
                        tick={<RechartsCustomizedAxisTick offsetY={10} className={styles.axisTick} />}
                        interval={2}
                    />

                    <YAxis
                        tickLine={false}
                        stroke="#E5EBEF"
                        width={45}
                        tick={<RechartsCustomizedAxisTick offsetX={-10} className={styles.axisTick} />}
                    />

                    <Tooltip cursor={false} content={<CustomTooltip />} />

                    <Bar dataKey="proposals" fill="#337BFF" radius={[2, 2, 0, 0]} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

import React, { FC, ReactElement, useEffect, useState, useCallback } from 'react';

import { useLocation } from 'react-router-dom';

import InfiniteScroll from 'react-infinite-scroll-component';

import { debounce } from 'lodash';

import styles from './styles.module.scss';

import { FeedSearchFilter, FeedCardItems, DotsLoader, DaoFeedPageHeader } from 'components';

import { DEFAULT_OFFSET, DEFAULT_LIMIT, FEED_CARD_TYPES, PROPOSAL_TYPE } from './constants';
import { DATA_NOT_FOUND } from 'constants/dataNotFound';
import { PAGES_TYPES } from 'constants/pagesTypes';

import { useAppDispatch, useAppSelector } from 'store';
import { FeedActions } from 'store/actions';
import { FormatUtils, LinkUtils } from 'utils';
import { getElementsIdsByType, setProposalType } from './utils';
import { defaultUserPlaceholderImage } from 'assets';
import { mixpanelService } from 'services';

import { IFeedOrganizationSearchedData, IFeedItemsData, IFeedMember, IFeedSelectedItem } from 'types/interfaces';

export const DaoFeedPage: FC = (): ReactElement => {
    const {
        feedItems,
        hasMoreDataToLoad,
        feedOrganizations,
        feedActivityData: { members },
    } = useAppSelector(({ feed }) => feed);
    const dispatch = useAppDispatch();
    const location = useLocation();

    const { organizations: searchedOrganizations, people: searchedPeople } = LinkUtils.parseQueryParams(
        location.search,
    );

    const [selectedItems, setSelectedItems] = useState<IFeedSelectedItem[]>([]);

    const [inputValue, setInputValue] = useState<string>('');
    const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);

    useEffect(() => {
        const queryOrganizations = FormatUtils.getNoramalizedArrayData(searchedOrganizations) || [];
        const queryProposers = FormatUtils.getNoramalizedArrayData(searchedPeople) || [];

        setSelectedItems(
            [
                ...members.map(({ address }: IFeedMember) => ({ id: address, name: address, type: PAGES_TYPES.USER })),
                ...feedOrganizations.map(({ id, title }: IFeedOrganizationSearchedData) => ({
                    id,
                    name: title,
                    type: PAGES_TYPES.ORGANIZATION,
                })),
            ].filter((items: IFeedSelectedItem) => [...queryOrganizations, ...queryProposers].includes(items.id)),
        );
    }, [members]);

    useEffect(() => {
        const queryOrganizations = FormatUtils.getNoramalizedArrayData(searchedOrganizations) || [];
        const queryProposers = FormatUtils.getNoramalizedArrayData(searchedPeople) || [];
        LinkUtils.updateLinkWithQueryParams('daofeed', {
            organizations: queryOrganizations.join(','),
            people: queryProposers.join(','),
        });

        dispatch(
            FeedActions.getFeedData.request({
                search: inputValue,
                organization_id: queryOrganizations,
            }),
        );

        dispatch(
            FeedActions.getFeedItems.request({
                offset: DEFAULT_OFFSET,
                limit: DEFAULT_LIMIT,
                type: PROPOSAL_TYPE,
                organizationId: queryOrganizations.join(','),
                proposer: queryProposers.join(','),
            }),
        );
        dispatch(FeedActions.getFeedStats.request());
        dispatch(FeedActions.getFeedActivity.request());
    }, [dispatch]);

    useEffect(() => mixpanelService.track(mixpanelService.eventsGenerator.feedEvent()), []);

    const organizationSelect = (organizationData: IFeedSelectedItem, multiplySearch?: boolean) => {
        const data = multiplySearch ? [...selectedItems, organizationData] : [organizationData];
        setSelectedItems(data as IFeedSelectedItem[]);
        updateFeedItems(data as IFeedSelectedItem[]);
    };

    const removeSelectedOrganization = (organizationId: string) => {
        const data = selectedItems.filter(({ id }: IFeedSelectedItem) => organizationId !== id);
        setSelectedItems(data);
        updateFeedItems(data);
    };

    const onInputChange = (value: string) => {
        setInputValue(value);
        debounceSearch(value, getElementsIdsByType(selectedItems, PAGES_TYPES.ORGANIZATION));
    };

    const debounceSearch = useCallback(
        debounce((value: string, selectedOrganizationsIds: string[]) => {
            dispatch(
                FeedActions.getFeedOrganizationsSearch.request({
                    search: value,
                    include: selectedOrganizationsIds,
                }),
            );
        }, 500),
        [],
    );

    const updateFeedItems = (organizationsData: IFeedSelectedItem[]) => {
        setOffset(DEFAULT_OFFSET);
        const organizationsIds = getElementsIdsByType(organizationsData, PAGES_TYPES.ORGANIZATION);
        const proposersIds = getElementsIdsByType(organizationsData, PAGES_TYPES.USER);
        LinkUtils.updateLinkWithQueryParams('daofeed', {
            organizations: organizationsIds.join(','),
            people: proposersIds.join(','),
        });

        dispatch({ type: FeedActions.CLEAR_FEED_ITEMS_DATA });
        dispatch(
            FeedActions.getFeedOrganizationsSearch.request({
                search: inputValue,
                include: organizationsIds,
            }),
        );
        dispatch(
            FeedActions.getFeedItems.request({
                offset: DEFAULT_OFFSET,
                limit: DEFAULT_LIMIT,
                type: PROPOSAL_TYPE,
                organizationId: organizationsIds.join(','),
                proposer: proposersIds.join(','),
            }),
        );
    };

    const loadMore = () => {
        if (!feedItems.length) return;

        setOffset(offset + DEFAULT_LIMIT);

        dispatch(
            FeedActions.getFeedItems.request({
                offset: offset + DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                type: PROPOSAL_TYPE,
                organizationId: getElementsIdsByType(selectedItems, PAGES_TYPES.ORGANIZATION).join(','),
                proposer: getElementsIdsByType(selectedItems, PAGES_TYPES.USER).join(','),
                withLoadMore: true,
            }),
        );
    };

    useEffect(
        () => () => {
            dispatch({ type: FeedActions.CLEAR_ALL_FEED_DATA });
        },
        [dispatch],
    );

    return (
        <>
            <DaoFeedPageHeader organizationSelect={organizationSelect} />

            <div className={styles.pageContainer}>
                <div className={styles.feedsSection}>
                    <FeedSearchFilter
                        inputValue={inputValue}
                        onInputChange={onInputChange}
                        selectedItems={selectedItems}
                        organizationSelect={organizationSelect}
                        removeSelectedOrganization={removeSelectedOrganization}
                    />

                    <div className={styles.infinityScrollWrapper}>
                        <InfiniteScroll
                            next={loadMore}
                            dataLength={feedItems.length}
                            hasMore={hasMoreDataToLoad}
                            className={styles.cardsContainer}
                            loader={<DotsLoader className={styles.loaderWrapper} />}
                            style={{ overflow: 'none' }}
                        >
                            {feedItems.map((item: IFeedItemsData) => {
                                const userLogoLink = LinkUtils.isValidUrl(item.proposerAvatar || '')
                                    ? item.proposerAvatar
                                    : defaultUserPlaceholderImage;
                                const username = item.proposerName || FormatUtils.formatUserAddress(item.proposer);
                                const feedType = FEED_CARD_TYPES[item.type];

                                return (
                                    <FeedCardItems
                                        key={item.id}
                                        id={item.nativeId}
                                        username={username}
                                        userImage={userLogoLink as string}
                                        title={item.title}
                                        organizationImage={LinkUtils.getOrganizationLogo(item.organizationLogo || '')}
                                        organizationName={item.organizationName || DATA_NOT_FOUND}
                                        userLink={LinkUtils.createDynamicLink(item.proposer, 'user')}
                                        createdAt={item.createdAt}
                                        organizationLink={LinkUtils.createDynamicLink(
                                            item.organizationId,
                                            'organization',
                                        )}
                                        status={item.status}
                                        description={item.description}
                                        type={feedType}
                                        proposalType={setProposalType(item.type, item.platform)}
                                        mainSiteUrl={item.url}
                                    />
                                );
                            })}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        </>
    );
};

import React, { FC, ReactElement } from 'react';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import styles from './styles.module.scss';

import { TooltipWrapper } from 'components';

import { IDetailedDescriptionSectionProps } from 'types/interfaces';

export const DetailedDescriptionSection: FC<IDetailedDescriptionSectionProps> = ({
    title,
    description,
    infoText,
}: IDetailedDescriptionSectionProps): ReactElement => (
    <div className={styles.detailedDescriptionSection}>
        <h5 className={styles.title}>{title}</h5>
        <TooltipWrapper title={infoText}>
            <InfoOutlinedIcon className={styles.infoIcon} />
        </TooltipWrapper>

        {description && <h6 className={styles.description}>{description}</h6>}
    </div>
);

import { DEEP_DAO_API } from './apis';
import { DEEP_DAO_MAIL } from './deepDaoLinks';

import { IDetailedPlansPricings, ISelectOptions, IPremiumPlanDetails, ISubscriptionFaq } from 'types/interfaces';

export enum PLANS_PAGES_INDEXES {
    PLANS_INFO = 1,
    ACCOUNT_INFO = 2,
    PAYMENT = 3,
}

export enum PLANS_DURATIONS {
    MONTHLY_PLAN_DURATION = 'MONTHLY',
    YEARLY_PLAN_DURATION = 'ANNUALLY',
}

export enum PLAN_NAMES {
    STARTER = 'Ecosystem',
    PRO = 'DAO Analytics',
    MARKETING = 'MARKETING',
    CUSTOM = 'CUSTOM',
    BASIC = 'BASIC',
}

export enum PLANS_PRICINGS {
    STARTER_MONTHLY = 45,
    STARTER_YEARLY = 450,
    PRO_MONTHLY = 750,
    PRO_YEARLY = 7500,
    CUSTOM = 3000,
}

export enum PLAN_ACTIONS {
    CREATING = 'CREATING',
    UPDATING = 'UPDATING',
}

export enum PAYMENT_PROVIDERS {
    BLUESNAP = 'bluesnap',
    PAYPAL = 'paypal',
}

export enum PAYMENT_STATUSES {
    ACTIVE = 'ACTIVE',
    CANCELED = 'CANCELED',
}

export const detailedPlansPricings: IDetailedPlansPricings = {
    [PLAN_NAMES.STARTER]: [
        {
            id: 1,
            pricing: PLANS_PRICINGS.STARTER_MONTHLY,
            duration: PLANS_DURATIONS.MONTHLY_PLAN_DURATION,
        },
        {
            id: 2,
            pricing: PLANS_PRICINGS.STARTER_YEARLY,
            duration: PLANS_DURATIONS.YEARLY_PLAN_DURATION,
        },
    ],
    [PLAN_NAMES.PRO]: [
        {
            id: 1,
            pricing: PLANS_PRICINGS.PRO_MONTHLY,
            duration: PLANS_DURATIONS.MONTHLY_PLAN_DURATION,
        },
        {
            id: 2,
            pricing: PLANS_PRICINGS.PRO_YEARLY,
            duration: PLANS_DURATIONS.YEARLY_PLAN_DURATION,
        },
    ],
};

export enum PAYMENT_METHODS {
    CREDIT_CARD = 'CREDIT_CARD',
    PAYPAL = 'PAYPAL',
}

export const plansDurations: ISelectOptions[] = [
    {
        id: 1,
        name: 'Monthly',
        value: PLANS_DURATIONS.MONTHLY_PLAN_DURATION,
    },
    {
        id: 2,
        name: 'Yearly',
        value: PLANS_DURATIONS.YEARLY_PLAN_DURATION,
    },
];

export const paymentMethods: ISelectOptions[] = [
    {
        id: 1,
        name: 'paypal',
        value: PAYMENT_METHODS.PAYPAL,
    },
    {
        id: 2,
        name: 'credit card',
        value: PAYMENT_METHODS.CREDIT_CARD,
    },
];

export const plansDetailsList: IPremiumPlanDetails[] = [
    {
        id: 1,
        title: PLAN_NAMES.STARTER,
        planName: PLAN_NAMES.STARTER,
        emptyPricing: true,
        showContactLinkInDescription: true,
        documentation: {
            link: DEEP_DAO_API,
            title: 'api.deepdao.io',
        },
        planInfo: [
            {
                id: 1,
                text: 'Full ecosystem stats',
            },
            {
                id: 2,
                text: 'Organizations list',
            },
            {
                id: 3,
                text: 'DAO Categories',
            },
            {
                id: 4,
                text: 'People (G-score and partial profiles)',
            },
            {
                id: 5,
                text: 'Ecosystem Time series',
            },
            {
                id: 6,
                text: 'API Documentation: ',
                bold: true,
                linkItems: {
                    linkText: 'DeepDAO API',
                    link: 'https://api.deepdao.io',
                },
            },
            {
                id: 7,
                text: 'Products documentation: ',
                bold: true,
                linkItems: {
                    linkText: 'Products',
                    link: 'https://deepdao.gitbook.io/deepdao-products/',
                },
            },
        ],
    },
    {
        id: 2,
        title: PLAN_NAMES.PRO,
        planName: PLAN_NAMES.PRO,
        planDescription: 'Everything in Ecosystem plus',
        emptyPricing: true,
        showContactLinkInDescription: true,
        documentation: {
            link: DEEP_DAO_API,
            title: 'api.deepdao.io',
        },
        planInfo: [
            {
                id: 1,
                text: 'People: full DAO Experience profiles, including votes and proposals, rankings, DAO Friends and DAO Antagonists',
            },
            {
                id: 2,
                text: 'Top active participants in each organization based on activity',
            },
            {
                id: 3,
                text: 'Similar organizations (based on mutual voters, and on treasury tokens)',
            },
            {
                id: 5,
                text: 'DAO Participants Boxscore',
            },
            {
                id: 6,
                text: 'Voting Predictions: individuals, organizations, and categories',
            },
            {
                id: 7,
                text: 'Treasury trends (over the past 24h, 7d, 30d, 180d, 1y)',
            },
            {
                id: 8,
                text: 'Governance trends (over the past 24h, 7d, 30d, 180d, 1y)',
            },
            {
                id: 9,
                text: 'API Documentation: ',
                bold: true,
                linkItems: {
                    linkText: 'DeepDAO API',
                    link: 'https://api.deepdao.io',
                },
            },
            {
                id: 10,
                text: 'Products documentation: ',
                bold: true,
                linkItems: {
                    linkText: 'Products',
                    link: 'https://deepdao.gitbook.io/deepdao-products/',
                },
            },
        ],
    },
    {
        id: 3,
        title: 'Top People in Governance',
        planName: 'Marketing',
        emptyPricing: true,
        showContactLinkInDescription: true,
        planInfo: [
            {
                id: 1,
                text: 'Create your own segment of the top people in governance.',
            },
            {
                id: 2,
                text: 'Across DAOs, DAO Categories, and time range',
            },
            {
                id: 3,
                text: `All lists are based on DeepDAO's G-score`,
            },
            {
                id: 4,
                text: 'Use cases: Incentive Campaigns (airdrops and others), Launching a new DAO, Marketing DAO tools, Academic Research',
            },
        ],
    },
];

export const subscriptionFaqList: ISubscriptionFaq[] = [
    {
        id: 1,
        title: 'The most complete aggregation of DAO data and',
    },
    {
        id: 2,
        title: 'Get a step ahead of your DAO competition',
    },
    {
        id: 3,
        title: 'Trends and insights for navigating the DAO ecosystem.',
    },
    {
        id: 4,
        title: 'For custom data contact us at ',
        linkItems: {
            link: DEEP_DAO_MAIL,
            linkText: 'info@deepdao.io',
        },
    },
];

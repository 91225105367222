import React, { FC, ReactElement, useRef } from 'react';

import { deepDaoLogoCircle } from 'assets';

import styles from './styles.module.scss';
import classNames from 'classnames';

const LARGE_BANNER_STARTER_WIDTH = 800;

interface IPremiumTabProps {
    title?: string;
    descriptions?: string[];
    // add if needed
}

export const PremiumTab: FC<IPremiumTabProps> = ({ title, descriptions }: IPremiumTabProps): ReactElement => {
    const bannerRef = useRef<HTMLDivElement | null>(null);
    const isMediumBanner = Number(bannerRef?.current?.clientWidth) < LARGE_BANNER_STARTER_WIDTH;

    return (
        <div
            className={classNames(styles.bannerWrapper, { [styles.mediumBannerWrapper]: isMediumBanner })}
            ref={bannerRef}
        >
            <div className={styles.bannerContainer}>
                <img src={deepDaoLogoCircle} className={styles.logoCircle} alt="" />
                <h1 className={styles.title}>{title || 'Explore further with DeepDao'}</h1>
                {descriptions?.map((description: string) => (
                    <h5 key={description} className={styles.description}>
                        {description}
                    </h5>
                ))}

                {/*<div className={styles.buttonsContainer}>*/}
                {/*    <Link to="/products">*/}
                {/*        <Button className={styles.loginButton}>Subscribe</Button>*/}
                {/*    </Link>*/}
                {/*    <Link to="/premium_email_login">*/}
                {/*        <Button className={styles.loginButton}>Login</Button>*/}
                {/*    </Link>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

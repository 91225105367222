import React, { FC, ReactElement, useEffect, useState } from 'react';
import styles from './styles.module.scss';

import { TableDataNotFound, Loader } from 'components';
import { TableHeader, CoalitionsItems } from './components';

import { useAppDispatch, useAppSelector } from 'store';
import { tableDataSort } from 'helpers';

import { ICoalitionItem, ISortedParam, ITabItems } from 'types/interfaces';
import { useTabSelector } from '../../../../../../../../hooks';
import { OrganizationActions } from 'store/actions';
import { COALITIONS_PARAMS, tabItems } from './constants';
import { CoalitionsRanking } from './components/CoalitionsRankingItems';
import { CoalitionsMetadata } from './components/CoalitionsMetadataItems';
import { CoalitionsTabs } from './components/CoalitionsTabs';

export const VotingCoalitionsTable: FC = (): ReactElement => {
    const {
        organizationCoalitionsData,
        coalitionsDataLoading,
        organizationData: { id: organizationId },
    } = useAppSelector(({ organization }) => organization);

    const dispatch = useAppDispatch();
    const { tabSelected, handleTabChange } = useTabSelector({} as ITabItems);
    const [sortedParam, setSortedParam] = useState<ISortedParam>({
        paramName: COALITIONS_PARAMS.VOTED_TOGETHER_PARAM,
        descending: true,
    });

    const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);

    const sortByParam = (param: string) => {
        setSortedParam({
            paramName: param,
            descending: sortedParam.paramName === param ? !sortedParam.descending : true,
        });
    };

    useEffect(() => {
        handleTabChange(tabItems.tabsList[0] || {});
        dispatch(OrganizationActions.getOrganizationCoalitionsData.request({ organizationId, membersInGroup: 2 }));
    }, [organizationId]);

    useEffect(
        () => () => {
            dispatch({ type: OrganizationActions.CLEAR_ORGANIZATION_COALITIONS_DATA });
        },
        [dispatch],
    );

    const coalitionsSortedData = tableDataSort([...organizationCoalitionsData], sortedParam);

    const onTabChange = (tab: ITabItems) => {
        if (tab.value !== tabSelected.value) {
            handleTabChange(tab);
            setSortedParam({
                paramName: COALITIONS_PARAMS.VOTED_TOGETHER_PARAM,
                descending: true,
            });
            dispatch(
                OrganizationActions.getOrganizationCoalitionsData.request({ organizationId, membersInGroup: tab.id }),
            );
            dispatch({ type: OrganizationActions.CLEAR_ORGANIZATION_COALITIONS_DATA });
        }
    };

    const handleMouseEnter = (index: number) => {
        setHoveredRowIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredRowIndex(null);
    };

    return (
        <div className={styles.tableContent}>
            <div className={styles.tableOptions}>
                <CoalitionsTabs
                    tableTabs={tabItems}
                    tabSelected={tabSelected}
                    handleTabChange={onTabChange}
                ></CoalitionsTabs>
            </div>

            {coalitionsDataLoading && coalitionsSortedData.length === 0 && (
                <div className={styles.loaderBox}>
                    <Loader size={40} className={styles.membersLoader} />
                </div>
            )}

            {!coalitionsDataLoading && coalitionsSortedData.length === 0 && <TableDataNotFound forTable />}

            {coalitionsSortedData.length > 0 && (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        <div className={styles.table}>
                            <TableHeader sortedParamName={sortedParam.paramName} sortByParam={sortByParam} />
                            <div className={styles.tableData}>
                                <div className={styles.ranks}>
                                    {coalitionsSortedData.map((coalition: ICoalitionItem, index: number) => (
                                        <CoalitionsRanking
                                            key={index}
                                            {...coalition}
                                            handleMouseEnter={handleMouseEnter}
                                            handleMouseLeave={handleMouseLeave}
                                            isHovered={hoveredRowIndex === index}
                                            rank={index + 1}
                                            sortedParamName={sortedParam.paramName}
                                        />
                                    ))}
                                </div>
                                <div className={styles.coalitionsWrapper}>
                                    <div className={styles.coalitions}>
                                        {coalitionsSortedData.map((coalition: ICoalitionItem, index: number) => (
                                            <CoalitionsItems
                                                key={index}
                                                {...coalition}
                                                handleMouseEnter={handleMouseEnter}
                                                handleMouseLeave={handleMouseLeave}
                                                isHovered={hoveredRowIndex === index}
                                                rank={index + 1}
                                                sortedParamName={sortedParam.paramName}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className={styles.metadata}>
                                    {coalitionsSortedData.map((coalition: ICoalitionItem, index: number) => (
                                        <CoalitionsMetadata
                                            key={index}
                                            {...coalition}
                                            handleMouseEnter={handleMouseEnter}
                                            handleMouseLeave={handleMouseLeave}
                                            isHovered={hoveredRowIndex === index}
                                            rank={index + 1}
                                            sortedParamName={sortedParam.paramName}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

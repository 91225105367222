import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

import { CustomSvgGradient, InfoCircle, Loader, RechartsCustomizedAxisTick, TableDataNotFound } from 'components';

import { IAvgVotesAndTokenPricePerDate } from '../../../../../../../../types/interfaces';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CustomTooltip } from './components';
import { useMediaQuery } from '@material-ui/core';
import { AreaChartGradient } from '../../../OrganizationTreasury/components/FundsGraph/constants';
import { CustomLegend } from './components/CustomLegend';

interface ITrendsTableProps {
    data: IAvgVotesAndTokenPricePerDate[];
    infoText: string;
    flDataKey: string;
    flName: string;
    slDataKey: string;
    slName: string;
    loading: boolean;
}

export const CommonVotingTokenGraph = ({
    data,
    infoText,
    flDataKey,
    flName,
    slDataKey,
    slName,
    loading,
}: ITrendsTableProps): ReactElement => {
    const isTablet = useMediaQuery('(max-width: 767.98px)');

    return (
        <div className={styles.graphCard}>
            <div className={styles.titleWrapper}>
                <h4 className={styles.tableTitle}>
                    {flName} / {slName}
                </h4>
                <InfoCircle tooltipText={infoText} className={styles.infoIcon} />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <ResponsiveContainer height={isTablet ? 270 : 335}>
                        <LineChart data={data}>
                            {CustomSvgGradient({
                                id: 'AreaChartGradient',
                                rotate: 90,
                                data: AreaChartGradient,
                            })}

                            <XAxis
                                dataKey="date"
                                tick={<RechartsCustomizedAxisTick offsetY={10} className={styles.axisTick} />}
                                tickLine={false}
                                axisLine={false}
                            />

                            <YAxis
                                yAxisId="left"
                                tick={
                                    <RechartsCustomizedAxisTick
                                        offsetX={-13}
                                        className={styles.axisTick}
                                        formatValue={true}
                                    />
                                }
                                tickLine={false}
                                width={40}
                                axisLine={false}
                            />

                            <YAxis
                                yAxisId="right"
                                orientation="right"
                                tick={
                                    <RechartsCustomizedAxisTick
                                        offsetX={13}
                                        className={styles.axisTick}
                                        formatValue={true}
                                    />
                                }
                                tickLine={false}
                                width={40}
                                axisLine={false}
                            />

                            <Tooltip
                                cursor={{ fill: '#DDEEFF' }}
                                content={
                                    <CustomTooltip
                                        firstColumn={flName}
                                        firstColumnDataKey={flDataKey}
                                        secondColumn={slName}
                                        secondColumnDataKey={slDataKey}
                                    />
                                }
                            />

                            <Legend content={<CustomLegend firstColumn={flName} secondColumn={slName} />} />

                            <Line
                                yAxisId="left"
                                type="linear"
                                dataKey={flDataKey}
                                stroke="#FFA500"
                                strokeWidth={2}
                                dot={false}
                            />

                            <Line
                                yAxisId="right"
                                type="linear"
                                dataKey={slDataKey}
                                stroke="#337BFF"
                                strokeWidth={2}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                {!data.length && !loading && <TableDataNotFound forGraph removeMargins />}
                {loading && <Loader className={styles.loader} />}
            </div>
        </div>
    );
};

import React, { FC, ReactElement, memo } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { IOrganizationSubstorm, ISubstormsWrapperProps } from 'types/interfaces';

export const SubstormsWrapper: FC<ISubstormsWrapperProps> = memo(
    ({
        substormsList,
        substormsListOpen,
        filter,
        handleUpdateSubstormsFilter,
    }: ISubstormsWrapperProps): ReactElement => (
        <div className={styles.substormsWrapper}>
            {substormsListOpen && (
                <div className={styles.substormsList}>
                    {substormsList.map((substormData: IOrganizationSubstorm) => (
                        <div
                            key={substormData.id}
                            className={classNames(styles.substorm, {
                                [styles.substormSelected]: Boolean(
                                    filter.substorms.find(({ id }: IOrganizationSubstorm) => substormData.id === id),
                                ),
                            })}
                            onClick={() => handleUpdateSubstormsFilter(substormData)}
                        >
                            <span className={styles.title}>{substormData.title}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    ),
);

import { call, takeLatest, put, all } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { PeopleApi } from 'store/apis';
import { PeopleActions } from 'store/actions';

import { IPeopleDataPayload } from 'types/interfaces';

function* getPeopleDataWorker({ payload }: IPeopleDataPayload): SagaIterator {
    try {
        const [peopleData, topPeopleTokensData, peopleMainInfo] = yield all([
            call(PeopleApi.getPeopleData, payload),
            call(PeopleApi.getPeopleStatsData),
            call(PeopleApi.getPeopleMainInfo),
        ]);
        yield put(
            PeopleActions.getAllPeopleData.success({
                peopleData: peopleData.data,
                aumByPopulation: topPeopleTokensData.data?.aumByPopulation,
                historyUsdAum: topPeopleTokensData.data?.historyUsdAum,
                topPeopleTokensData: topPeopleTokensData.data?.aumTokens,
                totalPeopleAumChanges: {
                    totalAum: topPeopleTokensData.data?.totalUsdAum,
                    changeWeek: topPeopleTokensData.data?.aumWeekChange,
                    changeMonth: topPeopleTokensData.data?.aumMonthChange,
                },
                hasMore: !!peopleData.data.length,
                lastUpdateDate: peopleMainInfo.data.lastUpdate,
                organizationsCount: peopleMainInfo.data.organizationsCount,
                enrichedOrganizationsCount: peopleMainInfo.data.enrichedOrganizationsCount,
            }),
        );
    } catch (e) {
        yield put(PeopleActions.getAllPeopleData.failure(e));
    }
}

function* getPeopleSortedData({ payload }: IPeopleDataPayload) {
    try {
        const { data } = yield call(PeopleApi.getPeopleData, payload);
        yield put(PeopleActions.getPeopleSortedData.success({ data, hasMore: !!data.length }));
    } catch (e) {
        yield put(PeopleActions.getPeopleSortedData.failure(e));
    }
}

export const peopleSaga = function* (): SagaIterator {
    yield takeLatest(PeopleActions.GET_ALL_PEOPLE_DATA, getPeopleDataWorker);
    yield takeLatest(PeopleActions.GET_PEOPLE_SORTED_DATA, getPeopleSortedData);
};

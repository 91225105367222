import { Reducer } from '@reduxjs/toolkit';

import { ClientsActions } from 'store/actions';

import type { IPremiumClientsState, IPremiumClientsActionsType } from 'types/interfaces';

const defaultState: IPremiumClientsState = {
    loading: false,
    error: '',
    created: false,
};

export const premiumClientsReducer: Reducer<IPremiumClientsState, IPremiumClientsActionsType> = (
    state: IPremiumClientsState = defaultState,
    action: IPremiumClientsActionsType,
): IPremiumClientsState => {
    const { type, error } = <IPremiumClientsActionsType>action;

    switch (type) {
        case ClientsActions.SAVE_CLIENT: {
            return {
                ...state,
                loading: true,
            };
        }

        case ClientsActions.SAVE_CLIENT_SUCCESS: {
            return {
                ...state,
                created: true,
                loading: false,
                error: '',
            };
        }

        case ClientsActions.SAVE_CLIENT_FAILURE: {
            return {
                ...state,
                error: error as string,
                loading: false,
                created: false,
            };
        }

        case ClientsActions.CLEAR_CREATED_FIELD: {
            return {
                ...state,
                created: false,
                error: '',
            };
        }

        default: {
            return state;
        }
    }
};

import React, { FC, ReactElement } from 'react';

import ExitToApp from '@material-ui/icons/ExitToApp';

import styles from './styles.module.scss';

import { IUserOptionsProps } from 'types/interfaces';

export const UserOptions: FC<IUserOptionsProps> = ({
    navigateToMyProfile,
    toggleConnection,
    navigateToMyFollowings,
}: IUserOptionsProps): ReactElement => {
    return (
        <div className={styles.loggedUserOptions}>
            <div className={styles.optionWrapper} onClick={navigateToMyProfile}>
                <span className={styles.optionName}>my profile</span>
            </div>
            <div className={styles.optionWrapper} onClick={navigateToMyFollowings}>
                <span className={styles.optionName}>following</span>
            </div>
            <div className={styles.inactiveOptionWrapper}>
                <span className={styles.optionName}>chats</span>
                <div className={styles.commingSoon}>
                    <span className={styles.text}>COMING SOON</span>
                </div>
            </div>
            <div className={styles.logoutOptionWrapper} onClick={toggleConnection}>
                <ExitToApp className={styles.logoutIcon} />
                <span className={styles.logoutText}>logout</span>
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { FollowButtonProps, FollowButtonTypes } from './helpers';

import styles from './styles.module.scss';

export const FollowButton: FC<FollowButtonProps> = ({
    isFollowedBack,
    handleFollow,
    handleUnfollow,
}: FollowButtonProps): ReactElement => {
    const handleClick = () => (isFollowedBack ? handleUnfollow() : handleFollow());

    return (
        <button
            className={classNames(styles.followButton, {
                [styles.isFollowed]: isFollowedBack,
            })}
            onClick={handleClick}
        >
            {isFollowedBack ? FollowButtonTypes.FOLLOWING : FollowButtonTypes.FOLLOW}
        </button>
    );
};

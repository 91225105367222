import moment from 'moment';

import { truncate, isNil } from 'lodash';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

const maxNumberValue = 1000;

const DEFAULT_DATE_FORMAT = 'MMM. Do YYYY';
const DEFAULT_LAST_UPDATE_DATE_FORMAT = 'MMM. Do';
const SHORT_DATE_FORMAT = 'DD.MM.YYYY';

class FormatUtils {
    static formatNumberShort = (number: number, digits: number, showZero?: boolean): string => {
        const lookup: { value: number; symbol: string }[] = [
            { value: 1, symbol: '' },
            { value: 1e3, symbol: 'k' },
            { value: 1e6, symbol: 'M' },
            { value: 1e9, symbol: 'B' },
            { value: 1e12, symbol: 'T' },
            { value: 1e15, symbol: 'P' },
            { value: 1e18, symbol: 'E' },
        ];

        if (number === 0 && showZero) {
            return '0';
        }

        const absoluteNumber = Math.abs(number);

        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        const item = lookup
            .slice()
            .reverse()
            .find((item) => absoluteNumber >= item.value);
        const result: string = item
            ? (absoluteNumber / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
            : absoluteNumber.toFixed(digits);

        return number >= 0 ? result : `-${result}`;
    };

    static getFormattedLastUpdateDate = (date: string | undefined | null): string =>
        date ? moment(date).format(`${DEFAULT_LAST_UPDATE_DATE_FORMAT}, h:mm`) : DATA_NOT_FOUND;

    static formatNumberWithCommas = (num: number | bigint, n: number, x?: number): string => {
        const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
        return (
            typeof num === 'number'
                ? num < maxNumberValue
                    ? num.toFixed(n)
                    : num.toFixed(Math.max(0, ~~n))
                : num.toString()
        ).replace(new RegExp(re, 'g'), '$&,');
    };

    static getFormattedDate = (date: string | undefined, format = DEFAULT_DATE_FORMAT): string =>
        date ? moment(date).format(format) : DATA_NOT_FOUND;

    static formatUserAddress = (address = ''): string =>
        address.length >= 10 ? address.slice(0, 6) + '...' + address.slice(-4) : address;

    static truncateString = (str: string, maxLength: number): string => truncate(str, { length: maxLength });

    static formatFeedDate = (date?: string | null): string =>
        date ? moment(date).format(DEFAULT_DATE_FORMAT) : DATA_NOT_FOUND;

    static formatExistingNumber = (num?: number | string | null | undefined, n = 2): string | number => {
        if (!FormatUtils.checkIfDataNotExist(num))
            return Number(num) >= 0 ? FormatUtils.formatNumberWithCommas(Number(num), n) : DATA_NOT_FOUND;
        return DATA_NOT_FOUND;
    };
    static convertToBigIntNumber = (num: number | string | bigint): number | bigint =>
        Number(num) > Number.MAX_SAFE_INTEGER
            ? String(num)?.includes('e')
                ? BigInt(num)
                : BigInt(String(num).split('.')[0])
            : Number(num);

    static checkIfDataNotExist = (num: unknown): boolean => isNil(num);

    static getNoramalizedArrayData = (data: string | (string | null)[] | null): string[] => {
        if (Array.isArray(data)) {
            return data as string[];
        } else if (data) {
            return [data] as string[];
        }
        return [];
    };

    static transformTextToHtml(text: string, link: string): string {
        const taggedText: RegExpMatchArray | null = text.match(/@\[.*?\]/g);
        const taggedMembers = new Map<string, string>();

        if (taggedText?.length)
            for (const taggedMember of taggedText) {
                const taggedData: string[] = taggedMember.slice(2, -1).split(',');
                const address: string = taggedData[1];
                taggedMembers.set(taggedData[0], address);
            }

        const transformedText: string = text
            .split(/@\[|.\w+\]/g)
            .map((word) =>
                taggedMembers.has(word) && taggedMembers
                    ? FormatUtils.getTaggedMemberHtmlLink(link + '/', word, taggedMembers.get(word))
                    : word,
            )
            .join(' ');
        const htmlText = `<p>${transformedText.replace(/\n/g, '<br>')}</p>`;

        return htmlText;
    }

    static getTaggedMemberHtmlLink = (url: string, mentionedData: string, address?: string): string => {
        return `<a href="${url}user/${address}/verified_dao_experience">${mentionedData}</a>`;
    };

    static getFormattedShortDate = (date: string | undefined): string =>
        date ? moment(date).format(SHORT_DATE_FORMAT) : DATA_NOT_FOUND;

    static getSnakeCaseText = (text: string): string =>
        text.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);

    static truncDigits = (inputNumber: number | string, digits: number): string => {
        const fact = 10 ** digits;

        return (Math.floor(Number(inputNumber) * fact) / fact).toString();
    };
}

export { FormatUtils };

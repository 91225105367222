import React, { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import classNames from 'classnames';

import copy from 'copy-to-clipboard';

import { isEmpty } from 'lodash';

import styles from './styles.module.scss';

import { AccountSettingBlock, HrefLink, Loader } from 'components';

import { DEEP_DAO_API, DEEP_DAO_API_PRO } from 'constants/apis';
import { DATA_NOT_FOUND } from 'constants/dataNotFound';
import { ALERT_TYPES, SUCCESS_MESSAGES } from 'constants/alert';
import { PAYMENT_STATUSES, PLAN_NAMES } from 'constants/plansItems';

import { useAppDispatch, useAppSelector } from 'store';
import { AlertActions, PremiumSubscriptionActions } from 'store/actions';
import { FormatUtils } from 'utils';
import { useHistoryPush } from 'hooks';
import { getApiKeyExpirationInfo } from './utils';
import { getPremiumSubscriptionIdFromStorage } from 'web3/storageHelper';

export const AccountSettingsPage: FC = (): ReactElement => {
    const dispatch = useAppDispatch();

    const { premiumUserData, premiumUserDataLoading, apiKey } = useAppSelector(
        ({ premiumSubscription }) => premiumSubscription,
    );

    const subscriptionId = getPremiumSubscriptionIdFromStorage();

    const { navigateToProducts } = useHistoryPush();

    useEffect(() => {
        if (isEmpty(premiumUserData)) dispatch(PremiumSubscriptionActions.getPremiumUserData.request());
    }, []);

    useEffect(() => {
        if (subscriptionId && !apiKey) {
            dispatch(PremiumSubscriptionActions.getApiKey.request({ subscriptionId }));
        }
    }, [subscriptionId, apiKey]);

    const onCopyApiKeyClick = useCallback(() => {
        if (apiKey) {
            copy(apiKey);
            dispatch({
                type: AlertActions.SHOW_ALERT,
                payload: {
                    title: SUCCESS_MESSAGES.SUCCESFULLY_COPIED,
                    type: ALERT_TYPES.SUCCESS,
                },
            });
        }
    }, [apiKey]);

    const apiKeyExpirationInfo = getApiKeyExpirationInfo(premiumUserData.keyExpirationDate);

    const optionsList: any = useMemo(
        () => [
            {
                id: 1,
                sectionTitle: <p className={styles.sectionTitle}>API Key</p>,
                description: (
                    <>
                        <h6 className={styles.descriptionText}>{apiKey || DATA_NOT_FOUND}</h6>
                        {premiumUserData.keyExpirationDate && (
                            <h6
                                className={classNames(styles.descriptionText, styles.descriptionTextMarginTop, {
                                    [styles.boldText]: premiumUserData.cancelledPaymentDate,
                                })}
                            >
                                Your key is valid until {apiKeyExpirationInfo?.keyExpirationDateFormatted}
                            </h6>
                        )}
                    </>
                ),
                className: styles.alignPositionCenter,
                dependingFields: [
                    premiumUserData.status === PAYMENT_STATUSES.ACTIVE || apiKeyExpirationInfo?.isKeyValid,
                ],
                option: (
                    <Button
                        className={styles.buttonGreen}
                        disableRipple
                        disabled={!Boolean(apiKey)}
                        onClick={onCopyApiKeyClick}
                    >
                        copy
                    </Button>
                ),
            },
            {
                id: 2,
                sectionTitle: (
                    <p className={styles.sectionTitle}>
                        {premiumUserData.status ? (
                            <>
                                <span className={styles.planName}>{premiumUserData.planName}</span>
                                {premiumUserData.autoRenew ? (
                                    ' plan'
                                ) : premiumUserData.planName.toUpperCase() === PLAN_NAMES.PRO ? (
                                    <HrefLink href={DEEP_DAO_API_PRO} className={styles.titleLink}>
                                        <>
                                            <span className={styles.linkText}>: api.deepdao.io/pro</span>
                                        </>
                                    </HrefLink>
                                ) : (
                                    <HrefLink href={DEEP_DAO_API} className={styles.titleLink}>
                                        <>
                                            <span className={styles.linkText}>: api.deepdao.io</span>
                                        </>
                                    </HrefLink>
                                )}
                            </>
                        ) : (
                            <span>{`You do not have a subscription`}</span>
                        )}
                    </p>
                ),
                description: (
                    <>
                        {premiumUserData.status && (
                            <>
                                <h6 className={styles.descriptionText}>
                                    {premiumUserData.cancelledPaymentDate
                                        ? `Subscribed from ${FormatUtils.getFormattedShortDate(
                                              premiumUserData.paymentDate,
                                          )} - ${FormatUtils.getFormattedShortDate(
                                              premiumUserData.cancelledPaymentDate,
                                          )}`
                                        : `Subscribed from ${FormatUtils.getFormattedShortDate(
                                              premiumUserData.paymentDate,
                                          )} Renews
                      automatically on ${FormatUtils.getFormattedShortDate(premiumUserData.nextBillingTime)}`}
                                </h6>

                                <h6
                                    className={classNames(styles.descriptionText, styles.descriptionTextMarginTop, {
                                        [styles.boldText]: premiumUserData.cancelledPaymentDate,
                                    })}
                                >
                                    {premiumUserData.cancelledPaymentDate
                                        ? `Canceled on ${FormatUtils.getFormattedShortDate(
                                              premiumUserData.cancelledPaymentDate,
                                          )}`
                                        : `Billing: $${FormatUtils.formatNumberShort(premiumUserData.value, 1)} ${
                                              premiumUserData.planPeriod
                                          }`}
                                </h6>
                            </>
                        )}
                    </>
                ),
                option: premiumUserData.status === PAYMENT_STATUSES.CANCELED && (
                    <Button className={styles.buttonGreen} disableRipple onClick={navigateToProducts}>
                        see plans
                    </Button>
                ),
            },
            {
                id: 3,
                sectionTitle: <p className={styles.sectionTitle}>Documentation</p>,
                description: (
                    <div className={styles.apiLinksWrapper}>
                        <HrefLink href={DEEP_DAO_API} className={styles.textLink}>
                            <span className={styles.text}>api.deepdao.io</span>
                        </HrefLink>

                        <HrefLink href={DEEP_DAO_API_PRO} className={styles.textLink}>
                            <span className={styles.text}>api.deepdao.io/pro</span>
                        </HrefLink>
                    </div>
                ),
            },
            {
                id: 4,
                sectionTitle: <p className={styles.sectionTitle}>Account email</p>,
                description: <h6 className={styles.descriptionText}>{premiumUserData.email}</h6>,
                option: (
                    <Link to="/premium_login_update" className={styles.link}>
                        <span className={styles.linkTitle}>Update</span> <ArrowBackIos className={styles.arrowIcon} />
                    </Link>
                ),
            },
            {
                id: 6,
                sectionTitle: <p className={styles.sectionTitle}>Cancel Subscription</p>,
                className: styles.alignPositionCenter,
                dependingFields: [premiumUserData.autoRenew],
                option: (
                    <Link to="/cancel_premium_subscription" className={styles.link}>
                        <span className={styles.linkTitle}>Cancel subscription</span>{' '}
                        <ArrowBackIos className={styles.arrowIcon} />
                    </Link>
                ),
            },
            {
                id: 7,
                sectionTitle: <p className={styles.sectionTitle}>Invoices</p>,
                className: styles.alignPositionCenter,
                option: (
                    <Link to="/premium_account_invoices" className={styles.link}>
                        <span className={styles.linkTitle}>View</span> <ArrowBackIos className={styles.arrowIcon} />
                    </Link>
                ),
            },
        ],
        [apiKey, onCopyApiKeyClick, premiumUserData, apiKeyExpirationInfo],
    );

    return (
        <div className={styles.wrapper}>
            {premiumUserDataLoading ? (
                <Loader />
            ) : (
                <div className={styles.container}>
                    <h1 className={styles.title}>Settings</h1>
                    <div className={styles.optionsContainer}>
                        {optionsList
                            .filter((items: any) =>
                                items.dependingFields ? items.dependingFields.every((items: any) => items) : items,
                            )
                            .map((items: any) => (
                                <AccountSettingBlock key={items.id} {...items} />
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
};

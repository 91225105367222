import React, { FC, ReactElement, useCallback, useState, useEffect } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';

import styles from './styles.module.scss';

import { Loader } from 'components';

import { setPDFFileByUrl, setMixpanelEventByUrl } from './utils';
import { mixpanelService } from 'services';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFViewPage: FC = (): ReactElement => {
    const [numPages, setNumPages] = useState<number | null>(null);

    const onDocumentLoadSuccess = useCallback(
        ({ numPages }: { numPages: number }) => {
            setNumPages(numPages);
        },
        [numPages],
    );

    useEffect(() => {
        setMixpanelEventByUrl(window.location.pathname) &&
            mixpanelService.track(setMixpanelEventByUrl(window.location.pathname) as string);
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <Document
                    file={setPDFFileByUrl(window.location.pathname)}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={styles.pdfWrapper}
                    loading={<Loader />}
                >
                    {Array.from(new Array(numPages), (_, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            className={styles.pageWrapper}
                            loading={<Loader />}
                        />
                    ))}
                </Document>
            </div>
        </div>
    );
};

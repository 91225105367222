import { Reducer, AnyAction } from '@reduxjs/toolkit';

import { FeedProposalActions } from 'store/actions';

import { IFeedProposalState, IFeedProposalItems } from 'types/interfaces';

const defaultState: IFeedProposalState = {
    loading: true,
    feedProposalData: {} as IFeedProposalItems,
};

export const feedProposalReducer: Reducer<IFeedProposalState, AnyAction> = (
    state: IFeedProposalState = defaultState,
    action: AnyAction,
): IFeedProposalState => {
    const { type, payload } = <AnyAction>action;

    switch (type) {
        case FeedProposalActions.GET_FEED_PROPOSAL_DATA: {
            return {
                ...state,
            };
        }

        case FeedProposalActions.GET_FEED_PROPOSAL_DATA_SUCCESS: {
            return {
                ...state,
                ...payload,
                loading: false,
            };
        }

        case FeedProposalActions.GET_FEED_PROPOSAL_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case FeedProposalActions.CLEAR_FEED_PROPOSAL_DATA: {
            return {
                ...defaultState,
            };
        }

        default: {
            return state;
        }
    }
};

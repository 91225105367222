import React, { FC, ReactElement, useEffect } from 'react';

import { Link } from 'react-router-dom';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import classNames from 'classnames';

import { isEmpty } from 'lodash';

import styles from './styles.module.scss';

import { CheckboxComponent, Loader, SelfListingInput } from 'components';

import { PLANS_PAGES_INDEXES } from 'constants/plansItems';

import { IUserAccountInfoProps } from 'types/interfaces';

export const UserAccountInfo: FC<IUserAccountInfoProps> = ({
    formik,
    clientError,
    accountCreated,
    changePlansPage,
    createUserAccount,
    loading,
}: IUserAccountInfoProps): ReactElement => {
    useEffect(() => {
        if (accountCreated && changePlansPage) changePlansPage(PLANS_PAGES_INDEXES.PAYMENT);
    }, [accountCreated]);

    return (
        <div className={styles.sectionContent}>
            <div className={styles.textInfo}>
                <h1 className={styles.title}>Create your account</h1>
                <div className={styles.loggedUserOptions}>
                    Already have an account? Please{' '}
                    <Link to="/premium_email_login" className={styles.loginLink}>
                        log in
                    </Link>
                </div>
            </div>

            <div className={styles.formContainer}>
                <h6 className={styles.description}>Enter your personal details below</h6>

                <form className={styles.billingForm} onSubmit={createUserAccount}>
                    <div className={styles.details}>
                        <SelfListingInput
                            placeholder="Full Name *"
                            name="userInfo.fullName"
                            value={formik.values.userInfo.fullName}
                            touched={formik.touched?.userInfo?.fullName}
                            error={formik.errors?.userInfo?.fullName}
                            onChange={formik.handleChange}
                            onTouch={formik.setFieldTouched}
                        />
                    </div>
                    <div className={styles.details}>
                        <SelfListingInput
                            placeholder="Email *"
                            name="userInfo.email"
                            value={formik.values.userInfo.email}
                            touched={formik.touched?.userInfo?.email}
                            error={formik.errors?.userInfo?.email}
                            onChange={formik.handleChange}
                            onTouch={formik.setFieldTouched}
                        />
                    </div>
                    <div className={styles.details}>
                        <SelfListingInput
                            placeholder="Repeat email *"
                            name="userInfo.repeatedEmail"
                            value={formik.values.userInfo.repeatedEmail}
                            touched={formik.touched?.userInfo?.repeatedEmail}
                            error={formik.errors?.userInfo?.repeatedEmail}
                            onChange={formik.handleChange}
                            onTouch={formik.setFieldTouched}
                        />
                    </div>
                    <div className={classNames(styles.details, styles.phoneField)}>
                        <div className={styles.countryCodeInputWrapper}>
                            <SelfListingInput
                                placeholder="Country Code"
                                name="userInfo.countryCode"
                                value={formik.values.userInfo.countryCode}
                                touched={formik.touched?.userInfo?.countryCode}
                                error={formik.errors?.userInfo?.countryCode}
                                onChange={formik.handleChange}
                                onTouch={formik.setFieldTouched}
                            />
                        </div>
                        <div className={styles.phoneInputWrapper}>
                            <SelfListingInput
                                placeholder="Phone Number"
                                name="userInfo.phoneNumber"
                                value={formik.values.userInfo.phoneNumber}
                                touched={formik.touched?.userInfo?.phoneNumber}
                                error={formik.errors?.userInfo?.phoneNumber}
                                onChange={formik.handleChange}
                                onTouch={formik.setFieldTouched}
                            />
                        </div>
                    </div>
                    <div className={styles.details}>
                        <SelfListingInput
                            placeholder="Address"
                            name="userInfo.address"
                            value={formik.values.userInfo.address}
                            touched={formik.touched?.userInfo?.address}
                            error={formik.errors?.userInfo?.address}
                            onChange={formik.handleChange}
                            onTouch={formik.setFieldTouched}
                        />
                    </div>
                    <div className={classNames(styles.details, styles.phoneField)}>
                        <div className={styles.countryInputWrapper}>
                            <SelfListingInput
                                placeholder="Country"
                                name="userInfo.country"
                                value={formik.values.userInfo.country}
                                touched={formik.touched?.userInfo?.country}
                                error={formik.errors?.userInfo?.country}
                                onChange={formik.handleChange}
                                onTouch={formik.setFieldTouched}
                            />
                        </div>
                        <div className={styles.zipCodeInputWrapper}>
                            <SelfListingInput
                                placeholder="Zip Code"
                                name="userInfo.postalCode"
                                value={formik.values.userInfo.postalCode}
                                touched={formik.touched?.userInfo?.postalCode}
                                error={formik.errors?.userInfo?.postalCode}
                                onChange={formik.handleChange}
                                onTouch={formik.setFieldTouched}
                            />
                        </div>
                    </div>
                    <div className={styles.agreements}>
                        <CheckboxComponent
                            checked={formik.values.userInfo.rightsApproved}
                            onCheckboxChange={() => {
                                formik.setFieldValue('userInfo.rightsApproved', !formik.values.userInfo.rightsApproved);
                            }}
                        />
                        <p className={styles.agreementsText}>
                            I understand and agree to the DeepDAO{' '}
                            <Link to="/api_tos" target={'_blank'} rel={'noreferrer'} className={styles.documentLink}>
                                API terms of service
                            </Link>{' '}
                            and{' '}
                            <Link to="/privacy" target={'_blank'} rel={'noreferrer'} className={styles.documentLink}>
                                Privacy policy.
                            </Link>
                        </p>
                    </div>
                    {formik.touched?.userInfo?.rightsApproved && formik.errors?.userInfo?.rightsApproved && (
                        <p className={styles.errorText}>{formik.errors?.userInfo?.rightsApproved}</p>
                    )}
                    <button
                        type="submit"
                        className={styles.continueBtn}
                        disabled={!(isEmpty(formik.errors?.userInfo) && formik.dirty)}
                    >
                        {loading ? (
                            <Loader className={styles.loader} size={30} />
                        ) : (
                            <>
                                <p className={styles.continueBtnText}>continue</p>
                                <ArrowForwardIcon className={styles.arrowIcon} />
                            </>
                        )}
                    </button>
                </form>

                <h6 className={styles.errorText}>{clientError.toString()}</h6>
            </div>
        </div>
    );
};

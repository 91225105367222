import React, { FC, ReactElement, useCallback, useState } from 'react';

import { Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { Moment } from 'moment';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { DrawerWrapper, SelectedFilterElement } from './components';

import { IDiscussionsFilterMember, IMobileFiltersProps, IOrganizationSubstorm } from 'types/interfaces';

export const MobileFilters: FC<IMobileFiltersProps> = ({
    handleUpdateOrderFilter,
    filter,
    handleUpdateOrderByTotalVotersFilter,
    isLogged,
    handleUpdateOnlyMyFilter,
    handleUpdateFilterDates,
    handleOpenDateRangePicker,
    isDateRangePickerOpen,
    membersSearch,
    setMembersSearch,
    isInputOpen,
    discussionsFilterMembers,
    onInputClick,
    handleUpdateMembersFilter,
    handleUpdateOrderByNumberOfMembersFilter,
    handleUpdateOrderByNumberOfTokensFilter,
    getFormattedDate,
    dateFilterBreakPoints,
    dateRangePickerSelectedDates,
    handleUpdateSubstormsFilter,
    substormsList,
    substormsListOpen,
    setSubstormsListOpen,
}: IMobileFiltersProps): ReactElement => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const onDateClick = useCallback(() => {
        handleOpenDateRangePicker();
        setDrawerOpen(true);
    }, [setDrawerOpen, handleOpenDateRangePicker]);

    const onDrawerClose = useCallback(() => {
        isInputOpen && onInputClick(false);
        isDateRangePickerOpen && handleOpenDateRangePicker();
        substormsListOpen && setSubstormsListOpen();
        setDrawerOpen(false);
    }, [isInputOpen, isDateRangePickerOpen, drawerOpen, substormsListOpen]);

    return (
        <div className={styles.mobileFiltersWrapper}>
            <div className={styles.filterHeader}>
                <h5 className={styles.title}>Brainstorm</h5>
                <div className={styles.mainFiltersContainer}>
                    <div className={styles.buttonWrapper}>
                        <Button className={styles.button} onClick={() => setDrawerOpen(true)}>
                            <FilterListIcon className={styles.filterIcon} />
                        </Button>
                    </div>
                    <div className={styles.buttonWrapper}>
                        <Button className={styles.button} onClick={onDateClick}>
                            <DateRangeIcon className={styles.dateIcon} />
                        </Button>
                    </div>
                    <div
                        className={styles.orderFilter}
                        onClick={() => handleUpdateOrderFilter(filter.order === 'DESC' ? 'ASC' : 'DESC')}
                    >
                        <ArrowForwardIcon
                            className={classNames(styles.arrowIcon, styles.orderDesc, {
                                [styles.orderAsc]: filter.order === 'ASC',
                            })}
                        />
                        <span
                            className={classNames(styles.text, [
                                { [styles.bold]: filter.order === 'ASC' || filter.order === 'DESC' },
                            ])}
                        >
                            {filter.order === 'ASC' ? 'earliest' : 'latest'}
                        </span>
                    </div>
                </div>
            </div>

            <div className={styles.selectedFiltersContainer}>
                {dateRangePickerSelectedDates.label && (
                    <SelectedFilterElement
                        text={
                            dateRangePickerSelectedDates.label === 'Custom'
                                ? `${getFormattedDate(
                                      dateRangePickerSelectedDates.from as Moment,
                                      'MM.DD.YY',
                                  )} - ${getFormattedDate(dateRangePickerSelectedDates.to as Moment, 'MM.DD.YY')}`
                                : dateRangePickerSelectedDates.label
                        }
                        onRemove={() => handleUpdateFilterDates()}
                    />
                )}

                {filter.members.length > 0 &&
                    filter.members.map(({ address, name, logo }: IDiscussionsFilterMember) => (
                        <SelectedFilterElement
                            key={address}
                            text={name || address}
                            onRemove={() => handleUpdateMembersFilter({ address, name, logo })}
                        />
                    ))}

                {filter.substorms.length > 0 &&
                    filter.substorms.map((substorm: IOrganizationSubstorm) => (
                        <SelectedFilterElement
                            key={substorm.id}
                            text={substorm.title}
                            onRemove={() => handleUpdateSubstormsFilter(substorm)}
                        />
                    ))}
            </div>

            <DrawerWrapper
                drawerOpen={drawerOpen}
                onClose={onDrawerClose}
                handleUpdateOrderByTotalVotersFilter={handleUpdateOrderByTotalVotersFilter}
                filter={filter}
                isLogged={isLogged}
                handleUpdateOnlyMyFilter={handleUpdateOnlyMyFilter}
                membersSearch={membersSearch}
                setMembersSearch={setMembersSearch}
                isInputOpen={isInputOpen}
                onInputClick={onInputClick}
                discussionsFilterMembers={discussionsFilterMembers}
                handleUpdateMembersFilter={handleUpdateMembersFilter}
                handleUpdateOrderByNumberOfMembersFilter={handleUpdateOrderByNumberOfMembersFilter}
                handleUpdateOrderByNumberOfTokensFilter={handleUpdateOrderByNumberOfTokensFilter}
                isDateRangePickerOpen={isDateRangePickerOpen}
                dateFilterBreakPoints={dateFilterBreakPoints}
                handleUpdateFilterDates={handleUpdateFilterDates}
                dateRangePickerSelectedDates={dateRangePickerSelectedDates}
                substormsList={substormsList}
                handleUpdateSubstormsFilter={handleUpdateSubstormsFilter}
                substormsListOpen={substormsListOpen}
                setSubstormsListOpen={setSubstormsListOpen}
            />
        </div>
    );
};

import { Reducer } from '@reduxjs/toolkit';

import { UserActions } from 'store/actions';

import { NOTIFICATIONS_ACTIONS_TYPES } from 'constants/notificationsActions';

import type {
    IUserActionTypes,
    IUserState,
    IUserPersonalInfo,
    IPeopleContact,
    IUserData,
    IUserProposalsData,
    IUserVotesData,
    ISimilarUserData,
    IUserTokenData,
    IUserNotificationsData,
    IUserFriendsAndEnemiesData,
    IUserErrorAction,
} from 'types/interfaces';

const defaultState: IUserState = {
    loading: true,
    updateAvatarLoading: false,
    updateUserOpportunitiesLoading: false,
    similarPeopleByOrganizationsLoading: false,
    similarPeopleByTokensLoading: false,
    userTokenListLoading: false,
    userData: {} as IUserData,
    userProposalsData: {} as IUserProposalsData,
    userVotesData: {} as IUserVotesData,
    similarPeopleByOrganizations: [],
    similarPeopleByTokens: [],
    userTokenListData: [],
    userTotalAum: null,
    userAboutSectionOpen: false,
    userNotificationsData: {
        newNotifications: [],
        oldNotifications: [],
    },
    isUserFollowing: false,
    isUserFollowingLoader: false,
    notificationsOffset: 0,
    hasMoreNotifications: true,
    notificationsActionType: NOTIFICATIONS_ACTIONS_TYPES.INITIAL_REQUEST,
    userEnemiesData: [],
    userFriendsData: [],
    userEnemiesDataLoading: false,
    userFriendsDataLoading: false,
    friendsAndEnemiesError: null,
    userProposalsDataLoading: false,
    userVotesDataLoading: false,
    userLabelsData: null,
};

export const userReducer: Reducer<IUserState, IUserActionTypes> = (
    state: IUserState = defaultState,
    action: IUserActionTypes,
): IUserState => {
    const { type, payload } = <IUserActionTypes>action;

    switch (type) {
        case UserActions.GET_USER_DATA: {
            return {
                ...state,
                loading: true,
            };
        }

        case UserActions.GET_USER_DATA_SUCCESS: {
            return {
                ...state,
                userData: payload as IUserData,
                loading: false,
            };
        }

        case UserActions.GET_USER_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case UserActions.GET_USER_PROPOSALS_DATA: {
            return {
                ...state,
                userProposalsDataLoading: true,
            };
        }

        case UserActions.GET_USER_PROPOSALS_DATA_SUCCESS: {
            return {
                ...state,
                userProposalsData: payload as IUserProposalsData,
                userProposalsDataLoading: false,
            };
        }

        case UserActions.GET_USER_PROPOSALS_DATA_FAILURE: {
            return {
                ...state,
                userProposalsDataLoading: false,
            };
        }

        case UserActions.GET_USER_VOTES_DATA: {
            return {
                ...state,
                userVotesDataLoading: true,
            };
        }

        case UserActions.GET_USER_VOTES_DATA_SUCCESS: {
            return {
                ...state,
                userVotesData: payload as IUserVotesData,
                userVotesDataLoading: false,
            };
        }

        case UserActions.GET_USER_VOTES_DATA_FAILURE: {
            return {
                ...state,
                userVotesDataLoading: false,
            };
        }

        case UserActions.UPDATE_USER_DATA: {
            return {
                ...state,
                loading: true,
            };
        }

        case UserActions.UPDATE_USER_DATA_SUCCESS: {
            return {
                ...state,
                userData: {
                    ...state.userData,
                    personalInfo: payload as IUserPersonalInfo,
                },
                loading: false,
            };
        }

        case UserActions.UPDATE_USER_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case UserActions.UPDATE_USER_AVATAR: {
            return {
                ...state,
                updateAvatarLoading: true,
            };
        }

        case UserActions.UPDATE_USER_AVATAR_SUCCESS: {
            return {
                ...state,
                userData: {
                    ...state.userData,
                    personalInfo: {
                        ...state.userData.personalInfo,
                        people_contact: payload as IPeopleContact,
                    },
                },
                loading: false,
                updateAvatarLoading: false,
            };
        }

        case UserActions.UPDATE_USER_AVATAR_FAILURE: {
            return {
                ...state,
                loading: false,
                updateAvatarLoading: false,
            };
        }

        case UserActions.UPDATE_USER_OPPORTUNITIES: {
            return {
                ...state,
                updateUserOpportunitiesLoading: true,
            };
        }

        case UserActions.UPDATE_USER_OPPORTUNITIES_SUCCESS: {
            return {
                ...state,
                userData: {
                    ...state.userData,
                    personalInfo: payload as IUserPersonalInfo,
                },
                updateUserOpportunitiesLoading: false,
            };
        }

        case UserActions.UPDATE_USER_OPPORTUNITIES_FAILURE: {
            return {
                ...state,
                updateUserOpportunitiesLoading: false,
            };
        }

        case UserActions.GET_SIMILAR_USERS_BY_ORGANIZATIONS_DATA: {
            return {
                ...state,
                similarPeopleByOrganizationsLoading: true,
            };
        }

        case UserActions.GET_SIMILAR_USERS_BY_ORGANIZATIONS_DATA_SUCCESS: {
            return {
                ...state,
                similarPeopleByOrganizationsLoading: false,
                similarPeopleByOrganizations: payload as ISimilarUserData[],
            };
        }

        case UserActions.GET_SIMILAR_USERS_BY_ORGANIZATIONS_DATA_FAILURE: {
            return {
                ...state,
                similarPeopleByOrganizationsLoading: false,
            };
        }

        case UserActions.GET_SIMILAR_USERS_BY_TOKENS_DATA: {
            return {
                ...state,
                similarPeopleByTokensLoading: true,
            };
        }

        case UserActions.GET_SIMILAR_USERS_BY_TOKENS_DATA_SUCCESS: {
            return {
                ...state,
                similarPeopleByTokensLoading: false,
                similarPeopleByTokens: payload as ISimilarUserData[],
            };
        }

        case UserActions.GET_SIMILAR_USERS_BY_TOKENS_DATA_FAILURE: {
            return {
                ...state,
                similarPeopleByTokensLoading: false,
            };
        }

        case UserActions.GET_USER_TOKEN_LIST_DATA: {
            return {
                ...state,
                userTokenListLoading: true,
            };
        }

        case UserActions.GET_USER_TOKEN_LIST_DATA_SUCCESS: {
            const castedPayload = payload as { tokens: IUserTokenData[]; totalAum: number | string | null };

            return {
                ...state,
                userTokenListLoading: false,
                userTokenListData: castedPayload.tokens,
                userTotalAum: castedPayload.totalAum,
            };
        }

        case UserActions.GET_USER_TOKEN_LIST_DATA_FAILURE: {
            return {
                ...state,
                userTokenListLoading: false,
            };
        }

        case UserActions.GET_USER_IS_FOLLOWING_DATA: {
            return {
                ...state,
                isUserFollowingLoader: true,
            };
        }

        case UserActions.GET_USER_IS_FOLLOWING_DATA_SUCCESS: {
            const { isUserFollowing } = payload as {
                isUserFollowing: boolean;
            };

            return {
                ...state,
                isUserFollowingLoader: false,
                isUserFollowing,
            };
        }

        case UserActions.GET_USER_IS_FOLLOWING_DATA_FAILURE: {
            return {
                ...state,
                isUserFollowingLoader: false,
            };
        }

        case UserActions.FOLLOW_UNFOLLOW_USER: {
            return {
                ...state,
                isUserFollowingLoader: true,
            };
        }

        case UserActions.FOLLOW_UNFOLLOW_USER_SUCCESS: {
            const { isUserFollowing } = payload as {
                isUserFollowing: boolean;
            };

            return {
                ...state,
                isUserFollowingLoader: false,
                isUserFollowing,
            };
        }

        case UserActions.FOLLOW_UNFOLLOW_USER_FAILURE: {
            return {
                ...state,
                isUserFollowingLoader: false,
            };
        }

        case UserActions.TOGGLE_ABOUT_SECTION_OPEN: {
            return {
                ...state,
                ...payload,
            };
        }

        case UserActions.GET_USER_LABELS_DATA: {
            return {
                ...state,
            };
        }

        case UserActions.GET_USER_LABELS_DATA_SUCCESS: {
            return {
                ...state,
                ...payload,
            };
        }

        case UserActions.GET_USER_LABELS_DATA_FAILURE: {
            return {
                ...state,
            };
        }

        case UserActions.UPDATE_NOTIFICATION_SEEN_STATUS: {
            return {
                ...state,
            };
        }

        case UserActions.UPDATE_NOTIFICATION_SEEN_STATUS_SUCCESS: {
            return {
                ...state,
                userNotificationsData: payload as IUserNotificationsData,
            };
        }

        case UserActions.UPDATE_NOTIFICATION_SEEN_STATUS_FAILURE: {
            return {
                ...state,
            };
        }

        case UserActions.GET_USER_ENEMIES_DATA: {
            return {
                ...state,
                friendsAndEnemiesError: null,
                userEnemiesDataLoading: true,
                userEnemiesData: [],
            };
        }

        case UserActions.GET_USER_ENEMIES_DATA_SUCCESS: {
            return {
                ...state,
                userEnemiesData: payload as IUserFriendsAndEnemiesData[],
                friendsAndEnemiesError: null,
                userEnemiesDataLoading: false,
            };
        }

        case UserActions.GET_USER_ENEMIES_DATA_FAILURE: {
            const { error } = action as IUserErrorAction;

            return {
                ...state,
                userEnemiesDataLoading: false,
                friendsAndEnemiesError: error,
                userEnemiesData: [],
            };
        }

        case UserActions.GET_USER_FRIENDS_DATA: {
            return {
                ...state,
                friendsAndEnemiesError: null,
                userFriendsDataLoading: true,
                userFriendsData: [],
            };
        }

        case UserActions.GET_USER_FRIENDS_DATA_SUCCESS: {
            return {
                ...state,
                userFriendsData: payload as IUserFriendsAndEnemiesData[],
                userFriendsDataLoading: false,
                friendsAndEnemiesError: null,
            };
        }

        case UserActions.GET_USER_FRIENDS_DATA_FAILURE: {
            const { error } = action as IUserErrorAction;

            return {
                ...state,
                userFriendsDataLoading: false,
                friendsAndEnemiesError: error,
                userFriendsData: [],
            };
        }

        case UserActions.GET_USER_NOTIFICATIONS: {
            return {
                ...state,
            };
        }

        case UserActions.GET_USER_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                ...payload,
            };
        }

        case UserActions.GET_USER_NOTIFICATIONS_FAILURE: {
            return {
                ...state,
            };
        }

        case UserActions.CLEAR_USER_DATA: {
            return {
                ...state,
                loading: true,
                userData: {} as IUserData,
                userProposalsData: {} as IUserProposalsData,
                userVotesData: {} as IUserVotesData,
                similarPeopleByOrganizations: [],
                userTokenListData: [],
                similarPeopleByTokens: [],
                userTotalAum: null,
                userAboutSectionOpen: false,
                userEnemiesData: [],
                userFriendsData: [],
                userLabelsData: null,
            };
        }

        default: {
            return state;
        }
    }
};

import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

import { ITreasuryComposition } from 'types/interfaces';
import { FormatUtils } from 'utils';
import { DATA_NOT_FOUND } from 'constants/dataNotFound';

export const TableRow = ({
    timePeriod,
    treasuryComposition,
}: {
    timePeriod: 'Current' | '7 Days' | '30 Days' | '6 Months' | '1 Year' | '2 Years';
    treasuryComposition: ITreasuryComposition;
}): ReactElement => {
    const votingToken = FormatUtils.checkIfDataNotExist(treasuryComposition?.votingTokenUsdValue)
        ? DATA_NOT_FOUND
        : `${FormatUtils.formatNumberShort(
              treasuryComposition?.votingTokenUsdValue,
              2,
          )} (${FormatUtils.formatNumberWithCommas(Number(treasuryComposition.votingTokenPercentage), 2)} %)`;
    const stablecoins = FormatUtils.checkIfDataNotExist(treasuryComposition?.stablecoinsUsdValue)
        ? DATA_NOT_FOUND
        : `${FormatUtils.formatNumberShort(
              treasuryComposition?.stablecoinsUsdValue,
              2,
          )} (${FormatUtils.formatNumberWithCommas(Number(treasuryComposition.stablecoinsPercentage), 2)} %)`;
    const ethBtcTokens = FormatUtils.checkIfDataNotExist(treasuryComposition?.ethBtcUsdValue)
        ? DATA_NOT_FOUND
        : `${FormatUtils.formatNumberShort(
              treasuryComposition?.ethBtcUsdValue,
              2,
          )} (${FormatUtils.formatNumberWithCommas(Number(treasuryComposition.ethBtcPercentage), 2)} %)`;
    const otherTokens = FormatUtils.checkIfDataNotExist(treasuryComposition?.otherTokensUsdValue)
        ? DATA_NOT_FOUND
        : `${FormatUtils.formatNumberShort(
              treasuryComposition?.otherTokensUsdValue,
              2,
          )} (${FormatUtils.formatNumberWithCommas(Number(treasuryComposition.otherTokensPercentage), 2)} %)`;

    return (
        <div className={styles.tableRow}>
            <div className={styles.timePeriod}>{timePeriod}</div>
            <div className={styles.votingToken}>{votingToken}</div>
            <div className={styles.stablecoins}>{stablecoins}</div>
            <div className={styles.ethBtcTokens}>{ethBtcTokens}</div>
            <div className={styles.otherTokens}>{otherTokens}</div>
        </div>
    );
};

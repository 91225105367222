import React, { ComponentType, FC, ReactElement, useEffect, useMemo } from 'react';

import { Route, Redirect } from 'react-router-dom';

import { withHeader } from 'hoc';

import type { IRouterSwitchProps } from 'types/interfaces';
import { setProtectedTokenByProtectionType } from './utils';
import { getDeepDAOTokenFromStorage, getPremiumUserTokenFromStorage } from 'web3/storageHelper';

export const ProtectedRoute: FC<IRouterSwitchProps> = ({
    path,
    exact,
    component,
    disableHeader,
    backgroundColor,
    alwaysShowFooter,
    protectedTokenType,
}: IRouterSwitchProps): ReactElement => {
    const protectedToken = useMemo(
        () => setProtectedTokenByProtectionType(protectedTokenType),
        [getDeepDAOTokenFromStorage, getPremiumUserTokenFromStorage],
    );

    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, []);

    return protectedToken ? (
        <Route
            path={path}
            exact={exact}
            component={withHeader(component, disableHeader, backgroundColor, alwaysShowFooter) as ComponentType}
        />
    ) : (
        <Redirect to="/organizations" />
    );
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { ImageLoader, TableCell } from 'components/index';

import { ORGANIZATIONS_TOKENS_PARAMS } from 'components/DaoTokensTable/constants';
import { IMAGE_TYPES } from 'constants/imageTypes';

import { FormatUtils } from 'utils';

import { IOrganizationTokenItemsProps } from 'types/interfaces';

export const DaoTokensItems: FC<IOrganizationTokenItemsProps> = ({
    rank,
    daosTotalUsdValue,
    numDaos,
    tokenName,
    tokenSymbol,
    tokenIcon,
    sortedParamName,
}: IOrganizationTokenItemsProps): ReactElement => (
    <div className={styles.itemContainer}>
        <div className={styles.rank}>
            <span>{rank}</span>
        </div>
        <TableCell
            className={styles.tokenInfo}
            isHighlighted={sortedParamName === ORGANIZATIONS_TOKENS_PARAMS.TOKEN_NAME_PARAM}
        >
            <ImageLoader src={tokenIcon} className={styles.tokenLogo} imageType={IMAGE_TYPES.TOKEN_IMAGE} />
            <span className={styles.tokenName}>{tokenName}</span>
        </TableCell>
        <TableCell
            className={styles.symbol}
            isHighlighted={sortedParamName === ORGANIZATIONS_TOKENS_PARAMS.SYMBOL_PARAM}
        >
            <span>{tokenSymbol}</span>
        </TableCell>
        <TableCell
            className={styles.balance}
            isHighlighted={sortedParamName === ORGANIZATIONS_TOKENS_PARAMS.BALANCE_USD_PARAM}
        >
            <span className={styles.sum}>${FormatUtils.formatNumberShort(daosTotalUsdValue, 1)}</span>
        </TableCell>
        <TableCell
            className={styles.organizations}
            isHighlighted={sortedParamName === ORGANIZATIONS_TOKENS_PARAMS.ORGANIZATIONS_PARAM}
        >
            <span>{FormatUtils.formatNumberShort(numDaos, 1)}</span>
        </TableCell>
    </div>
);

import {
    COMMON_TABLE_FIELD_TYPES,
    ICommonTableHeader,
    ICommonTableHeaderSettings,
} from '../../../../../../CommonTable/types';
import {
    IActiveVotersMinimumVotes,
    IActiveVotersMinimumVotingPower,
    IActiveVotersNumberVoters,
    IActiveVotersNumberVotersWithVoteThisYear,
    IOrganizationActiveVoters,
} from '../../../../../../../types/interfaces';

export enum ORGANIZATION_ACTIVE_VOTERS_TABLE_PARAMS {
    MINIMUM_VOTES = 'minimumVotes',
    MINIMUM_VOTING_POWER = 'minimumVotingPower',
    NUMBER_VOTERS = 'numberVoters',
    NUMBER_VOTERS_WITH_VOTE_THIS_YEAR = 'numberVoterWithVoteThisYear',
}

const tableSettingsFourItems: ICommonTableHeaderSettings = {
    width: {
        desktop: '25%',
    },
};

const MINIMUM_VOTES: ICommonTableHeader<IActiveVotersMinimumVotes, ORGANIZATION_ACTIVE_VOTERS_TABLE_PARAMS> = {
    title: 'Minimum votes',
    rowFieldKey: 'minimumVotes',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsFourItems,
};

const MINIMUM_VOTING_POWER: ICommonTableHeader<
    IActiveVotersMinimumVotingPower,
    ORGANIZATION_ACTIVE_VOTERS_TABLE_PARAMS
> = {
    title: 'Minimum voting power',
    rowFieldKey: 'minimumVotingPower',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsFourItems,
};

const NUMBER_VOTERS: ICommonTableHeader<
    IActiveVotersNumberVoters,
    ORGANIZATION_ACTIVE_VOTERS_TABLE_PARAMS
> = {
    title: 'Number voters',
    rowFieldKey: 'numberVoters',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsFourItems,
};

const NUMBER_VOTERS_WITH_VOTE_THIS_YEAR: ICommonTableHeader<
    IActiveVotersNumberVotersWithVoteThisYear,
    ORGANIZATION_ACTIVE_VOTERS_TABLE_PARAMS
> = {
    title: 'Voted in past 12 months',
    rowFieldKey: 'numberVotersWithVoteThisYear',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsFourItems,
};

const addSettingsToTableHeaderData = <T>(
    data: ICommonTableHeader<T, ORGANIZATION_ACTIVE_VOTERS_TABLE_PARAMS>[],
    settings: ICommonTableHeaderSettings,
): ICommonTableHeader<T, ORGANIZATION_ACTIVE_VOTERS_TABLE_PARAMS>[] =>
    data.map((item) => ({
        ...item,
        settings,
    }));

const organizationActiveVotersTableHeaders: ICommonTableHeader<
    IOrganizationActiveVoters,
    ORGANIZATION_ACTIVE_VOTERS_TABLE_PARAMS
>[] = addSettingsToTableHeaderData(
    [
        MINIMUM_VOTES,
        MINIMUM_VOTING_POWER,
        NUMBER_VOTERS,
        NUMBER_VOTERS_WITH_VOTE_THIS_YEAR,
    ] as ICommonTableHeader<IOrganizationActiveVoters, ORGANIZATION_ACTIVE_VOTERS_TABLE_PARAMS>[],
    tableSettingsFourItems,
);

export { organizationActiveVotersTableHeaders };

import React, { FC, ReactElement, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardOutlined from '@material-ui/icons/ArrowForwardOutlined';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { MainSocialLinks, MainPagesNavItems } from 'components';

import { SUPPORTING_PAGES_LINKS } from 'constants/supportingPages';
import { getPremiumUserTokenFromStorage } from 'web3/storageHelper';

import { threeDotsIcon } from 'assets';

import { ISupportingLink } from 'types/interfaces';

interface IMobileLinksModalProps {
    onClose: () => void;
}

export const MobileLinksModal: FC<IMobileLinksModalProps> = ({ onClose }: IMobileLinksModalProps): ReactElement => {
    const [aboutSectionOpen, setAboutSectionOpen] = useState<boolean>(false);
    const isUserWithPremiumIsLogged = Boolean(getPremiumUserTokenFromStorage());

    return (
        <div className={styles.modalWrapper}>
            <div className={styles.modal}>
                <div className={styles.modalHeader}>
                    <Button className={styles.closeButton} onClick={onClose}>
                        <CloseIcon className={styles.closeIcon} />
                    </Button>
                </div>
                <div
                    className={classNames(styles.modalBody, {
                        [styles.modalBodyAbout]: aboutSectionOpen,
                    })}
                >
                    {aboutSectionOpen ? (
                        <>
                            <div className={styles.aboutHeader} onClick={() => setAboutSectionOpen(false)}>
                                <ArrowForwardOutlined className={styles.arrowBackIcon} />
                                <h6 className={styles.aboutTitle}>About</h6>
                            </div>
                            {SUPPORTING_PAGES_LINKS.map(({ id, link, name }: ISupportingLink) => (
                                <Link key={id} to={link} className={styles.supportingPageLink}>
                                    {name}
                                </Link>
                            ))}
                        </>
                    ) : (
                        <>
                            <div className={styles.navigationAndOptions}>
                                <MainPagesNavItems menuExpanded className={styles.pagesLinksContainer} />

                                <div className={styles.aboutOptions} onClick={() => setAboutSectionOpen(true)}>
                                    <div>
                                        <img src={threeDotsIcon} className={styles.moreIcon} alt="" />
                                        <h6 className={styles.title}>About</h6>
                                    </div>

                                    <ArrowForwardOutlined className={styles.arrowIcon} />
                                </div>
                            </div>

                            <div className={styles.socialsPricingLinksWrapper}>
                                <MainSocialLinks className={styles.mainSocialLinks} />

                                <Link to="/products" className={styles.pricingLink}>
                                    data api
                                </Link>

                                {isUserWithPremiumIsLogged && (
                                    <Link to="/premium_account_settings" className={styles.pricingLink}>
                                        settings
                                    </Link>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

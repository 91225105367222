import React, { FC, ReactElement, useContext, useCallback } from 'react';

import { Button } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles.module.scss';

import { Loader } from 'components';
import { TokenInfo, GovernanceInfo, TreasuryInfo } from './components';

import { ERROR_MESSAGES } from 'constants/alert';

import { SelfListingContext } from 'context/selfListingContext';
import { useAppSelector, useAppDispatch } from 'store';
import { SelfListingActions } from 'store/actions';

import { IGovernanceInfoFormProps } from 'types/interfaces';

export const GovernanceInfoForm: FC<IGovernanceInfoFormProps> = ({
    previousStep,
}: IGovernanceInfoFormProps): ReactElement => {
    const { listOrganizationLoading, type, error } = useAppSelector(({ selfListing }) => selfListing);

    const dispatch = useAppDispatch();

    const { formik } = useContext(SelfListingContext);

    const clearSelfListingError = useCallback(
        () => dispatch({ type: SelfListingActions.CLEAR_SELF_LISTING_ERROR }),
        [dispatch, error],
    );

    return (
        <>
            <TokenInfo />
            <GovernanceInfo />
            <TreasuryInfo />

            <div className={styles.buttonsWrapper}>
                <Button className={styles.previousStepButton} onClick={previousStep}>
                    <span className={styles.title}>org. details</span>
                    <ArrowForwardIcon className={styles.arrowIcon} />
                </Button>
                <Button className={styles.listOrganizationButton} type="submit" disabled={!formik.isValid}>
                    {listOrganizationLoading ? (
                        <Loader className={styles.loader} size={30} />
                    ) : type === 'editing' ? (
                        'update'
                    ) : (
                        'list organization'
                    )}
                </Button>
            </div>

            {error && (
                <div className={styles.errorBlock}>
                    <span className={styles.errorText}>{ERROR_MESSAGES.SOMETHING_WENT_WRONG}</span>
                    <Button className={styles.closeBtn} onClick={clearSelfListingError}>
                        <CloseIcon className={styles.closeIcon} />
                    </Button>
                </div>
            )}
        </>
    );
};

import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { CreditCardBlock, FormRadioButtons, Loader } from 'components';

import {
    PAYMENT_METHODS,
    plansDurations,
    PLANS_DURATIONS,
    PLANS_PAGES_INDEXES,
    detailedPlansPricings,
    PLAN_NAMES,
} from 'constants/plansItems';
import { ERROR_MESSAGES } from 'constants/alert';

import { useAppDispatch } from 'store';
import { PremiumSubscriptionActions } from 'store/actions';
import { getTotalPlanPrice } from './utils';

import { IDetailedPlan, ISelectOptions, IStartPremiumPlanProps } from 'types/interfaces';

export const StartPremiumPlan: FC<IStartPremiumPlanProps> = ({
    formik,
    changePlansPage,
    bluesnapSubscription,
    paypalSubcription,
    filteredBluesnapPlansLoaded,
    filteredPaypalPlansLoaded,
    subscriptionLoading,
    paypalSubscriptionError,
    bluesnapSubscriptionErrors,
    premiumPFToken,
    isUpgradingType,
}: IStartPremiumPlanProps): ReactElement => {
    const [createPlanButtonDisabled, setCreatePlanButtonDisabled] = useState(true);

    const dispatch = useAppDispatch();

    const changeDuration = useCallback(
        (option: ISelectOptions) => {
            formik.setFieldValue('planInfo.duration', option);
            formik.setFieldValue('planInfo.price', getTotalPlanPrice(formik.values.planInfo.planName, option));
        },
        [formik],
    );

    // const onPaymentMethodChange = useCallback(
    //     (option: ISelectOptions) => {
    //         formik.setFieldValue('creditCardInfo.paymentMethodInfo', option);
    //     },
    //     [formik],
    // );

    useEffect(() => {
        if (subscriptionLoading) {
            setCreatePlanButtonDisabled(true);
        } else {
            if (formik.values.creditCardInfo.paymentMethodInfo.value === PAYMENT_METHODS.PAYPAL)
                setCreatePlanButtonDisabled(!filteredPaypalPlansLoaded);
        }
    }, [formik, subscriptionLoading, filteredPaypalPlansLoaded, filteredBluesnapPlansLoaded]);

    const onStartPlanClick = useCallback(() => {
        if (formik.values.creditCardInfo.paymentMethodInfo.value === PAYMENT_METHODS.CREDIT_CARD) {
            const [firstName, lastName] = formik.values.creditCardInfo.cardholderName.split(' ');

            const threeDSecureObj = {
                amount: formik.values.planInfo.price,
                currency: 'USD',
                billingFirstName: firstName,
                billingLastName: lastName,
                billingCountry: formik.values.userInfo.countryCode?.toLowerCase(),
                email: formik.values.userInfo.email,
                phone: formik.values.userInfo.phoneNumber,
                billingZip: formik.values.userInfo.postalCode,
            };

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.bluesnap.hostedPaymentFieldsSubmitData(function (callback: any) {
                if (callback?.cardData) {
                    bluesnapSubscription();
                } else {
                    const errorArray = callback?.error;
                    dispatch(
                        PremiumSubscriptionActions.bluesnapSubscribe.failure(
                            Array.isArray(errorArray)
                                ? errorArray.map(
                                      ({ errorDescription }: { errorDescription: string }) =>
                                          errorDescription || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                                  )
                                : [ERROR_MESSAGES.SOMETHING_WENT_WRONG],
                        ),
                    );
                }
            }, threeDSecureObj);
        } else {
            paypalSubcription();
        }
    }, [bluesnapSubscription, paypalSubcription, formik]);

    useEffect(
        () => () => {
            dispatch({ type: PremiumSubscriptionActions.CLEAR_BLUESNAP_SUBSCRIPTION_ERRORS });
        },
        [dispatch],
    );

    return (
        <div className={styles.container}>
            {isUpgradingType ? (
                <div className={styles.head}>
                    <div className={styles.navigationBackBlock}>
                        <Link to="/premium_account_settings" className={styles.accountSettingsLink}>
                            <ArrowBackIos className={styles.arrowIcon} />
                            <span className={styles.text}>Settings</span>
                        </Link>
                    </div>
                    <h1 className={styles.title}>Upgrade your plan</h1>
                </div>
            ) : (
                <>
                    <h1 className={styles.title}>Start your premium plan</h1>
                    <h6 className={styles.description}>
                        Enter the details below to start your DeepDAO premium membership
                    </h6>
                </>
            )}
            <div className={styles.cardsWrapper}>
                <div className={styles.card}>
                    {!isUpgradingType && (
                        <>
                            <div className={styles.cardHeader}>
                                <h5 className={styles.headText}>{formik.values.userInfo.fullName}</h5>
                            </div>
                            <div className={styles.userDetails}>
                                <p>{formik.values.userInfo.email}</p>
                                {formik.values.userInfo.phoneNumber && <p>{formik.values.userInfo.phoneNumber}</p>}
                                {(formik.values.userInfo.countryCode ||
                                    formik.values.userInfo.country ||
                                    formik.values.userInfo.postalCode) && (
                                    <h6 className={styles.locationInfo}>
                                        {formik.values.userInfo.countryCode && (
                                            <span>{formik.values.userInfo.countryCode}</span>
                                        )}
                                        {formik.values.userInfo.country && (
                                            <span>{formik.values.userInfo.country}</span>
                                        )}
                                        {formik.values.userInfo.postalCode && (
                                            <span>{formik.values.userInfo.postalCode}</span>
                                        )}
                                    </h6>
                                )}
                            </div>
                        </>
                    )}
                    <div
                        className={classNames(styles.block, {
                            [styles.removeMarginTop]: isUpgradingType,
                        })}
                    >
                        <h5 className={styles.headText}>Billing Cycle</h5>
                        <h6 className={styles.descriptionText}>Renews automatically, can be canceled anytime.</h6>
                        <div className={styles.radioButtonsContent}>
                            <div className={styles.radioWrapper}>
                                <FormRadioButtons
                                    options={plansDurations}
                                    selectedOption={formik.values.planInfo.duration}
                                    onChange={changeDuration}
                                    className={styles.periodRadioInputsWrapper}
                                />
                            </div>
                            <div className={styles.pricingsBlock}>
                                {detailedPlansPricings[
                                    formik.values.planInfo.planName.toUpperCase() as PLAN_NAMES
                                ]?.map((info: IDetailedPlan) => (
                                    <p
                                        key={info.id}
                                        className={classNames(styles.price, {
                                            [styles.bold]: info.duration === formik.values.planInfo.duration.value,
                                        })}
                                    >
                                        ${info.pricing} USD
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>

                    <CreditCardBlock
                        formik={formik}
                        showCardFields={
                            formik.values.creditCardInfo.paymentMethodInfo.value === PAYMENT_METHODS.CREDIT_CARD
                        }
                        premiumPFToken={premiumPFToken}
                        setCreatePlanButtonDisabled={setCreatePlanButtonDisabled}
                    />
                </div>

                <div className={styles.card}>
                    <div className={styles.cardHeader}>
                        <h2 className={styles.planName}>{formik.values.planInfo.title}</h2>
                        <div
                            className={styles.editOption}
                            onClick={() => changePlansPage(PLANS_PAGES_INDEXES.PLANS_INFO)}
                        >
                            change
                        </div>
                    </div>
                    <p className={styles.sumInfo}>
                        ${getTotalPlanPrice(formik.values.planInfo.planName, formik.values.planInfo.duration)} USD,
                        charged{' '}
                        {formik.values.planInfo.duration.value === PLANS_DURATIONS.MONTHLY_PLAN_DURATION
                            ? 'monthly'
                            : 'yearly'}
                    </p>
                    <div className={styles.totalSumInfo}>
                        <h5 className={styles.headText}>Total</h5>
                        <h5 className={styles.headText}>
                            ${getTotalPlanPrice(formik.values.planInfo.planName, formik.values.planInfo.duration)} USD
                        </h5>
                    </div>

                    <Button
                        className={styles.startPlanButton}
                        onClick={onStartPlanClick}
                        disabled={createPlanButtonDisabled}
                    >
                        {subscriptionLoading ? <Loader className={styles.loader} size={30} /> : 'start plan'}
                    </Button>

                    {bluesnapSubscriptionErrors &&
                        bluesnapSubscriptionErrors.map((error: string) => (
                            <h6 className={styles.errorText} key={error}>
                                {error}
                            </h6>
                        ))}
                    {paypalSubscriptionError && <h6 className={styles.errorText}>{paypalSubscriptionError}</h6>}
                </div>
            </div>
        </div>
    );
};

import React, { FC, ReactElement, useEffect, useRef } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ISelfListingTextareaProps } from 'types/interfaces/selfListing.interfaces';

export const SelfListingTextarea: FC<ISelfListingTextareaProps> = ({
    placeholder,
    name,
    value = '',
    onChange,
    onTouch,
    error,
    touched,
    infoText,
    required,
    max,
    shouldValidate = true,
}: ISelfListingTextareaProps): ReactElement => {
    const ref = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (ref?.current?.style) {
            ref.current.style.height = '1px';
            ref.current.style.height = ref.current.scrollHeight + 'px';
        }
    }, [value]);

    return (
        <div className={styles.textareaWrapper}>
            <textarea
                className={classNames(styles.textarea, { [styles.requiredField]: required && !value })}
                placeholder={placeholder}
                id={name}
                name={name}
                value={value as string}
                onChange={onChange}
                onFocus={() => onTouch(name, true, shouldValidate)}
                ref={ref}
                maxLength={max}
            />

            {required && !value && <span className={styles.asteriskSymbol}>*</span>}

            <div className={styles.textWrapper}>
                {error && touched && <h6 className={styles.error}>{error}</h6>}
                <h6 className={styles.infoText}>{infoText}</h6>
            </div>
        </div>
    );
};

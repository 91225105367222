import React, { FC, ReactElement, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import classNames from 'classnames';

import { HeaderButtons, SearchContainer } from 'components';
import { MobileLinksModal } from './components';

import styles from './styles.module.scss';

import { deepDaoHeaderLogo } from 'assets';

export const HeaderMobile: FC = (): ReactElement => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [inputOpen, setInputOpen] = useState(false);

    const handleMenuOpen = () => setMenuOpen(!menuOpen);

    useEffect(() => {
        document.body.style.overflow = menuOpen ? 'hidden' : 'unset';
    }, [menuOpen]);

    return (
        <>
            <header className={styles.wrapper}>
                <div className={styles.header}>
                    {!inputOpen && (
                        <div className={styles.logoContainer}>
                            <Link to="/organizations">
                                <img src={deepDaoHeaderLogo} className={styles.logo} alt="DeepDAO logo" />
                            </Link>
                        </div>
                    )}

                    <div
                        className={classNames(styles.options, {
                            [styles.optionsFullWidth]: inputOpen,
                        })}
                    >
                        {!inputOpen && (
                            <Button className={styles.menuButton} onClick={handleMenuOpen}>
                                <MenuIcon className={styles.menuIcon} />
                            </Button>
                        )}

                        <SearchContainer inputOpen={inputOpen} setInputOpen={setInputOpen} />

                        {!inputOpen && <HeaderButtons />}
                    </div>
                </div>
            </header>

            {menuOpen && <MobileLinksModal onClose={handleMenuOpen} />}
        </>
    );
};

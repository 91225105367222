import { twitterIcon, discordIcon, githubIcon, websiteIcon, otherIcon } from 'assets';
import { SocialTypes } from 'types/interfaces';

export const getSourceImage = (source: string): string => {
    if (source === SocialTypes.twitter) return twitterIcon;
    if (source === SocialTypes.discord) return discordIcon;
    if (source === SocialTypes.github) return githubIcon;
    if (source === SocialTypes.website) return websiteIcon;

    return otherIcon;
};

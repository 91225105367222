import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

import { HeaderItemWithSorting } from 'components/index';
import { ICommonTableHeader } from 'components/CommonTable/types';

interface ITableHeaderProps<T, K extends string> {
    tableHeaderData: ICommonTableHeader<T, K>[];
    sortedParamName: K;
    sortByParam: (param: K) => void;
}

export const TableHeader = <T, K extends string>({
    tableHeaderData,
    sortedParamName,
    sortByParam,
}: ITableHeaderProps<T, K>): ReactElement => {
    return (
        <div className={styles.tableHeader}>
            {tableHeaderData.map((headerItems: ICommonTableHeader<T, K>) => (
                <div
                    key={headerItems.title + headerItems.sortedParamName}
                    style={{ width: headerItems.title === '#' ? `5%` : headerItems.settings.width.desktop }}
                >
                    {headerItems.sortedParamName ? (
                        <HeaderItemWithSorting
                            sortByParam={sortByParam as (param: string) => void}
                            name={headerItems.title}
                            sortedParamName={sortedParamName}
                            paramName={headerItems.sortedParamName}
                        />
                    ) : (
                        <div>
                            <span>{headerItems.title}</span>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

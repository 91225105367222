import React, { ReactElement, ReactNode } from 'react';

import styles from './styles.module.scss';

import { InfoCircle, Loader, TableDataNotFound } from 'components/index';
import { FormatUtils } from 'utils';
import { IOVIYearByYear } from '../../../../../../../../types/interfaces';

interface IYearByYearTableProps {
    title: string;
    lastUpdate?: string | null;
    infoText?: string;
    tableRowsData: IOVIYearByYear[];
    loading?: boolean;
    error?: string | null;
    headContent?: ReactNode;
}

export const YearByYearTable = ({
    title,
    lastUpdate,
    infoText,
    tableRowsData,
    loading,
    headContent,
}: IYearByYearTableProps): ReactElement => {
    const columnSize = `${100 / tableRowsData?.length + 1}%`;

    return (
        <div className={styles.content}>
            <div className={styles.headSection}>
                <div className={styles.textSection}>
                    <h4>{title}</h4>
                    <InfoCircle tooltipText={infoText} className={styles.infoIcon} />
                    {lastUpdate && <h5>Last Update: {FormatUtils.getFormattedDate(lastUpdate)}</h5>}
                </div>
                {headContent}
            </div>

            <div className={styles.tableWrapper}>
                {tableRowsData && tableRowsData.length > 0 && (
                    <div className={styles.tableContainer}>
                        <div className={styles.tableHeader}>
                            <div key={'Year'} style={{ width: columnSize }}>
                                <div>
                                    <span className={styles.header}>{'Year'}</span>
                                </div>
                            </div>
                            {tableRowsData.map((trd) => (
                                <div key={trd.year} style={{ width: columnSize }}>
                                    <div>
                                        <span className={styles.data}>{trd.year}</span>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className={styles.tableHeader}>
                            <div key={'Voters'} style={{ width: columnSize }}>
                                <div>
                                    <span className={styles.header}>{'Voters'}</span>
                                </div>
                            </div>
                            {tableRowsData.map((trd) => (
                                <div key={trd.year} style={{ width: columnSize }}>
                                    <div>
                                        <span className={styles.data}>
                                            {FormatUtils.formatNumberWithCommas(trd.numberOfVotes, 0)}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {loading && tableRowsData.length === 0 && (
                    <div className={styles.tableContainer}>
                        <Loader size={40} className={styles.loaderBox} />
                    </div>
                )}
                {tableRowsData.length === 0 && !loading && <TableDataNotFound forTable overflowMarginsSecondVariant />}
            </div>
        </div>
    );
};

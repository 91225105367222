import { useState } from 'react';

import { ITabItems, ITabSelectorHook } from 'types/interfaces';

export const useTabSelector = (tab: ITabItems): ITabSelectorHook => {
    const [tabSelected, setTabSelected] = useState(tab);

    const handleTabChange = (selectedTab: ITabItems) => {
        setTabSelected(selectedTab);
    };

    return {
        tabSelected,
        handleTabChange,
    };
};

import React, { FC, ReactElement, Fragment, useContext, ChangeEvent } from 'react';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import styles from './styles.module.scss';

import { SelfListingInput, SelfListingSelect, SelfListingTitle } from 'components';

import { treasuryTypesList } from 'components/SelfListingModal/constants';

import { useAppSelector } from 'store';
import { SelfListingContext } from 'context/selfListingContext';

import { ISelfListingElement } from 'types/interfaces';

export const TreasuryInfo: FC = (): ReactElement => {
    const { selfListingChainsList } = useAppSelector(({ selfListing }) => selfListing);

    const { formik } = useContext(SelfListingContext);

    const onTreasurySelect = (value: ISelfListingElement, index: number) => {
        formik.setFieldValue(`treasury[${index}]`, {
            ...formik.values.treasury[index],
            chainId: value.id,
            title: value.title,
        });
    };

    const addTreasuryFieds = () => {
        formik.setFieldValue('treasury', [
            ...formik.values.treasury,
            {
                type: '',
                address: '',
                chainId: null,
                title: '',
                treasuryTypeId: null,
            },
        ]);
    };

    const onTreasuryRemove = (index: number) => {
        const treasuryData = formik.values.treasury.map((items: ISelfListingElement, id: number) =>
            index === id ? { ...items, chainId: null, title: '' } : items,
        );

        formik.setFieldValue('treasury', treasuryData);
    };

    const onTreasuryTypeSelect = (value: ISelfListingElement, index: number) => {
        formik.setFieldValue(`treasury[${index}]`, {
            ...formik.values.treasury[index],
            type: value.title,
            treasuryTypeId: value.id,
        });
    };

    const onTreasuryTypeRemove = (index: number) => {
        const treasuryData = formik.values.treasury.map((items: ISelfListingElement, id: number) =>
            index === id ? { ...items, type: '', treasuryTypeId: null } : items,
        );

        formik.setFieldValue('treasury', treasuryData);
    };

    return (
        <div className={styles.treasuryInfoWrapper}>
            <SelfListingTitle title="Treasury" />

            <div className={styles.fieldsWrapper}>
                {formik.values.treasury.map((_: ISelfListingElement, index: number) => (
                    <Fragment key={index}>
                        <SelfListingSelect
                            data={selfListingChainsList}
                            placeholderTitle="-Select-"
                            selectedElementTitle={formik.values.treasury[index].title}
                            onSelect={(value: ISelfListingElement) => onTreasurySelect(value, index)}
                            error={formik.errors?.treasury?.[index]?.title}
                            onRemove={() => onTreasuryRemove(index)}
                        />

                        <SelfListingSelect
                            data={treasuryTypesList}
                            placeholderTitle="Select type"
                            selectedElementTitle={formik.values.treasury[index].type}
                            onSelect={(value: ISelfListingElement) => onTreasuryTypeSelect(value, index)}
                            error={formik.errors?.treasury?.[index]?.type}
                            onRemove={() => onTreasuryTypeRemove(index)}
                        />

                        <SelfListingInput
                            placeholder="Address"
                            name={`treasury[${index}].address`}
                            value={formik.values.treasury[index].address}
                            touched={true}
                            error={formik.errors.treasury?.[index]?.address}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                formik.setFieldValue(`treasury[${index}].address`, event.target.value)
                            }
                            onTouch={formik.setFieldTouched}
                        />
                    </Fragment>
                ))}
            </div>

            <Button className={styles.addSocialsButton} onClick={addTreasuryFieds}>
                <AddIcon className={styles.addIcon} />
                <span className={styles.title}>add</span>
            </Button>
        </div>
    );
};

import { IDetailedDescriptionSectionProps } from 'types/interfaces';

const ADMIN_DESCRIPTION = {
    title: 'Admin',
    infoText:
        'Once listed you will be this organization’s admin, in control of all pieces of base data (token, treasury, governance system, and social links). You can also appoint editors, or remove them from the editors list',
} as IDetailedDescriptionSectionProps;

const EDITORS_DESCRIPTION = {
    title: 'Editors',
    infoText:
        'Editors can change the base data for this organization (token, treasury, governance system, and social links). They can also remove other editors and appoint new ones. They cannot delete this organization.',
} as IDetailedDescriptionSectionProps;

export { ADMIN_DESCRIPTION, EDITORS_DESCRIPTION };

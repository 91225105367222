import { axiosInstance } from 'services';
import { AxiosResponse } from 'axios';

import {
    ILoginRequest,
    ILoginResponse,
    ILogoutRequest,
    IChallengeRequest,
    IChallengeResponse,
    IRefreshRequest,
    IRefreshResponse,
} from 'types/interfaces';

export const AuthApi = {
    login: ({ challenge, signature }: ILoginRequest): Promise<AxiosResponse<ILoginResponse>> =>
        axiosInstance.post<ILoginResponse>(`auth/login`, { challenge, signature }),
    logout: ({ refreshToken }: ILogoutRequest): Promise<AxiosResponse<ILogoutRequest>> =>
        axiosInstance.delete(`auth/logout`, { data: { refreshToken } }),
    challenge: ({ account }: IChallengeRequest): Promise<AxiosResponse<IChallengeResponse>> =>
        axiosInstance.post<IChallengeResponse>(`auth/challenge`, { account }),
    refresh: ({ refreshToken }: IRefreshRequest): Promise<AxiosResponse<IRefreshResponse>> =>
        axiosInstance.post<IRefreshResponse>(`auth/refresh`, { refreshToken }),
    adminLogin: (data: {
        username: string | null;
        password: string | null;
    }): Promise<AxiosResponse<IRefreshResponse>> => axiosInstance.post<IRefreshResponse>(`auth/admin-login`, data),
};

import React, { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';

import { Redirect } from 'react-router-dom';

import { Button } from '@material-ui/core';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import styles from './styles.module.scss';

import { PremiumLoginForm } from 'components';

import { useAppDispatch, useAppSelector } from 'store';
import { PremiumSubscriptionActions } from 'store/actions';
import { getPremiumUserEmailFromStorage } from 'web3/storageHelper';
import { useHistoryPush } from 'hooks';

export const PremiumOTPLoginPage: FC = (): ReactElement => {
    const { premiumLoginError, premiumLoginLoading } = useAppSelector(({ premiumSubscription }) => premiumSubscription);

    const dispatch = useAppDispatch();

    const premiumUserEmail = useMemo(() => getPremiumUserEmailFromStorage(), [getPremiumUserEmailFromStorage]);

    const { navigateToAccountSettings } = useHistoryPush();

    const formik = useFormik({
        initialValues: {
            otpCode: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            otpCode: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            dispatch(
                PremiumSubscriptionActions.premiumOTPLogin.request({
                    email: premiumUserEmail,
                    otpCode: values.otpCode,
                    navigate: navigateToAccountSettings,
                }),
            );
        },
    });

    if (!premiumUserEmail) {
        return <Redirect to="/premium_email_login" />;
    }

    const formValues = useMemo(
        () => [
            {
                id: 1,
                name: 'otpCode',
                placeholder: 'Enter your code',
                value: formik.values.otpCode,
                onChange: formik.handleChange,
                touched: formik.touched?.otpCode,
                error: formik.errors?.otpCode,
                onTouch: formik.setFieldTouched,
            },
        ],
        [formik],
    );

    const onResendEmailClick = useCallback(() => {
        dispatch(PremiumSubscriptionActions.premiumEmailLogin.request({ email: premiumUserEmail }));
    }, [dispatch, premiumUserEmail]);

    const PremiumLoginButton = useMemo(
        () => (
            <Button className={styles.resendEmailButton} onClick={onResendEmailClick}>
                resend email
            </Button>
        ),
        [onResendEmailClick],
    );

    useEffect(() => {
        return () => {
            dispatch({ type: PremiumSubscriptionActions.CLEAR_PREMIUM_LOGGIN_ERROR });
        };
    }, []);

    return (
        <PremiumLoginForm
            title="Enter your one-time password"
            description={
                <p>
                    We’ve sent you an email with a login code to:{' '}
                    {<span className={styles.boldText}>{premiumUserEmail}</span>}
                </p>
            }
            submitButtonText="Sign in"
            formValues={formValues}
            onSubmit={formik.handleSubmit}
            error={premiumLoginError}
            loading={premiumLoginLoading}
            disabled={!(formik.isValid && formik.dirty)}
        >
            {PremiumLoginButton}
        </PremiumLoginForm>
    );
};

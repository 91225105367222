import React, { FC, ReactElement, memo } from 'react';

import { Button } from '@material-ui/core';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { IHeaderFollowButtonProps } from 'types/interfaces';

export const HeaderFollowButton: FC<IHeaderFollowButtonProps> = memo(
    ({ onClick, isFollowing }: IHeaderFollowButtonProps): ReactElement => (
        <Button
            className={classNames(styles.followButton, {
                [styles.unfollowButton]: isFollowing,
            })}
            onClick={onClick}
        >
            {isFollowing ? 'unfollow' : 'follow'}
        </Button>
    ),
);

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TooltipProps } from 'recharts';
import { FormatUtils } from '../../../../../../../../../../../../utils';

export const CustomTooltip: FC<TooltipProps<number, string>> = ({
    payload,
}: TooltipProps<number, string>): ReactElement => {
    const { createdAt, votes } = payload?.[0]?.payload || {};

    return (
        <div className={styles.tooltipWrapper}>
            <span>date: {createdAt}</span>
            <span>votes: {FormatUtils.formatNumberWithCommas(Number(votes), 0)}</span>
        </div>
    );
};

import React, { FC, ReactElement, useCallback, useState, useEffect, FormEvent, useMemo } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { isEmpty } from 'lodash';

import styles from './styles.module.scss';

import { Loader } from 'components';
import { PlansInfo, StartPremiumPlan, UserAccountInfo } from './components';

import { PLANS_PAGES_INDEXES, plansDurations, paymentMethods, PLAN_ACTIONS } from 'constants/plansItems';

import { useAppSelector, useAppDispatch } from 'store';
import { ClientsActions, PremiumPlansActions, PremiumSubscriptionActions } from 'store/actions';
import { filteredBluesnapPlans, filteredPaypalPlans } from './utils';
import { getPremiumSubscriptionIdFromStorage, getPremiumUserTokenFromStorage } from 'web3/storageHelper';
import { useHistoryPush } from 'hooks';

import { IFilteredPlans, IPlansPageBodyProps } from 'types/interfaces';

export const PlansPageBody: FC<IPlansPageBodyProps> = ({ type }: IPlansPageBodyProps): ReactElement => {
    const { error: clientError, created, loading } = useAppSelector(({ premiumClients }) => premiumClients);
    const { paypalPlans, bluesnapPlans } = useAppSelector(({ premiumPlans }) => premiumPlans);
    const {
        subscriptionLoading,
        paypalSubscriptionError,
        bluesnapSubscriptionErrors,
        premiumPFToken,
        premiumUserData,
        premiumUserDataLoading,
    } = useAppSelector(({ premiumSubscription }) => premiumSubscription);

    const isUpgradingType = type === PLAN_ACTIONS.UPDATING;
    const isCreatingType = type === PLAN_ACTIONS.CREATING;

    const dispatch = useAppDispatch();

    const [activePageIndex, setActivePageIndex] = useState(PLANS_PAGES_INDEXES.PLANS_INFO);

    const subscriptionId = getPremiumSubscriptionIdFromStorage();
    const premiumUserToken = getPremiumUserTokenFromStorage();

    const { navigateToAccountSettings } = useHistoryPush();

    const formik = useFormik({
        initialValues: {
            userInfo: {
                fullName: isCreatingType && premiumUserToken && premiumUserData.name ? premiumUserData.name : '',
                email: isCreatingType && premiumUserToken && premiumUserData.email ? premiumUserData.email : '',
                repeatedEmail: '',
                countryCode:
                    isCreatingType && premiumUserToken && premiumUserData.countryCode
                        ? premiumUserData.countryCode
                        : '',
                phoneNumber:
                    isCreatingType && premiumUserToken && premiumUserData.phoneNumber
                        ? premiumUserData.phoneNumber
                        : '',
                address: isCreatingType && premiumUserToken && premiumUserData.address ? premiumUserData.address : '',
                country: isCreatingType && premiumUserToken && premiumUserData.country ? premiumUserData.country : '',
                postalCode:
                    isCreatingType && premiumUserToken && premiumUserData.zipCode ? premiumUserData.zipCode : '',
                rightsApproved: false,
            },
            planInfo: {
                duration: plansDurations[0],
                planName: '',
                title: '',
                price: null,
            },
            creditCardInfo: {
                cardholderName: '',
                paymentMethodInfo: paymentMethods[1],
            },
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            userInfo: Yup.object().shape({
                fullName: Yup.string().required('Required'),
                email: Yup.string().email().required('Required'),
                repeatedEmail: Yup.string()
                    .email()
                    .required('Required')
                    .when('email', (email, schema) =>
                        schema.test({
                            test: (repeatedEmail: string) => repeatedEmail === email,
                            message: 'Repeated email does not match',
                        }),
                    ),
                countryCode: Yup.string(),
                phoneNumber: Yup.string(),
                address: Yup.string(),
                country: Yup.string(),
                postalCode: Yup.string(),
                rightsApproved: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
            }),
            creditCardInfo: Yup.object().shape({
                cardholderName: Yup.string().required('Required'),
            }),
        }),

        onSubmit: () => {
            return;
        },
    });

    const createUserAccount = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            dispatch(
                ClientsActions.saveClient.request({
                    ...formik.values.userInfo,
                    zipCode: formik.values.userInfo.postalCode,
                    name: formik.values.userInfo.fullName,
                }),
            );
        },
        [formik],
    );

    useEffect(() => {
        dispatch(PremiumPlansActions.fetchPaypalPremiumPlans.request());
        dispatch(PremiumPlansActions.fetchBluesnapPremiumPlans.request());
        if (isUpgradingType && isEmpty(premiumUserData))
            dispatch(PremiumSubscriptionActions.getPremiumUserData.request());

        if (isCreatingType && isEmpty(premiumUserData) && premiumUserToken) {
            dispatch(PremiumSubscriptionActions.getPremiumUserData.request());
        }
    }, []);

    const changePlansPage = useCallback(
        (pageIndex: PLANS_PAGES_INDEXES) => {
            setActivePageIndex(pageIndex);
        },
        [activePageIndex],
    );

    const bluesnapSubscription = useCallback(() => {
        const [firstName, lastName] = isUpgradingType
            ? premiumUserData.name.split(' ')
            : formik.values.creditCardInfo.cardholderName.split(' ');

        const data = {
            firstName: firstName || '',
            lastName: lastName || '',
            email: isUpgradingType ? premiumUserData.email : formik.values.userInfo.email,
            pfToken: premiumPFToken,
            planId: filteredBluesnapPlans(bluesnapPlans).find(
                (plan: IFilteredPlans) =>
                    plan.duration === formik.values.planInfo.duration.value &&
                    plan.planName === formik.values.planInfo.planName,
            )?.id,
        };

        dispatch(PremiumSubscriptionActions.bluesnapSubscribe.request({ data, navigate: navigateToAccountSettings }));
    }, [formik, bluesnapPlans, isUpgradingType, premiumUserData, premiumPFToken, subscriptionId]);

    const paypalSubcription = useCallback(() => {
        const [given_name, surname] = isUpgradingType
            ? premiumUserData.name.split(' ')
            : formik.values.userInfo.fullName.split(' ');

        const data = {
            given_name: given_name || '',
            surname: surname || '',
            email_address: isUpgradingType ? premiumUserData.email : formik.values.userInfo.email,
            plan_id: filteredPaypalPlans(paypalPlans).find(
                (plan: IFilteredPlans) =>
                    plan.duration === formik.values.planInfo.duration.value &&
                    plan.planName === formik.values.planInfo.planName.toUpperCase(),
            )?.id,
        };

        dispatch(
            isUpgradingType
                ? PremiumSubscriptionActions.paypalSubscriptionUpdate.request({
                      data,
                      paymentProvider: premiumUserData.paymentProvider,
                      subscriptionId,
                  })
                : PremiumSubscriptionActions.paypalSubscribe.request({ data }),
        );
    }, [formik, paypalPlans, isUpgradingType, premiumUserData, subscriptionId]);

    const renderPlansPage = useCallback(() => {
        switch (activePageIndex) {
            case PLANS_PAGES_INDEXES.PLANS_INFO: {
                return (
                    <PlansInfo
                        formik={formik as any}
                        changePlansPage={changePlansPage}
                        accountCreated={
                            created ||
                            Boolean(isUpgradingType && !isEmpty(premiumUserData)) ||
                            Boolean(isCreatingType && !isEmpty(premiumUserData))
                        }
                        isUpgradingType={isUpgradingType}
                        premiumUserData={premiumUserData}
                    />
                );
            }

            case PLANS_PAGES_INDEXES.ACCOUNT_INFO: {
                return (
                    <UserAccountInfo
                        formik={formik as any}
                        clientError={clientError}
                        accountCreated={created}
                        changePlansPage={changePlansPage}
                        createUserAccount={createUserAccount}
                        loading={loading}
                    />
                );
            }

            case PLANS_PAGES_INDEXES.PAYMENT: {
                return (
                    <StartPremiumPlan
                        formik={formik as any}
                        changePlansPage={changePlansPage}
                        bluesnapSubscription={bluesnapSubscription}
                        paypalSubcription={paypalSubcription}
                        filteredBluesnapPlansLoaded={filteredBluesnapPlans.length > 0}
                        filteredPaypalPlansLoaded={filteredPaypalPlans.length > 0}
                        subscriptionLoading={subscriptionLoading}
                        paypalSubscriptionError={paypalSubscriptionError}
                        bluesnapSubscriptionErrors={bluesnapSubscriptionErrors}
                        premiumPFToken={premiumPFToken}
                        isUpgradingType={isUpgradingType}
                    />
                );
            }
        }
    }, [activePageIndex, formik]);

    return <>{premiumUserDataLoading ? <Loader /> : <div className={styles.wrapper}>{renderPlansPage()}</div>}</>;
};

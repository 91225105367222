export const data: { organization: string; title: string; description: string }[] = [
    {
        organization: 'Uniswap',
        title: 'Uniswap',
        description:
            'Uniswap is a decentralized token exchange which brings transparency to the market and reduces fraud.',
    },
    {
        organization: 'Decentraland',
        title: "Decentra land: The Virtual Reality Platform That Isn't Owned by Anyone",
        description:
            'The Decentraland DAO is a blockchain-based decentralized organization that provides governance for the Decentraland ecosystem.',
    },
    {
        organization: 'Aave',
        title: 'Aave Blockchain Solutions, Crypto Exchange and Trading',
        description:
            'Aave crypto is an investment platform that offers three distinctive investment vehicles to capitalize on the future of the blockchain.',
    },
    {
        organization: 'Compound',
        title: "Compound The World's First Decentralized Autonomous Organization",
        description:
            'Compound is a platform for creating open financial products that connect traditional and crypto assets to smart contracts.',
    },
    {
        organization: 'Sushiswap',
        title: 'Sushiswap',
        description:
            'SushiSwap, a Decentralized Exchange that forked Uniswap, has decentralized governance and development. It allows investments in diverse, cross-blockchain DeFi products.',
    },
    {
        organization: 'Polkadot',
        title: 'Polkadot DAO, First Blockchain Network W Distributed Consensus',
        description:
            'Polkadot is an experimental new blockchain technology that provides a secure and scalable method of creating interoperable decentralized applications.',
    },
    {
        organization: 'Ribbon',
        title: 'Blockchain-based Decentralized Autonomous Organization',
        description:
            'Ribbon is a decentralized and tokenized platform that provides the tools for creating DAOs and managing their life cycle on the Ethereum blockchain.',
    },
    {
        organization: 'Serum',
        title: 'Serum DAO is an on-chain central orderbook for liquidity',
        description:
            'Serum DAO is an on-chain central orderbook that serves liquidity and DeFi trading products. It has its own DEX and a deal-matching engine. This allows it to prioritize its liquidity providers, traders, and apps.',
    },
    {
        organization: 'Silo',
        title: "SiloDAO to Build World's 1st Decentralized Ecommerce Platform",
        description:
            'Silo DAO is a blockchain-based governance system for enterprise. Silo provides an automated and transparent framework for making decisions and executing actions, resulting in a system that is fair, equitable, and accountable.',
    },
    {
        organization: 'ENS',
        title: 'ENS DAO: Decentralized Autono Organization for Ethereum Name',
        description:
            'ENS.DAO is a decentralized organization that is building the directory of domains and mapping them to Ethereum addresses.',
    },
    {
        organization: 'Gnosis',
        title: 'Gnosis - The Decentralized Platform for Prediction Markets',
        description:
            'Gnosis is an Ethereum-based prediction market platform where people can trade in the outcome of events.',
    },
    {
        organization: 'Lido',
        title: 'Lido DAO Self-Evolving Decentralized Autonomous Organization',
        description:
            'Lido is a blockchain-based, decentralized autonomous organization (DAO) that provides the tools to optimize and automate the revenue generation process for filmmakers.',
    },
    {
        organization: 'Kusama',
        title: 'How Kusama DAO Creates a New Global Economy',
        description:
            'Kusama is a decentralized autonomous organization that aims to build a nurturing and sustainable environment for the intellectual development of its members.',
    },
    {
        organization: 'Gitcoin',
        title: 'Earn Crypto Currency by Contributing to Open Source Software',
        description:
            'Gitcoin is a cryptocurrency and blockchain project built on Ethereum. It seeks to establish a protocol for decentralized micro-lending, with the goal of creating a global network of peers that can lend to one another.',
    },
    {
        organization: 'ShapeShift',
        title: 'ShapeShift DAO: decentralized token exchange',
        description:
            'ShapeShift is the fastest and safest way for crypto traders to exchange digital currency. We offer dozens of digital currencies and can convert them instantly without using a fiat currency.',
    },
    {
        organization: 'SuperRare',
        title: 'SuperRare DAO: The Future of Blockchain',
        description:
            'We are a decentralized autonomous organization that produces digital artworks and facilitates the sale of these works on our own blockchain.',
    },
    {
        organization: 'OlympusDAO',
        title: 'OlympusDAO: Decentralized Autonomous Organizations',
        description:
            'Olympus is a decentralized, blockchain-based financial ecosystem of crypto-assets, crypto-fiat currencies and fiduciary money.',
    },
    {
        organization: 'FeistyDAO',
        title: 'Feisty DAO is a market-leading AI-driven decentralized platform ',
        description:
            'FeistyDAO is a decentralized data protection ecosystem. It is designed to empower people by giving them control over their own data.',
    },
    {
        organization: 'Balancer',
        title: 'Balancer DAO Aims to Revolutionize Cryptocurrency',
        description:
            'Balancer is a DAO, a decentralized autonomous organization governed by its own set of rules. It is an evolving, living system that allows for the collective management of digital assets and automated distribution of profits.',
    },
    {
        organization: 'Tornado Cash',
        title: 'Tornado Cash is a DAO Platform for Crypto Investors',
        description:
            'Tornado Cash is a DAO-based cryptocurrency and is the first with its own blockchain. TCASH can be mined or traded on exchange platforms.',
    },
    {
        organization: 'Ampleforth',
        title: 'Ampleforth',
        description:
            'Ampleforth is a DAO that facilitates the organization of groups. It acts as a secure and transparent tool for managing resources, raising capital and make collective decisions.',
    },
    {
        organization: 'Cryptex',
        title: 'Cryptex DAO - Decentralized Autonomous Organization',
        description:
            'CryptexDAO is a decentralized autonomous organization, built on the Ethereum blockchain. It utilizes smart contracts to enable users to buy and sell cryptocurrency, as well as providing a set of tools for managing wallets and exchanging tokens.',
    },
    {
        organization: 'Synthetix',
        title: 'Synthetix DAO: Democratizing Business Ownership',
        description:
            'Synthetix is a DAO marketplace and platform where people can contribute their time, money, knowledge and skills to help each other.',
    },
    {
        organization: 'API3',
        title: 'API3 DAO: The Future of Decentralized Autonomous Organizations',
        description:
            'The API3DAO is a decentralized autonomous organization, developed for the collaboration of projects and people in the field of artificial intelligence.',
    },
    {
        organization: 'Acala',
        title: "The world's most secure decentralized platform for cryptocurrency",
        description:
            'Acala is a Decentralized Autonomous Organization which uses the power of blockchain technology to create an independent, resilient, self-sustaining and democratic organization.',
    },
    {
        organization: 'Metaplex',
        title: 'The Metaplex DAO is a revolutionary decentralized ecosystem.',
        description:
            'Metaplex is a DAO for the next generation of decentralized economies. Whether you are an entrepreneur, investor, or contributor, Metaplex will allow you to invest in and build your own DAO.',
    },
    {
        organization: 'yearn.finance',
        title: 'Yearn.finance: Comprehensive Personal Finance Management',
        description:
            'The yearn.finance DAO is a decentralized autonomous organization that builds and implements cryptocurrency-driven programs, such as Yearn.io, to help people learn about and gain control of their finances.',
    },
    {
        organization: 'Aavegotchi',
        title: 'Aavegotchi Coin: The Latest and Greatest in Digital Currency',
        description:
            'Aavegotchi coin is a brand new type of digital currency, called cryptocurrency, which is made up of blocks of code.',
    },
    {
        organization: 'Maker Dao',
        title: 'MakerDao - Decentralized, User-controlled and Autonomous Org',
        description:
            'MakerDAO is a decentralized organization built on the Ethereum blockchain that provides global stability to the crypto economy. We use a low volatility cryptocurrency called Dai to create stability in the platform.',
    },
    {
        organization: 'NFTX',
        title: 'The NFTX DAO is a Decentralized Autonomous Organization',
        description:
            'NFTX tokenizes NFTs for DeFi collaterals. This facilitates their discovery, yielding, and farming among other venues through its NFT marketplace.',
    },
    {
        organization: 'Radicle',
        title: 'Radicle DAO: The Blockchain Solution for Decentralizing Business Operations',
        description:
            'Radicle is a community-governed code network that encourages collaboration in coding in an interactive, permissionless environment. It also has designated tools.',
    },
    {
        organization: 'Badger DAO',
        title: 'Badger DAO: The Future of Decentralized Digital Identity',
        description:
            'Badger is a decentralized autonomous organization that issues tokens on the Ethereum blockchain. It is designed to be an operating system for marketplaces, with embedded protections against fraud, censorship and data breaches.',
    },
    {
        organization: 'Solend',
        title: 'SolendDAO Revolutionizes the Way We Invest',
        description:
            'Solend is a decentralized service for the development and execution of tasks. It is based on blockchain technology and smart contracts, which ensures the full transparency of transactions, as well as the absence of any centralized authority.',
    },
    {
        organization: 'Decentral Games',
        title: 'Decentral Games - A Blockchain Gaming Platform',
        description:
            'Decentral Games is a cooperative-competitive game studio based in Vancouver, Canada. We are passionate about board games and video games, and we want to make them for you.',
    },
    {
        organization: 'Instadapp',
        title: 'Instadapp is an asset investment manager that works across all DeFi',
        description:
            'Instadapp is a decentralized Financial Service platform that provides easy, fast and affordable access to financial products.',
    },
    {
        organization: 'Merit Circle',
        title: 'Merit Circle: The Future of Crypto and Blockchain Money',
        description:
            'Merit Circle, a Play2Earn DAO, is designed to work across multiple metaverse gaming ecosystems. It does this by investing, developing and incubating creative content, building professional gaming communities & training,',
    },
    {
        organization: 'BitDAO',
        title: 'BitDAO - Blockchain-based Decentralized Autonomous Organization',
        description:
            'BitDAO was launched by ByBit DEX, and is now being funded. It aims to partner with other owners in order to create, grant, and support a network of crypto projects that promote DeFi governance, education, and treasury management.',
    },
    {
        organization: 'KeeperDAO',
        title: 'KeeperDAO: Ethereum-based Decentralized Exchange',
        description:
            'KeeperDAO offers a decentralized network of custody solutions to help investors and traders keep their digital assets secure.',
    },
    {
        organization: 'Nouns DAO',
        title: 'Nouns organizes and funds fundraising around NFT avatars',
        description:
            'Nouns organizes fundraising around NFT avatars. It generates auctions every day. Avatars are used as voting tokens and their revenues support outreach and development.',
    },
];

import { Reducer } from '@reduxjs/toolkit';

import { DEFAULT_OFFSET_VALUE, SELECTED_FILTERS_LOCAL_STORAGE_KEY } from 'constants/organizationsSearch';

import { OrganizationsSearchActions } from 'store/actions';

import { IOrganizationsSearchActionTypes, IOrganizationsSearchState } from 'types/interfaces';

const defaultState: IOrganizationsSearchState = {
    organizationsSearchedDataLoading: false,
    categoriesDataLoading: false,
    organizationsSearchedData: [],
    organizationsCategoriesData: [],
    selectedFiltersData: JSON.parse(localStorage.getItem(SELECTED_FILTERS_LOCAL_STORAGE_KEY) || '[]'),
    offset: DEFAULT_OFFSET_VALUE,
};

export const organizationsSearchReducer: Reducer<IOrganizationsSearchState, IOrganizationsSearchActionTypes> = (
    state: IOrganizationsSearchState = defaultState,
    action: IOrganizationsSearchActionTypes,
): IOrganizationsSearchState => {
    const { type, payload } = <IOrganizationsSearchActionTypes>action;

    switch (type) {
        case OrganizationsSearchActions.GET_ORGANIZATIONS_SEARCHED_DATA: {
            return {
                ...state,
                organizationsSearchedDataLoading: true,
                organizationsSearchedData: [],
                ...payload,
            };
        }

        case OrganizationsSearchActions.GET_ORGANIZATIONS_SEARCHED_DATA_SUCCESS: {
            return {
                ...state,
                organizationsSearchedDataLoading: false,
                ...payload,
            };
        }

        case OrganizationsSearchActions.GET_ORGANIZATIONS_SEARCHED_DATA_FAILURE: {
            return {
                ...state,
                organizationsSearchedDataLoading: false,
            };
        }

        case OrganizationsSearchActions.GET_ORGANIZATIONS_CATEGORIES_DATA: {
            return {
                ...state,
                categoriesDataLoading: true,
            };
        }

        case OrganizationsSearchActions.GET_ORGANIZATIONS_CATEGORIES_DATA_SUCCESS: {
            return {
                ...state,
                categoriesDataLoading: false,
                ...payload,
            };
        }

        case OrganizationsSearchActions.GET_ORGANIZATIONS_CATEGORIES_DATA_FAILURE: {
            return {
                ...state,
                categoriesDataLoading: false,
            };
        }

        case OrganizationsSearchActions.UPDATE_ORGANIZATIONS_SEARCHED_DATA_LIMIT: {
            return {
                ...state,
                ...payload,
            };
        }

        default: {
            return state;
        }
    }
};

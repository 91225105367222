import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services';

import {
    ISearchedOrganizationFilterTransformedQueryData,
    IGetOrganizationsCategoriesDataResponse,
    IGetOrganizationsSearchedDataResponse,
} from 'types/interfaces';

export const OrganizationsSeacrhApi = {
    getOrganizationsSearchedData: (
        data?: ISearchedOrganizationFilterTransformedQueryData,
    ): Promise<AxiosResponse<IGetOrganizationsSearchedDataResponse>> =>
        axiosInstance.get<IGetOrganizationsSearchedDataResponse>(`organization/search`, {
            params: data,
        }),
    getOrganizationsCategoriesData: (): Promise<AxiosResponse<IGetOrganizationsCategoriesDataResponse>> =>
        axiosInstance.get<IGetOrganizationsCategoriesDataResponse>(`organization/categories`),
};

import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services';

import { IPaypalPlan, IBluesnapPlan, IGetBluesnapPlansResponse } from 'types/interfaces';

export const PremiumPlans = {
    fetchPaypalPremiumPlans: (): Promise<AxiosResponse<IPaypalPlan[]>> =>
        axiosInstance.get<IPaypalPlan[]>('paypal/plan'),
    getPaypalPlan: (id: string): Promise<AxiosResponse<IPaypalPlan>> =>
        axiosInstance.get<IPaypalPlan>(`paypal/plan/${id}`),
    fetchBluesnapPremiumPlans: (): Promise<AxiosResponse<IGetBluesnapPlansResponse>> =>
        axiosInstance.get<IGetBluesnapPlansResponse>('bluesnap/plan'),
    getBluesnapPlan: (id: string): Promise<AxiosResponse<IBluesnapPlan>> =>
        axiosInstance.get<IBluesnapPlan>(`bluesnap/plan/${id}`),
};

import React, { FC, ReactElement, useState } from 'react';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import styles from './styles.module.scss';

import { Loader, TableDataNotFound, TooltipWrapper } from 'components';
import { TableHeader, TableRow } from './components';

import { FRIENDS_AND_ENEMIES_PARAMS } from './constants';
import { friendsAndEnemiesMockUpData } from './mockupData';

import { tableDataSort } from 'helpers';

import { IFriendsAndEnemiesTableProps, ISortedParam, IUserFriendsAndEnemiesTableData } from 'types/interfaces';

export const FriendsAndEnemiesTable: FC<IFriendsAndEnemiesTableProps> = ({
    title,
    tooltipText,
    data,
    isFriendsTable = false,
    loading,
    isForbidden,
}: IFriendsAndEnemiesTableProps): ReactElement => {
    const [sortedParam, setSortedParam] = useState<ISortedParam>({
        paramName: isFriendsTable
            ? FRIENDS_AND_ENEMIES_PARAMS.FRIENDLY_VOTES
            : FRIENDS_AND_ENEMIES_PARAMS.HOSTILE_VOTES,
        descending: true,
    });

    const sortByParam = (param: string) => {
        setSortedParam({
            paramName: param,
            descending:
                sortedParam.paramName === param
                    ? !sortedParam.descending
                    : param === FRIENDS_AND_ENEMIES_PARAMS.DAO_SCORE
                    ? false
                    : true,
        });
    };

    const tableData = isForbidden ? friendsAndEnemiesMockUpData : data;

    return (
        <div className={styles.content}>
            <div className={styles.titleSection}>
                <h4 className={styles.title}>{title}</h4>
                <TooltipWrapper title={tooltipText}>
                    <InfoOutlinedIcon className={styles.infoIcon} />
                </TooltipWrapper>
            </div>
            {tableData.length > 0 && (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        <TableHeader
                            isFriendsTable={isFriendsTable}
                            sortByParam={sortByParam}
                            sortedParamName={sortedParam.paramName}
                        />

                        {tableDataSort(tableData, sortedParam).map((items: IUserFriendsAndEnemiesTableData) => (
                            <TableRow
                                key={items.coVoter}
                                {...items}
                                isFriendsTable={isFriendsTable}
                                sortedParamName={sortedParam.paramName}
                            />
                        ))}
                    </div>
                </div>
            )}
            {loading && tableData.length === 0 && <Loader size={40} className={styles.loaderBox} />}
            {tableData.length === 0 && !loading && !isForbidden && (
                <TableDataNotFound forTable overflowMarginsSecondVariant />
            )}
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { MintSection, ScoreStats } from './components';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { useAppSelector } from 'store';
import { FormatUtils } from 'utils';
import { getFirsAndLastDates } from './utils';

import { IUserProposalData, IUserProposalsData, IUserVoteData, IUserVotesData } from 'types/interfaces';

const SPECIFIC_USER_VALUE = -1;

export const ScoreInfo: FC = (): ReactElement => {
    const {
        userData: { totalProposals, totalVotes, totalOrgs },
        userProposalsData,
        userVotesData,
        userLabelsData,
    } = useAppSelector(({ user }) => user);

    const gScore = FormatUtils.checkIfDataNotExist(userLabelsData?.gScore)
        ? DATA_NOT_FOUND
        : FormatUtils.truncDigits(userLabelsData?.gScore as number, 2);
    const totalOrganizationsValue = FormatUtils.checkIfDataNotExist(totalOrgs)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberShort(totalOrgs === SPECIFIC_USER_VALUE ? 0 : Number(totalOrgs), 1, true);
    const totalProposalsValue = FormatUtils.checkIfDataNotExist(totalProposals)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberShort(totalProposals === SPECIFIC_USER_VALUE ? 0 : Number(totalProposals), 1, true);
    const totalVotesValue = FormatUtils.checkIfDataNotExist(totalVotes)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberShort(totalVotes === SPECIFIC_USER_VALUE ? 0 : Number(totalVotes), 1, true);
    const gScoreRanking = FormatUtils.checkIfDataNotExist(userLabelsData?.gScoreRanking)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberWithCommas(userLabelsData?.gScoreRanking as number, 0);

    return (
        <div className={styles.card}>
            <MintSection gScoreRanking={gScoreRanking} gScore={gScore} />

            <div className={styles.statsWrapper}>
                <div className={styles.titleWrapper}>
                    <h2 className={styles.title}>Mint G-score SBT</h2>
                </div>
                <div className={styles.statsContainer}>
                    <div className={styles.mainStats}>
                        <div>
                            <p className={styles.description}>
                                G-score <br />
                                Ranking #
                            </p>
                            <h4 className={styles.counter}>{gScoreRanking}</h4>
                        </div>
                        <div>
                            <p className={styles.description}>G-score %</p>
                            <h4 className={styles.counter}>{gScore}</h4>
                        </div>
                    </div>
                    <div className={styles.mainCounter}>
                        <ScoreStats title="organizations" mainCounter={totalOrganizationsValue} showDates={false} />
                        <ScoreStats
                            title="proposals"
                            mainCounter={totalProposalsValue}
                            {...getFirsAndLastDates<IUserProposalsData, IUserProposalData>(userProposalsData)}
                        />
                        <ScoreStats
                            title="votes"
                            mainCounter={totalVotesValue}
                            {...getFirsAndLastDates<IUserVotesData, IUserVoteData>(userVotesData)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

import { IUserFriendsAndEnemiesTableData } from 'types/interfaces';

const friendsAndEnemiesMockUpData: IUserFriendsAndEnemiesTableData[] = [
    {
        coVoter: '0xf0000000000000000000',
        coVoterDaoScore: 2143,
        coVoterName: 'User name 1',
        friendlyVotes: 742,
        targetVoter: '0xf1111111111111111111111111111',
        hostileVotes: 423,
        name: 'User name 1',
    },
    {
        coVoter: '0xf0000000000000000001',
        coVoterDaoScore: 1234,
        coVoterName: 'User name 2',
        friendlyVotes: 18,
        targetVoter: '0xf1111111111111111111111111112',
        hostileVotes: 9,
        name: 'User name 2',
    },
    {
        coVoter: '0xf0000000000000000002',
        coVoterDaoScore: 2345,
        coVoterName: 'User name 3',
        friendlyVotes: 888,
        targetVoter: '0xf1111111111111111111111111113',
        hostileVotes: 523,
        name: 'User name 3',
    },
    {
        coVoter: '0xf0000000000000000003',
        coVoterDaoScore: 111,
        coVoterName: 'User name 4',
        friendlyVotes: 654,
        targetVoter: '0xf1111111111111111111111111114',
        hostileVotes: 823,
        name: 'User name 4',
    },
    {
        coVoter: '0xf0000000000000000004',
        coVoterDaoScore: 820,
        coVoterName: 'User name 5',
        friendlyVotes: 23413,
        targetVoter: '0xf1111111111111111111111111115',
        hostileVotes: 2311,
        name: 'User name 5',
    },
    {
        coVoter: '0xf0000000000000000005',
        coVoterDaoScore: 72,
        coVoterName: 'User name 6',
        friendlyVotes: 12,
        targetVoter: '0xf1111111111111111111111111116',
        hostileVotes: 14,
        name: 'User name 6',
    },
    {
        coVoter: '0xf0000000000000000006',
        coVoterDaoScore: 63,
        coVoterName: 'User name 7',
        friendlyVotes: 20,
        targetVoter: '0xf1111111111111111111111111117',
        hostileVotes: 30,
        name: 'User name 7',
    },
];

export { friendsAndEnemiesMockUpData };

import React, { FC, ReactElement } from 'react';

import { Button } from '@material-ui/core';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ALL_CATEGORIES_ID } from 'pages/ResourcesPage/constants';

import { IResourcesCategoryButtonProps } from 'types/interfaces';

export const ResourcesCategoryButton: FC<IResourcesCategoryButtonProps> = ({
    category: { id: categoryId, name },
    categorySelected,
    categoryFilter,
    loading,
}: IResourcesCategoryButtonProps): ReactElement => {
    const isCategorySelected = categorySelected === categoryId;

    return (
        <div className={styles.categoryContainer}>
            <Button
                className={classNames(styles.categoryButton, {
                    [styles.allCategoriesButton]: categoryId === ALL_CATEGORIES_ID,
                    [styles.categoryButtonSelected]: isCategorySelected,
                })}
                onClick={() => categoryFilter(categoryId)}
                disabled={loading}
            >
                {name}
            </Button>
        </div>
    );
};

import { axiosInstance } from 'services';
import { AxiosResponse } from 'axios';

import {
    IUserData,
    IUserProposalData,
    IUserVoteData,
    IUpdateUserDataRequest,
    IUpdateUserDataResponse,
    IUserOpportunitiesResponse,
    ISimilarUserData,
    IUserTokenData,
    IFollowingResponse,
    IUserNotificationsData,
    INotificationData,
    IUserFriendsAndEnemiesData,
    IUserLabelsData,
} from 'types/interfaces';

import { getDeepDAOTokenFromStorage } from 'web3/storageHelper';

export const UserApi = {
    getUserData: (address: string): Promise<AxiosResponse<IUserData>> =>
        axiosInstance.get<IUserData>(`user/${address}`),
    getUserProposalsData: (address: string): Promise<AxiosResponse<IUserProposalData>> =>
        axiosInstance.get<IUserProposalData>(`user/${address}/proposals`),
    getUserVotesData: (address: string): Promise<AxiosResponse<IUserVoteData>> =>
        axiosInstance.get<IUserVoteData>(`user/${address}/votes`),
    getUserTokenListData: (address: string): Promise<AxiosResponse<IUserTokenData>> =>
        axiosInstance.get<IUserTokenData>(`user/${address}/tokens`),
    updateUserData: (updateData: IUpdateUserDataRequest): Promise<AxiosResponse<IUpdateUserDataResponse>> =>
        axiosInstance.put<IUpdateUserDataResponse>('user/personal-info/update', updateData, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    updateUserAvatar: (userId: string, data: FormData): Promise<AxiosResponse<IUpdateUserDataResponse>> =>
        axiosInstance.put<IUpdateUserDataResponse>(`user/personal-info/avatar`, data, {
            headers: {
                Authorization: `Bearer ${getDeepDAOTokenFromStorage()}`,
                'Content-Type': 'multipart/form-data',
            },
        }),
    updateUserOpportunities: (userId: string, openFor: string): Promise<AxiosResponse<IUserOpportunitiesResponse>> =>
        axiosInstance.put<IUserOpportunitiesResponse>(
            `user/personal-info/open-for`,
            { openFor },
            {
                headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
            },
        ),
    getSimilarUsersByOrganizationsData: (address: string): Promise<AxiosResponse<ISimilarUserData[]>> =>
        axiosInstance.get<ISimilarUserData[]>(`user/${address}/similar-people-by-dao`),
    getSimilarUsersByTokensData: (address: string): Promise<AxiosResponse<ISimilarUserData[]>> =>
        axiosInstance.get<ISimilarUserData[]>(`user/${address}/similar-people-by-token`),
    getUserIsFollowingData: (address: string): Promise<AxiosResponse<IFollowingResponse>> =>
        axiosInstance.get<IFollowingResponse>(`social-features/following-person/${address}`, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    followUser: (address: string): Promise<AxiosResponse<IFollowingResponse>> =>
        axiosInstance.post<IFollowingResponse>(`social-features/follows-person/${address}`, null, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    unFollowUser: (address: string): Promise<AxiosResponse<IFollowingResponse>> =>
        axiosInstance.delete<IFollowingResponse>(`social-features/unfollows-person/${address}`, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    getUserNotifications: (
        token: string,
        notificationsOffset: number,
    ): Promise<AxiosResponse<IUserNotificationsData>> =>
        axiosInstance.get<IUserNotificationsData>(
            `social-features/notifications?limit=10&offset=${notificationsOffset}`,
            {
                headers: { Authorization: `Bearer ${token}` },
            },
        ),
    updateNotificationSeenStatus: (notificationId: string): Promise<AxiosResponse<INotificationData[]>> =>
        axiosInstance.put<INotificationData[]>(`social-features/notifications/${notificationId}`, null, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    getUserFriendsAndEnemiesData: (
        address: string,
        orderBy: string,
    ): Promise<AxiosResponse<IUserFriendsAndEnemiesData[]>> =>
        axiosInstance.get<IUserFriendsAndEnemiesData[]>(
            `user/friends_and_antagonists/${address}?orderBy=${orderBy}&limit=20&offset=0`,
        ),
    getUserLabelsData: (userId: string): Promise<AxiosResponse<IUserLabelsData>> =>
        axiosInstance.get<IUserLabelsData>(`user/labels/${userId}`),
};

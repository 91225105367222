import React, { FC, ReactElement, useEffect } from 'react';

import { useMediaQuery } from '@material-ui/core';

import styles from './styles.module.scss';

import { AboutSellingPoint, DeepDaoMembersSection, DeepDaoPartnersRow, AboutEndlessSlider, HrefLink } from 'components';

import { aboutSellingPoints, members, partners, supported } from './constants';
import { DEEP_DAO_MAIL } from 'constants/deepDaoLinks';

import { mixpanelService } from 'services';

import { ISellingPoint } from 'types/interfaces';

export const AboutPage: FC = (): ReactElement => {
    const isTablet = useMediaQuery('(max-width: 767.98px)');

    useEffect(() => mixpanelService.track(mixpanelService.eventsGenerator.aboutEvent()), []);

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.introduction}>
                    <div className={styles.introducing}>
                        <h1 className={styles.introducingTitle}>
                            DeepDAO is the leading discovery and analytics engine for the DAO ecosystem.
                        </h1>
                        <h6 className={styles.introducingDescription}>
                            DeepDAO’s passion is to aggregate, list, and analyze financial and governance data for the
                            super-fast growing DAO ecosystem. We feature thousands of DAOs, and millions of governance
                            participants, thus creating a vast network of social and work related connections in the
                            Web3 space.
                        </h6>
                    </div>
                    <div className={styles.sellingPoints}>
                        {aboutSellingPoints.map(({ id, ...items }: ISellingPoint) => (
                            <AboutSellingPoint key={id} {...items} />
                        ))}
                    </div>
                </div>
                <div className={styles.partnersInfo}>
                    <h6 className={styles.partnersInfoTitle}>Strengh in collaboration</h6>
                    <h2 className={styles.partnersInfoDescription}>DeepDAO Partners</h2>
                    <DeepDaoPartnersRow data={partners.firstSection} className={styles.firstSection} />
                    <DeepDaoPartnersRow data={partners.secondSection} className={styles.secondSection} />
                </div>
                <div className={styles.teamInfo}>
                    <h6 className={styles.teamInfoTitle}>
                        <span>Deep</span>
                        <span>DAOists</span>
                    </h6>
                    <h4 className={styles.teamInfoDescription}>
                        <span>DeepDAO’s</span> <span>Team Leaders</span>
                    </h4>
                    <DeepDaoMembersSection data={members} />
                </div>
                <div className={styles.supportedBy}>
                    <h2 className={styles.supportedByTitle}>Supported by</h2>
                    <DeepDaoPartnersRow data={supported.firstSection} className={styles.firstRow} />
                    <DeepDaoPartnersRow data={supported.secondSection} className={styles.secondRow} />
                    <div className={styles.othersSupported}>
                        {isTablet ? (
                            <AboutEndlessSlider />
                        ) : (
                            <div className={styles.othersSupportedWrapper}>
                                <DeepDaoPartnersRow data={supported.thirdSection} />
                                <DeepDaoPartnersRow data={supported.fourthSection} />
                                <DeepDaoPartnersRow data={supported.fifthSection} />
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.contactInfo}>
                    <div className={styles.contactInfoWrapper}>
                        <h6>DeepDAO Ventures Ltd.</h6>
                        <p>Rothschild Boulevard 22, Tel-Aviv, 6688218 Israel</p>
                        <HrefLink href={DEEP_DAO_MAIL} disableTarget className={styles.mail}>
                            <span>info@deepdao.io</span>
                        </HrefLink>
                        <p>+972 (0)73-2844500</p>
                    </div>
                </div>
            </div>
        </>
    );
};

import React, { FC, ReactElement, useMemo } from 'react';

import { useParams, Link } from 'react-router-dom';

import ArrowBack from '@material-ui/icons/ArrowBack';

import styles from './styles.module.scss';

import { Loader } from 'components';

import { useContentful } from 'react-contentful';
import { IContentfulFaqItem, serializeContentfulData } from '../FAQPage/helper';

export const QuestionAnswerPage: FC = (): ReactElement => {
    const { id } = useParams<{ id: string }>();
    const { data, loading } = useContentful({
        contentType: 'faqlist',
    });

    const faqItems: IContentfulFaqItem[] = (data as { [key: string]: any })?.items[0].fields.faqitems || [];

    const faq = useMemo(() => serializeContentfulData(faqItems), [data]);
    const pageFaq = useMemo(() => faq?.find(({ id: _id }) => _id === id), [faq]);

    return (
        <div className={styles.wrapper}>
            {loading && <Loader />}
            <div className={styles.blurBox} />

            {!loading && (
                <div className={styles.container}>
                    <h2 className={styles.title}>{pageFaq?.question}</h2>
                    <div className={styles.linkWrapper}>
                        <Link to="/faq" className={styles.returnLink}>
                            <ArrowBack className={styles.arrowBackIcon} />
                            <span className={styles.linkText}>FAQ</span>
                        </Link>
                    </div>
                    <div className={styles.answerSection}>{pageFaq?.answer}</div>
                </div>
            )}
        </div>
    );
};

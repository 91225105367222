import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { IFormTextareaProps } from 'types/interfaces';

export const FormTextarea: FC<IFormTextareaProps> = ({
    value,
    name,
    placeholder,
    touched,
    error,
    onChange,
    onTouch,
    className,
}: IFormTextareaProps): ReactElement => (
    <div className={classNames(styles.textareaWrapper, className)}>
        <textarea
            className={classNames(styles.textarea, {
                [styles.textareaError]: touched && Boolean(error),
            })}
            placeholder={placeholder}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            onFocus={() => onTouch(name, true)}
        />
        {touched && error && <p className={styles.errorText}>{error}</p>}
    </div>
);

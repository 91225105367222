import React, { FC, ReactElement, memo } from 'react';
import classNames from 'classnames';
import { IFormRadioButtons } from 'types/interfaces/componentProps.interfaces';
import styles from './styles.module.scss';

export const FormRadioButtons: FC<IFormRadioButtons> = memo(
    ({ options, selectedOption, onChange, className }: IFormRadioButtons): ReactElement => {
        return (
            <div className={classNames(styles.radioGroup, className)}>
                {options.map((option) => {
                    const isChecked = selectedOption?.id === option?.id;

                    return (
                        <div key={option.name} className={styles.inputWrapper} onClick={() => onChange(option)}>
                            <input
                                type="radio"
                                id={option.id.toString()}
                                name={option.name}
                                value={option.name}
                                defaultChecked={isChecked}
                                className={isChecked ? styles.checked : ''}
                            />
                            <label
                                className={classNames(styles.label, {
                                    [styles.checkedLabel]: isChecked,
                                })}
                            >
                                {option.name}
                            </label>
                        </div>
                    );
                })}
            </div>
        );
    },
);

import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { HrefLink } from 'components';

import { DEEP_DAO_MAIL } from 'constants/deepDaoLinks';
import { footerInnerPageLinks, footerMails } from './constants';

import { deepDaoFooterLogo } from 'assets';

import { IPageLink } from 'types/interfaces';

export const FooterMobile: FC = (): ReactElement => (
    <footer className={styles.footerWrapper}>
        <div className={styles.linksSection}>
            <div className={styles.info}>
                <Link to="/organizations">
                    <img src={deepDaoFooterLogo} className={styles.footerLogo} alt="" />
                </Link>
                <h6 className={styles.description}>
                    Insights for a<br /> decentralized world
                </h6>
            </div>
            <div className={styles.pagesLinks}>
                <div className={styles.linksWrapper}>
                    {footerInnerPageLinks.map(({ id, name, link }: IPageLink) => (
                        <Link key={id} to={link} className={styles.link}>
                            {name}
                        </Link>
                    ))}
                </div>
                <div className={styles.linksWrapper}>
                    {footerMails.map(({ id, name, link }: IPageLink) => (
                        <HrefLink key={id} href={link} className={styles.link} disableTarget>
                            <span>{name}</span>
                        </HrefLink>
                    ))}
                </div>
            </div>
        </div>

        <div className={styles.bottomSection}>
            <HrefLink href={DEEP_DAO_MAIL} disableTarget className={styles.mail}>
                <span>info@deepdao.io</span>
            </HrefLink>
            <h6 className={styles.copyright}>© {new Date().getFullYear()} DeepDAO</h6>
        </div>
    </footer>
);

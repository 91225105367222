import React, { FC, ReactElement, useEffect, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { PremiumLoginForm } from 'components';

import { useAppDispatch, useAppSelector } from 'store';
import { PremiumSubscriptionActions } from 'store/actions';
import { useHistoryPush } from 'hooks';

export const PremiumEmailLoginPage: FC = (): ReactElement => {
    const dispatch = useAppDispatch();
    const { premiumLoginError, premiumLoginLoading } = useAppSelector(({ premiumSubscription }) => premiumSubscription);

    const { navigateToPremiumOTPLogin } = useHistoryPush();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            email: Yup.string().email().required('Required'),
        }),
        onSubmit: (values) => {
            dispatch(
                PremiumSubscriptionActions.premiumEmailLogin.request({
                    email: values.email,
                    navigate: navigateToPremiumOTPLogin,
                }),
            );
        },
    });

    const formValues = useMemo(
        () => [
            {
                id: 1,
                name: 'email',
                placeholder: 'Email',
                value: formik.values.email,
                onChange: formik.handleChange,
                touched: formik.touched?.email,
                error: formik.errors?.email,
                onTouch: formik.setFieldTouched,
            },
        ],
        [formik],
    );

    useEffect(() => {
        return () => {
            dispatch({ type: PremiumSubscriptionActions.CLEAR_PREMIUM_LOGGIN_ERROR });
        };
    }, []);

    return (
        <PremiumLoginForm
            title="Premium Login"
            description={
                <>
                    {`Don't have an account yet?`} <Link to={'/premium_account_create'}>Please create one</Link>
                </>
            }
            formTitle="Enter your account email"
            submitButtonText="send"
            formValues={formValues}
            onSubmit={formik.handleSubmit}
            error={premiumLoginError}
            loading={premiumLoginLoading}
            disabled={!(formik.isValid && formik.dirty)}
        />
    );
};

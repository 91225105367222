import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { STATUSES } from 'constants/statuses';

import { ITextStatusProps } from 'types/interfaces';

export const TextStatus: FC<ITextStatusProps> = ({
    convertedStatus,
    originalStatus,
    forFeed,
    forFeedProposal,
    removeForMobile,
}: ITextStatusProps): ReactElement => (
    <div
        className={classNames(styles.statusWrapper, {
            [styles.statusActive]: originalStatus === STATUSES.ACTIVE || originalStatus === STATUSES.QUEUED,
            [styles.statusDone]:
                originalStatus === STATUSES.EXECUTED ||
                originalStatus === STATUSES.CLOSED ||
                originalStatus === STATUSES.CANCELLED,
            [styles.statusPending]: originalStatus === STATUSES.ONGOING || originalStatus === STATUSES.PENDING,
            [styles.feedStatus]: forFeed,
            [styles.feedProposalStatus]: forFeedProposal,
            [styles.removeForMobile]: removeForMobile,
        })}
    >
        <span className={styles.status}>{convertedStatus}</span>
    </div>
);

export const DEEPDAO_ACCESS_TOKEN = 'DEEPDAO_ACCESS_TOKEN';
export const DEEPDAO_REFRESH_ACCESS_TOKEN = 'DEEPDAO_REFRESH_ACCESS_TOKEN';
export const WEB3_CONNECTION = 'WEB3_CONNECTION';
export const WEB3_CONNECT_CACHED_PROVIDER = 'WEB3_CONNECT_CACHED_PROVIDER';
export const ADDRESS = 'ADDRESS';
export const INFURA_KEY = '51ffbe3a81ed43eb937e68de5f170ac7';
export const FORTMATIC_KEY = 'pk_live_08977E22FF2A8123';
export const INJECTED_WALLET = 'injected';
export const WALLET_CONNECT = 'walletconnect';
export const FORTMATIC = 'fortmatic';
export const PREMIUM_USER_TOKEN = 'PREMIUM_USER_TOKEN';
export const PREMIUM_USER_REFRESH_TOKEN = 'PREMIUM_USER_REFRESH_TOKEN';
export const PREMIUM_SUBSCRIPTION_ID = 'PREMIUM_SUBSCRIPTION_ID';
export const PREMIUM_API_KEY = 'PREMIUM_API_KEY';
export const PREMIUM_USER_EMAIL = 'PREMIUM_USER_EMAIL';

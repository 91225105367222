import { createApiAction } from 'helpers';

export const GET_FOLLOWED_PEOPLE = 'GET_FOLLOWED_PEOPLE';
export const GET_FOLLOWED_PEOPLE_SUCCESS = 'GET_FOLLOWED_PEOPLE_SUCCESS';
export const GET_FOLLOWED_PEOPLE_FAILURE = 'GET_FOLLOWED_PEOPLE_FAILURE';

export const GET_FOLLOWING_PEOPLE = 'GET_FOLLOWING_PEOPLE';
export const GET_FOLLOWING_PEOPLE_SUCCESS = 'GET_FOLLOWING_PEOPLE_SUCCESS';
export const GET_FOLLOWING_PEOPLE_FAILURE = 'GET_FOLLOWING_PEOPLE_FAILURE';

export const GET_FOLLOWING_ORGANIZATIONS = 'GET_FOLLOWING_ORGANIZATIONS';
export const GET_FOLLOWING_ORGANIZATIONS_SUCCESS = 'GET_FOLLOWING_ORGANIZATIONS_SUCCESS';
export const GET_FOLLOWING_ORGANIZATIONS_FAILURE = 'GET_FOLLOWING_ORGANIZATIONS_FAILURE';

export const GET_FOLLOWING_DATA = 'GET_FOLLOWING_DATA';
export const GET_FOLLOWING_DATA_SUCCESS = 'GET_FOLLOWING_DATA_SUCCESS';
export const GET_FOLLOWING_DATA_FAILURE = 'GET_FOLLOWING_DATA_FAILURE';

export const FOLLOW_PERSON = 'FOLLOW_PERSON';
export const FOLLOW_PERSON_SUCCESS = 'FOLLOW_PERSON_SUCCESS';
export const FOLLOW_PERSON_FAILURE = 'FOLLOW_PERSON_FAILURE';

export const UNFOLLOW_PERSON = 'UNFOLLOW_PERSON';
export const UNFOLLOW_PERSON_SUCCESS = 'UNFOLLOW_PERSON_SUCCESS';
export const UNFOLLOW_PERSON_FAILURE = 'UNFOLLOW_PERSON_FAILURE';

export const FOLLOW_ORGANIZATION = 'FOLLOW_ORGANIZATION';
export const FOLLOW_ORGANIZATION_SUCCESS = 'FOLLOW_ORGANIZATION_SUCCESS';
export const FOLLOW_ORGANIZATION_FAILURE = 'FOLLOW_ORGANIZATION_FAILURE';

export const UNFOLLOW_ORGANIZATION = 'UNFOLLOW_ORGANIZATION';
export const UNFOLLOW_ORGANIZATION_SUCCESS = 'UNFOLLOW_ORGANIZATION_SUCCESS';
export const UNFOLLOW_ORGANIZATION_FAILURE = 'UNFOLLOW_ORGANIZATION_FAILURE';

export const REMOVE_PERSON = 'REMOVE_PERSON';
export const REMOVE_PERSON_SUCCESS = 'REMOVE_PERSON_SUCCESS';
export const REMOVE_PERSON_FAILURE = 'REMOVE_PERSON_FAILURE';

export const TOGGLE_PERSON_NOTIFICATION = 'TOGGLE_PERSON_NOTIFICATION';
export const TOGGLE_PERSON_NOTIFICATION_SUCCESS = 'TOGGLE_PERSON_NOTIFICATION_SUCCESS';
export const TOGGLE_PERSON_NOTIFICATION_FAILURE = 'TOGGLE_PERSON_NOTIFICATION_FAILURE';

export const TOGGLE_ORGANIZATION_NOTIFICATION = 'TOGGLE_ORGANIZATION_NOTIFICATION';
export const TOGGLE_ORGANIZATION_NOTIFICATION_SUCCESS = 'TOGGLE_ORGANIZATION_NOTIFICATION_SUCCESS';
export const TOGGLE_ORGANIZATION_NOTIFICATION_FAILURE = 'TOGGLE_ORGANIZATION_NOTIFICATION_FAILURE';

export const getFollowedPeopleData = createApiAction(GET_FOLLOWED_PEOPLE);
export const getFollowingPeopleData = createApiAction(GET_FOLLOWING_PEOPLE);
export const getFollowingOrganizationsData = createApiAction(GET_FOLLOWING_ORGANIZATIONS);
export const getFollowingData = createApiAction(GET_FOLLOWING_DATA);
export const followPerson = createApiAction(FOLLOW_PERSON);
export const unfollowPerson = createApiAction(UNFOLLOW_PERSON);
export const followOrganzation = createApiAction(FOLLOW_ORGANIZATION);
export const unfollowOrganzation = createApiAction(UNFOLLOW_ORGANIZATION);
export const removePerson = createApiAction(REMOVE_PERSON);
export const togglePersonNotification = createApiAction(TOGGLE_PERSON_NOTIFICATION);
export const toggleOrganizationNotification = createApiAction(TOGGLE_ORGANIZATION_NOTIFICATION);

enum ORGANIZATIONS_GRAPH_NAMES {
    TOTAL_TREASURY = 'Total treasury value',
    ORGS_THRESHOLD_AUM = 'Orgs. over threshold AUM',
    POTENTIAL_VOTERS = 'Potential voters',
    ACTIVE_MEMBERS = 'Active members',
    ORGS_THRESHOLD_TOKEN_HOLDERS = 'Orgs over threshold - active members',
}

enum GRAPH_TYPES {
    BAR_GRAPH = 'BAR_GRAPH',
    LINE_GRAPH = 'LINE_GRAPH',
}

export { ORGANIZATIONS_GRAPH_NAMES, GRAPH_TYPES };

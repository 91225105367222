import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { MemberAddressesList } from './components';
import { ICoalitionItemsProps, ICoalitionMemberData } from 'types/interfaces';

export const CoalitionsItems: FC<ICoalitionItemsProps> = ({
    members,
    rank,
    isHovered,
    handleMouseEnter,
    handleMouseLeave,
}: ICoalitionItemsProps): ReactElement => {
    return (
        <div
            className={classNames(styles.tableRow, { [styles.rowHover]: isHovered })}
            onMouseEnter={() => handleMouseEnter(rank - 1)}
            onMouseLeave={handleMouseLeave}
        >
            <div className={styles.coalitions}>
                {members?.map((member: ICoalitionMemberData) => (
                    <div key={member.address} className={styles.coalitionItem}>
                        <MemberAddressesList
                            address={member.address}
                            name={member.name || member.address}
                            avatar={member.avatar}
                            isHovered={isHovered}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

import React, { FC, ReactElement, useState } from 'react';

import { Button } from '@material-ui/core';

import styles from './styles.module.scss';

import { TooltipWrapper } from 'components';

import { IUserLabel } from 'types/interfaces';

const MAX_VISIBLE_LABELS = 8;

interface ILabelsSectionProps {
    userLabels: IUserLabel[];
}

export const LabelsSection: FC<ILabelsSectionProps> = ({ userLabels }: ILabelsSectionProps): ReactElement => {
    const [allLabelsVisible, setAllLabelsVisible] = useState(false);

    const labelsData = allLabelsVisible ? userLabels : userLabels.slice(0, MAX_VISIBLE_LABELS);

    return (
        <div className={styles.labelsWrapper}>
            <div className={styles.labelsContainer}>
                {labelsData.map((label: IUserLabel) => (
                    <TooltipWrapper key={label.id} title={label.name}>
                        <div className={styles.labelWrapper}>
                            <span className={styles.labelName}>{label.name}</span>
                        </div>
                    </TooltipWrapper>
                ))}
            </div>

            {userLabels.length > MAX_VISIBLE_LABELS && !allLabelsVisible && (
                <Button className={styles.showMoreLabelsButton} disableRipple onClick={() => setAllLabelsVisible(true)}>
                    + {userLabels.length - MAX_VISIBLE_LABELS} more
                </Button>
            )}
        </div>
    );
};

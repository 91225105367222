import React, { FC, ReactElement } from 'react';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { TooltipWrapper } from 'components';

import { IGraphCardHeaderProps } from 'types/interfaces';

export const GraphCardHeader: FC<IGraphCardHeaderProps> = ({
    title,
    titleComplement,
    className,
    titleFontIncreased = false,
    infoIconText,
}: IGraphCardHeaderProps): ReactElement => (
    <div className={classNames(styles.cardHeader, className)}>
        <h3
            className={classNames(styles.title, {
                [styles.titleFontIncreased]: titleFontIncreased,
            })}
        >
            {title}
            <span className={styles.titleComplement}>{titleComplement}</span>
        </h3>
        {infoIconText && (
            <TooltipWrapper title={infoIconText}>
                <InfoOutlinedIcon className={styles.infoIcon} />
            </TooltipWrapper>
        )}
    </div>
);

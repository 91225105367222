enum ORGANIZATIONS_PARAMS {
    ORGANIZATION_PARAM = 'daoName',
    TREASURY_PARAM = 'totalValueUSD',
    MEMBERS_PARAM = 'activeMembers',
    PROPOSALS_PARAM = 'totalNumProposals',
    VOTERS_PARAM = 'totalNumMembers',
    VOTES_PARAM = 'totalNumVotes',
    CHANGES_PER_DAY = 'change24hPercentage',
    CHANGES_PER_WEEK = 'change1WeekPercentage',
    MAIN_CHAIN_TITLE = 'mainTreasuryTitle',
}

export { ORGANIZATIONS_PARAMS };

import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { EditUserDetailsModal, Loader, UserPageBody, UserPageHeader } from 'components';

import { useAppDispatch, useAppSelector } from 'store';
import { UserActions } from 'store/actions';

import { mixpanelService } from 'services';
import { getDeepDAORefreshTokenFromStorage, getPremiumUserRefreshTokenFromStorage } from 'web3/storageHelper';

export const UserPage: FC = (): ReactElement => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [uploadedImageSrc, setUploadedImageSrc] = useState<string>('');
    const { id } = useParams<{ id: string }>();

    const dispatch = useAppDispatch();

    const {
        loading,
        userData: { address: userId },
    } = useAppSelector(({ user }) => user);

    const { token, address } = useAppSelector(({ auth }) => auth);

    const deepDaoRefreshToken = getDeepDAORefreshTokenFromStorage();
    const premiumUserRefreshToken = getPremiumUserRefreshTokenFromStorage();

    const isAuthenticatedUser = useMemo(() => {
        if (address && userId) {
            return Boolean(token && address.toLowerCase() === userId.toLowerCase());
        }
        return false;
    }, [token, userId]);

    const isGuestUserPage = useMemo(() => {
        if ((address && userId) || !Boolean(token)) {
            return Boolean(address?.toLowerCase() !== userId?.toLowerCase());
        }
        return false;
    }, [token, userId]);

    const handleEditProfileClick = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        dispatch(UserActions.getUserData.request({ userId: id }));
        dispatch(UserActions.getUserVotesData.request({ userId: id }));
        dispatch(UserActions.getUserProposalsData.request({ userId: id }));
        dispatch(UserActions.getUserTokenListData.request({ userId: id }));
        dispatch(UserActions.getUserLabelsData.request({ userId: id }));
    }, [dispatch]);

    useEffect(() => {
        document.body.style.overflow = isModalOpen ? 'hidden' : 'unset';
    }, [isModalOpen]);

    useEffect(
        () => () => {
            dispatch({ type: UserActions.CLEAR_USER_DATA });
        },
        [dispatch],
    );

    useEffect(() => {
        mixpanelService.track(mixpanelService.eventsGenerator.profileEvent());
        mixpanelService.track(mixpanelService.eventsGenerator.profileSelectedEvent(id));
    }, []);

    useEffect(() => {
        const isLogged = Boolean(token);

        dispatch(UserActions.getUserIsFollowingData.request({ address: id, isLogged }));
    }, [deepDaoRefreshToken]);

    useEffect(() => {
        dispatch(UserActions.getUserFriendsData.request({ userId: id }));
        dispatch(UserActions.getUserEnemiesData.request({ userId: id }));
    }, [deepDaoRefreshToken, premiumUserRefreshToken]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <UserPageHeader
                        handleEditProfileClick={handleEditProfileClick}
                        isAuthenticatedUser={isAuthenticatedUser}
                        uploadedImageSrc={uploadedImageSrc}
                        setUploadedImageSrc={setUploadedImageSrc}
                        isGuestUserPage={isGuestUserPage}
                        isLoggedUser={Boolean(token)}
                    />

                    <UserPageBody isAuthenticatedUser={isAuthenticatedUser} address={userId} />

                    {isModalOpen && (
                        <EditUserDetailsModal toggleModalOpen={closeModal} setUploadedImageSrc={setUploadedImageSrc} />
                    )}
                </>
            )}
        </>
    );
};

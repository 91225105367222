enum PAGES_TYPES {
    ORGANIZATION = 'organization',
    USER = 'user',
}

enum PROTECTED_PAGES_TYPES {
    DEEP_DAO_TOKEN = 'DEEP_DAO_TOKEN',
    PREMIUM_USER_TOKEN = 'PREMIUM_USER_TOKEN',
}

export { PAGES_TYPES, PROTECTED_PAGES_TYPES };

import { call, takeLatest, put, all } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { AlertActions, FeedbackActions } from 'store/actions';
import { FeedbackApi } from 'store/apis';

import { ALERT_TYPES, SUCCESS_MESSAGES } from 'constants/alert';

import { ISendFeedbackFormAction } from 'types/interfaces/feedback.interfaces';

function* sendFeedbackFormWorker({ payload }: ISendFeedbackFormAction) {
    try {
        yield call(FeedbackApi.sendFeedbackForm, payload);

        yield put({
            type: AlertActions.SHOW_ALERT,
            payload: {
                title: SUCCESS_MESSAGES.FEEDBACK_SUCCESS,
                type: ALERT_TYPES.SUCCESS,
            },
        });
        yield put(FeedbackActions.sendFeedbackForm.success({ success: true }));
    } catch (e) {
        yield put(FeedbackActions.sendFeedbackForm.failure(e));
    }
}

export const feedbackSaga = function* (): SagaIterator {
    yield all([takeLatest(FeedbackActions.SEND_FEEDBACK_FORM, sendFeedbackFormWorker)]);
};

import React, { FC, ReactElement, useState, useCallback, useEffect } from 'react';

import styles from './styles.module.scss';

import { DiscussionTooltip } from 'components';
import { VotingButton } from 'components/DiscussionCardItems/components/VotingButton';

import { VOTING_OPTIONS } from 'constants/votingConstants';
import { DISCUSSIONS_NOTIFICATIONS } from 'constants/discussionsElements';

import { greenDolphinIconFor, greyDolphinIconFor, greyDolphinIconAgainst, purpleDolphinIconAgainst } from 'assets';
import { useAppDispatch, useAppSelector } from 'store';
import { OrganizationActions } from 'store/actions';

import { ICommentsVotingSectionProps } from 'types/interfaces';

export const CommentsVotingSection: FC<ICommentsVotingSectionProps> = ({
    voted,
    isAuthenticatedUser,
    discussionId,
    commentId,
    isDiscussionSingle,
    loggedUserAddress,
    organizationId,
}: ICommentsVotingSectionProps): ReactElement => {
    const dispatch = useAppDispatch();
    const [notificationOpen, setNotificationOpen] = useState<boolean>(false);

    const { commentsIdsWithVoteError } = useAppSelector(({ organization }) => organization);

    const createCommentVote = useCallback(
        (choice: VOTING_OPTIONS) => {
            if (isAuthenticatedUser) {
                dispatch(
                    OrganizationActions.createDiscussionCommentVote.request({
                        discussionId,
                        organizationId,
                        isDiscussionSingle,
                        commentId,
                        data: {
                            voter: loggedUserAddress,
                            choice,
                        },
                    }),
                );
            } else {
                setNotificationOpen(true);
            }
        },
        [loggedUserAddress, notificationOpen, isAuthenticatedUser, isDiscussionSingle, organizationId],
    );

    useEffect(() => {
        if (commentsIdsWithVoteError.includes(commentId) && !notificationOpen) setNotificationOpen(true);
    }, [commentsIdsWithVoteError]);

    const clearNotificationError = useCallback(() => {
        setNotificationOpen(false);
        dispatch({
            type: OrganizationActions.CLEAR_DISCUSSION_TOKENLESS_ERROR,
            payload: {
                commentsIdsWithVoteError: commentsIdsWithVoteError.filter((itemId: string) => itemId !== commentId),
            },
        });
    }, [notificationOpen, commentsIdsWithVoteError]);

    return (
        <div className={styles.buttonsWrapper}>
            {notificationOpen && (
                <DiscussionTooltip
                    onClose={clearNotificationError}
                    className={styles.notificationTooltip}
                    text={DISCUSSIONS_NOTIFICATIONS.VOTE}
                />
            )}

            <VotingButton
                onClick={() => createCommentVote(VOTING_OPTIONS.FOR)}
                votingOption={VOTING_OPTIONS.FOR}
                defaultImage={greyDolphinIconFor}
                hoverImage={greenDolphinIconFor}
                voted={voted}
                forComment
            />

            <VotingButton
                onClick={() => createCommentVote(VOTING_OPTIONS.AGAINST)}
                votingOption={VOTING_OPTIONS.AGAINST}
                defaultImage={greyDolphinIconAgainst}
                hoverImage={purpleDolphinIconAgainst}
                voted={voted}
                forComment
            />
        </div>
    );
};

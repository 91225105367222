import { FEED_TYPES } from 'constants/feedTypes';

import { ISelectOptions } from 'types/interfaces';

export const PROPOSAL_TYPE = 'PROPOSAL';
export const DISCUSSIONS_TYPE = 'DISCOURSE';

const selectOptions: ISelectOptions[] = [
    {
        id: 1,
        name: 'show all',
        value: '',
    },
    {
        id: 2,
        name: 'proposals',
        value: PROPOSAL_TYPE,
    },
    {
        id: 3,
        name: 'discussions',
        value: DISCUSSIONS_TYPE,
    },
];

const DEFAULT_PAGE = 0;
const DEFAULT_OFFSET = 0;
const DEFAULT_LIMIT = 20;

const FEED_CARD_TYPES: { [key: string]: FEED_TYPES } = {
    [PROPOSAL_TYPE]: FEED_TYPES.PROPOSAL,
    [DISCUSSIONS_TYPE]: FEED_TYPES.DISCUSSION,
};

const DISCOURCE_BY_TYPE = 'discourse by';
const SNAPSHOT_BY_PROPOSAL = 'snapshot by';
const PROPOSAL_BY_TYPE = 'proposal by';

export {
    selectOptions,
    DEFAULT_PAGE,
    DEFAULT_OFFSET,
    DEFAULT_LIMIT,
    FEED_CARD_TYPES,
    DISCOURCE_BY_TYPE,
    SNAPSHOT_BY_PROPOSAL,
    PROPOSAL_BY_TYPE,
};

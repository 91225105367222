import React, { FC, ReactElement, useState, useRef } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { useOutsideClick } from 'hooks';

import { ISelectProps, ISelectOptions } from 'types/interfaces';

export const Select: FC<ISelectProps> = ({
    options,
    selectedOption,
    onSelect,
    className,
    placeholder,
}: ISelectProps): ReactElement => {
    const [toggleMenu, setToggleMenu] = useState<boolean>(false);
    const ref = useRef(null);

    useOutsideClick(ref, () => setToggleMenu(false));

    const toggleMenuChange = (option: ISelectOptions) => {
        setToggleMenu(!toggleMenu);
        onSelect(option);
    };

    return (
        <div className={classNames(styles.selectWrapper, className)} ref={ref}>
            <div
                className={classNames(styles.select, { [styles.selectActive]: toggleMenu })}
                onClick={() => setToggleMenu(!toggleMenu)}
            >
                <span className={styles.selectTitle}>{selectedOption?.name || placeholder}</span>
                <KeyboardArrowDownIcon className={styles.selectIcon} />
            </div>
            <div className={classNames(styles.selectItems, { [styles.fadeIn]: toggleMenu })}>
                {options
                    ?.filter((item: ISelectOptions) => item.name !== selectedOption?.name)
                    .map((option: ISelectOptions) => (
                        <div key={option.id} className={styles.menuItem} onClick={() => toggleMenuChange(option)}>
                            {option.name}
                        </div>
                    ))}
            </div>
        </div>
    );
};

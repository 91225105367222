import React, { FC, ReactElement, ReactNode, memo, CSSProperties } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface ITableCellProps {
    children: ReactNode;
    isHighlighted?: boolean;
    className?: string;
    style?: CSSProperties;
}

export const TableCell: FC<ITableCellProps> = memo(
    ({ children, isHighlighted, className, style }: ITableCellProps): ReactElement => {
        return (
            <div className={classNames(styles.tableCellWrapper, className)} style={style}>
                <div className={classNames({ [styles.highlightedCell]: isHighlighted })}>{children}</div>
            </div>
        );
    },
);

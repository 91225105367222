import { Reducer } from '@reduxjs/toolkit';

import { ResourcesActions } from 'store/actions';

import type {
    IResourcesState,
    IResourcesActionsType,
    IGetAllResourcesPayload,
    IGetSortedResourcesPayload,
} from 'types/interfaces';

const defaultState: IResourcesState = {
    loading: false,
    resourcesData: [],
    resourcesCategoriesData: [],
};

export const resourcesReducer: Reducer<IResourcesState, IResourcesActionsType> = (
    state: IResourcesState = defaultState,
    action: IResourcesActionsType,
): IResourcesState => {
    const { type, payload } = <IResourcesActionsType>action;

    switch (type) {
        case ResourcesActions.GET_ALL_RESOURCES_DATA: {
            return {
                ...state,
                loading: true,
            };
        }

        case ResourcesActions.GET_ALL_RESOURCES_DATA_SUCCESS: {
            const { resourcesData, resourcesCategoriesData } = payload as IGetAllResourcesPayload;

            return {
                ...state,
                resourcesData,
                resourcesCategoriesData,
                loading: false,
            };
        }

        case ResourcesActions.GET_ALL_RESOURCES_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case ResourcesActions.GET_SORTED_RESOURCES_DATA: {
            return {
                ...state,
                resourcesData: [],
                loading: true,
            };
        }

        case ResourcesActions.GET_SORTED_RESOURCES_DATA_SUCCESS: {
            const { resourcesData } = payload as IGetSortedResourcesPayload;

            return {
                ...state,
                resourcesData: resourcesData,
                loading: false,
            };
        }

        case ResourcesActions.GET_SORTED_RESOURCES_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case ResourcesActions.CLEAR_RESOURCES_DATA: {
            return {
                ...state,
                loading: false,
                resourcesData: [],
                resourcesCategoriesData: [],
            };
        }

        default: {
            return state;
        }
    }
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { SearchContainer } from 'components';

import { HeaderButtons } from 'components/HeaderButtons';

export const HeaderDesktop: FC = (): ReactElement => (
    <header className={styles.wrapper}>
        <div className={styles.header}>
            <div className={styles.headerMainItems}>
                <SearchContainer />
                <HeaderButtons />
            </div>
        </div>
    </header>
);

import React, { FC, ReactElement, Fragment, useContext, ChangeEvent } from 'react';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import styles from './styles.module.scss';

import { SelfListingTitle, SelfListingSelect, SelfListingInput } from 'components';

import { useAppSelector } from 'store';
import { SelfListingContext } from 'context/selfListingContext';

import { ISelfListingFormGovernance, ISelfListingElement } from 'types/interfaces';

export const GovernanceInfo: FC = (): ReactElement => {
    const { selfListingPlatformsList } = useAppSelector(({ selfListing }) => selfListing);

    const { formik } = useContext(SelfListingContext);

    const onGovernanceSelect = (value: ISelfListingElement, index: number) => {
        formik.setFieldValue(`governance[${index}]`, {
            ...formik.values.governance[index],
            platformId: value.id,
            title: value.title,
        });
    };

    const addGovernanceFieds = () => {
        formik.setFieldValue('governance', [
            ...formik.values.governance,
            {
                platformId: null,
                title: '',
                address: '',
            },
        ]);
    };

    const onGovernanceRemove = (index: number) => {
        const governanceData = formik.values.governance.map((items: ISelfListingFormGovernance, id: number) =>
            index === id ? { ...items, platformId: null, title: '' } : items,
        );

        formik.setFieldValue('governance', governanceData);
    };

    return (
        <div className={styles.governanceInfoWrapper}>
            <SelfListingTitle title="Governance" />

            <div className={styles.fieldsWrapper}>
                {formik.values.governance.map((_: ISelfListingFormGovernance, index: number) => (
                    <Fragment key={index}>
                        <SelfListingSelect
                            data={selfListingPlatformsList}
                            placeholderTitle="-Select-"
                            selectedElementTitle={formik.values.governance[index].title}
                            onSelect={(value: ISelfListingElement) => onGovernanceSelect(value, index)}
                            error={formik.errors?.governance?.[index]?.title}
                            onRemove={() => onGovernanceRemove(index)}
                        />

                        <SelfListingInput
                            placeholder="Organization ID/url entry"
                            name={`governance[${index}].address`}
                            value={formik.values.governance[index].address}
                            touched={true}
                            error={formik.errors.governance?.[index]?.address}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                formik.setFieldValue(`governance[${index}].address`, event.target.value)
                            }
                            onTouch={formik.setFieldTouched}
                        />
                    </Fragment>
                ))}
            </div>

            <Button className={styles.addSocialsButton} onClick={addGovernanceFieds}>
                <AddIcon className={styles.addIcon} />
                <span className={styles.title}>add</span>
            </Button>
        </div>
    );
};

import React, { ReactElement, ReactNode } from 'react';

import { Link } from 'react-router-dom';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { HrefLink, TableCell } from 'components/index';

import { FormatUtils } from 'utils';

import { COMMON_TABLE_FIELD_TYPES, ICommonTableHeader, IRowCellLinkItems } from 'components/CommonTable/types';
import { generateUniqId } from 'helpers';
import { DATA_NOT_FOUND } from '../../../../../../../../constants';

interface ITableRowProps<T, K> {
    rowItems: T;
    tableHeaderData: ICommonTableHeader<T, K>[];
    columnSortedParamName?: K;
}

export const TableRow = <T, K>({
    rowItems,
    tableHeaderData,
    columnSortedParamName,
}: ITableRowProps<T, K>): ReactElement => (
    <div className={styles.tableRow}>
        {tableHeaderData.map((headerItems: ICommonTableHeader<T, K>) => {
            const { rowFieldKey, fieldType, sortedParamName } = headerItems;
            const uniqKey = generateUniqId();

            const rowCellData = rowItems[rowFieldKey];

            const fieldValue =
                typeof rowCellData === 'number'
                    ? FormatUtils.formatNumberWithCommas(rowCellData, 0)
                    : typeof rowCellData === 'string'
                        ? rowCellData
                        : DATA_NOT_FOUND;

            const renderRowFieldByType = (): ReactNode => {
                switch (fieldType) {
                    case COMMON_TABLE_FIELD_TYPES.COMMON_TEXT:
                    case COMMON_TABLE_FIELD_TYPES.BOLD_TEXT:
                        return (
                            <span
                                className={classNames(styles.rowCellText, {
                                    [styles.rowCellBoldText]: fieldType === COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
                                })}
                            >
                                {fieldValue}
                            </span>
                        );

                    case COMMON_TABLE_FIELD_TYPES.EXTERNAL_LINK:
                        const externalLinkData = rowCellData as unknown as IRowCellLinkItems;

                        return (
                            <HrefLink
                                className={classNames(styles.rowCellTextOverflow, styles.linkWrapper)}
                                href={externalLinkData.link}
                            >
                                <span className={styles.rowCellText}>{externalLinkData.title}</span>
                            </HrefLink>
                        );
                    case COMMON_TABLE_FIELD_TYPES.INNER_LINK:
                        const innerLinkData = rowCellData as unknown as IRowCellLinkItems;

                        return (
                            <Link
                                className={classNames(styles.rowCellTextOverflow, styles.linkWrapper)}
                                to={innerLinkData.link}
                            >
                                <span className={styles.rowCellText}>{innerLinkData.title}</span>
                            </Link>
                        );

                    default:
                        return (
                            <div className={styles.rowCellContainer}>
                                <span className={styles.rowCellText}>{fieldValue}</span>
                            </div>
                        );
                }
            };

            return sortedParamName ? (
                <TableCell
                    className={styles.rowCellWrapper}
                    key={uniqKey}
                    style={{ width: headerItems.title === '#' ? `5%` : headerItems.settings.width.desktop }}
                    isHighlighted={sortedParamName === columnSortedParamName}
                >
                    {renderRowFieldByType()}
                </TableCell>
            ) : (
                <div
                    style={{ width: headerItems.title === '#' ? `5%` : headerItems.settings.width.desktop }}
                    key={uniqKey}
                    className={styles.rowCellWrapper}
                >
                    {renderRowFieldByType()}
                </div>
            );
        })}
    </div>
);

import React, { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';

import { Button } from '@material-ui/core';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { TagItem } from 'components';

import { isPersonalInfoEmpty } from './utils';

import { useAppSelector, useAppDispatch } from 'store';
import { UserActions } from 'store/actions';

import { IUserAboutProps } from 'types/interfaces';

export const UserAbout: FC<IUserAboutProps> = ({ isAuthenticatedUser }: IUserAboutProps): ReactElement => {
    const {
        userData: { personalInfo },
        userAboutSectionOpen,
    } = useAppSelector(({ user }) => user);

    const dispatch = useAppDispatch();

    const { people_contact: peopleContact, skills: skills = [], interests: interests = [] } = personalInfo || {};

    const isEmpty = useMemo(() => isPersonalInfoEmpty(personalInfo), [personalInfo]);

    const handleAboutSectionOpen = useCallback(
        () =>
            dispatch({
                type: UserActions.TOGGLE_ABOUT_SECTION_OPEN,
                payload: { userAboutSectionOpen: !userAboutSectionOpen },
            }),
        [userAboutSectionOpen],
    );

    useEffect(() => {
        if (!isEmpty && !userAboutSectionOpen) {
            dispatch({
                type: UserActions.TOGGLE_ABOUT_SECTION_OPEN,
                payload: { userAboutSectionOpen: true },
            });
        } else if (isEmpty && userAboutSectionOpen) {
            dispatch({
                type: UserActions.TOGGLE_ABOUT_SECTION_OPEN,
                payload: { userAboutSectionOpen: false },
            });
        }
    }, [isEmpty]);

    return (
        <div className={styles.userAboutContainer}>
            <div className={styles.userAboutBody}>
                <div className={styles.headingSection}>
                    {!isEmpty && (
                        <Button
                            className={classNames(styles.showMoreButton, {
                                [styles.showMoreButtonOpen]: userAboutSectionOpen,
                            })}
                            onClick={handleAboutSectionOpen}
                        >
                            <ArrowDropUp className={styles.dropIcon} />
                        </Button>
                    )}
                    <p className={styles.title}>About</p>
                </div>
                {userAboutSectionOpen && !isEmpty && (
                    <div className={styles.moreInfo}>
                        {peopleContact?.about && <div className={styles.moreInfoText}>{peopleContact?.about}</div>}

                        {skills && skills.length > 0 && (
                            <div
                                className={classNames(styles.moreInfoBlock, styles.skills, {
                                    [styles.loggedUserOptions]: !isAuthenticatedUser && !peopleContact?.about,
                                })}
                            >
                                <div className={styles.moreInfoTitle}>Skills</div>
                                <div className={styles.skillsList}>
                                    {skills.map((skill) => (
                                        <TagItem key={skill.name} {...skill} />
                                    ))}
                                </div>
                            </div>
                        )}

                        {interests && interests.length > 0 && (
                            <div className={`${styles.moreInfoBlock} ${styles.skills}`}>
                                <div className={styles.moreInfoTitle}>Interests</div>
                                <div className={styles.skillsList}>
                                    {interests.map((interest) => (
                                        <TagItem key={interest.name} {...interest} />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {isEmpty && (
                    <div className={styles.moreInfo}>
                        <div className={styles.moreInfoText}>Enrich your profile</div>
                    </div>
                )}
            </div>
        </div>
    );
};

import React, { FC, ReactElement, useEffect } from 'react';

import { Link } from 'react-router-dom';

import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import styles from './styles.module.scss';

import { AccountInvoicesTable } from 'components';

import { useAppDispatch, useAppSelector } from 'store';
import { PremiumSubscriptionActions } from 'store/actions';

export const AccountInvoicesPage: FC = (): ReactElement => {
    const { premiumUserInvoices, premiumUserInvoicesLoading } = useAppSelector(
        ({ premiumSubscription }) => premiumSubscription,
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(PremiumSubscriptionActions.getPremiumUserInvoices.request());
    }, [dispatch]);

    useEffect(
        () => () => {
            dispatch({ type: PremiumSubscriptionActions.CLEAR_PREMIUM_INVOICES });
        },
        [dispatch],
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <Link to="/premium_account_settings" className={styles.accountSettingsLink}>
                    <ArrowBackIos className={styles.arrowIcon} />
                    <span className={styles.text}>Settings</span>
                </Link>
                <h1 className={styles.title}>Invoices</h1>

                <AccountInvoicesTable premiumUserInvoices={premiumUserInvoices} loading={premiumUserInvoicesLoading} />
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { OmitPropsId, IHashtagData, ITagData } from 'types/interfaces';

export const TagItem: FC<OmitPropsId<IHashtagData>> = ({ name }: OmitPropsId<ITagData>): ReactElement => (
    <div className={styles.tagContent}>
        <span>{name}</span>
    </div>
);

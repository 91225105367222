import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

import { HrefLink, InfoCircle, Loader, TableDataNotFound } from 'components';
import { TableHeader, TableRow } from './components';

import { FormatUtils } from 'utils';
import { IVotingToken } from '../../../../../../../../types/interfaces';
import { TOKEN_TAB, TOKEN_TAB_DESCRIPTIONS } from '../../../../../../../../constants';

interface ITrendsTableProps {
    organizationVotingToken: IVotingToken | null;
    lastUpdate: Date | null;
    loading: boolean;
}

export const VotingTokenTable = ({ organizationVotingToken, lastUpdate, loading }: ITrendsTableProps): ReactElement => {
    return (
        <div className={styles.content}>
            <div className={styles.headSection}>
                <div className={styles.textSection}>
                    <h4>
                        {`Voting Token: `}
                        <HrefLink href={organizationVotingToken?.token.explorerLink} className={styles.titleLink}>
                            <>
                                <span className={styles.linkText}>
                                    {organizationVotingToken?.token.name}
                                    {organizationVotingToken?.token.name ? ', ' : ''}{' '}
                                </span>
                            </>
                        </HrefLink>
                        <HrefLink href={organizationVotingToken?.token.explorerLink} className={styles.titleLink}>
                            <>
                                <span className={styles.linkText}>{organizationVotingToken?.token.symbol}</span>
                            </>
                        </HrefLink>
                    </h4>
                    <InfoCircle
                        tooltipText={TOKEN_TAB_DESCRIPTIONS[TOKEN_TAB.VOTING_TOKEN]}
                        className={styles.infoIcon}
                    />
                    {lastUpdate && (
                        <h5>Last Update: {FormatUtils.getFormattedDate(lastUpdate as unknown as string)}</h5>
                    )}
                </div>
            </div>

            <div className={styles.tableWrapper}>
                <div className={styles.tableContainer}>
                    {organizationVotingToken && (
                        <div>
                            <TableHeader />
                            <TableRow timePeriod={'Current'} tokenMetrics={organizationVotingToken.metrics.current} />
                        </div>
                    )}

                    {loading && !organizationVotingToken?.metrics && <Loader size={40} className={styles.loaderBox} />}
                    {!organizationVotingToken?.metrics && !loading && (
                        <TableDataNotFound forTable overflowMarginsSecondVariant />
                    )}
                </div>
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import { FollowingPageBody } from 'components';

import styles from './styles.module.scss';

export const FollowingPage: FC = (): ReactElement => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.blurBox} />
            <div className={styles.container}>
                <FollowingPageBody />
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ILoaderProps } from 'types/interfaces';

const commonLoaderSize = 60;

export const Loader: FC<ILoaderProps> = ({ size = commonLoaderSize, className }: ILoaderProps): ReactElement => (
    <div className={classNames(styles.loader, className)}>
        <div className={styles.spinner} style={{ width: `${size}px`, height: `${size}px` }}></div>
    </div>
);

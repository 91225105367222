import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { HeaderItemWithSorting } from 'components';

import { INDICES_PARAMS } from '../../constants';

import { ITableHeaderWithSortingProps } from 'types/interfaces';

export const TableHeader: FC<ITableHeaderWithSortingProps> = ({
    sortedParamName,
    sortByParam,
}: ITableHeaderWithSortingProps): ReactElement => (
    <div className={styles.tableHeaderWrapper}>
        <h3 className={styles.indicesOwner}>Banzhaf Indices</h3>
        <div className={styles.tableHeader}>
            <div className={styles.headerVoter}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="voter"
                    sortedParamName={sortedParamName}
                    paramName={INDICES_PARAMS.VOTER_PARAM}
                />
            </div>
            <div className={styles.headerShares}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="reputation/shares"
                    sortedParamName={sortedParamName}
                    paramName={INDICES_PARAMS.REPUTATION_SHARES_PARAM}
                />
            </div>
            <div className={styles.headerIndex}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="Banzhaf Index"
                    sortedParamName={sortedParamName}
                    paramName={INDICES_PARAMS.BANZHAF_INDEX_PARAM}
                />
            </div>
        </div>
    </div>
);

import React, { FC, ReactElement, useEffect, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';

import styles from './styles.module.scss';

import { TableDataNotFound, TableTabs, SearchField, Loader } from 'components';
import { TableHeader, DecisionRowItems } from './components';

import { DECISIONS_PARAMS } from './constants';

import { useAppSelector, useAppDispatch } from 'store';
import { OrganizationActions } from 'store/actions';
import { useTabSelector, useOrganizationDaos } from 'hooks';
import { tableDataSort } from 'helpers';
import { decisionsTableSearch, createDecisonsTableData } from './utils';

import { ISortedParam, ITabItems, IOrganizationDecisionTableData } from 'types/interfaces';
import { getDeepDAORefreshTokenFromStorage, getPremiumUserRefreshTokenFromStorage } from 'web3/storageHelper';

export const DecisionsTable: FC = (): ReactElement => {
    const { organizationDaos, decisionsDataLoading, organizationDecisionsData } = useAppSelector(
        ({ organization }) => organization,
    );
    const dispatch = useAppDispatch();
    const isTablet = useMediaQuery('(max-width: 767.98px)');
    const { organizationHaveDaos, createTabsList, createDaosList, organizationFirstDaoId } = useOrganizationDaos();

    const { tabSelected, handleTabChange } = useTabSelector({} as ITabItems);
    const [tabSearchValue, setTabSearchValue] = useState<string>('');
    const [sortedParam, setSortedParam] = useState<ISortedParam>({
        paramName: DECISIONS_PARAMS.DATE_PARAM,
        descending: true,
    });

    const deepDaoRefreshToken = getDeepDAORefreshTokenFromStorage();
    const premiumUserRefreshToken = getPremiumUserRefreshTokenFromStorage();

    useEffect(() => {
        if (organizationHaveDaos) {
            handleTabChange(createDaosList()?.[0] || {});
            dispatch(
                OrganizationActions.getOrganizationDecisionsData.request({ daoId: organizationFirstDaoId as string }),
            );
        }
    }, [organizationDaos, deepDaoRefreshToken, premiumUserRefreshToken]);

    useEffect(
        () => () => {
            dispatch({ type: OrganizationActions.CLEAR_ORGANIZATION_DECISIONS_DATA });
        },
        [dispatch],
    );

    const sortByParam = (param: string) => {
        setSortedParam({
            paramName: param,
            descending: sortedParam.paramName === param ? !sortedParam.descending : true,
        });
    };

    const onTabChange = (tab: ITabItems) => {
        if (tab.value !== tabSelected.value) {
            handleTabChange(tab);
            setTabSearchValue('');
            setSortedParam({
                paramName: DECISIONS_PARAMS.DATE_PARAM,
                descending: true,
            });
            dispatch(OrganizationActions.getOrganizationDecisionsData.request({ daoId: tab.value }));
        }
    };

    const decisionsSearchData = decisionsTableSearch(
        createDecisonsTableData(tableDataSort([...organizationDecisionsData], sortedParam)),
        tabSearchValue,
    );

    return (
        <div className={styles.tableContentWrapper}>
            <div className={styles.tableContent}>
                <div className={styles.headerOptions}>
                    <TableTabs
                        tableTabs={createTabsList('Decisions')}
                        tabSelected={tabSelected}
                        handleTabChange={onTabChange}
                    >
                        {!isTablet && (
                            <SearchField
                                forTable={true}
                                inputValue={tabSearchValue}
                                setInputValue={setTabSearchValue}
                            />
                        )}
                    </TableTabs>
                </div>

                {decisionsDataLoading && decisionsSearchData.length === 0 && (
                    <div className={styles.loaderBox}>
                        <Loader size={40} className={styles.decisionsLoader} />
                    </div>
                )}

                {!decisionsDataLoading && decisionsSearchData.length === 0 && (
                    <TableDataNotFound forTable overflowMarginsFirstVariant widthAuto />
                )}

                {decisionsSearchData.length > 0 && (
                    <div className={styles.tableWrapper}>
                        <div className={styles.tableContainer}>
                            <TableHeader sortedParamName={sortedParam.paramName} sortByParam={sortByParam} />

                            {decisionsSearchData.map((decisionData: IOrganizationDecisionTableData) => (
                                <DecisionRowItems
                                    key={decisionData.id}
                                    {...decisionData}
                                    sortedParamName={sortedParam.paramName}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

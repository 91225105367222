import { call, takeLatest, put } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { FaqApi } from 'store/apis';
import { FaqActions } from 'store/actions';
import { IGetSingleFaqAction } from 'types/interfaces';

function* getFaqsDataWorker() {
    try {
        const { data } = yield call(FaqApi.getFaqs);
        yield put(
            FaqActions.getAllFaqs.success({
                data,
            }),
        );
    } catch (e) {
        yield put(FaqActions.getAllFaqs.failure(e));
    }
}

function* getSingleFaqDataWorker({ payload }: IGetSingleFaqAction) {
    try {
        const { data } = yield call(FaqApi.getFaqs, payload.id);
        yield put(
            FaqActions.getFaq.success({
                data,
            }),
        );
    } catch (e) {
        yield put(FaqActions.getFaq.failure(e));
    }
}

export const faqsSaga = function* (): SagaIterator {
    yield takeLatest(FaqActions.GET_ALL_FAQS, getFaqsDataWorker);
    yield takeLatest(FaqActions.GET_FAQ, getSingleFaqDataWorker);
};

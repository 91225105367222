import {
    IAssetsTableItems,
    IFundsGraphItems,
    IOrganizations,
    IOrganizationsTokens,
    IActivityStatsItems,
    IVotingStatsItems,
    IOrganizationMembers,
    IVotingCoalition,
    IResearchData,
    IFeedItems,
    IStatsChartChanges,
    IChartItem,
    IUserInfoData,
    ILabelItems,
    IUserPersonalStatsData,
    IMembersPosts,
    IUserTokensData,
    IHashtagData,
    ITopTokensValuesData,
    IUserItems,
    ILabelsSearchItems,
    IUserSearchItems,
    IFollowPeopleData,
    IFollowOrganizationData,
} from 'types/interfaces';

const organizations: IOrganizations[] = [
    {
        rank: 1,
        id: 1,
        organazationName: 'name',
        treasury: 3243243,
        marketCap: 7654321,
        members: 121212,
        proposals: 212121,
        votes: 333333,
        voterParticipation: 80,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
    {
        rank: 1,
        id: 2,
        organazationName: 'name organization #1',
        treasury: 34234,
        marketCap: 7654321,
        members: 121212,
        proposals: 212121,
        votes: 333333,
        voterParticipation: 39,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
    {
        rank: 1,
        id: 3,
        organazationName: 'DAOS Organization name',
        treasury: 3332,
        marketCap: 7654321,
        members: 121212,
        proposals: 212121,
        votes: 333333,
        voterParticipation: 60,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
    {
        rank: 1,
        id: 4,
        organazationName: 'name',
        treasury: 444324342,
        marketCap: 7654321,
        members: 121212,
        proposals: 212121,
        votes: 333333,
        voterParticipation: 100,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
    {
        rank: 1,
        id: 5,
        organazationName: 'name',
        treasury: 199234567,
        marketCap: 7654321,
        members: 121212,
        proposals: 212121,
        votes: 333333,
        voterParticipation: 100,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
    {
        rank: 1,
        id: 6,
        organazationName: 'name',
        treasury: 127,
        marketCap: 7654321,
        members: 121212,
        proposals: 212121,
        votes: 333333,
        voterParticipation: 100,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
    {
        rank: 1,
        id: 7,
        organazationName: 'name',
        treasury: 423434,
        marketCap: 7654321,
        members: 121212,
        proposals: 212121,
        votes: 333333,
        voterParticipation: 100,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
];

const organizationTokens: IOrganizationsTokens[] = [
    {
        id: 1,
        rank: 1,
        tokenName: 'Token name',
        symbol: 'TKN',
        balance: 7654321,
        organizations: 12,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
    {
        id: 2,
        rank: 1,
        tokenName: 'Token name',
        symbol: 'BOND',
        balance: 7654321,
        organizations: 12,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
    {
        id: 3,
        rank: 1,
        tokenName: 'Token name',
        symbol: 'WETH',
        balance: 7654321,
        organizations: 12,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
    {
        id: 4,
        rank: 1,
        tokenName: 'Token name',
        symbol: 'TKN',
        balance: 7654321,
        organizations: 12,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
    {
        id: 5,
        rank: 1,
        tokenName: 'Token name',
        symbol: 'DOUGH',
        balance: 7654321,
        organizations: 12,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
    {
        id: 6,
        rank: 1,
        tokenName: 'Token name',
        symbol: 'Some Symbol',
        balance: 7321,
        organizations: 12,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
    {
        id: 7,
        rank: 1,
        tokenName: 'Token name',
        symbol: 'TKN',
        balance: 721,
        organizations: 12,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
    {
        id: 8,
        rank: 1,
        tokenName: 'Token name',
        symbol: 'TKN',
        balance: 7621,
        organizations: 12,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
    },
];

const organizationFeeds: IFeedItems[] = [
    {
        id: 1,
        userImage: 'https://picsum.photos/id/237/536/354',
        userName: 'Username',
        status: 'active',
        activity: 12,
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
        subTitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
        type: 'proposal',
    },
    {
        id: 2,
        userImage: 'https://picsum.photos/seed/picsum/536/354',
        userName: 'Username',
        status: 'pending',
        activity: 55,
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
        subTitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
        type: 'proposal',
    },
    {
        id: 332,
        userImage: 'https://picsum.photos/id/237/536/354',
        userName: 'Username',
        status: 'done',
        activity: 77,
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
        subTitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
        type: 'disscusions',
    },
    {
        id: 122,
        userImage: 'https://picsum.photos/id/237/536/354',
        userName: 'Username',
        activity: 55,
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing, Lorem ipsum dolor sit amet, consectetur adipiscing',
        subTitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
        type: 'proposal',
    },
    {
        id: 32,
        userImage: 'https://picsum.photos/id/237/536/314',
        userName: 'Username',
        status: 'done',
        activity: 77,
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
        subTitle:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam...',
        type: 'proposal',
    },
];

const shareholdersList = [
    {
        id: 1,
        image: 'https://picsum.photos/id/237/536/354',
        userName: 'Username1',
        shares: 17,
        followers: 32,
    },
    {
        id: 12,
        image: 'https://picsum.photos/536/354',
        userName: 'Username1',
        shares: 20,
        followers: 3,
    },
    {
        id: 13,
        image: 'https://picsum.photos/id/237/536/354',
        userName: 'Username1',
        shares: 33.1,
        followers: 3,
    },
    {
        id: 15,
        image: 'https://picsum.photos/id/237/536/354',
        userName: 'Username1',
        shares: 0,
        followers: 0,
    },
    {
        id: 16,
        image: 'https://picsum.photos/536/354',
        userName: 'Username1',
        shares: 21,
        followers: 31,
    },
    {
        id: 17,
        image: 'https://picsum.photos/id/237/536/354',
        userName: 'Username1',
        shares: 20,
        followers: 3,
    },
    {
        id: 121,
        image: 'https://picsum.photos/seed/picsum/536/354',
        userName: 'Username1',
        shares: 0,
        followers: 3,
    },
    {
        id: 34,
        image: 'https://picsum.photos/id/237/536/354',
        userName: 'Username1',
        shares: 20,
        followers: 43,
    },
    {
        id: 1122,
        image: 'https://picsum.photos/id/237/536/354',
        userName: 'Username1',
        shares: 16,
        followers: 99,
    },
    {
        id: 1412,
        image: 'https://picsum.photos/id/237/536/354',
        userName: 'Username1',
        shares: 3,
        followers: 311,
    },
];

const similarPeople: IUserItems[] = [
    {
        id: 1,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 33,
        members: 22,
    },
    {
        id: 2,
        name: 'BarnBridge MAIN ORGANIZATION',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 11,
        members: 12,
    },
    {
        id: 3,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 1,
        members: 1,
    },
    {
        id: 4,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 11,
        members: 12,
    },
    {
        id: 5,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 11,
        members: 12,
    },
    {
        id: 6,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 9,
        members: 12,
    },
    {
        id: 7,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 11,
        members: 12,
    },
    {
        id: 8,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 11,
        members: 92,
    },
    {
        id: 9,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 6,
        members: 12,
    },
    {
        id: 10,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 0,
        members: 1,
    },
    {
        id: 11,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 11,
        members: 12,
    },
    {
        id: 12,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 11,
        members: 12,
    },
    {
        id: 13,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 11,
        members: 4,
    },
    {
        id: 14,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 1,
        members: 2,
    },
    {
        id: 15,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 11,
        members: 12,
    },
    {
        id: 16,
        name: 'USERNAME',
        logo: 'https://picsum.photos/id/237/536/354',
        tokens: 12,
        members: 33,
    },
];

const treasuryRank = {
    currentRank: 2,
    previousRank: 4,
    rankInfo: 'increased',
};

const totalInRank = {
    currentRank: 2,
    previousRank: 4,
    rankInfo: 'decreased',
};

const totalOutRank = {
    currentRank: 2,
    previousRank: 4,
    rankInfo: 'default',
};

const membersRank = {
    currentRank: 21,
    previousRank: 4,
    rankInfo: 'increased',
};

const proposalsRank = {
    currentRank: 1,
    previousRank: 1,
    rankInfo: 'default',
};

const organizationMembers: IOrganizationMembers[] = [
    {
        id: 1,
        rank: 1,
        usernameAddress: 'Username1',
        tokensShare: 17.9,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
        sharePercent: 1.34,
        proposalsCreated: 25,
        proposalsWin: 42,
        proposalsWonLost: '23/32',
        voted: 210,
        votedWin: 9.0,
        votedWonLost: '47/3',
    },
    {
        id: 2,
        rank: 2,
        usernameAddress: 'Username',
        tokensShare: 127.1,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
        sharePercent: 12.34,
        proposalsCreated: 5,
        proposalsWin: 22,
        proposalsWonLost: '23/32',
        voted: 20,
        votedWin: 934.0,
        votedWonLost: '47/3',
    },
    {
        id: 3,
        rank: 3,
        usernameAddress: 'Username',
        tokensShare: 170.77,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
        sharePercent: 12.34,
        proposalsCreated: 5,
        proposalsWin: 24,
        proposalsWonLost: '23/32',
        voted: 20,
        votedWin: 94.0,
        votedWonLost: '47/3',
    },
    {
        id: 4,
        rank: 4,
        usernameAddress: 'Username',
        tokensShare: 170.92,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
        sharePercent: 12.34,
        proposalsCreated: 5,
        proposalsWin: 24,
        proposalsWonLost: '23/32',
        voted: 20,
        votedWin: 94.0,
        votedWonLost: '47/3',
    },
    {
        id: 5,
        rank: 5,
        usernameAddress: 'Username',
        tokensShare: 170.55,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
        sharePercent: 12.34,
        proposalsCreated: 5,
        proposalsWin: 24,
        proposalsWonLost: '23/32',
        voted: 80,
        votedWin: 91.0,
        votedWonLost: '47/3',
    },
    {
        id: 6,
        rank: 7,
        usernameAddress: 'Username',
        tokensShare: 170.14,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
        sharePercent: 12.34,
        proposalsCreated: 5,
        proposalsWin: 24,
        proposalsWonLost: '23/32',
        voted: 20,
        votedWin: 94.0,
        votedWonLost: '47/3',
    },
    {
        id: 7,
        rank: 8,
        usernameAddress: 'Username',
        tokensShare: 11.11,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
        sharePercent: 12.34,
        proposalsCreated: 5,
        proposalsWin: 24,
        proposalsWonLost: '23/32',
        voted: 20,
        votedWin: 94.0,
        votedWonLost: '47/3',
    },
    {
        id: 8,
        rank: 9,
        usernameAddress: 'Username',
        tokensShare: 55.5,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
        sharePercent: 12.34,
        proposalsCreated: 5,
        proposalsWin: 24,
        proposalsWonLost: '23/32',
        voted: 20,
        votedWin: 94.0,
        votedWonLost: '47/3',
    },
    {
        id: 9,
        rank: 10,
        usernameAddress: 'Username',
        tokensShare: 1.2,
        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
        sharePercent: 12.34,
        proposalsCreated: 5,
        proposalsWin: 24,
        proposalsWonLost: '23/32',
        voted: 20,
        votedWin: 94.0,
        votedWonLost: '47/3',
    },
];

const votingCoalitions: IVotingCoalition[] = [
    {
        id: 1,
        number: 1,
        votedTogether: 416,
        percentage: 18.1,
        members: [
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                address: '0x5846711b4b7485392c1f0feaec203aa889071717',
                id: 1,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa8890717112127',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 6,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 7,
                    },
                ],
            },
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                address: 'address',
                id: 2,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: 'Username',
                id: 3,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
        ],
    },

    {
        id: 2,
        number: 2,
        votedTogether: 6,
        percentage: 33.3,
        members: [
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: 'Username1',
                id: 1,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 5,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 7,
                    },
                ],
            },
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: 'Username1',
                id: 2,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                address: 'address1',
                id: 3,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: 'Username1',
                id: 4,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: 'Username112122112',
                id: 5,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
        ],
    },

    {
        id: 3,
        votedTogether: 16,
        percentage: 0.8,
        number: 3,
        members: [
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: 'Username1',
                id: 1,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa88907171237',
                        id: 3,
                    },
                ],
            },
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: 'DAO',
                id: 2,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: 'Username1DAO',
                id: 3,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
        ],
    },
    {
        id: 4,
        number: 4,
        votedTogether: 98,
        percentage: 81,
        members: [
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: 'Username1',
                id: 1,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: 'Username1',
                id: 2,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: 'Username1',
                id: 3,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
        ],
    },

    {
        id: 5,
        number: 5,
        votedTogether: 49,
        percentage: 8.2,
        members: [
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                id: 1,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: 'Username1',
                id: 2,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
            {
                logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                userName: 'Username1',
                id: 3,
                addresses: [
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 1,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 2,
                    },
                    {
                        logo: 'https://image.shutterstock.com/image-vector/office-icons-organization-icon-vector-600w-321804731.jpg',
                        addressName: '0x5846711b4b7485392c1f0feaec203aa889071717',
                        id: 3,
                    },
                ],
            },
        ],
    },
];

const assetsData: IAssetsTableItems[] = [
    {
        id: 1,
        img: 'https://randompicturegenerator.com/img/national-park-generator/ga297e25bf92a69031cf84ae257638a9344de49b2de59bee57cc49bd7e3f44f3310434c9a89f870cd44c047ffac168c4c_640.jpg',
        name: 'NAMEhere',
        type: 'Aragon DAO',
        treasury: 3312312,
        members: 22,
    },
    {
        id: 2,
        img: 'https://randompicturegenerator.com/img/national-park-generator/ga297e25bf92a69031cf84ae257638a9344de49b2de59bee57cc49bd7e3f44f3310434c9a89f870cd44c047ffac168c4c_640.jpg',
        name: 'NAMEhere',
        type: 'Aragon DAO',
        treasury: 99999,
        members: 55,
    },
    {
        id: 3,
        img: 'https://randompicturegenerator.com/img/national-park-generator/ga297e25bf92a69031cf84ae257638a9344de49b2de59bee57cc49bd7e3f44f3310434c9a89f870cd44c047ffac168c4c_640.jpg',
        name: 'Namehere',
        type: 'Aragon DAO',
        treasury: 44214,
        members: 222,
    },
    {
        id: 4,
        img: 'https://randompicturegenerator.com/img/national-park-generator/ga297e25bf92a69031cf84ae257638a9344de49b2de59bee57cc49bd7e3f44f3310434c9a89f870cd44c047ffac168c4c_640.jpg',
        name: 'Namehere',
        type: 'Aragon DAO',
        treasury: 123123,
        members: 122,
    },
];

const fundsGraphData: IFundsGraphItems[] = [
    {
        month: '06',
        funds: 2000,
    },
    {
        month: '07',
        funds: 1700,
    },
    {
        month: '08',
        funds: 2500,
    },
    {
        month: '09',
        funds: 2400,
    },
    {
        month: '10',
        funds: 2700,
    },
    {
        month: '11',
        funds: 2800,
    },
    {
        month: '12',
        funds: 2300,
    },
    {
        month: '01',
        funds: 2600,
    },
    {
        month: '02',
        funds: 1400,
    },
    {
        month: '03',
        funds: 2200,
    },
    {
        month: '04',
        funds: 800,
    },
    {
        month: '05',
        funds: 2000,
    },
];

const proposalsChartData = [
    {
        month: '06',
        proposals: 4,
        votes: 19,
    },
    {
        month: '07',
        proposals: 40,
        votes: 24,
    },
    {
        month: '08',
        proposals: 30,
        votes: 13,
    },
    {
        month: '09',
        proposals: 20,
        votes: 8,
    },
    {
        month: '10',
        proposals: 27,
        votes: 39,
    },
    {
        month: '11',
        proposals: 10,
        votes: 4,
    },
    {
        month: '12',
        proposals: 20,
        votes: 30,
    },
    {
        month: '01',
        proposals: 34,
        votes: 43,
    },
    {
        month: '02',
        proposals: 27,
        votes: 39,
    },
    {
        month: '03',
        proposals: 10,
        votes: 4,
    },
    {
        month: '04',
        proposals: 20,
        votes: 30,
    },
    {
        month: '05',
        proposals: 34,
        votes: 43,
    },
];

const activityStats: IActivityStatsItems = {
    proposals: 123,
    creators: 312,
    successful: 23,
    voting: 118,
    participationProposal: 0.0,
    participationVoter: 0.1,
};

const votingStats: IVotingStatsItems = {
    totalVoters: 2343,
    voters: 234,
    avgVotes: 0.8,
    votingFor: 4324,
    votingAgainst: 2343,
};

const researchData: IResearchData = {
    researcherInfo: {
        avatar: 'https://randompicturegenerator.com/img/national-park-generator/gef311385038f8a23c7d764b607bbbfc1ec1c8a3938d46a2193a6e4f2b60240b85f7cf6e91fb76408a287ace7b89366ed_640.jpg',
        nickName: 'User S.',
        date: '25.06.2021',
        description: 'Author and research',
    },

    daoLabels: [
        {
            id: 1,
            label: 'label here too',
            labelNum: 443,
        },
        {
            id: 2,
            label: 'labelhere',
            labelNum: 89,
        },
        {
            id: 3,
            label: 'labelhere',
            labelNum: 111,
        },
        {
            id: 4,
            label: 'label here',
            labelNum: 331,
        },
        {
            id: 5,
            label: 'labelhere',
            labelNum: 12,
        },
    ],

    selfListedLabels: [
        {
            id: 1,
            label: 'labelheretoo',
            labelNum: 22,
        },
        {
            id: 2,
            label: 'labelhere',
            labelNum: 213,
        },
        {
            id: 3,
            label: 'labelhere',
            labelNum: 123,
        },
        {
            id: 4,
            label: 'labelhere',
            labelNum: 77,
        },
        {
            id: 5,
            label: 'labelhere',
            labelNum: 8,
        },
    ],

    textData: `
        <section id='Active_Heading_1'>
            <h4 class='headingText'>Active Heading 1</h4>
            <span class='descriptionText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </span>
        </section>

        <section id='Active_Heading_2'>
            <h4 class='headingText'>Active Heading 2</h4>
            <span class='descriptionText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </span>
        </section>

        <section id='Subheading_1'>
            <h5 class="subheadingText" '>Subheading 1</h5>
            <span class='descriptionText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </span>
        </section>

        <section id='Active_Heading_3'>
            <h4 class='headingText'>Active Heading 3</h4>
            <span class='descriptionText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </span>
        </section>
        
        <section id='Subheading_2'>
            <h5 class="subheadingText">Subheading 2</h5>
            <span class='descriptionText'>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</br> sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </br> Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </br>Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </br>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
            </span>   
        </section>
        `,

    leftMenuNavigation: [
        {
            id: 1,
            titleText: 'Active Heading 1',
            scrollId: 'Active_Heading_1',
        },
        {
            id: 2,
            titleText: 'Active Heading 2',
            scrollId: 'Active_Heading_2',
            values: [
                {
                    id: 1,
                    titleText: 'Subheading 1',
                    scrollId: 'Subheading_1',
                },
            ],
        },
        {
            id: 3,
            titleText: 'Active Heading 3',
            scrollId: 'Active_Heading_3',
            values: [
                {
                    id: 1,
                    titleText: 'Subheading 2',
                    scrollId: 'Subheading_2',
                },
            ],
        },
    ],
};

const totalAumUSD: IChartItem[] = [
    {
        label: 'One',
        value: 1,
    },
    {
        label: 'Two',
        value: 22,
    },
    {
        label: 'Three',
        value: 16,
    },
    {
        label: 'Four',
        value: 19,
    },
    {
        label: 'Five',
        value: 9,
    },
    {
        label: 'Six',
        value: 23,
    },
    {
        label: 'Seven',
        value: 19,
    },
    {
        label: 'Eight',
        value: 25,
    },
];

const peopleAUMbyDeciles: IChartItem[] = [
    {
        label: '1st',
        value: 1,
    },
    {
        label: '2nd',
        value: 13,
    },
    {
        label: '3rd',
        value: 6,
    },
    {
        label: '4th',
        value: 20,
    },
    {
        label: '5th',
        value: 15,
    },
    {
        label: '6th',
        value: 11,
    },
    {
        label: '7th',
        value: 22,
    },
    {
        label: '8th',
        value: 9,
    },
    {
        label: '9th',
        value: 11,
    },
    {
        label: '10th',
        value: 5,
    },
];

const totalAUMBreackdown: IChartItem[] = [
    {
        label: '0-25k',
        value: 30,
    },
    {
        label: '25-50k',
        value: 80,
    },
    {
        label: '50-100k',
        value: 300,
    },
    {
        label: '100-200k',
        value: 120,
    },
    {
        label: '200k-1m',
        value: 79,
    },
    {
        label: '+1m',
        value: 236,
    },
];

const topTokensByUsd: IChartItem[] = [
    {
        label: 'Ether',
        value: 130,
    },
    {
        label: 'Lido',
        value: 80,
    },
    {
        label: 'StormX',
        value: 30,
    },
    {
        label: 'Gitcoin',
        value: 222,
    },
    {
        label: 'Sushi',
        value: 111,
    },
];

const topTokensByPeople: IChartItem[] = [
    {
        label: 'Ether',
        value: 432,
    },
    {
        label: 'Lido',
        value: 234,
    },
    {
        label: 'StormX',
        value: 98,
    },
    {
        label: 'Gitcoin',
        value: 323,
    },
    {
        label: 'Sushi',
        value: 76,
    },
];

const totalPeopleAumChanges: IStatsChartChanges = {
    totalAum: 586900000,
    changeMonth: -17400000,
    changeWeek: 505300000,
};

const peopleTableData = [
    {
        id: 1,
        rank: 1,
        avatar: 'https://picsum.photos/id/10/367/267',
        userName: 'Username',
        tagline: 'Tagline ipsum dolor sit amet, consectetur adipiscing elit',
        daos: 32,
        proposals: 23,
        votes: 23,
        topTokensList: [
            {
                id: 1,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1041/367/267',
            },
            {
                id: 2,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1015/367/267',
            },
            {
                id: 23,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1041/367/267',
            },
            {
                id: 13,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/10/367/267',
            },
            {
                id: 12,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/101/367/267',
            },
            {
                id: 43,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/10/367/267',
            },
        ],
    },
    {
        id: 2,
        rank: 2,
        avatar: 'https://picsum.photos/id/1015/367/267',
        userName: 'Username',
        tagline: 'Tagline ipsum dolor sit amet, consectetur adipiscing elit',
        daos: 12,
        proposals: 7,
        votes: 132,
        topTokensList: [
            {
                id: 1,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1041/367/267',
            },
            {
                id: 13,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/10/367/267',
            },
            {
                id: 12,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1015/367/267',
            },
            {
                id: 43,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1019/367/267',
            },
        ],
    },
    {
        id: 3,
        rank: 12,
        avatar: 'https://picsum.photos/id/1019/367/267',
        userName: 'Username',
        tagline: 'Tagline ipsum dolor sit amet, consectetur adipiscing elit',
        daos: 90,
        proposals: 123,
        votes: 424,
        topTokensList: [
            {
                id: 1,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1045/367/267',
            },
            {
                id: 13,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1018/367/267',
            },
        ],
    },
    {
        id: 4,
        rank: 15,
        avatar: 'https://picsum.photos/id/1018/367/267',
        userName: 'Username',
        tagline: 'Tagline ipsum dolor sit amet, consectetur adipiscing elit',
        daos: 0,
        proposals: 0,
        votes: 0,
        topTokensList: [
            {
                id: 1,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/10/367/267',
            },
            {
                id: 13,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1045/367/267',
            },
            {
                id: 12,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1022/367/267',
            },
            {
                id: 43,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/10/367/267',
            },
        ],
    },
    {
        id: 22,
        rank: 20,
        avatar: 'https://picsum.photos/id/1022/367/267',
        userName: 'Username',
        tagline: 'Tagline ipsum dolor sit amet, consectetur adipiscing elit',
        daos: 6,
        proposals: 7,
        votes: 8,
        topTokensList: [
            {
                id: 1,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/10/367/267',
            },
            {
                id: 13,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1045/367/267',
            },
            {
                id: 12,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/101/367/267',
            },
            {
                id: 43,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1045/367/267',
            },
            {
                id: 1333,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/10/367/267',
            },
            {
                id: 122,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/101/367/267',
            },
            {
                id: 143,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/10/367/267',
            },
        ],
    },
    {
        id: 152,
        rank: 25,
        avatar: 'https://picsum.photos/id/101/367/267',
        userName: 'Username',
        tagline: 'Tagline ipsum dolor sit amet, consectetur adipiscing elit',
        daos: 90,
        proposals: 180,
        votes: 270,
        topTokensList: [
            {
                id: 13,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/10/367/267',
            },
            {
                id: 12,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/101/367/267',
            },
            {
                id: 43,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1045/367/267',
            },
        ],
    },
    {
        id: 2252,
        rank: 27,
        avatar: 'https://picsum.photos/id/1039/367/267',
        userName: 'Username',
        tagline: 'Tagline ipsum dolor sit amet, consectetur adipiscing elit',
        daos: 11,
        proposals: 111,
        votes: 1111,
        topTokensList: [
            {
                id: 1,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/10/367/267',
            },
            {
                id: 13,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/1039/367/267',
            },
            {
                id: 12,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/101/367/267',
            },
            {
                id: 431,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/10/367/267',
            },
            {
                id: 43,
                name: 'Token',
                tokenImage: 'https://picsum.photos/id/10/367/267',
            },
        ],
    },
];

const userInfoData: IUserInfoData = {
    userName: 'Username',
    avatar: 'https://picsum.photos/id/374/367/267',
    tagline: 'Tagline here dolor sit amet, consectetur adipiscing elit, sed do',
    descripton:
        'Description here dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniamconsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    userAddresses: [
        { id: 1, address: '0x5846711b4b7485392c1f0feaec203aa889071717' },
        { id: 12, address: '0x5846711b4b7485392c1f0feaec203aasd9071317' },
    ],
};

const userLabelsList: ILabelItems[] = [
    {
        id: 1,
        label: 'labelheretoo',
        labelNum: 222,
    },
    {
        id: 2,
        label: 'userlabelhere',
        labelNum: 123,
    },
    {
        id: 3,
        label: 'labelhere',
        labelNum: 99,
    },
    {
        id: 4,
        label: 'labelhere',
        labelNum: 20,
    },
    {
        id: 5,
        label: 'labelhere',
        labelNum: 8,
    },
];

const userPersonalStatsData: IUserPersonalStatsData = {
    daos: 12143,
    proposals: 231212,
    successfulProposals: 80,
    votes: 321399723,
    successfulVotes: 44,
};

const topTokensValuesData: ITopTokensValuesData[] = [
    {
        id: 1,
        avatar: 'https://picsum.photos/id/1023/367/267',
        currencyName: 'Currencyname1',
        holding: 68,
        usdValue: 123213,
    },
    {
        id: 2,
        avatar: 'https://picsum.photos/id/1040/367/267',
        currencyName: 'Currencyname2',
        holding: 53,
        usdValue: 424124,
    },
    {
        id: 3,
        avatar: 'https://picsum.photos/id/1029/367/267',
        currencyName: 'Currencyname313',
        holding: 4444,
        usdValue: 3221243,
    },
    {
        id: 112,
        avatar: 'https://picsum.photos/id/1042/367/267',
        currencyName: 'Currencyname113',
        holding: 345,
        usdValue: 123213,
    },
    {
        id: 213,
        avatar: 'https://picsum.photos/id/1043/367/267',
        currencyName: 'Currencyname213131',
        holding: 432,
        usdValue: 8989435,
    },
    {
        id: 133,
        avatar: 'https://picsum.photos/id/1037/367/267',
        currencyName: 'Currencyname33333',
        holding: 999,
        usdValue: 999999,
    },
];

const connectToPeopleData: IUserTokensData[] = [
    {
        id: 1,
        avatar: 'https://picsum.photos/id/1037/367/267',
        daosTokens: [
            { id: 1, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
            { id: 2, tokenImage: 'https://picsum.photos/id/1058/367/267', name: 'token' },
            { id: 3, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
        ],
        topTokens: [
            { id: 1, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
            { id: 2, tokenImage: 'https://picsum.photos/id/1058/367/267', name: 'token' },
            { id: 3, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
        ],
    },
    {
        id: 112,
        avatar: 'https://picsum.photos/id/1055/367/267',
        daosTokens: [
            { id: 1, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
            { id: 2, tokenImage: 'https://picsum.photos/id/1058/367/267', name: 'token' },
            { id: 3, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
            { id: 11, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
            { id: 22, tokenImage: 'https://picsum.photos/id/1058/367/267', name: 'token' },
            { id: 33, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
        ],
        topTokens: [
            { id: 3312, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
            { id: 32233, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
            { id: 31, tokenImage: 'https://picsum.photos/id/1061/367/267', name: 'token' },
            { id: 33, tokenImage: 'https://picsum.photos/id/1061/367/267', name: 'token' },
            { id: 342, tokenImage: 'https://picsum.photos/id/1061/367/267', name: 'token' },
        ],
    },
    {
        id: 1133,
        avatar: 'https://picsum.photos/id/1054/367/267',
        daosTokens: [
            { id: 13, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
            { id: 23, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
            { id: 33, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
        ],
        topTokens: [
            { id: 3321, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
            { id: 3123, tokenImage: 'https://picsum.photos/id/1037/367/267', name: 'token' },
            { id: 3125, tokenImage: 'https://picsum.photos/id/1064/367/267', name: 'token' },
        ],
    },
];

const organizationInfo = {
    id: 234324,
    organizationName: 'Decentraland',
    organizationPlatform: 'Aragon',
    lastUpdate: 'June 10th, 2021, 16:04',
    textDescription:
        'Description here dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniamconsectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
};

const membersPosts: IMembersPosts[] = [
    {
        id: 1,
        organizationName: 'Bancor',
        organizationImage: 'https://picsum.photos/seed/picsum/536/354',
        posts: 10,
    },
    {
        id: 2,
        organizationName: 'Organization Name',
        organizationImage: 'https://picsum.photos/536/354',
        posts: 72,
    },
    {
        id: 13,
        organizationName: 'Bancor1',
        organizationImage: 'https://picsum.photos/id/658/367/267',
        posts: 99,
    },
    {
        id: 1321,
        organizationName: 'Bancor2',
        organizationImage: 'https://picsum.photos/seed/picsum/536/354',
        posts: 0,
    },
    {
        id: 2132,
        organizationName: 'Bancor3',
        organizationImage: 'https://picsum.photos/id/664/367/267',
        posts: 214,
    },
];

const personalHashtagsData: IHashtagData[] = [
    {
        id: 1,
        name: 'label',
        count: 84,
    },
    {
        id: 3,
        name: 'label',
        count: 67,
    },
    {
        id: 123,
        name: 'label',
        count: 111,
    },
    {
        id: 112,
        name: 'label',
        count: 242,
    },
    {
        id: 113,
        name: 'label',
        count: 99,
    },
    {
        id: 1123,
        name: 'label',
        count: 13,
    },
    {
        id: 24231,
        name: 'Label',
        count: 242,
    },
];

const labelsSearchResults: ILabelsSearchItems[] = [
    {
        id: 1,
        labelName: 'label',
        labelCount: 23,
    },
    {
        id: 13134,
        labelName: 'label',
        labelCount: 23,
    },
    {
        id: 12,
        labelName: 'labelname',
        labelCount: 9,
    },
    {
        id: 31,
        labelName: 'labelitem',
        labelCount: 2,
    },
    {
        id: 241,
        labelName: 'label_',
        labelCount: 45,
    },
    {
        id: 1241,
        labelName: 'Label',
        labelCount: 776,
    },
    {
        id: 1214,
        labelName: 'label',
        labelCount: 99,
    },
];

const peopleSearchResults: IUserSearchItems[] = [
    {
        id: 11,
        avatar: 'https://picsum.photos/id/10/367/267',
        userName: 'Username1',
        address: '0x5846711b4b7485392c1f0feaec203aa889071717',
    },
    {
        id: 111,
        avatar: 'https://picsum.photos/id/10/367/267',
        userName: 'Username2',
        address: '0x5846711b4b7485392c1f0feaec203aa889071717',
    },
    {
        id: 131,
        avatar: 'https://picsum.photos/id/10/367/267',
        userName: 'Username3',
        address: '0x5846711b4b7485392c1f0feaec203aa889071717',
    },
    {
        id: 141,
        avatar: 'https://picsum.photos/id/10/367/267',
        userName: 'Username4',
        address: '0x5846711b4b7485392c1f0feaec203aa889071717',
    },
    {
        id: 151,
        avatar: 'https://picsum.photos/id/10/367/267',
        userName: 'Username5',
        address: '0x5846711b4b7485392c1f0feaec203aa889071717',
    },
    {
        id: 161,
        avatar: 'https://picsum.photos/id/10/367/267',
        userName: 'Username6',
        address: '0x5846711b4b7485392c1f0feaec203aa889071717',
    },
    {
        id: 16311,
        avatar: 'https://picsum.photos/id/10/367/267',
        userName: 'Username7',
        address: '0x5846711b4b7485392c1f0feaec203aa889071717',
    },
    {
        id: 16131,
        avatar: 'https://picsum.photos/id/10/367/267',
        userName: 'Username6113',
        address: '0x5846711b4b7485392c1f0feaec203aa889071717',
    },
    {
        id: 1613121,
        avatar: 'https://picsum.photos/id/10/367/267',
        userName: 'Username6144',
        address: '0x5846711b4b7485392c1f0feaec203aa889071717',
    },
];

const topPeopleTokensData = [
    {
        id: 1,
        rank: 1,
        tokenName: 'Ether',
        tokenSymbol: 'ETH',
        balance: 324234.324,
        countOfPeople: 234,
    },
    {
        id: 2,
        rank: 2,
        tokenName: 'Uniswap',
        tokenSymbol: 'UNI',
        balance: 87812731823.23,
        countOfPeople: 31232,
    },
    {
        id: 3,
        rank: 3,
        tokenName: 'Gitcoin',
        tokenSymbol: 'Git',
        balance: 1111.111,
        countOfPeople: 111,
    },
    {
        id: 4,
        rank: 4,
        tokenName: 'NXM',
        tokenSymbol: 'NXM',
        balance: 960000,
        countOfPeople: 234,
    },
    {
        id: 5,
        rank: 5,
        tokenName: 'Quant',
        tokenSymbol: 'QNT',
        balance: 555555,
        countOfPeople: 555,
    },
    {
        id: 6,
        rank: 6,
        tokenName: 'Aave',
        tokenSymbol: 'AAVE',
        balance: 90123.32,
        countOfPeople: 8721,
    },
    {
        id: 7,
        rank: 7,
        tokenName: 'API3',
        tokenSymbol: 'API3',
        balance: 423432.234,
        countOfPeople: 72432,
    },
    {
        id: 8,
        rank: 8,
        tokenName: 'BZZ',
        tokenSymbol: 'BZZ',
        balance: 6712323422424,
        countOfPeople: 242,
    },
];

const resourcersTagsList = [
    {
        id: 1,
        name: 'Tag1',
    },
    {
        id: 2,
        name: 'Tag2',
    },
    {
        id: 3,
        name: 'Tag3',
    },
    {
        id: 4,
        name: 'Tag4',
    },
    {
        id: 5,
        name: 'Tag5',
    },
    {
        id: 6,
        name: 'Tag6',
    },
    {
        id: 7,
        name: 'Tag7',
    },
    {
        id: 8,
        name: 'Tag8',
    },
    {
        id: 9,
        name: 'Tag9',
    },
    {
        id: 10,
        name: 'Tag239',
    },
    {
        id: 11,
        name: 'Tag10',
    },
    {
        id: 12,
        name: 'Tag11',
    },
    {
        id: 21,
        name: 'Tag12',
    },
    {
        id: 22,
        name: 'Tag13',
    },
    {
        id: 23,
        name: 'Tag14',
    },
    {
        id: 24,
        name: 'Tag15',
    },
    {
        id: 25,
        name: 'Tag16',
    },
    {
        id: 26,
        name: 'Tag17',
    },
    {
        id: 27,
        name: 'Tag18',
    },
    {
        id: 28,
        name: 'Tag19',
    },
    {
        id: 29,
        name: 'Tag20',
    },
    {
        id: 210,
        name: 'Tag21',
    },
    {
        id: 211,
        name: 'Tag22',
    },
    {
        id: 212,
        name: 'Tag23',
    },
];

export {
    organizations,
    organizationTokens,
    organizationFeeds,
    shareholdersList,
    similarPeople,
    treasuryRank,
    totalInRank,
    totalOutRank,
    membersRank,
    proposalsRank,
    organizationMembers,
    votingCoalitions,
    assetsData,
    fundsGraphData,
    proposalsChartData,
    activityStats,
    votingStats,
    researchData,
    totalAumUSD,
    peopleAUMbyDeciles,
    totalAUMBreackdown,
    totalPeopleAumChanges,
    peopleTableData,
    userInfoData,
    userLabelsList,
    userPersonalStatsData,
    topTokensValuesData,
    connectToPeopleData,
    organizationInfo,
    membersPosts,
    personalHashtagsData,
    labelsSearchResults,
    peopleSearchResults,
    topPeopleTokensData,
    resourcersTagsList,
    topTokensByUsd,
    topTokensByPeople,
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { ImageLoader, TableCell } from 'components';

import { IMAGE_TYPES } from 'constants/imageTypes';
import { PEOPLE_PARAMS } from 'components/PeopleTables/constants';

import { votesIcon, proposalIcon } from 'assets';
import { FormatUtils, LinkUtils } from 'utils';

import { IPeopleData, ITableDataWithSorting } from 'types/interfaces';
import { Link } from 'react-router-dom';

export const PeopleItems: FC<ITableDataWithSorting<IPeopleData>> = ({
    address,
    rank,
    daoAmount,
    avatar,
    name,
    participationScore,
    participationRelativeScore,
    proposalsAmount,
    votesAmount,
    sortedParamName,
    isSmartContract,
}: ITableDataWithSorting<IPeopleData>): ReactElement => {
    return (
        <Link to={LinkUtils.createDynamicLink(address, 'user')}>
            <div className={styles.tableRow}>
                <div className={styles.rank}>
                    <span>{rank}</span>
                </div>
                <div className={styles.members}>
                    <ImageLoader src={avatar} className={styles.avatar} imageType={IMAGE_TYPES.USER_IMAGE} />
                    <div className={styles.userInfo}>
                        <h4 className={styles.userName}>{name || FormatUtils.formatUserAddress(address)}</h4>
                    </div>
                </div>
                <div className={styles.type}>
                    <span>{isSmartContract ? 'Contract' : 'Wallet'}</span>
                </div>
                <TableCell
                    className={styles.participationScore}
                    isHighlighted={sortedParamName === PEOPLE_PARAMS.PARTICIPATION_SCORE_PARAM}
                >
                    <span>{`${participationScore} (${participationRelativeScore}%)`}</span>
                </TableCell>
                <TableCell className={styles.daos} isHighlighted={sortedParamName === PEOPLE_PARAMS.DAOS_PARAM}>
                    <span>{daoAmount}</span>
                </TableCell>
                <TableCell className={styles.proposals} isHighlighted={sortedParamName === PEOPLE_PARAMS.VOTES_PARAM}>
                    <img src={votesIcon} className={styles.votesIcon} alt="" />
                    <span>{votesAmount}</span>
                </TableCell>
                <TableCell className={styles.votes} isHighlighted={sortedParamName === PEOPLE_PARAMS.PROPOSALS_PARAM}>
                    <img src={proposalIcon} className={styles.proposalIcon} alt="" />
                    <span>{proposalsAmount}</span>
                </TableCell>
            </div>
        </Link>
    );
};

import React, { FC, ReactElement, memo } from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { IFeedbackLinksProps, ISupportingLink } from 'types/interfaces';

export const FeedbackLinks: FC<IFeedbackLinksProps> = memo(({ links }: IFeedbackLinksProps): ReactElement => {
    return (
        <div className={styles.linksWrapper}>
            {links.map(({ id, link, name }: ISupportingLink) => (
                <Link to={link} key={id} className={styles.link}>
                    {name}
                </Link>
            ))}
        </div>
    );
});

import { createApiAction } from 'helpers';

export const GET_ALL_PEOPLE_DATA = 'GET_ALL_PEOPLE_DATA';
export const GET_ALL_PEOPLE_DATA_SUCCESS = 'GET_ALL_PEOPLE_DATA_SUCCESS';
export const GET_ALL_PEOPLE_DATA_FAILURE = 'GET_ALL_PEOPLE_DATA_FAILURE';

export const GET_PEOPLE_SORTED_DATA = 'GET_PEOPLE_SORTED_DATA';
export const GET_PEOPLE_SORTED_DATA_SUCCESS = 'GET_PEOPLE_SORTED_DATA_SUCCESS';
export const GET_PEOPLE_SORTED_DATA_FAILURE = 'GET_PEOPLE_SORTED_DATA_FAILURE';

export const CLEAR_PEOPLE_DATA = 'CLEAR_PEOPLE_DATA';
export const CLEAR_ALL_PEOPLE_DATA = 'CLEAR_ALL_PEOPLE_DATA';

export const getAllPeopleData = createApiAction(GET_ALL_PEOPLE_DATA);
export const getPeopleSortedData = createApiAction(GET_PEOPLE_SORTED_DATA);

import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

import { InfoCircle, Loader, TableDataNotFound } from 'components';
import { TableHeader, TableRow } from './components';

import { ITreasuryComposition } from '../../../../../../../../types/interfaces';
import { TREASURY_TAB, TREASURY_TAB_DESCRIPTIONS } from '../../../../../../../../constants';

interface ITrendsTableProps {
    organizationTreasuryComposition: ITreasuryComposition[];
    loading: boolean;
}

export const TreasuryCompositionTable = ({
    organizationTreasuryComposition,
    loading,
}: ITrendsTableProps): ReactElement => {
    return (
        <div className={styles.content}>
            <div className={styles.headSection}>
                <div className={styles.textSection}>
                    <h4>{'Treasury Composition Value'}</h4>
                    <InfoCircle
                        tooltipText={TREASURY_TAB_DESCRIPTIONS[TREASURY_TAB.COMPOSITION_VALUE]}
                        className={styles.infoIcon}
                    />
                </div>
            </div>

            {organizationTreasuryComposition.length && (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        <TableHeader />
                        <TableRow
                            timePeriod={'Current'}
                            treasuryComposition={
                                organizationTreasuryComposition.find((t) => t.datePeriod === 'current') as any
                            }
                        />
                        <TableRow
                            timePeriod={'7 Days'}
                            treasuryComposition={
                                organizationTreasuryComposition.find((t) => t.datePeriod === '7 days') as any
                            }
                        />
                        <TableRow
                            timePeriod={'30 Days'}
                            treasuryComposition={
                                organizationTreasuryComposition.find((t) => t.datePeriod === '30 days') as any
                            }
                        />
                        <TableRow
                            timePeriod={'6 Months'}
                            treasuryComposition={
                                organizationTreasuryComposition.find((t) => t.datePeriod === '6 months') as any
                            }
                        />
                        <TableRow
                            timePeriod={'1 Year'}
                            treasuryComposition={
                                organizationTreasuryComposition.find((t) => t.datePeriod === '1 year') as any
                            }
                        />
                        <TableRow
                            timePeriod={'2 Years'}
                            treasuryComposition={
                                organizationTreasuryComposition.find((t) => t.datePeriod === '2 years') as any
                            }
                        />
                    </div>
                </div>
            )}

            {loading && organizationTreasuryComposition.length && <Loader size={40} className={styles.loaderBox} />}
            {!organizationTreasuryComposition.length && !loading && (
                <TableDataNotFound forTable overflowMarginsSecondVariant />
            )}
        </div>
    );
};

import {
    DanielBarAvatar,
    EyalEithcowichAvatar,
    InbalMizrachAvatar,
    YuriiPristayAvatar,
    StasMaksinAvatar,
    NoamHofAvatar,
    IvanSucharskiAvatar,
    RonaPerryAvatar,
    AmandeepAvatar,
    OleksandrVelychkoAvatar,
    About_A,
    About_B,
    About_C,
    GnosisSafe,
    Aragon,
    Snapshot,
    Covalent,
    DAOHaus,
    Colony,
    OpenLaw,
    DStack,
    SubQuery,
    YBBFoundation,
    Moonwhale,
    LDEN,
    Incuba,
    Bitfwd,
    Dfg,
    Hypersphere,
    ArkstreamCapital,
    TRGC,
    IG_1,
    BC,
    IDtheory,
    SevenX1,
    KLabs,
    ElliptiLogo,
    ImpossibleFinance,
    IDC,
    Kerne,
    Lancer,
    FollowSeed,
    MrBlockLogo,
    AU21,
    Stratos,
    LVT,
    PuzzleVentures,
    MorningstarVentures,
    Waterdrip,
    Paka,
    JRR,
    About_A_Mobile,
    About_B_Mobile,
    About_C_Mobile,
    RadiBeckerAvatar,
    MoonCapital,
} from 'assets';

import styles from './styles.module.scss';

import { ISellingPoint, IDeepDaoMember, IDeepDaoPartnersItems } from 'types/interfaces';

const daoDataKeys = ['Governance', 'Members', 'Coalitions', 'Votes and Proposals', 'DAO assets', 'Similar DAOs', 'API'];

const daoPeopleKeys = [
    'Governance ID ',
    'People’s DAOs',
    'People’s holdings',
    'G-score',
    'Activity Badges',
    'Similar DAOists',
];

const ecosystemKeys = [
    'DAO Guides',
    'DAO Creation',
    'Governance',
    'Treasury',
    'Legal Frameworks',
    'Security',
    'Insurance',
    'Dispute Resolution',
];

const members: IDeepDaoMember[] = [
    {
        id: 1,
        avatar: EyalEithcowichAvatar,
        name: 'Eyal Eithcowich',
        role: 'Founder',
        link: '/user/0xf5913759b449fae8900d61f7116d451d200c60f7/verified_dao_experience',
    },
    {
        id: 2,
        avatar: RonaPerryAvatar,
        name: 'Rona Perry',
        role: 'COO',
        link: '/user/0x23c8cc67758050971c2e5a8d73d1f9731252c046/verified_dao_experience',
    },
    {
        id: 3,
        avatar: RadiBeckerAvatar,
        name: 'Radi Becker',
        role: 'CTO',
        link: '/user/0x9de6217d99cb6b6abc72adf0b60addaf7f6aca4e/verified_dao_experience',
    },
    {
        id: 4,
        avatar: InbalMizrachAvatar,
        name: 'Inbal Mizrach',
        role: 'UX Strategy',
        link: undefined,
    },
    {
        id: 6,
        avatar: OleksandrVelychkoAvatar,
        name: 'Oleksandr Velychko',
        role: 'Tech',
        link: undefined,
    },
    {
        id: 7,
        avatar: StasMaksinAvatar,
        name: 'Stas Maksin',
        role: 'Tech',
        link: '/user/0xcedaa20b7f09cd051bb12ea8c7c8b64b90879f82/verified_dao_experience',
    },
    {
        id: 8,
        avatar: YuriiPristayAvatar,
        name: 'Yurii Pristay',
        role: 'Tech',
        link: undefined,
    },
    {
        id: 9,
        avatar: NoamHofAvatar,
        name: 'Noam Hof',
        role: 'Research & Analysis',
        link: '/user/0x0F9D7257a592c7d4359D6059811e07A9A1Cff110/verified_dao_experience',
    },
    {
        id: 10,
        avatar: AmandeepAvatar,
        name: 'Amandeep',
        role: 'Research',
        link: '/user/0x7ace0b7a0cfb2980aa25310af5c2602144d58db2/verified_dao_experience',
    },
    {
        id: 11,
        avatar: IvanSucharskiAvatar,
        name: 'Ivan Sucharski, Phd.',
        role: 'Advisor',
        link: '/user/0x0ebbda43014a6e6c32657a8db79376738d549b2f/verified_dao_experience',
    },
    {
        id: 12,
        avatar: DanielBarAvatar,
        name: 'Daniel Bar',
        role: 'Strategy Advisor',
        link: '/user/0xb64850fe701fb64667b6cc762aa2c12f2e02193c/verified_dao_experience',
    },
];

const aboutSellingPoints: ISellingPoint[] = [
    {
        id: 1,
        image: About_A,
        mobileImage: About_A_Mobile,
        title: 'Explore DAO Data',
        subTitle:
            'Deep dive into the core of each DAO on the ecosystem. Be informed on every activity made in thousands of DAOs and the people in it.',
        keysData: daoDataKeys,
        wrapperClass: styles.firstWrapper,
    },
    {
        id: 2,
        image: About_B,
        mobileImage: About_B_Mobile,
        title: 'Governance Identity: Discover DAO people',
        subTitle:
            'Addresses as DAO identities: The DAOs each person is active in. Their proposals. Votes. Decision making process. Tokens. Coalitions. Which people are they friends with? Working closely with? Discover it all with DeepDAO People.',
        keysData: daoPeopleKeys,
        wrapperClass: styles.secondWrapper,
    },
    {
        id: 3,
        image: About_C,
        mobileImage: About_C_Mobile,
        title: 'Ecosystem tools researched by DeepDAO',
        subTitle:
            'We’re in the midst of DAO tools explosion. DeepDAO lists the top tools for creating, and managing DAOs. Start here for all your DAO needs, from learning to building to rewarding contributors.',
        keysData: ecosystemKeys,
        wrapperClass: styles.thirdWrapper,
    },
];

const partners: IDeepDaoPartnersItems = {
    firstSection: [
        {
            id: 1,
            partnerImg: GnosisSafe,
            imgStyles: { maxWidth: '200px' },
        },
        {
            id: 2,
            partnerImg: Aragon,
            imgStyles: { maxWidth: '176px' },
        },
        {
            id: 3,
            partnerImg: Snapshot,
            imgStyles: { maxWidth: '156px' },
        },
        {
            id: 4,
            partnerImg: Covalent,
            imgStyles: { maxWidth: '167px' },
        },
    ],

    secondSection: [
        {
            id: 5,
            partnerImg: DAOHaus,
            imgStyles: { maxWidth: '158px' },
        },
        {
            id: 6,
            partnerImg: Colony,
            imgStyles: { maxWidth: '190px' },
        },
        {
            id: 7,
            partnerImg: OpenLaw,
            imgStyles: { maxWidth: '225px' },
        },
        {
            id: 8,
            partnerImg: DStack,
            imgStyles: { maxWidth: '162px' },
        },
        {
            id: 9,
            partnerImg: SubQuery,
            imgStyles: { maxWidth: '167px' },
        },
    ],
};

const supported: IDeepDaoPartnersItems = {
    firstSection: [
        {
            id: 1,
            partnerImg: YBBFoundation,
            imgStyles: { maxWidth: '246px' },
        },
        {
            id: 2,
            partnerImg: Moonwhale,
            imgStyles: { maxWidth: '134px' },
        },
        {
            id: 3,
            partnerImg: LDEN,
            imgStyles: { maxWidth: '212px' },
        },
    ],
    secondSection: [
        {
            id: 4,
            partnerImg: MoonCapital,
            imgStyles: { maxWidth: '140px' },
        },
        {
            id: 5,
            partnerImg: Dfg,
            imgStyles: { maxWidth: '140px' },
        },
        {
            id: 6,
            partnerImg: Incuba,
            imgStyles: { maxWidth: '92px' },
        },
        {
            id: 7,
            partnerImg: Bitfwd,
            imgStyles: { maxWidth: '193px' },
        },
        {
            partnerImg: Hypersphere,
            imgStyles: { maxWidth: '132px' },
            id: 8,
        },
    ],
    thirdSection: [
        {
            id: 9,
            partnerImg: ArkstreamCapital,
            imgStyles: { maxWidth: '146px' },
        },
        {
            id: 10,
            partnerImg: TRGC,
            imgStyles: { maxWidth: '69px' },
        },
        {
            id: 11,
            partnerImg: IG_1,
            imgStyles: { maxWidth: '42px' },
        },
        {
            id: 12,
            partnerImg: BC,
            imgStyles: { maxWidth: '190px' },
        },
        {
            id: 13,
            partnerImg: IDtheory,
            imgStyles: { maxWidth: '54px' },
        },
        {
            id: 14,
            partnerImg: SevenX1,
            imgStyles: { maxWidth: '144px' },
        },
        {
            id: 15,
            partnerImg: KLabs,
            imgStyles: { maxWidth: '43px' },
        },
        {
            partnerImg: ElliptiLogo,
            imgStyles: { maxWidth: '73px' },
            id: 16,
        },
    ],
    fourthSection: [
        {
            id: 17,
            partnerImg: ImpossibleFinance,
            imgStyles: { maxWidth: '139px' },
        },
        {
            id: 18,
            partnerImg: IDC,
            imgStyles: { maxWidth: '64px' },
        },
        {
            id: 19,
            partnerImg: Kerne,
            imgStyles: { maxWidth: '147px' },
        },
        {
            id: 20,
            partnerImg: Lancer,
            imgStyles: { maxWidth: '49px' },
        },
        {
            id: 21,
            partnerImg: FollowSeed,
            imgStyles: { maxWidth: '167px' },
        },
        {
            id: 22,
            partnerImg: MrBlockLogo,
            imgStyles: { maxWidth: '61px' },
        },
        {
            partnerImg: AU21,
            imgStyles: { maxWidth: '179px' },
            id: 23,
        },
    ],
    fifthSection: [
        {
            id: 24,
            partnerImg: JRR,
            imgStyles: { maxWidth: '61px' },
        },
        {
            id: 25,
            partnerImg: Stratos,
            imgStyles: { maxWidth: '136px' },
        },
        {
            id: 26,
            partnerImg: Waterdrip,
            imgStyles: { maxWidth: '101px' },
        },
        {
            id: 27,
            partnerImg: Paka,
            imgStyles: { maxWidth: '96px' },
        },
        {
            id: 28,
            partnerImg: PuzzleVentures,
            imgStyles: { maxWidth: '110px' },
        },
        {
            id: 29,
            partnerImg: LVT,
            imgStyles: { maxWidth: '146px' },
        },
        {
            id: 30,
            partnerImg: MorningstarVentures,
            imgStyles: { maxWidth: '164px' },
        },
    ],
};

export { daoDataKeys, daoPeopleKeys, ecosystemKeys, members, aboutSellingPoints, partners, supported };

import React, { FC, ReactElement, useRef } from 'react';

import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import classNames from 'classnames';

import { ISearchFieldProps } from 'types/interfaces';

import styles from './styles.module.scss';

export const SearchField: FC<ISearchFieldProps> = ({ inputValue, setInputValue }: ISearchFieldProps): ReactElement => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <div className={classNames(styles.searchContainer)}>
            <Button
                className={classNames(styles.searchButton)}
                disableRipple
                onClick={() => {
                    setInputValue('');
                    inputRef?.current && inputRef?.current.focus();
                }}
            >
                <SearchIcon className={styles.searchIcon} />
            </Button>

            <div className={styles.inputContainer}>
                <input
                    ref={inputRef}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className={classNames(styles.input)}
                />
                <Button
                    className={classNames(styles.clearFieldButton, { [styles.clearButtonActive]: inputValue })}
                    disableRipple
                    onClick={() => setInputValue('')}
                >
                    <CloseIcon className={styles.clearFieldIcon} />
                </Button>
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import { ProtectedRoute, RouterRoute } from './components';

import { ROUTES } from './constants';

import { IRout } from 'types/interfaces';

export const Router: FC = (): ReactElement => {
    return (
        <Switch>
            <Route exact path="/" render={() => <Redirect to="/organizations" />} />
            {ROUTES.map(({ active, isProtected, ...options }: IRout) =>
                !active ? null : isProtected ? (
                    <ProtectedRoute key={options.id} {...options} />
                ) : (
                    <RouterRoute key={options.id} {...options} />
                ),
            )}
        </Switch>
    );
};

import React, { FC, ReactElement } from 'react';

import { Button } from '@material-ui/core';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { SHOW_ALL_CATEGORIES, SHOW_ALL_CATEGORIES_ID } from 'components/OrganizationsTables/constants';

import { useAppSelector } from 'store';

import { IOrganizationCategory, IOrganizationsCategoriesContainerProps } from 'types/interfaces';

export const OrganizationsCategoriesContainer: FC<IOrganizationsCategoriesContainerProps> = ({
    onCategoryClick,
    categorySelectedId,
}: IOrganizationsCategoriesContainerProps): ReactElement => {
    const { organizationsCategories } = useAppSelector(({ organizations }) => organizations);

    return (
        <div className={styles.categoriesWrapper}>
            <div className={styles.categoriesContainer}>
                {[SHOW_ALL_CATEGORIES, ...organizationsCategories].map(({ id, name }: IOrganizationCategory) => (
                    <Button
                        key={id}
                        className={classNames(styles.categoryButton, {
                            [styles.showAllCategoriesButton]: id === SHOW_ALL_CATEGORIES_ID,
                            [styles.categorySelected]: categorySelectedId === id,
                        })}
                        onClick={() => onCategoryClick(id)}
                    >
                        {name}
                    </Button>
                ))}
            </div>
        </div>
    );
};

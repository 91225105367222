import { axiosInstance } from 'services';
import { AxiosResponse } from 'axios';

import { API_KEY } from 'constants/apis';

import {
    IFeedActivityDataResponse,
    IMainCountersData,
    IFeedOrganizationSearchedData,
    IFeedItemsDataResponse,
    IGetFeedOrganizationsSearchParams,
    IGetFeedItemsParams,
    IFeedStatsData,
} from 'types/interfaces';

export const FeedApi = {
    getFeedActivityData: (): Promise<AxiosResponse<IFeedActivityDataResponse>> =>
        axiosInstance.get<IFeedActivityDataResponse>('feed/activity'),
    getFeedMainInfo: (): Promise<AxiosResponse<IMainCountersData>> =>
        axiosInstance.get<IMainCountersData>(`organization/${API_KEY}/last-update`),
    getFeedOrganizationsSearch: (
        params: IGetFeedOrganizationsSearchParams,
    ): Promise<AxiosResponse<IFeedOrganizationSearchedData>> =>
        axiosInstance.get<IFeedOrganizationSearchedData>('feed/search/organization', { params }),
    getFeedItems: (params: IGetFeedItemsParams): Promise<AxiosResponse<IFeedItemsDataResponse>> =>
        axiosInstance.get<IFeedItemsDataResponse>('feed/list', { params }),
    getFeedStatsData: (): Promise<AxiosResponse<IFeedStatsData>> => axiosInstance.get<IFeedStatsData>('feed/stats'),
};

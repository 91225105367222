import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { stepperItems } from 'components/SelfListingModal/constants';

import { IStepItems, IStepperProps } from 'types/interfaces';

export const Stepper: FC<IStepperProps> = ({ stepIndex }: IStepperProps): ReactElement => (
    <div className={styles.stepperWrapper}>
        <div className={styles.stepperContent}>
            <div className={styles.line} />
            <div className={styles.stepsList}>
                {stepperItems.map(({ id, description }: IStepItems, index: number) => (
                    <div
                        key={id}
                        className={classNames(styles.step, {
                            [styles.firstStep]: index === 0,
                            [styles.lastStep]: index === stepperItems.length - 1,
                            [styles.activeStep]: index === stepIndex,
                            [styles.complitedStep]: stepIndex > index,
                        })}
                    >
                        <div className={styles.stepCircle} />
                        <h5 className={styles.stepDescription}>{description}</h5>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

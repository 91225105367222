import React, { FC, ReactElement, useState } from 'react';
import { Button } from '@material-ui/core';

import styles from './styles.module.scss';

import { mintScoreLogo } from 'assets';
import { TooltipWrapper } from 'components/TooltipWrapper';

interface MintSectionProps {
    gScoreRanking: string;
    gScore: string;
}

const comingSoonTooltipTitle: ReactElement = (
    <div className={styles.tooltipTitle}>
        <p>
            <b>DeepDAO&apos;s G-score Soulbound Token (SBT)</b> is an attestation of a wallet&apos;s participation in
            DAOs. It takes into account the wallet&apos;s votes, proposals, delegations, and more.
        </p>
        <p>
            <b>The G-score SBT</b> is aggregated across 1000s of DAOs and millions of voters.
        </p>
        <p>
            <b>Why mint?</b>
        </p>
        <p>
            In a word, the G-score SBT is your DAO Resume. As it is connected to a particular wallet, it is non
            transferable.
        </p>
        <p>
            The G-score SBT is useful for DAOs looking to evaluate their own voters and token holders, and job
            applicants. It is a governance primitive, making the onboarding easier and more information-based both for
            DAOs, and people in DAOs.
        </p>
    </div>
);

export const MintSection: FC<MintSectionProps> = ({ gScoreRanking, gScore }: MintSectionProps): ReactElement => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.card}>
                <div className={styles.cardWrapper}>
                    <img className={styles.dolphinImage} src={mintScoreLogo} />
                    <div className={styles.content}>
                        <div className={styles.statsWrapper}>
                            <div className={styles.stats}>
                                <p>
                                    G-score
                                    <br />
                                    Ranking
                                </p>
                                <p>#{gScoreRanking}</p>
                            </div>
                            <div className={styles.stats}>
                                <p>G-score</p>
                                <p>{gScore}%</p>
                            </div>
                        </div>
                        <div className={styles.labelWrapper}>
                            <div className={styles.label}>COMING SOON</div>
                        </div>
                    </div>
                </div>
            </div>
            <TooltipWrapper title={comingSoonTooltipTitle} placement="bottom">
                <p className={styles.infoText}>What is it?</p>
            </TooltipWrapper>
        </div>
    );
};

import React, { FC, ReactElement, useEffect, useState, useRef } from 'react';

import { Button, ClickAwayListener } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles.module.scss';

import { Loader } from 'components';

import { Stepper, OrganizationDetailsForm, GovernanceInfoForm } from './components';

import { ORGANIZATION_DETAILS_STEP_INDEX, GOVERNANCE_STEP_INDEX } from './constants';
import { LISTING_TYPE } from 'constants/selfListing';

import { useAppDispatch, useAppSelector } from 'store';
import { SelfListingActions } from 'store/actions';
import { SelfListingContext } from 'context/selfListingContext';
import { UseSelfListingFormik } from './utils';

export const SelfListingModal: FC = (): ReactElement => {
    const { selfListingDataLoaded, type, listedOrganizationDataLoading } = useAppSelector(
        ({ selfListing }) => selfListing,
    );

    const {
        userOrganizationRole,
        organizationData: { id: organizationId },
    } = useAppSelector(({ organization }) => organization);

    const ref = useRef<null | HTMLDivElement>(null);

    const dispatch = useAppDispatch();

    const { formik, isOrganizationDetailsFormDisabled } = UseSelfListingFormik(type as LISTING_TYPE);

    const [stepIndex, setStepIndex] = useState<number>(ORGANIZATION_DETAILS_STEP_INDEX);

    const onCloseButtonClick = () => {
        dispatch({ type: SelfListingActions.TOGGLE_SELF_LISTING_MODAL_OPEN });
    };

    useEffect(() => {
        if (!selfListingDataLoaded) dispatch(SelfListingActions.getSelfListingData.request());
    }, [dispatch]);

    const nextStep = () => {
        setStepIndex(stepIndex + 1);
        if (ref?.current) ref.current.scrollTop = 0;
    };

    const previousStep = () => {
        setStepIndex(stepIndex - 1);
        if (ref?.current) ref.current.scrollTop = 0;
    };

    useEffect(() => {
        if (type === 'editing' && organizationId)
            dispatch(SelfListingActions.getListedOrganizationData.request({ organizationId }));
    }, [dispatch]);

    return (
        <SelfListingContext.Provider
            value={{
                formik,
                isOrganizationDetailsFormDisabled,
                disabledForEditors: Boolean(type === 'editing' && userOrganizationRole !== 'admin'),
            }}
        >
            {listedOrganizationDataLoading && <Loader />}
            <div className={styles.modalContainer}>
                <ClickAwayListener onClickAway={onCloseButtonClick}>
                    <div className={styles.modal}>
                        <div className={styles.modalHeader}>
                            <div className={styles.titleWithCloseBtn}>
                                <h3 className={styles.title}>List Your organization with us</h3>

                                <Button className={styles.closeButton} onClick={onCloseButtonClick}>
                                    <CloseIcon className={styles.closeIcon} />
                                </Button>
                            </div>

                            <Stepper stepIndex={stepIndex} />
                        </div>

                        <div className={styles.modalBody} ref={ref}>
                            <form onSubmit={formik.handleSubmit}>
                                {stepIndex === ORGANIZATION_DETAILS_STEP_INDEX && (
                                    <OrganizationDetailsForm nextStep={nextStep} />
                                )}
                                {stepIndex === GOVERNANCE_STEP_INDEX && (
                                    <GovernanceInfoForm previousStep={previousStep} />
                                )}
                            </form>
                        </div>
                    </div>
                </ClickAwayListener>
            </div>
        </SelfListingContext.Provider>
    );
};

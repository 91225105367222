import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import { Button } from '@material-ui/core';

import styles from './styles.module.scss';

import { SelfListingInput, Loader, HrefLink } from 'components';

import { DEEP_DAO_MAIL } from 'constants/deepDaoLinks';

import { IPremiumLoginFormProps, IPremiumLoginFormValues } from 'types/interfaces';

export const PremiumLoginForm: FC<IPremiumLoginFormProps> = ({
    title,
    description,
    submitButtonText,
    formValues,
    onSubmit,
    error,
    loading,
    disabled,
    isNavigationBackAvailable = false,
    formTitle,
    children,
}: IPremiumLoginFormProps): ReactElement => (
    <div className={styles.wrapper}>
        <div className={styles.container}>
            <div className={styles.mainContent}>
                {isNavigationBackAvailable && (
                    <div className={styles.navigationBackBlock}>
                        <Link to="/premium_account_settings" className={styles.accountSettingsLink}>
                            <ArrowBackIos className={styles.arrowIcon} />
                            <span className={styles.text}>Settings</span>
                        </Link>
                    </div>
                )}
                <h1 className={styles.title}>{title}</h1>
                {description && <h6 className={styles.description}>{description}</h6>}
                <div className={styles.formWrapper}>
                    {formTitle && <h6 className={styles.formTitle}>{formTitle}</h6>}
                    <form className={styles.form} onSubmit={onSubmit}>
                        <div className={styles.fieldsWrapper}>
                            {formValues.map(
                                ({
                                    id,
                                    name,
                                    placeholder,
                                    value,
                                    onChange,
                                    touched,
                                    error,
                                    onTouch,
                                }: IPremiumLoginFormValues) => (
                                    <SelfListingInput
                                        key={id}
                                        name={name}
                                        placeholder={placeholder}
                                        value={value}
                                        onChange={onChange}
                                        touched={touched}
                                        error={error}
                                        onTouch={onTouch}
                                    />
                                ),
                            )}
                        </div>
                        <Button className={styles.submitBtn} type="submit" disabled={disabled}>
                            {loading ? <Loader className={styles.loader} size={30} /> : `${submitButtonText}`}
                        </Button>

                        {error && <h6 className={styles.errorText}>{error}</h6>}
                    </form>
                </div>
            </div>
            <div className={styles.optionsBlock}>
                {children}
                <p className={styles.infoText}>
                    Need assistance?{' '}
                    <HrefLink href={DEEP_DAO_MAIL} disableTarget className={styles.mail}>
                        <span>Contact Us</span>
                    </HrefLink>
                </p>
            </div>
        </div>
    </div>
);

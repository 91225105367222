import React, { FC, ReactElement, memo } from 'react';

import styles from './styles.module.scss';

export const TableHeader: FC = memo((): ReactElement => {
    return (
        <div className={styles.tableHeader}>
            <div>
                <span>Date</span>
            </div>
            <div>
                <span>Plan</span>
            </div>
            <div>
                <span>Type</span>
            </div>
            <div>
                <span>Payment method</span>
            </div>
            <div>
                <span>Download</span>
            </div>
        </div>
    );
});

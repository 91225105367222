import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { FormatUtils } from 'utils';

import { ICustomLineGraphTooltipProps } from 'types/interfaces';

export const CustomLineGraphTooltip: FC<ICustomLineGraphTooltipProps> = ({
    payload,
    valueName,
}: ICustomLineGraphTooltipProps): ReactElement => {
    const { value, label } = payload?.[0]?.payload || {};

    const tooltipValue = FormatUtils.checkIfDataNotExist(value)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberShort(value, 1);

    return (
        <div className={styles.tooltipContainer}>
            <h6>Date: {label}</h6>
            <h6>
                {valueName}: {tooltipValue}
            </h6>
        </div>
    );
};

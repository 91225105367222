import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { HeaderItemWithSorting } from 'components';
import { ITableHeaderWithSortingProps, IFollowPeopleTableProps } from 'types/interfaces';

import { PEOPLE_PARAMS } from '../../constants';

import styles from './styles.module.scss';

export const TableHeader: FC<ITableHeaderWithSortingProps & Pick<IFollowPeopleTableProps, 'isFollowersTable'>> = ({
    sortedParamName,
    sortByParam,
    isFollowersTable,
}): ReactElement => (
    <div className={classNames(styles.tableHeader, { [styles.followersTableHeader]: isFollowersTable })}>
        <div className={styles.people}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="People"
                sortedParamName={sortedParamName}
                paramName={PEOPLE_PARAMS.PEOPLE}
            />
        </div>
        <div className={styles.score}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="DAO Score"
                sortedParamName={sortedParamName}
                paramName={PEOPLE_PARAMS.DAO_SCORE}
            />
        </div>
        <div className={styles.following}>
            <div>
                <span>Following</span>
            </div>
        </div>
        {isFollowersTable ? (
            <div className={styles.remove}>
                <div>
                    <span>Remove</span>
                </div>
            </div>
        ) : (
            <div className={styles.notifications}>
                <div>
                    <span>Notifications</span>
                </div>
            </div>
        )}
    </div>
);

import React, { FC, ReactElement } from 'react';

import { ICustomSvgGradientProps, ICustomGradient } from 'types/interfaces';

export const CustomSvgGradient: FC<ICustomSvgGradientProps> = ({
    id,
    rotate = 0,
    data,
}: ICustomSvgGradientProps): ReactElement => {
    return (
        <defs>
            <linearGradient id={id} gradientTransform={`rotate(${rotate})`}>
                {data.map(({ color, offset, opacity = 1 }: ICustomGradient, index: number) => (
                    <stop key={`color-${index}`} offset={offset} stopColor={color} stopOpacity={opacity} />
                ))}
            </linearGradient>
        </defs>
    );
};

import React, { MouseEvent, ChangeEvent, FC, ReactElement } from 'react';

import { Button } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ALERT_TYPES, ERROR_MESSAGES } from 'constants/alert';

import { useAppSelector, useAppDispatch } from 'store';
import { AlertActions } from 'store/actions';
import { readFile } from 'helpers/fileHelpers';

import { IAvatarUploaderProps } from 'types/interfaces';

export const AvatarUploader: FC<IAvatarUploaderProps> = ({
    peopleContactId,
    setUploadedImageSrc,
    className,
    title,
}: IAvatarUploaderProps): ReactElement => {
    const { updateAvatarLoading } = useAppSelector(({ user }) => user);
    const dispatch = useAppDispatch();

    const updateAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;

        if (!files) return;

        if (files[0].size > 2 * 1024 * 1024) {
            dispatch({
                type: AlertActions.SHOW_ALERT,
                payload: {
                    title: ERROR_MESSAGES.AVATAR_SIZE_LIMIT,
                    type: ALERT_TYPES.ERROR,
                },
            });
            return;
        }

        const data = new FormData();
        data.append('file', files[0]);

        if (!peopleContactId) return;

        const imageDataUrl = (await readFile(files[0])) as string;
        setUploadedImageSrc(imageDataUrl);
    };

    const handleClick = (event: MouseEvent<HTMLInputElement>) => {
        const { target } = event as any;
        if (target?.value) target.value = '';
    };

    return (
        <div className={classNames(styles.uploaderWrapper, className)}>
            <Button
                className={classNames(styles.avatarUploadButton, {
                    [styles.uploadActive]: updateAvatarLoading && !title,
                })}
                component="label"
            >
                <PhotoCamera className={styles.cameraIcon} />
                <input
                    type="file"
                    accept="image/jpeg, image/jpg, image/png, image/svg+xml"
                    hidden
                    onChange={updateAvatar}
                    onClick={handleClick}
                />
                {title && (
                    <span
                        className={classNames(styles.title, {
                            [styles.uploadLoading]: updateAvatarLoading,
                        })}
                    >
                        {updateAvatarLoading ? 'loading...' : title}
                    </span>
                )}
            </Button>
        </div>
    );
};

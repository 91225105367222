import React, { FC, ReactElement, memo, useMemo } from 'react';

import classNames from 'classnames';

import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './styles.module.scss';

import { DotsLoader } from 'components';
import { NotificationItems } from './components';

import { INotificationData, INotificationsListWrapperProps } from 'types/interfaces';

const MIN_NOTIFICATIONS_FOR_SCROLLING = 7;
const NOTIFICATIONS_BLOCK_HEIGHT = 460;

export const NotificationsListWrapper: FC<INotificationsListWrapperProps> = memo(
    ({
        newNotifications,
        oldNotifications,
        loadMoreNotifications,
        hasMoreNotifications,
    }: INotificationsListWrapperProps): ReactElement => {
        const combinedNotificationsDataLength = useMemo(
            () => [...oldNotifications, ...newNotifications].length,
            [newNotifications, oldNotifications],
        );

        return (
            <div className={styles.notificationsListWrapper}>
                <InfiniteScroll
                    next={loadMoreNotifications}
                    dataLength={combinedNotificationsDataLength}
                    hasMore={hasMoreNotifications}
                    loader={combinedNotificationsDataLength ? <DotsLoader className={styles.dotsLoader} /> : null}
                    className={styles.notificationsListContainer}
                    height={
                        combinedNotificationsDataLength > MIN_NOTIFICATIONS_FOR_SCROLLING
                            ? NOTIFICATIONS_BLOCK_HEIGHT
                            : 'auto'
                    }
                >
                    {newNotifications.length > 0 && (
                        <>
                            <h5 className={styles.title}>new</h5>
                            {newNotifications.map((items: INotificationData) => (
                                <NotificationItems key={items.id} {...items} />
                            ))}
                        </>
                    )}

                    {oldNotifications.length > 0 && (
                        <>
                            <h5 className={classNames(styles.title, styles.oldNotificationsTitle)}>old</h5>
                            {oldNotifications.map((items: INotificationData) => (
                                <NotificationItems key={items.id} {...items} />
                            ))}
                        </>
                    )}
                </InfiniteScroll>

                {oldNotifications.length === 0 && newNotifications.length === 0 && (
                    <span className={styles.emptyNotificationsMessage}>You do not have any notifications yet</span>
                )}
            </div>
        );
    },
);

import React, { FC, ReactElement, useState, useEffect, MouseEvent } from 'react';

import { useMediaQuery } from '@material-ui/core';

import { TabsController, TopOrganizationsTable } from './components';

import { SHOW_ALL_CATEGORIES_ID } from './constants';

import { useAppDispatch, useAppSelector } from 'store';
import { mixpanelService } from 'services';
import { OrganizationsActions } from 'store/actions';

import { createOrganizationsTableData, organizationsTableSearch } from './utils';

import { IOrganizationCategory, ISortedParam } from 'types/interfaces';

export const OrganizationsTables: FC = (): ReactElement => {
    const { organizationsData, loading, organizationsCategories, orderBy, paginationOrderDescending } = useAppSelector(
        ({ organizations }) => organizations,
    );

    const dispatch = useAppDispatch();

    const [tabSearchValue, setTabSearchValue] = useState<string>('');
    const [categorySelectedId, setCategorySelectedId] = useState<number>(SHOW_ALL_CATEGORIES_ID);
    const [categoriesContainerOpen, setCategoriesContainersOpen] = useState<boolean>(true);

    const [organizationsSortedParam, setOrganizationsSortedParam] = useState<ISortedParam>({
        paramName: orderBy,
        descending: paginationOrderDescending,
    });

    const isMobile = useMediaQuery('(max-width:575.98px)');

    useEffect(() => {
        isMobile && setCategoriesContainersOpen(false);
    }, [isMobile]);

    const toggleCategoriesOpen = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setCategoriesContainersOpen(!categoriesContainerOpen);
        mixpanelService.track(mixpanelService.eventsGenerator.onCategoryOpenClickEvent());

        if (categoriesContainerOpen && categorySelectedId !== SHOW_ALL_CATEGORIES_ID) {
            setCategorySelectedId(SHOW_ALL_CATEGORIES_ID);
        }
    };

    const onCategoryClick = (categoryId: number) => {
        categoryId !== categorySelectedId && setCategorySelectedId(categoryId);
        const categoryName = organizationsCategories.find(({ id }: IOrganizationCategory) => id === categoryId)?.name;
        categoryName && mixpanelService.track(mixpanelService.eventsGenerator.onCategoryClickEvent(categoryName));
    };

    const sortOrganizationsDataByParam = (param: string) => {
        const paginationOrderDescending =
            organizationsSortedParam.paramName === param ? !organizationsSortedParam.descending : true;

        dispatch(
            OrganizationsActions.getMoreOrganizationsData.request({
                orderBy: param,
                descending: paginationOrderDescending,
                isSorting: true,
            }),
        );

        setOrganizationsSortedParam({
            paramName: param,
            descending: paginationOrderDescending,
        });
    };

    return (
        <div>
            <TabsController
                onTabSearch={setTabSearchValue}
                tabSearchValue={tabSearchValue}
                toggleCategoriesOpen={toggleCategoriesOpen}
                categoriesContainerOpen={categoriesContainerOpen}
                loading={loading}
            />

            <>
                <TopOrganizationsTable
                    data={organizationsTableSearch(
                        createOrganizationsTableData([...organizationsData], categorySelectedId),
                        tabSearchValue,
                    )}
                    sortedParam={organizationsSortedParam}
                    sortByParam={sortOrganizationsDataByParam}
                    loading={loading}
                    categoriesContainerOpen={categoriesContainerOpen}
                    onCategoryClick={onCategoryClick}
                    categorySelectedId={categorySelectedId}
                    toggleCategoriesOpen={toggleCategoriesOpen}
                    onTabSearch={setTabSearchValue}
                    tabSearchValue={tabSearchValue}
                />
            </>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { HeaderItemWithSorting } from 'components';

import { DECISIONS_PARAMS } from '../../constants';

import { ITableHeaderWithSortingProps } from 'types/interfaces';

export const TableHeader: FC<ITableHeaderWithSortingProps> = ({
    sortedParamName,
    sortByParam,
}: ITableHeaderWithSortingProps): ReactElement => (
    <div className={styles.tableHeader}>
        <div className={styles.rank}>
            <div>
                <span>#</span>
            </div>
        </div>
        <div className={styles.title}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="title"
                sortedParamName={sortedParamName}
                paramName={DECISIONS_PARAMS.TITLE_PARAM}
            />
        </div>
        <div className={styles.date}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="date"
                sortedParamName={sortedParamName}
                paramName={DECISIONS_PARAMS.DATE_PARAM}
            />
        </div>
        <div className={styles.proposedBy}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="proposed by"
                sortedParamName={sortedParamName}
                paramName={DECISIONS_PARAMS.PROPOSED_BY_PARAM}
            />
        </div>
        <div className={styles.outcomeStatus}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name={`outcome/ ${'\n'}status`}
                sortedParamName={sortedParamName}
                paramName={DECISIONS_PARAMS.STATUS_PARAM}
            />
        </div>
        <div className={styles.quorum}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="quorum"
                sortedParamName={sortedParamName}
                paramName={DECISIONS_PARAMS.QUORUM}
            />
        </div>
        <div className={styles.votes}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="votes"
                sortedParamName={sortedParamName}
                paramName={DECISIONS_PARAMS.VOTES_PARAM}
            />
        </div>
        <div className={styles.forShares}>
            <div>
                <span>for</span>
            </div>
        </div>
        <div className={styles.againstShares}>
            <div>
                <span>against</span>
            </div>
        </div>
        <div className={styles.abstainShares}>
            <div>
                <span>abstain</span>
            </div>
        </div>
    </div>
);

import { axiosInstance } from 'services';
import { AxiosResponse } from 'axios';

import { API_KEY } from 'constants/apis';

import { IPeopleData, IPeopleSortedParams, IPeopleStatsPesponse, IMainCountersData } from 'types/interfaces';

export const PeopleApi = {
    getPeopleData: (params: IPeopleSortedParams): Promise<AxiosResponse<IPeopleData[]>> =>
        axiosInstance.get<IPeopleData[]>('/people/top', { params }),
    getPeopleStatsData: (): Promise<AxiosResponse<IPeopleStatsPesponse>> =>
        axiosInstance.get<IPeopleStatsPesponse>('/people/stats'),
    getPeopleMainInfo: (): Promise<AxiosResponse<IMainCountersData>> =>
        axiosInstance.get<IMainCountersData>(`organization/${API_KEY}/last-update`),
};

export const validPhoneNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
export const validUrl =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w\-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const validateInputAddresses = (address: string): boolean => {
    return /^(0x){1}[0-9a-fA-F]{40}$/i.test(address);
};

export const mastercardNumbersRegEx =
    /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/;
export const visaNumbersRegEx = /^4[0-9]{12}(?:[0-9]{3})?$/;
export const amexNumbersRegEx = /^3[47][0-9]{13}$/;

import React from 'react';

import { data } from './customTitleAndDesctiptions';

export const getOrganizationMeta = (tabTitle = 'DeepDAO', name?: string): JSX.Element[] => {
    const customMeta = data.find(({ organization }) => organization === name);
    const cleanTabTitle = tabTitle.replace(/<\/?[^>]+(>|$)/g, '');

    const title = customMeta?.title ?? cleanTabTitle;
    const description = customMeta?.description;

    const metaTitle = title ? <title key="title">{title}</title> : null;
    const metaDescription = description ? (
        <meta key="name-description" name="description" content={description} />
    ) : null;
    const metaOGTitle = title ? <meta key="property-og:title" property="og:title" content={title} /> : null;
    const metaOGDescription = description ? (
        <meta key="property-og:description" property="og:description" content={description} />
    ) : null;
    const metaTwitterTitle = title ? <meta key="name-twitter:title" property="twitter:title" content={title} /> : null;
    const metaTwitterDescription = description ? (
        <meta key="name-twitter:description" property="twitter:description" content={description} />
    ) : null;

    return [
        metaTitle,
        metaDescription,
        metaOGTitle,
        metaOGDescription,
        metaTwitterTitle,
        metaTwitterDescription,
    ].filter(Boolean) as JSX.Element[];
};

import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';

import styles from './styles.module.scss';
import { useContentful } from 'react-contentful';
import {
    IContentfulResponse,
    IKnowledgeCenterPost,
    IKnowledgeCenterPostField,
    serializeContentfulKnowledgeCenterPosts,
} from '../FAQPage/helper';
import { useHistory, useParams } from 'react-router-dom';
import { facebookGrey, linkedInGrey, twitterGrey } from '../../assets';
import copy from 'copy-to-clipboard';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import LinkIcon from '@material-ui/icons/Link';
import { mixpanelService } from '../../services';

// Create a bespoke renderOptions object to target BLOCKS.EMBEDDED_ENTRY (linked block entries e.g. code blocks)
// INLINES.EMBEDDED_ENTRY (linked inline entries e.g. a reference to another blog post)
// and BLOCKS.EMBEDDED_ASSET (linked assets e.g. images)

const renderOptions = {
    renderNode: {
        [INLINES.EMBEDDED_ENTRY]: (node: any) => {
            // target the contentType of the EMBEDDED_ENTRY to display as you need
            if (node.data.target.sys.contentType.sys.id === 'blogPost') {
                return <a href={`/blog/${node.data.target.fields.slug}`}> {node.data.target.fields.title}</a>;
            }
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
            // target the contentType of the EMBEDDED_ENTRY to display as you need
            if (node.data.target.sys.contentType.sys.id === 'codeBlock') {
                return (
                    <pre>
                        <code>{node.data.target.fields.code}</code>
                    </pre>
                );
            }

            if (node.data.target.sys.contentType.sys.id === 'videoEmbed') {
                return (
                    <iframe
                        src={node.data.target.fields.embedUrl}
                        height="100%"
                        width="100%"
                        frameBorder="0"
                        scrolling="no"
                        title={node.data.target.fields.title}
                        allowFullScreen={true}
                    />
                );
            }
        },

        [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
            // render the EMBEDDED_ASSET as you need
            return (
                <img
                    src={`https://${node.data.target.fields.file.url}`}
                    //  height={node.data.target.fields.file.details.image.height}
                    // width={node.data.target.fields.file.details.image.width}
                    alt={node.data.target.fields.description}
                />
            );
        },
    },
};

export const KnowledgeDetailsPage = (): ReactElement => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    // TODO: spinner on loading contentful data
    const { data, loading } = useContentful({
        contentType: 'knowledgeCenterPost',
    });

    const posts = useMemo(
        () =>
            data ? serializeContentfulKnowledgeCenterPosts(data as IContentfulResponse<IKnowledgeCenterPostField>) : [],
        [data],
    );

    const currentPost = useMemo(() => (posts.find(({ id: _id }) => _id === id) || {}) as IKnowledgeCenterPost, [posts]);
    const featuredPosts = useMemo(() => {
        const randomlySorted = posts.sort(() => Math.random() - 0.5);

        return randomlySorted.slice(1, 4);
    }, [posts]);

    const handleClickPost = useCallback((id: string) => history.push(`/knowledge/${id}`), [id]);
    const handleShareLink = useCallback(() => copy(window.location.href, { message: 'Copied link to page!' }), []);

    const content = useMemo(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        () => (currentPost ? documentToReactComponents(currentPost?.content, renderOptions) : null),
        [currentPost],
    );

    useEffect(() => {
        if (currentPost?.title) {
            mixpanelService.track(mixpanelService.eventsGenerator.knowledgeCenterArticle(currentPost.title));
        }
    }, [currentPost]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.blurBox} />

            <div className={styles.header}>
                <div className={styles.category}>
                    <div className={styles.categoryWrapper}>
                        <RouterLink to="/knowledge" style={{ color: 'inherit' }}>
                            <ArrowBackIcon className={styles.titleWrapper} />
                        </RouterLink>

                        <span className={styles.category}>{currentPost.category}</span>
                    </div>

                    <LinkIcon className={styles.shareLinkIcon} onClick={handleShareLink} />
                </div>

                <div className={styles.headerWrapper}>
                    <div className={styles.postTitleWrapper}>
                        <span className={styles.postHeader}>{currentPost.title}</span>
                        <LinkIcon className={styles.shareLinkIcon} onClick={handleShareLink} />
                    </div>
                    <div className={styles.socialsWrapper}>
                        {currentPost.twitter && (
                            <Link href={currentPost.twitter} target="_blank">
                                <img src={twitterGrey} alt="twitter" className={styles.firstSocial} />
                            </Link>
                        )}
                        {currentPost.linkedIn && (
                            <Link href={currentPost.linkedIn} target="_blank">
                                <img src={linkedInGrey} alt="linkedIn" />
                            </Link>
                        )}
                        {currentPost.facebook && (
                            <Link href={currentPost.facebook} target="_blank">
                                <img src={facebookGrey} alt="facebook" />
                            </Link>
                        )}
                        <img
                            src={twitterGrey}
                            alt="twitter"
                            onClick={handleShareLink}
                            className={styles.cursorPointer}
                        />
                    </div>
                </div>
                <div className={styles.authorWrapper}>
                    <img src={currentPost.authorPhoto} className={styles.authorPhoto} alt="" />
                    <span className={styles.authorName}>{currentPost.author}</span>
                </div>
            </div>

            <div className={styles.postWrapper}>
                <div className={styles.mainLine}>
                    <img src={currentPost.mainImage} alt={currentPost.title} className={styles.image} />
                    <div className={styles.titleMainLineWrapper}>
                        <span className={styles.titleMainLine}>{currentPost.description}</span>
                    </div>
                </div>
            </div>

            <div className={styles.contentWrapper}>{content}</div>

            <div className={styles.recommendations}>
                <div className={styles.header}>
                    <h5>You Might Also Like</h5>
                </div>

                <div className={styles.featuredPosts}>
                    {featuredPosts.map((post) => (
                        <PostPreviewSmall key={1} {...post} handleClickPost={handleClickPost} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const PostPreviewSmall = (props: any): ReactElement => {
    const { id, previewImage, title, category, description, handleClickPost } = props;

    return (
        <div className={styles.postPreviewSmall} onClick={() => handleClickPost(id)}>
            <img src={previewImage} alt={title} className={styles.image} />
            <span className={styles.category}>{category}</span>
            <span className={styles.title}>{title}</span>
            <span className={styles.description}>{description}</span>
        </div>
    );
};

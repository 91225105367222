import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TableDataNotFound } from 'components';
import { TableHeader, TableRow } from './components';

import { IVotesTableData, IUserVoteData } from 'types/interfaces';

export const VotesTable: FC<IVotesTableData> = ({ votesData }: IVotesTableData): ReactElement => (
    <>
        {votesData ? (
            <>
                <TableHeader />

                <div className={styles.rowsWrapper}>
                    {votesData.map((item: IUserVoteData, index: number) => (
                        <TableRow key={item.voteNativeId} {...item} index={index} />
                    ))}
                </div>
            </>
        ) : (
            <TableDataNotFound forTable removeBoxShadow />
        )}
    </>
);

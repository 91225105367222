import { createApiAction } from 'helpers';

export const GET_ORGANIZATIONS_DATA = 'GET_ORGANIZATIONS_DATA';
export const GET_ORGANIZATIONS_DATA_SUCCESS = 'GET_ORGANIZATIONS_DATA_SUCCESS';
export const GET_ORGANIZATIONS_DATA_FAILURE = 'GET_ORGANIZATIONS_DATA_FAILURE';

export const CLEAR_ORGANIZATIONS_DATA = 'CLEAR_ORGANIZATIONS_DATA';

export const GET_MORE_ORGANIZATIONS_DATA = 'GET_MORE_ORGANIZATIONS_DATA';
export const GET_MORE_ORGANIZATIONS_DATA_SUCCESS = 'GET_MORE_ORGANIZATIONS_DATA_SUCCESS';
export const GET_MORE_ORGANIZATIONS_DATA_FAILURE = 'GET_MORE_ORGANIZATIONS_DATA_FAILURE';

export const GET_LAST_UPDATE_INFO = 'GET_LAST_UPDATE_INFO';
export const GET_LAST_UPDATE_INFO_SUCCESS = 'GET_LAST_UPDATE_INFO_SUCCESS';
export const GET_LAST_UPDATE_INFO_FAILURE = 'GET_LAST_UPDATE_INFO_FAILURE';

export const SET_ORGANIZATIONS_TABLE_SORT_PARAM = 'SET_ORGANIZATIONS_TABLE_SORT_PARAM';

export const getOrganizationsData = createApiAction(GET_ORGANIZATIONS_DATA);
export const getMoreOrganizationsData = createApiAction(GET_MORE_ORGANIZATIONS_DATA);
export const getLastUpdateInfo = createApiAction(GET_LAST_UPDATE_INFO);

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TableCell } from 'components';

import { COALITIONS_PARAMS } from '../../constants';
import classNames from 'classnames';
import { FormatUtils } from '../../../../../../../../../../utils';
import { DATA_NOT_FOUND } from '../../../../../../../../../../constants';
import { ICoalitionItemsProps } from '../../../../../../../../../../types/interfaces';

export const CoalitionsMetadata: FC<ICoalitionItemsProps> = ({
    votedTogether,
    votingPower,
    votingPowerPercentage,
    delegatorsNumber,
    rank,
    sortedParamName,
    handleMouseEnter,
    handleMouseLeave,
    isHovered,
    votingPowerCirculatingSupplyPercentage,
    votingPowerNeededForWinningPercentage,
    votingPower6MonthWinningPercentage,
}: ICoalitionItemsProps): ReactElement => {
    const delegatorsNumberValue = FormatUtils.checkIfDataNotExist(delegatorsNumber)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberWithCommas(Number(delegatorsNumber), 0);
    const votingPowerValue = FormatUtils.checkIfDataNotExist(votingPower)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberWithCommas(Number(votingPower), 1);
    const votingPowerPercentageValue = FormatUtils.checkIfDataNotExist(votingPowerPercentage)
        ? DATA_NOT_FOUND
        : `${FormatUtils.formatNumberWithCommas(Number(votingPowerPercentage), 2)} %`;
    const circulatingSupplyPercentageValue = FormatUtils.checkIfDataNotExist(votingPowerCirculatingSupplyPercentage)
        ? DATA_NOT_FOUND
        : `${FormatUtils.formatNumberWithCommas(Number(votingPowerCirculatingSupplyPercentage), 2)} %`;
    const neededForWinningPercentageValue = FormatUtils.checkIfDataNotExist(votingPowerNeededForWinningPercentage)
        ? DATA_NOT_FOUND
        : `${FormatUtils.formatNumberWithCommas(Number(votingPowerNeededForWinningPercentage), 2)} %`;
    const sixMWinningPercentageValue = FormatUtils.checkIfDataNotExist(votingPower6MonthWinningPercentage)
        ? DATA_NOT_FOUND
        : `${FormatUtils.formatNumberWithCommas(Number(votingPower6MonthWinningPercentage), 2)} %`;

    return (
        <div
            className={classNames(styles.tableRow, isHovered ? styles.rowHover : null)}
            onMouseEnter={() => handleMouseEnter(rank - 1)}
            onMouseLeave={handleMouseLeave}
        >
            <TableCell
                className={styles.votedTogether}
                isHighlighted={sortedParamName === COALITIONS_PARAMS.VOTED_TOGETHER_PARAM}
            >
                <span>{votedTogether}</span>
            </TableCell>
            <TableCell
                className={styles.votingPower}
                isHighlighted={sortedParamName === COALITIONS_PARAMS.VOTING_POWER}
            >
                <span>{votingPowerValue}</span>
            </TableCell>
            <TableCell
                className={styles.delegatorsNumber}
                isHighlighted={sortedParamName === COALITIONS_PARAMS.DELEGATORS_NUMBER}
            >
                <span>{delegatorsNumberValue}</span>
            </TableCell>
            <TableCell
                className={styles.votingPowerPercentage}
                isHighlighted={sortedParamName === COALITIONS_PARAMS.VOTING_POWER_PERCENTAGE}
            >
                <span>{votingPowerPercentageValue}</span>
            </TableCell>
            <TableCell
                className={styles.circulatingSupplyPercentage}
                isHighlighted={sortedParamName === COALITIONS_PARAMS.CIRCULATING_SUPPLY_PERCENTAGE}
            >
                <span>{circulatingSupplyPercentageValue}</span>
            </TableCell>
            <TableCell
                className={styles.sixMWinningPercentage}
                isHighlighted={sortedParamName === COALITIONS_PARAMS.SIX_M_WINNING_PERCENTAGE}
            >
                <span>{sixMWinningPercentageValue}</span>
            </TableCell>
        </div>
    );
};

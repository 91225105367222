import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

import { IVotingTokenMetrics } from 'types/interfaces';
import { FormatUtils } from 'utils';
import { DATA_NOT_FOUND } from 'constants/dataNotFound';

export const TableRow = ({
    timePeriod,
    tokenMetrics,
}: {
    timePeriod: 'Current' | '7 Days' | '30 Days' | '6 Months' | '1 Year';
    tokenMetrics: IVotingTokenMetrics;
}): ReactElement => {
    const tokenValue = FormatUtils.checkIfDataNotExist(tokenMetrics.tokenValue)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberShort(Number(tokenMetrics.tokenValue), 2);
    const totalSupply = FormatUtils.checkIfDataNotExist(tokenMetrics.totalSupply)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberShort(Number(tokenMetrics.totalSupply), 2);
    const circulatingSupply = FormatUtils.checkIfDataNotExist(tokenMetrics.circulatingSupply)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberShort(Number(tokenMetrics.circulatingSupply), 2);
    const circulatingSupplyPercentage = FormatUtils.checkIfDataNotExist(tokenMetrics.tokenPercentOfCirculatingSupply)
        ? DATA_NOT_FOUND
        : `${FormatUtils.formatNumberWithCommas(Number(tokenMetrics.tokenPercentOfCirculatingSupply), 2)} %`;
    const totalSupplyPercentage =
        tokenMetrics?.totalSupply && tokenMetrics.tokenValue
            ? `${FormatUtils.formatNumberWithCommas((tokenMetrics.tokenValue / tokenMetrics.totalSupply) * 100, 2)} %`
            : DATA_NOT_FOUND;
    const holders = FormatUtils.checkIfDataNotExist(tokenMetrics.numberOfTokenHolders)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberWithCommas(Number(tokenMetrics.numberOfTokenHolders), 0);

    return (
        <div className={styles.tableRow}>
            <div className={styles.totalSupply}>{totalSupply}</div>
            <div className={styles.circulatingSupply}>{circulatingSupply}</div>
            <div className={styles.tokenValue}>{tokenValue}</div>
            <div className={styles.circulatingSupplyPercentage}>{circulatingSupplyPercentage}</div>
            <div className={styles.totalSupplyPercentage}>{totalSupplyPercentage}</div>
            <div className={styles.holders}>{holders}</div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import classNames from 'classnames';

import { TooltipWrapper } from 'components';

import styles from './styles.module.scss';

interface IInfoCircleProps {
    tooltipText?: string;
    className?: string;
}

export const InfoCircle: FC<IInfoCircleProps> = ({ tooltipText, className }: IInfoCircleProps): ReactElement => (
    <TooltipWrapper title={tooltipText}>
        <InfoOutlinedIcon className={classNames(styles.infoIcon, className)} />
    </TooltipWrapper>
);

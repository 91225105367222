import { call, put, select, takeLatest } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { DaoTokensActions } from 'store/actions';

import { DaoTokensApi } from '../apis';

function* getDaoTokensWorker(): SagaIterator {
    try {
        const { orderBy, paginationOrderDescending } = yield select(({ daoTokens }) => daoTokens);

        const { data, hasError, error } = yield call(DaoTokensApi.getDaoTokens, {
            orderBy,
            order: paginationOrderDescending ? 'desc' : 'asc',
        });

        if (hasError)
            yield put(
                DaoTokensActions.getDaoTokens.failure({
                    error,
                }),
            );
        else
            yield put(
                DaoTokensActions.getDaoTokens.success({
                    daoTokens: data.daoTokens,
                }),
            );
    } catch (e) {
        yield put(DaoTokensActions.getDaoTokens.failure(e));
    }
}

// TODO: in progress
// function* getMoreDaoTokensWorker({ payload }: IGetMoreOrganizationsAction): SagaIterator {
//     try {
//         const { currentPage, isEndOfDaoTokensList, orderBy, paginationOrderDescending } = yield select(
//             ({ daoTokens }) => daoTokens,
//         );
//
//         if (isEndOfDaoTokensList) {
//             return;
//         }
//
//         const nextPage = payload?.isSorting ? 0 : currentPage + 1;
//         const offset = payload?.isSorting ? 0 : nextPage * 50;
//
//         const { data, hasError, error } = yield call(DaoTokensApi.getDaoTokens, {
//             offset,
//             limit: 50,
//             orderBy: payload?.orderBy || orderBy,
//             order: (payload ? payload?.descending : paginationOrderDescending) ? 'desc' : 'asc',
//         });
//
//         if (hasError)
//             yield put(
//                 DaoTokensActions.getMoreDaoTokens.failure({
//                     error,
//                 }),
//             );
//         else
//             yield put(
//                 DaoTokensActions.getMoreDaoTokens.success({
//                     daoTokens: data.daoTokens,
//                     orderBy: payload?.orderBy || orderBy,
//                     paginationOrderDescending: payload ? payload?.descending : paginationOrderDescending,
//                     isSorting: Boolean(payload?.isSorting),
//                 }),
//             );
//     } catch (e) {
//         yield put(DaoTokensActions.getMoreDaoTokens.failure(e));
//     }
// }

export const daoTokensSaga = function* (): SagaIterator {
    yield takeLatest(DaoTokensActions.GET_DAO_TOKENS, getDaoTokensWorker);
    // yield takeLatest(DaoTokensActions.GET_MORE_DAO_TOKENS, getMoreDaoTokensWorker);
};

import React, { FC, ReactElement, useState, useRef, useEffect } from 'react';

import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ISearchFieldProps } from 'types/interfaces';

export const SearchField: FC<ISearchFieldProps> = ({
    forTable = false,
    inputValue,
    setInputValue,
    setIsInputOpen,
    title,
    customInputStyles,
    customButtonStyles,
}: ISearchFieldProps): ReactElement => {
    const [inputOpen, setInputOpen] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setIsInputOpen && setIsInputOpen(inputOpen);
    }, [inputOpen]);

    return (
        <div className={classNames(styles.searchContainer, { [styles.searchContainerTable]: forTable })}>
            <Button
                className={classNames(styles.searchButton, customButtonStyles)}
                disableRipple
                onClick={() => {
                    setInputOpen(!inputOpen);
                    setInputValue('', !inputOpen);
                    inputRef?.current && inputRef?.current.focus();
                }}
            >
                <SearchIcon className={styles.searchIcon} />
                {title && !inputOpen && <span className={styles.title}>{title}</span>}
            </Button>

            <div className={styles.inputContainer}>
                <input
                    className={classNames(styles.input, {
                        [styles.inputOpen]: inputOpen,
                        [customInputStyles || '']: customInputStyles && inputOpen,
                    })}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    ref={inputRef}
                />

                <Button
                    className={classNames(styles.clearFieldButton, { [styles.clearButtonActive]: inputValue })}
                    disableRipple
                    onClick={() => setInputValue('')}
                >
                    <CloseIcon className={styles.clearFieldIcon} />
                </Button>
            </div>
        </div>
    );
};

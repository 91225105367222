import React, { FC, ReactElement, useCallback, useState, useEffect } from 'react';

import MessageIcon from '@material-ui/icons/Message';

import styles from './styles.module.scss';

import { DiscussionTooltip } from 'components';
import { VotingResult } from '../VotingResult';
import { VotingButton } from '../VotingButton';

import { VOTING_OPTIONS } from 'constants/votingConstants';
import { DISCUSSIONS_NOTIFICATIONS } from 'constants/discussionsElements';

import { useAppDispatch, useAppSelector } from 'store';
import { OrganizationActions } from 'store/actions';
import { greenDolphinIconFor, greyDolphinIconFor, greyDolphinIconAgainst, purpleDolphinIconAgainst } from 'assets';

import { IVotingSectionProps } from 'types/interfaces';

export const VotingSection: FC<IVotingSectionProps> = ({
    discussionId,
    loggedUserAddress,
    voted,
    isAuthenticatedUser,
    isDiscussionSingle,
    comments,
    isMobile,
    isDiscussionFinished,
    organizationId,
}: IVotingSectionProps): ReactElement => {
    const dispatch = useAppDispatch();
    const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
    const { discussionsIdsWithVoteError } = useAppSelector(({ organization }) => organization);

    const createVote = useCallback(
        (choice: VOTING_OPTIONS) => {
            if (isAuthenticatedUser) {
                dispatch(
                    OrganizationActions.createDiscussionVote.request({
                        discussionId,
                        organizationId,
                        isDiscussionSingle,
                        data: {
                            voter: loggedUserAddress,
                            choice,
                        },
                    }),
                );
            } else {
                setNotificationOpen(true);
            }
        },
        [loggedUserAddress, notificationOpen, isAuthenticatedUser, isDiscussionSingle],
    );

    useEffect(() => {
        if (discussionsIdsWithVoteError.includes(discussionId) && !notificationOpen) setNotificationOpen(true);
    }, [discussionsIdsWithVoteError]);

    const clearNotificationError = useCallback(() => {
        setNotificationOpen(false);
        dispatch({
            type: OrganizationActions.CLEAR_DISCUSSION_TOKENLESS_ERROR,
            payload: {
                discussionsIdsWithVoteError: discussionsIdsWithVoteError.filter(
                    (itemId: string) => itemId !== discussionId,
                ),
            },
        });
    }, [notificationOpen, discussionsIdsWithVoteError]);

    return (
        <div className={styles.votingSection}>
            {notificationOpen && (
                <DiscussionTooltip
                    onClose={clearNotificationError}
                    className={styles.notificationTooltip}
                    text={DISCUSSIONS_NOTIFICATIONS.VOTE}
                />
            )}

            {isMobile && (
                <div className={styles.commentsBlock}>
                    <MessageIcon className={styles.messageIcon} />
                    <span className={styles.commentsCount}>{comments.length}</span>
                </div>
            )}

            {!isDiscussionFinished && (
                <div className={styles.buttonsWrapper}>
                    <VotingButton
                        onClick={() => createVote(VOTING_OPTIONS.FOR)}
                        votingOption={VOTING_OPTIONS.FOR}
                        title="support"
                        defaultImage={greyDolphinIconFor}
                        hoverImage={greenDolphinIconFor}
                        voted={voted}
                    />

                    <VotingButton
                        onClick={() => createVote(VOTING_OPTIONS.AGAINST)}
                        votingOption={VOTING_OPTIONS.AGAINST}
                        title="against"
                        defaultImage={greyDolphinIconAgainst}
                        hoverImage={purpleDolphinIconAgainst}
                        voted={voted}
                    />
                </div>
            )}

            {isDiscussionFinished && voted && <VotingResult voted={voted} isMobile={isMobile} />}
        </div>
    );
};

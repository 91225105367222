import { call, takeLatest, put, all } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { SelfListingActions } from 'store/actions';
import { SelfListingApi } from 'store/apis';
import { LinkUtils } from 'utils';
import { mixpanelService } from 'services';

import { ISelfListingChain, ISelfListingPlatform } from 'types/interfaces/selfListing.interfaces';

function* getSelfListingDataWorker(): SagaIterator {
    try {
        const [selfListingChainsList, selfListingPlatformsList] = yield all([
            call(SelfListingApi.getSelfListingChains),
            call(SelfListingApi.getSelfListingPlatforms),
        ]);

        yield put(
            SelfListingActions.getSelfListingData.success({
                selfListingChainsList: selfListingChainsList.data?.length
                    ? selfListingChainsList.data.map(({ id, title }: ISelfListingChain) => ({
                          id,
                          title,
                      }))
                    : [],
                selfListingPlatformsList: selfListingPlatformsList.data?.length
                    ? selfListingPlatformsList.data.map(({ id, title }: ISelfListingPlatform) => ({
                          id,
                          title,
                      }))
                    : [],
            }),
        );
    } catch (e) {
        yield put(SelfListingActions.getSelfListingData.failure(e));
    }
}

function* listOrganizationWorker({ payload: { listingData, history, type, organizationId, updateDispatch } }: any) {
    try {
        const { data, hasError } = yield call(
            type === 'editing' ? SelfListingApi.updateListedOrganization : SelfListingApi.listOrganization,
            listingData,
            organizationId,
        );

        if (!hasError) {
            yield put(SelfListingActions.listOrganization.success());
            yield put({ type: SelfListingActions.TOGGLE_SELF_LISTING_MODAL_OPEN });
            if (type === 'adding') {
                mixpanelService.track(mixpanelService.eventsGenerator.brainstormCreated());
                history.push(LinkUtils.createDynamicLink(data.organization.id, 'organization'));
            } else {
                yield updateDispatch();
            }
        } else {
            yield put(SelfListingActions.listOrganization.failure());
        }
    } catch (e) {
        yield put(SelfListingActions.listOrganization.failure());
    }
}

function* getListedOrganizationDataWorker({ payload }: any) {
    try {
        const { data, hasError } = yield call(SelfListingApi.getListedOrganizationData, payload.organizationId);

        if (!hasError) {
            yield put(SelfListingActions.getListedOrganizationData.success({ listedOrganizationData: data }));
        } else {
            yield put(SelfListingActions.getListedOrganizationData.failure());
        }
    } catch (e) {
        yield put(SelfListingActions.getListedOrganizationData.failure(e));
    }
}

export const selfListingSaga = function* (): SagaIterator {
    yield all([takeLatest(SelfListingActions.GET_SELF_LISTING_DATA, getSelfListingDataWorker)]);
    yield all([takeLatest(SelfListingActions.LIST_ORGANIZATION, listOrganizationWorker)]);
    yield all([takeLatest(SelfListingActions.GET_LISTED_ORGANIZATION_DATA, getListedOrganizationDataWorker)]);
};

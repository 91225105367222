import React, { FC, ReactElement, useContext } from 'react';

import { Button } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import styles from './styles.module.scss';

import { ContactDetails, OrganizationInfo, SocialsContainer } from './components';

import { SelfListingContext } from 'context/selfListingContext';

import { IOrganizationDetailsFormProps } from 'types/interfaces';

export const OrganizationDetailsForm: FC<IOrganizationDetailsFormProps> = ({
    nextStep,
}: IOrganizationDetailsFormProps): ReactElement => {
    const { isOrganizationDetailsFormDisabled } = useContext(SelfListingContext);

    return (
        <>
            <ContactDetails />
            <OrganizationInfo />
            <SocialsContainer />
            <div className={styles.wrapper}>
                <h6 className={styles.notificationText}>Please fill in the required fields to continue</h6>
                <Button
                    className={styles.nextStepButton}
                    onClick={nextStep}
                    disabled={isOrganizationDetailsFormDisabled}
                >
                    <span className={styles.title}>continue</span>
                    <ArrowForwardIcon className={styles.arrowIcon} />
                </Button>
            </div>
        </>
    );
};

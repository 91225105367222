import { call, takeLatest, put, all } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { AnyAction } from '@reduxjs/toolkit';

import { NOTIFICATIONS_ACTIONS_TYPES } from 'constants/notificationsActions';

import { AuthActions, SearchActions, UserActions } from 'store/actions';
import { AuthApi, UserApi } from 'store/apis';
import { searchTopThreeWorker } from './search.saga';
import { getUserNotificationsWorker } from './user.saga';

function* authWorker(action: AnyAction) {
    try {
        const { data } = yield call(() => AuthApi.login(action.payload));
        const { token, refreshToken, address } = data;

        if (!token || !refreshToken || !address) {
            yield put(AuthActions.login.failure('missing details'));
        } else {
            yield all([
                call(getLoggedUserDataWorked, { type: AuthActions.GET_LOGGED_USER_DATA, payload: { address } }),
                call(searchTopThreeWorker, { type: SearchActions.SEARCH_TOP_THREE, payload: { token } }),
                call(getUserNotificationsWorker, {
                    type: UserActions.GET_USER_NOTIFICATIONS,
                    payload: { token, notificationsActionType: NOTIFICATIONS_ACTIONS_TYPES.INITIAL_REQUEST },
                }),
            ]);

            yield put(
                AuthActions.login.success({
                    token,
                    refreshToken,
                    address,
                }),
            );
        }
    } catch (e) {
        yield put(AuthActions.login.failure(e));
    }
}

function* logoutWorker(action: AnyAction) {
    try {
        const { hasError } = yield call(() => AuthApi.logout(action.payload));

        if (!hasError) {
            yield call(searchTopThreeWorker, { type: SearchActions.SEARCH_TOP_THREE });
            yield put(
                UserActions.getUserNotifications.success({
                    userNotificationsData: {
                        newNotifications: [],
                        oldNotifications: [],
                    },
                    hasMoreNotifications: true,
                    notificationsOffset: 0,
                }),
            );
        }
    } catch (e) {
        yield put(AuthActions.login.failure(e));
    }
}

function* challengeWorker(action: AnyAction) {
    try {
        const { data } = yield call(() => AuthApi.challenge(action.payload));
        yield put(
            AuthActions.challenge.success({
                challenge: data.challenge,
            }),
        );
    } catch (e) {
        yield put(AuthActions.challenge.failure(e));
    }
}

export const getLoggedUserDataWorked = function* ({ payload }: AnyAction): SagaIterator {
    try {
        const { data } = yield call(UserApi.getUserData, payload.address);

        yield put(
            AuthActions.getLoggedUserData.success({
                loggedUserData: {
                    address: data.address,
                    avatar: data.avatar,
                    name: data.name,
                },
            }),
        );
    } catch (e) {
        yield put(AuthActions.getLoggedUserData.failure(e));
    }
};

export const authSaga = function* (): SagaIterator {
    yield all([
        takeLatest(AuthActions.LOGIN, authWorker),
        takeLatest(AuthActions.LOGOUT, logoutWorker),
        takeLatest(AuthActions.CHALLENGE, challengeWorker),
        takeLatest(AuthActions.GET_LOGGED_USER_DATA, getLoggedUserDataWorked),
    ]);
};

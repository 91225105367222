import { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { serialize } from 'object-to-formdata';

import { treasuryTypesList } from './constants';

import { validateInputAddresses } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store';
import { OrganizationActions, SelfListingActions } from 'store/actions';

import {
    SocialTypes,
    ISelfListingAdminInfo,
    ISelfListingEditor,
    ISelfListingFormValues,
    ISelfListingSocial,
    ISelfListingElement,
    ISelfListingGovernance,
    ISelfListingTreasury,
    ISelfListingFormEditor,
    ISelfListingFormGovernance,
    ISelfListingFormTreasury,
} from 'types/interfaces';

enum SOCIAL_FIELD_NAME {
    URL = 'URL',
    ID = 'ID',
}

export const UseSelfListingFormik = (type: any) => {
    const {
        selfListingChainsList,
        selfListingPlatformsList,
        listedOrganizationData: {
            organization,
            contactDetails: {
                selfListedData = {} as ISelfListingAdminInfo,
                editorsData = [] as ISelfListingEditor[],
            } = {},
            socials,
            governance,
            token,
            treasury,
        },
    } = useAppSelector(({ selfListing }) => selfListing);

    const dispatch = useAppDispatch();

    const {
        loggedUserData: { address },
    } = useAppSelector(({ auth }) => auth);

    const history = useHistory();

    const findSocialByType = useCallback(
        (socialType: SocialTypes, fieldType: SOCIAL_FIELD_NAME) => {
            const data = socials?.find(({ type }: ISelfListingSocial) => type === socialType.toUpperCase());
            if (fieldType === SOCIAL_FIELD_NAME.URL) return data?.url || '';

            return data?.id || null;
        },
        [socials],
    );

    const updateDispatch = () => {
        const organizationId = organization.id;
        dispatch(OrganizationActions.getOrganizationData.request({ organizationId }));
        dispatch(OrganizationActions.getOrganizationSimilarDaosData.request({ organizationId }));
        dispatch(OrganizationActions.getOrganizationCoalitionsData.request({ organizationId }));
        dispatch(OrganizationActions.getOrganizationActivityData.request({ organizationId }));
    };

    const formik = useFormik({
        initialValues: {
            contactDetails: {
                adminAddress: selfListedData?.adminFullname || address || '',
                adminEmail: selfListedData?.adminEmail || '',
                adminId: selfListedData?.id || null,
                editors: editorsData?.length
                    ? editorsData.map(({ email, address, id }: ISelfListingEditor) => ({
                          email: email || '',
                          address: address || '',
                          id,
                      }))
                    : [
                          {
                              address: '',
                              email: '',
                          },
                      ],
                organizationLogoFile: '',
                organizationLogoUrl: organization?.logo || '',
                organizationTitle: organization?.title || '',
                organizationWebsite: findSocialByType(SocialTypes.website, SOCIAL_FIELD_NAME.URL),
                organizationDescription: organization?.description?.replace(/(<([^>]+)>)/gi, '') || '',
                socials: [
                    {
                        type: SocialTypes.discord,
                        url: findSocialByType(SocialTypes.discord, SOCIAL_FIELD_NAME.URL),
                        id: findSocialByType(SocialTypes.discord, SOCIAL_FIELD_NAME.ID),
                    },
                    {
                        type: SocialTypes.telegram,
                        url: findSocialByType(SocialTypes.telegram, SOCIAL_FIELD_NAME.URL),
                        id: findSocialByType(SocialTypes.telegram, SOCIAL_FIELD_NAME.ID),
                    },
                    {
                        type: SocialTypes.twitter,
                        url: findSocialByType(SocialTypes.twitter, SOCIAL_FIELD_NAME.URL),
                        id: findSocialByType(SocialTypes.twitter, SOCIAL_FIELD_NAME.ID),
                    },
                    {
                        type: SocialTypes.github,
                        url: findSocialByType(SocialTypes.github, SOCIAL_FIELD_NAME.URL),
                        id: findSocialByType(SocialTypes.github, SOCIAL_FIELD_NAME.ID),
                    },
                    {
                        type: SocialTypes.blog,
                        url: findSocialByType(SocialTypes.blog, SOCIAL_FIELD_NAME.URL),
                        id: findSocialByType(SocialTypes.blog, SOCIAL_FIELD_NAME.ID),
                    },
                    {
                        type: SocialTypes.youtube,
                        url: findSocialByType(SocialTypes.youtube, SOCIAL_FIELD_NAME.URL),
                        id: findSocialByType(SocialTypes.youtube, SOCIAL_FIELD_NAME.ID),
                    },

                    ...(socials?.length
                        ? socials?.filter(({ type }: ISelfListingSocial) => type === SocialTypes.url.toUpperCase())
                        : []),
                ],
            },

            token: token?.id
                ? {
                      tokenName: token.tokenName || '',
                      tokenSymbol: token.tokenSymbol || '',
                      tokenAddress: token.address || '',
                      chainId: token.chainId || null,
                      chainTitle: token.chainId
                          ? selfListingChainsList.find(({ id }: ISelfListingElement) => token.chainId === id)?.title
                          : '',
                      id: token.id,
                  }
                : {
                      tokenName: '',
                      tokenSymbol: '',
                      tokenAddress: '',
                      chainId: null,
                      chainTitle: '',
                  },
            governance: governance?.length
                ? governance.map(({ platformId, other, id }: ISelfListingGovernance) => ({
                      platformId: platformId || null,
                      address: other || '',
                      title: platformId
                          ? selfListingPlatformsList.find(({ id }: ISelfListingElement) => platformId === id)?.title
                          : '',
                      id,
                  }))
                : [
                      {
                          platformId: null,
                          title: '',
                          address: '',
                      },
                  ],
            treasury: treasury?.length
                ? treasury.map(({ chainId, address, other, id }: ISelfListingTreasury) => ({
                      type: other || '',
                      treasuryTypeId: other
                          ? treasuryTypesList.find(({ title }: ISelfListingElement) => title === (other as string))?.id
                          : null,
                      address: address || '',
                      chainId: chainId || null,
                      title: chainId
                          ? selfListingChainsList.find(({ id }: ISelfListingElement) => chainId === id)?.title
                          : '',
                      id,
                  }))
                : [
                      {
                          type: '',
                          address: '',
                          chainId: null,
                          title: '',
                          treasuryTypeId: null,
                      },
                  ],
        } as ISelfListingFormValues,
        enableReinitialize: true,
        validateOnBlur: false,
        validationSchema: Yup.object({
            contactDetails: Yup.object().shape({
                adminAddress: Yup.string().required('Required'),
                adminEmail: Yup.string().email('invalid email').required('Required'),
                editors: Yup.array().of(
                    Yup.object().shape({
                        email: Yup.string().email('invalid email'),
                        address: Yup.string().test('checking if valid address', 'invalid address', (value = '') => {
                            if (value.length) {
                                return validateInputAddresses(value);
                            }
                            return true;
                        }),
                    }),
                ),
                organizationTitle: Yup.string().required('Required'),
                organizationWebsite: Yup.string().required('Required'),
                organizationDescription: Yup.string().required('Required'),
            }),
            token: Yup.object().shape({
                tokenAddress: Yup.string().when('chainId', {
                    is: (chainId?: string) => Boolean(chainId),
                    then: Yup.string().required('please enter token address'),
                    otherwise: Yup.string(),
                }),
                chainTitle: Yup.string().when('tokenAddress', {
                    is: (tokenAddress?: string) => Boolean(tokenAddress),
                    then: Yup.string().required('please enter chain id'),
                    otherwise: Yup.string(),
                }),
            }),
            governance: Yup.array().of(
                Yup.object().shape({
                    address: Yup.string().when('platformId', {
                        is: (platformId?: number | null) => Boolean(platformId),
                        then: Yup.string().required('please enter governance address'),
                        otherwise: Yup.string(),
                    }),
                    title: Yup.string().when('address', {
                        is: (address?: string) => Boolean(address),
                        then: Yup.string().required('please enter platform id'),
                        otherwise: Yup.string(),
                    }),
                }),
            ),
            treasury: Yup.array().of(
                Yup.object().shape({
                    address: Yup.string().when(['chainId', 'treasuryTypeId'], {
                        is: (chainId?: number | null, treasuryTypeId?: number | null) =>
                            Boolean(chainId) || Boolean(treasuryTypeId),
                        then: Yup.string().required('please enter treasury address'),
                        otherwise: Yup.string(),
                    }),
                    title: Yup.string().when(['address', 'treasuryTypeId'], {
                        is: (address?: string, treasuryTypeId?: number | null) =>
                            Boolean(address) || Boolean(treasuryTypeId),
                        then: Yup.string().required('please enter chain id'),
                        otherwise: Yup.string(),
                    }),
                    type: Yup.string().when(['address', 'chainId'], {
                        is: (address?: string, chainId?: number | null) => Boolean(address) || Boolean(chainId),

                        then: Yup.string().nullable().required('please enter type'),
                        otherwise: Yup.string().nullable(),
                    }),
                }),
            ),
        }),
        onSubmit: ({ contactDetails, token, governance, treasury }) => {
            const data = {
                organization: {
                    description: contactDetails.organizationDescription,
                    logo: contactDetails.organizationLogoFile || contactDetails.organizationLogoUrl,
                    title: contactDetails.organizationTitle,
                },
                contactDetails: {
                    ...{
                        ...(contactDetails.adminId
                            ? {
                                  adminAddress: contactDetails.adminAddress,
                                  adminEmail: contactDetails.adminEmail,
                                  id: contactDetails.adminId,
                              }
                            : {
                                  adminAddress: contactDetails.adminAddress,
                                  adminEmail: contactDetails.adminEmail,
                              }),
                    },
                    adminAddress: contactDetails.adminAddress,
                    adminEmail: contactDetails.adminEmail,
                    editors: contactDetails.editors
                        .filter(({ address, email }: ISelfListingFormEditor) => address || email)
                        .map(({ address, email, ...items }: ISelfListingFormEditor) => {
                            const editorData = { ...items } as ISelfListingFormEditor;
                            if (address) editorData.address = address;
                            if (email) editorData.email = email;

                            return editorData;
                        }),
                },
                socials: [
                    organization
                        ? {
                              type: SocialTypes.website,
                              url: /^((http|https|ftp):\/\/)/.test(contactDetails.organizationWebsite as string)
                                  ? contactDetails.organizationWebsite
                                  : 'https://' + contactDetails.organizationWebsite,
                              id: findSocialByType(SocialTypes.website, SOCIAL_FIELD_NAME.ID),
                          }
                        : {
                              type: SocialTypes.website,
                              url: /^((http|https|ftp):\/\/)/.test(contactDetails.organizationWebsite as string)
                                  ? contactDetails.organizationWebsite
                                  : 'https://' + contactDetails.organizationWebsite,
                          },
                    ...contactDetails.socials
                        .filter(({ url }: ISelfListingSocial) => url)
                        .map(({ id, url, type }: ISelfListingSocial) => {
                            const socialData = {
                                type: type.toLowerCase(),
                                url: /^((http|https|ftp):\/\/)/.test(url as string) ? url : 'https://' + url,
                            } as ISelfListingSocial;
                            if (id) socialData.id = id;

                            return socialData;
                        }),
                ],
                token:
                    token.tokenAddress && token.chainId
                        ? token.id
                            ? {
                                  tokenName: token.tokenName,
                                  tokenSymbol: token.tokenSymbol,
                                  tokenAddress: token.tokenAddress,
                                  chainId: token.chainId,
                                  id: token.id,
                              }
                            : {
                                  tokenName: token.tokenName,
                                  tokenSymbol: token.tokenSymbol,
                                  tokenAddress: token.tokenAddress,
                                  chainId: token.chainId,
                              }
                        : {},
                governance: governance
                    .filter(({ platformId, address }: ISelfListingFormGovernance) => platformId && address)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .map(({ title, ...governanceItems }: ISelfListingFormGovernance) => ({ ...governanceItems })),
                treasury: treasury
                    .filter(({ address, chainId, type }: ISelfListingFormTreasury) => chainId && address && type)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .map(({ title, treasuryTypeId, ...treasuryItems }: ISelfListingFormTreasury) => ({
                        ...treasuryItems,
                    })),
            };

            dispatch(
                SelfListingActions.listOrganization.request({
                    listingData: serialize(data, { indices: true }),
                    organizationId: organization?.id,
                    history,
                    type,
                    updateDispatch,
                }),
            );
        },
    });

    const { adminAddress, adminEmail, organizationTitle, organizationDescription, organizationWebsite } =
        formik.values.contactDetails;

    const isOrganizationDetailsFormDisabled = Boolean(
        adminAddress &&
            adminEmail &&
            organizationTitle &&
            organizationDescription &&
            organizationWebsite &&
            !formik.errors.contactDetails,
    );

    return { formik, isOrganizationDetailsFormDisabled: !isOrganizationDetailsFormDisabled };
};

import React, { FC, ReactElement, memo } from 'react';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import styles from './styles.module.scss';

import { TooltipWrapper, ImageLoader } from 'components';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { defaultTokenPlaceholderImage } from 'assets';

import { ITopTokensItemsProps, ITopTokenData } from 'types/interfaces';

const MAX_TOKENS_ICONS = 5;
const ETHER_LOGO_LINK = 'https://logos.covalenthq.com/tokens/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png';

export const TopTokensItems: FC<ITopTokensItemsProps> = memo(
    ({ tokens, showMoreIcon }: ITopTokensItemsProps): ReactElement => (
        <div className={styles.topTokensItems}>
            {tokens
                ?.slice(0, MAX_TOKENS_ICONS)
                .map(({ tokenAddress, logo, symbol, name: tokenName }: ITopTokenData) => (
                    <TooltipWrapper key={tokenAddress} title={tokenName}>
                        <div className={styles.tokenLogoWrapper}>
                            <ImageLoader
                                src={symbol !== 'ETH' ? (logo ? logo : defaultTokenPlaceholderImage) : ETHER_LOGO_LINK}
                                className={styles.tokenLogo}
                                imageType={IMAGE_TYPES.TOKEN_IMAGE}
                            />
                        </div>
                    </TooltipWrapper>
                ))}

            {showMoreIcon && <MoreHorizIcon className={styles.moreIcon} />}
        </div>
    ),
);

import { SELF_LISTING_STATUSES } from 'constants/selfListing';
import { DEEP_DAO_MAIL } from 'constants/deepDaoLinks';

const MAIL_TO_DEEPDAO = `
    <a href=${DEEP_DAO_MAIL}>
        <span>info@deepdao.io</span>
    </a>`;

export const showTextByOrganizationStatus = (organizationStatus: SELF_LISTING_STATUSES): string => {
    switch (organizationStatus) {
        case SELF_LISTING_STATUSES.NOTHING_PROVIDED:
            return `This self listed organization still does not have: Token, Governance and Treasury. If you're the admin please contact us at ${MAIL_TO_DEEPDAO}`;

        case SELF_LISTING_STATUSES.TOKEN_PROVIDED:
            return `This self listed organization still does not have: Governance and Treasury. If you're the admin please contact us at ${MAIL_TO_DEEPDAO}`;

        case SELF_LISTING_STATUSES.TREASURY_PROVIDED:
            return `This self listed organization still does not have: Governance and Token. If you're the admin please contact us at ${MAIL_TO_DEEPDAO}`;

        case SELF_LISTING_STATUSES.GOVERNANCE_PROVIDED:
            return `This self listed organization still does not have: Treasury and Token. If you're the admin please contact us at ${MAIL_TO_DEEPDAO}`;

        case SELF_LISTING_STATUSES.TOKEN_TREASURY_PROVIDED:
            return `This self listed organization still does not have: Governance. If you're the admin please contact us at ${MAIL_TO_DEEPDAO}`;

        case SELF_LISTING_STATUSES.TREASURY_GOVERNANCE_PROVIDED:
            return `This self listed organization still does not have: Token. If you're the admin please contact us at ${MAIL_TO_DEEPDAO}`;

        case SELF_LISTING_STATUSES.TOKEN_GOVERNANCE_PROVIDED:
            return `This self listed organization still does not have: Treasury. If you're the admin please contact us at ${MAIL_TO_DEEPDAO}`;

        case SELF_LISTING_STATUSES.ALL_PROVIDED:
            return `Our admins are currently verifying this organization's Governance, Treasury and Token.`;

        default:
            return `This self listed organization still does not have: Token, Governance and Treasury. If you're the admin please contact us at ${MAIL_TO_DEEPDAO}`;
    }
};

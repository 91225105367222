import { MouseEvent } from 'react';

class ScrollUtils {
    static scrollToElement = (event: MouseEvent<HTMLElement>, elementId: string): void => {
        event.stopPropagation();
        const hiddenElement = document.getElementById(elementId);
        hiddenElement?.scrollIntoView({ behavior: 'smooth' });
    };
}

export { ScrollUtils };

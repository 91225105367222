import React, { FC, ReactElement, useContext } from 'react';

import styles from './styles.module.scss';

import { SelfListingInput, SelfListingTextarea, SelfListingTitle } from 'components';
import { OrganizationLogoSection } from './components';

import { SelfListingContext } from 'context/selfListingContext';

export const OrganizationInfo: FC = (): ReactElement => {
    const { formik, disabledForEditors } = useContext(SelfListingContext);

    return (
        <div className={styles.organizationInfoWrapper}>
            <SelfListingTitle title="Organization" />

            <OrganizationLogoSection />

            <div className={styles.organizationFieldsWrapper}>
                <div className={styles.inputsWrapper}>
                    <SelfListingInput
                        placeholder="Organization Name"
                        name="contactDetails.organizationTitle"
                        value={formik.values.contactDetails.organizationTitle}
                        touched={formik.touched.contactDetails?.organizationTitle}
                        error={formik.errors.contactDetails?.organizationTitle}
                        onChange={formik.handleChange}
                        onTouch={formik.setFieldTouched}
                        required
                        disabled={disabledForEditors}
                    />

                    <SelfListingInput
                        placeholder="Website"
                        name="contactDetails.organizationWebsite"
                        value={formik.values.contactDetails.organizationWebsite}
                        touched={formik.touched.contactDetails?.organizationWebsite}
                        error={formik.errors.contactDetails?.organizationWebsite}
                        onChange={formik.handleChange}
                        onTouch={formik.setFieldTouched}
                        required
                    />
                </div>

                <SelfListingTextarea
                    placeholder="Description"
                    name="contactDetails.organizationDescription"
                    infoText="Max 5000 Characters"
                    value={formik.values.contactDetails?.organizationDescription}
                    touched={formik.touched.contactDetails?.organizationDescription}
                    error={formik.errors.contactDetails?.organizationDescription}
                    onChange={formik.handleChange}
                    onTouch={formik.setFieldTouched}
                    max={5000}
                    required
                />
            </div>
        </div>
    );
};

import { Reducer } from '@reduxjs/toolkit';

import { PeopleActions } from 'store/actions';

import type {
    IPeopleActionsType,
    IPeopleState,
    IGetPeopleSortedDataPayload,
    IGetAllPeopleDataPayload,
    IChartItem,
} from 'types/interfaces';

const defaultState: IPeopleState = {
    loading: false,
    hasMore: true,
    totalAumUSD: [],
    peopleAUMbyDeciles: [],
    totalAUMBreackdown: [],
    topTokensByUsd: [],
    topTokensByPeople: [],
    totalPeopleAumChanges: {
        totalAum: 0,
        changeWeek: 0,
        changeMonth: 0,
    },
    peopleData: [],
    lastUpdateDate: null,
    organizationsCount: null,
    enrichedOrganizationsCount: null,
    topPeopleTokensData: [],
};

export const peopleReducer: Reducer<IPeopleState, IPeopleActionsType> = (
    state: IPeopleState = defaultState,
    action: IPeopleActionsType,
): IPeopleState => {
    const { type, payload } = <IPeopleActionsType>action;

    switch (type) {
        case PeopleActions.GET_ALL_PEOPLE_DATA: {
            return {
                ...state,
                loading: true,
            };
        }

        case PeopleActions.GET_ALL_PEOPLE_DATA_SUCCESS: {
            const {
                hasMore,
                peopleData,
                topPeopleTokensData,
                aumByPopulation,
                historyUsdAum,
                totalPeopleAumChanges,
                lastUpdateDate,
                organizationsCount,
                enrichedOrganizationsCount,
            } = payload as IGetAllPeopleDataPayload;

            return {
                ...state,
                hasMore,
                peopleData: peopleData,
                topPeopleTokensData: topPeopleTokensData,
                topTokensByPeople: topPeopleTokensData
                    .map(({ name: label, totalHolders: value }) => ({ label, value }))
                    .sort((a, b) => b.value - a.value)
                    .slice(0, 5),
                topTokensByUsd: topPeopleTokensData
                    .sort((a, b) => b.usd - a.usd)
                    .map(({ name: label, usd: value }) => ({ label, value }))
                    .slice(0, 4),
                peopleAUMbyDeciles: aumByPopulation.map(({ name: label, value }) => ({ label, value })) as IChartItem[],
                totalAumUSD: historyUsdAum
                    .slice(-6)
                    .map(({ date: label, aum: value }) => ({ label, value })) as IChartItem[],
                totalPeopleAumChanges: totalPeopleAumChanges,
                lastUpdateDate,
                organizationsCount,
                enrichedOrganizationsCount,
                loading: false,
            };
        }

        case PeopleActions.GET_ALL_PEOPLE_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case PeopleActions.GET_PEOPLE_SORTED_DATA: {
            return {
                ...state,
                loading: true,
            };
        }

        case PeopleActions.GET_PEOPLE_SORTED_DATA_SUCCESS: {
            const { data, hasMore } = payload as IGetPeopleSortedDataPayload;

            return {
                ...state,
                hasMore,
                peopleData: [...state.peopleData, ...data],
                loading: false,
            };
        }

        case PeopleActions.GET_PEOPLE_SORTED_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case PeopleActions.CLEAR_PEOPLE_DATA: {
            return {
                ...state,
                peopleData: [],
            };
        }

        case PeopleActions.CLEAR_ALL_PEOPLE_DATA: {
            return {
                ...defaultState,
            };
        }

        default: {
            return state;
        }
    }
};

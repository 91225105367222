import { IOrganizationsTokensData, IOrganizationTokensItems } from 'types/interfaces';

export const createDaoTokensTableData = (organizationsTokens: IOrganizationsTokensData[]): IOrganizationTokensItems[] =>
    organizationsTokens
        ? organizationsTokens.map(
              (
                  {
                      daosTotalBalance,
                      daosTotalUsdValue,
                      numDaos,
                      tokenName,
                      tokenSymbol,
                      tokenIcon,
                  }: IOrganizationsTokensData,
                  index: number,
              ) => ({
                  daosTotalBalance,
                  daosTotalUsdValue,
                  numDaos,
                  tokenName,
                  tokenSymbol,
                  tokenIcon,
                  rank: index + 1,
              }),
          )
        : [];

export const tokensTableSearch = (
    tokensTableData: IOrganizationTokensItems[],
    value: string,
): IOrganizationTokensItems[] =>
    value
        ? tokensTableData.filter(({ tokenName }: IOrganizationTokensItems) => {
              return tokenName?.toLowerCase()?.includes(value.toLowerCase());
          })
        : tokensTableData;

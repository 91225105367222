import React, { FC, ReactElement, useCallback, useState } from 'react';

import { Link } from 'react-router-dom';

import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import styles from './styles.module.scss';

import { ConfirmationModal, FormRadioButtons, SubscriptionInfo } from 'components';
import { PlanCard } from './components';

import { plansDetailsList, plansDurations, PLANS_PAGES_INDEXES, subscriptionFaqList } from 'constants/plansItems';

import { IPremiumPlanDetails, ISelectOptions, IPlansInfoProps, ISubscriptionFaq } from 'types/interfaces';

export const PlansInfo: FC<IPlansInfoProps> = ({
    formik,
    changePlansPage,
    accountCreated,
    isUpgradingType = false,
    premiumUserData,
}: IPlansInfoProps): ReactElement => {
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

    const changeDuration = useCallback(
        (option: ISelectOptions) => {
            formik.setFieldValue('planInfo.duration', option);
        },
        [formik],
    );

    const onPlanSelect = useCallback(
        (planName: string, title: string, price: number) => {
            if (premiumUserData.autoRenew && !isUpgradingType) {
                setConfirmationModalOpen(true);
            } else {
                formik.setFieldValue('planInfo.planName', planName);
                formik.setFieldValue('planInfo.title', title);
                formik.setFieldValue('planInfo.price', price);
                accountCreated
                    ? changePlansPage(PLANS_PAGES_INDEXES.PAYMENT)
                    : changePlansPage(PLANS_PAGES_INDEXES.ACCOUNT_INFO);
            }
        },
        [formik, accountCreated, changePlansPage],
    );

    return (
        <>
            <div className={styles.container}>
                {isUpgradingType && (
                    <div className={styles.navigationBackBlock}>
                        <Link to="/premium_account_settings" className={styles.accountSettingsLink}>
                            <ArrowBackIos className={styles.arrowIcon} />
                            <span className={styles.text}>Settings</span>
                        </Link>
                    </div>
                )}
                <div className={styles.header}>
                    <div className={styles.textSection}>
                        {isUpgradingType ? (
                            <h1 className={styles.title}>Upgrade your plan</h1>
                        ) : (
                            <>
                                <h1 className={styles.title}>DeepDAO Products</h1>
                                <h6 className={styles.description}>
                                    DeepDAO is the #1 discovery and analytics platform for the DAO ecosystem,
                                    aggregating thousands of DAOs and millions of DAO participants. Our products rely on
                                    the data, and go beyond it to find trends and insights, and help DAOs, DAOists, and
                                    DAO tools to navigate the ecosystem.
                                </h6>
                            </>
                        )}
                    </div>

                    <div className={styles.billingCycleOptions}>
                        <h3 className={styles.radioFormTitle}>Billing Cycle</h3>
                        <div className={styles.period}>
                            <FormRadioButtons
                                options={plansDurations}
                                selectedOption={formik.values.planInfo.duration}
                                onChange={changeDuration}
                                className={styles.radioInputsWrapper}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.plansCardsWrapper}>
                    {plansDetailsList.map((items: IPremiumPlanDetails) => (
                        <PlanCard
                            key={items.id}
                            {...items}
                            duration={formik.values.planInfo.duration}
                            onPlanSelect={onPlanSelect}
                            isUpgradingType={isUpgradingType}
                            premiumUserData={premiumUserData}
                        />
                    ))}
                </div>
                <div className={styles.subscriptionsInfo}>
                    <h1 className={styles.title}>Subscription FAQ</h1>
                    <div className={styles.subscriptionsBlocks}>
                        {subscriptionFaqList.map((items: ISubscriptionFaq) => (
                            <SubscriptionInfo key={items.id} {...items} />
                        ))}
                    </div>
                </div>
            </div>

            {confirmationModalOpen && (
                <ConfirmationModal
                    onClose={() => setConfirmationModalOpen(false)}
                    confirmationModalOpen={confirmationModalOpen}
                    textInfo={{
                        title: 'You already have a subscription. If you want to switch to another one, please first cancel your existing subscription. To do so go to the Settings page',
                        confirmationText: 'OK',
                    }}
                    onConfirm={() => setConfirmationModalOpen(false)}
                />
            )}
        </>
    );
};

import React, { FC, ReactElement, memo } from 'react';

import './styles.css';

import { useAppSelector } from 'store';

export const TextContainer: FC = memo((): ReactElement => {
    const {
        organizationData: { description },
    } = useAppSelector(({ organization }) => organization);

    return <div dangerouslySetInnerHTML={{ __html: description || '' }}></div>;
});

import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { TooltipWrapper, ImageLoader } from 'components';

import { IMAGE_TYPES } from 'constants/imageTypes';
import { IOrganizationHoldingToken } from '../../../../../../../../types/interfaces';
import { FormatUtils, LinkUtils } from '../../../../../../../../utils';
import { defaultOrganizationPlaceholderImage } from '../../../../../../../../assets';

export const HoldingTokenOrganizationCard: FC<IOrganizationHoldingToken> = ({
    organization,
    totalTokenUsd,
    totalTokenValue,
}: IOrganizationHoldingToken): ReactElement => {
    const logo = LinkUtils.isValidUrl(organization.logo || '')
        ? organization.logo
        : LinkUtils.getDaoLogoLinkFromBucket(organization.logo, defaultOrganizationPlaceholderImage);
    const link = LinkUtils.createDynamicLink(organization.id, 'organization');

    return (
        <Link to={link} className={styles.linkWrapper}>
            <div className={styles.container}>
                <ImageLoader src={logo} className={styles.avatar} imageType={IMAGE_TYPES.ORGANIZATION_IMAGE} />
                <div className={styles.mainInfo}>
                    <TooltipWrapper title={organization.title}>
                        <h5 className={styles.organizationName}>{organization.title}</h5>
                    </TooltipWrapper>
                    <p className={styles.description}>In common:</p>
                    <div className={styles.stats}>
                        <div>
                            <h6>Tokens:</h6>
                        </div>
                        <div>
                            <h6>{FormatUtils.formatNumberShort(totalTokenValue, 1)}</h6>
                        </div>
                    </div>
                    <div className={styles.stats}>
                        <div>
                            <h6>Total USD:</h6>
                        </div>
                        <div>
                            <h6>${FormatUtils.formatNumberShort(totalTokenUsd, 1)}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

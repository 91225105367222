import React, { FC, ReactElement, memo } from 'react';

import styles from './styles.module.scss';

import { HrefLink } from 'components';

import { planSubscriptionImage } from 'assets';

import { ISubscriptionFaq } from 'types/interfaces';

export const SubscriptionInfo: FC<ISubscriptionFaq> = memo(
    ({ title, description, linkItems }: ISubscriptionFaq): ReactElement => (
        <div className={styles.container}>
            <img src={planSubscriptionImage} className={styles.icon} alt="" />
            <div>
                {title && (
                    <p className={styles.title}>
                        <span>{title}</span>
                        {linkItems && (
                            <HrefLink href={linkItems.link} className={styles.link}>
                                <span>{linkItems.linkText}</span>
                            </HrefLink>
                        )}
                    </p>
                )}
                {description && <h6 className={styles.text}>{description}</h6>}
            </div>
        </div>
    ),
);

import { call, takeLatest, put } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { SearchActions } from 'store/actions';
import { SearchApi } from 'store/apis';

import {
    ISearchTopThreeAction,
    ISearchWorker,
    ITopOrganizationItems,
    ITopUserOrganizationItems,
} from 'types/interfaces';

function* searchWorker({ payload }: ISearchWorker): SagaIterator {
    try {
        const { data } = yield call(SearchApi.search, payload);

        yield put(
            SearchActions.searchUserAddress.success({
                organizationsSearchByCategoryResults: data.data.organizationsSearchByCategoryResults,
                peopleSearchResults: data.data.peopleSearchResults,
                organizationsSearchResults: data.data.organizationsSearchResults,
                userOrganizationsSearchResults: data.data.myDaosSearchResults,
            }),
        );
    } catch (e) {
        yield put(SearchActions.searchUserAddress.failure(e));
    }
}

export const searchTopThreeWorker = function* ({ payload }: ISearchTopThreeAction): SagaIterator {
    try {
        const { data } = yield call(SearchApi.searchTopThree, payload?.token);

        yield put(
            SearchActions.searchUserAddress.success({
                topThreeOrganizations: data.data.topOrganizations.map(
                    ({ organizationId, organizationName, organizationImage }: ITopOrganizationItems) => ({
                        id: organizationId,
                        name: organizationName,
                        organizationImage: organizationImage,
                    }),
                ),
                topThreeUsers: data.data.topPeople,
                topUserOrganizations: data.data.topMyDaos.map(
                    ({ id, logo, organizationImage, title }: ITopUserOrganizationItems) => ({
                        id,
                        name: title,
                        organizationImage: logo || organizationImage,
                    }),
                ),
            }),
        );
    } catch (e) {
        yield put(SearchActions.searchUserAddress.failure(e));
    }
};

export const searchSaga = function* (): SagaIterator {
    yield takeLatest(SearchActions.SEARCH, searchWorker);
    yield takeLatest(SearchActions.SEARCH_TOP_THREE, searchTopThreeWorker);
};

import { axiosInstance } from 'services';
import { AxiosResponse } from 'axios';

import { getDeepDAOTokenFromStorage } from 'web3/storageHelper';

import { ISearchUserAddress, ISearchResponse, ISearchTopThree } from 'types/interfaces';

export const SearchApi = {
    search: ({ value, include }: { value: string; include: string }): Promise<AxiosResponse<ISearchResponse>> =>
        axiosInstance.get<ISearchResponse>(`search?include=${include}&value=${value}`, {
            headers: getDeepDAOTokenFromStorage() ? { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` } : null,
        }),
    searchUserAddress: (userId: string): Promise<AxiosResponse<ISearchUserAddress>> =>
        axiosInstance.get<ISearchUserAddress>(`user/2/${userId}`),
    searchTopThree: (token?: string): Promise<AxiosResponse<ISearchTopThree>> =>
        axiosInstance.get<ISearchTopThree>('search/top-data', {
            headers:
                token || getDeepDAOTokenFromStorage()
                    ? { Authorization: `Bearer ${token || getDeepDAOTokenFromStorage()}` }
                    : null,
        }),
};

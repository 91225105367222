import {PLANS_DURATIONS, PLAN_NAMES, PLANS_PRICINGS} from 'constants/plansItems';

import { IBluesnapPlan, IPaypalPlan, IFilteredPlans } from 'types/interfaces';

export const filteredPaypalPlans = (paypalPlans: IPaypalPlan[]): IFilteredPlans[] =>
    paypalPlans
        .filter(
            (planInfo: IPaypalPlan) =>
                planInfo.name.toLowerCase() === PLAN_NAMES.STARTER.toLowerCase() ||
                planInfo.name.toLowerCase() === PLAN_NAMES.PRO.toLowerCase(),
        )
        .map((planInfo: IPaypalPlan) => ({
            id: planInfo.id,
            planName: planInfo.name.toUpperCase() as PLAN_NAMES,
            duration: (planInfo.billing_cycles[0].frequency.interval_unit + 'LY') as PLANS_DURATIONS,
        }));

export const filteredBluesnapPlans = (bluesnapPlans: IBluesnapPlan[]): IFilteredPlans[] =>
    bluesnapPlans
        .filter(
            (planInfo: IBluesnapPlan) =>
                planInfo.name.toLowerCase() === PLAN_NAMES.BASIC.toLowerCase() ||
                (
                    planInfo.name.toLowerCase() === PLAN_NAMES.STARTER.toLowerCase() &&
                    [PLANS_PRICINGS.STARTER_MONTHLY, PLANS_PRICINGS.STARTER_YEARLY].includes(planInfo.recurringChargeAmount)
                ) ||
                (
                    planInfo.name.toLowerCase() === PLAN_NAMES.PRO.toLowerCase() &&
                    [PLANS_PRICINGS.PRO_MONTHLY, PLANS_PRICINGS.PRO_YEARLY].includes(planInfo.recurringChargeAmount)
                ),
        )
        .map((planInfo: IBluesnapPlan) => ({
            id: planInfo.planId,
            planName:
                planInfo.name.toLowerCase() === PLAN_NAMES.PRO.toLowerCase() ? PLAN_NAMES.PRO : PLAN_NAMES.STARTER,
            duration:
                planInfo.chargeFrequency === PLANS_DURATIONS.MONTHLY_PLAN_DURATION
                    ? PLANS_DURATIONS.MONTHLY_PLAN_DURATION
                    : PLANS_DURATIONS.YEARLY_PLAN_DURATION,
        }));

import { SocialLink, SocialTypes } from 'types/interfaces';

const defaultTwitterResource = {
    type: SocialTypes.twitter,
    handle: '',
} as SocialLink;

const defaultDiscordResource = {
    type: SocialTypes.discord,
    handle: '',
} as SocialLink;

const defaultGithubResource = {
    type: SocialTypes.github,
    handle: '',
} as SocialLink;

const defaultWebsiteResource = {
    type: SocialTypes.website,
    handle: '',
} as SocialLink;

export const addDefaultResources = (resources: SocialLink[]): SocialLink[] => {
    const twitterResource = resources.find(({ type }) => type === SocialTypes.twitter) || defaultTwitterResource;
    const discordResource = resources.find(({ type }) => type === SocialTypes.discord) || defaultDiscordResource;
    const githubResource = resources.find(({ type }) => type === SocialTypes.github) || defaultGithubResource;
    const websiteResource = resources.find(({ type }) => type === SocialTypes.website) || defaultWebsiteResource;
    const otherResources = resources.filter(
        (resource) => ![twitterResource, discordResource, githubResource, websiteResource]?.includes(resource),
    );

    return [twitterResource, discordResource, githubResource, websiteResource, ...otherResources];
};

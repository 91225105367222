export const DAO_STACK_PLATFORM_NAME = 'DAOstack';
export const MOLOCH_PLATFORM_NAME = 'Moloch';
export const OPEN_LAW_PLATFORM_NAME = 'OpenLaw';
export const ARAGON_PLATFORM_NAME = 'Aragon';
export const COLONY_PLATFORM_NAME = 'Colony';
export const COMPOUND_GOVERNANCE_PLATFORM_NAME = 'Compound Governance';
export const SNAPSHOT_PLATFORM_NAME = 'Snapshot';
export const GNOSIS_SAFE_SNAPSHOT_PLATFORM_NAME = 'Gnosis Safe / Snapshot';
export const SUBSTRATE_PLATFORM_NAME = 'Substrate';
export const INDEPENDENT_PLATFORM_NAME = 'Independent';

export const INDEPENDENT_PLATFORM_ID = -1;
export const DAO_STACK_PLATFORM_ID = 1;
export const MOLOCH_PLATFORM_ID = 2;
export const OPEN_LAW_PLATFORM_ID = 3;
export const ARAGON_PLATFORM_ID = 4;
export const COLONY_PLATFORM_ID = 5;
export const COMPOUND_GOVERNANCE_PLATFORM_ID = 6;
export const SNAPSHOT_PLATFORM_ID = 11;
export const GNOSIS_SAFE_SNAPSHOT_PLATFORM_ID = 12;
export const SUBSTRATE_PLATFORM_ID = 13;

export const DAO_STACK_PLATFORM = {
    id: DAO_STACK_PLATFORM_ID,
    name: DAO_STACK_PLATFORM_NAME,
};

export const MOLOCH_PLATFORM = {
    id: MOLOCH_PLATFORM_ID,
    name: MOLOCH_PLATFORM_NAME,
};

export const OPEN_LAW_PLATFORM = {
    id: OPEN_LAW_PLATFORM_ID,
    name: OPEN_LAW_PLATFORM_NAME,
};

export const ARAGON_PLATFORM = {
    id: ARAGON_PLATFORM_ID,
    name: ARAGON_PLATFORM_NAME,
};

export const COLONY_PLATFORM = {
    id: COLONY_PLATFORM_ID,
    name: COLONY_PLATFORM_NAME,
};

export const COMPOUND_GOVERNANCE_PLATFORM = {
    id: COMPOUND_GOVERNANCE_PLATFORM_ID,
    name: COMPOUND_GOVERNANCE_PLATFORM_NAME,
};

export const SNAPSHOT_PLATFORM = {
    id: SNAPSHOT_PLATFORM_ID,
    name: SNAPSHOT_PLATFORM_NAME,
};

export const GNOSIS_SAFE_SNAPSHOT_PLATFORM = {
    id: GNOSIS_SAFE_SNAPSHOT_PLATFORM_ID,
    name: GNOSIS_SAFE_SNAPSHOT_PLATFORM_NAME,
};

export const SUBSTRATE_PLATFORM = {
    id: SUBSTRATE_PLATFORM_ID,
    name: SUBSTRATE_PLATFORM_NAME,
};

export const INDEPENDENT_PLATFORM = {
    id: INDEPENDENT_PLATFORM_ID,
    name: INDEPENDENT_PLATFORM_NAME,
};

import React, { FC, ReactElement } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './styles.module.scss';

import { Loader } from 'components';
import { TableHeader, PeopleItems } from './components';

import { IPeopleTableProps, IPeopleData } from 'types/interfaces';

export const PeopleTable: FC<IPeopleTableProps> = ({
    data,
    sortByParam,
    sortedParam,
    loadMoreData,
}: IPeopleTableProps): ReactElement => (
    <div className={styles.tableWrapper}>
        <div className={styles.tableContainer}>
            <TableHeader sortedParamName={sortedParam} sortByParam={sortByParam} />

            <InfiniteScroll
                next={loadMoreData}
                dataLength={data.length}
                hasMore={false}
                style={{ overflow: 'inherit' }}
                loader={data.length ? <Loader className={styles.loader} /> : null}
            >
                {data?.length >= 0 &&
                    data.map((item: IPeopleData) => (
                        <PeopleItems key={item.address} {...item} sortedParamName={sortedParam} />
                    ))}
            </InfiniteScroll>
        </div>
    </div>
);

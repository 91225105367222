import { IResourceData, IResourseDataWithCategories } from 'types/interfaces';

export const groupedResourcesData = (resources: IResourceData[]): IResourseDataWithCategories[] =>
    resources.reduce(
        (prevValue: IResourseDataWithCategories[], currentValue: IResourceData) =>
            prevValue.find((value: IResourseDataWithCategories) => value.resource_id === currentValue.resource_id)
                ? prevValue.map((value: IResourseDataWithCategories) =>
                      value.resource_id === currentValue.resource_id
                          ? {
                                ...value,
                                categoriesData: [
                                    ...value.categoriesData,
                                    { categoryId: currentValue.category_id, type: currentValue.type },
                                ],
                            }
                          : value,
                  )
                : [
                      ...prevValue,
                      {
                          ...currentValue,
                          categoriesData: [{ categoryId: currentValue.category_id, type: currentValue.type }],
                      },
                  ],
        [],
    );

import { isEmpty } from 'lodash';

import moment from 'moment';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { tableDataSort } from 'helpers/tableHelpers';

export const getFirsAndLastDates = <T, F>(data: T): { firstDate: string; latestDate: string } => {
    if (isEmpty(data)) {
        return {
            firstDate: DATA_NOT_FOUND,
            latestDate: DATA_NOT_FOUND,
        };
    }

    const sortedData = tableDataSort(
        Object.values(data).reduce((prev, current): F[] => [...(prev as F[]), ...(current as F[])], []),
        { paramName: 'createdAt', descending: false },
    ) as F[];

    const firstDate = sortedData[0]['createdAt' as keyof F];
    const lastDate = sortedData[sortedData.length - 1]['createdAt' as keyof F];

    return {
        firstDate: firstDate ? moment(firstDate).format('M.D.YYYY') : DATA_NOT_FOUND,
        latestDate: lastDate ? moment(lastDate).format('M.D.YYYY') : DATA_NOT_FOUND,
    };
};

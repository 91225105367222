import React, { FC } from 'react';
import { Button } from '@material-ui/core';

import classNames from 'classnames';
import styles from './styles.module.scss';

import { TooltipWrapper } from 'components';

import EtherscanLogo from './etherscan-logo.jpg';

interface EtherscanButtonProps {
    className?: string;
    userAddress: string;
}

export const EtherscanButton: FC<EtherscanButtonProps> = ({ className, userAddress }) => {
    const isEthereumAddress = userAddress.startsWith('0x');

    if (!isEthereumAddress) return null;

    const etherscanLink = `https://etherscan.io/address/${userAddress}`;

    const handleButtonClick = () => {
        window.open(etherscanLink, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className={classNames(styles.etherscanButtonWrapper, className)}>
            <TooltipWrapper title="view on Etherscan">
                <Button className={styles.etherscanButton} onClick={handleButtonClick}>
                    <img src={EtherscanLogo} className={styles.etherscanLogo} alt="Etherscan" />
                </Button>
            </TooltipWrapper>
        </div>
    );
};

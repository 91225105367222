import {
    COMMON_TABLE_FIELD_TYPES,
    ICommonTableHeader,
    ICommonTableHeaderSettings,
} from '../../../../../../CommonTable/types';
import {
    IOrganizationTrendsTable,
    IOrganizationTrendsTimePeriod,
    IOrganizationTrendsTotal1Year,
    IOrganizationTrendsTotal30Days,
    IOrganizationTrendsTotal3Months,
    IOrganizationTrendsTotal6Months,
    IOrganizationTrendsTotal7Days,
} from '../../../../../../../types/interfaces';

export enum ORGANIZATION_TRENDS_TABLE_PARAMS {
    TIME_PERIOD = 'timePeriod',
    TOTAL_24HOURS = 'total24Hours',
    TOTAL_7DAYS = 'total7Days',
    TOTAL_30DAYS = 'total30Days',
    TOTAL_6MONTHS = 'total6Months',
}

const tableSettingsFiveItems: ICommonTableHeaderSettings = {
    width: {
        desktop: '20%',
    },
};

const TIME_PERIOD: ICommonTableHeader<IOrganizationTrendsTimePeriod, ORGANIZATION_TRENDS_TABLE_PARAMS> = {
    title: 'Time Period',
    rowFieldKey: 'timePeriod',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsFiveItems,
};

const TOTAL_7DAYS: ICommonTableHeader<IOrganizationTrendsTotal7Days, ORGANIZATION_TRENDS_TABLE_PARAMS> = {
    title: '7 days',
    rowFieldKey: 'total7Days',
    fieldType: COMMON_TABLE_FIELD_TYPES.COLOR_TEXT,
    settings: tableSettingsFiveItems,
};

const TOTAL_30DAYS: ICommonTableHeader<IOrganizationTrendsTotal30Days, ORGANIZATION_TRENDS_TABLE_PARAMS> = {
    title: '30 days',
    rowFieldKey: 'total30Days',
    fieldType: COMMON_TABLE_FIELD_TYPES.COLOR_TEXT,
    settings: tableSettingsFiveItems,
};

const TOTAL_3MONTHS: ICommonTableHeader<IOrganizationTrendsTotal3Months, ORGANIZATION_TRENDS_TABLE_PARAMS> = {
    title: '3 months',
    rowFieldKey: 'total3Months',
    fieldType: COMMON_TABLE_FIELD_TYPES.COLOR_TEXT,
    settings: tableSettingsFiveItems,
};

const TOTAL_6MONTHS: ICommonTableHeader<IOrganizationTrendsTotal6Months, ORGANIZATION_TRENDS_TABLE_PARAMS> = {
    title: '6 months',
    rowFieldKey: 'total6Months',
    fieldType: COMMON_TABLE_FIELD_TYPES.COLOR_TEXT,
    settings: tableSettingsFiveItems,
};

const TOTAL_1YEAR: ICommonTableHeader<IOrganizationTrendsTotal1Year, ORGANIZATION_TRENDS_TABLE_PARAMS> = {
    title: '1 year',
    rowFieldKey: 'total1Year',
    fieldType: COMMON_TABLE_FIELD_TYPES.COLOR_TEXT,
    settings: tableSettingsFiveItems,
};

const addSettingsToTableHeaderData = <T>(
    data: ICommonTableHeader<T, ORGANIZATION_TRENDS_TABLE_PARAMS>[],
    settings: ICommonTableHeaderSettings,
): ICommonTableHeader<T, ORGANIZATION_TRENDS_TABLE_PARAMS>[] =>
    data.map((item) => ({
        ...item,
        settings,
    }));

const organizationTrendsTableHeaders: ICommonTableHeader<IOrganizationTrendsTable, ORGANIZATION_TRENDS_TABLE_PARAMS>[] =
    addSettingsToTableHeaderData(
        [TIME_PERIOD, TOTAL_1YEAR, TOTAL_6MONTHS, TOTAL_3MONTHS, TOTAL_30DAYS, TOTAL_7DAYS] as ICommonTableHeader<
            IOrganizationTrendsTable,
            ORGANIZATION_TRENDS_TABLE_PARAMS
        >[],
        tableSettingsFiveItems,
    );

export { organizationTrendsTableHeaders };

import React, { FC, ReactElement, memo } from 'react';

import styles from './styles.module.scss';

import { ImageLoader, TooltipWrapper, HrefLink } from 'components';

import { FormatUtils, LinkUtils } from 'utils';
import { defaultOrganizationPlaceholderImage } from 'assets';

import { IMAGE_TYPES } from 'constants/imageTypes';
import { ISearchedOrganization, ISearchedOrganizationCategoryInfo } from 'types/interfaces';

const MAX_DESCRIPTION_SYMBOLS = 100;

interface IOrganizationInfoCardProps {
    organizationData: ISearchedOrganization;
}

export const OrganizationInfoCard: FC<IOrganizationInfoCardProps> = memo(
    ({
        organizationData: { organizationId, logo, title, description, categories },
    }: IOrganizationInfoCardProps): ReactElement => (
        <HrefLink
            href={window.location.origin + LinkUtils.createDynamicLink(organizationId, 'organization')}
            className={styles.cardLinkWrapper}
        >
            <div className={styles.card}>
                <div className={styles.cardHeader}>
                    <ImageLoader
                        className={styles.organizationLogo}
                        src={
                            LinkUtils.isValidUrl(logo || '')
                                ? logo
                                : LinkUtils.getDaoLogoLinkFromBucket(logo, defaultOrganizationPlaceholderImage)
                        }
                        imageType={IMAGE_TYPES.USER_IMAGE}
                    />
                    <TooltipWrapper title={title}>
                        <h3 className={styles.organizationName}>{title}</h3>
                    </TooltipWrapper>
                </div>
                <div className={styles.cardBody}>
                    {categories && categories.length > 0 && (
                        <div className={styles.categoriesWrapper}>
                            {categories.map(({ id, title }: ISearchedOrganizationCategoryInfo) => (
                                <div className={styles.category} key={id}>
                                    <span className={styles.categoryName}>{title}</span>
                                </div>
                            ))}
                        </div>
                    )}
                    <h5 className={styles.description}>
                        {FormatUtils.truncateString(description || '', MAX_DESCRIPTION_SYMBOLS)}
                    </h5>
                </div>
            </div>
        </HrefLink>
    ),
);

import React, { FC, ReactElement } from 'react';

import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { CustomLineGraphTooltip, CustomSvgGradient } from 'components';
import { StatsLineGraphHeader } from './components';

import { chartLineGradientPrimary } from './constants';

import { IStatsLineGraphProps } from 'types/interfaces';

export const StatsLineGraph: FC<IStatsLineGraphProps> = ({
    data,
    headerData,
    graphClass,
}: IStatsLineGraphProps): ReactElement => {
    return (
        <>
            <StatsLineGraphHeader {...headerData} />

            <div className={classNames(styles.graph, graphClass)}>
                <ResponsiveContainer>
                    <LineChart data={data}>
                        {CustomSvgGradient({ id: 'chartLineGradientPrimary', data: chartLineGradientPrimary })}

                        <Line
                            type="monotone"
                            dataKey="value"
                            stroke="#FFFFFF"
                            strokeWidth={5}
                            isAnimationActive={false}
                            dot={false}
                        />
                        <Tooltip content={<CustomLineGraphTooltip valueName="AUM" />} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </>
    );
};

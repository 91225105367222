import React, { FC, ReactElement } from 'react';

import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';

import styles from './styles.module.scss';

import { CustomSvgGradient, Loader } from 'components';
import { CustomTooltip } from './components';

import { chartLineGradientPrimary } from './constants';

import { FormatUtils } from 'utils';
import { useAppSelector } from 'store';

import { IActivityLineGraphProps } from 'types/interfaces';

export const ActivityLineGraph: FC<IActivityLineGraphProps> = ({
    data,
    heading,
    value,
}: IActivityLineGraphProps): ReactElement => {
    const { statsDataLoading } = useAppSelector(({ feed }) => feed);

    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>
                <h4 className={styles.heading}>{heading} (all time)</h4>
                <h2 className={styles.value}>{FormatUtils.formatNumberShort(value, 1)}</h2>
            </div>
            <div className={styles.graph}>
                {statsDataLoading ? (
                    <Loader className={styles.loaderBox} size={40} />
                ) : (
                    <ResponsiveContainer>
                        <LineChart data={data}>
                            {CustomSvgGradient({ id: 'chartLineGradientPrimary', data: chartLineGradientPrimary })}

                            <Line
                                type="monotone"
                                dataKey="value"
                                stroke="url(#chartLineGradientPrimary)"
                                strokeWidth={2}
                                isAnimationActive={false}
                                dot={{ stroke: '#FFF', fill: '#FFF', r: 4 }}
                            />
                            <Tooltip content={<CustomTooltip valueName={heading} />} cursor={{ stroke: '#FFF' }} />
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </div>
        </div>
    );
};

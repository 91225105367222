import { axiosInstance } from 'services';
import { AxiosResponse } from 'axios';

import { getDeepDAOTokenFromStorage } from 'web3/storageHelper';

import {
    IListedOrganizationData,
    ISelfListingChain,
    ISelfListingPlatform,
} from 'types/interfaces/selfListing.interfaces';

export const SelfListingApi = {
    getSelfListingPlatforms: (): Promise<AxiosResponse<ISelfListingPlatform[]>> =>
        axiosInstance.get<ISelfListingPlatform[]>('self-listing/available-platforms'),
    getSelfListingChains: (): Promise<AxiosResponse<ISelfListingChain[]>> =>
        axiosInstance.get<ISelfListingChain[]>('self-listing/available-chains'),
    listOrganization: (data: FormData): Promise<AxiosResponse<any>> =>
        axiosInstance.post<any>('self-listing', data, {
            headers: {
                Authorization: `Bearer ${getDeepDAOTokenFromStorage()}`,
            },
        }),
    getListedOrganizationData: (organizationId: string): Promise<AxiosResponse<IListedOrganizationData>> =>
        axiosInstance.get<IListedOrganizationData>(`/self-listing/${organizationId}`, {
            headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
        }),
    updateListedOrganization: (data: any, organizationId: string): Promise<AxiosResponse<any>> =>
        axiosInstance.patch<any>(`self-listing/${organizationId}`, data, {
            headers: {
                Authorization: `Bearer ${getDeepDAOTokenFromStorage()}`,
            },
        }),
};

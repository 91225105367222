import React, { FC, ReactElement, ReactNode, memo } from 'react';

import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { InputsFilter, SelectsFilter } from './components';

import { SEARCHED_ORGANIZATION_FILTERS_TYPE } from 'constants/organizationsSearch';

import { ISearchedOrganizationFilterData, ISearchedOrganizationFilter } from 'types/interfaces';

interface IOrganizationsFilterProps {
    filterData: ISearchedOrganizationFilterData;
    updateOpenedFiltersIds: (filterId: number) => void;
    addNewFilter: (filterData: ISearchedOrganizationFilter) => void;
    removeSelectedFilter: (filterId: string) => void;
    selectedFiltersData: ISearchedOrganizationFilter[];
    openedFiltersIds: number[];
}

export const OrganizationsFilter: FC<IOrganizationsFilterProps> = memo(
    ({
        filterData,
        updateOpenedFiltersIds,
        addNewFilter,
        removeSelectedFilter,
        selectedFiltersData,
        openedFiltersIds,
    }: IOrganizationsFilterProps): ReactElement => {
        const filterIsOpenState = openedFiltersIds.includes(filterData.id);

        const renderFilterFieldByType = (type: SEARCHED_ORGANIZATION_FILTERS_TYPE): ReactNode => {
            switch (type) {
                case SEARCHED_ORGANIZATION_FILTERS_TYPE.SELECT: {
                    return (
                        <SelectsFilter
                            addNewFilter={addNewFilter}
                            removeSelectedFilter={removeSelectedFilter}
                            selectedFiltersData={selectedFiltersData}
                            filtersData={filterData.filtersData as ISearchedOrganizationFilter[]}
                        />
                    );
                }
                case SEARCHED_ORGANIZATION_FILTERS_TYPE.INPUT: {
                    return (
                        <InputsFilter
                            addNewFilter={addNewFilter}
                            removeSelectedFilter={removeSelectedFilter}
                            selectedFiltersData={selectedFiltersData}
                            filtersData={filterData.filtersData as ISearchedOrganizationFilter[]}
                        />
                    );
                }

                default:
                    return null;
            }
        };

        return (
            <div className={styles.filterWrapper}>
                <div className={styles.filterWrapperHeader} onClick={() => updateOpenedFiltersIds(filterData.id)}>
                    <h4 className={styles.title}>{filterData.title}</h4>
                    <ArrowBackIos
                        className={classNames(styles.arrowIcon, {
                            [styles.arrowIconUp]: filterIsOpenState,
                        })}
                    />
                </div>
                {filterIsOpenState && (
                    <div
                        className={classNames(styles.filtersWrapper, {
                            [styles.filtersWrapperNoOverflow]:
                                filterData.filterType === SEARCHED_ORGANIZATION_FILTERS_TYPE.INPUT,
                        })}
                    >
                        {filterData.filtersData?.length && renderFilterFieldByType(filterData.filterType)}
                    </div>
                )}
            </div>
        );
    },
);

import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { TooltipWrapper, ImageLoader } from 'components';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { FormatUtils, LinkUtils } from 'utils';
import { defaultUserPlaceholderImage } from 'assets';

import { IShareholderCardProps } from 'types/interfaces';

export const ShareholderCard: FC<IShareholderCardProps> = ({
    address,
    avatar,
    name,
    percentage,
}: IShareholderCardProps): ReactElement => {
    const username = name ? FormatUtils.truncateString(name, 16) : FormatUtils.formatUserAddress(address);

    return (
        <Link to={LinkUtils.createDynamicLink(address, 'user')}>
            <div className={styles.card}>
                <div className={styles.userInfo}>
                    <ImageLoader
                        src={avatar || defaultUserPlaceholderImage}
                        className={styles.avatar}
                        imageType={IMAGE_TYPES.USER_IMAGE}
                    />
                    <div className={styles.userDetails}>
                        {percentage && (
                            <h5 className={styles.shares}>
                                {percentage}
                                <span>%</span>
                            </h5>
                        )}
                        <TooltipWrapper title={name || address}>
                            <h4 className={styles.userName}>{username}</h4>
                        </TooltipWrapper>
                    </div>
                </div>
            </div>
        </Link>
    );
};

import { SocialTypes } from 'types/interfaces';

export const getPlaceholderNameByType = (type: string): string => {
    switch (type) {
        case SocialTypes.blog: {
            return 'blog';
        }

        default: {
            return type;
        }
    }
};

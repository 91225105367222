import React, { FC, ReactElement, useState, useRef, useCallback } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ImageLoader } from 'components';
import { UserOptions } from './components';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { useHistoryPush, useOutsideClick } from 'hooks';
import { useAppSelector } from 'store';

import { ILoggedUserCircleProps } from 'types/interfaces';

export const LoggedUserCircle: FC<ILoggedUserCircleProps> = ({
    toggleConnection,
}: ILoggedUserCircleProps): ReactElement => {
    const [optionsOpen, setOptionsOpen] = useState(false);
    const { loggedUserData } = useAppSelector(({ auth }) => auth);

    const ref = useRef(null);

    useOutsideClick(ref, () => setOptionsOpen(false));

    const { navigateToUserInfo, navigateToUserFollowings } = useHistoryPush(loggedUserData.address);

    const navigateToMyProfile = useCallback(() => {
        navigateToUserInfo();
        setOptionsOpen(false);
    }, [toggleConnection, navigateToUserInfo]);

    const navigateToMyFollowings = useCallback(() => {
        navigateToUserFollowings();
        setOptionsOpen(false);
    }, [toggleConnection, navigateToUserFollowings]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.circleWrapper} ref={ref}>
                <div
                    className={classNames(styles.container, {
                        [styles.avatarActive]: optionsOpen,
                    })}
                    onClick={() => setOptionsOpen(!optionsOpen)}
                >
                    <ImageLoader
                        src={loggedUserData.avatar}
                        imageType={IMAGE_TYPES.USER_IMAGE}
                        className={styles.userAvatar}
                    />
                </div>

                {optionsOpen && (
                    <UserOptions
                        toggleConnection={toggleConnection}
                        navigateToMyProfile={navigateToMyProfile}
                        navigateToMyFollowings={navigateToMyFollowings}
                    />
                )}
            </div>
        </div>
    );
};

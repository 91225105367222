import { IOrganizationDecision, IOrganizationDecisionTableData } from 'types/interfaces';

export const createDecisonsTableData = (decisionsData: IOrganizationDecision[]): IOrganizationDecisionTableData[] =>
    decisionsData?.length
        ? decisionsData.map(
              (
                  {
                      id,
                      proposer,
                      totalVotingPower,
                      sharesAgainst,
                      sharesFor,
                      sharesAbstain,
                      balanceFor,
                      balanceAbstain,
                      balanceAgainst,
                      status,
                      title,
                      votes,
                      createdAt,
                      avatar,
                      quorum,
                  }: IOrganizationDecision,
                  index: number,
              ) => ({
                  id,
                  proposer,
                  totalVotingPower,
                  sharesAgainst,
                  createdAt,
                  sharesFor,
                  sharesAbstain,
                  balanceFor,
                  balanceAbstain,
                  balanceAgainst,
                  status,
                  title,
                  votes,
                  avatar,
                  rank: index + 1,
                  quorum,
              }),
          )
        : [];

export const decisionsTableSearch = (
    decisionsData: IOrganizationDecisionTableData[],
    value: string,
): IOrganizationDecisionTableData[] =>
    value
        ? decisionsData.filter(({ title }: IOrganizationDecisionTableData) =>
              title?.toLowerCase()?.includes(value.toLowerCase()),
          )
        : decisionsData;

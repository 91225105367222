import React, { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import styles from './styles.module.scss';

import { FeedProposalHeader, FeedProposalDescription, FeedProposalInfoContainer, Loader } from 'components';

import { useAppDispatch, useAppSelector } from 'store';
import { FeedProposalActions } from 'store/actions';

export const FeedProposalPage: FC = (): ReactElement => {
    const { loading } = useAppSelector(({ feedProposal }) => feedProposal);
    const dispatch = useAppDispatch();

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        dispatch(FeedProposalActions.getFeedProposalData.request({ id }));
    }, [dispatch]);

    useEffect(
        () => () => {
            dispatch({ type: FeedProposalActions.CLEAR_FEED_PROPOSAL_DATA });
        },
        [dispatch],
    );

    return loading ? (
        <Loader />
    ) : (
        <>
            <FeedProposalHeader />

            <div className={styles.container}>
                <FeedProposalDescription />
                <FeedProposalInfoContainer />
            </div>
        </>
    );
};

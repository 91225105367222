import React, { FC, ReactElement } from 'react';

import { Checkbox } from '@material-ui/core';

import styles from './styles.module.scss';

import { ICheckboxComponentProps } from 'types/interfaces';

export const CheckboxComponent: FC<ICheckboxComponentProps> = ({
    checked,
    onCheckboxChange,
}: ICheckboxComponentProps): ReactElement => (
    <Checkbox
        checked={checked}
        onChange={onCheckboxChange}
        classes={{ root: styles.checkbox, checked: styles.checkboxChecked }}
    />
);

import {
    twitterLogoWhite,
    discordLogoWhite,
    githubLogoWhite,
    instagramLogoWhite,
    telegramLogoWhite,
    discourseLogoWhite,
    websiteLogoWhite,
    youtubeLogoWhite,
    mediumLogoWhite,
    notionIcon,
} from 'assets';

enum SOCIAL_TYPES {
    TWITTER = 'TWITTER',
    DISCORD = 'DISCORD',
    GITHUB = 'GITHUB',
    TELEGRAM = 'TELEGRAM',
    DISCOURSE = 'DISCOURSE',
    INSTAGRAM = 'INSTAGRAM',
    MAIN_WEBSITE = 'WEBSITE',
    YOUTUBE = 'YOUTUBE',
    MEDIUM = 'MEDIUM',
    NOTION = 'NOTION',
}

export const getSocialByType = (socialType: string): string => {
    switch (socialType) {
        case SOCIAL_TYPES.TWITTER: {
            return twitterLogoWhite;
        }

        case SOCIAL_TYPES.DISCORD: {
            return discordLogoWhite;
        }

        case SOCIAL_TYPES.GITHUB: {
            return githubLogoWhite;
        }

        case SOCIAL_TYPES.TELEGRAM: {
            return telegramLogoWhite;
        }

        case SOCIAL_TYPES.DISCOURSE: {
            return discourseLogoWhite;
        }

        case SOCIAL_TYPES.INSTAGRAM: {
            return instagramLogoWhite;
        }

        case SOCIAL_TYPES.MAIN_WEBSITE: {
            return websiteLogoWhite;
        }

        case SOCIAL_TYPES.YOUTUBE: {
            return youtubeLogoWhite;
        }

        case SOCIAL_TYPES.MEDIUM: {
            return mediumLogoWhite;
        }

        case SOCIAL_TYPES.NOTION: {
            return notionIcon;
        }

        default: {
            return websiteLogoWhite;
        }
    }
};

import React, { FC, ReactElement, useEffect, useMemo } from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { Loader } from 'components';

import { mixpanelService } from 'services';

import { useContentful } from 'react-contentful';
import { serializeContentfulData, IContentfulFaqItem } from './helper';

export const FAQPage: FC = (): ReactElement => {
    const { data, loading } = useContentful({
        contentType: 'faqlist',
    });

    const faqItems: IContentfulFaqItem[] = (data as { [key: string]: any })?.items[0].fields.faqitems || [];

    const faq = useMemo(() => serializeContentfulData(faqItems), [data]);

    useEffect(() => mixpanelService.track(mixpanelService.eventsGenerator.faqEvent()), []);

    return (
        <div className={styles.wrapper}>
            {loading && <Loader />}
            <div className={styles.blurBox} />

            <div className={styles.container}>
                <h2 className={styles.title}>FAQ</h2>

                {!loading && faq?.length && (
                    <div className={styles.questionsContainer}>
                        {faq.map(({ id, question, answer }) => (
                            <Link
                                key={id}
                                to={{
                                    pathname: `faq/${id}`,
                                    state: { id, question, answer },
                                }}
                                className={styles.link}
                            >
                                {question}
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

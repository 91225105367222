import React, { FC, ReactElement, useState, useRef, MouseEvent } from 'react';

import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { useOutsideClick } from 'hooks';

import { ISelfListingSelectProps, ISelfListingElement } from 'types/interfaces';

export const SelfListingSelect: FC<ISelfListingSelectProps> = ({
    data,
    placeholderTitle,
    selectedElementTitle,
    onSelect,
    error,
    onRemove,
}: ISelfListingSelectProps): ReactElement => {
    const [toggleMenuOpen, setToggleMenuOpen] = useState<boolean>(false);
    const ref = useRef(null);

    useOutsideClick(ref, () => setToggleMenuOpen(false));

    const toggleMenuChange = (value: ISelfListingElement) => {
        setToggleMenuOpen(!toggleMenuOpen);
        onSelect(value);
    };

    const onRemoveButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onRemove();
    };

    return (
        <div className={styles.selectWrapper} ref={ref}>
            <div
                className={classNames(styles.select, { [styles.selectActive]: toggleMenuOpen })}
                onClick={() => setToggleMenuOpen(!toggleMenuOpen)}
            >
                <span className={styles.selectTitle}>{selectedElementTitle || placeholderTitle}</span>
                {selectedElementTitle ? (
                    <Button className={styles.removeButton} onClick={onRemoveButtonClick}>
                        <CloseIcon className={styles.removeIcon} />
                    </Button>
                ) : (
                    <span className={styles.selectIcon} />
                )}
            </div>
            {error && <h6 className={styles.error}>{error}</h6>}
            <div className={classNames(styles.selectItems, { [styles.fadeIn]: toggleMenuOpen })}>
                {data.map(({ id, title }: ISelfListingElement) => (
                    <div className={styles.menuItem} onClick={() => toggleMenuChange({ id, title })} key={id}>
                        {title}
                    </div>
                ))}
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { DecisionsTable } from './components';

export const OrganizationProposals: FC = (): ReactElement => (
    <div className={styles.container}>
        <div className={styles.content}>
            <DecisionsTable />
        </div>
    </div>
);

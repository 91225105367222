enum TAB_TYPES {
    FOLLOWING = 'following',
    FOLLOWERS = 'followers',
}

enum FOLLOWING_TABLE_TAB_TYPES {
    PEOPLE = 'people',
    ORGANIZATIONS = 'organizations',
}

export { TAB_TYPES, FOLLOWING_TABLE_TAB_TYPES };

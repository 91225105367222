import React, { FC, ReactElement, useState, ChangeEvent, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseOutlined from '@material-ui/icons/CloseOutlined';

import styles from './styles.module.scss';

import { ITagInputProps } from 'types/interfaces';

export const TagInput: FC<ITagInputProps> = ({
    defaultTags = [],
    onChange,
    placeholder,
    tagMaxLength,
    ...props
}: ITagInputProps): ReactElement => {
    const [tags, setTags] = useState<string[]>(defaultTags);
    const [currentTag, setCurrentTag] = useState<string>(defaultTags.join(', '));

    const handleDelete = (tagToRemove: string) => () => {
        const changedTags = tags.filter((tag) => tag !== tagToRemove);
        setTags(changedTags);
        setCurrentTag(changedTags.join(', '));
    };

    const onTagValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = (event.target as HTMLInputElement).value;
        const tags = inputValue.split(',').map((tag) => tag.trim());
        const lastTag = tags[tags.length - 1];
        if (tagMaxLength && lastTag.length > tagMaxLength) {
            return event.preventDefault();
        }

        setCurrentTag(inputValue);
        setTags(
            inputValue
                .trim()
                .split(/\s*,\s*/)
                .filter((item: string) => item.trim()),
        );
    };

    useEffect(() => {
        if (onChange) {
            onChange(tags);
        }
    }, [tags]);

    return (
        <div className={styles.tagsContainer}>
            <div className={styles.tagsList}>
                {tags.map((tag: string, index: number) => (
                    <div key={`${tag}-${index}`} className={styles.tagsItem}>
                        <span className={styles.tagText}>{tag}</span>
                        <Button className={styles.deleteButton} onClick={handleDelete(tag)}>
                            <CloseOutlined className={styles.closeIcon} />
                        </Button>
                    </div>
                ))}
            </div>
            <TextField
                {...props}
                className={styles.tagsInputField}
                value={currentTag}
                placeholder={placeholder}
                onChange={onTagValueChange}
            />
            {tagMaxLength && <p className={styles.maxTagLength}>max {tagMaxLength} characters per tag</p>}
        </div>
    );
};

import { IAssetInfo } from 'types/interfaces';

const assetsMockUpData: IAssetInfo[] = [
    {
        address: '0x0000000000000000000000000',
        chainTitle: 'Optimism',
        description: 'liquid',
        type: 'treasury',
        url: 'https://0x0000000000000000000000000',
    },
    {
        address: '0x0000000000000000000000001',
        chainTitle: 'Snapshot Governance',
        description: 'liquid',
        type: 'governance',
        url: 'https://0x0000000000000000000000001',
    },
    {
        address: '0x0000000000000000000000002',
        chainTitle: 'Ethereum',
        description: 'Uniswap',
        type: 'token',
        url: 'https://0x0000000000000000000000002',
    },
    {
        address: '0x00000000000000000000000003',
        chainTitle: 'Governor',
        description: 'Governor',
        type: 'governance',
        url: 'https://0x00000000000000000000000003',
    },
    {
        address: '0x00000000000000000000000004',
        chainTitle: 'Ethereum',
        description: 'vesting',
        type: 'treasury',
        url: 'https://0x00000000000000000000000004',
    },
    {
        address: '0x00000000000000000000000005',
        chainTitle: 'Snapshot Governance',
        description: 'liquid',
        type: 'governance',
        url: 'https://0x00000000000000000000000005',
    },
    {
        address: '0x0000000000000000000000006',
        chainTitle: 'Ethereum',
        description: 'Uniswap',
        type: 'token',
        url: 'https://0x0000000000000000000000006',
    },
];

export { assetsMockUpData };

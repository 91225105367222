import React, { FC, ReactElement } from 'react';

import { useMediaQuery, Button } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { SearchField } from 'components';

import { IOrganizationsTabController } from 'types/interfaces';

export const TabsController: FC<IOrganizationsTabController> = ({
    tabSearchValue,
    onTabSearch,
    toggleCategoriesOpen,
    categoriesContainerOpen,
    loading,
}: IOrganizationsTabController): ReactElement => {
    const isTablet = useMediaQuery('(max-width: 767.98px)');

    const onInputChange = (inputValue: string) => {
        onTabSearch(inputValue);
    };

    return (
        <div className={styles.tabsWrapper}>
            <div className={styles.tabsContainer}>
                <div className={classNames(styles.tabItem)}>
                    <div className={styles.itemContent}>
                        <span>{isTablet ? 'Top Organizations' : 'top organizations'}</span>

                        <div className={styles.optionsContainer}>
                            <SearchField inputValue={tabSearchValue} setInputValue={onInputChange} />

                            <Button
                                className={classNames(styles.categoriesButton, {
                                    [styles.categoriesButtonActive]: categoriesContainerOpen,
                                })}
                                onClick={(event) => toggleCategoriesOpen(event)}
                                disabled={loading}
                            >
                                <span className={styles.title}>categories</span>
                                <ArrowDropDown className={styles.arrowIcon} />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

import { AnyAction, Reducer } from '@reduxjs/toolkit';

import type { ISearchState, IReducerAction, ISearchUserAddressPayload, ISearchPayload } from 'types/interfaces';

import { SearchActions } from 'store/actions';

const defaultState: ISearchState = {
    organizationsSearchResults: [],
    organizationsSearchByCategoryResults: [],
    peopleSearchResults: [],
    topThreeOrganizations: [],
    topThreeUsers: [],
    searchLoading: false,
    topUserOrganizations: [],
    userOrganizationsSearchResults: [],
};

export const searchReducer: Reducer<ISearchState> = (
    state: ISearchState = defaultState,
    action: AnyAction,
): ISearchState => {
    const { type, payload } = <IReducerAction>action;

    switch (type) {
        case SearchActions.SEARCH: {
            return {
                ...state,
                organizationsSearchResults: [],
                organizationsSearchByCategoryResults: [],
                peopleSearchResults: [],
                searchLoading: true,
            };
        }

        case SearchActions.SEARCH_SUCCESS: {
            return {
                ...state,
                ...(payload as ISearchPayload),
                searchLoading: false,
            };
        }

        case SearchActions.SEARCH_FAILURE: {
            return {
                ...state,
                searchLoading: false,
            };
        }

        case SearchActions.SEARCH_TOP_THREE: {
            return {
                ...state,
                searchLoading: true,
            };
        }

        case SearchActions.SEARCH_TOP_THREE_SUCCESS: {
            return {
                ...state,
                ...(payload as ISearchPayload),
                searchLoading: false,
            };
        }

        case SearchActions.SEARCH_TOP_THREE_FAILURE: {
            return {
                ...state,
                searchLoading: false,
            };
        }

        case SearchActions.SEARCH_USER_ADDRESS: {
            return {
                ...state,
                searchLoading: true,
            };
        }

        case SearchActions.SEARCH_USER_ADDRESS_SUCCESS: {
            return {
                ...state,
                ...(payload as ISearchUserAddressPayload),
                searchLoading: false,
            };
        }

        case SearchActions.SEARCH_USER_ADDRESS_FAILURE: {
            return {
                ...state,
                searchLoading: false,
            };
        }

        case SearchActions.CLEAR_SEARCH: {
            return {
                ...state,
                organizationsSearchResults: [],
                organizationsSearchByCategoryResults: [],
                peopleSearchResults: [],
            };
        }

        default: {
            return state;
        }
    }
};

import React, { FC, ReactElement, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';

import styles from './styles.module.scss';

import { TableDataNotFound, SearchField, Loader } from 'components';

import { useAppSelector } from 'store';

export const VotingCoalitionsTable: FC = (): ReactElement => {
    const { organizationCoalitionsData, coalitionsDataLoading } = useAppSelector(({ organization }) => organization);
    const isTablet = useMediaQuery('(max-width:767.98px)');

    const [tabSearchValue, setTabSearchValue] = useState<string>('');

    return (
        <div className={styles.tableContent}>
            <div className={styles.tableOptions}>
                <h4 className={styles.title}>Voting Coalitions</h4>
                {!isTablet && (
                    <SearchField forTable={true} inputValue={tabSearchValue} setInputValue={setTabSearchValue} />
                )}
            </div>

            {coalitionsDataLoading && organizationCoalitionsData.length === 0 && (
                <div className={styles.loaderBox}>
                    <Loader size={40} className={styles.membersLoader} />
                </div>
            )}

            {/*Temporary*/}
            <TableDataNotFound forTable />
        </div>
    );
};

import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { TooltipWrapper, ImageLoader, HrefLink } from 'components';
import { IMAGE_TYPES } from 'constants/imageTypes';
import { LinkUtils } from 'utils';
import { defaultUserPlaceholderImage } from 'assets';
import { ICoalitionMemberData } from 'types/interfaces';

interface MemberAddressesListProps extends ICoalitionMemberData {
    isHovered: boolean;
}

export const MemberAddressesList: FC<MemberAddressesListProps> = ({
    address,
    name,
    avatar,
    isHovered,
}): ReactElement => {
    return (
        <div className={classNames(styles.addressContainer, { [styles.rowHover]: isHovered })}>
            <ImageLoader
                src={avatar ? LinkUtils.getImageLinkFromIPFS(avatar || '') : defaultUserPlaceholderImage}
                className={styles.avatar}
                imageType={IMAGE_TYPES.USER_IMAGE}
            />
            <div className={styles.coalitionDetails}>
                <TooltipWrapper title={name}>
                    <h5 className={styles.userNameAddress}>
                        <HrefLink href={LinkUtils.createDynamicLink(address, 'user')} className={styles.link}>
                            <>{name}</>
                        </HrefLink>
                    </h5>
                </TooltipWrapper>
            </div>
        </div>
    );
};

import { useAppSelector } from 'store';
import { IOrganizationDao, IUseOrganizationDaosHook } from 'types/interfaces';

export const useOrganizationDaos = (): IUseOrganizationDaosHook => {
    const { organizationDaos } = useAppSelector(({ organization }) => organization);

    const organizationHaveDaos = Boolean(organizationDaos?.length);

    const organizationFirstDaoId = organizationHaveDaos ? organizationDaos[0].daoId : null;

    const createDaosList = (maxLength = 0) =>
        organizationDaos.length > maxLength
            ? organizationDaos.map((dao: IOrganizationDao) => ({
                  id: dao.daoId,
                  text: `${dao.name} (${dao.platformTitle})`,
                  value: dao.daoId,
              }))
            : [];

    const createTabsList = (title: string) => ({
        title: title,
        tabsList: createDaosList(),
    });

    return {
        organizationHaveDaos,
        createTabsList,
        createDaosList,
        organizationFirstDaoId,
    };
};

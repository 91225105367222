import React, { FC, ReactElement, useCallback, useMemo } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ClickAwayListener } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

import moment from 'moment';

import styles from './styles.module.scss';

import { Loader, BrainstormSelect, BrainstormSubstormSelect, DiscussionCommentTextarea } from 'components';

import { INewDiscussionData, timeframeOptions, GENERAL_SUBSTORM_TITLE } from './constants';
import { BRAINSTORM_ACTION_TYPE } from 'constants/discussionsElements';

import { useAppSelector, useAppDispatch } from 'store';
import { OrganizationActions } from 'store/actions';

import { IDiscussionItems, IOrganizationSubstorm, ISelectOptions } from 'types/interfaces';

const DEFAULT_THRESHOLD_VALUE = 1;

export const StartDiscussionModal: FC = (): ReactElement => {
    const {
        organizationData: { id },
        createDiscussionLoading,
        discussionForEditingId,
        brainstormActionType,
        organizationDiscussions,
        substormsList,
    } = useAppSelector(({ organization }) => organization);

    const {
        loggedUserData: { address },
    } = useAppSelector(({ auth }) => auth);

    const dispatch = useAppDispatch();

    const discussionForEditingData = useMemo(
        () => organizationDiscussions.find(({ id }: IDiscussionItems) => id === discussionForEditingId),
        [organizationDiscussions, discussionForEditingId],
    );

    const brainstormInitialValues = useMemo(() => {
        const timeframe = moment(discussionForEditingData?.endDate as string)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss');
        const diff = moment.duration(
            moment(timeframe).diff(
                moment(discussionForEditingData?.createdAt as string)
                    .utc()
                    .format('YYYY-MM-DDTHH:mm:ss'),
            ),
        );

        const days = diff.days();

        const endDate =
            brainstormActionType === BRAINSTORM_ACTION_TYPE.ADDING
                ? { ...timeframeOptions[0], dirty: false }
                : isNaN(days)
                ? { ...timeframeOptions[0], dirty: false }
                : {
                      ...(timeframeOptions.find(
                          ({ value }: ISelectOptions) => Number(value) === days,
                      ) as ISelectOptions),
                      dirty: true,
                  };

        return {
            title: discussionForEditingData?.title || '',
            text: discussionForEditingData?.text || '',
            endDate,
            subStorms: discussionForEditingData?.substorms?.length
                ? discussionForEditingData.substorms
                : substormsList.filter(({ title }: IOrganizationSubstorm) => title === GENERAL_SUBSTORM_TITLE),
        };
    }, [brainstormActionType, discussionForEditingData]);

    const formik = useFormik({
        initialValues: brainstormInitialValues,
        validationSchema: Yup.object({
            title: Yup.string().required('Required'),
            text: Yup.string().required('Required'),
        }),

        onSubmit: (values) => {
            const discussionData = {
                title: values.title,
                text: values.text,
            } as INewDiscussionData;

            if (brainstormActionType === BRAINSTORM_ACTION_TYPE.ADDING) {
                discussionData.substorms = values.subStorms.map(({ id }: IOrganizationSubstorm) => id);
            }

            if (values.endDate.value) {
                if (values.endDate.dirty) discussionData.endDate = discussionForEditingData?.endDate;
                else
                    discussionData.endDate = moment()
                        .add(values.endDate.value as string, 'd')
                        .format();
            }

            if (brainstormActionType === BRAINSTORM_ACTION_TYPE.ADDING)
                dispatch(
                    OrganizationActions.createDiscussion.request({
                        organizationId: id,
                        data: {
                            ...discussionData,
                            creator: address,
                            discussionThreshold: DEFAULT_THRESHOLD_VALUE,
                            votingThreshold: DEFAULT_THRESHOLD_VALUE,
                        },
                    }),
                );
            else
                dispatch(
                    OrganizationActions.updateOrganizationDiscussion.request({
                        organizationId: id,
                        discussionId: discussionForEditingId,
                        data: discussionData,
                    }),
                );
        },
    });

    const { touched, errors, values, setFieldTouched, setFieldValue, handleSubmit } = useMemo(() => formik, [formik]);

    const onTimeframeClick = useCallback(
        (option: ISelectOptions) => setFieldValue('endDate', { ...option, dirty: false }),
        [setFieldValue],
    );

    const onModalClose = useCallback(() => {
        dispatch({
            type: OrganizationActions.TOGGLE_DISSCUSION_MODAL_OPEN,
            payload: {
                discussionForEditingId: null,
                brainstormActionType: null,
            },
        });
    }, []);

    const onSubStormClick = useCallback(
        (substorm: IOrganizationSubstorm) => {
            const subStorms = values.subStorms;
            const newSubStormsValues = subStorms.some((item) => item.id === substorm.id)
                ? subStorms.filter((item) => item.id !== substorm.id)
                : [...subStorms, substorm];
            setFieldValue('subStorms', newSubStormsValues);
        },
        [values, setFieldValue],
    );

    return (
        <div className={styles.modalContainer}>
            {createDiscussionLoading && <Loader />}

            <ClickAwayListener onClickAway={onModalClose}>
                <div className={styles.modal}>
                    <div className={styles.modalHeader}>
                        <h3 className={styles.title}>Start a Brainstorm</h3>
                        <Button className={styles.closeButton} onClick={onModalClose}>
                            <CloseIcon className={styles.closeIcon} />
                        </Button>
                    </div>
                    <div className={styles.modalBody}>
                        <form onSubmit={handleSubmit} className={styles.form}>
                            <div className={styles.selectWrapper}>
                                <p className={styles.title}>Select up to 2 SubStorms for your brainstorm:</p>
                                <BrainstormSubstormSelect
                                    options={substormsList}
                                    value={formik.values.subStorms}
                                    onSelect={onSubStormClick}
                                    isAllItemsDisabled={brainstormActionType === BRAINSTORM_ACTION_TYPE.EDITING}
                                />
                            </div>
                            <div className={styles.selectWrapper}>
                                <p className={styles.title}>Timeframe for expressing support</p>
                                <BrainstormSelect
                                    options={timeframeOptions}
                                    value={formik.values.endDate}
                                    onSelect={onTimeframeClick}
                                />
                            </div>
                            <div>
                                <p className={styles.title}>Brainstorm title</p>
                                <input
                                    className={styles.input}
                                    name="title"
                                    value={values.title}
                                    onChange={formik.handleChange}
                                    onFocus={() => setFieldTouched('title', true)}
                                />
                                {touched.title && errors.title && <p className={styles.errorText}>{errors.title}</p>}
                            </div>
                            <div className={styles.textareaSection}>
                                <p className={styles.title}>Get into details here</p>
                                <DiscussionCommentTextarea
                                    placeholder=""
                                    value={formik.values.text}
                                    setValue={(value: string) => formik.setFieldValue('text', value)}
                                    onFocus={() => setFieldTouched('text', true)}
                                    name="text"
                                    className={styles.textAreaWrapper}
                                />
                                {touched.text && errors.text && <p className={styles.errorText}>{errors.text}</p>}
                            </div>
                            <Button
                                className={styles.publishButton}
                                type="submit"
                                disabled={!(formik.isValid && formik.dirty)}
                            >
                                publish
                            </Button>
                        </form>
                    </div>
                </div>
            </ClickAwayListener>
        </div>
    );
};

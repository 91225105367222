import React, { FC, ReactElement } from 'react';

import { IFollowPeopleTableProps, ITableHeaderWithSortingProps } from 'types/interfaces';

import { PeopleTable } from 'components/FollowingPageBody/components/PeopleTable';

export const FollowingPeopleTable: FC<IFollowPeopleTableProps & ITableHeaderWithSortingProps> = (
    props,
): ReactElement => {
    return <PeopleTable {...props} />;
};

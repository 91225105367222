import { ITableTabs } from 'types/interfaces';

const tabItems: ITableTabs = {
    title: 'Members',
    tabsList: [
        { value: 'council', text: 'Council', id: 1 },
        { value: 'technicalCommittees', text: 'Technical Committees', id: 2 },
        { value: 'democracy', text: 'Democracy', id: 3 },
    ],
};

enum MEMBERS_PARAMS {
    RANK = 'rank',
    ADDRESS_USERNAME = 'name',
    TOKENS_SHARE_PARAM = 'tokenShares',
    SHARE_PERCENT_PARAM = 'tokenSharesPercentage',
    PROPOSALS_CREATED_PARAM = 'proposalsCount',
    VOTED_PARAM = 'votesCount',
    PROPOSAL_WIN_PARAM = 'proposalsWonPercentage',
    VOTED_WIN_PARAM = 'votesWonPercentage',
}

export { MEMBERS_PARAMS, tabItems };

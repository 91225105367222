import React, { FC, useEffect } from 'react';

import { useMediaQuery } from '@material-ui/core';

import classNames from 'classnames';

import styles from 'components/App/styles.module.scss';

import {
    FooterMobile,
    HeaderDesktop,
    HeaderMobile,
    Footer,
    SelfListingModal,
    StartDiscussionModal,
    LeftSidebarMenu,
} from 'components';

import { MAIN_PAGES_BACKGROUNDS } from 'constants/mainPagesBackgrounds';

import { useAppSelector } from 'store';

export const withHeader =
    (
        ContentComponent: FC,
        disableHeader: boolean,
        backgroundColor: MAIN_PAGES_BACKGROUNDS,
        alwaysShowFooter: boolean,
    ): FC =>
    (props) => {
        const { selfListingModalOpen } = useAppSelector(({ selfListing }) => selfListing);
        const { discussionModalOpen } = useAppSelector(({ organization }) => organization);
        const { sidebarIsExpanded } = useAppSelector(({ sidebar }) => sidebar);

        const isLargeTablet = useMediaQuery('(max-width: 991.98px)');
        const isTablet = useMediaQuery('(max-width: 767.98px)');
        const isMobile = useMediaQuery('(max-width: 575.98px)');

        useEffect(() => {
            document.body.style.overflow = selfListingModalOpen ? 'hidden' : 'unset';
        }, [selfListingModalOpen]);

        useEffect(() => {
            document.body.style.overflow = discussionModalOpen ? 'hidden' : 'unset';
        }, [discussionModalOpen]);

        return (
            <div className={styles.pageContainer}>
                {!isLargeTablet && <LeftSidebarMenu />}

                <div
                    className={classNames(styles.pageContentWrapper, {
                        [styles.pageHeaderDisabled]: disableHeader,
                        [styles.backgroundGhostWhite]: backgroundColor === MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
                        [styles.backgroundWhite]: backgroundColor === MAIN_PAGES_BACKGROUNDS.WHITE,
                        [styles.backgroundBlue]: backgroundColor === MAIN_PAGES_BACKGROUNDS.BLUE,
                        [styles.sidebarIsExpanded]: sidebarIsExpanded,
                    })}
                >
                    <div className={styles.headerAndContent}>
                        {disableHeader ? null : isLargeTablet ? <HeaderMobile /> : <HeaderDesktop />}

                        <ContentComponent {...props} />
                    </div>

                    {alwaysShowFooter ? isTablet ? <FooterMobile /> : <Footer /> : isMobile ? <FooterMobile /> : null}

                    {selfListingModalOpen && <SelfListingModal />}

                    {discussionModalOpen && <StartDiscussionModal />}
                </div>
            </div>
        );
    };

import React, { FC, ReactElement, useRef, memo } from 'react';

import { Button, useMediaQuery } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { IMembersSearchFieldProps } from 'types/interfaces';

export const SearchField: FC<IMembersSearchFieldProps> = memo(
    ({ membersSearch, setMembersSearch, onInputClick, isInputOpen }: IMembersSearchFieldProps): ReactElement => {
        const inputRef = useRef<HTMLInputElement>(null);
        const isMobile = useMediaQuery('(max-width:575.98px)');

        return (
            <div
                className={classNames(styles.searchContainer, {
                    [styles.searchContainerMobile]: isInputOpen,
                })}
            >
                <Button
                    className={styles.searchButton}
                    disableRipple
                    onClick={() => {
                        onInputClick(!isInputOpen);
                        setMembersSearch('');
                        inputRef?.current && inputRef?.current.focus();
                    }}
                >
                    <SearchIcon className={styles.searchIcon} />
                    {!isInputOpen && <span className={styles.title}>Members</span>}
                </Button>

                {isInputOpen && (
                    <div className={styles.inputContainer}>
                        <input
                            className={classNames(styles.input, {
                                [styles.inputOpen]: isInputOpen,
                            })}
                            value={membersSearch}
                            onChange={(e) => setMembersSearch(e.target.value)}
                            ref={inputRef}
                            placeholder={isMobile ? 'Member search' : ''}
                        />

                        <Button
                            className={classNames(styles.clearFieldButton, {
                                [styles.clearButtonActive]: membersSearch,
                            })}
                            disableRipple
                            onClick={() => setMembersSearch('')}
                        >
                            <CloseIcon className={styles.clearFieldIcon} />
                        </Button>
                    </div>
                )}
            </div>
        );
    },
);

import React, { FC, ReactElement } from 'react';
import { TooltipProps } from 'recharts';
import styles from './styles.module.scss';

interface CustomLegendProps extends TooltipProps<number, string> {
    firstColumn: string;
    secondColumn: string;
}

export const CustomLegend: FC<CustomLegendProps> = ({ firstColumn, secondColumn }): ReactElement => {
    return (
        <div className={styles.legend}>
            <div className={styles.barColoringInfo}>
                <span className={styles.firstColoring}>{firstColumn}</span>
                <span className={styles.secondColoring}>{secondColumn}</span>
            </div>
        </div>
    );
};

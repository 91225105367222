import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TableDataNotFound } from 'components';
import { TableHeader, TableRow } from './components';

import { IProposalTableData, IUserProposalData } from 'types/interfaces';

export const ProposalsTable: FC<IProposalTableData> = ({ proposalsData }: IProposalTableData): ReactElement => (
    <>
        {proposalsData ? (
            <>
                <TableHeader />

                <div className={styles.rowsWrapper}>
                    {proposalsData.map(
                        (
                            {
                                proposalNativeId,
                                fundsRequested,
                                title,
                                createdAt,
                                status,
                                platform,
                                successfulVote,
                                voteChoice,
                                choices,
                            }: IUserProposalData,
                            index: number,
                        ) => (
                            <TableRow
                                key={proposalNativeId}
                                fundsRequested={fundsRequested}
                                title={title}
                                createdAt={createdAt}
                                status={status}
                                index={index}
                                platform={platform}
                                successfulVote={successfulVote}
                                voteChoice={voteChoice}
                                choices={choices}
                            />
                        ),
                    )}
                </div>
            </>
        ) : (
            <TableDataNotFound forTable removeBoxShadow />
        )}
    </>
);

import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

import { IOrganizationVoterGroups } from 'types/interfaces';
import { FormatUtils } from 'utils';
import { DATA_NOT_FOUND } from 'constants/dataNotFound';

export const TableRow = ({
    organizationVoterGroups,
}: {
    organizationVoterGroups: IOrganizationVoterGroups;
}): ReactElement => {
    const numberOfLoyalVoters = FormatUtils.checkIfDataNotExist(organizationVoterGroups?.numberOfLoyalVoters)
        ? DATA_NOT_FOUND
        : `${FormatUtils.formatNumberWithCommas(
              Number(organizationVoterGroups?.numberOfLoyalVoters),
              0,
          )} (CSV available)`;
    const numberOfMissingVoters = FormatUtils.checkIfDataNotExist(organizationVoterGroups?.numberOfMissingVoters)
        ? DATA_NOT_FOUND
        : `${FormatUtils.formatNumberWithCommas(
              Number(organizationVoterGroups?.numberOfMissingVoters),
              0,
          )} (CSV available)`;
    const numberOfNonParticipatingTokenHolder = FormatUtils.checkIfDataNotExist(
        organizationVoterGroups?.numberOfNonParticipatingTokenHolders,
    )
        ? DATA_NOT_FOUND
        : `${FormatUtils.formatNumberWithCommas(
              Number(organizationVoterGroups?.numberOfNonParticipatingTokenHolders),
              0,
          )} (CSV available)`;

    return (
        <div className={styles.tableRow}>
            <div className={styles.numberOfLoyalVoters}>{numberOfLoyalVoters}</div>
            <div className={styles.numberOfMissingVoters}>{numberOfMissingVoters}</div>
            <div className={styles.numberOfNonParticipatingTokenHolder}>{numberOfNonParticipatingTokenHolder}</div>
        </div>
    );
};

import React, { FC, ReactElement, useRef, useState } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { sumBy as _sumBy } from 'lodash';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import styles from './styles.module.scss';

import { TagInput, AvatarUploader } from 'components';

import { useAppDispatch, useAppSelector } from 'store';
import { UserActions } from 'store/actions';
import { validUrl } from 'helpers';
import { addDefaultResources } from './utils';

import { IEditUserDetailsModalProps, SocialLink, IPeopleContactSkill, IPeopleContactInterest } from 'types/interfaces';

const MAX_NAME_CHARS = 100;
const MAX_TAG_CHARS = 25;
const MAX_ABOUT_WORDS = 101;
const MAX_ABOUT_CHARS = 600;
const MAX_TAGLINE_WORDS = 16;
const MAX_TAGLINE_CHARS = 100;
const TOTAL_INTERESTS_MAX_CHARS = 1000;
const TOTAL_SKILLS_MAX_CHARS = 1000;

export const EditUserDetailsModal: FC<IEditUserDetailsModalProps> = ({
    toggleModalOpen,
    setUploadedImageSrc,
}: IEditUserDetailsModalProps): ReactElement => {
    const ref = useRef(null);

    const dispatch = useAppDispatch();

    const {
        userData: { address, personalInfo },
    } = useAppSelector(({ user }) => user);

    const {
        people_contact: peopleContact,
        skills: currentSkills = [],
        interests: currentInterests = [],
        resources: currentResources = [],
    } = personalInfo || {};

    const skillNames = (currentSkills as IPeopleContactSkill[]).map(({ name }) => name);
    const interestNames = (currentInterests as IPeopleContactInterest[]).map(({ name }) => name);
    const defaultResources = addDefaultResources(currentResources);
    const [skills, setSkills] = useState<string[]>(skillNames);
    const [interests, setInterests] = useState<string[]>(interestNames);
    const [resources] = useState<SocialLink[]>(defaultResources);

    const handleSkillsChange = (values: string[]) => {
        setSkills(values);
    };

    const handleInterestsChange = (values: string[]) => {
        setInterests(values);
    };

    const formik = useFormik({
        initialValues: {
            name: peopleContact?.name || '',
            tagline: peopleContact?.tagline || '',
            about: peopleContact?.about || '',
            skills: skills || [],
            interests: interests || [],
            resources: [],
        },
        validationSchema: Yup.object({
            name: Yup.string(),
            tagline: Yup.string(),
            about: Yup.string(),
            skills: Yup.array()
                .of(Yup.string())
                .test(
                    'skills-total-length',
                    `Total skills length should not exceed ${TOTAL_SKILLS_MAX_CHARS} chars`,
                    (values) =>
                        Array.isArray(values) && _sumBy(values, (skill) => skill?.length || 0) < TOTAL_SKILLS_MAX_CHARS,
                ),
            interests: Yup.array()
                .of(Yup.string())
                .test(
                    'interests-total-length',
                    `Total interests length should not exceed ${TOTAL_INTERESTS_MAX_CHARS} chars`,
                    (values) =>
                        Array.isArray(values) &&
                        _sumBy(values, (interest) => interest?.length || 0) < TOTAL_INTERESTS_MAX_CHARS,
                ),
            resources: Yup.array().of(
                Yup.object().shape({
                    id: Yup.string(),
                    type: Yup.string().required(),
                    handle: Yup.string().required().matches(validUrl),
                }),
            ),
        }),
        onSubmit: (values) => {
            const data = {
                ...values,
                address,
                skills,
                interests,
                resources,
            };

            dispatch(UserActions.updateUserData.request(data));

            toggleModalOpen();
        },
    });
    const areResourcesValid = resources.every(({ handle }) => !handle || validUrl.test(handle));

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modal} ref={ref}>
                <div className={styles.modalHeader}>
                    <div className={styles.titleSection}>
                        <h3 className={styles.title}>Create Your Governance Identity</h3>
                    </div>
                    <Button className={styles.closeButton} onClick={toggleModalOpen}>
                        <CloseIcon />
                    </Button>
                </div>
                <div className={styles.modalBody}>
                    <form
                        className={styles.form}
                        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                        onSubmit={formik.handleSubmit}
                    >
                        <div className={styles.section}>
                            <p className={styles.sectionTitle}>Name</p>
                            <TextareaAutosize
                                className={styles.textarea}
                                placeholder="What would you want to be called on DeepDAO?"
                                name="name"
                                value={formik.values.name}
                                maxLength={MAX_NAME_CHARS}
                                onChange={formik.handleChange}
                            />
                            <p className={styles.maxWordsLength}>max {MAX_NAME_CHARS} chars</p>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.sectionTitle}>Say a few words</p>
                            <TextareaAutosize
                                className={styles.textarea}
                                placeholder="A great one-liner describing you"
                                name="tagline"
                                value={formik.values.tagline}
                                maxLength={MAX_TAGLINE_CHARS}
                                onChange={(e) => {
                                    const spacesLength = e.target.value.trim().split(/\S+/).length;
                                    if (spacesLength > 16) {
                                        e.preventDefault();
                                    } else {
                                        formik.handleChange(e);
                                    }
                                }}
                            />
                            <p className={styles.maxWordsLength}>
                                max {MAX_TAGLINE_WORDS - 1} words ({MAX_TAGLINE_CHARS} chars max)
                            </p>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.sectionTitle}>Say a bit more</p>
                            <TextareaAutosize
                                className={styles.textarea}
                                placeholder="Introduce yourself to the community"
                                name="about"
                                value={formik.values.about}
                                maxLength={MAX_ABOUT_CHARS}
                                onChange={(e) => {
                                    const spacesLength = e.target.value.trim().split(/\S+/).length;
                                    if (spacesLength > 101) {
                                        e.preventDefault();
                                    } else {
                                        formik.handleChange(e);
                                    }
                                }}
                            />
                            <p className={styles.maxWordsLength}>
                                max {MAX_ABOUT_WORDS - 1} words ({MAX_ABOUT_CHARS} chars max)
                            </p>
                        </div>
                        <div className={styles.section}>
                            <p className={styles.sectionTitle}>Skills</p>
                            <TagInput
                                defaultTags={skills}
                                placeholder="Add a skill"
                                onChange={handleSkillsChange}
                                tagMaxLength={MAX_TAG_CHARS}
                            />
                        </div>
                        <div className={styles.section}>
                            <p className={styles.sectionTitle}>Interests</p>
                            <TagInput
                                defaultTags={interests}
                                placeholder="Add an interest"
                                onChange={handleInterestsChange}
                                tagMaxLength={MAX_TAG_CHARS}
                            />
                        </div>
                        <div className={styles.formOptionsContainer}>
                            <AvatarUploader
                                setUploadedImageSrc={setUploadedImageSrc}
                                peopleContactId={personalInfo?.people_contact?.id}
                                className={styles.uploaderContainer}
                                title={'profile picture'}
                            />

                            <div className={styles.buttonsContainer}>
                                <Button className={styles.cancelButton} onClick={toggleModalOpen}>
                                    cancel
                                </Button>
                                <Button type="submit" className={styles.saveButton} disabled={!areResourcesValid}>
                                    update your profile
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

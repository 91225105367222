import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { IFormInputProps } from 'types/interfaces';

export const FormInput: FC<IFormInputProps> = ({
    value,
    name,
    placeholder,
    type = 'text',
    touched,
    error,
    onChange,
    onTouch,
}: IFormInputProps): ReactElement => (
    <div className={styles.inputWrapper}>
        <input
            className={classNames(styles.input, {
                [styles.inputError]: touched && Boolean(error),
            })}
            placeholder={placeholder}
            id={name}
            name={name}
            value={value}
            type={type}
            onChange={onChange}
            onFocus={() => onTouch(name, true)}
        />
        {touched && error && <p className={styles.errorText}>{error}</p>}
    </div>
);

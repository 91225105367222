import React, { FC, ReactElement } from 'react';

import { Tooltip } from '@material-ui/core';

import classNames from 'classnames';

import { useStyles } from './styles';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { ITooltipWrapperProps } from 'types/interfaces';

export const TooltipWrapper: FC<ITooltipWrapperProps> = ({
    title,
    interactive = false,
    children,
    tooltipClass,
    arrowClass,
    placement = 'top',
}: ITooltipWrapperProps): ReactElement => {
    const classes = useStyles();

    return (
        <Tooltip
            title={title || DATA_NOT_FOUND}
            placement={placement}
            arrow
            classes={{
                tooltip: classNames(classes.tooltip, tooltipClass),
                arrow: classNames(classes.arrow, arrowClass),
            }}
            interactive={interactive}
            enterTouchDelay={0}
        >
            {children}
        </Tooltip>
    );
};

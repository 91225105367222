import React, { FC, ReactElement } from 'react';

import { Button } from '@material-ui/core';

import styles from './styles.module.scss';

import { USER_FEEDBACKS } from 'components/QuestionAnswerCard/constants';

import { IDefaultFeedbackProps } from 'types/interfaces';

export const DefaultFeedback: FC<IDefaultFeedbackProps> = ({
    setUserFeedback,
}: IDefaultFeedbackProps): ReactElement => (
    <div className={styles.defaultFeedback}>
        <h4>Was this answer helpful?</h4>
        <Button className={styles.positiveFeedbackButton} onClick={() => setUserFeedback(USER_FEEDBACKS.POSITIVE)}>
            yes
        </Button>
        <Button className={styles.negativeFeedbackButton} onClick={() => setUserFeedback(USER_FEEDBACKS.NEGATIVE)}>
            no
        </Button>
    </div>
);

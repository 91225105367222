import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { HeaderItemWithSorting } from 'components';

import { FRIENDS_AND_ENEMIES_PARAMS } from '../../constants';

import { ITableHeaderWithSortingProps } from 'types/interfaces';

type ITableHeaderProps = ITableHeaderWithSortingProps & { isFriendsTable: boolean };

export const TableHeader: FC<ITableHeaderProps> = ({
    isFriendsTable,
    sortByParam,
    sortedParamName,
}: ITableHeaderProps): ReactElement => (
    <div className={styles.tableHeader}>
        <div>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name={`G-score ${'\n'}ranking`}
                sortedParamName={sortedParamName}
                paramName={FRIENDS_AND_ENEMIES_PARAMS.DAO_SCORE}
            />
        </div>
        <div>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name={'name'}
                sortedParamName={sortedParamName}
                paramName={FRIENDS_AND_ENEMIES_PARAMS.NAME}
            />
        </div>
        <div>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name={`${isFriendsTable ? 'friendly votes' : 'hostile votes '}`}
                sortedParamName={sortedParamName}
                paramName={
                    isFriendsTable
                        ? FRIENDS_AND_ENEMIES_PARAMS.FRIENDLY_VOTES
                        : FRIENDS_AND_ENEMIES_PARAMS.HOSTILE_VOTES
                }
            />
        </div>
        <div>
            <div>
                <span>latest vote</span>
            </div>
        </div>
    </div>
);

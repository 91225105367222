import Mixpanel from 'mixpanel';

class MixpanelService {
    constructor() {
        this.mixpanelInstance = Mixpanel.init('46210778ffe24370970093af21636e2c');
    }

    private mixpanelInstance: Mixpanel.Mixpanel;
    public eventsGenerator = {
        dashboardEvent: (): string => 'gg_orgs',
        dashboardSortEvent: (columnName: string): string => `gg_orgs_${columnName}`,
        dashboardAdEvent: (): string => 'gg_ad_primary_organizations',
        peopleEvent: (): string => 'gg_people',
        peopleSortEvent: (columnName: string): string => `gg_people_${columnName}`,
        peopleTokensEvent: (): string => 'gg_people_tokens',
        peopleAdEvent: (): string => 'gg_ad_primary_people',
        feedEvent: (): string => 'gg_feed',
        feedAdEvent: (): string => 'gg_ad_primary_feed',
        toolsEvent: (): string => 'gg_tools',
        toolsCategoryEvent: (category: string): string => `gg_tools_${category}`,
        toolsAdEvent: (): string => 'gg_ad_primary_tools',
        searchEvent: (): string => 'gg_search',
        searchInputEvent: (search: string): string => `gg_search_${search}`,
        organizationEvent: (): string => 'gg_org',
        organizationSelectedEvent: (name: string, tabName: string): string => `gg_org_${name}_${tabName}`,
        aboutEvent: (): string => 'gg_about',
        faqEvent: (): string => 'gg_faq',
        feedbackEvent: (): string => 'gg_feedback',
        profileEvent: (): string => 'gg_profile',
        profileSelectedEvent: (address: string): string => `gg_profile_${address}`,
        similarPeopleClickEvent: (): string => 'gg_similar_people',
        similarOrganizationsClickEvent: (): string => 'gg_similar_orgs',
        onCategoryOpenClickEvent: (): string => 'DAO categories',
        onCategoryClickEvent: (categoryName: string): string => `DAO category ${categoryName}`,
        listDaoMain: () => `listdao_main_button`,
        brainstorm: () => `brainstorm_tab`,
        brainstormOrg: (id: string) => `brainstorm_tab_${id}`,
        brainStormFilter: (filter: string) => `brainstorm_filter_${filter}`,
        tosPage: () => 'tos_page',
        privacyPage: () => 'privacy_page',
        apiTos: () => 'api_tos',
        brainstormCreated: () => 'brainstorm_created',
        knowledgeCenterMain: () => 'knowledge_center_main',
        knowledgeCenterArticle: (article: string) => `kc_${article}`,
        organizationAdEvent: () => 'orgs_pages_img',
        friendsAndEnemies: () => 'frenemies',
        friendsAndEnemiesByAddress: (address: string) => `frenemies_${address}`,
        organizationsSearchPage: () => 'SearchPage',
        daoTreasuryInvestmentsPage: () => 'daos_and_defi',
        daoTokensPage: () => 'dao_tokens_dashboard',
    };

    public track(event: string): void {
        this.mixpanelInstance.track(event);
    }
}

export const mixpanelService = new MixpanelService();

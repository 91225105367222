import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TooltipWrapper, ImageLoader } from 'components';

import { FormatUtils } from 'utils';
import { defaultTokenPlaceholderImage } from 'assets';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';
import { IMAGE_TYPES } from 'constants/imageTypes';

import { IOrganizationCurrencyTableData } from 'types/interfaces';

export const CurrencyRowItems: FC<IOrganizationCurrencyTableData> = ({
    chainTitle,
    rank,
    currencyPercentage,
    tokenName,
    usdValue,
    tokenIcon,
    tokenBalance,
}: IOrganizationCurrencyTableData): ReactElement => {
    const tokenBalanceValue = FormatUtils.checkIfDataNotExist(tokenBalance)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberWithCommas(Number(tokenBalance), 1);
    const currencyValue = FormatUtils.checkIfDataNotExist(usdValue)
        ? DATA_NOT_FOUND
        : `$${FormatUtils.formatNumberWithCommas(Number(usdValue), 1)}`;
    const currencyPercentageValue = FormatUtils.checkIfDataNotExist(currencyPercentage)
        ? DATA_NOT_FOUND
        : (currencyPercentage as number).toFixed(1);

    return (
        <div className={styles.tableRow}>
            <div className={styles.rank}>
                <span>{rank}</span>
            </div>
            <div className={styles.token}>
                <ImageLoader
                    src={tokenIcon || defaultTokenPlaceholderImage}
                    className={styles.avatar}
                    imageType={IMAGE_TYPES.TOKEN_IMAGE}
                />
                <div className={styles.tokenInfo}>
                    <TooltipWrapper title={tokenName}>
                        <h5 className={styles.tokenName}>{tokenName}</h5>
                    </TooltipWrapper>
                    <span className={styles.tokenBalance}>{chainTitle}</span>
                </div>
            </div>
            <div className={styles.currencyValue}>
                {!FormatUtils.checkIfDataNotExist(currencyPercentage) ? (
                    <>
                        <TooltipWrapper title={`${currencyValue}`}>
                            <div className={styles.value}>
                                <div>
                                    <span>{currencyValue}</span>
                                </div>
                            </div>
                        </TooltipWrapper>

                        <span className={styles.percentage}>{currencyPercentageValue} %</span>
                    </>
                ) : (
                    <span>{DATA_NOT_FOUND}</span>
                )}
            </div>
            <div className={styles.balance}>
                <TooltipWrapper title={`${tokenBalanceValue}`}>
                    <span className={styles.tokenBalanceValue}>{tokenBalanceValue}</span>
                </TooltipWrapper>
            </div>
        </div>
    );
};

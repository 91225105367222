import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './styles.module.scss';

import { Loader } from 'components';
import { HeadSection, OrganizationsFilter, OrganizationInfoCard } from './components';

import {
    DEFAULT_OFFSET_VALUE,
    DEFAULT_ORGANIZATIONS_DATA_LIMIT,
    SEARCHED_ORGANIZATION_FILTERS_TITLES,
    ORGANIZATIONS_SEACRHED_DATA_SORTED_PARAM,
    organizationsTreasurySizesData,
    organizationsNamesFilterData,
    organizationsProposalsFilterData,
    organizationsVotesFilterData,
    SEARCHED_ORGANIZATION_FILTERS_TYPE,
} from 'constants/organizationsSearch';

import { createOrganizationsCategoriesFilterData, tableDataSort } from 'helpers';

import {
    IOrganizationsSearchPageBodyProps,
    ISearchedOrganization,
    ISearchedOrganizationFilter,
    ISearchedOrganizationFilterData,
} from 'types/interfaces';

export const OrganizationsSearchPageBody: FC<IOrganizationsSearchPageBodyProps> = ({
    onSelectedFiltersUpdate,
    organizationsSearchedData,
    organizationsCategoriesData,
    selectedFiltersData,
    categoriesDataLoading,
    organizationsSearchedDataLoading,
    loadMoreOrganizations,
    offset,
}: IOrganizationsSearchPageBodyProps): ReactElement => {
    const [openedFiltersIds, setOpenedFiltersIds] = useState<number[]>([]);

    const organizationsCategoriesFilterData = useMemo(
        () => createOrganizationsCategoriesFilterData(organizationsCategoriesData),
        [organizationsCategoriesData],
    );

    const filtersData = useMemo(
        () => [
            {
                id: 1,
                title: SEARCHED_ORGANIZATION_FILTERS_TITLES.NAME,
                filterType: SEARCHED_ORGANIZATION_FILTERS_TYPE.INPUT,
                filtersData: organizationsNamesFilterData,
            },
            {
                id: 2,
                title: SEARCHED_ORGANIZATION_FILTERS_TITLES.TREASURY_SIZE,
                filterType: SEARCHED_ORGANIZATION_FILTERS_TYPE.SELECT,
                filtersData: organizationsTreasurySizesData,
            },
            {
                id: 3,
                title: SEARCHED_ORGANIZATION_FILTERS_TITLES.NUMBER_PROPOSALS,
                filterType: SEARCHED_ORGANIZATION_FILTERS_TYPE.SELECT,
                filtersData: organizationsProposalsFilterData,
            },
            {
                id: 4,
                title: SEARCHED_ORGANIZATION_FILTERS_TITLES.NUMBER_VOTES,
                filterType: SEARCHED_ORGANIZATION_FILTERS_TYPE.SELECT,
                filtersData: organizationsVotesFilterData,
            },
            {
                id: 5,
                title: SEARCHED_ORGANIZATION_FILTERS_TITLES.CATEGORY,
                filterType: SEARCHED_ORGANIZATION_FILTERS_TYPE.SELECT,
                filtersData: organizationsCategoriesFilterData,
            },
        ],
        [organizationsCategoriesData],
    );

    const addNewFilter = useCallback(
        (filterData: ISearchedOrganizationFilter) => {
            const updatedSelectedFiltersData = selectedFiltersData.filter(
                ({ relatedFieldKey }: ISearchedOrganizationFilter) => relatedFieldKey !== filterData.relatedFieldKey,
            );

            onSelectedFiltersUpdate([...updatedSelectedFiltersData, filterData], DEFAULT_OFFSET_VALUE);
        },
        [selectedFiltersData, onSelectedFiltersUpdate],
    );

    const removeSelectedFilter = useCallback(
        (filterId: string) => {
            const filteredData = selectedFiltersData.filter(({ id }: ISearchedOrganizationFilter) => id !== filterId);

            onSelectedFiltersUpdate(filteredData, DEFAULT_OFFSET_VALUE);
        },
        [selectedFiltersData, onSelectedFiltersUpdate],
    );

    const updateOpenedFiltersIds = (filterId: number) => {
        setOpenedFiltersIds((prevValue: number[]) =>
            prevValue.includes(filterId) ? prevValue.filter((id: number) => id !== filterId) : [...prevValue, filterId],
        );
    };

    const updateOrganizationsDataOffset = () => {
        loadMoreOrganizations(offset + DEFAULT_OFFSET_VALUE);
    };

    const getSlicedData = (data: ISearchedOrganization[], limit: number) => data.slice(0, limit);

    const organizationsSearchedDataSorted = useMemo(
        () => tableDataSort(organizationsSearchedData, ORGANIZATIONS_SEACRHED_DATA_SORTED_PARAM),
        [organizationsSearchedData],
    );

    const organizationsSearchedDataLimited = useMemo(
        () => getSlicedData(organizationsSearchedDataSorted, offset * DEFAULT_ORGANIZATIONS_DATA_LIMIT),
        [organizationsSearchedDataSorted, offset],
    );

    const hasMoreOrganizations = useMemo(
        () =>
            getSlicedData(organizationsSearchedData, offset * DEFAULT_ORGANIZATIONS_DATA_LIMIT).length !==
            organizationsSearchedData.length,
        [organizationsSearchedData, offset],
    );

    return (
        <div className={styles.wrapper}>
            <HeadSection
                selectedFiltersData={selectedFiltersData}
                removeSelectedFilter={removeSelectedFilter}
                organizationsSearchedData={organizationsSearchedData}
            />
            <div className={styles.mainContent}>
                <div className={styles.filterWrapper}>
                    {categoriesDataLoading ? (
                        <Loader size={40} className={styles.loaderBox} />
                    ) : (
                        filtersData.map((filter: ISearchedOrganizationFilterData) => (
                            <OrganizationsFilter
                                key={filter.id}
                                filterData={filter}
                                addNewFilter={addNewFilter}
                                removeSelectedFilter={removeSelectedFilter}
                                selectedFiltersData={selectedFiltersData}
                                updateOpenedFiltersIds={updateOpenedFiltersIds}
                                openedFiltersIds={openedFiltersIds}
                            />
                        ))
                    )}
                </div>
                <div className={styles.cardsWrapper}>
                    {organizationsSearchedDataLoading ? (
                        <Loader size={40} className={styles.loaderBox} />
                    ) : (
                        <InfiniteScroll
                            next={updateOrganizationsDataOffset}
                            dataLength={organizationsSearchedDataLimited.length}
                            hasMore={hasMoreOrganizations}
                            loader={<Loader size={40} className={styles.loaderBox} />}
                            style={{ overflow: 'inherit' }}
                        >
                            <div className={styles.cardsContainer}>
                                {organizationsSearchedDataLimited.map((organizationData: ISearchedOrganization) => (
                                    <OrganizationInfoCard
                                        key={organizationData.organizationId}
                                        organizationData={organizationData}
                                    />
                                ))}
                            </div>
                        </InfiniteScroll>
                    )}
                </div>
            </div>
        </div>
    );
};

import { ALERT_TYPES } from 'constants/alert';

import { alertErrorIcon, alertInfoIcon, alertSuccessIcon, alertNotificationIcon } from 'assets';

export const setIconByType = (type: ALERT_TYPES): string | undefined => {
    switch (type) {
        case ALERT_TYPES.SUCCESS: {
            return alertSuccessIcon;
        }

        case ALERT_TYPES.ERROR: {
            return alertErrorIcon;
        }

        case ALERT_TYPES.INFO: {
            return alertInfoIcon;
        }

        case ALERT_TYPES.NOTIFICATION: {
            return alertNotificationIcon;
        }

        default: {
            return undefined;
        }
    }
};

import React, { FC, ReactElement } from 'react';

import { Link } from '@material-ui/core';

import _ from 'lodash';

import styles from './styles.module.scss';

import { TooltipWrapper } from 'components';

import { IOrganizationToolProps } from 'types/interfaces';

export const OrganizationTool: FC<IOrganizationToolProps> = ({
    logo,
    name,
    description,
    mainUrl,
    isTablet,
}: IOrganizationToolProps): ReactElement => (
    <div className={styles.tool}>
        <div className={styles.imageWrapper}>
            <img src={logo} alt={name} className={styles.logo} />
        </div>
        <div className={styles.nameWrapper}>
            <span className={styles.organizationName}>{_.truncate(name, { length: 30 })}</span>
            <TooltipWrapper title={description}>
                <Link href={mainUrl} target="_blank" className={styles.phrase}>
                    <span>{_.truncate(description, { length: isTablet ? 20 : 30 })}</span>
                </Link>
            </TooltipWrapper>
        </div>
    </div>
);

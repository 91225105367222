import React, { FC, ReactElement, memo } from 'react';

import classNames from 'classnames';

import { NavItem } from './components';

import { HEADER_MAIN_ROUTES } from 'router/constants';

import { IMainPagesNavItemsProps, IRout } from 'types/interfaces';

export const MainPagesNavItems: FC<IMainPagesNavItemsProps> = memo(
    ({ menuExpanded, className }: IMainPagesNavItemsProps): ReactElement => (
        <nav className={classNames(className)}>
            {HEADER_MAIN_ROUTES.map((items: IRout) => (
                <NavItem key={items.id} {...items} menuExpanded={menuExpanded} />
            ))}
        </nav>
    ),
);

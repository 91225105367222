import { axiosInstance } from 'services';
import { AxiosResponse } from 'axios';
import { IDaoTreasuryAssetsQueryParams, IDaoTreasuryResponseData } from 'types/interfaces';

export const DaoTreasuryInvestmentsApi = {
    getDaoTreasuryInvestments: (
        queryParams: IDaoTreasuryAssetsQueryParams,
    ): Promise<AxiosResponse<IDaoTreasuryResponseData>> =>
        axiosInstance.get<IDaoTreasuryResponseData>('treasury-assets/total_treasuries_assets', { params: queryParams }),
    getDaoTreasuryTopAumOrganizations: (
        queryParams: IDaoTreasuryAssetsQueryParams,
    ): Promise<AxiosResponse<IDaoTreasuryResponseData>> =>
        axiosInstance.get<IDaoTreasuryResponseData>('treasury-assets/top_aum_organizations', {
            params: queryParams,
        }),
    getDaoTreasuryInvestmentsAssets: (
        queryParams: IDaoTreasuryAssetsQueryParams,
    ): Promise<AxiosResponse<IDaoTreasuryResponseData>> =>
        axiosInstance.get<IDaoTreasuryResponseData>('treasury-assets/organization_treasuries_assets', {
            params: queryParams,
        }),
};

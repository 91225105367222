import React, { FC, ReactElement, useEffect, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';
import styles from './styles.module.scss';

import { TableTabs, TableDataNotFound, SearchField, Loader } from 'components';
import { TableHeader, OrganizationMembersItems } from './components';

import { MEMBERS_PARAMS } from './constants';

import { useAppSelector, useAppDispatch } from 'store';
import { useTabSelector, useOrganizationDaos } from 'hooks';
import { tableDataSort } from 'helpers';
import { membersTableSearch } from './utils';
import { OrganizationActions } from 'store/actions';

import type { ITabItems, ISortedParam, IOrganizationMemberDataWithId } from 'types/interfaces';

export const OrganizationMembersTable: FC = (): ReactElement => {
    const { organizationDaos, membersDataLoading, organizationMembersData } = useAppSelector(
        ({ organization }) => organization,
    );
    const dispatch = useAppDispatch();
    const isTablet = useMediaQuery('(max-width: 767.98px)');

    const { tabSelected, handleTabChange } = useTabSelector({} as ITabItems);
    const [tabSearchValue, setTabSearchValue] = useState<string>('');
    const [sortedParam, setSortedParam] = useState<ISortedParam>({
        paramName: MEMBERS_PARAMS.VOTED_PARAM,
        descending: true,
    });

    const { organizationHaveDaos, createTabsList, createDaosList, organizationFirstDaoId } = useOrganizationDaos();

    useEffect(() => {
        if (organizationHaveDaos) {
            handleTabChange(createDaosList(0)?.[0] || {});
            dispatch(
                OrganizationActions.getOrganizationMembersData.request({ daoId: organizationFirstDaoId as string }),
            );
        }
    }, [organizationDaos]);

    useEffect(
        () => () => {
            dispatch({ type: OrganizationActions.CLEAR_ORGANIZATION_MEMBERS_DATA });
        },
        [dispatch],
    );

    const sortByParam = (param: string) => {
        setSortedParam({
            paramName: param,
            descending:
                sortedParam.paramName === param
                    ? !sortedParam.descending
                    : param === MEMBERS_PARAMS.RANK
                    ? false
                    : true,
        });
    };

    const onTabChange = (tab: ITabItems) => {
        if (tab.value !== tabSelected.value) {
            handleTabChange(tab);
            setTabSearchValue('');
            setSortedParam({
                paramName: MEMBERS_PARAMS.VOTED_PARAM,
                descending: true,
            });
            dispatch(OrganizationActions.getOrganizationMembersData.request({ daoId: tab.value }));
        }
    };

    const membersSearchData = membersTableSearch(
        tableDataSort([...organizationMembersData], sortedParam),
        tabSearchValue,
    );

    return (
        <div className={styles.container}>
            <div className={styles.tabsWrapper}>
                <TableTabs
                    tableTabs={createTabsList('Members')}
                    tabSelected={tabSelected}
                    handleTabChange={onTabChange}
                >
                    {!isTablet && (
                        <SearchField forTable={true} inputValue={tabSearchValue} setInputValue={setTabSearchValue} />
                    )}
                </TableTabs>
            </div>

            {membersDataLoading && membersSearchData.length === 0 && (
                <div className={styles.loaderBox}>
                    <Loader size={40} className={styles.membersLoader} />
                </div>
            )}

            {!membersDataLoading && membersSearchData.length === 0 && (
                <TableDataNotFound forTable overflowMarginsFirstVariant widthAuto />
            )}

            {membersSearchData.length > 0 && (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        <TableHeader sortedParamName={sortedParam.paramName} sortByParam={sortByParam} />

                        {membersSearchData.map((memberData: IOrganizationMemberDataWithId) => (
                            <OrganizationMembersItems
                                key={memberData.id}
                                {...memberData}
                                sortedParamName={sortedParam.paramName}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

import { PROPOSAL_BY_TYPE, PROPOSAL_TYPE, SNAPSHOT_BY_PROPOSAL, DISCOURCE_BY_TYPE } from './constants';
import { GNOSIS_SAFE_SNAPSHOT_PLATFORM_ID, SNAPSHOT_PLATFORM_ID } from 'constants/organizationsPlatforms';
import { PAGES_TYPES } from 'constants/pagesTypes';

export const getElementsIdsByType = <T extends { id: string; type: PAGES_TYPES }>(
    organizationsData: T[],
    type: PAGES_TYPES,
): string[] => organizationsData.filter((item: T) => item.type === type).map((item: T) => item.id);

export const setProposalType = (type: string, platfrom: number): string => {
    if (type === PROPOSAL_TYPE) {
        if ([SNAPSHOT_PLATFORM_ID, GNOSIS_SAFE_SNAPSHOT_PLATFORM_ID].includes(platfrom)) {
            return SNAPSHOT_BY_PROPOSAL;
        }
        return PROPOSAL_BY_TYPE;
    }
    return DISCOURCE_BY_TYPE;
};

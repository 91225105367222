import { createApiAction } from 'helpers';

export const GET_ALL_FAQS = 'GET_ALL_FAQS';
export const GET_ALL_FAQS_SUCCESS = 'GET_ALL_FAQS_SUCCESS';
export const GET_ALL_FAQS_FAILURE = 'GET_ALL_FAQS_FAILURE';

export const GET_FAQ = 'GET_FAQ';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_FAILURE = 'GET_FAQ_FAILURE';

export const CLEAR_FAQS_DATA = 'CLEAR_FAQ_DATA';

export const getAllFaqs = createApiAction(GET_ALL_FAQS);
export const getFaq = createApiAction(GET_FAQ);

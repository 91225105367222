import React, { FC, ReactElement, memo } from 'react';

import CloseIcon from '@material-ui/icons/Close';

import styles from './styles.module.scss';

import { ISelectedFilterElementProps } from 'types/interfaces';

export const SelectedFilterElement: FC<ISelectedFilterElementProps> = memo(
    ({ text, onRemove }: ISelectedFilterElementProps): ReactElement => (
        <div className={styles.wrapper} onClick={onRemove}>
            <CloseIcon className={styles.closeIcon} />
            <span className={styles.text}>{text}</span>
        </div>
    ),
);

import { Reducer } from '@reduxjs/toolkit';

import { FaqActions } from 'store/actions';

import { IFaqItems, IFaqState, IFaqActions } from 'types/interfaces';

const defaultState: IFaqState = {
    faqsLoading: false,
    singleFaqLoading: false,
    faqsList: [],
    singleFaqData: {} as IFaqItems,
};

export const faqsReducer: Reducer<IFaqState, IFaqActions> = (
    state: IFaqState = defaultState,
    action: IFaqActions,
): IFaqState => {
    const { type, payload } = <IFaqActions>action;

    switch (type) {
        case FaqActions.GET_ALL_FAQS: {
            return {
                ...state,
                faqsLoading: true,
            };
        }

        case FaqActions.GET_ALL_FAQS_SUCCESS: {
            const { data } = payload as { data: IFaqItems[] };

            return {
                ...state,
                faqsList: data,
                faqsLoading: false,
            };
        }

        case FaqActions.GET_ALL_FAQS_FAILURE: {
            return {
                ...state,
                faqsLoading: false,
            };
        }
        case FaqActions.GET_FAQ: {
            return {
                ...state,
                singleFaqLoading: true,
            };
        }

        case FaqActions.GET_FAQ_SUCCESS: {
            const { data } = payload as { data: IFaqItems[] };

            return {
                ...state,
                singleFaqData: data[0],
                singleFaqLoading: false,
            };
        }

        case FaqActions.GET_FAQ_FAILURE: {
            return {
                ...state,
                singleFaqLoading: false,
            };
        }

        case FaqActions.CLEAR_FAQS_DATA: {
            return {
                ...defaultState,
            };
        }

        default: {
            return state;
        }
    }
};

import React, { ReactElement, FC } from 'react';

import styles from './styles.module.scss';

import { VotingCoalitionsTable } from './components';

export const OrganizationProCoalitions: FC = (): ReactElement => (
    <div className={styles.container}>
        <VotingCoalitionsTable />
    </div>
);

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { SortIcon } from 'components';

import { mixpanelService } from 'services';

import { IHeaderItemWithSortingProps } from 'types/interfaces';

export const HeaderItemWithSorting: FC<IHeaderItemWithSortingProps> = ({
    sortedParamName,
    sortByParam,
    name,
    paramName,
    isOrganizationsPage,
    isPeoplePage,
}: IHeaderItemWithSortingProps): ReactElement => {
    const handleSort = () => {
        isOrganizationsPage && mixpanelService.track(mixpanelService.eventsGenerator.dashboardSortEvent(paramName));
        isPeoplePage && mixpanelService.track(mixpanelService.eventsGenerator.peopleSortEvent(paramName));
        sortByParam(paramName);
    };

    return (
        <div className={styles.sortingWrapper} onClick={handleSort}>
            <SortIcon active={sortedParamName === paramName} />
            <span className={styles.name}>{name}</span>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { deepDaoSocialLinks } from 'constants/deepDaoLinks';

import { IMainLink, IMainSocialLinksProps } from 'types/interfaces';

export const MainSocialLinks: FC<IMainSocialLinksProps> = ({
    className,
    maxSocials = 3,
}: IMainSocialLinksProps): ReactElement => {
    return (
        <nav className={classNames(styles.linksContainer, className)}>
            {deepDaoSocialLinks.slice(0, maxSocials).map(({ id, logo, link }: IMainLink) => (
                <a key={id} href={link} target="_blank" rel="noreferrer" className={styles.socialLink}>
                    <img src={logo} className={styles.socialIcon} alt="" />
                </a>
            ))}
        </nav>
    );
};

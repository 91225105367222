import React, { FC, ReactElement, Fragment, useContext, ChangeEvent } from 'react';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import styles from './styles.module.scss';

import { SelfListingInput, SelfListingTitle } from 'components';
import { DetailedDescriptionSection } from './components';

import { ADMIN_DESCRIPTION, EDITORS_DESCRIPTION } from './constants';

import { SelfListingContext } from 'context/selfListingContext';

import { ISelfListingElement } from 'types/interfaces';

export const ContactDetails: FC = (): ReactElement => {
    const { formik, disabledForEditors } = useContext(SelfListingContext);

    const addEditorsFieds = () => {
        formik.setFieldValue('contactDetails.editors', [
            ...formik.values.contactDetails.editors,
            {
                address: '',
                email: '',
            },
        ]);
    };

    return (
        <div className={styles.contactDetailsWrapper}>
            <SelfListingTitle title="Contact details" />

            <div>
                <DetailedDescriptionSection {...ADMIN_DESCRIPTION} />

                <div className={styles.inputsWrapper}>
                    <SelfListingInput
                        placeholder="Username, Full ETH address here"
                        name="contactDetails.adminAddress"
                        value={formik.values.contactDetails.adminAddress}
                        touched={formik.touched.contactDetails?.adminAddress}
                        error={formik.errors.contactDetails?.adminAddress}
                        onChange={formik.handleChange}
                        onTouch={formik.setFieldTouched}
                        required
                        disabled
                    />

                    <SelfListingInput
                        placeholder="Best email"
                        name="contactDetails.adminEmail"
                        value={formik.values.contactDetails.adminEmail}
                        touched={formik.touched.contactDetails?.adminEmail}
                        error={formik.errors.contactDetails?.adminEmail}
                        onChange={formik.handleChange}
                        onTouch={formik.setFieldTouched}
                        required
                        disabled={disabledForEditors}
                    />
                </div>
            </div>
            <div>
                <DetailedDescriptionSection {...EDITORS_DESCRIPTION} />

                <div className={styles.inputsWrapper}>
                    {formik.values.contactDetails.editors.map((_: ISelfListingElement, index: number) => (
                        <Fragment key={index}>
                            <SelfListingInput
                                placeholder="ETH Address"
                                name={`contactDetails.editors[${index}].address`}
                                value={formik.values.contactDetails.editors[index].address}
                                touched={formik.touched?.contactDetails?.editors?.[index]?.address}
                                error={formik.errors?.contactDetails?.editors?.[index]?.address}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    formik.setFieldValue(
                                        `contactDetails.editors[${index}].address`,
                                        event.target.value,
                                    );
                                }}
                                onTouch={formik.setFieldTouched}
                                disabled={disabledForEditors}
                            />

                            <SelfListingInput
                                placeholder="Email (Optional)"
                                name={`contactDetails.editors[${index}].email`}
                                value={formik.values.contactDetails.editors[index].email}
                                touched={formik.touched?.contactDetails?.editors?.[index]?.email}
                                error={formik.errors?.contactDetails?.editors?.[index]?.email}
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    formik.setFieldValue(`contactDetails.editors[${index}].email`, event.target.value)
                                }
                                onTouch={formik.setFieldTouched}
                                disabled={disabledForEditors}
                            />
                        </Fragment>
                    ))}
                </div>
            </div>

            <Button className={styles.addEditorButton} onClick={addEditorsFieds} disabled={disabledForEditors}>
                <AddIcon className={styles.addIcon} />
                <span className={styles.title}>add editor</span>
            </Button>
        </div>
    );
};

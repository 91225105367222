import React, { FC, ReactElement, useCallback, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';

import Checkbox from '@material-ui/core/Checkbox';

import styles from './styles.module.scss';

import { IBrainstormSubstormSelectProps, IOrganizationSubstorm } from 'types/interfaces';

import { useOutsideClick } from 'hooks';

export const BrainstormSubstormSelect: FC<IBrainstormSubstormSelectProps> = ({
    options,
    value,
    onSelect,
    isAllItemsDisabled,
}: IBrainstormSubstormSelectProps): ReactElement => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const selectRef = useRef(null);

    useOutsideClick(selectRef, () => setIsOpen(false));

    const handleItemSelect = useCallback(
        (item: IOrganizationSubstorm, isDisabled: boolean) => {
            if (!isDisabled) {
                onSelect(item);
            }
        },
        [onSelect],
    );

    const selectTitle = useMemo(
        () => (!value.length ? 'Select substorm' : value.length === 1 ? value[0].title : `${value.length} selected`),
        [value],
    );

    const renderItem = (item: IOrganizationSubstorm) => {
        const isActive = value.some((option) => option.id === item.id);
        const isDisabled = (!isActive && value.length >= 2) || isAllItemsDisabled;

        return (
            <div
                className={classNames(styles.itemWrapper, {
                    [styles.itemWrapperDisabled]: isDisabled,
                })}
                onClick={() => handleItemSelect(item, isDisabled)}
                key={item.id}
            >
                <Checkbox
                    disabled={isDisabled}
                    checked={isActive}
                    classes={{
                        root: styles.checkbox,
                        checked: styles.checkboxChecked,
                        disabled: styles.checkboxDisabled,
                    }}
                />
                <span
                    className={classNames(styles.itemName, {
                        [styles.itemNameActive]: isActive,
                        [styles.itemNameDisabled]: isDisabled,
                    })}
                >
                    {item.title}
                </span>
            </div>
        );
    };

    return (
        <div className={styles.selectWrapper} ref={selectRef}>
            <div
                className={classNames(styles.select, { [styles.selectActive]: isOpen })}
                onClick={() => setIsOpen((prevValue) => !prevValue)}
            >
                <span className={styles.selectTitle}>{selectTitle}</span>
                <div className={styles.selectToggleIcon} />
            </div>
            <div className={classNames(styles.selectItems, { [styles.selectItemsOpen]: isOpen })}>
                {options.map((item) => renderItem(item))}
            </div>
        </div>
    );
};

import React, { FC, ReactElement, memo } from 'react';

import styles from './styles.module.scss';

import { TableDataNotFound, Loader } from 'components';
import { TableHeader, TableRow } from './components';

import { IAccountInvoicesTableProps, IPremiumUserInvoiceData } from 'types/interfaces';

export const AccountInvoicesTable: FC<IAccountInvoicesTableProps> = memo(
    ({ premiumUserInvoices, loading }: IAccountInvoicesTableProps): ReactElement => (
        <div className={styles.tableWrapper}>
            <TableHeader />

            {loading && <Loader />}

            {premiumUserInvoices.length > 0 &&
                !loading &&
                premiumUserInvoices.map((invoice: IPremiumUserInvoiceData) => (
                    <TableRow key={invoice.id} {...invoice} />
                ))}

            {!loading && premiumUserInvoices.length === 0 && <TableDataNotFound forTable />}
        </div>
    ),
);

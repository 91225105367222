import React, { FC, ReactElement, useState, useEffect } from 'react';

import { useMediaQuery } from '@material-ui/core';

import styles from './styles.module.scss';

import { ResourcesCard, ResourcesCategoryButton, Loader, HrefLink } from 'components';

import { allCategoryFilter } from './constants';
import { CATEGORY_TYPE } from 'constants/resourcesConstants';
import { MAIN_PAGES_BANNER, MAIN_PAGES_BANNER_MOBILE, MAIN_PAGES_BANNER_LINK } from 'constants/banners';

import { useAppSelector, useAppDispatch } from 'store';
import { ResourcesActions } from 'store/actions';
import { groupedResourcesData } from 'helpers';
import { mixpanelService } from 'services';

import { IEcosystemCategory, IResourseDataWithCategories } from 'types/interfaces';

export const ResourcesPage: FC = (): ReactElement => {
    const { resourcesData, resourcesCategoriesData, loading } = useAppSelector(({ resources }) => resources);
    const dispatch = useAppDispatch();

    const [categorySelected, setCategorySelected] = useState<number>(allCategoryFilter.id);
    const [categoriesList, setCategoriesList] = useState([] as IEcosystemCategory[]);

    const isTablet = useMediaQuery('(max-width:767.98px)');

    useEffect(() => {
        dispatch(ResourcesActions.getAllResourcesData.request());
    }, [dispatch]);

    useEffect(() => {
        setCategoriesList([
            allCategoryFilter,
            ...resourcesCategoriesData.filter(
                (categoryData: IEcosystemCategory) => categoryData.type === CATEGORY_TYPE,
            ),
        ]);
    }, [resourcesCategoriesData]);

    useEffect(
        () => () => {
            dispatch({ type: ResourcesActions.CLEAR_RESOURCES_DATA });
        },
        [dispatch],
    );

    useEffect(() => mixpanelService.track(mixpanelService.eventsGenerator.toolsEvent()), []);

    useEffect(() => {
        const category: string | undefined =
            categoriesList?.find(({ id }) => categorySelected === id)?.name || 'undefined';
        mixpanelService.track(mixpanelService.eventsGenerator.toolsCategoryEvent(category));
    }, [categorySelected]);

    const handleAdClick = () => mixpanelService.track(mixpanelService.eventsGenerator.toolsAdEvent());

    const categoryFilter = (categoryId: number) => {
        if (
            (categoryId === allCategoryFilter.id && categorySelected === allCategoryFilter.id) ||
            categoryId === categorySelected
        )
            return;

        setCategorySelected(categoryId);
        dispatch(
            ResourcesActions.getSortedResourcesData.request({
                categoriesIds: categoryId,
            }),
        );
    };

    const findCategory = (categoryId: number) => {
        const categoryData = resourcesCategoriesData.find(({ id }: IEcosystemCategory) => id === categoryId);
        return categoryData || ({} as IEcosystemCategory);
    };

    return (
        <>
            {loading && <Loader />}

            <div className={styles.container}>
                <div className={styles.categoriesBannerWrapper}>
                    <div className={styles.blurBox} />
                    <div className={styles.content}>
                        <div className={styles.headSection}>
                            <div className={styles.details}>
                                <h2 className={styles.title}>DAO Tools</h2>
                                <h6 className={styles.subTitle}>A comprehensive list of DAO Resources</h6>
                            </div>
                            <HrefLink href={MAIN_PAGES_BANNER_LINK}>
                                <img
                                    src={isTablet ? MAIN_PAGES_BANNER_MOBILE : MAIN_PAGES_BANNER}
                                    className={styles.banner}
                                    onClick={handleAdClick}
                                    alt=""
                                />
                            </HrefLink>
                        </div>
                        <div className={styles.categoriesContainer}>
                            <div className={styles.resourcesCategoriesContainer}>
                                {categoriesList.map((category: IEcosystemCategory) => (
                                    <ResourcesCategoryButton
                                        key={category.id}
                                        category={category}
                                        categoryFilter={categoryFilter}
                                        categorySelected={categorySelected}
                                        loading={loading}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.resourcesCardsWrapper}>
                    {resourcesData?.length ? (
                        <div className={styles.resourcesCardsContainer}>
                            {groupedResourcesData(resourcesData).map((resourceData: IResourseDataWithCategories) => (
                                <ResourcesCard
                                    key={resourceData.resource_id}
                                    resourceData={resourceData}
                                    findCategory={findCategory}
                                />
                            ))}
                        </div>
                    ) : (
                        !loading && <h5 className={styles.resourcesNotFound}>no results found</h5>
                    )}
                </div>
            </div>
        </>
    );
};

import { Reducer } from '@reduxjs/toolkit';
import { DAO_TREASURY_INVESTMENTS_TYPES } from 'constants/daoTreasuryInvestments';
import { createOrUpdateDAOTreasuryInvestmentReduxFields } from 'helpers/daoTreasuryInvestmentsHelpers';
import { DaoTreasuryInvestmentsActions } from 'store/actions';
import {
    IDaoTreasuryIndividualOrganization,
    IDaoTreasuryInvestmentProtocol,
    IDaoTreasuryInvestmentProtocolAndType,
    IDaoTreasuryInvestmentType,
    IDaoTreasuryInvestmentsActionsType,
    IDaoTreasuryInvestmentsData,
    IDaoTreasuryInvestmentsState,
    IDaoTreasuryInvestmentsStateData,
    IErrorResponse,
} from 'types/interfaces';

const DEFAULT_VALUES: Partial<
    IDaoTreasuryInvestmentsData<{ loading: boolean; error?: IErrorResponse | null; lastUpdateDate: string | null }>
> = {
    loading: true,
    error: null,
    lastUpdateDate: null,
};

const defaultState: IDaoTreasuryInvestmentsState = {
    ...(createOrUpdateDAOTreasuryInvestmentReduxFields(
        DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_PROTOCOLS,
        DEFAULT_VALUES,
    ) as IDaoTreasuryInvestmentsStateData<IDaoTreasuryInvestmentProtocol>),
    ...(createOrUpdateDAOTreasuryInvestmentReduxFields(
        DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_TYPE,
        DEFAULT_VALUES,
    ) as IDaoTreasuryInvestmentsStateData<IDaoTreasuryInvestmentType>),
    ...(createOrUpdateDAOTreasuryInvestmentReduxFields(
        DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_PROTOCOL_AND_TYPE,
        DEFAULT_VALUES,
    ) as IDaoTreasuryInvestmentsStateData<IDaoTreasuryInvestmentProtocolAndType>),
    ...(createOrUpdateDAOTreasuryInvestmentReduxFields(
        DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_PROTOCOL_AND_TYPE_BORROWING,
        DEFAULT_VALUES,
    ) as IDaoTreasuryInvestmentsStateData<IDaoTreasuryInvestmentProtocolAndType>),
    ...(createOrUpdateDAOTreasuryInvestmentReduxFields(
        DAO_TREASURY_INVESTMENTS_TYPES.INDIVIDUAL_ORGANIZATION_BORROWS,
        DEFAULT_VALUES,
    ) as IDaoTreasuryInvestmentsStateData<IDaoTreasuryIndividualOrganization>),
    ...(createOrUpdateDAOTreasuryInvestmentReduxFields(
        DAO_TREASURY_INVESTMENTS_TYPES.INDIVIDUAL_ORGANIZATION_INVESTMENTS,
        DEFAULT_VALUES,
    ) as IDaoTreasuryInvestmentsStateData<IDaoTreasuryIndividualOrganization>),
    ...(createOrUpdateDAOTreasuryInvestmentReduxFields(
        DAO_TREASURY_INVESTMENTS_TYPES.TOP_20_INDIVIDUAL_ORGANIZATION_INVESTMENTS,
        DEFAULT_VALUES,
    ) as IDaoTreasuryInvestmentsStateData<IDaoTreasuryIndividualOrganization>),
    ...(createOrUpdateDAOTreasuryInvestmentReduxFields(
        DAO_TREASURY_INVESTMENTS_TYPES.TOP_20_INDIVIDUAL_ORGANIZATION_BORROWS,
        DEFAULT_VALUES,
    ) as IDaoTreasuryInvestmentsStateData<IDaoTreasuryIndividualOrganization>),
};

export const daoTreasuryInvestmentsReducer: Reducer<
    IDaoTreasuryInvestmentsState,
    IDaoTreasuryInvestmentsActionsType
> = (
    state: IDaoTreasuryInvestmentsState = defaultState,
    action: IDaoTreasuryInvestmentsActionsType,
): IDaoTreasuryInvestmentsState => {
    const { type, payload } = <IDaoTreasuryInvestmentsActionsType>action;

    switch (type) {
        case DaoTreasuryInvestmentsActions.GET_DAO_TREASURY_INVESTMENTS: {
            return {
                ...state,
            };
        }

        case DaoTreasuryInvestmentsActions.GET_DAO_TREASURY_INVESTMENTS_SUCCESS: {
            const { fieldName, data } = payload;

            return {
                ...state,
                /* eslint-disable */
                // @ts-ignore
                ...(createOrUpdateDAOTreasuryInvestmentReduxFields(fieldName, data) as IDaoTreasuryInvestmentsState),
            };
        }

        case DaoTreasuryInvestmentsActions.GET_DAO_TREASURY_INVESTMENTS_FAILURE: {
            const { error } = action;

            return {
                ...state,
                ...(createOrUpdateDAOTreasuryInvestmentReduxFields(
                    error?.fieldName as DAO_TREASURY_INVESTMENTS_TYPES,
                    { error: error?.error } as Partial<IDaoTreasuryInvestmentsData<{ error: IErrorResponse }>>,
                ) as IDaoTreasuryInvestmentsState),
            };
        }

        case DaoTreasuryInvestmentsActions.CLEAR_DAO_TREASURY_INVESTMENTS_DATA: {
            return defaultState;
        }

        default: {
            return state;
        }
    }
};

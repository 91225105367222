import { ITableTabs, ISelectOptions } from 'types/interfaces';

const tabItems: ITableTabs = {
    title: 'Decisions',
    tabsList: [
        { value: 'council', text: 'Council', id: 1 },
        { value: 'technicalCommittees', text: 'Technical Committees', id: 2 },
        { value: 'democracy', text: 'Democracy', id: 3 },
    ],
};

enum DECISIONS_PARAMS {
    TITLE_PARAM = 'title',
    DATE_PARAM = 'createdAt',
    PROPOSED_BY_PARAM = 'proposer',
    STATUS_PARAM = 'status',
    VOTES_PARAM = 'votes',
    FOR_SHARES_PARAM = 'sharesFor',
    AGAINST_SHARES_PARAM = 'sharesAgainst',
    QUORUM = 'quorum',
}

const selectOptions: ISelectOptions[] = [
    {
        id: 1,
        name: 'show all',
        value: 'all',
    },
    {
        id: 2,
        name: 'active',
        value: 'active',
    },
    {
        id: 3,
        name: 'pending',
        value: 'pending',
    },
    {
        id: 4,
        name: 'done',
        value: 'done',
    },
];

export { DECISIONS_PARAMS, tabItems, selectOptions };

import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { FeedbackOptions, MainPagesNavItems } from 'components';

import { deepDaoLogoWhite } from 'assets';
import { useAppSelector } from 'store';

export const LeftSidebarMenu: FC = (): ReactElement => {
    const { sidebarIsExpanded } = useAppSelector(({ sidebar }) => sidebar);

    return (
        <div
            className={classNames(styles.sideBarWrapper, {
                [styles.sidebarExpanded]: sidebarIsExpanded,
            })}
        >
            <div className={styles.sideBarContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.navigations}>
                        <div className={styles.logoWrapper}>
                            <Link to="/organizations" className={styles.imageLink}>
                                <img src={deepDaoLogoWhite} className={styles.deepDaoLogo} alt="" />
                            </Link>
                        </div>

                        <div className={styles.linksWrapper}>
                            <MainPagesNavItems menuExpanded={sidebarIsExpanded} />
                        </div>

                        <FeedbackOptions />
                    </div>
                </div>
            </div>
        </div>
    );
};

import Fortmatic from 'fortmatic';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { FORTMATIC_KEY, INFURA_KEY } from './constants';

export const providerOptions = {
    fortmatic: {
        package: Fortmatic,
        options: {
            key: FORTMATIC_KEY,
        },
    },
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: INFURA_KEY,
        },
    },
};

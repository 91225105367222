import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { useContentful } from 'react-contentful';
import {
    IContentfulResponse,
    IKnowledgeCenterPost,
    IKnowledgeCenterPostField,
    serializeContentfulKnowledgeCenterPosts,
} from '../FAQPage/helper';
import { uniq } from 'lodash';
import { useHistory } from 'react-router-dom';
import { mixpanelService } from '../../services';
import { useMediaQuery } from '@material-ui/core';

export const KnowledgePage = (): ReactElement => {
    const history = useHistory();
    const [categoryFilter, setCategoryFilter] = useState<string>('show all');

    const isMobile = useMediaQuery('(max-width:450px)');

    // TODO: spinner on loading contentful data
    const { data, loading } = useContentful({
        contentType: 'knowledgeCenterPost',
    });

    const posts = useMemo(
        () =>
            data ? serializeContentfulKnowledgeCenterPosts(data as IContentfulResponse<IKnowledgeCenterPostField>) : [],
        [data],
    );

    const mainPost = useMemo(() => (posts.find((post) => post.isMainPost) || {}) as IKnowledgeCenterPost, [posts]);
    const selectedPosts = useMemo(() => {
        const featuredPostNumber = isMobile ? 2 : 3;
        const featuredPosts = (posts.filter((post) => post.isSelectedPost) || []).splice(
            0,
            featuredPostNumber,
        ) as IKnowledgeCenterPost[];

        if (isMobile) {
            return [mainPost, ...featuredPosts];
        }

        return featuredPosts;
    }, [posts, mainPost, isMobile]);
    const categories = useMemo(() => {
        const categories = posts?.filter(({ category }) => category).map(({ category }) => category);

        return ['show all', ...(uniq(categories) || [])];
    }, [posts]);

    const postsByCategories = useMemo(() => {
        if (categoryFilter === 'show all') return posts || [];

        return posts.filter(({ category }) => category === categoryFilter) || [];
    }, [posts, categoryFilter]);

    const handleClickPost = useCallback((id: string) => history.push(`/knowledge/${id}`), []);

    useEffect(() => mixpanelService.track(mixpanelService.eventsGenerator.knowledgeCenterMain()), []);

    return (
        <>
            <div className={styles.blurBox} />

            <div className={styles.pageWrapper}>
                <div className={styles.header}>
                    <h1>Knowledge Center</h1>
                    <h5>DAO data, DAO life, and the connections between them</h5>
                </div>

                <div className={styles.content}>
                    <div className={styles.contentHeader}>
                        <h5>Featured Content</h5>
                    </div>

                    <div className={styles.mainPost} onClick={() => handleClickPost(mainPost.id)}>
                        <img alt={mainPost.title} src={mainPost.mainImage} className={styles.image} />

                        <div className={styles.postPreview}>
                            <span className={styles.category}>{mainPost.category}</span>
                            <span className={styles.title}>{mainPost.title}</span>
                            <span className={styles.text}>{mainPost.description}</span>
                        </div>
                    </div>

                    <div className={styles.recentPostsWrapper}>
                        {selectedPosts.map((post) => (
                            <PostPreviewSmall key={post.id} {...post} handleClickPost={handleClickPost} />
                        ))}
                    </div>

                    <div className={styles.postsByCategories}>
                        <div className={styles.headerCategories}>
                            <h5>Dig Deeper</h5>
                        </div>

                        <div className={styles.categoriesWrapper}>
                            {categories.map((category, index) => (
                                <CategoryChip
                                    key={index}
                                    name={category}
                                    selectedCategory={categoryFilter}
                                    setCategory={setCategoryFilter}
                                />
                            ))}
                        </div>

                        <div className={classNames(styles.recentPostsWrapper, styles.postsByCategoriesWrapper)}>
                            {postsByCategories.map((post) => (
                                <PostPreviewSmall key={post.id} {...post} handleClickPost={handleClickPost} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

interface IPostPreviewSmall extends IKnowledgeCenterPost {
    handleClickPost: (id: string) => void;
}

export const PostPreviewSmall = (props: IPostPreviewSmall): ReactElement => {
    const { id, previewImage, title, category, description, handleClickPost } = props;

    return (
        <div className={styles.postPreviewSmall} onClick={() => handleClickPost(id)}>
            <img src={previewImage} alt={title} className={styles.image} />
            <span className={styles.category}>{category}</span>
            <span className={styles.title}>{title}</span>
            <span className={styles.description}>{description}</span>
        </div>
    );
};

interface ICategoryChip {
    name: string;
    selectedCategory: string;
    setCategory: React.Dispatch<React.SetStateAction<string>>;
}

export const CategoryChip = (props: ICategoryChip): ReactElement => {
    const { name, selectedCategory, setCategory } = props;

    return (
        <div
            className={classNames([styles.categoryChip], { [styles.selectedCategoryChip]: selectedCategory === name })}
            onClick={() => setCategory(name)}
        >
            <span>{name}</span>
        </div>
    );
};

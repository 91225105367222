enum TAB_TYPES {
    PEOPLE = 'PEOPLE',
    PEOPLE_TOKENS = 'PEOPLE_TOKENS',
}

enum PEOPLE_PARAMS {
    DAOS_PARAM = 'daoAmount',
    PROPOSALS_PARAM = 'proposalsAmount',
    VOTES_PARAM = 'votesAmount',
    PARTICIPATION_SCORE_PARAM = 'participationScore',
    PARTICIPATION_RELATIVE_SCORE_PARAM = 'participationRelativeScore',
}

enum PEOPLE_TOKENS_PARAM {
    TOKEN_NAME_PARAM = 'name',
    TOKEN_SYMBOL_PARAM = 'symbol',
    TOKEN_BALANCE_PARAM = 'usd',
    TOKEN_PEOPLE_PARAM = 'totalHolders',
}

const LIMIT = 50;
const OFFSET = 0;

export { TAB_TYPES, PEOPLE_PARAMS, PEOPLE_TOKENS_PARAM, LIMIT, OFFSET };

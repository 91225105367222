import React, { FC, ReactElement, memo } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

export const DiscussionTimeframe: FC<any> = memo(
    ({ isDiscussionFinished, finishedDiscussionDate, timeLeftValue }: any): ReactElement => (
        <div
            className={classNames(styles.timeframe, {
                [styles.discussionFinished]: isDiscussionFinished,
            })}
        >
            <div className={styles.timeframeInfo}>
                <p>{isDiscussionFinished ? 'vote closed' : 'time left to support'}</p>
                {isDiscussionFinished ? (
                    <h5>{finishedDiscussionDate}</h5>
                ) : (
                    <div className={styles.timeLeft}>
                        {Object.entries(timeLeftValue).map(([key, value]: any) => (
                            <div key={key} className={styles.dateBlock}>
                                <div className={styles.timeBlock}>{value}</div>
                                <p className={styles.period}>{key}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    ),
);

import React, { FC, ReactElement, useCallback, useContext, useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import { Button, useMediaQuery } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EditIcon from '@material-ui/icons/Edit';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { HrefLink, ShareButton, ImageLoader, HeaderFollowButton } from 'components';

import { OrganizationDetailsCard, SelfListingReviewCard, SocialsContainer } from './components';

import { ORGANIZATION_DATA, RESEARCH } from 'constants/organizationTabs';
import { IMAGE_TYPES } from 'constants/imageTypes';
import { SELF_LISTING_STATUSES } from 'constants/selfListing';

import { useAppSelector, useAppDispatch } from 'store';
import { LinkUtils, FormatUtils } from 'utils';
import { defaultOrganizationPlaceholderImage } from 'assets';
import { OrganizationContext } from 'context/organizationContext';
import { OrganizationActions, SelfListingActions } from 'store/actions';
import { useWalletAuth } from 'components/ConnectWallet/useWalletAuth';

const MAX_DESCRIPTION_LENGTH = 250;
const ORGANIZATION_LISTED_BY_DEEPDAO_TEXT = 'Listed by DeepDAO';
const ORGANIZATION_SELF_LISTED_TEXT = 'Self-listed';

export const OrganizationHeader: FC = (): ReactElement => {
    const isTablet = useMediaQuery('(max-width:767.98px)');
    const isMobile = useMediaQuery('(max-width:575.98px)');
    const history = useHistory();

    const { organizationDataTabSelected, mainTabSelected, changeOrganizationDataTab } = useContext(OrganizationContext);

    const {
        organizationData: { name, logo, description, socials, website, id, selfListingStatus },
        userOrganizationRole,
        isOrganizationFollowing,
    } = useAppSelector(({ organization }) => organization);

    const { token } = useAppSelector(({ auth }) => auth);

    const { lastUpdate, enrichedOrganizationsCount, organizationsCount } = useAppSelector(
        ({ organizations }) => organizations,
    );

    const dispatch = useAppDispatch();

    const onEditButtonClick = useCallback(() => {
        dispatch({
            type: SelfListingActions.TOGGLE_SELF_LISTING_MODAL_OPEN,
            payload: {
                type: 'editing',
            },
        });
    }, [dispatch]);

    const organizationLogo = useMemo(
        () =>
            LinkUtils.isValidUrl(logo || '')
                ? logo
                : LinkUtils.getDaoLogoLinkFromBucket(logo, defaultOrganizationPlaceholderImage),
        [logo],
    );

    const isLoggedUser = useMemo(() => Boolean(token), [token]);

    const shortDescription = useMemo(
        () => FormatUtils.truncateString(description || '', MAX_DESCRIPTION_LENGTH),
        [description],
    );

    const { toggleConnection } = useWalletAuth();

    const onReadMoreClick = useCallback(() => {
        if (organizationDataTabSelected !== RESEARCH) {
            if (mainTabSelected === ORGANIZATION_DATA) {
                changeOrganizationDataTab(RESEARCH);
            } else {
                history.push(`/organization/${id}/organization_data/dig_deeper`);
            }
        }
    }, [organizationDataTabSelected, mainTabSelected]);

    const onFollowButtonClick = useCallback(() => {
        isLoggedUser
            ? dispatch(
                  OrganizationActions.followUnfollowOrganization.request({
                      isOrganizationFollowing,
                      organizationId: id,
                  }),
              )
            : toggleConnection();
    }, [isOrganizationFollowing, isLoggedUser, id, toggleConnection]);

    return (
        <div className={styles.headerWrapper}>
            <div className={styles.statsWrapper}>
                <div className={styles.statsInfoContainer}>
                    {isMobile && (
                        <Button className={styles.goBackButton} onClick={() => history.goBack()}>
                            <ArrowForwardIcon className={styles.goBackIcon} />
                        </Button>
                    )}
                    {!isMobile && !FormatUtils.checkIfDataNotExist(organizationsCount) && (
                        <span>
                            Aggregated governances:{' '}
                            {FormatUtils.formatNumberWithCommas(organizationsCount as number, 0)}
                        </span>
                    )}
                    {!isMobile && !FormatUtils.checkIfDataNotExist(enrichedOrganizationsCount) && (
                        <span>
                            Enriched by DeepDAO:{' '}
                            {FormatUtils.formatNumberWithCommas(enrichedOrganizationsCount as number, 0)}
                        </span>
                    )}
                    {lastUpdate && (
                        <span className={styles.lastUpdateDate}>
                            Updated: {FormatUtils.getFormattedLastUpdateDate(lastUpdate)}
                        </span>
                    )}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.content}>
                    <HrefLink href={website}>
                        <ImageLoader
                            src={organizationLogo || defaultOrganizationPlaceholderImage}
                            className={styles.organizationLogo}
                            imageType={IMAGE_TYPES.ORGANIZATION_IMAGE}
                        />
                    </HrefLink>
                    <div className={styles.organizationInfo}>
                        <div className={styles.organizationInfoHead}>
                            <HrefLink href={website}>
                                <h2 className={styles.organizationName}>{name}</h2>
                            </HrefLink>
                            <div className={styles.optionsButtonsWrapper}>
                                <ShareButton />

                                <HeaderFollowButton
                                    onClick={onFollowButtonClick}
                                    isFollowing={isOrganizationFollowing}
                                />

                                {(userOrganizationRole === 'admin' || userOrganizationRole === 'editor') && (
                                    <Button className={styles.editButton} onClick={onEditButtonClick}>
                                        <EditIcon className={styles.editIcon} />
                                        <span className={styles.buttonText}>edit</span>
                                    </Button>
                                )}
                            </div>
                        </div>

                        {!isTablet && (
                            <>
                                <div
                                    className={styles.textDescription}
                                    dangerouslySetInnerHTML={{ __html: shortDescription }}
                                />
                                {(description || '').length > MAX_DESCRIPTION_LENGTH && (
                                    <div
                                        className={classNames(styles.readMore, {
                                            [styles.readMoreDisabled]: organizationDataTabSelected === RESEARCH,
                                        })}
                                        onClick={onReadMoreClick}
                                    >
                                        <span className={styles.text}>read more</span>
                                        <ArrowForwardIcon className={styles.arrowIcon} />
                                    </div>
                                )}
                            </>
                        )}

                        {!isTablet && socials?.length > 0 && <SocialsContainer socials={socials} />}

                        <div className={styles.listingLabelBlock}>
                            <span className={styles.label}>
                                {!selfListingStatus
                                    ? ORGANIZATION_LISTED_BY_DEEPDAO_TEXT
                                    : ORGANIZATION_SELF_LISTED_TEXT}
                            </span>
                        </div>
                    </div>
                </div>

                {!selfListingStatus || selfListingStatus === SELF_LISTING_STATUSES.VERIFIED ? (
                    <OrganizationDetailsCard />
                ) : (
                    <SelfListingReviewCard selfListingStatus={selfListingStatus} />
                )}

                {isTablet && socials?.length > 0 && <SocialsContainer socials={socials} />}
            </div>
        </div>
    );
};

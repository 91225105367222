import React, { FC, ReactElement } from 'react';

import { useMediaQuery } from '@material-ui/core';

import styles from './styles.module.scss';

import { TableDataNotFound } from 'components';
import { TableHeader, OrganizationItems, OrganizationsCategoriesContainer, TabletSearchContainer } from './components';

import { ITopOrganizationsTableProps, IOrganizationItems } from 'types/interfaces';

export const TopOrganizationsTable: FC<ITopOrganizationsTableProps> = ({
    data,
    sortedParam,
    sortByParam,
    loading,
    categoriesContainerOpen,
    onCategoryClick,
    categorySelectedId,
    onTabSearch,
    tabSearchValue,
    toggleCategoriesOpen,
}: ITopOrganizationsTableProps): ReactElement => {
    const isLargeTablet = useMediaQuery('(max-width: 991.98px)');

    return (
        <>
            {isLargeTablet && (
                <TabletSearchContainer
                    categoriesContainerOpen={categoriesContainerOpen}
                    onTabSearch={onTabSearch}
                    tabSearchValue={tabSearchValue}
                    toggleCategoriesOpen={toggleCategoriesOpen}
                    loading={loading}
                />
            )}

            {categoriesContainerOpen && (
                <OrganizationsCategoriesContainer
                    onCategoryClick={onCategoryClick}
                    categorySelectedId={categorySelectedId}
                />
            )}

            <div className={styles.tableContent}>
                {data.length > 0 && !loading && (
                    <div className={styles.tableWrapper}>
                        <div className={styles.tableContainer}>
                            <TableHeader sortedParamName={sortedParam.paramName} sortByParam={sortByParam} />

                            {data.map((organizationInfo: IOrganizationItems) => (
                                <OrganizationItems
                                    key={organizationInfo.uniqueKey}
                                    sortedParamName={sortedParam.paramName}
                                    {...organizationInfo}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {!loading && data.length === 0 && (
                    <TableDataNotFound forTable removeMargins overflowMarginsFirstVariant />
                )}
            </div>
        </>
    );
};

import { ISelectOptions } from 'types/interfaces';

interface INewDiscussionData {
    title: string;
    text: string;
    creator: string;
    discussionThreshold: number;
    votingThreshold: number;
    endDate?: string | null;
    substorms?: string[];
}

export const timeframeOptions: ISelectOptions[] = [
    {
        id: 0,
        name: 'Forever',
    },
    {
        id: 1,
        name: '1 Day',
        value: '1',
    },
    {
        id: 2,
        name: '3 Days',
        value: '3',
    },
    {
        id: 3,
        name: '7 Days',
        value: '7',
    },
    {
        id: 4,
        name: '14 Days',
        value: '14',
    },
];

export const GENERAL_SUBSTORM_TITLE = 'General';

export type { INewDiscussionData };

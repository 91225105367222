import React, { FC, ReactElement } from 'react';

import { useMediaQuery } from '@material-ui/core';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { IAboutSellingPointProps } from 'types/interfaces';

export const AboutSellingPoint: FC<IAboutSellingPointProps> = ({
    image,
    title,
    subTitle,
    keysData,
    wrapperClass,
    mobileImage,
}: IAboutSellingPointProps): ReactElement => {
    const isMobile = useMediaQuery('(max-width:575.98px)');

    return (
        <div className={classNames(styles.wrapper, wrapperClass)}>
            <div className={styles.block}>
                <img src={isMobile ? mobileImage : image} className={styles.image} alt="" />
                <div className={styles.mainInfo}>
                    <h2 className={styles.title}>{title}</h2>
                    <h6 className={styles.subTitle}>{subTitle}</h6>
                    <div className={styles.featuresContainer}>
                        {keysData.map((key: string, index: number) => (
                            <div className={styles.feature} key={index}>
                                <span className={styles.name}>{key}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { FC, ReactElement, useCallback, FormEvent } from 'react';

import { useFormik } from 'formik';

import * as Yup from 'yup';

import styles from './styles.module.scss';

import { UserAccountInfo } from 'components/PlansPageBody/components';

import { useAppSelector, useAppDispatch } from 'store';
import { ClientsActions } from 'store/actions';
import { useHistoryPush } from 'hooks';

export const PremiumAccountCreatePage: FC = (): ReactElement => {
    const { error: clientError, created, loading } = useAppSelector(({ premiumClients }) => premiumClients);

    const dispatch = useAppDispatch();

    const { navigateToAccountSettings } = useHistoryPush();

    const formik = useFormik({
        initialValues: {
            userInfo: {
                fullName: '',
                email: '',
                repeatedEmail: '',
                countryCode: '',
                phoneNumber: '',
                address: '',
                country: '',
                postalCode: '',
                rightsApproved: false,
            },
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            userInfo: Yup.object().shape({
                fullName: Yup.string().required('Required'),
                email: Yup.string().email().required('Required'),
                repeatedEmail: Yup.string()
                    .email()
                    .required('Required')
                    .when('email', (email, schema) =>
                        schema.test({
                            test: (repeatedEmail: string) => repeatedEmail === email,
                            message: 'Repeated email does not match',
                        }),
                    ),
                countryCode: Yup.string(),
                phoneNumber: Yup.string(),
                address: Yup.string(),
                country: Yup.string(),
                postalCode: Yup.string(),
                rightsApproved: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
            }),
            creditCardInfo: Yup.object().shape({
                cardholderName: Yup.string().required('Required'),
            }),
        }),

        onSubmit: () => {
            return;
        },
    });

    const createUserAccount = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            dispatch(
                ClientsActions.saveClient.request({
                    ...formik.values.userInfo,
                    zipCode: formik.values.userInfo.postalCode,
                    name: formik.values.userInfo.fullName,
                    navigate: navigateToAccountSettings,
                }),
            );
        },
        [formik],
    );

    return (
        <div className={styles.wrapper}>
            <UserAccountInfo
                formik={formik as any}
                clientError={clientError}
                accountCreated={created}
                createUserAccount={createUserAccount}
                loading={loading}
            />
        </div>
    );
};

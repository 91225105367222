import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { InfoCircle, Loader, TableDataNotFound } from 'components';
import { AssetRowItems, TableHeader } from './components';

import { useAppSelector } from 'store';

import { IAssetInfo } from 'types/interfaces';
import { TREASURY_TAB, TREASURY_TAB_DESCRIPTIONS } from '../../../../../../../../constants';

export const MetadataTable: FC = (): ReactElement => {
    const { daoAssets, daoAssetsDataLoading } = useAppSelector(({ organization }) => organization);

    return (
        <>
            {!daoAssetsDataLoading && daoAssets.length === 0 && (
                <TableDataNotFound forTable overflowMarginsSecondVariant />
            )}

            {daoAssetsDataLoading && daoAssets.length === 0 && (
                <div className={styles.loaderBox}>
                    <Loader size={40} className={styles.decisionsLoader} />
                </div>
            )}

            {daoAssets.length > 0 && (
                <div className={styles.tableWrapper}>
                    <div className={styles.titleWrapper}>
                        <h4 className={styles.tableTitle}>Metadata</h4>
                        <InfoCircle
                            tooltipText={TREASURY_TAB_DESCRIPTIONS[TREASURY_TAB.METADATA]}
                            className={styles.infoIcon}
                        />
                    </div>

                    <div className={styles.tableContainer}>
                        <div className={styles.table}>
                            <TableHeader />

                            {daoAssets.map((assetItems: IAssetInfo) => (
                                <AssetRowItems
                                    key={assetItems.address + assetItems.url + assetItems.description}
                                    {...assetItems}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

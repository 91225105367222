import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

import { InfoCircle, Loader, TableDataNotFound } from 'components';
import { TableHeader, TableRow } from './components';

import { IOrganizationVoterGroups } from '../../../../../../../../types/interfaces';
import { ECOSYSTEM_TAB, ECOSYSTEM_TAB_DESCRIPTIONS } from '../../../../../../../../constants';

interface IVoterGroupsTableProps {
    organizationVoterGroups: IOrganizationVoterGroups | null;
    loading: boolean;
}

export const VoterGroupsTable = ({ organizationVoterGroups, loading }: IVoterGroupsTableProps): ReactElement => {
    return (
        <div className={styles.content}>
            <div className={styles.headSection}>
                <div className={styles.textSection}>
                    <h4>Voter Groups</h4>
                    <InfoCircle
                        tooltipText={ECOSYSTEM_TAB_DESCRIPTIONS[ECOSYSTEM_TAB.VOTER_GROUPS]}
                        className={styles.infoIcon}
                    />
                </div>
            </div>

            {organizationVoterGroups && (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        <div>
                            <TableHeader />
                            <TableRow organizationVoterGroups={organizationVoterGroups} />
                        </div>
                    </div>
                </div>
            )}

            {loading && !organizationVoterGroups && <Loader size={40} className={styles.loaderBox} />}
            {!organizationVoterGroups && !loading && <TableDataNotFound forTable overflowMarginsSecondVariant />}
        </div>
    );
};

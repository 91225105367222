import React, { FC, memo, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { VOTING_OPTIONS } from 'constants/votingConstants';

import { greenDolphinIconFor, purpleDolphinIconAgainst } from 'assets';
import { setMobileVotingStatus } from './utils';

import { IVotingResultProps } from 'types/interfaces';

export const VotingResult: FC<IVotingResultProps> = memo(
    ({ voted, isMobile = false }: IVotingResultProps): ReactElement => (
        <div
            className={classNames(styles.voteSection, {
                [styles.voteSectionMobile]: isMobile,
            })}
        >
            <div
                className={classNames(styles.voteCircle, {
                    [styles.votedFor]: voted === VOTING_OPTIONS.FOR,
                    [styles.votedAgainst]: voted === VOTING_OPTIONS.AGAINST,
                })}
            >
                {voted === VOTING_OPTIONS.FOR && <img src={greenDolphinIconFor} alt="" />}
                {voted === VOTING_OPTIONS.AGAINST && <img src={purpleDolphinIconAgainst} alt="" />}
            </div>
            <h5 className={styles.text}>
                {isMobile ? (
                    `you voted ${setMobileVotingStatus(voted)}`
                ) : (
                    <>
                        your
                        <br /> vote
                    </>
                )}
            </h5>
        </div>
    ),
);

import { Area } from 'react-easy-crop/types';

const createImage = (url: string) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
    });

const getRadianAngle = (degreeValue: number) => {
    return (degreeValue * Math.PI) / 180;
};

const AVAILABLE_IMAGES_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'];

export const getCroppedImg = async (imageSrc: string, pixelCrop: Area, rotation = 0): Promise<FormData> => {
    const image = (await createImage(imageSrc)) as HTMLImageElement;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const imageType = AVAILABLE_IMAGES_TYPES.find((imageType: string) => imageSrc.includes(imageType));
    const safeArea = Math.max(image.width, image.height) * 2;

    canvas.width = safeArea;
    canvas.height = safeArea;

    if (ctx) {
        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.rotate(getRadianAngle(rotation));
        ctx.translate(-safeArea / 2, -safeArea / 2);
        ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);

        const data = ctx.getImageData(0, 0, safeArea, safeArea);

        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        ctx.putImageData(
            data,
            0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
            0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
        );
    }

    return new Promise((resolve) => {
        canvas.toBlob((file) => {
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                resolve(formData);
            }
        }, imageType);
    });
};

import { VOTING_OPTIONS } from 'constants/votingConstants';

import { FormatUtils } from 'utils';

import { INotificationData, USER_NOTIFICATION_TYPES } from 'types/interfaces';

export const setNotificationLinkByType = (type: USER_NOTIFICATION_TYPES, data: INotificationData): string => {
    switch (type) {
        case USER_NOTIFICATION_TYPES.NEW_BRAINSTORM:
        case USER_NOTIFICATION_TYPES.NEW_BRAINSTORM_VOTE: {
            return `/brainstorm/${data?.groupData?.organizationId}/${data?.groupData?.brainstormId}`;
        }

        case USER_NOTIFICATION_TYPES.TAGGED_USER:
        case USER_NOTIFICATION_TYPES.NEW_BRAINSTORM_COMMENT: {
            return `/brainstorm/${data?.data?.organizationId}/${data?.data?.brainstormId}`;
        }

        case USER_NOTIFICATION_TYPES.NEW_FOLLOWER: {
            return `/user/${data?.data?.address}/verified_dao_experience`;
        }

        default:
            return '/organizations';
    }
};

export const setNotificationTextByType = (type: USER_NOTIFICATION_TYPES, data: INotificationData): string => {
    const username =
        data?.data?.name ||
        data?.data?.idxName ||
        data?.data?.ensName ||
        FormatUtils.formatUserAddress(data?.data?.address) ||
        data?.groupData?.name ||
        data?.groupData?.idxName ||
        data?.groupData?.ensName ||
        FormatUtils.formatUserAddress(data?.groupData?.address);

    switch (type) {
        case USER_NOTIFICATION_TYPES.NEW_BRAINSTORM: {
            return `${username} created a new Brainstorm in ${data.groupData?.organizationName} `;
        }

        case USER_NOTIFICATION_TYPES.NEW_BRAINSTORM_VOTE: {
            return `There is a new ${
                data.groupData?.choice === VOTING_OPTIONS.AGAINST ? 'against' : 'support'
            } action on a brainstorm you follow: ${FormatUtils.truncateString(data.groupData?.title as string, 10)} `;
        }

        case USER_NOTIFICATION_TYPES.NEW_FOLLOWER: {
            return `User ${username} followed you`;
        }

        case USER_NOTIFICATION_TYPES.TAGGED_USER: {
            return `${username} tagged you in ${FormatUtils.truncateString(data.data?.title as string, 10)}`;
        }

        case USER_NOTIFICATION_TYPES.NEW_BRAINSTORM_COMMENT: {
            return `new comment from ${username} in brainstorm ${FormatUtils.truncateString(
                data.data?.title as string,
                10,
            )}`;
        }

        default:
            return 'no description available';
    }
};

import React, { FC, ReactElement, useEffect } from 'react';

import styles from './styles.module.scss';

import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { DELEGATES } from '../../../../../../constants/organizationTabs';
import { PremiumTab } from '../../../../../PremiumTab';
import { OrganizationTabsActions } from 'store/actions';
import {
    ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS,
    organizationActiveDelegatesTableHeaders,
} from './constants/organization-active-delegates.constants';
import {
    ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS,
    organizationTopDelegatesTableHeaders,
} from './constants/organization-top-delegates.constants';
import { LinkUtils } from '../../../../../../utils';
import { CommonTable } from '../CommonTable';
import { DELEGATES_TAB, DELEGATES_TAB_DESCRIPTIONS } from '../../../../../../constants';

export const OrganizationDelegates: FC = (): ReactElement => {
    const { organizationTabs, organizationActiveDelegates, organizationTopDelegates } = useAppSelector(
        ({ organizationTabs }) => organizationTabs,
    );
    const isTabBlocked = organizationTabs.data.find((t) => t.tab.name === DELEGATES)?.isBlocked;
    const { organizationData } = useAppSelector(({ organization }) => organization);
    const organizationId = organizationData.id;

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(OrganizationTabsActions.getOrganizationActiveDelegates.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationTopDelegates.request({ organizationId }));
    }, [organizationId]);

    const generatedOrganizationTopDelegates = organizationTopDelegates.data.map((otd) => {
        return {
            ...otd,
            ensName: {
                title: otd.ensName || otd.delegateAddress,
                link: LinkUtils.createDynamicLink(otd.delegateAddress, 'user'),
            },
        };
    });

    return (
        <div className={styles.container}>
            {isTabBlocked ? (
                <div className={styles.premiumWindow}>
                    <PremiumTab title="Delegates is a DeepDAO premium tab for this organization" />
                </div>
            ) : (
                <div className={styles.tablesWrapper}>
                    <div className={styles.topDelegates}>
                        <CommonTable
                            title="Top Delegate Votes"
                            infoText={DELEGATES_TAB_DESCRIPTIONS[DELEGATES_TAB.TOP_DELEGATES]}
                            lastUpdate={organizationActiveDelegates.lastUpdate}
                            tableRowsData={generatedOrganizationTopDelegates as any}
                            loading={organizationTopDelegates.loading}
                            tableHeaderData={organizationTopDelegatesTableHeaders}
                            sortedParamName={ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.VOTING_POWER}
                        />
                    </div>
                    <div className={styles.activeDelegates}>
                        <CommonTable
                            title="Active Delegates"
                            infoText={DELEGATES_TAB_DESCRIPTIONS[DELEGATES_TAB.ACTIVE_DELEGATE]}
                            lastUpdate={organizationActiveDelegates.lastUpdate}
                            tableRowsData={organizationActiveDelegates.data}
                            loading={organizationActiveDelegates.loading}
                            tableHeaderData={organizationActiveDelegatesTableHeaders}
                            sortedParamName={ORGANIZATION_ACTIVE_DELEGATES_TABLE_PARAMS.NUMBER_DELEGATES}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

import React, { FC, ReactElement, useState } from 'react';

import styles from './styles.module.scss';

import { InfoCircle, TableDataNotFound } from 'components';
import { AssetRowItems, TableHeader } from './components';

import { useAppSelector } from 'store';
import { tableDataSort } from 'helpers';

import { IOrganizationAssetsTable, ISortedParam } from 'types/interfaces';
import { TREASURY_TAB, TREASURY_TAB_DESCRIPTIONS } from '../../../../../../../../constants';

export const DaoAssetsTable: FC = (): ReactElement => {
    const { organizationDaoAssetsTable } = useAppSelector(({ organization }) => organization);

    const [sortedParam, setSortedParam] = useState<ISortedParam>({
        paramName: 'usdValue',
        descending: true,
    });

    const sortByParam = (param: string) => {
        setSortedParam({
            paramName: param,
            descending: sortedParam.paramName === param ? !sortedParam.descending : true,
        });
    };

    const daoAssetsTableData = tableDataSort([...organizationDaoAssetsTable], sortedParam);

    return (
        <>
            <div className={styles.tableWrapper}>
                <div className={styles.titleWrapper}>
                    <h4 className={styles.tableTitle}>DAO Assets</h4>
                    <InfoCircle
                        tooltipText={TREASURY_TAB_DESCRIPTIONS[TREASURY_TAB.ASSETS]}
                        className={styles.infoIcon}
                    />
                </div>
                {organizationDaoAssetsTable.length ? (
                    <div className={styles.dataContainer}>
                        <div className={styles.tableContainer}>
                            <TableHeader sortByParam={sortByParam} sortedParamName={sortedParam.paramName} />

                            <div className={styles.rowsWrapper}>
                                <div className={styles.rowsContainer}>
                                    {daoAssetsTableData.map((currencyItems: IOrganizationAssetsTable) => (
                                        <AssetRowItems key={currencyItems.rank} {...currencyItems} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <TableDataNotFound forTable overflowMarginsSecondVariant />
                )}
            </div>
        </>
    );
};

import { createApiAction } from 'helpers';

export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const UPDATE_USER_DATA_FAILURE = 'UPDATE_USER_DATA_FAILURE';

export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const UPDATE_USER_AVATAR_SUCCESS = 'UPDATE_USER_AVATAR_SUCCESS';
export const UPDATE_USER_AVATAR_FAILURE = 'UPDATE_USER_AVATAR_FAILURE';

export const UPDATE_USER_OPPORTUNITIES = 'UPDATE_USER_OPPORTUNITIES';
export const UPDATE_USER_OPPORTUNITIES_SUCCESS = 'UPDATE_USER_OPPORTUNITIES_SUCCESS';
export const UPDATE_USER_OPPORTUNITIES_FAILURE = 'UPDATE_USER_OPPORTUNITIES_FAILURE';

export const GET_SIMILAR_USERS_BY_ORGANIZATIONS_DATA = 'GET_SIMILAR_USERS_BY_ORGANIZATIONS_DATA';
export const GET_SIMILAR_USERS_BY_ORGANIZATIONS_DATA_SUCCESS = 'GET_SIMILAR_USERS_BY_ORGANIZATIONS_DATA_SUCCESS';
export const GET_SIMILAR_USERS_BY_ORGANIZATIONS_DATA_FAILURE = 'GET_SIMILAR_USERS_BY_ORGANIZATIONS_DATA_FAILURE';

export const GET_SIMILAR_USERS_BY_TOKENS_DATA = 'GET_SIMILAR_USERS_BY_TOKENS_DATA';
export const GET_SIMILAR_USERS_BY_TOKENS_DATA_SUCCESS = 'GET_SIMILAR_USERS_BY_TOKENS_DATA_SUCCESS';
export const GET_SIMILAR_USERS_BY_TOKENS_DATA_FAILURE = 'GET_SIMILAR_USERS_BY_TOKENS_DATA_FAILURE';

export const GET_USER_TOKEN_LIST_DATA = 'GET_USER_TOKEN_LIST_DATA';
export const GET_USER_TOKEN_LIST_DATA_SUCCESS = 'GET_USER_TOKEN_LIST_DATA_SUCCESS';
export const GET_USER_TOKEN_LIST_DATA_FAILURE = 'GET_USER_TOKEN_LIST_DATA_FAILURE';

export const GET_USER_IS_FOLLOWING_DATA = 'GET_USER_IS_FOLLOWING_DATA';
export const GET_USER_IS_FOLLOWING_DATA_SUCCESS = 'GET_USER_IS_FOLLOWING_DATA_SUCCESS';
export const GET_USER_IS_FOLLOWING_DATA_FAILURE = 'GET_USER_IS_FOLLOWING_DATA_FAILURE';

export const FOLLOW_UNFOLLOW_USER = 'FOLLOW_UNFOLLOW_USER';
export const FOLLOW_UNFOLLOW_USER_SUCCESS = 'FOLLOW_UNFOLLOW_USER_SUCCESS';
export const FOLLOW_UNFOLLOW_USER_FAILURE = 'FOLLOW_UNFOLLOW_USER_FAILURE';

export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export const GET_USER_NOTIFICATIONS_SUCCESS = 'GET_USER_NOTIFICATIONS_SUCCESS';
export const GET_USER_NOTIFICATIONS_FAILURE = 'GET_USER_NOTIFICATIONS_FAILURE';

export const UPDATE_NOTIFICATION_SEEN_STATUS = 'UPDATE_NOTIFICATION_SEEN_STATUS';
export const UPDATE_NOTIFICATION_SEEN_STATUS_SUCCESS = 'UPDATE_NOTIFICATION_SEEN_STATUS_SUCCESS';
export const UPDATE_NOTIFICATION_SEEN_STATUS_FAILURE = 'UPDATE_NOTIFICATION_SEEN_STATUS_FAILURE';

export const GET_USER_ENEMIES_DATA = 'GET_USER_ENEMIES_DATA';
export const GET_USER_ENEMIES_DATA_SUCCESS = 'GET_USER_ENEMIES_DATA_SUCCESS';
export const GET_USER_ENEMIES_DATA_FAILURE = 'GET_USER_ENEMIES_DATA_FAILURE';

export const GET_USER_FRIENDS_DATA = 'GET_USER_FRIENDS_DATA';
export const GET_USER_FRIENDS_DATA_SUCCESS = 'GET_USER_FRIENDS_DATA_SUCCESS';
export const GET_USER_FRIENDS_DATA_FAILURE = 'GET_USER_FRIENDS_DATA_FAILURE';

export const GET_USER_PROPOSALS_DATA = 'GET_USER_PROPOSALS_DATA';
export const GET_USER_PROPOSALS_DATA_SUCCESS = 'GET_USER_PROPOSALS_DATA_SUCCESS';
export const GET_USER_PROPOSALS_DATA_FAILURE = 'GET_USER_PROPOSALS_DATA_FAILURE';

export const GET_USER_VOTES_DATA = 'GET_USER_VOTES_DATA';
export const GET_USER_VOTES_DATA_SUCCESS = 'GET_USER_VOTES_DATA_SUCCESS';
export const GET_USER_VOTES_DATA_FAILURE = 'GET_USER_VOTES_DATA_FAILURE';

export const GET_USER_LABELS_DATA = 'GET_USER_LABELS_DATA';
export const GET_USER_LABELS_DATA_SUCCESS = 'GET_USER_LABELS_DATA_SUCCESS';
export const GET_USER_LABELS_DATA_FAILURE = 'GET_USER_LABELS_DATA_FAILURE';

export const TOGGLE_ABOUT_SECTION_OPEN = 'TOGGLE_ABOUT_SECTION_OPEN';

export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';

export const getUserData = createApiAction(GET_USER_DATA);
export const updateUserData = createApiAction(UPDATE_USER_DATA);
export const updateUserAvatar = createApiAction(UPDATE_USER_AVATAR);
export const updateUserOpportunities = createApiAction(UPDATE_USER_OPPORTUNITIES);
export const getSimilarUsersByOrganizationsData = createApiAction(GET_SIMILAR_USERS_BY_ORGANIZATIONS_DATA);
export const getUserTokenListData = createApiAction(GET_USER_TOKEN_LIST_DATA);
export const getSimilarUsersByTokensData = createApiAction(GET_SIMILAR_USERS_BY_TOKENS_DATA);
export const getUserNotifications = createApiAction(GET_USER_NOTIFICATIONS);
export const updateNotificationSeenStatus = createApiAction(UPDATE_NOTIFICATION_SEEN_STATUS);
export const getUserIsFollowingData = createApiAction(GET_USER_IS_FOLLOWING_DATA);
export const followUnfollowUser = createApiAction(FOLLOW_UNFOLLOW_USER);
export const getUserEnemiesData = createApiAction(GET_USER_ENEMIES_DATA);
export const getUserFriendsData = createApiAction(GET_USER_FRIENDS_DATA);
export const getUserVotesData = createApiAction(GET_USER_VOTES_DATA);
export const getUserProposalsData = createApiAction(GET_USER_PROPOSALS_DATA);
export const getUserLabelsData = createApiAction(GET_USER_LABELS_DATA);

import React, { FC, ReactElement } from 'react';

import { ITableHeaderWithSortingProps } from 'types/interfaces';
import { HeaderItemWithSorting } from 'components';

import { ORGANIZATION_PARAMS } from '../../constants';

import styles from './styles.module.scss';

export const TableHeader: FC<ITableHeaderWithSortingProps> = ({ sortedParamName, sortByParam }): ReactElement => (
    <div className={styles.tableHeader}>
        <div className={styles.organization}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="Organization"
                sortedParamName={sortedParamName}
                paramName={ORGANIZATION_PARAMS.ORGANIZATION}
            />
        </div>
        <div className={styles.treasury}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="Treasury"
                sortedParamName={sortedParamName}
                paramName={ORGANIZATION_PARAMS.TREASURY}
            />
        </div>
        <div className={styles.members}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="Active members"
                sortedParamName={sortedParamName}
                paramName={ORGANIZATION_PARAMS.ACTIVE_MEMBERS}
            />
        </div>
        <div className={styles.following}>
            <div>
                <span>Following</span>
            </div>
        </div>
        <div className={styles.notifications}>
            <div>
                <span>Notifications</span>
            </div>
        </div>
    </div>
);

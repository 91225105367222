import React, { FC, ReactElement, memo } from 'react';

import styles from './styles.module.scss';

import { HeaderItemWithSorting } from 'components';

import { ORGANIZATIONS_PARAMS } from 'constants/organizationsSorting';

import { ITableHeaderWithSortingProps } from 'types/interfaces';

export const TableHeader: FC<ITableHeaderWithSortingProps> = memo(
    ({ sortedParamName, sortByParam }: ITableHeaderWithSortingProps): ReactElement => (
        <div className={styles.tableHeader}>
            <div className={styles.rank}>
                <div>
                    <span>rank</span>
                </div>
            </div>
            <div className={styles.organization}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="organization"
                    sortedParamName={sortedParamName}
                    paramName={ORGANIZATIONS_PARAMS.ORGANIZATION_PARAM}
                    isOrganizationsPage
                />
            </div>
            <div className={styles.treasury}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="treasury"
                    sortedParamName={sortedParamName}
                    paramName={ORGANIZATIONS_PARAMS.TREASURY_PARAM}
                    isOrganizationsPage
                />
            </div>
            <div className={styles.changesPerDay}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`last ${'\n'}7d`}
                    sortedParamName={sortedParamName}
                    paramName={ORGANIZATIONS_PARAMS.CHANGES_PER_WEEK}
                    isOrganizationsPage
                />
            </div>
            <div className={styles.topTokens}>
                <div>
                    <span>top treasury tokens</span>
                </div>
            </div>
            <div className={styles.mainTreasuryChain}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`main treasury ${'\n'}chain`}
                    sortedParamName={sortedParamName}
                    paramName={ORGANIZATIONS_PARAMS.MAIN_CHAIN_TITLE}
                    isOrganizationsPage
                />
            </div>
            <div className={styles.potentialVoters}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`token holders`}
                    sortedParamName={sortedParamName}
                    paramName={ORGANIZATIONS_PARAMS.VOTERS_PARAM}
                    isOrganizationsPage
                />
            </div>
            <div className={styles.activeMembers}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`lifetime ${'\n'}participants`}
                    sortedParamName={sortedParamName}
                    paramName={ORGANIZATIONS_PARAMS.MEMBERS_PARAM}
                    isOrganizationsPage
                />
            </div>
            <div className={styles.proposals}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="proposals"
                    sortedParamName={sortedParamName}
                    paramName={ORGANIZATIONS_PARAMS.PROPOSALS_PARAM}
                    isOrganizationsPage
                />
            </div>
            <div className={styles.votes}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="votes"
                    sortedParamName={sortedParamName}
                    paramName={ORGANIZATIONS_PARAMS.VOTES_PARAM}
                    isOrganizationsPage
                />
            </div>
        </div>
    ),
);

import { detailedPlansPricings, PLAN_NAMES } from 'constants/plansItems';

import { IDetailedPlan, ISelectOptions } from 'types/interfaces';

export const getTotalPlanPrice = (planName: string, duration: ISelectOptions): number | null => {
    return (
        detailedPlansPricings[planName as PLAN_NAMES]?.find((plan: IDetailedPlan) => plan.duration === duration.value)
            ?.pricing || null
    );
};

import {
    COMMON_TABLE_FIELD_TYPES,
    ICommonTableHeader,
    ICommonTableHeaderSettings,
    IRowCellLinkData,
} from 'components/CommonTable/types';
import {
    IDaoTreasuryIndividualOrganization,
    IDaoTreasuryInvestmentOrganizationsCount,
    IDaoTreasuryInvestmentProtocol,
    IDaoTreasuryInvestmentProtocolAndType,
    IDaoTreasuryInvestmentProtocolName,
    IDaoTreasuryInvestmentProtocolType,
    IDaoTreasuryInvestmentTotalAum,
    IDaoTreasuryInvestmentTotalValue,
    IDaoTreasuryInvestmentType,
} from 'types/interfaces';
import { addSettingsToTableHeaderData } from './utils';

const tableSettingsThreeItems: ICommonTableHeaderSettings = {
    width: {
        desktop: '33.3%',
    },
};

const tableSettingsFourItems: ICommonTableHeaderSettings = {
    width: {
        desktop: '25%',
    },
};

export enum DAO_TREASURY_INVESTMENTS_TABLE_PARAMS {
    ORGANIZATIONS_COUNT = 'organizationCount',
    PROTOCOL_NAME = 'protocolName',
    TOTAL_VALUE = 'totalValue',
    PROTOCOL_TYPE = 'protocolType',
    TOTAL_AUM = 'totalAum',
    ORGANIZATION_NAME = 'title',
}

const PROTOCOL_NAME_DATA: ICommonTableHeader<
    IDaoTreasuryInvestmentProtocolName,
    DAO_TREASURY_INVESTMENTS_TABLE_PARAMS
> = {
    title: 'Protocol Name',
    rowFieldKey: 'protocolName',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsThreeItems,
};

const PROTOCOL_TYPE_DATA: ICommonTableHeader<
    IDaoTreasuryInvestmentProtocolType,
    DAO_TREASURY_INVESTMENTS_TABLE_PARAMS
> = {
    title: 'Protocol type',
    rowFieldKey: 'protocolType',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsThreeItems,
};

const NUMBER_OF_ORGANIZATIONS_DATA: ICommonTableHeader<
    IDaoTreasuryInvestmentOrganizationsCount,
    DAO_TREASURY_INVESTMENTS_TABLE_PARAMS
> = {
    title: 'Number of Orgs',
    rowFieldKey: 'organizationCount',
    sortedParamName: DAO_TREASURY_INVESTMENTS_TABLE_PARAMS.ORGANIZATIONS_COUNT,
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsThreeItems,
};

const TOTAL_USD_DATA: ICommonTableHeader<IDaoTreasuryInvestmentTotalValue, DAO_TREASURY_INVESTMENTS_TABLE_PARAMS> = {
    title: 'Total USD',
    rowFieldKey: 'totalValue',
    sortedParamName: DAO_TREASURY_INVESTMENTS_TABLE_PARAMS.TOTAL_VALUE,
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsThreeItems,
};

const ORGANIZATION_NAME_DATA: ICommonTableHeader<IRowCellLinkData, DAO_TREASURY_INVESTMENTS_TABLE_PARAMS> = {
    title: 'Organization name',
    rowFieldKey: 'linkData',
    fieldType: COMMON_TABLE_FIELD_TYPES.INNER_LINK,
    settings: tableSettingsFourItems,
};

const daoTreasuryInvestmentProtocolTableHeaderData: ICommonTableHeader<
    IDaoTreasuryInvestmentProtocol,
    DAO_TREASURY_INVESTMENTS_TABLE_PARAMS
>[] = [PROTOCOL_NAME_DATA, NUMBER_OF_ORGANIZATIONS_DATA, TOTAL_USD_DATA];

const daoTreasuryInvestmentTypeTableHeaderData: ICommonTableHeader<
    IDaoTreasuryInvestmentType,
    DAO_TREASURY_INVESTMENTS_TABLE_PARAMS
>[] = [PROTOCOL_TYPE_DATA, NUMBER_OF_ORGANIZATIONS_DATA, TOTAL_USD_DATA];

const daoTreasuryInvestmentProtocolAndTypeTableHeaderData: ICommonTableHeader<
    IDaoTreasuryInvestmentProtocolAndType,
    DAO_TREASURY_INVESTMENTS_TABLE_PARAMS
>[] = addSettingsToTableHeaderData(
    [PROTOCOL_NAME_DATA, PROTOCOL_TYPE_DATA, NUMBER_OF_ORGANIZATIONS_DATA, TOTAL_USD_DATA] as ICommonTableHeader<
        IDaoTreasuryInvestmentProtocolAndType,
        DAO_TREASURY_INVESTMENTS_TABLE_PARAMS
    >[],
    tableSettingsFourItems,
);

const daoTreasuryIndividualOrganizationTableHeaderData: ICommonTableHeader<
    IDaoTreasuryIndividualOrganization,
    DAO_TREASURY_INVESTMENTS_TABLE_PARAMS
>[] = addSettingsToTableHeaderData(
    [ORGANIZATION_NAME_DATA, PROTOCOL_NAME_DATA, PROTOCOL_TYPE_DATA, TOTAL_USD_DATA] as ICommonTableHeader<
        IDaoTreasuryIndividualOrganization,
        DAO_TREASURY_INVESTMENTS_TABLE_PARAMS
    >[],
    tableSettingsFourItems,
);

export {
    daoTreasuryInvestmentProtocolTableHeaderData,
    daoTreasuryInvestmentTypeTableHeaderData,
    daoTreasuryInvestmentProtocolAndTypeTableHeaderData,
    daoTreasuryIndividualOrganizationTableHeaderData,
    tableSettingsThreeItems,
    tableSettingsFourItems,
};

import React, { FC, ReactElement, useCallback, useState } from 'react';

import { Button } from '@material-ui/core';
import MoreVertRounded from '@material-ui/icons/MoreVertRounded';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ConfirmationModal } from 'components';

import { BRAINSTORM_ACTION_TYPE } from 'constants/discussionsElements';

import { useAppDispatch, useAppSelector } from 'store';
import { OrganizationActions } from 'store/actions';

import { IDiscussionElementRemoverProps } from 'types/interfaces';

export const DiscussionElementOptions: FC<IDiscussionElementRemoverProps> = ({
    text,
    onRemove,
    forComment = false,
    hovered = true,
    className,
    allowEditing = false,
    discussionId,
}: IDiscussionElementRemoverProps): ReactElement => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

    const { discussionModalOpen } = useAppSelector(({ organization }) => organization);

    const dispatch = useAppDispatch();

    const onConfirm = useCallback(() => {
        onRemove();
        setConfirmationModalOpen(false);
        setMenuOpen(false);
    }, [menuOpen, onRemove]);

    const onModalClose = useCallback(() => {
        setConfirmationModalOpen(false);
        setMenuOpen(false);
    }, [menuOpen, confirmationModalOpen]);

    const openStartDiscussionModal = useCallback(() => {
        dispatch({
            type: OrganizationActions.TOGGLE_DISSCUSION_MODAL_OPEN,
            payload: {
                brainstormActionType: BRAINSTORM_ACTION_TYPE.EDITING,
                discussionForEditingId: discussionId,
            },
        });
        setMenuOpen(false);
    }, [discussionId, menuOpen]);

    return (
        <>
            {(hovered || menuOpen) && (
                <div
                    className={classNames(styles.wrapper, className, {
                        [styles.insideComment]: forComment,
                    })}
                >
                    <Button
                        className={classNames(styles.button, {
                            [styles.buttonActive]: menuOpen,
                        })}
                        onClick={() => setMenuOpen(!menuOpen)}
                        disableRipple
                    >
                        <MoreVertRounded className={styles.moreIcon} />
                    </Button>

                    {menuOpen && (
                        <div className={styles.optionsBlock}>
                            {allowEditing && (
                                <div
                                    className={classNames(styles.elementBlock, styles.editBlock, {
                                        [styles.elementActive]: discussionModalOpen,
                                    })}
                                    onClick={openStartDiscussionModal}
                                >
                                    <EditIcon className={styles.editIcon} />
                                    <span className={styles.text}>edit</span>
                                </div>
                            )}

                            <div
                                className={classNames(styles.elementBlock, {
                                    [styles.elementActive]: confirmationModalOpen,
                                })}
                                onClick={() => setConfirmationModalOpen(true)}
                            >
                                <DeleteOutline className={styles.deleteIcon} />
                                <span className={styles.text}>hide {text}</span>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {confirmationModalOpen && (
                <ConfirmationModal
                    onClose={onModalClose}
                    confirmationModalOpen={confirmationModalOpen}
                    textInfo={{
                        title: `Are you sure you want to hide this ${text}?`,
                        confirmationText: 'Confirm',
                        cancelText: 'Cancel',
                    }}
                    onConfirm={onConfirm}
                />
            )}
        </>
    );
};

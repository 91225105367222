import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

export const TableHeader = (): ReactElement => (
    <div className={styles.tableHeader}>
            <div className={styles.timePeriod}>Time Period</div>
            <div className={styles.votingToken}>Voting Token</div>
            <div className={styles.stablecoins}>Stablecoins</div>
            <div className={styles.ethBtcTokens}>ETH/BTC Tokens</div>
            <div className={styles.otherTokens}>Other Tokens</div>
    </div>
);

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TooltipWrapper } from 'components';

import { passedIcon, rejectedIcon, thumbDownIcon, thumbUpIcon } from 'assets';
import { FormatUtils } from 'utils';

import { PROPOSAL_STATUS } from 'constants/statuses';

import { IUserVoteRowData } from 'types/interfaces';

export const TableRow: FC<IUserVoteRowData> = ({
    index,
    status,
    successful,
    title,
    createdAt,
    voteChoice,
    choices,
}: IUserVoteRowData): ReactElement => (
    <div className={styles.tableRow}>
        <div className={styles.number}>{index + 1}</div>
        <div className={styles.proposalName}>
            <img
                src={status === PROPOSAL_STATUS.ACCEPTED ? passedIcon : rejectedIcon}
                className={styles.proposalStatusIcon}
                alt=""
            />
            <TooltipWrapper title={title}>
                <span> {title || 'N/A'}</span>
            </TooltipWrapper>
        </div>
        <div className={styles.date}>{FormatUtils.getFormattedDate(createdAt)}</div>
        <VoteChoiceText voteChoice={voteChoice} choices={choices} />
        <WonVote successful={successful} />
    </div>
);

const WonVote = ({ successful }: { successful: boolean | null }) => {
    let src = null;

    if (successful) {
        src = thumbUpIcon;
    } else if (successful === false) {
        src = thumbDownIcon;
    }

    return <div className={styles.wonVote}>{src ? <img src={src} className={styles.thumbIcon} alt="" /> : '--'}</div>;
};

const VoteChoiceText = ({ voteChoice, choices }: { voteChoice: number; choices: string[] }) => {
    const FOR = 'for';
    const AGAINST = 'against';
    let choice = undefined;
    let formattedChoice = undefined;

    if (choices?.length) {
        choice = voteChoice - 1 >= 0 ? choices[voteChoice - 1] : undefined;
        formattedChoice = choice ? `${choice.replace(/^((?:\S+\s+){2}\S+).*/, '')}...` : undefined;
    } else {
        if (voteChoice == 1) {
            choice = FOR;
        }

        if (voteChoice == 0) {
            choice = AGAINST;
        }
    }

    return (
        <div className={styles.voted} title={choice}>
            {formattedChoice || choice || '--'}
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { thumbDownIcon, thumbUpIcon } from 'assets';

import { IImageStatusProps } from 'types/interfaces';

export const ImageStatus: FC<IImageStatusProps> = ({
    thumbsUp,
    forFeed,
    removeForMobile,
}: IImageStatusProps): ReactElement => (
    <div
        className={classNames(styles.statusWrapper, {
            [styles.feedStatus]: forFeed,
            [styles.removeForMobile]: removeForMobile,
        })}
    >
        <img src={thumbsUp ? thumbUpIcon : thumbDownIcon} className={styles.imageStatus} alt="" />
    </div>
);

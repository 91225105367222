import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TextContainer } from './components';

import { useAppSelector } from 'store';

export const OrganizationResearch: FC = (): ReactElement => {
    const {
        organizationData: { name },
    } = useAppSelector(({ organization }) => organization);

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <h3 className={styles.title}>{`${name} description`}</h3>

                <div className={styles.description}>
                    <TextContainer />
                </div>
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { IDeepDaoPartner, ISliderBlockProps } from 'types/interfaces';

export const SliderBlock: FC<ISliderBlockProps> = ({ data }: ISliderBlockProps): ReactElement => (
    <div className={styles.block}>
        {data.map(({ id, partnerImg, imgStyles: { maxWidth } }: IDeepDaoPartner) => (
            <div key={id} className={styles.imageWrapper}>
                <img src={partnerImg} style={{ width: maxWidth }} alt="" />
            </div>
        ))}
    </div>
);

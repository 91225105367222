import { createApiAction } from 'helpers';

export const GET_DAO_TOKENS = 'GET_DAO_TOKENS';
export const GET_DAO_TOKENS_SUCCESS = 'GET_DAO_TOKENS_SUCCESS';
export const GET_DAO_TOKENS_FAILURE = 'GET_DAO_TOKENS_FAILURE';

// export const GET_MORE_DAO_TOKENS = 'GET_MORE_DAO_TOKENS';
// export const GET_MORE_DAO_TOKENS_SUCCESS = 'GET_MORE_DAO_TOKENS_SUCCESS';
// export const GET_MORE_DAO_TOKENS_FAILURE = 'GET_MORE_DAO_TOKENS_FAILURE';

export const CLEAR_DAO_TOKENS = 'CLEAR_DAO_TOKENS';

export const getDaoTokens = createApiAction(GET_DAO_TOKENS);
// export const getMoreDaoTokens = createApiAction(GET_MORE_DAO_TOKENS);

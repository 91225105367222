import React, { FC, ReactElement, useMemo } from 'react';

import { Link } from 'react-router-dom';

import Switch from 'react-switch';

import { ImageLoader, FollowButton } from 'components';
import { FormatUtils, LinkUtils } from 'utils';
import { useAppDispatch } from 'store';
import { IMAGE_TYPES } from 'constants/imageTypes';
import { switchConfig } from 'constants/switchConfig';

import { IOrganizationTableRowProps } from '../../constants';

import styles from './styles.module.scss';
import { SocialFeaturesActions } from 'store/actions';

export const TableRow: FC<IOrganizationTableRowProps> = ({ item }): ReactElement => {
    const { organizationId, logo, name, activeMembers, treasury, enabledNotification = true, isFollowedBack } = item;
    const formatedTreasury = FormatUtils.formatNumberShort(treasury, 1, true);
    const dispatch = useAppDispatch();

    const handleFollow = () =>
        dispatch(
            SocialFeaturesActions.followOrganzation.request({
                following: organizationId,
            }),
        );

    const handleUnfollow = () =>
        dispatch(
            SocialFeaturesActions.unfollowOrganzation.request({
                following: organizationId,
            }),
        );

    const handleSwitch = () =>
        dispatch(
            SocialFeaturesActions.toggleOrganizationNotification.request({
                following: organizationId,
                enabledNotification: !enabledNotification,
            }),
        );

    const organizationLogo = useMemo(() => LinkUtils.getOrganizationLogo(logo || ''), [logo]);

    return (
        <div className={styles.tableRow}>
            <div className={styles.organization}>
                <ImageLoader src={organizationLogo} className={styles.avatar} imageType={IMAGE_TYPES.USER_IMAGE} />
                <Link to={LinkUtils.createDynamicLink(organizationId, 'organization')}>{name}</Link>
            </div>
            <div className={styles.treasury}>
                $<span>{formatedTreasury}</span>
            </div>
            <div className={styles.members}>{activeMembers}</div>
            <div className={styles.following}>
                <FollowButton
                    isFollowedBack={isFollowedBack}
                    handleFollow={handleFollow}
                    handleUnfollow={handleUnfollow}
                />
            </div>
            <div className={styles.notifications}>
                <Switch {...switchConfig} checked={enabledNotification} onChange={handleSwitch} />
            </div>
        </div>
    );
};

import { ITabs_Types } from 'types/interfaces';

export const API_LIST = 'list';
export const API_PLANS = 'plans';
export const API_REGISTARTION = 'registration';

export const API_PAGES: ITabs_Types = {
    [API_LIST]: API_LIST,
    [API_PLANS]: API_PLANS,
    [API_REGISTARTION]: API_REGISTARTION,
};

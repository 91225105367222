import React, { FC, ReactElement, useCallback, useEffect } from 'react';

import { OrganizationsSearchPageBody } from 'components';

import { SELECTED_FILTERS_LOCAL_STORAGE_KEY } from 'constants/organizationsSearch';

import { useAppDispatch, useAppSelector } from 'store';
import { OrganizationsSearchActions } from 'store/actions';
import { mixpanelService } from 'services';

import { ISearchedOrganizationFilter } from 'types/interfaces';

export const OrganizationsSearchPage: FC = (): ReactElement => {
    const {
        organizationsSearchedData,
        organizationsCategoriesData,
        selectedFiltersData,
        categoriesDataLoading,
        organizationsSearchedDataLoading,
        offset,
    } = useAppSelector(({ organizationsSearch }) => organizationsSearch);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(OrganizationsSearchActions.getOrganizationsSearchedData.request({ selectedFiltersData }));
        dispatch(OrganizationsSearchActions.getOrganizationsCategoriesData.request());
    }, [dispatch]);

    useEffect(() => {
        mixpanelService.track(mixpanelService.eventsGenerator.organizationsSearchPage());
    }, []);

    const onSelectedFiltersUpdate = useCallback(
        (updatedSelectedFiltersData: ISearchedOrganizationFilter[], newOffsetValue: number) => {
            localStorage.setItem(SELECTED_FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(updatedSelectedFiltersData));

            dispatch(
                OrganizationsSearchActions.getOrganizationsSearchedData.request({
                    selectedFiltersData: updatedSelectedFiltersData,
                    offset: newOffsetValue,
                }),
            );
        },
        [],
    );

    const loadMoreOrganizations = useCallback((newOffsetValue: number) => {
        dispatch({
            type: OrganizationsSearchActions.UPDATE_ORGANIZATIONS_SEARCHED_DATA_LIMIT,
            payload: { offset: newOffsetValue },
        });
    }, []);

    return (
        <OrganizationsSearchPageBody
            onSelectedFiltersUpdate={onSelectedFiltersUpdate}
            organizationsSearchedData={organizationsSearchedData}
            organizationsCategoriesData={organizationsCategoriesData}
            offset={offset}
            selectedFiltersData={selectedFiltersData}
            categoriesDataLoading={categoriesDataLoading}
            organizationsSearchedDataLoading={organizationsSearchedDataLoading}
            loadMoreOrganizations={loadMoreOrganizations}
        />
    );
};

import moment from 'moment';

import { FormatUtils } from 'utils';

export const getApiKeyExpirationInfo = (
    keyExpirationDate?: string,
): { keyExpirationDateFormatted: string; isKeyValid: boolean } | undefined => {
    if (keyExpirationDate) {
        const now = moment().format('YYYY-MM-DDTHH:mm:ss');
        const timeframe = moment(keyExpirationDate).utc().format('YYYY-MM-DDTHH:mm:ss');
        const diff = moment.duration(moment(timeframe).diff(moment(now)));

        return {
            isKeyValid: diff.days() >= 0 && diff.hours() >= 0 && diff.minutes() >= 0,
            keyExpirationDateFormatted: FormatUtils.getFormattedShortDate(keyExpirationDate),
        };
    }
};

import { IFollowPeopleData } from 'types/interfaces';

export interface IPeopleTableRowProps {
    isFollowersTable?: boolean;
    item: IFollowPeopleData;
}

export enum PEOPLE_PARAMS {
    DAO_SCORE = 'score',
    PEOPLE = 'name',
}

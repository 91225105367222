import React, { FC, ReactElement, useCallback, useMemo, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { DiscussionElementOptions, ImageLoader, DiscussionTooltip } from 'components';
import { RepliesSection, CommentsVotingSection } from './components';
import { TokensStats } from 'components/DiscussionCardItems/components';

import { IMAGE_TYPES } from 'constants/imageTypes';
import { DISCUSSIONS_NOTIFICATIONS } from 'constants/discussionsElements';

import { FormatUtils, LinkUtils } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { OrganizationActions } from 'store/actions';

import { ICommentInfoProps } from 'types/interfaces';

export const CommentInfo: FC<ICommentInfoProps> = ({
    userLogo,
    text,
    createdAt,
    comments,
    creatorLink,
    avatar,
    createComment,
    id,
    parentId,
    idxName,
    ensName,
    idxImageUrl,
    ensImageUrl,
    isReplyingAvailable,
    onBrainstormBanClick,
    isDiscussionSingle,
    isUserAdminOrEditor,
    votingFor,
    votingAgainst,
    tokensFor,
    tokensAgainst,
    voted,
    discussionId,
    loggedUserAddress,
    isAuthenticatedUser,
    tokenVoters,
    creator,
    userAddress,
    organizationId,
    userName,
}: ICommentInfoProps): ReactElement => {
    const [replyInputOpen, setReplyInputOpen] = useState<boolean>(false);
    const [commentHovered, setCommentHovered] = useState<boolean>(false);
    const [notificationOpen, setNotificationOpen] = useState<boolean>(false);

    const { commentsIdsWithReplyError } = useAppSelector(({ organization }) => organization);

    const dispatch = useAppDispatch();

    const commenterLink = useMemo(
        () => LinkUtils.createDynamicLink(creator || userAddress, 'user'),
        [creator, userAddress],
    );
    const creationDate = useMemo(() => FormatUtils.getFormattedLastUpdateDate(createdAt), [createdAt]);
    const commentsCount = useMemo(() => Number(comments.length), [comments]);

    useEffect(() => {
        if (commentsIdsWithReplyError.includes(id) && !notificationOpen) {
            setNotificationOpen(true);
            setReplyInputOpen(false);
        }
    }, [commentsIdsWithReplyError]);

    const onReplyClick = useCallback(() => {
        isReplyingAvailable && !notificationOpen ? setReplyInputOpen(!replyInputOpen) : setNotificationOpen(true);
    }, [replyInputOpen, notificationOpen, isReplyingAvailable]);

    const formattedCommenterAddress = useMemo(
        () => FormatUtils.formatUserAddress(creator || userAddress),
        [creator, userAddress],
    );

    const clearNotificationError = useCallback(() => {
        setNotificationOpen(false);
        dispatch({
            type: OrganizationActions.CLEAR_DISCUSSION_TOKENLESS_ERROR,
            payload: {
                commentsIdsWithReplyError: commentsIdsWithReplyError.filter((itemId: string) => itemId !== id),
            },
        });
    }, [notificationOpen, commentsIdsWithReplyError]);

    return (
        <>
            <div className={styles.comment}>
                <div className={styles.commentDetails}>
                    <Link to={commenterLink} className={styles.link}>
                        <ImageLoader
                            src={userLogo || idxImageUrl || ensImageUrl}
                            className={styles.commenterLogo}
                            imageType={IMAGE_TYPES.USER_IMAGE}
                        />
                    </Link>

                    <div
                        className={styles.commentTextBlock}
                        onMouseEnter={() => !isDiscussionSingle && setCommentHovered(true)}
                        onMouseLeave={() => !isDiscussionSingle && setCommentHovered(false)}
                    >
                        <div className={styles.commentTextBlockHeader}>
                            <Link to={commenterLink} className={styles.commenter}>
                                {userName || idxName || ensName || formattedCommenterAddress}
                            </Link>
                            <span className={styles.date}>{creationDate}</span>
                        </div>

                        <div
                            className={classNames(styles.text, {
                                [styles.textMarginRight]:
                                    isReplyingAvailable && !isDiscussionSingle && isUserAdminOrEditor,
                            })}
                            dangerouslySetInnerHTML={{
                                __html: FormatUtils.transformTextToHtml(text, window.location.origin),
                            }}
                        />
                        <div className={styles.commentsOptions}>
                            <div className={styles.tokensInfo}>
                                <TokensStats
                                    title="by members"
                                    votedFor={votingFor || 0}
                                    votedAgainst={votingAgainst || 0}
                                    forComment
                                    tokenVoters={tokenVoters}
                                />

                                <TokensStats
                                    title="by tokens"
                                    votedFor={tokensFor || 0}
                                    votedAgainst={tokensAgainst || 0}
                                    forComment
                                    tokenVoters={tokenVoters}
                                />
                            </div>

                            <CommentsVotingSection
                                voted={voted}
                                isAuthenticatedUser={isAuthenticatedUser}
                                discussionId={discussionId}
                                commentId={id}
                                isDiscussionSingle={isDiscussionSingle}
                                loggedUserAddress={loggedUserAddress}
                                organizationId={organizationId}
                            />
                        </div>

                        {isReplyingAvailable && !isDiscussionSingle && isUserAdminOrEditor && (
                            <DiscussionElementOptions
                                forComment
                                text="comment"
                                hovered={commentHovered}
                                onRemove={() => onBrainstormBanClick(false, 'comment', { commentId: id })}
                            />
                        )}
                    </div>
                </div>

                <div className={styles.info}>
                    <div className={styles.replySection}>
                        <div className={styles.reply}>
                            {notificationOpen && (
                                <DiscussionTooltip
                                    onClose={clearNotificationError}
                                    className={styles.notificationTooltip}
                                    text={DISCUSSIONS_NOTIFICATIONS.REPLY}
                                />
                            )}

                            <div className={styles.text} onClick={onReplyClick}>
                                Reply
                            </div>
                        </div>
                        {commentsCount > 0 && (
                            <span className={styles.replies}>
                                {commentsCount === 1 ? commentsCount + ' reply' : commentsCount + ' replies'}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <RepliesSection
                comments={comments}
                creatorLink={creatorLink}
                avatar={avatar}
                createComment={createComment}
                id={id}
                discussionId={parentId}
                replyInputOpen={replyInputOpen}
                isReplyingAvailable={isReplyingAvailable}
                onBrainstormBanClick={onBrainstormBanClick}
                isDiscussionSingle={isDiscussionSingle}
                isUserAdminOrEditor={isUserAdminOrEditor}
            />
        </>
    );
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

export const TableHeader: FC = (): ReactElement => (
    <div className={styles.tableHeader}>
        <div className={styles.titleWrapper}>
            <h4 className={styles.tableTitle}>Treasury and Governance</h4>
        </div>
        <div className={styles.headerItems}>
            <div className={styles.address}>
                <span>address</span>
            </div>
            <div className={styles.type}>
                <span>type</span>
            </div>
            <div className={styles.chainName}>
                <span>chain</span>
            </div>
            <div className={styles.description}>
                <span>description</span>
            </div>
        </div>
    </div>
);

import { AnyAction, Reducer } from '@reduxjs/toolkit';
import { AuthActions } from 'store/actions';

import type { ILoginResponse, IAuthState, IReducerAction, IChallengeResponse, ILoggedUserData } from 'types/interfaces';
import {
    getDeepDAOTokenFromStorage,
    removeDeepDAOTokenFromStorage,
    setDeepDAOTokenInStorage,
    getDeepDAORefreshTokenFromStorage,
    removeDeepDAORefreshTokenFromStorage,
    removeWalletConnectFromStorage,
    removeFortmaticFromStorage,
    setDeepDAORefreshTokenInStorage,
    getAddressFromStorage,
    removeAddressFromStorage,
    setAddressInStorage,
} from 'web3/storageHelper';

const defaultState = (() => {
    const accessToken = getDeepDAOTokenFromStorage();
    const refreshToken = getDeepDAORefreshTokenFromStorage();
    const address = getAddressFromStorage();

    return accessToken
        ? {
              address,
              status: 'init_state',
              token: accessToken,
              refreshToken: refreshToken,
              challenge: null,
              error: null,
              loading: false,
              adminToken: false,
              loggedUserData: {} as ILoggedUserData,
          }
        : {
              address: null,
              status: 'init_state',
              token: null,
              refreshToken: null,
              challenge: null,
              error: null,
              loading: false,
              adminToken: false,
              loggedUserData: {} as ILoggedUserData,
          };
})();

export const authReducer: Reducer<IAuthState> = (state: IAuthState = defaultState, action: AnyAction): IAuthState => {
    const { type } = <IReducerAction>action;

    switch (type) {
        case AuthActions.LOGIN: {
            return {
                ...state,
                status: AuthActions.LOGIN,
                loading: true,
            };
        }

        case AuthActions.LOGIN_SUCCESS: {
            const payload = action.payload as ILoginResponse;
            setDeepDAOTokenInStorage(payload.token);
            setDeepDAORefreshTokenInStorage(payload.refreshToken);
            setAddressInStorage(payload.address);

            return {
                ...state,
                status: AuthActions.LOGIN_SUCCESS,
                loading: false,
                address: payload.address,
                token: payload.token,
                refreshToken: payload.refreshToken,
            };
        }

        case AuthActions.LOGIN_FAILURE: {
            return {
                ...state,
                status: AuthActions.LOGIN_FAILURE,
                token: null,
                refreshToken: null,
                loading: false,
                error: new Error(action.error),
            };
        }

        case AuthActions.SET_ACCESS_TOKEN: {
            const payload = action.payload;
            setDeepDAOTokenInStorage(payload.token);

            return {
                ...state,
                status: AuthActions.SET_ACCESS_TOKEN,
                token: payload.token,
            };
        }

        case AuthActions.SET_ADMIN_TOKEN: {
            const payload = action.payload;

            return {
                ...state,
                adminToken: payload.adminToken,
            };
        }

        case AuthActions.CHALLENGE: {
            return {
                ...state,
                status: AuthActions.CHALLENGE,
                loading: true,
            };
        }

        case AuthActions.CHALLENGE_SUCCESS: {
            const payload = action.payload as IChallengeResponse;

            return {
                ...state,
                status: AuthActions.CHALLENGE_SUCCESS,
                loading: false,
                challenge: payload.challenge,
            };
        }

        case AuthActions.CHALLENGE_FAILURE: {
            return {
                ...state,
                status: AuthActions.CHALLENGE_FAILURE,
                loading: false,
                challenge: null,
                error: new Error(action.error),
            };
        }

        case AuthActions.GET_LOGGED_USER_DATA: {
            return {
                ...state,
            };
        }

        case AuthActions.GET_LOGGED_USER_DATA_SUCCESS: {
            const { loggedUserData } = action.payload as { loggedUserData: ILoggedUserData };

            return {
                ...state,
                loggedUserData,
            };
        }

        case AuthActions.GET_LOGGED_USER_DATA_FAILURE: {
            return {
                ...state,
            };
        }

        case AuthActions.LOGOUT: {
            removeDeepDAOTokenFromStorage();
            removeAddressFromStorage();
            removeDeepDAORefreshTokenFromStorage();
            removeWalletConnectFromStorage();
            removeFortmaticFromStorage();

            return {
                ...state,
                address: null,
                challenge: null,
                status: AuthActions.LOGOUT,
                token: null,
                refreshToken: null,
                error: null,
                loading: false,
                loggedUserData: {} as ILoggedUserData,
            };
        }

        default: {
            return state;
        }
    }
};

import { IRout } from 'types/interfaces';

import {
    AboutPage,
    DaoFeedPage,
    FAQPage,
    FeedbackPage,
    FeedProposalPage,
    KnowledgeDetailsPage,
    KnowledgePage,
    OrganizationDiscussion,
    OrganizationPage,
    OrganizationsPage,
    PageNotFound,
    PDFViewPage,
    PeoplePage,
    QuestionAnswerPage,
    ResourcesPage,
    UserPage,
    FollowingPage,
    OrderPlanPageSecond,
    PremiumEmailLoginPage,
    PremiumOTPLoginPage,
    AccountSettingsPage,
    AccountInvoicesPage,
    PremiumEmailUpdatePage,
    CancelPremiumSubscriptionPage,
    PremiumAccountCreatePage,
    OrganizationsSearchPage,
    DAOTreasuryInvestmentsPage,
    DaoTokensPage,
} from 'pages';

import { MAIN_PAGES_BACKGROUNDS } from 'constants/mainPagesBackgrounds';
import { PROTECTED_PAGES_TYPES } from 'constants/pagesTypes';

import {
    daoFeedPageIcon,
    daoToolsPageIcon,
    knowledgePageIcon,
    organizationsPageIcon,
    peoplePageIcon,
    searchIconWhite,
    daoTreasuryInvestmentsPageIcon,
} from 'assets';

const HEADER_MAIN_ROUTES: IRout[] = [
    {
        id: 111,
        name: 'Organizations',
        path: '/organizations',
        relatedPaths: ['organization'],
        exact: true,
        component: OrganizationsPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: false,
        pageImage: organizationsPageIcon,
    },
    {
        id: 112,
        name: 'Search',
        path: '/search',
        relatedPaths: ['search'],
        exact: true,
        component: OrganizationsSearchPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: false,
        pageImage: searchIconWhite,
    },
    {
        id: 113,
        name: 'DAOs & DeFi',
        path: '/daos_and_defi',
        relatedPaths: ['daos_and_defi'],
        exact: true,
        component: DAOTreasuryInvestmentsPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
        pageImage: daoTreasuryInvestmentsPageIcon,
    },
    {
        id: 118,
        name: 'DAO Tokens',
        path: '/dao_tokens',
        relatedPaths: ['dao_tokens'],
        exact: true,
        component: DaoTokensPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
        pageImage: daoTreasuryInvestmentsPageIcon,
    },
    {
        id: 114,
        name: 'DAO Feed',
        path: '/daofeed',
        relatedPaths: ['daofeed'],
        exact: true,
        component: DaoFeedPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: false,
        pageImage: daoFeedPageIcon,
    },
    {
        id: 115,
        name: 'DAO Tools',
        path: '/dao_tools',
        relatedPaths: ['dao_tools'],
        exact: true,
        component: ResourcesPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: false,
        pageImage: daoToolsPageIcon,
    },
    {
        id: 116,
        name: 'People',
        path: '/people',
        relatedPaths: ['people', 'user'],
        exact: true,
        component: PeoplePage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: false,
        pageImage: peoplePageIcon,
    },
    {
        id: 117,
        name: 'Knowledge',
        path: '/knowledge',
        relatedPaths: ['knowledge'],
        exact: true,
        component: KnowledgePage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
        pageImage: knowledgePageIcon,
    },
];

const OTHER_ROUTES: IRout[] = [
    {
        id: 211,
        name: 'organization data',
        path: '/organization/:organizationId/:tab/:organization_data_tab',
        exact: true,
        component: OrganizationPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: false,
    },
    {
        id: 212,
        name: 'organization discussions',
        path: '/organization/:organizationId/:tab',
        exact: true,
        component: OrganizationPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: false,
    },
    {
        id: 213,
        name: 'user',
        path: '/user/:id/:tab',
        exact: true,
        component: UserPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: false,
    },
    {
        id: 214,
        name: 'feed-proposal',
        path: '/feed-proposal/:id',
        exact: true,
        component: FeedProposalPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: false,
    },
    {
        id: 215,
        name: 'feedback',
        path: '/feedback',
        exact: true,
        component: FeedbackPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.WHITE,
        alwaysShowFooter: false,
    },
    {
        id: 216,
        name: 'FAQ',
        path: '/faq',
        exact: true,
        component: FAQPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.WHITE,
        alwaysShowFooter: false,
    },
    {
        id: 217,
        name: 'Following',
        path: '/user/following',
        exact: true,
        component: FollowingPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: false,
        isProtected: true,
        protectedTokenType: PROTECTED_PAGES_TYPES.DEEP_DAO_TOKEN,
    },
    {
        id: 218,
        name: 'FAQ',
        path: '/faq/:id',
        exact: true,
        component: QuestionAnswerPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.WHITE,
        alwaysShowFooter: false,
    },
    {
        id: 219,
        name: 'About',
        path: '/about',
        exact: true,
        component: AboutPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.WHITE,
        alwaysShowFooter: true,
    },
    {
        id: 220,
        name: 'organization discussion',
        path: '/brainstorm/:organizationId/:brainstormId',
        exact: true,
        component: OrganizationDiscussion,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
    },
    {
        id: 221,
        name: 'privacy',
        path: '/privacy',
        exact: true,
        component: PDFViewPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
    },
    {
        id: 222,
        name: 'tos',
        path: '/tos',
        exact: true,
        component: PDFViewPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
    },
    {
        id: 223,
        name: 'API TOS',
        path: '/api_tos',
        exact: true,
        component: PDFViewPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
    },
    // {
    //     id: 16,
    //     name: 'Documentation',
    //     path: '/documentation',
    //     exact: true,
    //     component: DocumentationPage,
    //     active: true,
    //     disableHeader: false,
    //     backgroundColor: MAIN_PAGES_BACKGROUNDS.WHITE,
    //     alwaysShowFooter: false,
    // },
    // {
    //     id: 17,
    //     name: 'Api',
    //     path: '/api/:page',
    //     exact: true,
    //     component: ApiPage,
    //     active: true,
    //     disableHeader: false,
    //     backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
    //     alwaysShowFooter: false,
    // },
    {
        id: 224,
        name: 'Knowledge Details',
        path: '/knowledge',
        exact: true,
        component: KnowledgePage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.WHITE,
        alwaysShowFooter: true,
    },
    {
        id: 225,
        name: 'Knowledge Details',
        path: '/knowledge/:id',
        exact: true,
        component: KnowledgeDetailsPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.WHITE,
        alwaysShowFooter: true,
    },
    {
        id: 226,
        name: 'products',
        path: '/products',
        exact: true,
        component: OrderPlanPageSecond,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
    },
    {
        id: 227,
        name: 'premium login',
        path: '/premium_email_login',
        exact: true,
        component: PremiumEmailLoginPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
    },
    {
        id: 228,
        name: 'premium otp login',
        path: '/premium_otp_login',
        exact: true,
        component: PremiumOTPLoginPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
    },
    {
        id: 229,
        name: 'account settings page',
        path: '/premium_account_settings',
        exact: true,
        component: AccountSettingsPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
        isProtected: true,
        protectedTokenType: PROTECTED_PAGES_TYPES.PREMIUM_USER_TOKEN,
    },
    {
        id: 230,
        name: 'account invoices page',
        path: '/premium_account_invoices',
        exact: true,
        component: AccountInvoicesPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
        isProtected: true,
        protectedTokenType: PROTECTED_PAGES_TYPES.PREMIUM_USER_TOKEN,
    },
    {
        id: 231,
        name: 'premium login update',
        path: '/premium_login_update',
        exact: true,
        component: PremiumEmailUpdatePage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
        isProtected: true,
        protectedTokenType: PROTECTED_PAGES_TYPES.PREMIUM_USER_TOKEN,
    },
    {
        id: 232,
        name: 'cancel premium subscription',
        path: '/cancel_premium_subscription',
        exact: true,
        component: CancelPremiumSubscriptionPage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
        isProtected: true,
        protectedTokenType: PROTECTED_PAGES_TYPES.PREMIUM_USER_TOKEN,
    },
    // {
    //     id: 29,
    //     name: 'premium plan upgrade',
    //     path: '/premium_plan_upgrade',
    //     exact: true,
    //     component: PremiumPlanUpgradePage,
    //     active: true,
    //     disableHeader: false,
    //     backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
    //     alwaysShowFooter: true,
    //     isProtected: true,
    //     protectedTokenType: PROTECTED_PAGES_TYPES.PREMIUM_USER_TOKEN,
    // },
    {
        id: 233,
        name: 'premium account create',
        path: '/premium_account_create',
        exact: true,
        component: PremiumAccountCreatePage,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.GHOST_WHITE,
        alwaysShowFooter: true,
    },
    {
        id: 404,
        name: 'Not found',
        path: '/*',
        exact: true,
        component: PageNotFound,
        active: true,
        disableHeader: false,
        backgroundColor: MAIN_PAGES_BACKGROUNDS.BLUE,
        alwaysShowFooter: false,
    },
];

const ROUTES: IRout[] = [...HEADER_MAIN_ROUTES, ...OTHER_ROUTES];

export { ROUTES, HEADER_MAIN_ROUTES };

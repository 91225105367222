import React, { FC, ReactElement, useState, useMemo } from 'react';

import styles from './styles.module.scss';

import { Loader, TableDataNotFound } from 'components';
import { TableHeader, TableRow } from './components';

import { DAO_EXPERIENCE_PARAMS } from './constants';

import { useAppSelector } from 'store';
import { tableDataSort } from 'helpers/tableHelpers';

import { IUserORGData, ISortedParam, IDAOExperienceTableData } from 'types/interfaces';

export const DAOExperienceTable: FC = (): ReactElement => {
    const {
        userProposalsData,
        userVotesData,
        userData: { daos = [], address: userAddress },
        userProposalsDataLoading,
        userVotesDataLoading,
    } = useAppSelector(({ user }) => user);
    const [sortedParam, setSortedParam] = useState<ISortedParam>({
        paramName: DAO_EXPERIENCE_PARAMS.USER_VOTES_PARAM,
        descending: true,
    });

    const daoExperienceTransformedTableData = useMemo(
        () =>
            daos?.length
                ? daos.map(({ organizationId, daoId, image, name }: IUserORGData) => {
                      const proposalsData = userProposalsData[organizationId];
                      const votesData = userVotesData[organizationId];

                      const proposalsByUserAmount =
                          proposalsData?.filter(
                              (proposal) => proposal.proposerId.toLowerCase() === userAddress.toLowerCase(),
                          ).length || 0;
                      const votesByUserAmount =
                          votesData?.filter((vote) => vote.voter.toLowerCase() === userAddress.toLowerCase()).length ||
                          0;

                      return {
                          daoId,
                          organizationId,
                          image,
                          name,
                          proposalsByUserAmount,
                          votesByUserAmount,
                      };
                  })
                : [],
        [daos, userProposalsData, userVotesData],
    );

    const sortByParam = (param: string) => {
        setSortedParam({
            paramName: param,
            descending: sortedParam.paramName === param ? !sortedParam.descending : true,
        });
    };

    const daoExperienceTableData = tableDataSort(daoExperienceTransformedTableData, sortedParam);

    const tableLoading = userProposalsDataLoading || userVotesDataLoading;

    return (
        <div className={styles.wrapper}>
            {tableLoading && <Loader className={styles.loaderBox} size={40} />}

            {daoExperienceTableData.length > 0 && !tableLoading && (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        <TableHeader sortedParamName={sortedParam.paramName} sortByParam={sortByParam} />

                        {daoExperienceTableData.map((item: IDAOExperienceTableData) => (
                            <TableRow
                                key={item.organizationId + item.daoId}
                                sortedParamName={sortedParam.paramName}
                                {...item}
                            />
                        ))}
                    </div>
                </div>
            )}

            {!tableLoading && daoExperienceTableData.length === 0 && (
                <TableDataNotFound forTable widthAuto className={styles.dataNotFound} />
            )}
        </div>
    );
};

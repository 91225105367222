export const switchConfig = {
    width: 36,
    height: 20,
    handleDiameter: 16,
    checkedIcon: false,
    uncheckedIcon: false,
    offColor: '#DEEAFF',
    onColor: '#0FDAB5',
    activeBoxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
};

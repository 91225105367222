import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { GraphCardHeader } from 'components';
import { ActivityLineGraph } from './components';

import { useAppSelector } from 'store';

import { IFeedActivityGraph } from 'types/interfaces';

const feedTooltipText = (
    <span>
        DAO Activity: decisions (proposals) and votes made in a listed DAO since DeepDAO started aggregating data in mid
        2020.
        <br />
        <br />
        Most active Organizations: the top 10 organizations, sorted by number of decisions (proposals) made over the
        past 7 days.
        <br />
        <br />
        Most active people: the top 10 people, sorted by number of votes made over the past 7 days.
    </span>
);

export const ActivityGraphs: FC = (): ReactElement => {
    const { votesGraphData, decisionsGraphData, feedCounters } = useAppSelector(({ feed }) => feed);

    return (
        <div className={styles.wrapper}>
            <GraphCardHeader title="DAO activity" titleFontIncreased infoIconText={feedTooltipText} />

            <div className={styles.graphsContainer}>
                <ActivityLineGraph
                    data={decisionsGraphData.map(({ date, count }: IFeedActivityGraph) => ({
                        label: date,
                        value: count,
                    }))}
                    heading="Decisions"
                    value={feedCounters.proposalsCount}
                />
                <ActivityLineGraph
                    data={votesGraphData.map(({ date, count }: IFeedActivityGraph) => ({
                        label: date,
                        value: count,
                    }))}
                    heading="Votes"
                    value={feedCounters.votesCount}
                />
            </div>
        </div>
    );
};

import { SHOW_ALL_CATEGORIES_ID } from './constants';

import { IOrganizationExtendedData, IOrganizationItems, IOrganizationCategory } from 'types/interfaces';

const filterOrganizationsByCategory = (
    organizations: IOrganizationItems[],
    categorySelectedId: number,
): IOrganizationItems[] =>
    organizations.filter(({ categories }: IOrganizationItems) => {
        return categories?.some(({ id }: IOrganizationCategory) => id === categorySelectedId);
    });

export const createOrganizationsTableData = (
    organizations: IOrganizationExtendedData[],
    categorySelectedId: number,
): IOrganizationItems[] => {
    if (organizations.length) {
        const data = organizations.map(
            (
                {
                    daoId,
                    organizationId,
                    logo,
                    daoName,
                    totalNumMembers,
                    totalNumProposals,
                    totalNumVoters,
                    totalValueUSD,
                    daosArr,
                    activeMembers,
                    totalNumVotes,
                    tokens,
                    change24hPercentage,
                    change1WeekPercentage,
                    categories,
                    chainLogo,
                    mainTreasuryTitle,
                    aum,
                    isPremium,
                }: IOrganizationExtendedData,
                index: number,
            ) => ({
                daoId,
                organizationId,
                logo,
                daoName,
                totalNumMembers,
                totalNumProposals,
                totalNumVoters,
                totalValueUSD,
                tokens,
                rank: index + 1,
                activeMembers,
                totalNumVotes,
                daosArr,
                change24hPercentage: Number(change24hPercentage) || 0,
                change1WeekPercentage: Number(change1WeekPercentage) || 0,
                categories,
                chainLogo,
                mainTreasuryTitle,
                aum,
                isPremium,
            }),
        );

        return categorySelectedId === SHOW_ALL_CATEGORIES_ID
            ? data
            : filterOrganizationsByCategory(data, categorySelectedId);
    }

    return [];
};

export const organizationsTableSearch = (
    organizationsTableData: IOrganizationItems[],
    value: string,
): IOrganizationItems[] =>
    value
        ? organizationsTableData.filter(({ daoName }: IOrganizationItems) =>
              daoName?.toLowerCase()?.includes(value.toLowerCase()),
          )
        : organizationsTableData;

import { ITopTokenData } from 'types/interfaces';

export const SHOW_ALL_CATEGORIES_ID = 0;
const SHOW_ALL_CATEGORIES_NAME = 'show all';

export const SHOW_ALL_CATEGORIES = {
    id: SHOW_ALL_CATEGORIES_ID,
    name: SHOW_ALL_CATEGORIES_NAME,
};

const organizationTokens: ITopTokenData[] = [
    {
        tokenAddress: '1',
        logo: '',
        symbol: 'TKN',
        name: 'TKN',
    },
    {
        tokenAddress: '2',
        logo: '',
        symbol: 'TKN',
        name: 'TKN',
    },
    {
        tokenAddress: '3',
        logo: '',
        symbol: 'TKN',
        name: 'TKN',
    },
    {
        tokenAddress: '4',
        logo: '',
        symbol: 'TKN',
        name: 'TKN',
    },
    {
        tokenAddress: '5',
        logo: '',
        symbol: 'TKN',
        name: 'TKN',
    },
    {
        tokenAddress: '6',
        logo: '',
        symbol: 'TKN',
        name: 'TKN',
    },
];

export { organizationTokens };

const mockupData: any[] = [
    {
        tokenName: 'OP',
        tokenSymbol: 'OP',
        daosTotalBalance: 2454602419.359649,
        daosTotalUsdValue: 3932763526.5434265,
        tokenIcon: 'https://uploads.deepdao.io/token/OP.webp',
        numDaos: 6,
    },
    {
        tokenName: 'ARB',
        tokenSymbol: 'ARB',
        daosTotalBalance: 170978997314.24353,
        daosTotalUsdValue: 3884072763.647982,
        tokenIcon: 'https://uploads.deepdao.io/token/ARB.webp',
        numDaos: 12,
    },
    {
        tokenName: 'BIT',
        tokenSymbol: 'BIT',
        daosTotalBalance: 104671985610.2643,
        daosTotalUsdValue: 3100034723.435211,
        tokenIcon: 'https://uploads.deepdao.io/token/BIT.webp',
        numDaos: 5,
    },
    {
        tokenName: 'UNI',
        tokenSymbol: 'UNI',
        daosTotalBalance: 594140033.9551201,
        daosTotalUsdValue: 2101073468.1763587,
        tokenIcon: 'https://uploads.deepdao.io/token/UNI.webp',
        numDaos: 20,
    },
    {
        tokenName: 'MATIC',
        tokenSymbol: 'MATIC',
        daosTotalBalance: 1184863119.9398136,
        daosTotalUsdValue: 1050854844.6904665,
        tokenIcon: 'https://uploads.deepdao.io/token/MATIC.webp',
        numDaos: 32,
    },
    {
        tokenName: 'GNO',
        tokenSymbol: 'GNO',
        daosTotalBalance: 7088412.162919738,
        daosTotalUsdValue: 795319870.528881,
        tokenIcon: 'https://uploads.deepdao.io/token/GNO.webp',
        numDaos: 8,
    },
    {
        tokenName: 'ETH',
        tokenSymbol: 'ETH',
        daosTotalBalance: 427381.3428605578,
        daosTotalUsdValue: 769294726.8673134,
        tokenIcon: 'https://uploads.deepdao.io/token/ETH.webp',
        numDaos: 225,
    },
    {
        tokenName: 'ENS',
        tokenSymbol: 'ENS',
        daosTotalBalance: 69168711.77200668,
        daosTotalUsdValue: 684662040.9980513,
        tokenIcon: 'https://uploads.deepdao.io/token/ENS.webp',
        numDaos: 23,
    },
    {
        tokenName: 'DYDX',
        tokenSymbol: 'DYDX',
        daosTotalBalance: 339004394.2803008,
        daosTotalUsdValue: 682775137.5704286,
        tokenIcon: 'https://uploads.deepdao.io/token/DYDX.webp',
        numDaos: 2,
    },
    {
        tokenName: 'USDC',
        tokenSymbol: 'USDC',
        daosTotalBalance: 546207291.7136171,
        daosTotalUsdValue: 546200308.3535342,
        tokenIcon: 'https://uploads.deepdao.io/token/USDC.webp',
        numDaos: 162,
    },
    {
        tokenName: 'LDO',
        tokenSymbol: 'LDO',
        daosTotalBalance: 110084519.64063796,
        daosTotalUsdValue: 224816332.901364,
        tokenIcon: 'https://uploads.deepdao.io/token/LDO.webp',
        numDaos: 13,
    },
];

export { mockupData };

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { DaoTokensTableHeader, DaoTokensItems } from './components';

import { IDaoTokensTableProps, IOrganizationTokensItems } from 'types/interfaces';
import { DaoTokensMockupData } from './components/DaoTokensMockupData';
import { RestrictedBanner } from '../RestrictedBanner';

export const DaoTokensTable: FC<IDaoTokensTableProps> = ({
    data,
    error,
    sortedParam,
    sortByParam,
    loading,
}: IDaoTokensTableProps): ReactElement => {
    return (
        <div className={styles.tablesWrapper}>
            {error?.error.statusCode === 403 ? (
                <>
                    <DaoTokensMockupData />
                    <RestrictedBanner
                        title="DAO Tokens is a DeepDAO premium feature"
                        descriptions={['Available only for DAO Analytics subscribers.']}
                        connectWalletButtonTitle="Subscribe Here"
                    />
                </>
            ) : (
                <div className={styles.tableContent}>
                    {data.length > 0 && !loading && (
                        <div className={styles.tableWrapper}>
                            <div className={styles.tableContainer}>
                                <DaoTokensTableHeader
                                    sortedParamName={sortedParam.paramName}
                                    sortByParam={sortByParam}
                                />

                                <div className={styles.rowsWrapper}>
                                    {data.map((tokensInfo: IOrganizationTokensItems) => (
                                        <DaoTokensItems
                                            key={tokensInfo.tokenName}
                                            {...tokensInfo}
                                            sortedParamName={sortedParam.paramName}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

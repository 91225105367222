import { IStepItems, ISelfListingElement } from 'types/interfaces';

const ORGANIZATION_DETAILS_STEP_INDEX = 0;
const GOVERNANCE_STEP_INDEX = 1;
const DEEPDAO_PAGE_STEP_INDEX = 2;

const stepperItems: IStepItems[] = [
    {
        id: ORGANIZATION_DETAILS_STEP_INDEX,
        description: 'organization details',
    },
    {
        id: GOVERNANCE_STEP_INDEX,
        description: 'governance, token, treasury',
    },
    {
        id: DEEPDAO_PAGE_STEP_INDEX,
        description: 'your deepdao page',
    },
];

const treasuryTypesList: ISelfListingElement[] = [
    {
        id: 1,
        title: 'liquid',
    },
    {
        id: 2,
        title: 'vesting',
    },
];

export {
    stepperItems,
    ORGANIZATION_DETAILS_STEP_INDEX,
    GOVERNANCE_STEP_INDEX,
    DEEPDAO_PAGE_STEP_INDEX,
    treasuryTypesList,
};

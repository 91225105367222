import React, { FC, ReactElement, useCallback, useContext } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { DISCUSSIONS, ORGANIZATION_DATA } from 'constants/organizationTabs';

import { OrganizationContext } from 'context/organizationContext';

export const TabsController: FC = (): ReactElement => {
    const { mainTabSelected, changeMainTab } = useContext(OrganizationContext);

    const showActiveTab = useCallback(
        (tab: string): boolean => {
            return tab === mainTabSelected;
        },
        [mainTabSelected],
    );

    return (
        <div className={styles.tabsWrapper}>
            <div className={styles.tabsContainer}>
                <div
                    onClick={() => changeMainTab(ORGANIZATION_DATA)}
                    className={classNames(styles.tabItem, {
                        [styles.activeTab]: showActiveTab(ORGANIZATION_DATA),
                    })}
                >
                    <div className={styles.itemContent}>
                        <span>organization data</span>
                    </div>
                </div>

                <div
                    onClick={() => changeMainTab(DISCUSSIONS)}
                    className={classNames(styles.tabItem, { [styles.activeTab]: showActiveTab(DISCUSSIONS) })}
                >
                    <div className={styles.itemContent}>
                        <span>brainstorm</span>
                        <div className={styles.beta}>
                            <span className={styles.text}>beta</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

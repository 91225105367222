import React, { FC, ReactElement, useState } from 'react';

import styles from './styles.module.scss';

import { InfoCircle, TableDataNotFound } from 'components';
import { CurrencyRowItems, TableHeader } from './components';

import { CURRENCIES_PARAMS } from './constants';

import { useAppSelector } from 'store';
import { tableDataSort } from 'helpers';
import { createCurrenciesTableData } from './utils';

import { ISortedParam, IOrganizationCurrencyTableData } from 'types/interfaces';
import { TREASURY_TAB, TREASURY_TAB_DESCRIPTIONS } from '../../../../../../../../constants';

export const CurrenciesTable: FC = (): ReactElement => {
    const { organizationCurrenciesData } = useAppSelector(({ organization }) => organization);

    const [sortedParam, setSortedParam] = useState<ISortedParam>({
        paramName: CURRENCIES_PARAMS.USD_VALUE_PARAM,
        descending: true,
    });

    const sortByParam = (param: string) => {
        setSortedParam({
            paramName: param,
            descending: sortedParam.paramName === param ? !sortedParam.descending : true,
        });
    };

    const currenciesTableData = createCurrenciesTableData(tableDataSort([...organizationCurrenciesData], sortedParam));

    return (
        <>
            {currenciesTableData.length ? (
                <div className={styles.tableWrapper}>
                    <div className={styles.titleWrapper}>
                        <h4 className={styles.tableTitle}>Currencies</h4>
                        <InfoCircle
                            tooltipText={TREASURY_TAB_DESCRIPTIONS[TREASURY_TAB.CURRENCIES]}
                            className={styles.infoIcon}
                        />
                    </div>
                    <div className={styles.dataContainer}>
                        <div className={styles.tableContainer}>
                            <TableHeader sortByParam={sortByParam} sortedParamName={sortedParam.paramName} />

                            <div className={styles.rowsWrapper}>
                                <div className={styles.rowsContainer}>
                                    {currenciesTableData.map((currencyItems: IOrganizationCurrencyTableData) => (
                                        <CurrencyRowItems key={currencyItems.rank} {...currencyItems} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <TableDataNotFound forTable overflowMarginsSecondVariant />
            )}
        </>
    );
};

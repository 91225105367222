import React, { FC, ReactElement, useCallback, useState } from 'react';

import { Drawer, Button } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import moment from 'moment';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { MembersFilter } from '../../../MembersFilter';
import { SubstormsFilter } from '../../../SubstormsFilter';

import { dolphinDown, dolphinUp, diamondIcon } from 'assets';

import { IDrawerWrapperProps, IDateFilterBreakPoint } from 'types/interfaces';

export const DrawerWrapper: FC<IDrawerWrapperProps> = ({
    drawerOpen,
    onClose,
    handleUpdateOrderByTotalVotersFilter,
    filter,
    isLogged,
    handleUpdateOnlyMyFilter,
    membersSearch,
    setMembersSearch,
    isInputOpen,
    onInputClick,
    discussionsFilterMembers,
    handleUpdateMembersFilter,
    handleUpdateOrderByNumberOfMembersFilter,
    handleUpdateOrderByNumberOfTokensFilter,
    isDateRangePickerOpen,
    dateFilterBreakPoints,
    dateRangePickerSelectedDates,
    handleUpdateFilterDates,
    handleUpdateSubstormsFilter,
    substormsList,
    substormsListOpen,
    setSubstormsListOpen,
}: IDrawerWrapperProps): ReactElement => {
    const [fromDateValue, setFromDateValue] = useState<string | undefined>();
    const [toDateValue, setToDateValue] = useState<string | undefined>();

    const onDrawerClose = useCallback(() => {
        onClose();
    }, [fromDateValue, toDateValue, onClose]);

    const onApplyDatesClick = useCallback(() => {
        handleUpdateFilterDates('Custom', moment(fromDateValue), moment(toDateValue));
        onClose();
    }, [handleUpdateFilterDates, fromDateValue, toDateValue]);

    return (
        <Drawer
            anchor="bottom"
            open={drawerOpen}
            onClose={onDrawerClose}
            classes={{ root: styles.drawerRoot, paper: styles.drawerPaper }}
        >
            <div className={styles.wrapper}>
                {isInputOpen && (
                    <>
                        <div className={styles.membersHeader}>
                            <Button className={styles.button} onClick={() => onInputClick(!isInputOpen)}>
                                <ArrowForwardIcon className={styles.arrowIcon} />
                            </Button>
                            <h6 className={styles.text}>Filters</h6>
                        </div>
                        <MembersFilter
                            membersSearch={membersSearch}
                            setMembersSearch={setMembersSearch}
                            isInputOpen={isInputOpen}
                            onInputClick={onInputClick}
                            discussionsFilterMembers={discussionsFilterMembers}
                            handleUpdateMembersFilter={handleUpdateMembersFilter}
                            filter={filter}
                        />
                    </>
                )}

                {substormsListOpen && (
                    <>
                        <div className={styles.membersHeader}>
                            <Button className={styles.button} onClick={setSubstormsListOpen}>
                                <ArrowForwardIcon className={styles.arrowIcon} />
                            </Button>
                            <h6 className={styles.text}>Filters</h6>
                        </div>
                        <SubstormsFilter
                            substormsList={substormsList}
                            handleUpdateSubstormsFilter={handleUpdateSubstormsFilter}
                            filter={filter}
                            substormsListOpen={substormsListOpen}
                            setSubstormsListOpen={setSubstormsListOpen}
                        />
                    </>
                )}

                {isDateRangePickerOpen && (
                    <>
                        <div className={styles.titleSection}>
                            <h5 className={styles.title}>Dates</h5>
                        </div>
                        <div className={styles.dateFilterBreakPointsList}>
                            {dateFilterBreakPoints.map(({ label, from, to }: IDateFilterBreakPoint) => (
                                <div
                                    key={label}
                                    className={classNames(styles.breakPoint, {
                                        [styles.bold]: label === dateRangePickerSelectedDates.label,
                                    })}
                                    onClick={() => handleUpdateFilterDates(label, from, to)}
                                >
                                    {label}
                                </div>
                            ))}
                        </div>
                        <div className={styles.customDatesWrapper}>
                            <div className={styles.customDates}>
                                <div className={styles.dateFieldWrapper}>
                                    <p>from</p>
                                    <input
                                        type="date"
                                        onChange={(e) => setFromDateValue(e.target.value)}
                                        value={fromDateValue}
                                        className={styles.dateField}
                                        placeholder="dd-mm-yyyy"
                                        min="1970-01-01"
                                        max="2070-12-31"
                                    />
                                </div>
                                <div className={styles.dateFieldWrapper}>
                                    <p>to</p>
                                    <input
                                        type="date"
                                        onChange={(e) => setToDateValue(e.target.value)}
                                        value={toDateValue}
                                        className={styles.dateField}
                                        placeholder="dd-mm-yyyy"
                                        min="1970-01-01"
                                        max="2070-12-31"
                                    />
                                </div>
                            </div>

                            <Button
                                className={styles.applyButton}
                                disabled={!Boolean(fromDateValue) || !Boolean(toDateValue)}
                                onClick={onApplyDatesClick}
                            >
                                apply
                            </Button>
                        </div>
                    </>
                )}

                {!isInputOpen && !isDateRangePickerOpen && !substormsListOpen && (
                    <>
                        <div className={styles.titleSection}>
                            <h5 className={styles.title}>Filters</h5>
                        </div>
                        <div className={styles.commonFiltersWrapper}>
                            <div
                                className={styles.orderFilter}
                                onClick={() =>
                                    handleUpdateOrderByTotalVotersFilter(
                                        filter.orderByTotalVoters === 'DESC' ? 'ASC' : 'DESC',
                                    )
                                }
                            >
                                <img
                                    src={diamondIcon}
                                    className={classNames(styles.diamondIcon, {
                                        [styles.votersOrderAsc]: filter.orderByTotalVoters === 'ASC',
                                    })}
                                    alt=""
                                />
                                <span
                                    className={classNames([
                                        {
                                            [styles.bold]:
                                                filter.orderByTotalVoters === 'ASC' ||
                                                filter.orderByTotalVoters === 'DESC',
                                        },
                                    ])}
                                >
                                    {filter.orderByTotalVoters === 'ASC' ? 'Least' : 'Most'} active
                                </span>
                            </div>

                            <SubstormsFilter
                                substormsList={substormsList}
                                handleUpdateSubstormsFilter={handleUpdateSubstormsFilter}
                                filter={filter}
                                substormsListOpen={substormsListOpen}
                                setSubstormsListOpen={setSubstormsListOpen}
                            />

                            <MembersFilter
                                membersSearch={membersSearch}
                                setMembersSearch={setMembersSearch}
                                isInputOpen={isInputOpen}
                                onInputClick={onInputClick}
                                discussionsFilterMembers={discussionsFilterMembers}
                                handleUpdateMembersFilter={handleUpdateMembersFilter}
                                filter={filter}
                            />

                            {isLogged && (
                                <div className={styles.orderFilter} onClick={handleUpdateOnlyMyFilter}>
                                    <FaceIcon className={styles.discussionsFilterIcon} />
                                    <span className={classNames([{ [styles.bold]: filter.isOnlyMyDiscussions }])}>
                                        My Brainstorms
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className={classNames(styles.orderFiltersWrapper, styles.orderFiltersWrapperFirstBlock)}>
                            <div className={styles.orderByNumberOfMembersHeader}>
                                <span>by members</span>
                            </div>
                            <div className={styles.container}>
                                <div
                                    className={styles.orderByNumberOfMembersDesc}
                                    onClick={() =>
                                        handleUpdateOrderByNumberOfMembersFilter('filterByNumberOfMembersSupport')
                                    }
                                >
                                    <img src={dolphinUp} alt="dolphinUp" />
                                    <span
                                        className={classNames({
                                            [styles.bold]: filter.filterByNumberOfMembersSupport,
                                        })}
                                    >
                                        Supported
                                    </span>
                                </div>

                                <div
                                    className={styles.orderByNumberOfMembersAsc}
                                    onClick={() =>
                                        handleUpdateOrderByNumberOfMembersFilter('filterByNumberOfMembersAgainst')
                                    }
                                >
                                    <img src={dolphinDown} alt="dolphinDown" />
                                    <span
                                        className={classNames({
                                            [styles.bold]: filter.filterByNumberOfMembersAgainst,
                                        })}
                                    >
                                        Against
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.orderFiltersWrapper}>
                            <div className={styles.orderByNumberOfMembersHeader}>
                                <span>by tokens</span>
                            </div>
                            <div className={styles.container}>
                                <div
                                    className={styles.orderByNumberOfTokensDesc}
                                    onClick={() =>
                                        handleUpdateOrderByNumberOfTokensFilter('filterByTokenBalanceSupport')
                                    }
                                >
                                    <img src={dolphinUp} alt="dolphinUp" />
                                    <span
                                        className={classNames({
                                            [styles.bold]: filter.filterByTokenBalanceSupport,
                                        })}
                                    >
                                        Supported
                                    </span>
                                </div>

                                <div
                                    className={styles.orderByNumberOfTokensAsc}
                                    onClick={() =>
                                        handleUpdateOrderByNumberOfTokensFilter('filterByTokenBalanceAgainst')
                                    }
                                >
                                    <img src={dolphinDown} alt="dolphinDown" />
                                    <span
                                        className={classNames({
                                            [styles.bold]: filter.filterByTokenBalanceAgainst,
                                        })}
                                    >
                                        Against
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Drawer>
    );
};

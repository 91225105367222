import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { TABLE_TABS_TYPES } from '../../constants';

import { ITabsControllerProps } from 'types/interfaces';

export const TableTabsController: FC<ITabsControllerProps> = ({
    changeTab,
    tab,
}: ITabsControllerProps): ReactElement => (
    <div className={styles.tabsContainer}>
        {Object.entries(TABLE_TABS_TYPES).map(([tabKey, tabItem]) => (
            <div
                onClick={() => changeTab(tabKey)}
                className={classNames(styles.tabItem, { [styles.activeTab]: tabKey === tab })}
                key={tabKey}
            >
                <span>{tabItem}</span>
            </div>
        ))}
    </div>
);

import { SocialLink } from './componentProps.interfaces';

import { IMAGE_TYPES } from 'constants/imageTypes';
import { VOTING_OPTIONS } from 'constants/votingConstants';
import { NOTIFICATIONS_ACTIONS_TYPES } from 'constants/notificationsActions';
import { IErrorResponse } from './custom.interfaces';
import { IApiAccountMetaData } from './organizationsSearch.interfaces';

interface IUserTokenData {
    cmcId: number | null;
    logo: string;
    name: string;
    symbol: string;
    tokenAddress: string;
    usd: number;
    usdVolumePercent: number;
    value: number;
}

interface IUserORGData {
    daoId: string;
    organizationId: string;
    address: string;
    aum: number;
    image: string;
    membersAmount: number;
    name: string;
    proposalsAmount: number;
}

interface IUserData {
    address: string;
    aum: string;
    avatar: string | null;
    createdAt: string;
    daoAmount: string | null;
    daos: IUserORGData[];
    description: string | null;
    github: string | null;
    name: string;
    participationScore: number | string | null;
    relativeScore: number | string | null;
    proposalsAmount: string | null;
    tokens: IUserTokenData[];
    totalAum: string | null;
    totalDaos: number | string | null;
    totalProposals: number | string | null;
    totalVotes: number | string | null;
    totalOrgs: number | string | null;
    twitter: string | null;
    votesAmount: string | null;
    website: string | null;
    personalInfo: IUserPersonalInfo;
    participationScoreRank: number | null;
}

interface IUserProposalData {
    avatar: string | null;
    coverPhoto3Box: string | null;
    createdAt: string;
    daoId: string;
    daoImage: string | null;
    daoName: string;
    daohausDidPass: boolean;
    description: string | null;
    firstName: string | null;
    fundsRequested?: string;
    gracePeriod: string;
    image3Box: string | null;
    lastName: string | null;
    middleName: string | null;
    name3Box: string | null;
    organizationId: string;
    outcome: number;
    platform: number;
    proposalNativeId: string;
    proposerId: string;
    stage: string;
    status: string;
    title: string | null;
    userPicture: string | null;
    votesCount: string;
    successfulVote: boolean;
    voteChoice: number;
    choices: string[];
}

interface IUserProposalsData {
    [key: string]: IUserProposalData[];
}

interface IUserVoteData {
    successful: boolean | null;
    platform: number;
    snapshotProposalChoicesMappedToScores: Record<string, unknown> | null;
    createdAt: string;
    daoId: string;
    daoLogo: string | null;
    daoName: string;
    description: string;
    organizationId: string;
    proposalId: string;
    proposerAvatar: string | null;
    proposerId: string;
    proposerName: string;
    status: string;
    title: string;
    voter: string;
    voteChoice: number;
    voteNativeId: string;
    votesCount: string;
    choices: string[];
}

interface IUserVotesData {
    [key: string]: IUserVoteData[];
}

interface IMoreInfoTables {
    proposalsData: IUserProposalData[] | undefined;
    votesData: IUserVoteData[] | undefined;
}

interface IProposalTableData {
    proposalsData: IUserProposalData[] | undefined;
}

interface IVotesTableData {
    votesData: IUserVoteData[] | undefined;
}

interface IIndex {
    index: number;
}

type IUserProposalRowData = Pick<
    IUserProposalData,
    'fundsRequested' | 'title' | 'createdAt' | 'status' | 'successfulVote' | 'voteChoice' | 'platform' | 'choices'
> &
    IIndex;

type IUserVoteRowData = Pick<
    IUserVoteData,
    | 'successful'
    | 'status'
    | 'title'
    | 'createdAt'
    | 'voteChoice'
    | 'platform'
    | 'snapshotProposalChoicesMappedToScores'
    | 'choices'
> &
    IIndex;

interface IOrganizationStatsContainer {
    totalAUM: number;
    members: number;
    daoProposals: number;
}

type ITopTokensValuesRowData = Omit<IUserTokenData, 'cmcId' | 'tokenAddress'> & IIndex;

type ITopOrganizationsRowData = Omit<IUserORGData, 'membersAmount' | 'proposalsAmount' | 'address' | 'daoId'> & IIndex;

type IDAOExperienceTableData = Omit<IUserORGData, 'address' | 'aum' | 'membersAmount' | 'proposalsAmount'> & {
    proposalsByUserAmount: number;
    votesByUserAmount: number;
};

interface IAvatarUploaderProps {
    peopleContactId: string | undefined;
    setUploadedImageSrc: (imgSrc: string) => void;
    className?: string;
    title?: string;
}

interface IUpdateUserDataRequest {
    tagline: string;
    about: string;
    skills: string[];
    interests: string[];
    resources: { type: string; handle: string }[];
}

interface IImageCropModalProps {
    uploadedImageSrc: string;
    setUploadedImageSrc: (imageSrc: string) => void;
    peopleContactId: string | undefined;
    address: string | undefined;
}

interface IPeopleContact {
    id?: string;
    name: string;
    address: string;
    about: string;
    tagline: string;
    logo: string;
    email: string;
    openFor: string | null;
}

interface IUserPersonalInfo {
    people_contact: IPeopleContact;
    resources: SocialLink[];
    skills: { name: string }[];
    interests: { name: string }[];
}

interface IUserWorkStatus {
    id: number;
    name: string;
    value: string;
}

interface IUserPageBodyProps {
    isAuthenticatedUser: boolean;
    address?: string;
}

interface IUserPageHeaderProps {
    isAuthenticatedUser: boolean;
    handleEditProfileClick: () => void;
    uploadedImageSrc: string;
    setUploadedImageSrc: (imageSrc: string) => void;
    isGuestUserPage: boolean;
    isLoggedUser: boolean;
}

interface IUserAboutProps {
    isAuthenticatedUser: boolean;
}

interface IAuthenticatedUserOptionsProps {
    handleEditProfileClick: () => void;
    setUserWorkStatus: (status: IUserWorkStatus) => void;
}

interface IUserOpportunitiesResponse {
    open_for: string;
}

interface IOpportunitiesOptionsProps {
    setUserWorkStatusOpen: (isOpen: boolean) => void;
}

interface IUserOpportunitiesPayload {
    userId: string;
    openFor: string;
}

type IUpdateUserDataResponse = IUserPersonalInfo;

interface ISimilarUserDao {
    daoId: string;
    logo: string;
    name: string;
}

interface ISimilarUserToken {
    address: string;
    name: string;
    symbol: string;
    value?: string;
    icon?: string;
}

interface IUserLogoData {
    id: number | string;
    name: string;
    logo: string;
    link?: string;
}

interface ILogosWrapperProps {
    data: IUserLogoData[];
    totalItems: number | null;
    className?: string;
    imageType: IMAGE_TYPES;
}

interface ISimilarUserProperties {
    address: string;
    name?: string;
    avatar?: string;
}

interface ISimilarUserData {
    organizations?: ISimilarUserDao[];
    number_of_organizations?: {
        high: number;
        low: number;
    };
    number_of_tokens?: {
        high: number;
        low: number;
    };
    member: ISimilarUserProperties;
    tokens?: ISimilarUserToken[];
}

type ISimilarUserCardProps = {
    organizations?: ISimilarUserDao[];
    tokens?: ISimilarUserToken[];
    tokensCount: number;
    organizationsCount: number;
} & ISimilarUserProperties;

interface IScoreStatsProps {
    title: string;
    mainCounter: string;
    firstDate?: string;
    latestDate?: string;
    showDates?: boolean;
}

interface IPersonalInfoProps {
    address?: string;
    tagline?: string;
}

interface ISimilarPeopleCarouselProps {
    data: ISimilarUserData[];
    loading: boolean;
    title: string;
}

export enum USER_NOTIFICATION_TYPES {
    NEW_FOLLOWER = 'new_follower',
    NEW_BRAINSTORM = 'new_brainstorm',
    NEW_BRAINSTORM_VOTE = 'new_brainstorm_vote',
    TAGGED_USER = 'tagged_user',
    NEW_BRAINSTORM_COMMENT = 'new_brainstorm_comment',
}

interface INotificationData {
    data: {
        address: string;
        ensImageUrl: string | null;
        ensName: string | null;
        idxImageUrl: string | null;
        idxName: string | null;
        logo: string | null;
        name: string | null;
        brainstormId?: string;
        organizationId?: string;
        title?: string;
    } | null;
    groupData: {
        brainstormId: string;
        organizationId: string;
        voterAddress?: string;
        creatorAddress?: string;
        choice?: VOTING_OPTIONS;
        organizationName?: string;
        title?: string;
        name: string | null;
        idxName: string | null;
        ensName: string | null;
        address: string;
        logo: string | null;
        ensImageUrl: string | null;
        idxImageUrl: string | null;
    } | null;
    id: string;
    isSeen: boolean;
    type: USER_NOTIFICATION_TYPES;
    createdAt: string;
}

interface IUserNotificationsData {
    newNotifications: INotificationData[];
    oldNotifications: INotificationData[];
}

interface IUserFriendsAndEnemiesData {
    coVoter: string;
    coVoterDaoScore: number;
    coVoterName: string | null;
    friendlyVotes: number | null;
    hostileVotes: number | null;
    targetVoter: string;
}

type IUserFriendsAndEnemiesTableData = IUserFriendsAndEnemiesData & { name: string };

interface IFriendsAndEnemiesTableProps {
    title: string;
    tooltipText: string;
    data: IUserFriendsAndEnemiesTableData[];
    isFriendsTable?: boolean;
    loading: boolean;
    isForbidden: boolean;
}

type IFriendsAndEnemiesRowProps = IUserFriendsAndEnemiesTableData & {
    sortedParamName: string;
    isFriendsTable: boolean;
};

interface IUserLabel {
    id: string;
    name: string;
}

interface IUserLabelsInfo {
    labels: IUserLabel[];
    gScore: number;
    gScoreRanking: number;
}

interface IUserLabelsData {
    apiAccountMetaData: IApiAccountMetaData;
    data: IUserLabelsInfo;
}

interface IGetUserDataWorker {
    type: string;
    payload: { userId: string };
}

interface IGetUserDataSuccessWorker {
    type: string;
    payload: IUserData;
}

interface IGetUserProposalsDataSuccessWorker {
    type: string;
    payload: IUserProposalsData;
}

interface IGetUserVotesDataSuccessWorker {
    type: string;
    payload: IUserVotesData;
}

interface IClearUserDataAction {
    type: string;
    payload: undefined;
}

interface IUpdateUserPersonalInfo {
    type: string;
    payload: IUserPersonalInfo;
}

interface IUpdatePeopleContact {
    type: string;
    payload: IPeopleContact;
}

interface IGetSimilarUsersAction {
    type: string;
    payload: {
        address: string;
    };
}

interface IGetSimilarUsersSuccessAction {
    type: string;
    payload: ISimilarUserData[];
}

interface IGetUserTokenListAction {
    type: string;
    payload: { userId: string };
}

interface IGetUserTokenListSuccessAction {
    type: string;
    payload: {
        totalAum: number | string | null;
        tokens: IUserTokenData[];
    };
}

interface IToogleAboutSectionAction {
    type: string;
    payload: {
        userAboutSectionOpen: boolean;
    };
}

interface IGetUserNotificationPayload {
    type: string;
    payload: {
        token?: string | null;
        notificationsOffset?: number;
        notificationsActionType: NOTIFICATIONS_ACTIONS_TYPES;
    };
}

interface IGetUserNotificationDataAction {
    type: string;
    payload: IUserNotificationsData;
}

interface IUpdateNotificationSeenStatusPayload {
    type: string;
    payload: {
        notificationId: string;
    };
}

interface IGetUserIsFollowingDataPayload {
    type: string;
    payload: {
        isLogged: boolean;
        address: string;
    };
}

interface IFollowUnfollowUserPayload {
    type: string;
    payload: {
        isUserFollowing: boolean;
        address: string;
    };
}

interface IUserFollowingAction {
    type: string;
    payload: {
        isUserFollowing: boolean;
    };
}

interface IGetUserFriendsANdEnemiesDataAction {
    type: string;
    payload: IUserFriendsAndEnemiesData[];
}

interface IUserErrorAction {
    type: string;
    payload: undefined;
    error: IErrorResponse;
}

type IUserActionTypes =
    | IGetUserDataWorker
    | IGetUserDataSuccessWorker
    | IClearUserDataAction
    | IUpdateUserPersonalInfo
    | IUpdatePeopleContact
    | IGetSimilarUsersAction
    | IGetSimilarUsersSuccessAction
    | IGetUserTokenListAction
    | IGetUserTokenListSuccessAction
    | IToogleAboutSectionAction
    | IGetUserNotificationDataAction
    | IUserFollowingAction
    | IGetUserFriendsANdEnemiesDataAction
    | IUserErrorAction
    | IGetUserProposalsDataSuccessWorker
    | IGetUserVotesDataSuccessWorker;

export type {
    IUserData,
    IUserORGData,
    IMoreInfoTables,
    IProposalTableData,
    IUserProposalData,
    IUserProposalRowData,
    IVotesTableData,
    IUserVoteData,
    IUserVoteRowData,
    IOrganizationStatsContainer,
    IUserTokenData,
    ITopTokensValuesRowData,
    ITopOrganizationsRowData,
    IGetUserDataWorker,
    IUserActionTypes,
    IUserPersonalInfo,
    IPeopleContact,
    IUpdateUserDataRequest,
    IUpdateUserDataResponse,
    IUserProposalsData,
    IUserVotesData,
    IUserWorkStatus,
    IUserPageBodyProps,
    IUserPageHeaderProps,
    IAuthenticatedUserOptionsProps,
    IDAOExperienceTableData,
    IAvatarUploaderProps,
    IImageCropModalProps,
    IUserAboutProps,
    IUserOpportunitiesResponse,
    IUserOpportunitiesPayload,
    IOpportunitiesOptionsProps,
    ISimilarUserData,
    ISimilarUserDao,
    ISimilarUserCardProps,
    ILogosWrapperProps,
    IUserLogoData,
    IGetSimilarUsersAction,
    ISimilarUserToken,
    IGetUserTokenListAction,
    IScoreStatsProps,
    IPersonalInfoProps,
    ISimilarPeopleCarouselProps,
    IUserNotificationsData,
    IGetUserNotificationPayload,
    INotificationData,
    IUpdateNotificationSeenStatusPayload,
    IGetUserIsFollowingDataPayload,
    IFollowUnfollowUserPayload,
    IUserFriendsAndEnemiesData,
    IFriendsAndEnemiesTableProps,
    IUserFriendsAndEnemiesTableData,
    IFriendsAndEnemiesRowProps,
    IUserErrorAction,
    IUserLabel,
    IUserLabelsInfo,
    IUserLabelsData,
};

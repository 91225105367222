import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { HrefLink, StatusInfo } from 'components';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';
import { STATUSES } from 'constants/statuses';
import { convertedStatusesNames } from './constants';

import { useAppSelector } from 'store';
import { FormatUtils, LinkUtils } from 'utils';
import { defaultUserPlaceholderImage } from 'assets';
import { convertStatus } from './utils';

export const FeedProposalInfoContainer: FC = (): ReactElement => {
    const {
        feedProposalData: { proposerName, proposer, votesCount, status, createdAt, proposerAvatar, url },
    } = useAppSelector(({ feedProposal }) => feedProposal);

    return (
        <div className={styles.feedProposalInfoContainer}>
            <div className={styles.card}>
                <div className={styles.cardHeader}>
                    <img className={styles.avatar} src={proposerAvatar || defaultUserPlaceholderImage} alt="" />
                    <Link to={LinkUtils.createDynamicLink(proposer, 'user')} className={styles.userName}>
                        {proposerName || FormatUtils.formatUserAddress(proposer) || DATA_NOT_FOUND}
                    </Link>
                </div>
                <div className={styles.statusInfo}>
                    <div
                        className={classNames(styles.statusHeading, {
                            [styles.acceptedStatus]: status === STATUSES.ACCEPTED,
                            [styles.rejectedStatus]: status === STATUSES.REJECTED,
                        })}
                    >
                        <h4>status:</h4>
                        {(status === STATUSES.ACCEPTED || status === STATUSES.REJECTED) && (
                            <StatusInfo status={status} />
                        )}
                        <span>
                            {convertedStatusesNames[
                                status as
                                    | STATUSES.ACCEPTED
                                    | STATUSES.REJECTED
                                    | STATUSES.ACTIVE
                                    | STATUSES.CLOSED
                                    | STATUSES.NOT_FOUND
                            ] || DATA_NOT_FOUND}
                        </span>
                    </div>
                    {status && <StatusInfo status={convertStatus(status)} forFeedProposal={true} />}
                </div>
                <div className={styles.mainInfo}>
                    <div>
                        <h5>Start date:</h5>
                        <span>{FormatUtils.getFormattedLastUpdateDate(createdAt)}</span>
                    </div>
                    <div>
                        <h5>Total votes:</h5>
                        <span>{FormatUtils.checkIfDataNotExist(votesCount) ? DATA_NOT_FOUND : votesCount}</span>
                    </div>
                </div>
            </div>

            <HrefLink
                className={classNames(styles.originalSourceNavigation, {
                    [styles.navigationInactive]: !url,
                })}
                href={url}
            >
                <>
                    <span className={styles.title}>original decision making</span>
                    <ArrowForwardIcon className={styles.arrowIcon} />
                </>
            </HrefLink>
        </div>
    );
};

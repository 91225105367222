import React, { FC, ReactElement, memo } from 'react';

import classNames from 'classnames';

import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './styles.module.scss';

import { IDiscussionTooltipProps } from 'types/interfaces';

export const DiscussionTooltip: FC<IDiscussionTooltipProps> = memo(
    ({ className, onClose, text }: IDiscussionTooltipProps): ReactElement => (
        <div className={classNames(styles.tooltipWrapper, className)}>
            <div className={styles.tootlipContainer}>
                <p className={styles.text}>{text}</p>

                <Button className={styles.closeButton} onClick={onClose}>
                    <CloseIcon className={styles.closeIcon} />
                </Button>
            </div>
        </div>
    ),
);

import React, { createContext, useEffect, useState } from 'react';
import Web3Modal from 'web3modal';
import { providerOptions } from 'web3/walletProviders';

interface IWeb3ModalContextData {
    web3Modal: Web3Modal | undefined;
    setWeb3Modal: (web3Modal: Web3Modal) => void;
}

export const Web3ModalContext = createContext({} as IWeb3ModalContextData);

export const Web3ModalWrapper = ({ children }: { children: JSX.Element[] | JSX.Element }): JSX.Element => {
    const [web3Modal, setWeb3Modal] = useState<Web3Modal>();

    const initWeb3Modal = () => {
        if (!web3Modal) {
            setWeb3Modal(
                new Web3Modal({
                    network: 'mainnet',
                    cacheProvider: true,
                    theme: 'dark',
                    providerOptions,
                }),
            );
        }
    };

    useEffect(() => {
        initWeb3Modal();

        return () => {
            web3Modal?.clearCachedProvider();
            setWeb3Modal(undefined);
        };
    }, []);

    return <Web3ModalContext.Provider value={{ web3Modal, setWeb3Modal }}>{children}</Web3ModalContext.Provider>;
};

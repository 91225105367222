import { axiosInstance } from 'services';
import { AxiosResponse } from 'axios';

import { API_KEY } from 'constants/apis';
import { ORGANIZATIONS_GRAPH_NAMES } from 'constants/organizationsGraphNames';

import { IOrganizationsCategoriesResponse, IOrganizationsResponse } from 'types/interfaces';

export const OrganizationsApi = {
    getOrganizationsData: (
        offset = 0,
        limit = 200,
        orderBy = 'totalValueUSD',
        paginationOrderDescending: boolean,
    ): Promise<AxiosResponse<IOrganizationsResponse>> =>
        axiosInstance.get<IOrganizationsResponse>(
            `dashboard/${API_KEY}?limit=${limit}&offset=${offset}&orderBy=${orderBy}&order=${
                paginationOrderDescending ? 'DESC' : 'ASC'
            }`,
        ),
    getOrganizationsGraphData: (name: ORGANIZATIONS_GRAPH_NAMES): Promise<AxiosResponse<unknown>> =>
        axiosInstance.get<unknown>(`dashboard/${API_KEY}/graph/${name}`),
    getOrganizationsCategories: (): Promise<AxiosResponse<IOrganizationsCategoriesResponse>> =>
        axiosInstance.get<IOrganizationsCategoriesResponse>('ecosystem-category/organization-categories'),
};

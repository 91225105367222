import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ImageStatus, TextStatus } from './components';

import { STATUSES } from 'constants/statuses';
import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { IStatusInfoProps } from 'types/interfaces';

export const StatusInfo: FC<IStatusInfoProps> = ({
    status,
    forFeed = false,
    forFeedProposal = false,
    removeForMobile = false,
}: IStatusInfoProps): ReactElement => {
    switch (status) {
        case STATUSES.ACTIVE: {
            return (
                <TextStatus
                    convertedStatus="active"
                    originalStatus={STATUSES.ACTIVE}
                    forFeed={forFeed}
                    forFeedProposal={forFeedProposal}
                    removeForMobile={removeForMobile}
                />
            );
        }

        case STATUSES.EXECUTED: {
            return (
                <TextStatus
                    convertedStatus="done"
                    originalStatus={STATUSES.EXECUTED}
                    forFeed={forFeed}
                    forFeedProposal={forFeedProposal}
                    removeForMobile={removeForMobile}
                />
            );
        }

        case STATUSES.ONGOING: {
            return (
                <TextStatus
                    convertedStatus="pending"
                    originalStatus={STATUSES.ONGOING}
                    forFeed={forFeed}
                    forFeedProposal={forFeedProposal}
                    removeForMobile={removeForMobile}
                />
            );
        }

        case STATUSES.PENDING: {
            return (
                <TextStatus
                    convertedStatus="pending"
                    originalStatus={STATUSES.PENDING}
                    forFeed={forFeed}
                    forFeedProposal={forFeedProposal}
                    removeForMobile={removeForMobile}
                />
            );
        }

        case STATUSES.CANCELLED: {
            return (
                <TextStatus
                    convertedStatus="cancelled"
                    originalStatus={STATUSES.CANCELLED}
                    forFeed={forFeed}
                    forFeedProposal={forFeedProposal}
                    removeForMobile={removeForMobile}
                />
            );
        }

        case STATUSES.QUEUED: {
            return (
                <TextStatus
                    convertedStatus="queued"
                    originalStatus={STATUSES.QUEUED}
                    forFeed={forFeed}
                    forFeedProposal={forFeedProposal}
                    removeForMobile={removeForMobile}
                />
            );
        }

        case STATUSES.CLOSED: {
            return (
                <TextStatus
                    convertedStatus="closed"
                    originalStatus={STATUSES.CLOSED}
                    forFeed={forFeed}
                    forFeedProposal={forFeedProposal}
                    removeForMobile={removeForMobile}
                />
            );
        }

        case STATUSES.ACCEPTED: {
            return <ImageStatus thumbsUp={true} forFeed={forFeed} removeForMobile={removeForMobile} />;
        }

        case STATUSES.REJECTED: {
            return <ImageStatus thumbsUp={false} forFeed={forFeed} removeForMobile={removeForMobile} />;
        }

        default:
            return (
                <div className={classNames(styles.statusNotFound, { [styles.removeForMobile]: removeForMobile })}>
                    {DATA_NOT_FOUND}
                </div>
            );
    }
};

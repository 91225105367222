import Axios from 'axios';

import { BASE_URL, DEEP_DAO_API } from 'constants/apis';
import { createAuthorizationHeaders } from 'helpers/createAuthorizationHeaders';

import { store } from 'store';
import { SET_ACCESS_TOKEN } from 'store/actions/auth.actions';
import { SET_PREMIUM_ACCESS_TOKEN } from 'store/actions/premiumSubscription.actions';
import { AuthApi, PremiumSubscription } from 'store/apis';
import { getDeepDAOTokenFromStorage, getPremiumUserTokenFromStorage } from 'web3/storageHelper';

const JWT_EXPIRED_ERROR = 'jwt expired';

const PREMIUM_USER_URLS_KEYS = ['client', 'bluesnap', 'paypal', 'assets', 'friends_and_antagonists', 'dao-tokens'];

const axiosInstance = Axios.create({
    baseURL: BASE_URL,
    responseType: 'json',
});

axiosInstance.interceptors.request.use(
    (config) => {
        const deepDAOToken = getDeepDAOTokenFromStorage();
        const premiumUserToken = getPremiumUserTokenFromStorage();

        config.headers = { ...config.headers, ...createAuthorizationHeaders(deepDAOToken, premiumUserToken) };

        if (config.url?.includes('v0.1/')) {
            config.baseURL = DEEP_DAO_API;
        }

        return config;
    },
    (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
    ({ data, headers }) => ({
        hasError: false,
        error: null,
        data: data,
        headers,
    }),
    async (error) => {
        try {
            const originalRequest = error?.config;
            const errorMessage = error?.response?.data?.message || error?.response?.data?.error?.message;

            if (error?.response?.status === 401 && errorMessage === JWT_EXPIRED_ERROR && !originalRequest?._retry) {
                originalRequest._retry = true;

                if (PREMIUM_USER_URLS_KEYS.some((urlKey: string) => originalRequest.url.match(urlKey))) {
                    const premiumAccessTokenResult = await PremiumSubscription.getPremiumUserToken();
                    const premiumAccessToken = premiumAccessTokenResult.data.accessToken;
                    store.dispatch({ type: SET_PREMIUM_ACCESS_TOKEN, payload: { premiumAccessToken } });
                    originalRequest.headers['Authorization'] = 'Bearer ' + premiumAccessToken;
                } else {
                    const accessTokenResult = await AuthApi.refresh({
                        refreshToken: String(store.getState().auth.refreshToken),
                    });
                    const accessToken = accessTokenResult.data.accessToken;
                    store.dispatch({ type: SET_ACCESS_TOKEN, payload: { token: accessToken } });
                    originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
                }

                return axiosInstance(originalRequest);
            }
        } catch (err) {
            console.log(err);
        }

        return {
            hasError: true,
            error: error?.response?.data,
            data: null,
        };
    },
);

export { axiosInstance };

import React, { FC, ReactElement } from 'react';

import { useMediaQuery } from '@material-ui/core';

import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';

import styles from './styles.module.scss';

import { CustomSvgGradient, Loader, RechartsCustomizedAxisTick, TableDataNotFound } from 'components';
import { CustomTooltip } from './components';

import { useAppSelector } from 'store';

import { AreaChartGradient } from './constants';

export const FundsGraph: FC = (): ReactElement => {
    const { aumGraphData, aumGraphDataLoading } = useAppSelector(({ organization }) => organization);
    const isTablet = useMediaQuery('(max-width: 767.98px)');

    return (
        <div className={styles.fundsGraphCard}>
            <div className={styles.fundsGraphCardWrapper}>
                <div className={styles.titleWrapper}>
                    <h4 className={styles.tableTitle}>Treasury over time (month by month)</h4>
                </div>
                <div className={styles.content}>
                    <ResponsiveContainer height={isTablet ? 270 : 310}>
                        <AreaChart data={aumGraphData}>
                            {CustomSvgGradient({
                                id: 'AreaChartGradient',
                                rotate: 90,
                                data: AreaChartGradient,
                            })}

                            <XAxis
                                dataKey="date"
                                tick={<RechartsCustomizedAxisTick offsetY={10} className={styles.axisTick} />}
                                tickLine={false}
                                axisLine={false}
                            />

                            <YAxis
                                tick={
                                    <RechartsCustomizedAxisTick
                                        offsetX={-13}
                                        className={styles.axisTick}
                                        formatValue={true}
                                    />
                                }
                                tickLine={false}
                                dataKey="aum"
                                tickCount={7}
                                width={40}
                                axisLine={false}
                            />
                            <Tooltip cursor={{ fill: '#DDEEFF' }} content={<CustomTooltip />} />
                            <Area dataKey="aum" stroke="#337BFF" strokeWidth={2} fill="url(#AreaChartGradient)" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
                {!aumGraphData.length && !aumGraphDataLoading && <TableDataNotFound forGraph removeMargins />}
                {aumGraphDataLoading && <Loader className={styles.loader} />}
            </div>
        </div>
    );
};

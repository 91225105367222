import { ISelectOptions } from 'types/interfaces';

export type ISearchSelectOptions = ISelectOptions & {
    mobileName?: string;
};

export enum SEARCH_SELECT_OPTIONS {
    ORGANIZATIONS = 'ORGANIZATIONS',
    PEOPLE = 'PEOPLE',
}

export const searchSelectsList: ISearchSelectOptions[] = [
    {
        id: 1,
        name: 'Organizations',
        mobileName: 'Orgs.',
        value: SEARCH_SELECT_OPTIONS.ORGANIZATIONS,
    },
    {
        id: 2,
        name: 'People',
        mobileName: 'People',
        value: SEARCH_SELECT_OPTIONS.PEOPLE,
    },
];

import React, { FC, ReactElement } from 'react';

import { useMediaQuery } from '@material-ui/core';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { SubstormsWrapper } from './components';
import { DesktopFilterSelectedElement } from '../DesktopFilterSelectedElement';

import { substormsIcon } from 'assets';

import { IOrganizationSubstorm, ISubstormsFilterProps } from 'types/interfaces';

export const SubstormsFilter: FC<ISubstormsFilterProps> = ({
    substormsListOpen,
    handleUpdateSubstormsFilter,
    filter,
    substormsList,
    setSubstormsListOpen,
}: ISubstormsFilterProps): ReactElement => {
    const isMobile = useMediaQuery('(max-width:575.98px)');

    return (
        <div className={styles.substormsFilter}>
            <div className={styles.substormsFilterHeader} onClick={setSubstormsListOpen}>
                <img src={substormsIcon} className={styles.substormsIcon} alt="" />
                <span
                    className={classNames(styles.title, {
                        [styles.substormsWrapperOpen]: substormsListOpen,
                    })}
                >
                    Substorms
                </span>
            </div>

            {substormsListOpen && (
                <SubstormsWrapper
                    substormsList={substormsList}
                    substormsListOpen={substormsListOpen}
                    filter={filter}
                    handleUpdateSubstormsFilter={handleUpdateSubstormsFilter}
                />
            )}

            {!substormsListOpen && !isMobile && (
                <div className={styles.selectedSubstormsWrapper}>
                    {filter.substorms.map((substorm: IOrganizationSubstorm) => (
                        <DesktopFilterSelectedElement
                            key={substorm.id}
                            text={substorm.title}
                            onRemove={() => handleUpdateSubstormsFilter(substorm)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

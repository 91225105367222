import React, { FC, ReactElement, memo, useRef } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import styles from './styles.module.scss';

import { useOutsideClick } from 'hooks';

import { ISearchSelectOptions } from '../../constants';

interface ISearchSelectProps {
    selectedSearchType: ISearchSelectOptions;
    setSelectedSearchType: (items: ISearchSelectOptions) => void;
    searchSelectsList: ISearchSelectOptions[];
    selectOpen: boolean;
    setSelectOpen: (inputOpen: boolean) => void;
    isMobile: boolean;
}

export const SearchSelect: FC<ISearchSelectProps> = memo(
    ({
        selectedSearchType,
        setSelectedSearchType,
        searchSelectsList,
        selectOpen,
        setSelectOpen,
        isMobile,
    }: ISearchSelectProps): ReactElement => {
        const ref = useRef(null);
        useOutsideClick(ref, () => setSelectOpen(false));

        return (
            <div className={styles.selectWrapper} ref={ref}>
                <div className={styles.selectContainer} onClick={() => setSelectOpen(!selectOpen)}>
                    <span className={styles.selectedOptionName}>
                        {isMobile ? selectedSearchType.mobileName : selectedSearchType.name}
                    </span>
                    <KeyboardArrowDownIcon className={styles.arrowIcon} />
                </div>

                {selectOpen && (
                    <div className={styles.selectItemsWrapper}>
                        {searchSelectsList
                            .filter(({ id }: ISearchSelectOptions) => id !== selectedSearchType.id)
                            .map((items: ISearchSelectOptions) => (
                                <div
                                    key={items.id}
                                    className={styles.selectElement}
                                    onClick={() => setSelectedSearchType(items)}
                                >
                                    <span className={styles.name}>{isMobile ? items.mobileName : items.name}</span>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        );
    },
);

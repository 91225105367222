import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    tooltip: {
        backgroundColor: '#fff',
        color: '#545c61',
        border: '1px solid #588BE8',
    },
    arrow: {
        fontSize: 10,
        width: 10,
        color: '#fff',

        '&::before': {
            backgroundColor: '#fff',
            border: '1px solid #588BE8',
            boxSizing: 'border-box',
        },
    },
}));

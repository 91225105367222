import type {
    IFeedActivityData,
    IFeedState,
    IFeedActionTypes,
    IDAOFeedPageData,
    IFeedOrganizationSearchedData,
    IFeedStatsData,
    IFeedItemsData,
} from 'types/interfaces';

import { Reducer } from '@reduxjs/toolkit';

import { FeedActions } from 'store/actions';

const initialState = {
    loading: false,
    feedActivityData: {
        organizations: [],
        members: [],
    },
    feedOrganizations: [],
    feedItems: [],
    hasMoreDataToLoad: true,
    votesGraphData: [],
    decisionsGraphData: [],
    discussionsGraphData: [],
    feedCounters: {
        discoursesCount: 0,
        proposalsCount: 0,
        votesCount: 0,
    },
    feedLastUpdateDate: null,
    organizationsCount: null,
    activityDataLoading: false,
    statsDataLoading: false,
    enrichedOrganizationsCount: null,
};

const defaultState: IFeedState = {
    ...initialState,
};

export const feedReducer: Reducer<IFeedState, IFeedActionTypes> = (
    state: IFeedState = defaultState,
    action: IFeedActionTypes,
): IFeedState => {
    const { type, payload } = <IFeedActionTypes>action;

    switch (type) {
        case FeedActions.GET_FEED_DATA: {
            return {
                ...state,
                loading: true,
            };
        }

        case FeedActions.GET_FEED_DATA_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...(payload as IDAOFeedPageData),
            };
        }

        case FeedActions.GET_FEED_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case FeedActions.GET_FEED_ORGANIZATIONS_SEARCH: {
            return {
                ...state,
                loading: true,
                feedOrganizations: [],
            };
        }

        case FeedActions.GET_FEED_ORGANIZATIONS_SEARCH_SUCCESS: {
            return {
                ...state,
                loading: false,
                feedOrganizations: payload as IFeedOrganizationSearchedData[],
            };
        }

        case FeedActions.GET_FEED_ORGANIZATIONS_SEARCH_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case FeedActions.GET_FEED_ITEMS: {
            return {
                ...state,
                loading: true,
            };
        }

        case FeedActions.GET_FEED_ITEMS_SUCCESS: {
            return {
                ...state,
                loading: false,
                ...(payload as IFeedItemsData[]),
            };
        }

        case FeedActions.GET_FEED_ITEMS_FAILURE: {
            return {
                ...state,
                loading: false,
                hasMoreDataToLoad: false,
            };
        }

        case FeedActions.GET_FEED_STATS: {
            return {
                ...state,
                statsDataLoading: true,
            };
        }

        case FeedActions.GET_FEED_STATS_SUCCESS: {
            return {
                ...state,
                ...(payload as IFeedStatsData),
                statsDataLoading: false,
            };
        }

        case FeedActions.GET_FEED_STATS_FAILURE: {
            return {
                ...state,
                statsDataLoading: false,
            };
        }

        case FeedActions.GET_FEED_ACTIVITY: {
            return {
                ...state,
                activityDataLoading: true,
            };
        }

        case FeedActions.GET_FEED_ACTIVITY_SUCCESS: {
            return {
                ...state,
                ...(payload as IFeedActivityData),
                activityDataLoading: false,
            };
        }

        case FeedActions.GET_FEED_ACTIVITY_FAILURE: {
            return {
                ...state,
                activityDataLoading: false,
            };
        }

        case FeedActions.CLEAR_FEED_ITEMS_DATA: {
            return {
                ...state,
                feedItems: [],
            };
        }

        case FeedActions.CLEAR_ALL_FEED_DATA: {
            return {
                ...defaultState,
            };
        }

        default: {
            return state;
        }
    }
};

import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';

import styles from './styles.module.scss';

import { Loader, StatsCard } from 'components';

import { useAppDispatch, useAppSelector } from 'store';
import { DaoTokensActions } from 'store/actions';
import { mixpanelService } from 'services';
import { getTitleAndDescriptionMetaTags, tableDataSort } from 'helpers';
import { HelmetContext } from 'context/helmetContext';
import { DaoTokensTable } from '../../components/DaoTokensTable';
import { ISortedParam } from '../../types/interfaces';
import { ORGANIZATIONS_TOKENS_PARAMS } from '../../components/DaoTokensTable/constants';
import { createDaoTokensTableData, tokensTableSearch } from '../../components/DaoTokensTable/utils';

export const DaoTokensPage: FC = (): ReactElement => {
    const { daoTokens, loading, error, paginationOrderDescending } = useAppSelector(({ daoTokens }) => daoTokens);

    const { lastUpdate, enrichedOrganizationsCount, organizationsCount } = useAppSelector(
        ({ organizations }) => organizations,
    );

    const { refreshToken } = useAppSelector(({ auth }) => auth);

    const dispatch = useAppDispatch();

    const [isBottom, setIsBottom] = useState(false);

    const [daoTokensSortedParam, setDaoTokensSortedParam] = useState<ISortedParam>({
        paramName: ORGANIZATIONS_TOKENS_PARAMS.BALANCE_USD_PARAM,
        descending: paginationOrderDescending,
    });
    const [tabSearchValue, setTabSearchValue] = useState<string>('');
    const { setMetaTags } = useContext(HelmetContext);

    const sortDaoTokensDataByParam = (param: string) => {
        setDaoTokensSortedParam({
            paramName: param,
            descending: paginationOrderDescending,
        });
    };

    const handleUserScroll = () => {
        const heightLimit = 100;

        if (
            heightLimit + window.innerHeight + document.documentElement.scrollTop >
            document.documentElement.scrollHeight
        ) {
            setIsBottom(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleUserScroll);
        return () => window.removeEventListener('scroll', handleUserScroll);
    }, []);

    useEffect(() => {
        dispatch(DaoTokensActions.getDaoTokens.request());
    }, [refreshToken]);

    useEffect(
        () => () => {
            dispatch({ type: DaoTokensActions.CLEAR_DAO_TOKENS });
        },
        [dispatch],
    );

    useEffect(() => {
        mixpanelService.track(mixpanelService.eventsGenerator.daoTokensPage());

        setMetaTags(
            getTitleAndDescriptionMetaTags(
                'DeepDAO – Discovery Engine for DAO Ecosystem',
                `DeepDAO offers extensive analytics and information gathering. The main dashboard product lists and ranks blockchain DAOs using key metrics like membership and assets under management.`,
            ),
        );

        return () => {
            setMetaTags(getTitleAndDescriptionMetaTags());
        };
    }, []);

    return (
        <>
            {loading && <Loader />}

            <>
                <StatsCard
                    lastUpdateDate={lastUpdate}
                    organizationsCount={organizationsCount}
                    enrichedOrganizationsCount={enrichedOrganizationsCount}
                    title="DAO Tokens"
                    isPeoplePage
                    className={styles.statsCardWrapper}
                    externalLink
                    mobileBanner
                />

                <div className={styles.pageContentWrapper}>
                    <DaoTokensTable
                        data={tokensTableSearch(
                            createDaoTokensTableData(tableDataSort([...daoTokens], daoTokensSortedParam)),
                            tabSearchValue,
                        )}
                        error={error}
                        sortedParam={daoTokensSortedParam}
                        sortByParam={sortDaoTokensDataByParam}
                        loading={loading}
                    />
                </div>
            </>
        </>
    );
};

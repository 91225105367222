import { call, takeLatest, put, all } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { FeedProposalActions } from 'store/actions';
import { FeedProposalApi } from 'store/apis';

import { IGetFeedProposal } from 'types/interfaces';

function* getFeedDataWorker({ payload }: IGetFeedProposal) {
    try {
        const { data } = yield call(FeedProposalApi.getFeedProposalData, payload.id);

        yield put(FeedProposalActions.getFeedProposalData.success({ feedProposalData: data?.data?.[0] || {} }));
    } catch (e) {
        yield put(FeedProposalActions.getFeedProposalData.failure(e));
    }
}

export const feedProposaSaga = function* (): SagaIterator {
    yield all([takeLatest(FeedProposalActions.GET_FEED_PROPOSAL_DATA, getFeedDataWorker)]);
};

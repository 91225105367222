import React, { FC, ReactElement, useCallback, useEffect, useState, useMemo } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { VOTING_OPTIONS } from 'constants/votingConstants';

import { IVotingButtonProps } from 'types/interfaces';

export const VotingButton: FC<IVotingButtonProps> = ({
    onClick,
    defaultImage,
    hoverImage,
    title,
    votingOption,
    voted,
    forComment = false,
}: IVotingButtonProps): ReactElement => {
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const isVoted = useMemo(() => Boolean(voted && voted === votingOption), [voted, votingOption]);

    const isVotedFor = useMemo(
        () => Boolean(votingOption === VOTING_OPTIONS.FOR && voted === VOTING_OPTIONS.FOR),
        [votingOption, voted],
    );

    const isVotedAgainst = useMemo(
        () => Boolean(votingOption === VOTING_OPTIONS.AGAINST && voted === VOTING_OPTIONS.AGAINST),
        [votingOption, voted],
    );

    const onButtonClick = useCallback(() => {
        onClick();
        setButtonDisabled(true);
    }, [onClick, buttonDisabled]);

    useEffect(() => {
        if (buttonDisabled !== isVoted) setButtonDisabled(!buttonDisabled);
    }, [isVoted]);

    return (
        <button
            onClick={onButtonClick}
            className={classNames(styles.votingBtn, {
                [styles.commentVoteBtn]: forComment,
                [styles.buttonDisabled]: isVoted,
                [styles.votedFor]: isVotedFor,
                [styles.votedAgainst]: isVotedAgainst,
                [styles.votedForBorder]: forComment && isVotedFor,
                [styles.votedAgainstBorder]: forComment && isVotedAgainst,
            })}
            disabled={buttonDisabled}
        >
            <img
                src={isVoted ? hoverImage : defaultImage}
                className={classNames(styles.votingDolphinImage, {
                    [styles.votingDolphinForImageHovered]: isVotedFor,
                    [styles.votingDolphinAgainstImageHovered]: isVotedAgainst,
                })}
            />

            {title && <span className={styles.title}>{title}</span>}
        </button>
    );
};

import React, { FC, ReactElement } from 'react';

import { LabelProps } from 'recharts';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { FormatUtils } from 'utils';

type ILabelProps = LabelProps & { total?: number; barLabelStyles?: string; showPercentage?: boolean };

export const CustomLabel: FC<ILabelProps> = (props: ILabelProps): ReactElement => {
    const { x, y, width, value, total, barLabelStyles, showPercentage = false } = props;

    const percentage = showPercentage ? ((Number(value) / Number(total)) * 100).toFixed(1) : null;

    return (
        <g>
            <text
                x={Number(x) + Number(width) / 2}
                y={Number(y) - 10}
                textAnchor="middle"
                dominantBaseline="middle"
                className={styles.wrapper}
            >
                <tspan className={classNames(styles.barLabel, barLabelStyles)}>{`${FormatUtils.formatNumberShort(
                    Number(value),
                    1,
                )} `}</tspan>
                {percentage && <tspan className={styles.barLabelPercentage} dy={1.5}>{`${percentage}%`}</tspan>}
            </text>
        </g>
    );
};

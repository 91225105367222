import React, { ReactElement, FC, useState, useEffect } from 'react';

import { Button, Link, useMediaQuery } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBackIos';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { DAOToolsSection } from '../DAOToolsSection';
import { ImageLoader } from 'components/ImageLoader';

import { IMAGE_TYPES } from 'constants/imageTypes';
import { brainstormInstructions } from './constants';

import { useAppSelector } from 'store';

export const ProjectTokenSection: FC = (): ReactElement => {
    const { projectToken } = useAppSelector((store) => store.organization);
    const [tokenDescriptionVisible, setTokenDescriptionVisible] = useState<boolean>(true);

    const { tokenName, tokenIcon, chainName, explorerUrl } = projectToken || {};

    const isMobile = useMediaQuery('(max-width:575.98px)');

    useEffect(() => {
        isMobile && setTokenDescriptionVisible(false);
    }, [isMobile]);

    return (
        <aside className={styles.left}>
            <div
                className={classNames(styles.projectTokenCard, {
                    [styles.projectTokenCardMobile]: tokenDescriptionVisible,
                })}
            >
                {isMobile && (
                    <div className={styles.header}>
                        <span className={styles.headerTitle}>DAO token</span>
                        <Button
                            className={styles.expandMoreButton}
                            onClick={() => setTokenDescriptionVisible(!tokenDescriptionVisible)}
                        >
                            <ArrowBack
                                className={classNames(styles.arrowIcon, {
                                    [styles.arrowIconDown]: tokenDescriptionVisible,
                                })}
                            />
                        </Button>
                    </div>
                )}

                <div className={styles.body}>
                    <Link className={styles.link} target="_blank" rel="noreferrer" href={explorerUrl}>
                        <ImageLoader
                            src={tokenIcon}
                            className={styles.tokenLogoBig}
                            imageType={IMAGE_TYPES.TOKEN_IMAGE}
                        />
                        <div className={styles.section}>
                            <h5 className={styles.title}>
                                {isMobile ? '' : 'DAO token: '}
                                {tokenName}
                            </h5>
                            <div className={styles.chainSection}>
                                <ImageLoader
                                    src={tokenIcon}
                                    className={styles.tokenLogoSmall}
                                    imageType={IMAGE_TYPES.TOKEN_IMAGE}
                                />
                                <span className={styles.chainName}>{chainName}</span>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

            {tokenDescriptionVisible && (
                <div className={styles.tokenDescriptionCard}>
                    <div className={styles.container}>
                        {brainstormInstructions.map(({ id, title, text }: any) => (
                            <div className={styles.descriptionSection} key={id}>
                                <h5 className={styles.title}>{title}</h5>
                                <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <DAOToolsSection />
        </aside>
    );
};

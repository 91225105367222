import { IOrganizationMemberDataWithId } from 'types/interfaces';

export const membersTableSearch = (
    membersData: IOrganizationMemberDataWithId[],
    value: string,
): IOrganizationMemberDataWithId[] =>
    value
        ? membersData.filter(({ name }: IOrganizationMemberDataWithId) =>
              (name as string)?.toLowerCase()?.includes(value.toLowerCase()),
          )
        : membersData;

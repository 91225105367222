import { useContext, useEffect, useState } from 'react';
import * as ethers from 'ethers';
import { decode } from 'jsonwebtoken';
import { useAppDispatch, useAppSelector } from 'store';
import { AuthActions } from 'store/actions';
import {
    removeCachedWalletProviderFromStorage,
    getCachedWalletProviderFromStorage,
    getDeepDAORefreshTokenFromStorage,
} from 'web3/storageHelper';
// import { providerOptions } from 'web3/walletProviders';
import { WALLET_CONNECT, FORTMATIC } from 'web3/constants';
import { Web3ModalContext } from './Web3ModalContext';

interface IDefaultLocalState {
    provider: ethers.providers.Web3Provider | undefined;
    active: boolean;
    account: string;
}

const defaultLocalState: IDefaultLocalState = {
    provider: undefined,
    active: false,
    account: '',
};

export const useWalletAuth = (): {
    account?: string | null;
    token?: string | null;
    toggleConnection: () => void;
    active: boolean;
    loading: boolean;
} => {
    const dispatch = useAppDispatch();
    const { web3Modal } = useContext(Web3ModalContext);
    const { challenge, token, loading, address: cachedConnectedAddress } = useAppSelector(({ auth }) => auth);
    const [localState, setLocalState] = useState(defaultLocalState);

    const requestChallenge = () => {
        // after dispatch we wait for the 'challange' state to be ready in useEffet
        dispatch(AuthActions.challenge.request({ account: localState.account }));
    };

    const toggleConnection = async () => {
        try {
            if (localState.active && token) {
                signOut();
            } else if (challenge && !token) {
                signIn(challenge);
            } else if (localState.active && !token) {
                requestChallenge();
            } else {
                connectWallet();
            }
        } catch (error: unknown) {
            console.log('web3 connection error', error);
            signOut();
        }
    };

    async function connectWallet() {
        try {
            const modalProvider = await web3Modal?.connect();
            if (modalProvider) {
                const ethersProvider = new ethers.providers.Web3Provider(modalProvider);
                const signer = ethersProvider.getSigner();
                const address = await signer.getAddress();
                setLocalState({ ...localState, provider: ethersProvider, active: true, account: address });
            }
        } catch (error) {
            console.log('connectWallet error', error);
            signOut();
        }
    }

    async function disconnectWallet(clearCache = false) {
        if (clearCache) {
            web3Modal?.setCachedProvider('');
            // web3Modal?.clearCachedProvider();
            removeCachedWalletProviderFromStorage();
        }
        setLocalState(defaultLocalState);
    }

    const signIn = async (challenge: string) => {
        try {
            // we ask the user to sign and send a login request with the signature and challenge
            // if everything goes well, we should get back a jwt token and save it in localstorage
            // this happens in auth reducer
            const signature = await signPayload(challenge);
            if (signature) {
                dispatch(AuthActions.login.request({ challenge, signature }));
            }
        } catch (error: unknown) {
            console.log('web3 authentication error', error);
            signOut();
        }
    };

    const signOut = async () => {
        dispatch(AuthActions.logout.request({ refreshToken: getDeepDAORefreshTokenFromStorage() }));
        disconnectWallet(true);
    };

    const getDecodedChallenge = (challenge: string): string => {
        const decodedChallenge = decode(challenge);
        if (!decodedChallenge) {
            throw new Error('no decoded challenge');
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return `Verify your wallet ${decodedChallenge.account.toLowerCase()} (${decodedChallenge.randomChallenge})`;
    };

    const signPayload = async (challenge: string) => {
        const decoded = getDecodedChallenge(challenge);
        const signature = await localState.provider?.send('personal_sign', [decoded, localState.account]);
        return signature;
    };

    useEffect(() => {
        const handleAccountChanged = () => {
            signOut();
        };

        window?.ethereum?.on('accountsChanged', handleAccountChanged);

        return () => {
            window?.ethereum?.removeListener('accountsChanged', handleAccountChanged);
        };
    }, [localState.provider]);

    // active state changes to true when the wallet has connected with the website
    // then we check for the existense of the access token, if its misssing we start
    // a request challenge process
    useEffect(() => {
        if (localState.active && !token && !challenge) {
            requestChallenge();
        }
    }, [localState.active, token]);

    // when challenge state is available, we try to sign in
    useEffect(() => {
        if (challenge && localState.active && !token) {
            signIn(challenge);
        }
    }, [challenge]);

    useEffect(() => {
        if (web3Modal?.cachedProvider && token) {
            connectWallet();
        }
    }, [web3Modal]);

    useEffect(() => {
        async function checkWalletStatus() {
            try {
                const cachedProvider = await getCachedWalletProviderFromStorage();
                const walletAddresses = await window.ethereum?.request({ method: 'eth_accounts' });
                const connectedAddress =
                    Array.isArray(walletAddresses) &&
                    cachedConnectedAddress &&
                    walletAddresses.find((wAddress) => wAddress.toLowerCase() == cachedConnectedAddress?.toLowerCase());
                if (
                    !connectedAddress &&
                    cachedProvider &&
                    token &&
                    ![WALLET_CONNECT, FORTMATIC].includes(cachedProvider)
                ) {
                    signOut();
                } else if (challenge && !token) {
                    disconnectWallet();
                }
            } catch (error) {
                console.log('checkWalletStatus error', error);
            }
        }

        checkWalletStatus();
    }, []);

    return {
        account: localState.account,
        active: localState.active,
        toggleConnection,
        loading,
        token,
    };
};

import React, { FC, useState, ReactElement, useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'store';
import { SocialFeaturesActions } from 'store/actions';

import { ISortedParam } from 'types/interfaces';
import { tableDataSort, tableSearch } from 'helpers';

import { PeopleTable } from '../PeopleTable';
import { PEOPLE_PARAMS } from '../PeopleTable/constants';
import { SearchField } from './components';

export const FollowersData: FC = (): ReactElement => {
    const dispatch = useAppDispatch();
    const [searchValue, setSearchValue] = useState<string>('');
    const { followedPeopleData, loading } = useAppSelector(({ socialFeatures }) => socialFeatures);

    const [peopleSortedParam, setPeopleSortedParam] = useState<ISortedParam>({
        paramName: PEOPLE_PARAMS.DAO_SCORE,
        descending: true,
    });

    const sortPeopleDataByParam = (param: string) => {
        setPeopleSortedParam({
            paramName: param,
            descending: peopleSortedParam.paramName === param ? !peopleSortedParam.descending : true,
        });
    };

    useEffect(() => {
        dispatch(SocialFeaturesActions.getFollowedPeopleData.request());
    }, []);

    return (
        <>
            <SearchField inputValue={searchValue} setInputValue={setSearchValue} />
            <PeopleTable
                loading={loading}
                sortByParam={sortPeopleDataByParam}
                sortedParamName={peopleSortedParam.paramName}
                data={tableSearch(tableDataSort(followedPeopleData, peopleSortedParam), searchValue)}
                isFollowersTable
            />
        </>
    );
};

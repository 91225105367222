import React from 'react';

export const getTitleAndDescriptionMetaTags = (title?: string, description?: string): JSX.Element[] => {
    const newTitle = title || 'DeepDAO – Discovery Engine for DAO Ecosystem';
    const newDescription =
        description ||
        'DeepDAO offers extensive analytics and information gathering. The main dashboard product lists and ranks blockchain DAOs using key metrics like membership and assets under management.';

    const metaTitle = <title key="title">{newTitle}</title>;
    const metaDescription = <meta key="name-description" name="description" content={newDescription} />;
    const metaOGTitle = <meta key="property-og:title" property="og:title" content={newTitle} />;
    const metaOGDescription = <meta key="property-og:description" property="og:description" content={newDescription} />;
    const metaTwitterTitle = <meta key="name-twitter:title" property="twitter:title" content={newTitle} />;
    const metaTwitterDescription = (
        <meta key="name-twitter:description" name="twitter:description" content={newDescription} />
    );

    return [
        metaTitle,
        metaDescription,
        metaOGTitle,
        metaOGDescription,
        metaTwitterTitle,
        metaTwitterDescription,
    ].filter(Boolean) as JSX.Element[];
};

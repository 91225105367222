import { call, takeLatest, put, takeEvery } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { OrganizationTabsActions } from 'store/actions';
import { OrganizationTabsApi } from 'store/apis';

import { IGetOrganizationAction } from 'types/interfaces';

// ---------- Workers ----------
// general
function* getOrganizationTabsWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(OrganizationTabsApi.getOrganizationTabs, payload.organizationId);

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationTabs.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationTabs.success({
                    organizationTabs: data,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationTabs.failure(e));
    }
}

// delegation insights
function* getOrganizationActiveDelegatesWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationActiveDelegates,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationActiveDelegates.failure({ error }));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationActiveDelegates.success({
                    organizationActiveDelegates: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationActiveDelegates.failure(e));
    }
}

function* getOrganizationTopDelegatesWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationTopDelegates,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationTopDelegates.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationTopDelegates.success({
                    organizationTopDelegates: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationTopDelegates.failure(e));
    }
}

// voter decentralization
function* getOrganizationActiveVotersWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationActiveVoters,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationActiveVoters.failure({ error }));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationActiveVoters.success({
                    organizationActiveVoters: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationActiveVoters.failure(e));
    }
}

function* getOrganizationTopVotersWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationTopVoters,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationTopVoters.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationTopVoters.success({
                    organizationTopVoters: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationTopVoters.failure(e));
    }
}

// voters insights
function* getOrganizationProposalsTrendsWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationProposalsTrends,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationProposalsTrends.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationProposalsTrends.success({
                    organizationProposalsTrends: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationProposalsTrends.failure(e));
    }
}

function* getOrganizationSuccessfulProposalsTrendsWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationSuccessfulProposalsTrends,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationSuccessfulProposalsTrends.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationSuccessfulProposalsTrends.success({
                    organizationSuccessfulProposalsTrends: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationSuccessfulProposalsTrends.failure(e));
    }
}

function* getOrganizationVotesTrendsWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationVotesTrends,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationVotesTrends.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationVotesTrends.success({
                    organizationVotesTrends: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationVotesTrends.failure(e));
    }
}

function* getOrganizationVotersTrendsWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationVotersTrends,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationVotersTrends.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationVotersTrends.success({
                    organizationVotersTrends: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationVotersTrends.failure(e));
    }
}

function* getOrganizationAverageVotesPerProposalTrendsWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationAverageVotesPerProposalTrends,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationAverageVotesPerProposalTrends.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationAverageVotesPerProposalTrends.success({
                    organizationAverageVotesPerProposalTrends: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationAverageVotesPerProposalTrends.failure(e));
    }
}

function* getOrganizationYearByYearVotesWorker({ payload }: any): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationYearByYearVotes,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationYearByYearVotes.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationYearByYearVotes.success({
                    organizationYearByYearVotes: data,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationYearByYearVotes.failure(e));
    }
}

// voter interests tab
function* getOrganizationVoterInterestsWorker({ payload }: any): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationVoterInterests,
            payload.organizationId,
            payload.tableType,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationVoterInterests.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationVoterInterests.success({
                    organizationVoterInterests: data,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationVoterInterests.failure(e));
    }
}

function* getOrganizationVoterGroupsWorker({ payload }: any): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationVoterGroups,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationVoterGroups.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationVoterGroups.success({
                    organizationVoterGroups: data.data,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationVoterGroups.failure(e));
    }
}

// finance tab
function* getOrganizationTreasuryTrendsWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationTreasuryTrends,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationTreasuryTrends.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationTreasuryTrends.success({
                    organizationTreasuryTrends: data.data,
                    lastUpdate: data.lastUpdate,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationTreasuryTrends.failure(e));
    }
}

function* getOrganizationTreasuryCompositionWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationTreasuryComposition,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationTreasuryComposition.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationTreasuryComposition.success({
                    organizationTreasuryComposition: data,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationTreasuryComposition.failure(e));
    }
}

function* getOrganizationTreasuryCompositionGraphWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationTreasuryCompositionGraph,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationTreasuryCompositionGraph.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationTreasuryCompositionGraph.success({
                    organizationTreasuryCompositionGraph: data,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationTreasuryCompositionGraph.failure(e));
    }
}

// token tab
function* getOrganizationVotingTokenWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationVotingToken,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationVotingToken.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationVotingToken.success({
                    organizationVotingToken: data,
                    lastUpdate: data.updatedAt,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationVotingToken.failure(e));
    }
}

function* getOrganizationAvgVotesAndTokenPriceGraphWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationAvgVotesAndTokenPriceGraph,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationAvgVotesAndTokenPriceGraph.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationAvgVotesAndTokenPriceGraph.success({
                    organizationAvgVotesAndTokenPriceGraph: data.data,
                    lastUpdate: data.updatedAt,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationAvgVotesAndTokenPriceGraph.failure(e));
    }
}

function* getOrganizationHoldingTokenWorker({ payload }: IGetOrganizationAction): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            OrganizationTabsApi.getOrganizationHoldingToken,
            payload.organizationId,
        );

        if (hasError) {
            yield put(OrganizationTabsActions.getOrganizationHoldingToken.failure(error));
        } else
            yield put(
                OrganizationTabsActions.getOrganizationHoldingToken.success({
                    organizationHoldingToken: data,
                }),
            );
    } catch (e) {
        yield put(OrganizationTabsActions.getOrganizationHoldingToken.failure(e));
    }
}

// ---------- SAGA ----------
export const organizationTabsSaga = function* (): SagaIterator {
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_TABS, getOrganizationTabsWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_ACTIVE_DELEGATES, getOrganizationActiveDelegatesWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_TOP_DELEGATES, getOrganizationTopDelegatesWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_ACTIVE_VOTERS, getOrganizationActiveVotersWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_TOP_VOTERS, getOrganizationTopVotersWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_PROPOSALS_TRENDS, getOrganizationProposalsTrendsWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_SUCCESSFUL_PROPOSALS_TRENDS, getOrganizationSuccessfulProposalsTrendsWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_VOTES_TRENDS, getOrganizationVotesTrendsWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_VOTERS_TRENDS, getOrganizationVotersTrendsWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_AVERAGE_VOTES_PER_PROPOSAL_TRENDS, getOrganizationAverageVotesPerProposalTrendsWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_YEAR_BY_YEAR_VOTES, getOrganizationYearByYearVotesWorker);
    yield takeEvery(OrganizationTabsActions.GET_ORGANIZATION_VOTER_INTERESTS, getOrganizationVoterInterestsWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_TREASURY_TRENDS, getOrganizationTreasuryTrendsWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_VOTING_TOKEN, getOrganizationVotingTokenWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_AVG_VOTES_AND_TOKEN_PRICE, getOrganizationAvgVotesAndTokenPriceGraphWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_TREASURY_COMPOSITION, getOrganizationTreasuryCompositionWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_HOLDING_TOKEN, getOrganizationHoldingTokenWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_TREASURY_COMPOSITION_GRAPH, getOrganizationTreasuryCompositionGraphWorker);
    yield takeLatest(OrganizationTabsActions.GET_ORGANIZATION_VOTER_GROUPS, getOrganizationVoterGroupsWorker);
};

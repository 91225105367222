import React, { FC, ReactElement } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { useMediaQuery } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ListIcon from '@material-ui/icons/List';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import styles from './styles.module.scss';

import { StatusInfo, FeedCardIcon, TooltipWrapper, HrefLink, ImageLoader, CardDescriptionSection } from 'components';

import { MAX_DESKTOP_TITLE_LENGTH, MAX_MOBILE_TITLE_LENGTH } from './constants';
import { FEED_TYPES } from 'constants/feedTypes';
import { DATA_NOT_FOUND } from 'constants/dataNotFound';
import { IMAGE_TYPES } from 'constants/imageTypes';

import { FormatUtils, LinkUtils } from 'utils';

import { IFeedCardItemsProps } from 'types/interfaces';

export const FeedCardItems: FC<IFeedCardItemsProps> = ({
    id,
    userImage,
    organizationImage,
    username,
    organizationName,
    status,
    createdAt,
    title,
    description,
    type,
    organizationLink,
    userLink,
    proposalType,
    mainSiteUrl,
    children,
}: IFeedCardItemsProps): ReactElement => {
    const history = useHistory();
    const isMobile = useMediaQuery('(max-width:575.98px)');

    const maxTitleLength = isMobile ? MAX_MOBILE_TITLE_LENGTH : MAX_DESKTOP_TITLE_LENGTH;

    const getTitle = (title: string | null) =>
        title ? FormatUtils.truncateString(title, maxTitleLength) : 'No title available for this proposal';

    const navigateToFeedProposal = () => {
        history.push(LinkUtils.createDynamicLink(id, 'feed-proposal'));
    };

    return (
        <div className={styles.card}>
            <div className={styles.cardHeader}>
                <div className={styles.head}>
                    {isMobile && (
                        <>
                            {type === FEED_TYPES.PROPOSAL ? (
                                <div className={styles.proposalType}>
                                    <ListIcon />
                                </div>
                            ) : (
                                <div className={styles.discussionType}>
                                    <QuestionAnswerIcon />
                                </div>
                            )}
                            {status && status.toLowerCase() !== DATA_NOT_FOUND && (
                                <StatusInfo status={status} forFeed={true} />
                            )}
                        </>
                    )}
                    <span className={styles.date}>{FormatUtils.formatFeedDate(createdAt)}</span>
                </div>
                <div className={styles.details}>
                    <FeedCardIcon type={type} className={styles.feedCardIcon} />
                    {organizationLink && (
                        <div className={styles.organizationDetails}>
                            <Link to={organizationLink} className={styles.iconLink}>
                                <ImageLoader
                                    src={organizationImage}
                                    className={styles.image}
                                    imageType={IMAGE_TYPES.ORGANIZATION_IMAGE}
                                />
                            </Link>
                            <div className={styles.organizationInfo}>
                                <TooltipWrapper title={organizationName}>
                                    <Link to={organizationLink} className={styles.organizationName}>
                                        {organizationName}
                                    </Link>
                                </TooltipWrapper>
                                <span className={styles.proposalType}>{proposalType}</span>
                            </div>
                            <KeyboardArrowDownIcon className={styles.arrowRight} />
                        </div>
                    )}
                    <div className={styles.userDetails}>
                        {type === FEED_TYPES.PROPOSAL ? (
                            <Link to={userLink} className={styles.iconLink}>
                                <ImageLoader
                                    src={userImage}
                                    className={styles.image}
                                    imageType={IMAGE_TYPES.USER_IMAGE}
                                />
                            </Link>
                        ) : (
                            <HrefLink href={mainSiteUrl} className={styles.iconLink}>
                                <ImageLoader
                                    src={userImage}
                                    className={styles.image}
                                    imageType={IMAGE_TYPES.USER_IMAGE}
                                />
                            </HrefLink>
                        )}
                        <TooltipWrapper title={username}>
                            {type === FEED_TYPES.PROPOSAL ? (
                                <Link to={userLink} className={styles.userName}>
                                    {username} PROPOSAL
                                </Link>
                            ) : (
                                <HrefLink href={mainSiteUrl} className={styles.userName}>
                                    <>{username} DISCUSSION</>
                                </HrefLink>
                            )}
                        </TooltipWrapper>
                    </div>
                    {status && status.toLowerCase() !== DATA_NOT_FOUND && (
                        <StatusInfo status={status} forFeed={true} removeForMobile={true} />
                    )}
                </div>
            </div>

            <div className={styles.cardBody}>
                <div className={styles.textContainer}>
                    {type === FEED_TYPES.DISCUSSION ? (
                        <HrefLink href={mainSiteUrl}>
                            <h4 className={styles.title}>{getTitle(title)}</h4>
                        </HrefLink>
                    ) : (
                        <div onClick={navigateToFeedProposal}>
                            <h4 className={styles.title}>{getTitle(title)}</h4>
                        </div>
                    )}

                    <CardDescriptionSection description={description} />
                </div>
            </div>
            {children}
        </div>
    );
};

import React, { ReactElement, FC } from 'react';

import styles from './styles.module.scss';

import { OrganizationMembersTable } from './components';

export const OrganizationVoters: FC = (): ReactElement => {
    return (
        <div className={styles.mainContainer}>
            <OrganizationMembersTable />
        </div>
    );
};

import { DAO_TREASURY_INVESTMENTS_TYPES } from 'constants/daoTreasuryInvestments';
import { IDaoTreasuryInvestmentsData, IDaoTreasuryInvestmentsStateData } from 'types/interfaces';

const createOrUpdateDAOTreasuryInvestmentReduxFields = <T extends Partial<IDaoTreasuryInvestmentsData<T>>>(
    fieldName: DAO_TREASURY_INVESTMENTS_TYPES,
    data?: T,
): IDaoTreasuryInvestmentsStateData<T> =>
    ({
        [fieldName]: {
            data: data?.data || [],
            lastUpdateDate: data?.lastUpdateDate || null,
            loading: data?.loading || false,
            error: data?.error || null,
        },
    } as IDaoTreasuryInvestmentsStateData<T>);

export { createOrUpdateDAOTreasuryInvestmentReduxFields };

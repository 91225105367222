import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { TooltipWrapper, TableCell, StatusInfo, ImageLoader } from 'components';

import { FormatUtils, LinkUtils } from 'utils';
import { defaultUserPlaceholderImage } from 'assets';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';
import { IMAGE_TYPES } from 'constants/imageTypes';
import { DECISIONS_PARAMS } from '../../constants';

import { IDesisionsRowItemsProps } from 'types/interfaces';

export const DecisionRowItems: FC<IDesisionsRowItemsProps> = ({
    rank,
    id,
    proposer,
    createdAt,
    totalVotingPower,
    sharesFor,
    sharesAbstain,
    sharesAgainst,
    balanceFor,
    balanceAbstain,
    balanceAgainst,
    status,
    title,
    votes,
    sortedParamName,
    avatar,
    quorum,
}: IDesisionsRowItemsProps): ReactElement => {
    const votesCount = !FormatUtils.checkIfDataNotExist(votes)
        ? FormatUtils.formatNumberWithCommas(votes as number, 0)
        : DATA_NOT_FOUND;
    const totalVotesVotingPower = !FormatUtils.checkIfDataNotExist(totalVotingPower)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(totalVotingPower as number), 3)
        : DATA_NOT_FOUND;

    const sharesForCount = !FormatUtils.checkIfDataNotExist(sharesFor)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(sharesFor as number), 0)
        : DATA_NOT_FOUND;
    const sharesAgainstCount = !FormatUtils.checkIfDataNotExist(sharesAgainst)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(sharesAgainst as number), 0)
        : DATA_NOT_FOUND;
    const sharesAbstainCount = !FormatUtils.checkIfDataNotExist(sharesAbstain)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(sharesAbstain as number), 0)
        : DATA_NOT_FOUND;

    const sharesForBalance = !FormatUtils.checkIfDataNotExist(balanceFor)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(balanceFor as number), 3)
        : DATA_NOT_FOUND;
    const sharesAgainstBalance = !FormatUtils.checkIfDataNotExist(balanceAgainst)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(balanceAgainst as number), 3)
        : DATA_NOT_FOUND;
    const sharesAbstainBalance = !FormatUtils.checkIfDataNotExist(balanceAbstain)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(balanceAbstain as number), 3)
        : DATA_NOT_FOUND;

    const castedQuorum = !FormatUtils.checkIfDataNotExist(quorum)
        ? `${FormatUtils.formatNumberShort(quorum as unknown as number, 1, true)}`
        : DATA_NOT_FOUND;

    return (
        <div className={styles.tableRow}>
            <div className={styles.rank}>{rank}</div>
            <div className={styles.titleBlock}>
                <TableCell className={styles.title} isHighlighted={sortedParamName === DECISIONS_PARAMS.TITLE_PARAM}>
                    <p className={styles.titleText}>
                        <Link to={LinkUtils.createDynamicLink(id, 'feed-proposal')} className={styles.proposalLink}>
                            {title || DATA_NOT_FOUND}
                        </Link>
                    </p>
                </TableCell>
            </div>
            <TableCell className={styles.date} isHighlighted={sortedParamName === DECISIONS_PARAMS.DATE_PARAM}>
                <span>{FormatUtils.getFormattedDate(createdAt)}</span>
            </TableCell>
            <div className={styles.proposerInfo}>
                <TableCell
                    className={styles.proposedBy}
                    isHighlighted={sortedParamName === DECISIONS_PARAMS.PROPOSED_BY_PARAM}
                >
                    <ImageLoader
                        src={avatar || defaultUserPlaceholderImage}
                        className={styles.logo}
                        imageType={IMAGE_TYPES.USER_IMAGE}
                    />
                    <TooltipWrapper title={proposer}>
                        <p
                            className={classNames(styles.proposalName, {
                                [styles.proposalSortingActive]: sortedParamName === DECISIONS_PARAMS.PROPOSED_BY_PARAM,
                            })}
                        >
                            <Link to={LinkUtils.createDynamicLink(proposer, 'user')} className={styles.link}>
                                {proposer}
                            </Link>
                        </p>
                    </TooltipWrapper>
                </TableCell>
            </div>
            <div className={styles.outcomeStatus}>
                <StatusInfo status={status} />
            </div>
            <div className={styles.quorum}>
                <span>{castedQuorum}</span>
            </div>
            <TableCell className={styles.votes} isHighlighted={sortedParamName === DECISIONS_PARAMS.VOTES_PARAM}>
                <TooltipWrapper title={`Voting power: ${totalVotesVotingPower}`}>
                    <span>{votesCount}</span>
                </TooltipWrapper>
            </TableCell>
            <div className={styles.forShares}>
                <TooltipWrapper title={`Voting power: ${sharesForBalance}`}>
                    <span>{sharesForCount}</span>
                </TooltipWrapper>
            </div>
            <div className={styles.againstShares}>
                <TooltipWrapper title={`Voting power: ${sharesAgainstBalance}`}>
                    <span>{sharesAgainstCount}</span>
                </TooltipWrapper>
            </div>
            <div className={styles.abstainShares}>
                <TooltipWrapper title={`Voting power: ${sharesAbstainBalance}`}>
                    <span>{sharesAbstainCount}</span>
                </TooltipWrapper>
            </div>
        </div>
    );
};

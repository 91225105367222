import { ISupportingLink } from 'types/interfaces';

export const SUPPORTING_PAGES_LINKS: ISupportingLink[] = [
    {
        id: 1,
        link: '/about',
        name: 'About',
    },
    {
        id: 3,
        link: '/faq',
        name: 'FAQ',
    },
    {
        id: 4,
        link: '/feedback',
        name: 'Feedback',
    },
    {
        id: 6,
        link: '/privacy',
        name: 'Privacy policy',
    },
    {
        id: 7,
        link: '/tos',
        name: 'Terms of service',
    },
    {
        id: 8,
        link: '/api_tos',
        name: 'API Terms of Service',
    },
];

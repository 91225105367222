import { Reducer } from '@reduxjs/toolkit';
import { ORGANIZATIONS_PAGINATION_BATCH_SIZE } from 'constants/dashboardPagination';
import { ORGANIZATIONS_PARAMS } from 'constants/organizationsSorting';

import { OrganizationsActions } from 'store/actions';

import {
    IOrganizationsActionsType,
    IOrganizationsGraphData,
    IOrganizationsState,
    IOrganizationsTotalTrasuryData,
    IOrganizationsStatsInfo,
    IOrganizationsdActiveMembersGraph,
    ITotalTreasuryAumItems,
    IOrganizationsResponse,
} from 'types/interfaces';

const defaultState: IOrganizationsState = {
    loading: false,
    paginationLoading: false,
    lastUpdate: null,
    organizationsCount: null,
    enrichedOrganizationsCount: null,
    daoEcosystemOverview: {},
    organizationsTokensData: [],
    organizationsData: [],
    nextOrganizationsData: [],
    organizationsTopTokens: [],
    organizationsTotalTreasury: {
        graph: {},
        treasuryGraph: [] as IOrganizationsGraphData[],
        aum: [] as ITotalTreasuryAumItems[],
    } as IOrganizationsTotalTrasuryData,
    organizationsOverThresholdAum: {} as IOrganizationsStatsInfo,
    organizationsPotentialVoters: {} as IOrganizationsStatsInfo,
    organizationsActiveMembers: {} as IOrganizationsStatsInfo,
    organizationsOverThresholdActiveMembers: {} as IOrganizationsdActiveMembersGraph,
    organizationsCategories: [],
    currentOrganizationsListPage: 0,
    isEndOfOrganizationsList: false,
    orderBy: ORGANIZATIONS_PARAMS.TREASURY_PARAM,
    paginationOrderDescending: true,
};

export const organizationsReducer: Reducer<IOrganizationsState, IOrganizationsActionsType> = (
    state: IOrganizationsState = defaultState,
    action: IOrganizationsActionsType,
): IOrganizationsState => {
    const { type } = <IOrganizationsActionsType>action;

    switch (type) {
        case OrganizationsActions.GET_ORGANIZATIONS_DATA: {
            return {
                ...state,
                loading: true,
            };
        }

        case OrganizationsActions.GET_ORGANIZATIONS_DATA_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        }

        case OrganizationsActions.GET_ORGANIZATIONS_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case OrganizationsActions.GET_MORE_ORGANIZATIONS_DATA: {
            return {
                ...state,
                paginationLoading: true,
                organizationsData: [...state.organizationsData, ...state.nextOrganizationsData],
                nextOrganizationsData: [],
            };
        }

        case OrganizationsActions.GET_MORE_ORGANIZATIONS_DATA_SUCCESS: {
            const { daosSummary, currentOrganizationsListPage, orderBy, paginationOrderDescending, isSorting } =
                action.payload as IOrganizationsResponse;

            const organizationsData = isSorting
                ? daosSummary.slice(0, ORGANIZATIONS_PAGINATION_BATCH_SIZE)
                : [...state.organizationsData, ...daosSummary.slice(0, ORGANIZATIONS_PAGINATION_BATCH_SIZE)];

            const nextOrganizationsData = daosSummary.slice(ORGANIZATIONS_PAGINATION_BATCH_SIZE);

            return {
                ...state,
                organizationsData,
                nextOrganizationsData,
                currentOrganizationsListPage,
                paginationLoading: false,
                loading: false,
                orderBy,
                paginationOrderDescending,
            };
        }

        case OrganizationsActions.GET_MORE_ORGANIZATIONS_DATA_FAILURE: {
            return {
                ...state,
                paginationLoading: false,
                loading: false,
            };
        }

        case OrganizationsActions.GET_LAST_UPDATE_INFO: {
            return {
                ...state,
            };
        }

        case OrganizationsActions.GET_LAST_UPDATE_INFO_SUCCESS: {
            return {
                ...state,
                ...action.payload,
            };
        }

        case OrganizationsActions.GET_LAST_UPDATE_INFO_FAILURE: {
            return {
                ...state,
            };
        }

        case OrganizationsActions.CLEAR_ORGANIZATIONS_DATA: {
            return {
                ...defaultState,
                lastUpdate: state.lastUpdate,
                organizationsCount: state.organizationsCount,
                enrichedOrganizationsCount: state.enrichedOrganizationsCount,
            };
        }

        default: {
            return state;
        }
    }
};

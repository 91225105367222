import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ITableDataNotFoundProps } from 'types/interfaces';

export const TableDataNotFound: FC<ITableDataNotFoundProps> = ({
    removeMargins = false,
    forTable,
    forGraph,
    className,
    overflowMarginsFirstVariant,
    overflowMarginsSecondVariant,
    widthAuto,
    removeBoxShadow,
}: ITableDataNotFoundProps): ReactElement => (
    <div
        className={classNames(className, {
            [styles.notificationWrapperTable]: forTable,
            [styles.notificationWrapperGraph]: forGraph,
            [styles.removedMargins]: removeMargins,
            [styles.overflowMarginsFirstVariant]: overflowMarginsFirstVariant,
            [styles.overflowMarginsSecondVariant]: overflowMarginsSecondVariant,
            [styles.widthAuto]: widthAuto,
            [styles.removeBoxShadow]: removeBoxShadow,
        })}
    >
        <h5 className={styles.title}>Information unavailable</h5>
    </div>
);

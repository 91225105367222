import React, { FC, ReactElement, useEffect, useCallback } from 'react';

import { useMediaQuery } from '@material-ui/core';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import styles from './styles.module.scss';

import {
    AvatarUploader,
    ImageCropModal,
    ImageLoader,
    ShareButton,
    HeaderFollowButton,
    TooltipWrapper,
} from 'components';
import { ScoreInfo, PersonalInfo, LabelsSection } from './components';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { useAppDispatch, useAppSelector } from 'store';
import { defaultUserPlaceholderImage } from 'assets';
import { FormatUtils } from 'utils';
import { UserActions } from 'store/actions';
import { useWalletAuth } from 'components/ConnectWallet/useWalletAuth';

import { IUserPageHeaderProps } from 'types/interfaces/user.interfaces';
import { EtherscanButton } from '../EtherscanButton';

export const UserPageHeader: FC<IUserPageHeaderProps> = ({
    isAuthenticatedUser,
    handleEditProfileClick,
    uploadedImageSrc,
    setUploadedImageSrc,
    isGuestUserPage,
    isLoggedUser,
}: IUserPageHeaderProps): ReactElement => {
    const {
        userData: { avatar, name, personalInfo, address },
        isUserFollowing,
        userLabelsData,
    } = useAppSelector(({ user }) => user);
    const isTablet = useMediaQuery('(max-width:767.98px)');

    const dispatch = useAppDispatch();

    const { toggleConnection } = useWalletAuth();

    const logo = personalInfo?.people_contact?.logo;
    const username = personalInfo?.people_contact?.name;

    useEffect(() => {
        document.body.style.overflow = uploadedImageSrc ? 'hidden' : 'unset';
    }, [uploadedImageSrc]);

    const onFollowButtonClick = useCallback(() => {
        isLoggedUser
            ? dispatch(UserActions.followUnfollowUser.request({ isUserFollowing, address: address.toLowerCase() }))
            : toggleConnection();
    }, [isUserFollowing, isLoggedUser, address, toggleConnection]);

    return (
        <>
            <div className={styles.headerWrapper}>
                <div className={styles.headerContainer}>
                    <div className={styles.userContent}>
                        <div className={styles.avatarContainer}>
                            <ImageLoader
                                src={logo || avatar || defaultUserPlaceholderImage}
                                className={styles.avatar}
                                imageType={IMAGE_TYPES.USER_IMAGE}
                            />
                            {isAuthenticatedUser && !isTablet && (
                                <AvatarUploader
                                    peopleContactId={personalInfo?.people_contact?.id}
                                    setUploadedImageSrc={setUploadedImageSrc}
                                />
                            )}
                        </div>
                        <div className={styles.mainContent}>
                            <div className={styles.userInfo}>
                                <div>
                                    {(username || name) && <h2 className={styles.userName}>{username || name}</h2>}
                                    {!username && !name && (
                                        <TooltipWrapper title={address}>
                                            <h2 className={styles.userName}>
                                                {FormatUtils.formatUserAddress(address)}
                                            </h2>
                                        </TooltipWrapper>
                                    )}
                                    {isTablet && <PersonalInfo address={address} />}
                                    <div className={styles.userButtonsContainer}>
                                        <ShareButton />

                                        {isAuthenticatedUser && (
                                            <>
                                                <Button className={styles.editButton} onClick={handleEditProfileClick}>
                                                    <EditIcon className={styles.editIcon} />
                                                    <span className={styles.buttonText}>edit</span>
                                                </Button>
                                            </>
                                        )}
                                        {isGuestUserPage && (
                                            <HeaderFollowButton
                                                onClick={onFollowButtonClick}
                                                isFollowing={isUserFollowing}
                                            />
                                        )}

                                        <EtherscanButton userAddress={address} />
                                    </div>
                                </div>
                                {!isTablet && <LabelsSection userLabels={userLabelsData?.labels || []} />}
                            </div>
                        </div>
                    </div>
                    {isTablet && <LabelsSection userLabels={userLabelsData?.labels || []} />}

                    <ScoreInfo />
                </div>
            </div>
            {uploadedImageSrc && (
                <ImageCropModal
                    uploadedImageSrc={uploadedImageSrc}
                    setUploadedImageSrc={setUploadedImageSrc}
                    peopleContactId={personalInfo?.people_contact?.id}
                    address={address}
                />
            )}
        </>
    );
};

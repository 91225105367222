import { IFilterState, IDiscussionsFilterMember, IOrganizationSubstorm } from 'types/interfaces';

export const DEFAULT_FILTER_STATE = {
    order: 'DESC',
    orderByTotalVoters: null,
    from: null,
    to: null,
    members: [] as IDiscussionsFilterMember[],
    isOnlyMyDiscussions: false,
    filterByTokenBalanceSupport: false,
    filterByTokenBalanceAgainst: false,
    filterByNumberOfMembersSupport: false,
    filterByNumberOfMembersAgainst: false,
    substorms: [] as IOrganizationSubstorm[],
} as IFilterState;

import React, { FC, ReactElement, memo } from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { ImageLoader } from 'components';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { LinkUtils } from 'utils';
import { ITokensVotersProps } from 'types/interfaces';

export const TokensVoters: FC<ITokensVotersProps> = memo(
    ({ tokenVotersDetails }: ITokensVotersProps): ReactElement => (
        <div className={styles.tokensVotersWrapper}>
            {tokenVotersDetails?.map((voterDetails) => (
                <Link
                    key={voterDetails.discussionId + voterDetails.address}
                    className={styles.voterInfo}
                    to={LinkUtils.createDynamicLink(voterDetails.address, 'user')}
                >
                    <ImageLoader
                        src={voterDetails.avatar}
                        className={styles.avatar}
                        imageType={IMAGE_TYPES.USER_IMAGE}
                    />
                    <span className={styles.name}>{voterDetails.name}</span>
                </Link>
            ))}
        </div>
    ),
);

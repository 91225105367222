export enum TREASURY_TAB {
    TREASURY_OVER_TIME = 'Treasury Over Time',
    CURRENCIES = 'Currencies',
    ASSETS = 'Assets',
    COMPOSITION_PERCENTAGE = 'Composition percentage',
    COMPOSITION_VALUE = 'Composition value',
    METADATA = 'Metadata',
}

export enum TOKEN_TAB {
    VOTING_TOKEN = 'Voting token',
    ORGANIZATIONS_HOLDING_TOKEN = 'Organizations holding token',
    TOP_TOKEN_HOLDERS = 'Top token holders',
    TOKEN_PRICE_AVG_VOTES_PER_PROPOSAL = 'Token price / avg votes per proposal',
}

export enum TRENDS_TAB {
    PROPOSALS = 'Proposals',
    VOTES = 'Votes',
    LIFETIME_VOTER = 'Lifetime voter',
    YEAR_BY_YEAR = 'Year by year',
    OTHER_TABLES = 'Other tables',
}

export enum DELEGATES_TAB {
    TOP_DELEGATES = 'Top delegates',
    ACTIVE_DELEGATE = 'Active delegate',
}

export enum VOTER_DECENTRALIZATION_TAB {
    TOP_VOTERS = 'Top voters',
    ACTIVE_VOTERS = 'Active voters',
}

export enum COALITIONS_TAB {
    COALITIONS = 'Coalitions',
}

export enum ECOSYSTEM_TAB {
    SIMILAR_ORGS = 'Similar orgs',
    VOTER_GROUPS = 'Voter groups',
    VOTER_ECOSYSTEM_PARTICIPATION = 'Voter ecosystem participation',
    VOTERS_INTERESTS_BREAKDOWN = 'Voters interests breakdown',
    VOTER_RANKS = 'Voter ranks',
    COMPETITOR_DAOS_FOR_VOTERS = 'Competitor DAOs for voters',
    COMPETITOR_DAOS_FOR_TOKEN_HOLDERS = 'Competitor DAOs for token holders',
}

// ---------- Descriptions ----------
export const TREASURY_TAB_DESCRIPTIONS = {
    [TREASURY_TAB.TREASURY_OVER_TIME]: 'The organization’s treasury from the time we started aggregating it. Wallets may be added at different times. See metadata table at the bottom of this page for details.',
    [TREASURY_TAB.CURRENCIES]: 'Tokens held by the organization’s treasury across all its wallets.',
    [TREASURY_TAB.ASSETS]: 'Assets held by the organization’s treasury across all its wallets.',
    [TREASURY_TAB.COMPOSITION_PERCENTAGE]: 'The different types of coins held in the treasury by their percentage of dominance.',
    [TREASURY_TAB.COMPOSITION_VALUE]: 'The different types of coins held in the treasury by their value in USD.',
    [TREASURY_TAB.METADATA]: 'All the contracts and wallets tracked for this organization.',
};

export const TOKEN_TAB_DESCRIPTIONS = {
    [TOKEN_TAB.VOTING_TOKEN]: 'Total and circulating supply, and the percentage of tokens held by the organization treasury.',
    [TOKEN_TAB.ORGANIZATIONS_HOLDING_TOKEN]: 'All organizations holding this organization’s voting token in their treasury.',
    [TOKEN_TAB.TOP_TOKEN_HOLDERS]: 'Contracts and wallets holding the largest stake in the organization’s voting token.',
    [TOKEN_TAB.TOKEN_PRICE_AVG_VOTES_PER_PROPOSAL]: 'Looking at the extent in which token price and governance metrics influence each other.',
};

export const TRENDS_TAB_DESCRIPTIONS = {
    [TRENDS_TAB.PROPOSALS]: 'Monthly number of proposals during the past two years.',
    [TRENDS_TAB.VOTES]: 'Monthly number of votes during the past two years.',
    [TRENDS_TAB.LIFETIME_VOTER]: 'Governance activity since the organization’s inception.',
    [TRENDS_TAB.YEAR_BY_YEAR]: 'Number of yearly voters since the organization’s inception.',
    [TRENDS_TAB.OTHER_TABLES]: 'Governance trend over the past year.',
};

export const DELEGATES_TAB_DESCRIPTIONS = {
    [DELEGATES_TAB.TOP_DELEGATES]: 'Top organization delegates sorted by their voting power, number of delegators, and their recent activity.',
    [DELEGATES_TAB.ACTIVE_DELEGATE]: 'Breakdown of the number of delegates within various voting power thresholds.'
};

export const VOTER_DECENTRALIZATION_TAB_DESCRIPTIONS = {
    [VOTER_DECENTRALIZATION_TAB.TOP_VOTERS]: 'Top organization voters sorted by their voting power and their recent activity.',
    [VOTER_DECENTRALIZATION_TAB.ACTIVE_VOTERS]: 'Breakdown of the number of voters within various voting power thresholds.'
};

export const COALITIONS_TAB_DESCRIPTIONS = {
    [COALITIONS_TAB.COALITIONS]: 'Groups of delegates voting together, and their combined power.',
};

export const ECOSYSTEM_TAB_DESCRIPTIONS = {
    [ECOSYSTEM_TAB.SIMILAR_ORGS]: 'Similar organizations by number of mutual voters.',
    [ECOSYSTEM_TAB.VOTER_GROUPS]: 'The number of voters in various groups. Indicating loyal voters, and missing voters. CSV lists are available to subscribing DAOs.',
    [ECOSYSTEM_TAB.VOTER_ECOSYSTEM_PARTICIPATION]: 'Breaking down the level of voter involvement in the entire DAO ecosystem through the years.',
    [ECOSYSTEM_TAB.VOTERS_INTERESTS_BREAKDOWN]: 'Breaking down the DAO categories which draw the most attention from this organization’s voters.',
    [ECOSYSTEM_TAB.VOTER_RANKS]: 'Measuring the level of involvement the organization’s voters have in the entire ecosystem.',
    [ECOSYSTEM_TAB.COMPETITOR_DAOS_FOR_VOTERS]: 'The DAOs that draw the strongest interest from this organization’s voters, and compete for their attention.',
    [ECOSYSTEM_TAB.COMPETITOR_DAOS_FOR_TOKEN_HOLDERS]: 'The DAOs that draw the strongest interest from this organization’s token holders, and compete for their attention.',
};

import { createApiAction } from 'helpers';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_ADMIN_TOKEN = 'SET_ADMIN_TOKEN';

export const CHALLENGE = 'CHALLENGE';
export const CHALLENGE_SUCCESS = 'CHALLENGE_SUCCESS';
export const CHALLENGE_FAILURE = 'CHALLENGE_FAILURE';

export const GET_LOGGED_USER_DATA = 'GET_LOGGED_USER_DATA';
export const GET_LOGGED_USER_DATA_SUCCESS = 'GET_LOGGED_USER_DATA_SUCCESS';
export const GET_LOGGED_USER_DATA_FAILURE = 'GET_LOGGED_USER_DATA_FAILURE';

export const LOGOUT = 'LOGOUT';

export const login = createApiAction(LOGIN);
export const logout = createApiAction(LOGOUT);
export const challenge = createApiAction(CHALLENGE);
export const getLoggedUserData = createApiAction(GET_LOGGED_USER_DATA);

import React, { FC, ReactElement, useState, useEffect } from 'react';

import styles from './styles.module.scss';

import { TableTabsController, ProposalsTable, VotesTable } from './components';

import { PROPOSALS, VOTES } from './constants';

import { IMoreInfoTables } from 'types/interfaces';

export const MoreInfoTables: FC<IMoreInfoTables> = ({ proposalsData, votesData }: IMoreInfoTables): ReactElement => {
    const [tabSelected, setTabSelected] = useState(VOTES);

    const changeTab = (key: string) => {
        setTabSelected(key);
    };

    const renderTableComponent = () => {
        switch (tabSelected) {
            case PROPOSALS:
                return <ProposalsTable proposalsData={proposalsData} />;

            case VOTES:
                return <VotesTable votesData={votesData} />;

            default:
                return null;
        }
    };

    useEffect(() => {
        const setInitialSelectedTab = () => {
            if (!votesData && proposalsData && proposalsData?.length) {
                changeTab(PROPOSALS);
            } else {
                changeTab(VOTES);
            }
        };
        setInitialSelectedTab();
    }, []);

    return (
        <div className={styles.wrapper}>
            <TableTabsController tab={tabSelected} changeTab={changeTab} />
            <div className={styles.moreInfoTableWrapper}>{renderTableComponent()}</div>
        </div>
    );
};

import { IEcosystemCategory } from 'types/interfaces';

export const ALL_CATEGORIES_ID = 0;

export const allCategoryFilter: IEcosystemCategory = {
    createdAt: null,
    description: null,
    id: ALL_CATEGORIES_ID,
    isActive: true,
    name: 'show all',
    parentId: null,
    type: 'category',
    updatedBy: null,
};

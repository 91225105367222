import { Document } from 'react-pdf';

interface IFaqItem {
    id: string;
    question: string | null;
    answer: string | null;
}

export interface IContentfulFaqItem {
    fields: IFaqItem;
    sys: {
        id: string;
        [key: string]: unknown;
    };
}

export interface IContentfulResponse<FieldStructure> {
    items: { sys: { id: string }; fields: FieldStructure }[];
    includes: { Entry: { sys: { id: string }; fields: { name: string } }[] };
}

export interface IKnowledgeCenterPostField {
    author: { content: { content: { value: string }[] }[] };
    authorPhoto: { sys: { id: string }; fields: { file: { url: string } } };
    categories: { sys: { id: string }; fields: { name: string } };
    description: string;
    facebook: string;
    isMainPost: boolean;
    isSelectedPost: boolean;
    linkedIn: string;
    mainImage: { sys: { id: string }; fields: { file: { url: string } } };
    previewImage: { sys: { id: string }; fields: { file: { url: string } } };
    title: string;
    twitter: string;
    content: any;
}

export interface IKnowledgeCenterPost {
    id: string;
    author: string;
    authorPhoto: string;
    category: string;
    description: string;
    facebook: string;
    isMainPost: boolean;
    isSelectedPost: boolean;
    linkedIn: string;
    mainImage: string;
    previewImage: string;
    title: string;
    twitter: string;
    content: Document;
}

export const serializeContentfulData = (data: IContentfulFaqItem[]): IFaqItem[] =>
    data.map(({ fields, sys }) => {
        const { question, answer } = fields;
        const { id } = sys;

        return {
            id,
            question,
            answer,
        };
    });

export const serializeContentfulKnowledgeCenterPosts = (
    data: IContentfulResponse<IKnowledgeCenterPostField>,
): IKnowledgeCenterPost[] => {
    return data.items.map(({ sys, fields: _data }) => {
        const { categories, ...rest } = _data;

        const mainImage = `https:/${_data.mainImage.fields.file.url}`;
        const previewImage = `https:/${_data.previewImage.fields.file.url}`;
        const author = _data.author.content[0].content[0].value;
        const authorPhoto = `https:/${_data.authorPhoto.fields.file.url}`;
        const category = _data.categories.fields.name;

        return {
            ...rest,
            mainImage,
            previewImage,
            author,
            authorPhoto,
            category,
            id: sys.id,
        };
    });
};

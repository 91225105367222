import { PROTECTED_PAGES_TYPES } from 'constants/pagesTypes';

import { getDeepDAOTokenFromStorage, getPremiumUserTokenFromStorage } from 'web3/storageHelper';

export const setProtectedTokenByProtectionType = (protectedTokenType?: PROTECTED_PAGES_TYPES | null): string | null => {
    switch (protectedTokenType) {
        case PROTECTED_PAGES_TYPES.DEEP_DAO_TOKEN: {
            return getDeepDAOTokenFromStorage();
        }

        case PROTECTED_PAGES_TYPES.PREMIUM_USER_TOKEN: {
            return getPremiumUserTokenFromStorage();
        }

        default:
            return null;
    }
};

import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { ICoalitionItemsProps } from '../../../../../../../../../../types/interfaces';

export const CoalitionsRanking: FC<ICoalitionItemsProps> = ({
    rank,
    isHovered,
    handleMouseEnter,
    handleMouseLeave,
}: ICoalitionItemsProps): ReactElement => {
    return (
        <div
            className={classNames(styles.tableRow, isHovered ? styles.rowHover : null)}
            onMouseEnter={() => handleMouseEnter(rank - 1)}
            onMouseLeave={handleMouseLeave}
        >
            <div className={styles.number}>{rank}</div>
        </div>
    );
};

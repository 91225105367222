import React, { FC, ReactElement, memo } from 'react';

import styles from './styles.module.scss';

import { ISubstormsSectionProps, IOrganizationSubstorm } from 'types/interfaces';

export const SubstormsSection: FC<ISubstormsSectionProps> = memo(
    ({ substorms }: ISubstormsSectionProps): ReactElement => (
        <div className={styles.substormsWrapper}>
            {substorms.map(({ id, title }: IOrganizationSubstorm) => (
                <div key={id} className={styles.substorm}>
                    <span className={styles.substormTitle}>{title}</span>
                </div>
            ))}
        </div>
    ),
);

import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ISortIconProps } from 'types/interfaces';

export const SortIcon: FC<ISortIconProps> = ({ active = false }: ISortIconProps): ReactElement => (
    <div
        className={classNames(styles.iconsWrapper, {
            [styles.iconsActive]: active,
        })}
    >
        <span className={styles.arrowUp} />
        <span className={styles.arrowDown} />
    </div>
);

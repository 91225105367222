import React, { FC, ReactElement } from 'react';

import { ImageLoader, TooltipWrapper } from 'components';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { IDaoElementsCardItemsProps } from 'types/interfaces';

export const DaoElementsCardItems: FC<IDaoElementsCardItemsProps> = ({
    name,
    link,
    logo,
    members,
}: IDaoElementsCardItemsProps): ReactElement => (
    <Link to={link} className={styles.linkWrapper}>
        <div className={styles.container}>
            <ImageLoader src={logo} className={styles.avatar} imageType={IMAGE_TYPES.ORGANIZATION_IMAGE} />
            <div className={styles.mainInfo}>
                <TooltipWrapper title={name}>
                    <h5 className={styles.organizationName}>{name}</h5>
                </TooltipWrapper>
                <p className={styles.description}>In common:</p>
                <div className={styles.stats}>
                    <div>
                        <h6>Mutual Voters</h6>
                    </div>
                    <div>
                        <h6>{members}</h6>
                    </div>
                </div>
            </div>
        </div>
    </Link>
);

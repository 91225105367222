import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import ListIcon from '@material-ui/icons/List';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import styles from './styles.module.scss';

import { FEED_TYPES } from 'constants/feedTypes';

import { IFeedCardIconProps } from 'types/interfaces';

export const FeedCardIcon: FC<IFeedCardIconProps> = ({ type, className }: IFeedCardIconProps): ReactElement => (
    <div className={classNames(styles.iconWrapper, className)}>
        {type === FEED_TYPES.PROPOSAL ? <ListIcon /> : <QuestionAnswerIcon />}
    </div>
);

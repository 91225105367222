import React, { FC, ReactElement, useState, useRef, useEffect, useCallback, useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import { useMediaQuery } from '@material-ui/core';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { DotsLoader } from 'components';
import { SearchAutocompleteItems, SearchSelect } from './components';

import { IMAGE_TYPES } from 'constants/imageTypes';
import { ISearchSelectOptions, searchSelectsList, SEARCH_SELECT_OPTIONS } from './constants';

import { useOutsideClick } from 'hooks';
import { useAppSelector } from 'store';
import { defaultUserPlaceholderImage, searchIconBold, closeArrowFiord } from 'assets';
import { createOrganizationsAutocompleteData } from './utils';
import { mixpanelService } from 'services';

import { IInputFieldProps, IUserSearchItems, ISearchAutocompleteItems } from 'types/interfaces';

export const AutocompleteField: FC<
    IInputFieldProps & {
        selectedSearchType: ISearchSelectOptions;
        setSelectedSearchType: (x: ISearchSelectOptions) => void;
    }
> = ({
    inputValue,
    setInputValue,
    inputOpen = true,
    setInputOpen,
    selectedSearchType,
    setSelectedSearchType,
}: IInputFieldProps & {
    selectedSearchType: ISearchSelectOptions;
    setSelectedSearchType: (x: ISearchSelectOptions) => void;
}): ReactElement => {
    const {
        searchLoading,
        organizationsSearchResults,
        peopleSearchResults,
        organizationsSearchByCategoryResults,
        topThreeOrganizations,
        topThreeUsers,
        userOrganizationsSearchResults,
        topUserOrganizations,
    } = useAppSelector(({ search }) => search);

    const ref = useRef(null);
    const history = useHistory();
    const isLargeTablet = useMediaQuery('(max-width:991.98px)');
    const isMobile = useMediaQuery('(max-width:575.98px)');

    const [inputActive, setInputActive] = useState(false);
    const [organizationsAutocompleteData, setOrganizationsAutocompleteData] = useState<ISearchAutocompleteItems[]>([]);
    const [peopleAutocompleteData, setPeopleAutocompleteData] = useState<IUserSearchItems[]>([]);
    const [userOrganizationsAutocompleteData, setUserOrganizationsAutocompleteData] = useState<
        ISearchAutocompleteItems[]
    >([]);
    const [selectOpen, setSelectOpen] = useState<boolean>(false);

    const showTopResults = useMemo(() => !Boolean(inputValue.length), [inputValue]);

    useOutsideClick(ref, () => setInputActive(false));

    useEffect(() => {
        if (inputValue.length) {
            setOrganizationsAutocompleteData(
                createOrganizationsAutocompleteData(
                    [...organizationsSearchByCategoryResults, ...organizationsSearchResults],
                    history,
                    true,
                ),
            );
            setPeopleAutocompleteData(peopleSearchResults);
            setUserOrganizationsAutocompleteData(
                createOrganizationsAutocompleteData(userOrganizationsSearchResults, history, true),
            );
        } else {
            setOrganizationsAutocompleteData(createOrganizationsAutocompleteData(topThreeOrganizations, history, true));
            setPeopleAutocompleteData(topThreeUsers);
            setUserOrganizationsAutocompleteData(
                createOrganizationsAutocompleteData(topUserOrganizations, history, true),
            );
        }
    }, [
        organizationsSearchByCategoryResults,
        organizationsSearchResults,
        peopleSearchResults,
        topThreeOrganizations,
        topThreeUsers,
        inputValue,
    ]);

    useEffect(() => {
        inputActive && mixpanelService.track(mixpanelService.eventsGenerator.searchEvent());
    }, [inputActive]);

    const onInputChange = useCallback(
        (value: string) => {
            setInputValue(value);
        },
        [inputValue],
    );

    const onMobileSearchClick = () => {
        if (setInputOpen) {
            setInputOpen(!inputOpen);

            if (inputActive) {
                setInputActive(false);
                setInputValue('');
            }
        }
    };

    return (
        <div
            className={classNames(styles.fieldsWrapper, {
                [styles.inputContainerFullWidth]: isLargeTablet,
            })}
        >
            {isLargeTablet && (
                <Button
                    className={classNames(styles.searchButton, { [styles.closeButton]: inputOpen })}
                    disabled={!isLargeTablet}
                    onClick={onMobileSearchClick}
                >
                    {inputOpen ? (
                        <img src={closeArrowFiord} className={styles.closeArrow} alt="" />
                    ) : (
                        <img src={searchIconBold} className={styles.searchIcon} alt="" />
                    )}
                </Button>
            )}

            {inputOpen && (
                <>
                    <SearchSelect
                        selectedSearchType={selectedSearchType}
                        setSelectedSearchType={setSelectedSearchType}
                        selectOpen={selectOpen}
                        setSelectOpen={setSelectOpen}
                        searchSelectsList={searchSelectsList}
                        isMobile={isMobile}
                    />

                    <div className={styles.inputContainer}>
                        <input
                            className={classNames(styles.searchInput, {
                                [styles.searchInputActive]: inputOpen && isLargeTablet,
                            })}
                            value={inputValue}
                            onChange={(e) => onInputChange(e.target.value)}
                            onClick={() => setInputActive(true)}
                            ref={ref}
                        />

                        {inputValue.length > 0 && (
                            <Button className={styles.clearFieldButton} onClick={() => setInputValue('')}>
                                <CloseIcon className={styles.clearFieldIcon} />
                            </Button>
                        )}

                        {inputActive && (
                            <div
                                className={classNames(styles.autocompleteWrapper, {
                                    [styles.autocompleteEmpty]:
                                        peopleAutocompleteData.length === 0 &&
                                        organizationsAutocompleteData.length === 0 &&
                                        !searchLoading,
                                })}
                            >
                                <div className={styles.autocompleteContainer}>
                                    {searchLoading && inputValue ? (
                                        <DotsLoader />
                                    ) : (
                                        <>
                                            {selectedSearchType.value === SEARCH_SELECT_OPTIONS.ORGANIZATIONS && (
                                                <>
                                                    {userOrganizationsAutocompleteData.length > 0 && (
                                                        <SearchAutocompleteItems
                                                            data={userOrganizationsAutocompleteData}
                                                            showMaxFive={true}
                                                            fieldName="my organizations"
                                                            imageType={IMAGE_TYPES.ORGANIZATION_IMAGE}
                                                        />
                                                    )}

                                                    {userOrganizationsAutocompleteData.length > 0 &&
                                                        organizationsAutocompleteData.length > 0 && (
                                                            <div className={styles.divider} />
                                                        )}

                                                    {organizationsAutocompleteData.length > 0 && (
                                                        <SearchAutocompleteItems
                                                            showTopResults={showTopResults}
                                                            data={organizationsAutocompleteData}
                                                            fieldName="organizations"
                                                            imageType={IMAGE_TYPES.ORGANIZATION_IMAGE}
                                                        />
                                                    )}
                                                </>
                                            )}

                                            {selectedSearchType.value === SEARCH_SELECT_OPTIONS.PEOPLE && (
                                                <>
                                                    {peopleAutocompleteData.length > 0 && (
                                                        <SearchAutocompleteItems
                                                            showTopResults={showTopResults}
                                                            data={peopleAutocompleteData.map(
                                                                ({ address, avatar, userName }: IUserSearchItems) => ({
                                                                    id: address,
                                                                    name: userName,
                                                                    logo: avatar || defaultUserPlaceholderImage,
                                                                    onClick: () =>
                                                                        history.push(
                                                                            `/user/${address}/verified_dao_experience`,
                                                                        ),
                                                                }),
                                                            )}
                                                            fieldName="people"
                                                            imageType={IMAGE_TYPES.USER_IMAGE}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export enum SocketEvents {
    JOIN_ORGANIZATION = 'joinOrganization',
    LEAVE_ORGANIZATION = 'leaveOrganization',
    SAVED_DATA = 'savedData',
}

export enum NotificationsSocketEvents {
    NOTIFICATED_USER = 'notificatedUser',
    JOIN_ROOM = 'joinRoom',
    LEAVE_ROOM = 'leaveRoom',
}

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TooltipWrapper, TableCell, ImageLoader } from 'components';

import { defaultUserPlaceholderImage } from 'assets';
import { FormatUtils, LinkUtils } from 'utils';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';
import { IMAGE_TYPES } from 'constants/imageTypes';
import { MEMBERS_PARAMS } from '../../constants';

import { IOrganizationMembersItemsProps } from 'types/interfaces';
import { Link } from 'react-router-dom';

export const OrganizationMembersItems: FC<IOrganizationMembersItemsProps> = ({
    rank,
    name,
    address,
    avatar,
    proposalsCount,
    proposalsLostCount,
    proposalsWonCount,
    proposalsWonPercentage,
    tokenShares,
    tokenSharesPercentage,
    votesCount,
    votesLostCount,
    votesWonCount,
    votesWonPercentage,
    sortedParamName,
}: IOrganizationMembersItemsProps): ReactElement => {
    const rankValue = !FormatUtils.checkIfDataNotExist(rank)
        ? FormatUtils.formatNumberWithCommas(rank, 0)
        : DATA_NOT_FOUND;

    const proposalWonLost = proposalsWonCount + '/' + proposalsLostCount;
    const voteWonLost = votesWonCount + '/' + votesLostCount;

    const tokensSharesValue = !FormatUtils.checkIfDataNotExist(tokenShares)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(tokenShares as number), 0)
        : DATA_NOT_FOUND;

    const tokensSharesPercentageValue = !FormatUtils.checkIfDataNotExist(tokenSharesPercentage)
        ? tokenSharesPercentage.toFixed(1)
        : DATA_NOT_FOUND;

    const proposalsCreatedValue = !FormatUtils.checkIfDataNotExist(proposalsCount)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(proposalsCount), 0)
        : DATA_NOT_FOUND;

    const proposalsWonPercentageValue = !FormatUtils.checkIfDataNotExist(proposalsWonPercentage)
        ? proposalsWonPercentage.toFixed(1)
        : DATA_NOT_FOUND;

    const votesCountValue = !FormatUtils.checkIfDataNotExist(votesCount)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(votesCount), 0)
        : DATA_NOT_FOUND;

    const votesWonPercentageValue = !FormatUtils.checkIfDataNotExist(votesWonPercentage)
        ? votesWonPercentage.toFixed(1)
        : DATA_NOT_FOUND;

    return (
        <Link to={LinkUtils.createDynamicLink(address, 'user')}>
            <div className={styles.tableRow}>
                <TableCell className={styles.rank} isHighlighted={sortedParamName === MEMBERS_PARAMS.RANK}>
                    <span className={styles.rankIndex}>{rankValue}</span>
                </TableCell>
                <TableCell
                    className={styles.usernameAddress}
                    isHighlighted={sortedParamName === MEMBERS_PARAMS.ADDRESS_USERNAME}
                >
                    <ImageLoader
                        src={avatar || defaultUserPlaceholderImage}
                        className={styles.avatar}
                        imageType={IMAGE_TYPES.USER_IMAGE}
                    />
                    <TooltipWrapper title={name || address}>
                        <span className={styles.name}>{name || address}</span>
                    </TooltipWrapper>
                </TableCell>
                <TableCell className={styles.voted} isHighlighted={sortedParamName === MEMBERS_PARAMS.VOTED_PARAM}>
                    <span>{votesCountValue}</span>
                </TableCell>
                <TableCell
                    className={styles.votedWin}
                    isHighlighted={sortedParamName === MEMBERS_PARAMS.VOTED_WIN_PARAM}
                >
                    <span>{votesWonPercentageValue}</span>
                </TableCell>
                <div className={styles.votedWonLost}>
                    <span className={styles.info}>{voteWonLost}</span>
                </div>
                <TableCell
                    className={styles.proposalsCreated}
                    isHighlighted={sortedParamName === MEMBERS_PARAMS.PROPOSALS_CREATED_PARAM}
                >
                    <span>{proposalsCreatedValue}</span>
                </TableCell>
                <TableCell
                    className={styles.proposalsWin}
                    isHighlighted={sortedParamName === MEMBERS_PARAMS.PROPOSAL_WIN_PARAM}
                >
                    <span>{proposalsWonPercentageValue}</span>
                </TableCell>
                <div className={styles.proposalsWonLost}>
                    <span className={styles.info}>{proposalWonLost}</span>
                </div>
                <TableCell
                    className={styles.tokensShare}
                    isHighlighted={sortedParamName === MEMBERS_PARAMS.TOKENS_SHARE_PARAM}
                >
                    <TooltipWrapper title={tokensSharesValue}>
                        <span className={styles.tokensSharesValue}>{tokensSharesValue}</span>
                    </TooltipWrapper>
                </TableCell>
                <TableCell
                    className={styles.sharePercent}
                    isHighlighted={sortedParamName === MEMBERS_PARAMS.SHARE_PERCENT_PARAM}
                >
                    <span>{tokensSharesPercentageValue}</span>
                </TableCell>
            </div>
        </Link>
    );
};

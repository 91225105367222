import { call, takeLatest, put } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { PremiumPlans } from 'store/apis';
import { PremiumPlansActions } from 'store/actions';

function* fetchPaypalPlansWorker(): SagaIterator {
    try {
        const { data } = yield call(PremiumPlans.fetchPaypalPremiumPlans);
        yield put(PremiumPlansActions.fetchPaypalPremiumPlans.success(data || []));
    } catch (e) {
        yield put(PremiumPlansActions.fetchPaypalPremiumPlans.failure(e));
    }
}

function* getPlanWorker({ payload }: any): SagaIterator {
    try {
        const { data } = yield call(PremiumPlans.getPaypalPlan, payload.id);
        yield put(PremiumPlansActions.getPaypalPlan.success(data));
    } catch (e) {
        yield put(PremiumPlansActions.getPaypalPlan.failure(e));
    }
}

function* fetchBluesnapPlansWorker(): SagaIterator {
    try {
        const { data } = yield call(PremiumPlans.fetchBluesnapPremiumPlans);
        yield put(PremiumPlansActions.fetchBluesnapPremiumPlans.success(data?.plans || []));
    } catch (e) {
        yield put(PremiumPlansActions.fetchBluesnapPremiumPlans.failure(e));
    }
}

function* getBluesnapPlanWorker({ payload }: any): SagaIterator {
    try {
        const { data } = yield call(PremiumPlans.getBluesnapPlan, payload.id);
        yield put(PremiumPlansActions.getBluesnapPlan.success(data));
    } catch (e) {
        yield put(PremiumPlansActions.getBluesnapPlan.failure(e));
    }
}

export const PremiumPlansSaga = function* (): SagaIterator {
    yield takeLatest(PremiumPlansActions.FETCH_PAYPAL_PLANS, fetchPaypalPlansWorker);
    yield takeLatest(PremiumPlansActions.GET_PAYPAL_PLAN, getPlanWorker);
    yield takeLatest(PremiumPlansActions.FETCH_BLUESNAP_PLANS, fetchBluesnapPlansWorker);
    yield takeLatest(PremiumPlansActions.GET_BLUESNAP_PLAN, getBluesnapPlanWorker);
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { InfoCircle, Loader } from 'components/index';
import { VotingActivityTable } from './components';

import { useAppSelector } from 'store';
import { TRENDS_TAB, TRENDS_TAB_DESCRIPTIONS } from '../../../../../../../../constants';

export const VotingActivity: FC = (): ReactElement => {
    const { activityDataLoading } = useAppSelector(({ organization }) => organization);

    return (
        <div className={styles.tablesWrapper}>
            <div className={styles.title}>
                <h4>Lifetime Voter Activity</h4>
                <InfoCircle
                    tooltipText={TRENDS_TAB_DESCRIPTIONS[TRENDS_TAB.LIFETIME_VOTER]}
                    className={styles.infoIcon}
                />
            </div>
            <div className={styles.tableWrapper}>
                <div className={styles.tableContainer}>
                    {activityDataLoading ? <Loader className={styles.loaderBox} size={50} /> : <VotingActivityTable />}
                </div>
            </div>
        </div>
    );
};

import { call, takeLatest, put, all, select } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { AuthActions, UserActions } from 'store/actions';
import { UserApi } from 'store/apis';

import { NOTIFICATIONS_ACTIONS_TYPES } from 'constants/notificationsActions';

import {
    IGetSimilarUsersAction,
    IGetUserDataWorker,
    IUpdateUserDataRequest,
    IUserOpportunitiesPayload,
    IGetUserTokenListAction,
    IGetUserNotificationPayload,
    INotificationData,
    IUpdateNotificationSeenStatusPayload,
    IGetUserIsFollowingDataPayload,
    IFollowUnfollowUserPayload,
} from 'types/interfaces';
import { getLoggedUserDataWorked } from './auth.saga';

function* getUserDataWorker({ payload }: IGetUserDataWorker): SagaIterator {
    try {
        const { data } = yield call(UserApi.getUserData, payload.userId);

        yield put(UserActions.getUserData.success(data || {}));
    } catch (e) {
        yield put(UserActions.getUserData.failure(e));
    }
}

function* getUserProposalsDataWorker({ payload }: IGetUserDataWorker): SagaIterator {
    try {
        const { data } = yield call(UserApi.getUserProposalsData, payload.userId);

        yield put(UserActions.getUserProposalsData.success(data || {}));
    } catch (e) {
        yield put(UserActions.getUserProposalsData.failure(e));
    }
}

function* getUserVotesDataWorker({ payload }: IGetUserDataWorker): SagaIterator {
    try {
        const { data } = yield call(UserApi.getUserVotesData, payload.userId);
        yield put(UserActions.getUserVotesData.success(data || {}));
    } catch (e) {
        yield put(UserActions.getUserVotesData.failure(e));
    }
}

function* updateUserDataWorker({ payload }: { type: string; payload: IUpdateUserDataRequest }): SagaIterator {
    try {
        const { data } = yield call(UserApi.updateUserData, payload);

        yield put(UserActions.updateUserData.success(data));
    } catch (e) {
        yield put(UserActions.updateUserData.failure(e));
    }
}

function* updateUserAvatarWorker({
    payload,
}: {
    type: string;
    payload: { userId: string; data: FormData; address: string };
}): SagaIterator {
    try {
        const { data, hasError } = yield call(UserApi.updateUserAvatar, payload.userId, payload.data);
        if (!hasError) {
            yield call(getLoggedUserDataWorked, {
                type: AuthActions.GET_LOGGED_USER_DATA,
                payload: { address: payload.address },
            });

            yield put(UserActions.updateUserAvatar.success(data));
        } else {
            yield put(UserActions.updateUserAvatar.failure());
        }
    } catch (e) {
        yield put(UserActions.updateUserAvatar.failure());
    }
}

function* updateUserOpportunitiesWorker({
    payload,
}: {
    type: string;
    payload: IUserOpportunitiesPayload;
}): SagaIterator {
    try {
        const {
            userData: { personalInfo },
        } = yield select(({ user }) => user);

        const { data } = yield call(UserApi.updateUserOpportunities, payload.userId, payload.openFor);
        const updatedPersonalInfo = { ...personalInfo, people_contact: { ...personalInfo.people_contact, ...data } };

        yield put(UserActions.updateUserOpportunities.success(updatedPersonalInfo));
    } catch (e) {
        yield put(UserActions.updateUserOpportunities.failure(e));
    }
}

function* getSimilarUsersByOrganizationsListWorker({ payload }: IGetSimilarUsersAction): SagaIterator {
    const { hasError, error, data } = yield call(UserApi.getSimilarUsersByOrganizationsData, payload.address);

    if (hasError) {
        yield put(UserActions.getSimilarUsersByOrganizationsData.failure(error));
        return;
    }

    yield put(UserActions.getSimilarUsersByOrganizationsData.success(data));
}

function* getSimilarUsersByTokensListWorker({ payload }: IGetSimilarUsersAction): SagaIterator {
    const { hasError, error, data } = yield call(UserApi.getSimilarUsersByTokensData, payload.address);

    if (hasError) {
        yield put(UserActions.getSimilarUsersByTokensData.failure(error));
        return;
    }

    yield put(UserActions.getSimilarUsersByTokensData.success(data));
}

function* getUserTokenListWorker({ payload }: IGetUserTokenListAction): SagaIterator {
    try {
        const { data } = yield call(UserApi.getUserTokenListData, payload.userId);

        yield put(UserActions.getUserTokenListData.success(data));
    } catch (e) {
        yield put(UserActions.getUserTokenListData.failure(e));
    }
}

export const getUserNotificationsWorker = function* ({ payload }: IGetUserNotificationPayload): SagaIterator {
    try {
        const { userNotificationsData, notificationsOffset } = yield select(({ user }) => user);

        if (payload.token) {
            const { data, hasError } = yield call(
                UserApi.getUserNotifications,
                payload.token,
                payload.notificationsActionType === NOTIFICATIONS_ACTIONS_TYPES.INITIAL_REQUEST
                    ? 0
                    : payload.notificationsOffset
                    ? payload.notificationsOffset
                    : notificationsOffset,
            );

            if (hasError) yield put(UserActions.getUserNotifications.success({ userNotificationsData }));
            else {
                if (payload.notificationsActionType === NOTIFICATIONS_ACTIONS_TYPES.INITIAL_REQUEST) {
                    yield put(
                        UserActions.getUserNotifications.success({
                            userNotificationsData: data,
                            notificationsOffset: 0,
                            hasMoreNotifications: true,
                            notificationsActionType: payload.notificationsActionType,
                        }),
                    );
                } else
                    yield put(
                        UserActions.getUserNotifications.success({
                            userNotificationsData: {
                                newNotifications: [...userNotificationsData.newNotifications, ...data.newNotifications],
                                oldNotifications: [...userNotificationsData.oldNotifications, ...data.oldNotifications],
                            },
                            notificationsOffset: payload.notificationsOffset
                                ? payload.notificationsOffset
                                : notificationsOffset,
                            hasMoreNotifications:
                                Boolean(data.newNotifications.length) || Boolean(data.oldNotifications.length),
                            notificationsActionType: payload.notificationsActionType,
                        }),
                    );
            }
        } else {
            yield put(
                UserActions.getUserNotifications.success({
                    newNotifications: [],
                    oldNotifications: [],
                }),
            );
        }
    } catch (e) {
        yield put(UserActions.getUserNotifications.failure(e));
    }
};

export const updateNotificationSeenStatusWorker = function* ({
    payload,
}: IUpdateNotificationSeenStatusPayload): SagaIterator {
    try {
        const { userNotificationsData } = yield select(({ user }) => user);

        const { hasError } = yield call(UserApi.updateNotificationSeenStatus, payload.notificationId);
        if (!hasError) {
            const updatedNewNotificationsData = userNotificationsData.newNotifications.filter(
                ({ id }: INotificationData) => id !== payload.notificationId,
            );

            const oldNotificationData = userNotificationsData.newNotifications.find(
                ({ id }: INotificationData) => id === payload.notificationId,
            );

            yield put(
                UserActions.updateNotificationSeenStatus.success({
                    newNotifications: updatedNewNotificationsData,
                    oldNotifications: [
                        { ...oldNotificationData, isSeen: true },
                        ...userNotificationsData.oldNotifications,
                    ],
                }),
            );
        }
    } catch (e) {
        yield put(UserActions.updateNotificationSeenStatus.failure(e));
    }
};

function* getUserIsFollowingDataWorker({ payload }: IGetUserIsFollowingDataPayload): SagaIterator {
    try {
        if (payload.isLogged) {
            const { data, hasError } = yield call(UserApi.getUserIsFollowingData, payload.address);

            if (hasError) yield put(UserActions.getUserIsFollowingData.failure());
            else yield put(UserActions.getUserIsFollowingData.success({ isUserFollowing: data.isFollowing }));
        } else yield put(UserActions.getUserIsFollowingData.success({ isUserFollowing: false }));
    } catch (e) {
        yield put(UserActions.getUserIsFollowingData.failure(e));
    }
}

function* followUnfollowUserWorker({ payload }: IFollowUnfollowUserPayload): SagaIterator {
    try {
        const { hasError } = yield call(
            payload.isUserFollowing ? UserApi.unFollowUser : UserApi.followUser,
            payload.address,
        );

        if (hasError) yield put(UserActions.followUnfollowUser.failure());
        else yield put(UserActions.followUnfollowUser.success({ isUserFollowing: !payload.isUserFollowing }));
    } catch (e) {
        yield put(UserActions.followUnfollowUser.failure(e));
    }
}

function* getUserFriendsDataWorker({ payload }: IGetUserDataWorker): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            UserApi.getUserFriendsAndEnemiesData,
            payload.userId,
            'friendly_votes',
        );

        if (hasError) yield put(UserActions.getUserFriendsData.failure(error));
        else yield put(UserActions.getUserFriendsData.success(data.data));
    } catch (e) {
        yield put(UserActions.getUserFriendsData.failure(e));
    }
}

function* getUserEnemiesDataWorker({ payload }: IGetUserDataWorker): SagaIterator {
    try {
        const { data, hasError, error } = yield call(
            UserApi.getUserFriendsAndEnemiesData,
            payload.userId,
            'hostile_votes',
        );

        if (hasError) yield put(UserActions.getUserEnemiesData.failure(error));
        else yield put(UserActions.getUserEnemiesData.success(data.data));
    } catch (e) {
        yield put(UserActions.getUserEnemiesData.failure(e));
    }
}

function* getUserLabelsDataWorker({ payload }: IGetUserDataWorker): SagaIterator {
    try {
        const { data, hasError } = yield call(UserApi.getUserLabelsData, payload.userId);

        if (hasError) yield put(UserActions.getUserLabelsData.failure());
        else yield put(UserActions.getUserLabelsData.success({ userLabelsData: data.data }));
    } catch (e) {
        yield put(UserActions.getUserLabelsData.failure(e));
    }
}

export const userSaga = function* (): SagaIterator {
    yield all([
        takeLatest(UserActions.GET_USER_DATA, getUserDataWorker),
        takeLatest(UserActions.UPDATE_USER_DATA, updateUserDataWorker),
        takeLatest(UserActions.UPDATE_USER_AVATAR, updateUserAvatarWorker),
        takeLatest(UserActions.UPDATE_USER_OPPORTUNITIES, updateUserOpportunitiesWorker),
        takeLatest(UserActions.GET_SIMILAR_USERS_BY_ORGANIZATIONS_DATA, getSimilarUsersByOrganizationsListWorker),
        takeLatest(UserActions.GET_SIMILAR_USERS_BY_TOKENS_DATA, getSimilarUsersByTokensListWorker),
        takeLatest(UserActions.GET_USER_TOKEN_LIST_DATA, getUserTokenListWorker),
        takeLatest(UserActions.GET_USER_NOTIFICATIONS, getUserNotificationsWorker),
        takeLatest(UserActions.UPDATE_NOTIFICATION_SEEN_STATUS, updateNotificationSeenStatusWorker),
        takeLatest(UserActions.GET_USER_IS_FOLLOWING_DATA, getUserIsFollowingDataWorker),
        takeLatest(UserActions.FOLLOW_UNFOLLOW_USER, followUnfollowUserWorker),
        takeLatest(UserActions.GET_USER_FRIENDS_DATA, getUserFriendsDataWorker),
        takeLatest(UserActions.GET_USER_ENEMIES_DATA, getUserEnemiesDataWorker),
        takeLatest(UserActions.GET_USER_PROPOSALS_DATA, getUserProposalsDataWorker),
        takeLatest(UserActions.GET_USER_VOTES_DATA, getUserVotesDataWorker),
        takeLatest(UserActions.GET_USER_LABELS_DATA, getUserLabelsDataWorker),
    ]);
};

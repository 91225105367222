import { IOrganizationCurrency, IOrganizationCurrencyTableData } from 'types/interfaces';

export const createCurrenciesTableData = (currenciesData: IOrganizationCurrency[]): IOrganizationCurrencyTableData[] =>
    currenciesData?.length
        ? currenciesData.map(
              (
                  {
                      chainId,
                      chainTitle,
                      currencyPercentage,
                      tokenName,
                      tokenSymbol,
                      usdValue,
                      tokenIcon,
                      tokenBalance,
                  }: IOrganizationCurrency,
                  index: number,
              ) => ({
                  chainId,
                  chainTitle,
                  currencyPercentage,
                  tokenName,
                  tokenSymbol,
                  usdValue,
                  tokenIcon,
                  tokenBalance,
                  rank: index + 1,
              }),
          )
        : [];

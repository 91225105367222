import { call, put, takeEvery } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import {
    DAO_TREASURY_INVESTMENTS_QUERY_PARAMS,
    DAO_TREASURY_INVESTMENTS_TYPES,
} from 'constants/daoTreasuryInvestments';

import { DaoTreasuryInvestmentsApi } from 'store/apis';
import { DaoTreasuryInvestmentsActions } from 'store/actions';
import { IGetDaoTreasuryInvestmentsAction } from 'types/interfaces';

function* getDaoTreasuryInvestmentsWorker({ payload }: IGetDaoTreasuryInvestmentsAction) {
    const staticQueryParams =
        DAO_TREASURY_INVESTMENTS_QUERY_PARAMS[payload.fieldName as DAO_TREASURY_INVESTMENTS_TYPES];

    try {
        const { data, hasError, error } = yield call(DaoTreasuryInvestmentsApi[payload.daoTreasuryAssetsApi], {
            ...staticQueryParams,
            ...payload?.queryParams,
        });

        if (hasError)
            yield put(
                DaoTreasuryInvestmentsActions.getDaoTreasuryInvestments.failure({
                    error,
                    fieldName: payload.fieldName,
                }),
            );
        else
            yield put(
                DaoTreasuryInvestmentsActions.getDaoTreasuryInvestments.success({
                    fieldName: payload.fieldName,
                    data: { data: data?.data || data || [], lastUpdateDate: data?.apiAccountMetaData?.date },
                }),
            );
    } catch (e) {
        yield put(DaoTreasuryInvestmentsActions.getDaoTreasuryInvestments.failure(e));
    }
}

export const daoTreasuryInvestmentsSaga = function* (): SagaIterator {
    yield takeEvery(DaoTreasuryInvestmentsActions.GET_DAO_TREASURY_INVESTMENTS, getDaoTreasuryInvestmentsWorker);
};

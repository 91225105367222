import { call, takeLatest, put, all } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { ResourcesActions } from 'store/actions';
import { ResourcesApi } from 'store/apis';

import { IGetSortedResourcesDataAction } from 'types/interfaces';

function* getResourcesDataWorker(): SagaIterator {
    try {
        const [resourcesData, resourcesCategoriesData] = yield all([
            call(ResourcesApi.getResourcesData),
            call(ResourcesApi.getResourcesCategoriesData),
        ]);

        yield put(
            ResourcesActions.getAllResourcesData.success({
                resourcesData: resourcesData?.data || [],
                resourcesCategoriesData: resourcesCategoriesData?.data || [],
            }),
        );
    } catch (e) {
        yield put(ResourcesActions.getAllResourcesData.failure(e));
    }
}

function* getSortedResourcesDataWorker({ payload }: IGetSortedResourcesDataAction): SagaIterator {
    try {
        const { data } = yield call(ResourcesApi.getResourcesData, payload?.categoriesIds);

        yield put(
            ResourcesActions.getSortedResourcesData.success({
                resourcesData: data,
            }),
        );
    } catch (e) {
        yield put(ResourcesActions.getSortedResourcesData.failure(e));
    }
}

export const resourcesSaga = function* (): SagaIterator {
    yield takeLatest(ResourcesActions.GET_ALL_RESOURCES_DATA, getResourcesDataWorker);
    yield takeLatest(ResourcesActions.GET_SORTED_RESOURCES_DATA, getSortedResourcesDataWorker);
};

import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState, useRef } from 'react';

import { useParams } from 'react-router-dom';

import io, { Socket } from 'socket.io-client';

import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import moment from 'moment';

import styles from './styles.module.scss';

import { DiscussionCardItems, Loader, StartDiscussionCard } from 'components';
import { ProjectTokenSection, DiscussionsFilter } from './components';

import { getAddressFromStorage } from 'web3/storageHelper';
import { WEBSOCKET_URL } from 'constants/apis';
import { SocketEvents } from 'constants/socketEvents';
import { ERROR_MESSAGES } from 'constants/alert';

import { useAppSelector, useAppDispatch } from 'store';
import { OrganizationActions } from 'store/actions';
import { mixpanelService } from 'services';

import { IDiscussionItems, IFilterState, IOrganizationSubstorm } from 'types/interfaces';

export const OrganizationDiscussions: FC = (): ReactElement => {
    const {
        token,
        refreshToken,
        loggedUserData: { address },
    } = useAppSelector(({ auth }) => auth);

    const { organizationDiscussions, discussionsLoading, discussionsError, allowInteractionWithDiscussions } =
        useAppSelector(({ organization }) => organization);

    const dispatch = useAppDispatch();

    const [discussionsFilters, setDiscussionsFilters] = useState('');

    const { organizationId } = useParams<{ organizationId: string; tab: string; organization_data_tab: string }>();

    const socketRef = useRef<Socket>();

    const isAuthenticatedUser = useMemo(() => Boolean(token) && Boolean(address), [token, address]);

    useEffect(() => {
        getOrganizationDiscussions();
    }, [discussionsFilters, refreshToken]);

    useEffect(() => {
        mixpanelService.track(mixpanelService.eventsGenerator.brainstorm());
        mixpanelService.track(mixpanelService.eventsGenerator.brainstormOrg(organizationId));
    }, []);

    const getOrganizationDiscussions = useCallback(
        (skipLoading = false) => {
            if (discussionsFilters) {
                dispatch(
                    OrganizationActions.getOrganizationDiscussions.request({
                        organizationId,
                        address: getAddressFromStorage(),
                        discussionsFilters,
                        skipLoading,
                    }),
                );
            }
        },
        [discussionsFilters],
    );

    useEffect(() => {
        if (socketRef.current) {
            socketRef.current.on(SocketEvents.SAVED_DATA, () => {
                getOrganizationDiscussions(true);
            });
        }
    }, [discussionsFilters]);

    useEffect(() => {
        socketRef.current = io(WEBSOCKET_URL);
        socketRef.current.emit(SocketEvents.JOIN_ORGANIZATION, organizationId);

        return () => {
            if (socketRef.current) {
                socketRef.current.emit(SocketEvents.LEAVE_ORGANIZATION, organizationId);
                socketRef.current.disconnect();
            }
        };
    }, []);

    const handleSetDiscussionsFilter = useCallback((filters: IFilterState) => {
        const {
            order,
            orderByTotalVoters,
            from,
            to,
            members,
            isOnlyMyDiscussions,
            filterByTokenBalanceSupport,
            filterByTokenBalanceAgainst,
            filterByNumberOfMembersSupport,
            filterByNumberOfMembersAgainst,
            substorms,
        } = filters;

        const mappedFilters = {
            organizationId,
            creator: members.map((member: { address: string }) => member.address),
            from: from && moment(from).format(),
            to: to && moment(to).format(),
            orderByCreatedAt: order,
            orderByTotalVoters,
            filterByTokenBalanceSupport,
            filterByTokenBalanceAgainst,
            filterByNumberOfMembersSupport,
            filterByNumberOfMembersAgainst,
            isOnlyMyDiscussions,
            substorms: substorms.map(({ id }: IOrganizationSubstorm) => id),
        } as any;

        setDiscussionsFilters('?' + new URLSearchParams(mappedFilters).toString());
    }, []);

    const clearDiscussionsError = useCallback(
        () => dispatch({ type: OrganizationActions.CLEAR_DISCUSSIONS_ERROR }),
        [dispatch, discussionsError],
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.discussionsContent}>
                <DiscussionsFilter handleSetDiscussionsFilter={handleSetDiscussionsFilter} isLogged={Boolean(token)} />

                <div className={styles.container}>
                    <div className={styles.startDiscussionWrapper}>
                        <StartDiscussionCard disabledModal={!isAuthenticatedUser || !allowInteractionWithDiscussions} />
                    </div>

                    {discussionsError && (
                        <div className={styles.errorBlock}>
                            <span className={styles.errorText}>{ERROR_MESSAGES.SOMETHING_WENT_WRONG}</span>
                            <Button className={styles.closeBtn} onClick={clearDiscussionsError}>
                                <CloseIcon className={styles.closeIcon} />
                            </Button>
                        </div>
                    )}

                    {discussionsLoading ? (
                        <div className={styles.loaderBox}>
                            <Loader className={styles.loader} />
                        </div>
                    ) : (
                        <div className={styles.cadrsContainer}>
                            {organizationDiscussions.map((discussionInfo: IDiscussionItems) => (
                                <DiscussionCardItems
                                    key={discussionInfo.id}
                                    {...discussionInfo}
                                    loggedUserAddress={address}
                                    isAuthenticatedUser={isAuthenticatedUser}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <ProjectTokenSection />
        </div>
    );
};

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './index.css';

import { App, AlertProvider } from 'components';

import { store } from './store';
import { ContentfulProvider } from 'react-contentful';
import { contentfulClient } from './providers/contentful';

ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
        <ContentfulProvider client={contentfulClient}>
            <BrowserRouter>
                <AlertProvider>
                    <App />
                </AlertProvider>
            </BrowserRouter>
        </ContentfulProvider>
    </Provider>,
    // </React.StrictMode>,
    document.getElementById('root'),
);

import { Reducer } from '@reduxjs/toolkit';

import { AlertActions } from 'store/actions';

import { IAlertState, IAlertActions, IShowAlertPayload } from 'types/interfaces';

const defaultState: IAlertState = {
    isAlertOpen: false,
    title: '',
    description: '',
    type: null,
};

export const alertReducer: Reducer<IAlertState, IAlertActions> = (
    state: IAlertState = defaultState,
    action: IAlertActions,
): IAlertState => {
    const { type, payload } = <IAlertActions>action;

    switch (type) {
        case AlertActions.SHOW_ALERT: {
            return {
                isAlertOpen: true,
                ...(payload as IShowAlertPayload),
            };
        }

        case AlertActions.HIDE_ALERT: {
            return {
                ...defaultState,
            };
        }

        default: {
            return state;
        }
    }
};

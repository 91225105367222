import React, { FC, ReactElement, memo, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';

import { ImageLoader } from 'components';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { setNotificationLinkByType, setNotificationTextByType } from './utils';
import { FormatUtils } from 'utils';
import { useAppDispatch } from 'store';
import { UserActions } from 'store/actions';

import { INotificationData } from 'types/interfaces';

export const NotificationItems: FC<INotificationData> = memo((data: INotificationData): ReactElement => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const onNotificationClick = useCallback(
        (notificationData: INotificationData) => {
            if (!notificationData.isSeen)
                dispatch(UserActions.updateNotificationSeenStatus.request({ notificationId: notificationData.id }));
            history.push(setNotificationLinkByType(notificationData.type, notificationData));
        },
        [dispatch],
    );

    return (
        <div className={styles.notificationItems} onClick={() => onNotificationClick(data)}>
            <ImageLoader
                src={
                    data?.data?.logo ||
                    data?.data?.idxImageUrl ||
                    data?.data?.ensImageUrl ||
                    data?.groupData?.logo ||
                    data?.groupData?.idxImageUrl ||
                    data?.groupData?.ensImageUrl
                }
                className={styles.avatar}
                imageType={IMAGE_TYPES.USER_IMAGE}
            />
            <div className={styles.textInfoSection}>
                <p className={styles.messageText}>{setNotificationTextByType(data.type, data)}</p>
                <h6 className={styles.date}>{FormatUtils.getFormattedLastUpdateDate(data.createdAt)}</h6>
            </div>
        </div>
    );
});

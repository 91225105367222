import { combineReducers } from 'redux';

import { feedReducer } from './feed.reducer';
import { organizationReducer } from './organization.reducer';
import { organizationsReducer } from './organizations.reducer';
import { peopleReducer } from './people.reducer';
import { searchReducer } from './search.reducer';
import { userReducer } from './user.reducer';
import { resourcesReducer } from './resources.reducer';
import { authReducer } from './auth.reducer';
import { faqsReducer } from './faqs.reducer';
import { alertReducer } from './alert.reducer';
import { feedProposalReducer } from './feedProposal.reducer';
import { apiReducer } from './api.reducer';
import { selfListingReducer } from './selfListing.reducer';
import { premiumClientsReducer } from './premiumClients.reducer';
import { premiumSubscriptionReducer } from './premiumSubscription.reducer';
import { premiumPlansReducer } from './premiumPlans.reducer';
import { socialFeaturesReducer } from './socialFeatures.reducer';
import { sidebarReducer } from './sidebar.reducer';
import { organizationsSearchReducer } from './organizationsSearch.reducer';
import { daoTreasuryInvestmentsReducer } from './daoTreasuryInvestments.reducer';
import { daoTokensReducer } from './daoTokens.reducer';
import { organizationTabsReducer } from './organization-tabs.reducer';

export const rootReducer = combineReducers({
    organizations: organizationsReducer,
    daoTokens: daoTokensReducer,
    organization: organizationReducer,
    organizationTabs: organizationTabsReducer,
    feed: feedReducer,
    people: peopleReducer,
    user: userReducer,
    search: searchReducer,
    resources: resourcesReducer,
    auth: authReducer,
    faqs: faqsReducer,
    alert: alertReducer,
    feedProposal: feedProposalReducer,
    api: apiReducer,
    selfListing: selfListingReducer,
    socialFeatures: socialFeaturesReducer,
    premiumClients: premiumClientsReducer,
    premiumSubscription: premiumSubscriptionReducer,
    premiumPlans: premiumPlansReducer,
    sidebar: sidebarReducer,
    organizationsSearch: organizationsSearchReducer,
    daoTreasuryInvestments: daoTreasuryInvestmentsReducer,
});

import React, { FC, ReactElement } from 'react';
import { TooltipProps } from 'recharts';
import styles from './styles.module.scss';

export const CustomLegend: FC<TooltipProps<number, string>> = (): ReactElement => {
    return (
        <div className={styles.legend}>
            <div className={styles.barColoringInfo}>
                <span className={styles.votingTokenColoring}>Voting Token</span>
                <span className={styles.stablecoinsColoring}>Stablecoins</span>
                <span className={styles.ethBtcColoring}>ETH/BTC</span>
                <span className={styles.otherTokensColoring}>Other Tokens</span>
            </div>
        </div>
    );
};

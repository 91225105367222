import React, { FC, ReactElement } from 'react';

import { Link } from '@material-ui/core';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { TooltipWrapper } from 'components';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { IAssetInfo } from 'types/interfaces';

const GOVERNANCE_TYPE = 'governance';

export const AssetRowItems: FC<IAssetInfo> = ({
    address,
    description,
    type,
    url,
    chainTitle,
}: IAssetInfo): ReactElement => {
    const assetType = type || DATA_NOT_FOUND;
    const assetDescription = description || DATA_NOT_FOUND;
    const chainName = chainTitle || DATA_NOT_FOUND;

    return (
        <div className={styles.tableRow}>
            <div className={styles.address}>
                <TooltipWrapper title={address}>
                    <Link className={styles.link} href={url} target="_blank" rel="noreferrer">
                        {address}
                    </Link>
                </TooltipWrapper>
            </div>
            <div className={styles.type}>
                <span className={styles.textCapitalized}>{assetType}</span>
            </div>
            <div className={styles.chainName}>
                <TooltipWrapper title={chainName}>
                    <span>{chainName}</span>
                </TooltipWrapper>
            </div>
            <div className={styles.description}>
                <TooltipWrapper
                    title={
                        <span className={classNames({ [styles.textCapitalized]: type === GOVERNANCE_TYPE })}>
                            {assetDescription}
                        </span>
                    }
                >
                    <span className={classNames({ [styles.textCapitalized]: type === GOVERNANCE_TYPE })}>
                        {assetDescription}
                    </span>
                </TooltipWrapper>
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { Loader, TableDataNotFound } from 'components';
import { TableHeader } from './components';

import { assetsMockUpData } from './mockupData';

import { useAppSelector } from 'store';
import { ProRestrictedBanner } from '../../../../../../../ProRestrictedBanner';

export const AssetsTable: FC = (): ReactElement => {
    const { daoAssets, daoAssetsDataLoading } = useAppSelector(({ organization }) => organization);

    // temporary disabled metadata for non PRO organizations
    const isForbidden = true; // daoAssetsError?.statusCode === 403;

    const tableData = isForbidden ? assetsMockUpData : daoAssets;

    return (
        <>
            {!daoAssetsDataLoading && tableData.length === 0 && (
                <TableDataNotFound forTable overflowMarginsSecondVariant />
            )}

            {daoAssetsDataLoading && tableData.length === 0 && (
                <div className={styles.loaderBox}>
                    <Loader size={40} className={styles.decisionsLoader} />
                </div>
            )}

            {tableData.length > 0 && (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        {isForbidden && <ProRestrictedBanner title="DAO metadata is a DeepDAO Pro feature" />}

                        <TableHeader />

                        {/*{tableData.map((assetItems: IAssetInfo) => (*/}
                        {/*    <AssetRowItems*/}
                        {/*        key={assetItems.address + assetItems.url + assetItems.description}*/}
                        {/*        {...assetItems}*/}
                        {/*    />*/}
                        {/*))}*/}
                    </div>
                </div>
            )}
        </>
    );
};

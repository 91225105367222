import React, { Component, ErrorInfo, ReactNode } from 'react';

import { Button } from '@material-ui/core';

import styles from './styles.module.scss';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

export class ErrorBoundaries extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error('Uncaught error:', error, errorInfo);
    }

    public render(): ReactNode {
        const navigateToDashboard = () => (location.href = '/organizations');

        if (this.state.hasError) {
            return (
                <div className={styles.wrapper}>
                    <h1 className={styles.header}>Oops! Something went wrong...</h1>
                    <Button className={styles.btn} onClick={navigateToDashboard}>
                        BACK TO DASHBOARD
                    </Button>
                </div>
            );
        }

        return this.props.children;
    }
}

import { DEEP_DAO_MAIL } from 'constants/deepDaoLinks';

import { IPageLink } from 'types/interfaces';

const pageLinks: IPageLink[] = [
    {
        id: 1,
        name: 'advertise',
        link: DEEP_DAO_MAIL,
    },
    {
        id: 2,
        name: 'get api',
        link: DEEP_DAO_MAIL,
    },
    {
        id: 3,
        name: 'careers',
        link: DEEP_DAO_MAIL,
    },
];

export { pageLinks };

import React, { FC, ReactElement, useCallback, useState } from 'react';

import { Link } from 'react-router-dom';

import Switch from 'react-switch';

import classNames from 'classnames';

import { ImageLoader, FollowButton, ConfirmationModal } from 'components';
import { FormatUtils, LinkUtils } from 'utils';
import { useAppDispatch } from 'store';
import { SocialFeaturesActions } from 'store/actions';
import { IMAGE_TYPES } from 'constants/imageTypes';
import { switchConfig } from 'constants/switchConfig';

import { IPeopleTableRowProps } from '../../constants';

import styles from './styles.module.scss';

export const TableRow: FC<IPeopleTableRowProps> = ({ item, isFollowersTable }): ReactElement => {
    const dispatch = useAppDispatch();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

    const { logo, name, score, relativeScore, isFollowedBack, memberAddress, enabledNotification = true } = item;
    const userName = name || FormatUtils.formatUserAddress(memberAddress);
    const daoScore = `${score.toFixed(2)} (${relativeScore.toFixed(2)}%)`;

    const handleFollow = () =>
        dispatch(SocialFeaturesActions.followPerson.request({ following: memberAddress, isFollowersTable }));

    const handleUnfollow = () =>
        dispatch(SocialFeaturesActions.unfollowPerson.request({ following: memberAddress, isFollowersTable }));

    const onConfirmRemove = useCallback(() => {
        dispatch(SocialFeaturesActions.removePerson.request({ following: memberAddress }));
        setConfirmationModalOpen(false);
    }, [memberAddress, confirmationModalOpen]);

    const handleSwitch = () =>
        dispatch(
            SocialFeaturesActions.togglePersonNotification.request({
                following: memberAddress,
                enabledNotification: !enabledNotification,
            }),
        );

    const onModalClose = useCallback(() => {
        setConfirmationModalOpen(false);
    }, [confirmationModalOpen]);

    return (
        <>
            <div className={classNames(styles.tableRow, { [styles.followersTableRow]: isFollowersTable })}>
                <div className={styles.people}>
                    <ImageLoader src={logo} className={styles.avatar} imageType={IMAGE_TYPES.USER_IMAGE} />
                    <Link to={LinkUtils.createDynamicLink(memberAddress, 'user')}>{userName}</Link>
                </div>
                <div className={styles.score}>
                    <span>{daoScore}</span>
                </div>
                <div className={styles.following}>
                    <FollowButton
                        handleFollow={handleFollow}
                        handleUnfollow={handleUnfollow}
                        isFollowedBack={isFollowedBack}
                    />
                </div>
                {isFollowersTable ? (
                    <div className={styles.remove} onClick={() => setConfirmationModalOpen(true)}>
                        <button className={styles.removeBtn}></button>
                    </div>
                ) : (
                    <div className={styles.notifications}>
                        <Switch {...switchConfig} checked={enabledNotification} onChange={handleSwitch} />
                    </div>
                )}
            </div>

            {confirmationModalOpen && (
                <ConfirmationModal
                    onClose={onModalClose}
                    confirmationModalOpen={confirmationModalOpen}
                    textInfo={{
                        title: `Are you sure you want to block ${
                            name ? name : FormatUtils.formatUserAddress(memberAddress)
                        }?`,
                        cancelText: 'Cancel',
                        confirmationText: 'Yes',
                    }}
                    onConfirm={onConfirmRemove}
                />
            )}
        </>
    );
};

import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { DeepDaoPartnersRowProps, IDeepDaoPartner } from 'types/interfaces';

export const DeepDaoPartnersRow: FC<DeepDaoPartnersRowProps> = ({
    data,
    className,
}: DeepDaoPartnersRowProps): ReactElement => (
    <div className={classNames(styles.rowWrapper, className)}>
        {data.map(({ id, partnerImg, imgStyles }: IDeepDaoPartner) => (
            <div key={id} className={styles.logoWrapper}>
                <img src={partnerImg} style={imgStyles} alt="" />
            </div>
        ))}
    </div>
);

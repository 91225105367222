export const brainstormInstructions = [
    {
        id: 1,
        title: 'How To Brainstorm',
        text: `Brainstorm is a token based tool for governance. Use it in your DAO to propose ideas and find out what your community thinks about important DAO decisions.<br/><br/>Brainstorm is open for participation by all DAO token holders. Each DAO should use their own governance token.`,
    },
    {
        id: 2,
        title: 'Simplicity, Transparency',
        text: 'Brainstorm is the most intuitive, easy to use governance tool. While only token holders can participate, all brainstorming is open for everyone to see. ',
    },
    {
        id: 3,
        title: 'Make Informed Decisions',
        text: 'Brainstorm is fully embedded within the DeepDAO set of data tools and dashboards. DAO financial information, governance analytics, DAO power dynamics and full member profiles are easily accessible. We encourage you to consider them when you brainstorm. Make informed decisions! ',
    },
];

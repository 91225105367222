import React, { FC, ReactElement } from 'react';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import styles from './styles.module.scss';

import { CATEGORY_TYPE } from 'constants/resourcesConstants';

import { IResourcesCardProps, ICustomResourceCategory } from 'types/interfaces';
import { HrefLink } from 'components';

export const ResourcesCard: FC<IResourcesCardProps> = ({
    resourceData: { name: resourceName, description, logo, main_url, categoriesData },
    findCategory,
}: IResourcesCardProps): ReactElement => {
    const resourceCategories = categoriesData.filter(({ type }: ICustomResourceCategory) => type === CATEGORY_TYPE);

    return (
        <HrefLink href={main_url}>
            <div className={styles.card}>
                <div className={styles.cardHeader}>
                    <img src={logo} className={styles.logo} alt="logo" />
                </div>
                <div className={styles.cardBody}>
                    <div className={styles.mainInfo}>
                        <h4 className={styles.resourceName}>{resourceName}</h4>
                        <div className={styles.resourceCategoriesWrapper}>
                            {resourceCategories.map(({ categoryId }: ICustomResourceCategory) => {
                                const { name } = findCategory(categoryId);

                                return (
                                    <div key={categoryId} className={styles.category}>
                                        {name}
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.descriptionTextContainer}>
                            <span>{description}</span>
                        </div>
                    </div>
                    <div className={styles.arrowContainer}>
                        <ArrowForwardIcon className={styles.arrowIcon} />
                    </div>
                </div>
            </div>
        </HrefLink>
    );
};

import React, { FC, ReactElement, useContext } from 'react';

import styles from './styles.module.scss';

import { SelfListingInput, SelfListingSelect, SelfListingTitle } from 'components';

import { useAppSelector } from 'store';
import { SelfListingContext } from 'context/selfListingContext';

import { ISelfListingElement } from 'types/interfaces';

export const TokenInfo: FC = (): ReactElement => {
    const { selfListingChainsList } = useAppSelector(({ selfListing }) => selfListing);

    const { formik } = useContext(SelfListingContext);

    const onChainSelect = (value: ISelfListingElement) => {
        formik.setFieldValue('token', { ...formik.values.token, chainId: value.id, chainTitle: value.title });
    };

    const onTokenRemove = () => {
        formik.setFieldValue('token', { ...formik.values.token, chainId: null, chainTitle: '' });
    };

    return (
        <div className={styles.tokenInfoWrapper}>
            <SelfListingTitle title="Token" />

            <div className={styles.fieldsWrapper}>
                <SelfListingSelect
                    data={selfListingChainsList}
                    placeholderTitle="Select chain"
                    selectedElementTitle={formik.values.token.chainTitle}
                    onSelect={onChainSelect}
                    error={formik.errors?.token?.chainTitle}
                    onRemove={onTokenRemove}
                />

                <SelfListingInput
                    placeholder="Token Name"
                    name="token.tokenName"
                    value={formik.values.token.tokenName}
                    touched={formik.touched.token?.tokenName}
                    error={formik.errors.token?.tokenName}
                    onChange={formik.handleChange}
                    onTouch={formik.setFieldTouched}
                />

                <SelfListingInput
                    placeholder="Symbol"
                    name="token.tokenSymbol"
                    value={formik.values.token.tokenSymbol}
                    touched={formik.touched.token?.tokenSymbol}
                    error={formik.errors.token?.tokenSymbol}
                    onChange={formik.handleChange}
                    onTouch={formik.setFieldTouched}
                />

                <SelfListingInput
                    placeholder="Token Address"
                    name="token.tokenAddress"
                    value={formik.values.token.tokenAddress}
                    touched={true}
                    error={formik.errors.token?.tokenAddress}
                    onChange={formik.handleChange}
                    onTouch={formik.setFieldTouched}
                />
            </div>
        </div>
    );
};

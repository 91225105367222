import React, { FC, ReactElement } from 'react';

import { useMediaQuery } from '@material-ui/core';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ITableTabsProps, ITabItems } from 'types/interfaces';

export const TableTabs: FC<ITableTabsProps> = ({
    tableTabs: { title, tabsList },
    className,
    tabSelected,
    handleTabChange,
    children,
}: ITableTabsProps): ReactElement => {
    const hideDots = useMediaQuery('(max-width:991.98px)');

    return (
        <div className={styles.tabsContainer}>
            <div className={styles.tabsWrapper}>
                {title && (
                    <div
                        className={classNames(styles.mainTitle, {
                            [styles.titleMarginBottom]: !Boolean(tabsList.length),
                        })}
                    >
                        <span>
                            {title}
                            {tabsList.length && !hideDots ? ':' : ''}
                        </span>
                    </div>
                )}

                {tabsList.length > 0 && (
                    <div className={classNames(styles.tabsItems, className)}>
                        {tabsList.map((tab: ITabItems) => (
                            <div
                                className={classNames(styles.tab, {
                                    [styles.activeTab]: tabSelected.value === tab.value,
                                })}
                                key={tab.id}
                                onClick={() => handleTabChange(tab)}
                            >
                                <span>{tab.text}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {children}
        </div>
    );
};

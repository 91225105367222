import React, { FC, ReactElement, memo } from 'react';

import styles from './styles.module.scss';

import { notificationGreenIcon } from 'assets';
import { showTextByOrganizationStatus } from './utils';

import { ISelfListingReviewCardProps } from 'types/interfaces';

export const SelfListingReviewCard: FC<ISelfListingReviewCardProps> = memo(
    ({ selfListingStatus }: ISelfListingReviewCardProps): ReactElement => (
        <div className={styles.card}>
            <img src={notificationGreenIcon} className={styles.notificationIcon} alt="" />
            <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: showTextByOrganizationStatus(selfListingStatus) }}
            />
        </div>
    ),
);

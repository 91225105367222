import React, { FC, ReactElement } from 'react';

import { CommonTable } from 'components';
import {
    DAO_TREASURY_INVESTMENTS_TABLE_PARAMS,
    daoTreasuryInvestmentProtocolAndTypeTableHeaderData,
} from '../InvestmentsRealData/constants';

import { topInvestmentProtocolsMockupData } from './mockupData';

export const InvestmentsMockupData: FC = (): ReactElement => (
    <>
        <CommonTable
            title="Top investment protocols"
            infoText="Top investment protocols"
            lastUpdate="Jun. 7th, 7:00"
            tableRowsData={topInvestmentProtocolsMockupData}
            loading={false}
            tableHeaderData={daoTreasuryInvestmentProtocolAndTypeTableHeaderData}
            sortedParamName={DAO_TREASURY_INVESTMENTS_TABLE_PARAMS.ORGANIZATIONS_COUNT}
        />
        <CommonTable
            title="Top investment type"
            infoText="Top investment type"
            lastUpdate="Jun. 7th, 7:00"
            tableRowsData={topInvestmentProtocolsMockupData}
            loading={false}
            tableHeaderData={daoTreasuryInvestmentProtocolAndTypeTableHeaderData}
            sortedParamName={DAO_TREASURY_INVESTMENTS_TABLE_PARAMS.ORGANIZATIONS_COUNT}
        />
    </>
);

import React, { FC, ReactElement } from 'react';

import { TooltipProps } from 'recharts';

import styles from './styles.module.scss';

import { FormatUtils } from 'utils';

export const CustomTooltip: FC<TooltipProps<number, string>> = ({
    payload,
}: TooltipProps<number, string>): ReactElement => {
    const { votingTokenPercentage, stablecoinsPercentage, ethBtcPercentage, otherTokensPercentage, date } =
        payload?.[0]?.payload || {};

    return (
        <div className={styles.tooltip}>
            <h6 className={styles.text}>
                Date: <span className={styles.value}>{date}</span>
            </h6>
            <h6 className={styles.text}>
                Voting Token:{' '}
                <span className={styles.value}>{FormatUtils.formatNumberShort(votingTokenPercentage || 0, 2)} %</span>
            </h6>
            <h6 className={styles.text}>
                Stablecoins:{' '}
                <span className={styles.value}>{FormatUtils.formatNumberShort(stablecoinsPercentage || 0, 2)} %</span>
            </h6>
            <h6 className={styles.text}>
                ETH/BTC:{' '}
                <span className={styles.value}>{FormatUtils.formatNumberShort(ethBtcPercentage || 0, 2)} %</span>
            </h6>
            <h6 className={styles.text}>
                Other Tokens:{' '}
                <span className={styles.value}>{FormatUtils.formatNumberShort(otherTokensPercentage || 0, 2)} %</span>
            </h6>
        </div>
    );
};

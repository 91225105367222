import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TooltipWrapper } from 'components';

import { passedIcon, rejectedIcon } from 'assets';
import { FormatUtils } from 'utils';

import { PROPOSAL_STATUS } from 'constants/statuses';

import { IUserProposalRowData } from 'types/interfaces';

export const TableRow: FC<IUserProposalRowData> = ({
    index,
    fundsRequested,
    title,
    createdAt,
    status,
    // successfulVote,
    voteChoice,
    choices,
}: IUserProposalRowData): ReactElement => {
    return (
        <div className={styles.tableRow}>
            <div className={styles.number}>{index + 1}</div>
            <div className={styles.proposalName}>
                <ProposalStatusIcon status={status} />
                <TooltipWrapper title={title}>
                    <span> {title || 'N/A'}</span>
                </TooltipWrapper>
            </div>
            <div className={styles.date}>{FormatUtils.getFormattedDate(createdAt)}</div>
            <div className={styles.fundsRequested}>{fundsRequested || 'N/A'}</div>
            <ProposerChoiceText voteChoice={voteChoice} choices={choices} />
        </div>
    );
};

// const WonProposalThumbIcon = ({ successfulVote }: { successfulVote: boolean | null }) => {
//     let src = null;

//     if (successfulVote) {
//         src = thumbUpIcon;
//     } else if (successfulVote === false) {
//         src = thumbDownIcon;
//     }

//     return <div className={styles.wonVote}>{src ? <img src={src} className={styles.thumbIcon} /> : '--'}</div>;
// };

const ProposalStatusIcon = ({ status }: { status: string }) => {
    let src;

    switch (status) {
        case PROPOSAL_STATUS.ACCEPTED:
            src = passedIcon;
            break;
        case PROPOSAL_STATUS.CANCELLED:
        case PROPOSAL_STATUS.REJECTED:
            src = rejectedIcon;
            break;
        default:
            src = null;
    }

    return src ? <img src={src} className={styles.proposalStatusIcon} alt="" /> : null;
};

const ProposerChoiceText = ({ voteChoice, choices }: { voteChoice: number; choices: string[] }) => {
    const FOR = 'for';
    const AGAINST = 'against';
    let choice = undefined;
    let formattedChoice = undefined;

    if (choices?.length) {
        choice = voteChoice - 1 >= 0 ? choices[voteChoice - 1] : undefined;
        formattedChoice = choice ? `${choice.replace(/^((?:\S+\s+){2}\S+).*/, '')}...` : undefined;
    } else {
        if (voteChoice == 1) {
            choice = FOR;
        }

        if (voteChoice == 0) {
            choice = AGAINST;
        }
    }

    return (
        <div className={styles.voted} title={choice}>
            {formattedChoice || choice || '--'}
        </div>
    );
};

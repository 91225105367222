export const useSideScroll = (element: HTMLDivElement | null, speed: number, distance: number, step: number): void => {
    let scrollAmount = 0;

    if (element) {
        const slideTimer = setInterval(() => {
            element.scrollLeft += step;
            scrollAmount += Math.abs(step);
            if (scrollAmount >= distance) {
                clearInterval(slideTimer);
            }
        }, speed);
    }
};

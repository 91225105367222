import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { COALITIONS_PARAMS } from '../../constants';

import { ITableHeaderWithSortingProps } from 'types/interfaces';

import { HeaderItemWithSorting } from './components/HeaderItemWithSorting';

export const TableHeader: FC<ITableHeaderWithSortingProps> = ({
    sortedParamName,
    sortByParam,
}: ITableHeaderWithSortingProps): ReactElement => {
    return (
        <div className={styles.tableHeader}>
            <div className={styles.number}>
                <div>
                    <span>#</span>
                </div>
            </div>
            <div className={styles.coalitions}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name="coalitions"
                    sortedParamName={sortedParamName}
                    paramName={COALITIONS_PARAMS.COALITIONS_PARAM}
                />
            </div>
            <div className={styles.votedTogether}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`voted together`}
                    sortedParamName={sortedParamName}
                    paramName={COALITIONS_PARAMS.VOTED_TOGETHER_PARAM}
                />
            </div>
            <div className={styles.votingPower}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`voting power`}
                    sortedParamName={sortedParamName}
                    paramName={COALITIONS_PARAMS.VOTING_POWER}
                />
            </div>
            <div className={styles.delegators}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`delegators`}
                    sortedParamName={sortedParamName}
                    paramName={COALITIONS_PARAMS.DELEGATORS_NUMBER}
                />
            </div>
            <div className={styles.quorumPercentage}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`quorum`}
                    sortedParamName={sortedParamName}
                    paramName={COALITIONS_PARAMS.VOTING_POWER_PERCENTAGE}
                />
            </div>
            <div className={styles.circulatingSupplyPercentage}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`circ. supply`}
                    sortedParamName={sortedParamName}
                    paramName={COALITIONS_PARAMS.CIRCULATING_SUPPLY_PERCENTAGE}
                />
            </div>
            <div className={styles.sixMWiningPercentage}>
                <HeaderItemWithSorting
                    sortByParam={sortByParam}
                    name={`6 month winning`}
                    sortedParamName={sortedParamName}
                    paramName={COALITIONS_PARAMS.SIX_M_WINNING_PERCENTAGE}
                />
            </div>
        </div>
    );
};

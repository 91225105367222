import React, { FC, ReactElement, memo } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { FRIENDS_AND_ENEMIES, VERIFIED_DAO_EXPERIENCE } from 'components/UserPageBody/constants';

import { ITabsControllerProps } from 'types/interfaces';

export const TabsController: FC<ITabsControllerProps> = memo(
    ({ tab, changeTab }: ITabsControllerProps): ReactElement => {
        const showActiveTab = (pageTab: string): boolean => {
            return tab === pageTab;
        };

        return (
            <div className={styles.tabsWrapper}>
                <div className={styles.tabsContainer}>
                    <div
                        onClick={() => changeTab(VERIFIED_DAO_EXPERIENCE)}
                        className={classNames(styles.tabItem, {
                            [styles.activeTab]: showActiveTab(VERIFIED_DAO_EXPERIENCE),
                        })}
                    >
                        <div className={styles.itemContent}>
                            <span>Verified DAO Experience</span>
                        </div>
                    </div>

                    <div
                        onClick={() => changeTab(FRIENDS_AND_ENEMIES)}
                        className={classNames(styles.tabItem, {
                            [styles.activeTab]: showActiveTab(FRIENDS_AND_ENEMIES),
                        })}
                    >
                        <div className={styles.itemContent}>
                            <span>Friends & Antagonists</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    },
);

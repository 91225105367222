import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { TableDataNotFound } from 'components';
import { TableHeader, TableRow } from './components';

import { useAppSelector } from 'store';

import { IUserTokenData } from 'types/interfaces';

export const TopTokensValuesTable: FC = (): ReactElement => {
    const tokens = useAppSelector(({ user }) => user.userTokenListData);

    return (
        <div
            className={classNames(styles.tableWrapper, {
                [styles.tableRowsEmpty]: !tokens?.length,
            })}
        >
            <h4 className={styles.tableTitle}>Top tokens held</h4>
            {tokens?.length ? (
                <>
                    <TableHeader />

                    {tokens.map(
                        (
                            { tokenAddress, usd, usdVolumePercent, value, logo, name, symbol }: IUserTokenData,
                            index: number,
                        ) => (
                            <TableRow
                                key={tokenAddress}
                                usd={usd}
                                usdVolumePercent={usdVolumePercent}
                                value={value}
                                logo={logo}
                                name={name}
                                symbol={symbol}
                                index={index}
                            />
                        ),
                    )}
                </>
            ) : (
                <TableDataNotFound forTable removeBoxShadow />
            )}
        </div>
    );
};

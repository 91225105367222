import { ReactNode, ReactElement, ChangeEventHandler, MouseEvent } from 'react';
import { TooltipProps } from 'recharts';

import { GRAPH_TYPES } from 'constants/organizationsGraphNames';
import { STATUSES } from 'constants/statuses';
import { FEED_TYPES } from 'constants/feedTypes';
import { IMAGE_TYPES } from 'constants/imageTypes';
import { CIRCLE_BUTTON_BACKGROUNDS } from 'constants/buttonsContent';

import {
    IAnonymousFunction,
    IOrganizationSearchItems,
    ISearchSelectOptions,
    ISelectOptions,
    ITableTabs,
    OmitPropsId,
    IUserSearchItems,
    ITotalTreasuryAumItems,
    IOrganizationSubstorm,
    INotificationData,
    IRout,
} from 'types/interfaces';
import {
    IChartItem,
    ICustomGradient,
    IStatsChartChanges,
    ITableHeaderItems,
    IOrganizationMembers,
    ILabelItems,
    ITopTokensValuesData,
    ITopTokensList,
    IUserTokensData,
    ITabItems,
    ISupportingLink,
    ILabelsSearchItems,
    IFormFields,
    IDeepDaoPartner,
} from './custom.interfaces';

interface IConnectWalletButtonProps {
    className?: string;
    toggleConnection: () => void;
    loading?: boolean;
}

interface ILoggedUserCircleProps {
    toggleConnection: () => void;
}

interface IMoreRoundBtnBtnProps {
    handleClick?: IAnonymousFunction;
}

interface IStatsCard {
    children?: ReactNode;
    className?: string;
    organizationsCount?: number | null;
    enrichedOrganizationsCount?: number | null;
    lastUpdateDate?: string | null;
    title: string;
    isDashboardPage?: boolean;
    isPeoplePage?: boolean;
    isFeedPage?: boolean;
    externalLink?: boolean;
    mobileBanner?: boolean;
}

interface IStatsGraphCard {
    title: string;
    titleComplement?: string;
    children?: ReactNode;
    graphType: GRAPH_TYPES;
    className?: string;
    titleFontIncreased?: boolean;
    infoIconText?: string | ReactNode;
}

interface IStatsLineGraphProps {
    data: IChartItem[];
    headerData: IStatsLineGraphHeaderProps;
    graphClass?: string;
}

interface ICustomSvgGradientProps {
    id: string;
    rotate?: number;
    data: ICustomGradient[];
}

type IStatsLineGraphHeaderProps = IStatsChartChanges & {
    infoDescription?: string;
    organizationsExtendedInfo?: boolean;
    containerClass?: string;
    headerValues?: ITotalTreasuryAumItems[];
};

interface IStatsBarGraphProps {
    data: IChartItem[];
    headerText?: string;
    options?: {
        xAxisDataKey?: string | undefined;
        yAxisDataKey?: string | undefined;
        xAxisLabel?: string | undefined;
        yAxisLabel?: string | undefined;
        barSize?: number | undefined;
        barLabelStyles?: string;
        xAxisTickStyles?: string;
        yLabelPosition?: any;
        customLabel?: boolean;
        heigth?: number;
        totalValue?: number;
        showPercentage?: boolean;
    };
    className?: string;
}

interface IRechartsCustomizedLabelProps {
    x?: number;
    y?: number;
    offsetY?: number;
    offsetX?: number;
    payload?: {
        value?: any;
    };
    className?: string;
    formatValue?: boolean;
}

interface ITabsControllerProps {
    changeTab(value: string): void;
    tab?: string;
}

type ITabsControlerWithInputProps = ITabsControllerProps & {
    tabSearchValue: string;
    onTabSearch: (inputValue: string) => void;
};

type IOrganizationsTabsController = ITabsControlerWithInputProps & {
    toggleCategoriesOpen: (event: MouseEvent<HTMLElement>) => void;
    loading: boolean;
    categoriesContainerOpen: boolean;
};

type IOrganizationsTabWithInputProps = {
    tabSearchValue: string;
    onTabSearch: (inputValue: string) => void;
};

type IOrganizationsTabController = IOrganizationsTabWithInputProps & {
    toggleCategoriesOpen: (event: MouseEvent<HTMLElement>) => void;
    loading: boolean;
    categoriesContainerOpen: boolean;
};

interface IOrganizationMainTableProps {
    tab: string;
}

interface IProgressBar {
    participation: string;
}

interface ITableHeaderProps {
    headerItems: ITableHeaderItems[];
    className?: string;
}

interface IStatusInfoProps {
    status?: string | null;
    forFeed?: boolean;
    forFeedProposal?: boolean;
    removeForMobile?: boolean;
}

interface ITableRow {
    children: ReactNode;
}

interface IOrganization {
    rank: number;
    organazationName: string;
    treasury: number;
    marketCup: number;
    members: number;
    proposals: number;
    votes: number;
    voterParticipation: number;
    logo: string;
    id: number;
}

interface IOrganizationTokens {
    rank: number;
    tokenName: string;
    balance: number;
    symbol: string;
    oraganizations: number;
    logo: string;
    id: number;
}

interface ITableTabsProps {
    tableTabs: ITableTabs;
    withSearch?: boolean;
    className?: string;
    tabSelected: ITabItems;
    handleTabChange: (selectedTab: ITabItems) => void;
    children?: ReactNode;
}

interface ILabelsContainerProps {
    title: string;
    labels: ILabelItems[];
    children?: ReactNode;
}

interface ISelectOptionsProps {
    setSelectedParam: (paramName: string) => void;
}

interface IFeedCardIconProps {
    type: string;
    className?: string;
}

interface IFeedCardItemsProps {
    id: string;
    userImage: string;
    organizationImage: string;
    username: string;
    organizationName: string;
    status: string | null;
    createdAt: string | null;
    title: string | null;
    description: string | null;
    type: FEED_TYPES;
    organizationLink: string;
    userLink: string;
    proposalType: string;
    mainSiteUrl: string | null;
    children?: ReactNode;
}

interface IActivityCardsProps {
    heading: string;
    subHeading: string;
}

interface IDiscussionModalProps {
    toggleModalOpen: () => void;
}

interface IEditUserDetailsModalProps {
    toggleModalOpen: () => void;
    setUploadedImageSrc: (imageSrc: string) => void;
}

interface ITagLabelsProps {
    labels: ILabelItems[];
    link: string;
}

interface IUserStatsItemsProps {
    statsSums: string;
    statsName: string;
}

type ITopTokensValuesProps = OmitPropsId<ITopTokensValuesData> & { index: number };

interface ITokensInfo {
    title: string;
    tokensList: ITopTokensList[];
}

interface IUserTokensContainerProps {
    title: string;
    subTitle?: string;
    data: IUserTokensData[];
}

interface IPeopleListProps {
    peopleSearchResults: IUserSearchItems[];
}

interface IOrganizationsListProps {
    organizationsSearchResults: IOrganizationSearchItems[];
}

interface ILabelsListProps {
    labelsSearchResults: ILabelsSearchItems[];
}

interface ISelectFilterProps {
    selectedParam: ISearchSelectOptions;
    setSelectedParam: (selectedItem: ISearchSelectOptions) => void;
    setInputValue: (inputValue: string) => void;
}

interface ISearchResultsContainerProps {
    selectedParam: ISearchSelectOptions;
    inputValue: string;
}

interface IInputFieldProps {
    inputValue: string;
    setInputValue: (value: string) => void;
    inputOpen?: boolean;
    setInputOpen?: (setOpen: boolean) => void;
}

interface ITabItemsProps {
    peopleResults: number;
    organizationsResults: number;
    tabItems: ITabItems;
    tabSelected: string;
    setSelectedTab: (tab: string) => void;
}

interface IRightSideMenuProps {
    menuOpen: boolean;
    handleMenuOpen: () => void;
}

interface ISearchFieldProps {
    forTable?: boolean;
    inputValue: string;
    setInputValue: (inputValue: string, preventSearching?: boolean) => void;
    customInputWidth?: number;
    setIsInputOpen?: (inputOpen: boolean) => unknown;
    title?: string;
    customInputStyles?: string;
    customButtonStyles?: string;
}

interface IFeedbackLinksProps {
    links: ISupportingLink[];
}

interface ISearchAutocompleteContainerProps {
    showTopResults: boolean;
}

type ILabelsAutocompleteProps = ISearchAutocompleteContainerProps & {
    setInputValue: (inputValue: string) => void;
    setInputActive: (isActive: boolean) => void;
};

interface ISortIconProps {
    active?: boolean;
}

interface ITableHeaderWithSortingProps {
    sortedParamName: string;
    sortByParam: (param: string) => void;
}

interface ILoaderProps {
    size?: number;
    className?: string;
}

interface IHeaderItemWithSortingProps {
    sortedParamName: string;
    sortByParam: (param: string) => void;
    name: string;
    paramName: string;
    isOrganizationsPage?: boolean;
    isPeoplePage?: boolean;
}

interface ITooltipWrapperProps {
    title?: string | ReactNode;
    interactive?: boolean;
    children: ReactElement<any, any>;
    tooltipClass?: string;
    arrowClass?: string;
    placement?: any;
}

interface IContactFormWrapperProps {
    title: string;
    children: ReactNode;
    description?: string;
}

interface ITopicSelectProps {
    value: string;
    setSelectedValue: (fieldName: string, topic: string) => void;
}

type IFormInputProps = IFormFields & { onChange: ChangeEventHandler<HTMLInputElement>; type?: string };

type IFormTextareaProps = IFormFields & { onChange: ChangeEventHandler<HTMLTextAreaElement> };

interface ITagInputProps {
    defaultTags?: string[];
    onChange?: (tags: string[]) => void;
    placeholder?: string;
    tagMaxLength?: number;
}

interface ISocialInputProps {
    defaultSocialLinks?: SocialLink[];
    onChange?: (tags: SocialLink[]) => void;
}

export enum SocialTypes {
    twitter = 'twitter',
    discord = 'discord',
    github = 'github',
    website = 'website',
    telegram = 'telegram',
    other = 'other',
    blog = 'own_blog',
    youtube = 'youtube',
    url = 'url',
}

type SocialType =
    | SocialTypes.twitter
    | SocialTypes.discord
    | SocialTypes.github
    | SocialTypes.website
    | SocialTypes.other;

interface SocialLink {
    id: number;
    type: SocialType;
    handle: string;
}

interface IPeopleContactSkill {
    id: string;
    name: string;
    is_active: boolean;
}

interface IPeopleContactInterest {
    id: string;
    name: string;
    is_active: boolean;
}

interface ISelectProps {
    options: ISelectOptions[];
    selectedOption?: ISelectOptions;
    onSelect: (option: ISelectOptions) => void;
    error?: string;
    className?: string;
    placeholder?: string;
}

interface ITopTokenData {
    tokenAddress: string;
    logo: string;
    symbol: string;
    name: string;
}

interface ITopTokensItemsProps {
    tokens?: ITopTokenData[];
    showMoreIcon: boolean;
}

interface IFormRadioButtons {
    options: ISelectOptions[];
    selectedOption: ISelectOptions | undefined;
    onChange: (option: ISelectOptions) => void;
    error?: string;
    className?: string;
}

interface IGraphCardHeaderProps {
    title?: string;
    titleComplement?: string;
    className?: string;
    titleFontIncreased?: boolean;
    infoIconText?: string | ReactNode;
}

interface IGraphTabsControllerProps {
    tabSelected: ITabItems;
    onTabChange: (tabItems: ITabItems) => void;
    tabsList: ITabItems[];
    title?: string;
}

interface IDaoElementsCardItemsProps {
    name: string;
    avatarLarge?: boolean;
    link: string;
    logo?: string | null;
    members: number;
}

interface IHrefLinkProps {
    className?: string;
    children: ReactElement;
    href?: string | null;
    disableTarget?: boolean;
}

interface IDeepDaoMember {
    id: number;
    avatar: string;
    name: string;
    role: string;
    link: string | undefined;
}

interface ISellingPoint {
    id: number;
    image: string;
    title: string;
    subTitle: string;
    keysData: string[];
    wrapperClass: string;
    mobileImage: string;
}

interface IDeepDaoMembersSectionProps {
    data: IDeepDaoMember[];
    className?: string;
}

type IAboutSellingPointProps = Omit<ISellingPoint, 'id'>;

interface IMainSocialLinksProps {
    className?: string;
    maxSocials?: number;
}

type ICustomLineGraphTooltipProps = TooltipProps<number, string> & { valueName: string };

interface IImageStatusProps {
    thumbsUp: boolean;
    forFeed: boolean;
    removeForMobile: boolean;
}

interface ITextStatusProps {
    convertedStatus: string;
    originalStatus: STATUSES;
    forFeed: boolean;
    forFeedProposal: boolean;
    removeForMobile: boolean;
}

interface DeepDaoPartnersRowProps {
    data: IDeepDaoPartner[];
    className?: string;
}

interface IAlertProviderProps {
    children: ReactElement;
}

interface ISliderBlockProps {
    data: IDeepDaoPartner[];
}

interface ICarouselWrapperProps {
    children: ReactNode;
    data: unknown[];
    loading: boolean;
    title?: string;
    wrapperClassName?: string;
    containerClassName?: string;
}

interface ITableDataNotFoundProps {
    removeMargins?: boolean;
    forTable?: boolean;
    forGraph?: boolean;
    className?: string;
    overflowMarginsFirstVariant?: boolean;
    overflowMarginsSecondVariant?: boolean;
    widthAuto?: boolean;
    removeBoxShadow?: boolean;
}

interface ICheckboxComponentProps {
    checked: boolean;
    onCheckboxChange: () => void;
}

interface IImageLoaderProps {
    src?: string | null;
    className: string;
    imageType: IMAGE_TYPES;
}

interface ICardDescriptionSectionProps {
    description?: string | null;
}

interface ICircleButtonProps {
    background: CIRCLE_BUTTON_BACKGROUNDS;
    imageSrc: string;
    imageStyles: string;
    title?: string;
    wrapperClass?: string;
    onClick: () => void;
    loading?: boolean;
}

interface IConfirmationModalProps {
    confirmationModalOpen: boolean;
    textInfo: {
        title: string;
        confirmationText: string;
        cancelText?: string;
    };
    onClose: () => void;
    onConfirm: () => void;
}

interface IUserOptionsProps {
    navigateToMyProfile: () => void;
    toggleConnection: () => void;
    navigateToMyFollowings: () => void;
}

interface IConnectWalletProps {
    toggleConnection: () => void;
    token?: string | null;
    active: boolean;
}

interface IBrainstormSelectProps {
    options: ISelectOptions[];
    value: ISelectOptions;
    onSelect: (option: ISelectOptions) => void;
}

interface IBrainstormSubstormSelectProps {
    options: IOrganizationSubstorm[];
    value: IOrganizationSubstorm[];
    onSelect: (option: IOrganizationSubstorm) => void;
    isAllItemsDisabled: boolean;
}
interface IHeaderFollowButtonProps {
    onClick: () => void;
    isFollowing: boolean;
}

interface IHeaderNotificationsProps {
    toggleConnection: () => void;
    isLogged: boolean;
}

interface INotificationsListWrapperProps {
    setNotificationsListOpen: (notificationsListOpen: boolean) => void;
    newNotifications: INotificationData[];
    oldNotifications: INotificationData[];
    hasMoreNotifications: boolean;
    loadMoreNotifications: () => void;
}

interface IHeaderFollowingButtonProps {
    navigateToMyFollowings: () => void;
    isFollowingPageOpen: boolean;
}

interface IDiscussionCommentTextareaProps {
    createComment?: (value: string) => void;
    placeholder: string;
    value: string;
    setValue: (value: string) => void;
    onFocus?: () => void;
    name?: string;
    className?: string;
    brainstormId?: string;
}

type INavItemProps = IRout & {
    menuExpanded: boolean;
};

interface IFeedbackOptionsProps {
    menuExpanded: boolean;
}

interface IHeaderRectangularButtonProps {
    onClick: () => void;
    isButtonActive?: boolean;
    title: string;
}

interface IMainPagesNavItemsProps {
    menuExpanded: boolean;
    className?: string;
}

interface IPlansOptionsProps {
    navigateToPremiumLogin: () => void;
    isUserWithPremiumIsLogged: boolean;
    premiumLogout: () => void;
}

export type {
    IConnectWalletButtonProps,
    IMoreRoundBtnBtnProps,
    INavItemProps,
    IStatsCard,
    IStatsGraphCard,
    IStatsLineGraphProps,
    ICustomSvgGradientProps,
    IStatsLineGraphHeaderProps,
    IStatsBarGraphProps,
    IRechartsCustomizedLabelProps,
    ITabsControllerProps,
    IOrganizationMainTableProps,
    IProgressBar,
    ITableHeaderProps,
    IStatusInfoProps,
    IOrganizationTokens,
    ITableRow,
    IOrganization,
    IOrganizationMembers,
    ITableTabsProps,
    ILabelsContainerProps,
    ISelectOptionsProps,
    IFeedCardIconProps,
    IFeedCardItemsProps,
    IActivityCardsProps,
    IDiscussionModalProps,
    ITagLabelsProps,
    IUserStatsItemsProps,
    ITopTokensValuesProps,
    ITokensInfo,
    IUserTokensContainerProps,
    IPeopleListProps,
    IOrganizationsListProps,
    ISelectFilterProps,
    ISearchResultsContainerProps,
    IInputFieldProps,
    ITabItemsProps,
    IRightSideMenuProps,
    ISearchFieldProps,
    ILabelsListProps,
    IFeedbackLinksProps,
    ILabelsAutocompleteProps,
    ISearchAutocompleteContainerProps,
    ITabsControlerWithInputProps,
    ISortIconProps,
    ITableHeaderWithSortingProps,
    ILoaderProps,
    IHeaderItemWithSortingProps,
    ITooltipWrapperProps,
    IContactFormWrapperProps,
    ITopicSelectProps,
    IFormInputProps,
    IFormTextareaProps,
    IEditUserDetailsModalProps,
    ITagInputProps,
    SocialLink,
    ISocialInputProps,
    IPeopleContactSkill,
    IPeopleContactInterest,
    ISelectProps,
    ITopTokenData,
    ITopTokensItemsProps,
    IGraphCardHeaderProps,
    IGraphTabsControllerProps,
    IDaoElementsCardItemsProps,
    ILoggedUserCircleProps,
    IHrefLinkProps,
    IDeepDaoMember,
    ISellingPoint,
    IDeepDaoMembersSectionProps,
    IAboutSellingPointProps,
    IMainSocialLinksProps,
    ICustomLineGraphTooltipProps,
    IImageStatusProps,
    ITextStatusProps,
    DeepDaoPartnersRowProps,
    IAlertProviderProps,
    ISliderBlockProps,
    ICarouselWrapperProps,
    ITableDataNotFoundProps,
    ICheckboxComponentProps,
    IImageLoaderProps,
    IOrganizationsTabsController,
    ICardDescriptionSectionProps,
    ICircleButtonProps,
    IConfirmationModalProps,
    IUserOptionsProps,
    IConnectWalletProps,
    IFormRadioButtons,
    IBrainstormSelectProps,
    IBrainstormSubstormSelectProps,
    IHeaderNotificationsProps,
    INotificationsListWrapperProps,
    IHeaderFollowButtonProps,
    IHeaderFollowingButtonProps,
    IDiscussionCommentTextareaProps,
    IFeedbackOptionsProps,
    IHeaderRectangularButtonProps,
    IMainPagesNavItemsProps,
    IPlansOptionsProps,
    IOrganizationsTabController,
};

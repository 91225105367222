import React, { FC, ReactElement } from 'react';

import { Link } from '@material-ui/core';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { IHrefLinkProps } from 'types/interfaces';

export const HrefLink: FC<IHrefLinkProps> = ({
    className,
    children,
    href,
    disableTarget = false,
}: IHrefLinkProps): ReactElement => {
    const linkParams = disableTarget
        ? {}
        : {
              target: '_blank',
              rel: 'noreferrer',
          };

    return (
        <Link href={href || undefined} className={classNames(styles.link, className)} {...linkParams}>
            {children}
        </Link>
    );
};

import { call, takeLatest, put, all, select } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { FeedActions } from 'store/actions';
import { FeedApi } from 'store/apis';

import {
    IGetFeedDataAction,
    IGetFeedOrganizationsSearchAction,
    IGetFeedItemsAction,
    IGetFeedItemsParams,
} from 'types/interfaces';

function* getFeedDataWorker({ payload }: IGetFeedDataAction): SagaIterator {
    const { search, organization_id } = payload;

    try {
        const [feedMainInfo, feedOrganizations] = yield all([
            call(FeedApi.getFeedMainInfo),
            call(FeedApi.getFeedOrganizationsSearch, { search, include: organization_id }),
        ]);

        yield put(
            FeedActions.getFeedData.success({
                feedLastUpdateDate: feedMainInfo.data.lastUpdate,
                organizationsCount: feedMainInfo.data.organizationsCount,
                enrichedOrganizationsCount: feedMainInfo.data.enrichedOrganizationsCount,
                feedOrganizations: feedOrganizations.data,
            }),
        );
    } catch (e) {
        yield put(FeedActions.getFeedData.failure(e));
    }
}

function* getFeedOrganizationsSearchWorker({ payload }: IGetFeedOrganizationsSearchAction): SagaIterator {
    try {
        const { data } = yield call(FeedApi.getFeedOrganizationsSearch, payload);
        yield put(FeedActions.getFeedOrganizationsSearch.success(data));
    } catch (e) {
        yield put(FeedActions.getFeedOrganizationsSearch.failure(e));
    }
}

function* getFeedItemsWorker({ payload }: IGetFeedItemsAction): SagaIterator {
    const { withLoadMore, ...payloadData } = payload;

    try {
        const { feedItems } = yield select((store) => store.feed);

        const { data, hasError } = yield call(
            FeedApi.getFeedItems,
            Object.entries(payloadData).reduce(
                (prev, current) => (current[1] !== '' ? { ...prev, [current[0]]: current[1] } : prev),
                {},
            ) as IGetFeedItemsParams,
        );

        if (hasError) {
            yield put(FeedActions.getFeedItems.failure());
        } else {
            let feedData = [];
            if (withLoadMore) {
                feedData = [...feedItems, ...(data?.data || [])];
            } else {
                feedData = data?.data || [];
            }

            yield put(
                FeedActions.getFeedItems.success({ feedItems: feedData, hasMoreDataToLoad: !!data?.data?.length }),
            );
        }
    } catch (e) {
        yield put(FeedActions.getFeedItems.failure(e));
    }
}

function* getFeedStatsDataWorker() {
    try {
        const { data } = yield call(FeedApi.getFeedStatsData);
        yield put(
            FeedActions.getFeedStats.success({
                votesGraphData: data.data.votes,
                decisionsGraphData: data.data.proposals,
                discussionsGraphData: data.data.discourses,
                feedCounters: {
                    discoursesCount: data.data.discoursesCount,
                    proposalsCount: data.data.proposalsCount,
                    votesCount: data.data.votesCount,
                },
            }),
        );
    } catch (e) {
        yield put(FeedActions.getFeedStats.failure(e));
    }
}

function* getFeedActivityDataWorker(): SagaIterator {
    try {
        const {
            data: { data },
        } = yield call(FeedApi.getFeedActivityData);
        yield put(FeedActions.getFeedActivity.success({ feedActivityData: data }));
    } catch (e) {
        yield put(FeedActions.getFeedActivity.failure(e));
    }
}

export const feedSaga = function* (): SagaIterator {
    yield takeLatest(FeedActions.GET_FEED_DATA, getFeedDataWorker);
    yield takeLatest(FeedActions.GET_FEED_ORGANIZATIONS_SEARCH, getFeedOrganizationsSearchWorker);
    yield takeLatest(FeedActions.GET_FEED_ITEMS, getFeedItemsWorker);
    yield takeLatest(FeedActions.GET_FEED_STATS, getFeedStatsDataWorker);
    yield takeLatest(FeedActions.GET_FEED_ACTIVITY, getFeedActivityDataWorker);
};

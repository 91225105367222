import {
    COMMON_TABLE_FIELD_TYPES,
    ICommonTableHeader,
    ICommonTableHeaderSettings,
} from '../../../../../../CommonTable/types';
import {
    IOVICategory,
    IOVINumberOfVoters,
    IOVIOrganizationTitle,
    IOVIPercentageOfVoters,
    IOVIVoterLabel,
} from '../../../../../../../types/interfaces';

export enum ORGANIZATION_VOTER_INTERESTS_PARAMS {
    VOTER_LABEL = 'voterLabel',
    VOTERS = 'numberOfVoters',
    CATEGORY = 'category',
    PERCENTAGE_OF_VOTERS = 'percentageOfVoters',
    ORGANIZATION_TITLE = 'organizationTitle',
}

const tableSettingsTwoItems: ICommonTableHeaderSettings = {
    width: {
        desktop: '50%',
    },
};

const tableSettingsThreeItems: ICommonTableHeaderSettings = {
    width: {
        desktop: '33.3%',
    },
};

const VOTER_LABEL: ICommonTableHeader<IOVIVoterLabel, ORGANIZATION_VOTER_INTERESTS_PARAMS> = {
    title: 'Voter Label',
    rowFieldKey: 'voterLabel',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsTwoItems,
};

const VOTERS: ICommonTableHeader<IOVINumberOfVoters, ORGANIZATION_VOTER_INTERESTS_PARAMS> = {
    title: 'Voters',
    rowFieldKey: 'numberOfVoters',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsTwoItems,
};

const ECOSYSTEM_PARTICIPATION: ICommonTableHeader<IOVIVoterLabel, ORGANIZATION_VOTER_INTERESTS_PARAMS> = {
    title: 'Ecosystem Participation',
    rowFieldKey: 'voterLabel',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsTwoItems,
};

const BREAKDOWN_ECOSYSTEM_CATEGORY: ICommonTableHeader<IOVICategory, ORGANIZATION_VOTER_INTERESTS_PARAMS> = {
    title: 'DAO Category',
    rowFieldKey: 'category',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsTwoItems,
};

const BREAKDOWN_ECOSYSTEM_VOTERS: ICommonTableHeader<IOVINumberOfVoters, ORGANIZATION_VOTER_INTERESTS_PARAMS> = {
    title: 'Voters in Ecosystem (min 5 votes to count)',
    rowFieldKey: 'numberOfVoters',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsThreeItems,
};

const BREAKDOWN_ECOSYSTEM_VOTERS_PERCENTAGE: ICommonTableHeader<
    IOVIPercentageOfVoters,
    ORGANIZATION_VOTER_INTERESTS_PARAMS
> = {
    title: 'Ecosystem Percentage',
    rowFieldKey: 'percentageOfVoters',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsThreeItems,
};

const BREAKDOWN_ORG_CATEGORY: ICommonTableHeader<IOVICategory, ORGANIZATION_VOTER_INTERESTS_PARAMS> = {
    title: 'DAO Category',
    rowFieldKey: 'category',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsThreeItems,
};

const BREAKDOWN_ORG_VOTERS: ICommonTableHeader<IOVINumberOfVoters, ORGANIZATION_VOTER_INTERESTS_PARAMS> = {
    title: 'Voters (min 5 votes to count)',
    rowFieldKey: 'numberOfVoters',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsThreeItems,
};

const BREAKDOWN_ORG_VOTERS_PERCENTAGE: ICommonTableHeader<IOVIPercentageOfVoters, ORGANIZATION_VOTER_INTERESTS_PARAMS> =
    {
        title: 'Ecosystem Percentage',
        rowFieldKey: 'percentageOfVoters',
        fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
        settings: tableSettingsThreeItems,
    };

const COMPETITOR: ICommonTableHeader<IOVIOrganizationTitle, ORGANIZATION_VOTER_INTERESTS_PARAMS> = {
    title: 'Competitor',
    rowFieldKey: 'organizationTitle',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsThreeItems,
};

const COMPETITORS_VOTERS: ICommonTableHeader<IOVINumberOfVoters, ORGANIZATION_VOTER_INTERESTS_PARAMS> = {
    title: 'Voters (min 3 votes to count)',
    rowFieldKey: 'numberOfVoters',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: tableSettingsThreeItems,
};

const addSettingsToTableHeaderData = <T>(
    data: ICommonTableHeader<T, ORGANIZATION_VOTER_INTERESTS_PARAMS>[],
    settings: ICommonTableHeaderSettings,
): ICommonTableHeader<T, ORGANIZATION_VOTER_INTERESTS_PARAMS>[] =>
    data.map((item) => ({
        ...item,
        settings,
    }));

const organizationVIVoterRanksHeaders: ICommonTableHeader<any, ORGANIZATION_VOTER_INTERESTS_PARAMS>[] =
    addSettingsToTableHeaderData(
        [VOTER_LABEL, VOTERS] as ICommonTableHeader<any, ORGANIZATION_VOTER_INTERESTS_PARAMS>[],
        tableSettingsTwoItems,
    );

const organizationVIVoterEcosystemParticipationHeaders: ICommonTableHeader<any, ORGANIZATION_VOTER_INTERESTS_PARAMS>[] =
    addSettingsToTableHeaderData(
        [ECOSYSTEM_PARTICIPATION, VOTERS] as ICommonTableHeader<any, ORGANIZATION_VOTER_INTERESTS_PARAMS>[],
        tableSettingsTwoItems,
    );

const organizationVIBreakdownEcosystemHeaders: ICommonTableHeader<any, ORGANIZATION_VOTER_INTERESTS_PARAMS>[] =
    addSettingsToTableHeaderData(
        [
            BREAKDOWN_ECOSYSTEM_CATEGORY,
            BREAKDOWN_ECOSYSTEM_VOTERS,
            BREAKDOWN_ECOSYSTEM_VOTERS_PERCENTAGE,
        ] as ICommonTableHeader<any, ORGANIZATION_VOTER_INTERESTS_PARAMS>[],
        tableSettingsTwoItems,
    );

const organizationVIBreakdownOrgHeaders: ICommonTableHeader<any, ORGANIZATION_VOTER_INTERESTS_PARAMS>[] =
    addSettingsToTableHeaderData(
        [BREAKDOWN_ORG_CATEGORY, BREAKDOWN_ORG_VOTERS, BREAKDOWN_ORG_VOTERS_PERCENTAGE] as ICommonTableHeader<
            any,
            ORGANIZATION_VOTER_INTERESTS_PARAMS
        >[],
        tableSettingsThreeItems,
    );

const organizationVICompetitorVotersHeaders: ICommonTableHeader<any, ORGANIZATION_VOTER_INTERESTS_PARAMS>[] =
    addSettingsToTableHeaderData(
        [COMPETITOR, COMPETITORS_VOTERS] as ICommonTableHeader<any, ORGANIZATION_VOTER_INTERESTS_PARAMS>[],
        tableSettingsTwoItems,
    );

const organizationVICompetitorTokenHoldersHeaders: ICommonTableHeader<any, ORGANIZATION_VOTER_INTERESTS_PARAMS>[] =
    addSettingsToTableHeaderData(
        [COMPETITOR, COMPETITORS_VOTERS] as ICommonTableHeader<any, ORGANIZATION_VOTER_INTERESTS_PARAMS>[],
        tableSettingsTwoItems,
    );

export {
    organizationVIVoterRanksHeaders,
    organizationVIVoterEcosystemParticipationHeaders,
    organizationVIBreakdownEcosystemHeaders,
    organizationVIBreakdownOrgHeaders,
    organizationVICompetitorVotersHeaders,
    organizationVICompetitorTokenHoldersHeaders,
};

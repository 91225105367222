import { IDaoTreasuryInvestmentProtocolAndType } from 'types/interfaces';

const topInvestmentProtocolsMockupData: IDaoTreasuryInvestmentProtocolAndType[] = [
    {
        organizationCount: 36,
        totalValue: 61094010.42,
        protocolName: 'LIDO',
        protocolType: 'Staking',
        updatedAt: '2023-06-25T13:48:09.000Z',
    },
    {
        organizationCount: 33,
        totalValue: 72703873.22,
        protocolName: 'Uniswap V3',
        protocolType: 'Deposit',
        updatedAt: '2023-06-25T13:48:09.000Z',
    },
    {
        organizationCount: 24,
        totalValue: 13844049,
        protocolName: 'Balancer V2',
        protocolType: 'Lending',
        updatedAt: '2023-06-25T13:48:09.000Z',
    },
    {
        organizationCount: 23,
        totalValue: 18338415.32,
        protocolName: 'Aave V2',
        protocolType: 'Liquidity Pool',
        updatedAt: '2023-06-25T13:48:09.000Z',
    },
    {
        organizationCount: 22,
        totalValue: 20652044.95,
        protocolName: 'Curve',
        protocolType: 'Staking',
        updatedAt: '2023-06-25T13:48:09.000Z',
    },
    {
        organizationCount: 21,
        totalValue: 18245940.57,
        protocolName: 'SushiSwap',
        protocolType: 'Lending',
        updatedAt: '2023-06-25T13:48:09.000Z',
    },
    {
        organizationCount: 19,
        totalValue: 14207007.35,
        protocolName: 'Uniswap V2',
        protocolType: 'Staking',
        updatedAt: '2023-06-25T13:48:09.000Z',
    },
    {
        organizationCount: 16,
        totalValue: 6879134.77,
        protocolName: 'Compound',
        protocolType: 'Lending',
        updatedAt: '2023-06-25T13:48:09.000Z',
    },
    {
        organizationCount: 16,
        totalValue: 7916928.19,
        protocolName: 'Sablier',
        protocolType: 'Staking',
        updatedAt: '2023-06-25T13:48:09.000Z',
    },
    {
        organizationCount: 15,
        totalValue: 97935135.32,
        protocolName: 'Convex',
        protocolType: 'Liquidity Pool',
        updatedAt: '2023-06-25T13:48:09.000Z',
    },
];

export { topInvestmentProtocolsMockupData };

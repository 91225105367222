import React, { FC, ReactElement } from 'react';

import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Label, Cell, LabelList } from 'recharts';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { RechartsCustomizedAxisTick } from 'components';
import { CustomLabel } from './components';

import type { IChartItem, IStatsBarGraphProps } from 'types/interfaces';

export const StatsBarGraph: FC<IStatsBarGraphProps> = ({
    data,
    headerText,
    options,
}: IStatsBarGraphProps): ReactElement => {
    const {
        xAxisDataKey = 'label',
        yAxisDataKey = 'value',
        xAxisLabel,
        yAxisLabel = 'Dao Numbers',
        barSize = 40,
        barLabelStyles,
        xAxisTickStyles,
        yLabelPosition = 'insideLeft',
        customLabel = false,
        heigth = 200,
        totalValue = 0,
        showPercentage = false,
    } = options || {};

    const maxBarValue = data.reduce(
        (prevValue: number, currentValue: IChartItem) =>
            Number(currentValue.value) > prevValue ? Number(currentValue.value) : prevValue,
        0,
    );

    return (
        <div className={styles.wrapper}>
            {headerText && <h6 className={styles.header}>{headerText}</h6>}

            <div className={styles.graph}>
                <ResponsiveContainer height={heigth}>
                    <BarChart data={data} barGap={60}>
                        <XAxis
                            dataKey={xAxisDataKey}
                            tick={
                                <RechartsCustomizedAxisTick
                                    offsetY={10}
                                    className={classNames(styles.xAxisTick, xAxisTickStyles)}
                                />
                            }
                            tickLine={false}
                            stroke="#E5EBEF"
                            interval={0}
                        >
                            {xAxisLabel && (
                                <Label
                                    value={xAxisLabel}
                                    position="insideBottomRight"
                                    dy={9}
                                    style={{ fill: '#FFF', fontSize: 10, fontWeight: 400 }}
                                />
                            )}
                        </XAxis>

                        <YAxis
                            dataKey={yAxisDataKey}
                            tick={false}
                            domain={[0, (dataMax: number) => dataMax * 1.2]}
                            width={20}
                            stroke="#E5EBEF"
                        >
                            <Label
                                value={yAxisLabel}
                                position={yLabelPosition}
                                offset={4}
                                angle={-90}
                                style={{ fill: '#FFF', fontSize: 10, color: '#FFF', fontWeight: 700 }}
                            />
                        </YAxis>

                        <Bar
                            dataKey="value"
                            barSize={barSize}
                            className={styles.bar}
                            radius={[2, 2, 0, 0]}
                            isAnimationActive={false}
                        >
                            {data.map((entry: IChartItem) => (
                                <Cell
                                    key={entry.label}
                                    fill={entry.value === maxBarValue ? '#FFF' : 'rgba(255, 255, 255, 0.3)'}
                                />
                            ))}

                            <LabelList
                                dataKey="value"
                                position="top"
                                offset={10}
                                className={styles.barLabel}
                                content={
                                    customLabel ? (
                                        <CustomLabel
                                            total={totalValue}
                                            barLabelStyles={barLabelStyles}
                                            showPercentage={showPercentage}
                                        />
                                    ) : undefined
                                }
                            />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

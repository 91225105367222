import React, { FC, ReactElement } from 'react';

import { useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';

import styles from './styles.module.scss';

import { pageNotFoundImage } from 'assets';

export const PageNotFound: FC = (): ReactElement => {
    const history = useHistory();

    const navigateToOrganizations = () => {
        history.push({ pathname: '/organizations' });
    };

    return (
        <div className={styles.wrapper}>
            <img src={pageNotFoundImage} className={styles.notFoundImage} alt="" />
            <div className={styles.mainContent}>
                <h1>Sorry about that.</h1>
                <h2>404 is not a dead end.</h2>
                <Button className={styles.navigationBtn} onClick={navigateToOrganizations}>
                    go to homepage
                </Button>
            </div>
        </div>
    );
};

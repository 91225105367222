import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { HeaderItemWithSorting } from 'components';

import { PEOPLE_PARAMS } from 'components/PeopleTables/constants';

import { ITableHeaderWithSortingProps } from 'types/interfaces';

export const TableHeader: FC<ITableHeaderWithSortingProps> = ({
    sortedParamName,
    sortByParam,
}: ITableHeaderWithSortingProps): ReactElement => (
    <div className={styles.tableHeader}>
        <div className={styles.rank}>
            <div>
                <span>G-score ranking</span>
            </div>
        </div>
        <div className={styles.members}>
            <div>
                <span>member</span>
            </div>
        </div>
        <div className={styles.type}>
            <div>
                <span>type</span>
            </div>
        </div>
        <div className={styles.participationScore}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name={`G-score ${'\n'}(absolute / relative)`}
                sortedParamName={sortedParamName}
                paramName={PEOPLE_PARAMS.PARTICIPATION_SCORE_PARAM}
                isPeoplePage
            />
        </div>
        <div className={styles.daos}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name={`# of ${'\n'}daos`}
                sortedParamName={sortedParamName}
                paramName={PEOPLE_PARAMS.DAOS_PARAM}
                isPeoplePage
            />
        </div>
        <div className={styles.votes}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="votes"
                sortedParamName={sortedParamName}
                paramName={PEOPLE_PARAMS.VOTES_PARAM}
                isPeoplePage
            />
        </div>
        <div className={styles.proposals}>
            <HeaderItemWithSorting
                sortByParam={sortByParam}
                name="proposals"
                sortedParamName={sortedParamName}
                paramName={PEOPLE_PARAMS.PROPOSALS_PARAM}
                isPeoplePage
            />
        </div>
        {/* <div className={styles.topTokens}>
            <div>
                <span>top tokens</span>
            </div>
        </div> */}
    </div>
);

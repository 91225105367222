import { DEEP_DAO_MAIL } from 'constants/deepDaoLinks';

import { IPageLink } from 'types/interfaces';

export const footerInnerPageLinks: IPageLink[] = [
    {
        id: 1,
        name: 'About',
        link: '/about',
    },
    {
        id: 2,
        name: 'FAQ',
        link: '/faq',
    },
    {
        id: 3,
        name: 'Feedback',
        link: '/feedback',
    },
];

export const footerMails: IPageLink[] = [
    {
        id: 1,
        name: 'List a DAO',
        link: DEEP_DAO_MAIL,
    },
    {
        id: 2,
        name: 'Advertise',
        link: DEEP_DAO_MAIL,
    },
    {
        id: 3,
        name: 'Contact',
        link: DEEP_DAO_MAIL,
    },
];

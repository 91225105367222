import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { FeedCardIcon } from 'components';

import { FEED_TYPES } from 'constants/feedTypes';

import { useAppSelector } from 'store';

export const FeedProposalDescription: FC = (): ReactElement => {
    const {
        feedProposalData: { title, description },
    } = useAppSelector(({ feedProposal }) => feedProposal);

    return (
        <div className={styles.wrapper}>
            <FeedCardIcon type={FEED_TYPES.PROPOSAL} />

            <div className={styles.textContainer}>
                <h4 className={styles.title}>{title || 'No title available for this proposal'}</h4>
                <h6 className={styles.description}>{description || 'No description available for this proposal'}</h6>
            </div>
        </div>
    );
};

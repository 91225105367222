import { call, takeLatest, put } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import { OrganizationsSearchActions } from 'store/actions';

import { OrganizationsSeacrhApi } from 'store/apis';
import { transformSelectedFiltersData } from 'helpers';
import { DEFAULT_OFFSET_VALUE } from 'constants/organizationsSearch';

import { IGetOrganizationsSearchedDataAction } from 'types/interfaces';

function* getOrganizationsSearchedDataWorker({ payload }: IGetOrganizationsSearchedDataAction): SagaIterator {
    const { selectedFiltersData, offset = DEFAULT_OFFSET_VALUE } = payload;

    try {
        const { data, hasError } = yield call(
            OrganizationsSeacrhApi.getOrganizationsSearchedData,
            transformSelectedFiltersData(selectedFiltersData),
        );
        if (hasError) {
            yield put(OrganizationsSearchActions.getOrganizationsSearchedData.failure());
        } else {
            yield put(
                OrganizationsSearchActions.getOrganizationsSearchedData.success({
                    organizationsSearchedData: data.data,
                    offset,
                }),
            );
        }
    } catch (e) {
        yield put(OrganizationsSearchActions.getOrganizationsSearchedData.failure(e));
    }
}

function* getOrganizationsCategoriesDataWorker(): SagaIterator {
    try {
        const { data, hasError } = yield call(OrganizationsSeacrhApi.getOrganizationsCategoriesData);
        if (hasError) {
            yield put(OrganizationsSearchActions.getOrganizationsCategoriesData.failure());
        } else {
            yield put(
                OrganizationsSearchActions.getOrganizationsCategoriesData.success({
                    organizationsCategoriesData: data.data,
                }),
            );
        }
    } catch (e) {
        yield put(OrganizationsSearchActions.getOrganizationsCategoriesData.failure(e));
    }
}

export const OrganizationsSearchSaga = function* (): SagaIterator {
    yield takeLatest(OrganizationsSearchActions.GET_ORGANIZATIONS_SEARCHED_DATA, getOrganizationsSearchedDataWorker);
    yield takeLatest(
        OrganizationsSearchActions.GET_ORGANIZATIONS_CATEGORIES_DATA,
        getOrganizationsCategoriesDataWorker,
    );
};

import React, { FC, ReactElement, memo } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { TokensVoters } from './components';

import { FormatUtils } from 'utils';
import { greenDolphinIconFor, greyDolphinIconFor, purpleDolphinIconAgainst } from 'assets';

import { ITokensStatsProps } from 'types/interfaces';

export const TokensStats: FC<ITokensStatsProps> = memo(
    ({ title, votedFor, votedAgainst, forComment, tokenVoters }: ITokensStatsProps): ReactElement => {
        const forValuePrevails = votedFor > votedAgainst;
        const againstValuePrevails = votedAgainst > votedFor;
        const valuesEqual = votedFor === votedAgainst;

        return (
            <div className={styles.tokensInfoWrapper}>
                <div
                    className={classNames(styles.imageCircle, {
                        [styles.forValuePrevails]: forValuePrevails,
                        [styles.againstValuePrevails]: againstValuePrevails,
                        [styles.forComment]: forComment,
                    })}
                >
                    {forValuePrevails && <img src={greenDolphinIconFor} alt="" />}
                    {againstValuePrevails && <img src={purpleDolphinIconAgainst} alt="" />}
                    {valuesEqual && <img src={greyDolphinIconFor} alt="" />}
                </div>

                <div className={styles.info}>
                    <h5 className={styles.title}>{title}</h5>
                    <div className={styles.stats}>
                        <div
                            className={classNames({
                                [styles.forValuePrevailsText]: forValuePrevails,
                            })}
                        >
                            {Boolean(tokenVoters?.for?.length) && (
                                <TokensVoters tokenVotersDetails={tokenVoters?.for} />
                            )}
                            <span>up {FormatUtils.formatNumberShort(votedFor, 1, true)}</span>
                        </div>

                        <div
                            className={classNames(styles.withVerticalDivider, {
                                [styles.againstValuePrevailsText]: againstValuePrevails,
                            })}
                        >
                            {Boolean(tokenVoters?.against?.length) && (
                                <TokensVoters tokenVotersDetails={tokenVoters?.against} />
                            )}
                            <span>down {FormatUtils.formatNumberShort(votedAgainst, 1, true)}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    },
);

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { GraphCardHeader, TooltipWrapper, Loader, ImageLoader } from 'components';

import { useAppSelector } from 'store';

import { IActiveFeedElementsCardProps, IFeedHeaderActivityItems } from 'types/interfaces';

export const ActiveFeedElementsCard: FC<IActiveFeedElementsCardProps> = ({
    data,
    title,
    titleComplement,
    fieldName,
    organizationSelect,
    type,
    imageType,
}: IActiveFeedElementsCardProps): ReactElement => {
    const { activityDataLoading } = useAppSelector(({ feed }) => feed);

    return (
        <div className={styles.wrapper}>
            <GraphCardHeader
                title={title}
                titleComplement={titleComplement}
                className={styles.cardHeader}
                titleFontIncreased
            />

            <div className={styles.card}>
                {activityDataLoading ? (
                    <Loader className={styles.loaderBox} size={40} />
                ) : (
                    <div className={styles.cardWrapper}>
                        {data.map(({ id, logo, name, value }: IFeedHeaderActivityItems) => (
                            <div
                                key={id}
                                className={styles.element}
                                onClick={() => organizationSelect({ id, type, name })}
                            >
                                <div className={styles.elementInfo}>
                                    <ImageLoader src={logo} className={styles.elementInfoLogo} imageType={imageType} />
                                    <TooltipWrapper title={name}>
                                        <h5 className={styles.elementInfoName}>{name}</h5>
                                    </TooltipWrapper>
                                </div>
                                <div className={styles.elementActivity}>
                                    <span>{fieldName}</span>
                                    <span>{value}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

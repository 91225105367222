import React, { FC, ReactElement, MouseEvent, useState, useEffect, useContext, useCallback } from 'react';

import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ImageLoader } from 'components';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { SelfListingContext } from 'context/selfListingContext';

export const OrganizationLogoSection: FC = (): ReactElement => {
    const [error, setError] = useState<boolean>(false);

    const { formik } = useContext(SelfListingContext);

    const [uploadedImage, setUploadedImage] = useState<string>(formik.values.contactDetails.organizationLogoUrl);
    const [uploadedFile, setUploadedFile] = useState<any>(formik.values.contactDetails.organizationLogoFile);

    useEffect(() => {
        if (uploadedFile && !uploadedImage) {
            setUploadedImage(URL.createObjectURL(uploadedFile));
        } else {
            setUploadedImage(formik.values.contactDetails.organizationLogoUrl || '');
        }
    }, [formik.values.contactDetails]);

    const handleImageUpload = useCallback(
        (e: any) => {
            if (e.target.files[0].size > 2 * 1024 * 1024) {
                setError(true);
                return;
            } else {
                if (error) {
                    setError(false);
                }
            }

            formik.setFieldValue('contactDetails.organizationLogoFile', e.target.files[0]);
            formik.setFieldValue('contactDetails.organizationLogoUrl', URL.createObjectURL(e.target.files[0]));
            setUploadedImage(URL.createObjectURL(e.target.files[0]));
            setUploadedFile(e.target.files[0]);
        },
        [formik, uploadedFile, formik, error],
    );

    const removeUploadedLogo = useCallback(() => {
        formik.setFieldValue('contactDetails.organizationLogoFile', '');
        formik.setFieldValue('contactDetails.organizationLogoUrl', '');
        setUploadedImage('');
        setUploadedFile('');
    }, [formik, uploadedFile, uploadedImage]);

    const handleClick = useCallback((event: MouseEvent<HTMLInputElement>) => {
        const { target } = event as any;
        if (target?.value) target.value = '';
    }, []);

    return (
        <div className={styles.logoSection}>
            <div className={styles.logoWrapper}>
                {uploadedImage && (
                    <ImageLoader
                        src={uploadedImage}
                        className={styles.organizationLogo}
                        imageType={IMAGE_TYPES.ORGANIZATION_IMAGE}
                    />
                )}
                {uploadedImage && (
                    <Button className={styles.removeLogoButton} onClick={removeUploadedLogo}>
                        <CloseIcon className={styles.closeIcon} />
                    </Button>
                )}
            </div>
            <div className={styles.logoOptions}>
                <div className={styles.logoInfoText}>
                    <h5 className={styles.title}>Add Logo</h5>
                    <h6 className={classNames(styles.notificationText, { [styles.errorText]: error })}>
                        max image size is 2 megabytes
                    </h6>
                </div>

                <div className={styles.imageUploaderWrapper}>
                    <input
                        type="file"
                        className={styles.imageUploaderInput}
                        onChange={handleImageUpload}
                        onClick={handleClick}
                        accept="image/jpeg, image/jpg, image/png, image/svg+xml"
                    />
                    <div className={styles.container}>
                        <span>upload</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { FC, ReactElement, useCallback, useEffect, useRef } from 'react';

import { Router } from 'router';

import io, { Socket } from 'socket.io-client';

import styles from './styles.module.scss';

import { ErrorBoundaries, AppHelmet } from 'components';

import { getAddressFromStorage, getDeepDAOTokenFromStorage } from 'web3/storageHelper';
import { NotificationsSocketEvents } from 'constants/socketEvents';
import { WEBSOCKET_URL } from 'constants/apis';
import { NOTIFICATIONS_ACTIONS_TYPES } from 'constants/notificationsActions';

import { useAppDispatch } from 'store';
import { OrganizationsActions, SearchActions, UserActions } from 'store/actions';
import { Web3ModalWrapper } from 'components/ConnectWallet/Web3ModalContext';

export const App: FC = (): ReactElement => {
    const dispatch = useAppDispatch();
    const socketRef = useRef<Socket>();

    useEffect(() => {
        dispatch(SearchActions.searchTopThree.request());
        dispatch(OrganizationsActions.getLastUpdateInfo.request());
    }, [dispatch]);

    const getUserNotifications = useCallback(() => {
        const token = getDeepDAOTokenFromStorage();
        dispatch(
            UserActions.getUserNotifications.request({
                token,
                notificationsActionType: NOTIFICATIONS_ACTIONS_TYPES.INITIAL_REQUEST,
            }),
        );
    }, [getDeepDAOTokenFromStorage]);

    useEffect(() => getUserNotifications(), [dispatch]);

    useEffect(() => {
        const loggedUserAddress = getAddressFromStorage();
        socketRef.current = io(WEBSOCKET_URL);
        socketRef.current.emit(NotificationsSocketEvents.JOIN_ROOM, loggedUserAddress);

        socketRef.current.on(NotificationsSocketEvents.NOTIFICATED_USER, () => {
            getUserNotifications();
        });

        return () => {
            if (socketRef.current) {
                socketRef.current.emit(NotificationsSocketEvents.LEAVE_ROOM, loggedUserAddress);
                socketRef.current.disconnect();
            }
        };
    }, []);

    return (
        <AppHelmet>
            <div className={styles.pageWrapper}>
                <ErrorBoundaries>
                    <Web3ModalWrapper>
                        <Router />
                    </Web3ModalWrapper>
                </ErrorBoundaries>
            </div>
        </AppHelmet>
    );
};

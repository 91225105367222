import React, { FC, ReactElement, memo } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ISearchedOrganizationFilter } from 'types/interfaces';

interface ISelectsFilterProps {
    filtersData: ISearchedOrganizationFilter[];
    addNewFilter: (filterData: ISearchedOrganizationFilter) => void;
    removeSelectedFilter: (filterId: string) => void;
    selectedFiltersData: ISearchedOrganizationFilter[];
}

export const SelectsFilter: FC<ISelectsFilterProps> = memo(
    ({ selectedFiltersData, filtersData, addNewFilter, removeSelectedFilter }: ISelectsFilterProps): ReactElement => {
        const checkIfFilterIsSelected = (filterData: ISearchedOrganizationFilter): boolean =>
            Boolean(selectedFiltersData.find(({ id }: ISearchedOrganizationFilter) => id === filterData.id));

        const onFilterClick = (filterData: ISearchedOrganizationFilter) => {
            const selectedFilterData = selectedFiltersData.find(
                ({ id }: ISearchedOrganizationFilter) => id === filterData.id,
            );

            selectedFilterData ? removeSelectedFilter(filterData.id) : addNewFilter(filterData);
        };

        return (
            <>
                {filtersData.map((filterData: ISearchedOrganizationFilter) => (
                    <div
                        key={filterData.id}
                        className={classNames(styles.filter, {
                            [styles.filterIsSelected]: checkIfFilterIsSelected(filterData),
                        })}
                        onClick={() => onFilterClick(filterData)}
                    >
                        {filterData.title}
                    </div>
                ))}
            </>
        );
    },
);

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TooltipWrapper } from 'components/index';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { TableHeader } from '../TableHeader';
import { TableRow } from '../TableRow';

import { useAppSelector } from 'store';
import { FormatUtils } from 'utils';

export const VotingActivityTable: FC = (): ReactElement => {
    const {
        organizationActivityData: {
            voteActivity: { avgVotesPerProposal, votersCount, votesCount },
        },
    } = useAppSelector(({ organization }) => organization);

    const totalVotes = FormatUtils.formatExistingNumber(votesCount, 0);
    const totalVoters = FormatUtils.formatExistingNumber(votersCount, 0);
    const votesPerProposal = !FormatUtils.checkIfDataNotExist(avgVotesPerProposal)
        ? FormatUtils.formatNumberWithCommas(FormatUtils.convertToBigIntNumber(avgVotesPerProposal as number), 0)
        : DATA_NOT_FOUND;

    return (
        <>
            <TableHeader>
                <div className={styles.totalVotes}>Total Votes</div>
                <div className={styles.voters}>Voters</div>
                <div className={styles.avgVotes}>Avg. Votes per Proposal</div>
            </TableHeader>
            <TableRow>
                <div className={styles.totalVotes}>{totalVotes}</div>
                <div className={styles.voters}>{totalVoters}</div>
                <div className={styles.avgVotes}>
                    <TooltipWrapper title={votesPerProposal}>
                        <span>{votesPerProposal}</span>
                    </TooltipWrapper>
                </div>
            </TableRow>
        </>
    );
};

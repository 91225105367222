import React, { FC, ReactElement, useEffect } from 'react';

import classNames from 'classnames';

import { ISocialFeaturesState, ITabsControllerProps } from 'types/interfaces';
import { useAppSelector, useAppDispatch } from 'store';
import { SocialFeaturesActions } from 'store/actions';

import { TAB_TYPES } from '../../constants';

import styles from './styles.module.scss';

export const TabsController: FC<ITabsControllerProps> = ({ changeTab, tab }: ITabsControllerProps): ReactElement => {
    const dispatch = useAppDispatch();
    const socialFeatures = useAppSelector(({ socialFeatures }) => socialFeatures);

    useEffect(() => {
        dispatch(SocialFeaturesActions.getFollowingData.request());
    }, []);

    const showActiveTab = (pageTab: string, tab?: string): boolean => pageTab === tab;
    return (
        <div className={styles.tabsWrapper}>
            <div className={styles.tabsContainer}>
                {Object.entries(TAB_TYPES).map(([tabKey, tabItem]) => (
                    <div
                        key={tabKey}
                        className={classNames(styles.tabItem, {
                            [styles.activeTab]: showActiveTab(tabItem, tab),
                        })}
                        onClick={() => tab !== tabItem && changeTab(tabItem)}
                    >
                        {`${socialFeatures[`${tabItem}Amount` as keyof ISocialFeaturesState]} ${tabItem}`}
                    </div>
                ))}
            </div>
        </div>
    );
};

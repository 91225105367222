import React, { FC, ReactElement } from 'react';

import { TooltipProps } from 'recharts';

import styles from './styles.module.scss';

import { FormatUtils } from 'utils';

interface CustomTooltipProps extends TooltipProps<number, string> {
    firstColumn: string;
    firstColumnDataKey: string;
    secondColumn: string;
    secondColumnDataKey: string;
}

export const CustomTooltip: FC<CustomTooltipProps> = ({
    payload,
    firstColumn,
    firstColumnDataKey,
    secondColumn,
    secondColumnDataKey,
}: CustomTooltipProps): ReactElement => {
    const data = payload?.[0]?.payload || {};

    return (
        <div className={styles.tooltip}>
            <h6 className={styles.text}>
                {firstColumn}:{' '}
                <span className={styles.value}>{FormatUtils.formatNumberShort(data[firstColumnDataKey] || 0, 2)}</span>
            </h6>
            <h6 className={styles.text}>
                {secondColumn}:{' '}
                <span className={styles.value}>{FormatUtils.formatNumberShort(data[secondColumnDataKey] || 0, 0)}</span>
            </h6>
        </div>
    );
};

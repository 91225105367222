export enum DAO_TREASURY_INVESTMENTS_TYPES {
    TOP_INVESTMENTS_PROTOCOLS = 'topInvestmentsProtocols',
    TOP_INVESTMENTS_TYPE = 'topInvestmentsType',
    TOP_INVESTMENTS_PROTOCOL_AND_TYPE = 'topInvestmentsProtocolAndType',
    TOP_INVESTMENTS_PROTOCOL_AND_TYPE_BORROWING = 'topInvestmentsProtocolAndTypeBorrowing',
    INDIVIDUAL_ORGANIZATION_INVESTMENTS = 'individualOrganizationInvestments',
    INDIVIDUAL_ORGANIZATION_BORROWS = 'individualOrganizationBorrows',
    TOP_20_INDIVIDUAL_ORGANIZATION_INVESTMENTS = 'top20IndividualOrganizationInvestments',
    TOP_20_INDIVIDUAL_ORGANIZATION_BORROWS = 'top20IndividualOrganizationBorrows',
}

export enum DAO_TREASURY_INVESTMENTS_APIS {
    DAO_TRASURY_INVESTMENTS = 'getDaoTreasuryInvestments',
    DAO_TREASURY_TOP_AUM_ORGANIZATIONS = 'getDaoTreasuryTopAumOrganizations',
    DAO_TREASURY_INVESTMENTS_ASSETS = 'getDaoTreasuryInvestmentsAssets',
}

const DAO_TREASURY_INVESTMENTS_QUERY_PARAMS = {
    [DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_PROTOCOLS]: {
        filterProtocolBy: 'protocol_name',
        orderBy: 'organization_count',
        limit: '10',
    },
    [DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_TYPE]: {
        filterProtocolBy: 'protocol_type',
        orderBy: 'organization_count',
        limit: '10',
    },
    [DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_PROTOCOL_AND_TYPE]: {
        filterProtocolBy: 'protocol_name, protocol_type',
        orderBy: 'organization_count',
        limit: '10',
    },
    [DAO_TREASURY_INVESTMENTS_TYPES.TOP_INVESTMENTS_PROTOCOL_AND_TYPE_BORROWING]: {
        filterProtocolBy: 'protocol_name, protocol_type',
        protocolTypeDetail: 'borrow',
        orderBy: 'organization_count',
        limit: '10',
    },
    [DAO_TREASURY_INVESTMENTS_TYPES.INDIVIDUAL_ORGANIZATION_INVESTMENTS]: {
        filterProtocolBy: 'protocol_name, protocol_type',
        orderBy: 'total_value',
    },
    [DAO_TREASURY_INVESTMENTS_TYPES.INDIVIDUAL_ORGANIZATION_BORROWS]: {
        filterProtocolBy: 'protocol_name, protocol_type',
        orderBy: 'total_value',
        protocolTypeDetail: 'borrow',
    },
    [DAO_TREASURY_INVESTMENTS_TYPES.TOP_20_INDIVIDUAL_ORGANIZATION_INVESTMENTS]: {
        limit: '20',
    },
    [DAO_TREASURY_INVESTMENTS_TYPES.TOP_20_INDIVIDUAL_ORGANIZATION_BORROWS]: {},
};

const DAO_TREASURY_INVESTMENTS_MAX_VISIBLE_FIELDS = 10;

export { DAO_TREASURY_INVESTMENTS_QUERY_PARAMS, DAO_TREASURY_INVESTMENTS_MAX_VISIBLE_FIELDS };

import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { RestrictedBanner } from 'components';
import { FriendsAndEnemiesTable } from './components';
import { useAppSelector } from 'store';

import { IUserFriendsAndEnemiesData } from 'types/interfaces';

const restrictedBannerBulletPoints = [
    'Find people to cooperate with',
    'Explore your network',
    'Find wallets to stay away from',
];

export const UserFriendsAndEnemies: FC = (): ReactElement => {
    const { userEnemiesData, userFriendsData, userFriendsDataLoading, userEnemiesDataLoading, friendsAndEnemiesError } =
        useAppSelector(({ user }) => user);

    const isForbidden = friendsAndEnemiesError?.statusCode === 403;

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.tablesWrapper}>
                    <FriendsAndEnemiesTable
                        title={'Friends'}
                        tooltipText={
                            'The people who vote with you the most times, across all DAOs. They are aligned with you, your DAO friends'
                        }
                        data={userFriendsData.map((items: IUserFriendsAndEnemiesData) => ({
                            ...items,
                            name: items.coVoterName || items.coVoter,
                        }))}
                        isFriendsTable
                        loading={userFriendsDataLoading}
                        isForbidden={isForbidden}
                    />
                    <FriendsAndEnemiesTable
                        title={'Antagonists'}
                        tooltipText={
                            'The people who vote against you the most times, across all DAOs. They are not aligned with you, they are your DAO antagonists'
                        }
                        data={userEnemiesData.map((items: IUserFriendsAndEnemiesData) => ({
                            ...items,
                            name: items.coVoterName || items.coVoter,
                        }))}
                        loading={userEnemiesDataLoading}
                        isForbidden={isForbidden}
                    />
                </div>
            </div>

            {isForbidden && (
                <RestrictedBanner
                    title="Friends & Antagonists is a DeepDAO premium feature"
                    descriptions={['Available only for DAO Analytics subscribers.']}
                    bulletPointsList={restrictedBannerBulletPoints}
                    connectWalletButtonTitle="Subscribe Here"
                />
            )}
        </div>
    );
};

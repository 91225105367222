import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { StatsCard } from 'components';
import { ActivityGraphs, ActiveFeedElementsCard } from './components';

import { PAGES_TYPES } from 'constants/pagesTypes';
import { IMAGE_TYPES } from 'constants/imageTypes';

import { useAppSelector } from 'store';
import { LinkUtils } from 'utils';
import { defaultUserPlaceholderImage } from 'assets';

import { IFeedMember, IFeedOrganization, IDaoFeedPageHeaderProps } from 'types/interfaces';

export const DaoFeedPageHeader: FC<IDaoFeedPageHeaderProps> = ({
    organizationSelect,
}: IDaoFeedPageHeaderProps): ReactElement => {
    const {
        feedLastUpdateDate,
        organizationsCount,
        enrichedOrganizationsCount,
        feedActivityData: { organizations, members },
    } = useAppSelector(({ feed }) => feed);

    return (
        <StatsCard
            organizationsCount={organizationsCount}
            lastUpdateDate={feedLastUpdateDate}
            enrichedOrganizationsCount={enrichedOrganizationsCount}
            title="DAOfeed"
            isFeedPage
            externalLink
            mobileBanner
        >
            <div className={styles.activityInfo}>
                <ActivityGraphs />

                <div className={styles.activityCardsWrapper}>
                    <ActiveFeedElementsCard
                        data={organizations.map(({ id, logo, name, proposalsCount }: IFeedOrganization) => ({
                            id,
                            name,
                            logo: LinkUtils.getOrganizationLogo(logo || ''),
                            value: proposalsCount,
                        }))}
                        title="Most active orgs."
                        titleComplement="Past 7 days"
                        fieldName="Decisions"
                        organizationSelect={organizationSelect}
                        type={PAGES_TYPES.ORGANIZATION}
                        imageType={IMAGE_TYPES.ORGANIZATION_IMAGE}
                    />

                    <ActiveFeedElementsCard
                        data={members.map(({ address, votesCount, name, avatar }: IFeedMember) => ({
                            id: address,
                            logo: avatar || defaultUserPlaceholderImage,
                            name: name || address,
                            value: Number(votesCount),
                        }))}
                        title="Most active people"
                        titleComplement="Past 7 days"
                        fieldName="Votes"
                        organizationSelect={organizationSelect}
                        type={PAGES_TYPES.USER}
                        imageType={IMAGE_TYPES.USER_IMAGE}
                    />
                </div>
            </div>
        </StatsCard>
    );
};

import React, { ReactElement, FC, useEffect, useState } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { SelfListingInput } from 'components';

import { amexImage, masterCardImage, paymentSecureImage, visaImage } from 'assets';
import { PremiumSubscriptionActions } from 'store/actions';
import { useAppDispatch } from 'store';

import { ICreditCardBlockProps } from 'types/interfaces';

export const CreditCardBlock: FC<ICreditCardBlockProps> = ({
    formik,
    showCardFields,
    premiumPFToken,
    children,
    setCreatePlanButtonDisabled,
}: ICreditCardBlockProps): ReactElement => {
    const [ccnError, setCcnError] = useState<string | null>(null);
    const [cvvError, setCvvError] = useState<string | null>(null);
    const [expError, setExpError] = useState<string | null>(null);
    const [ccnTouched, setCcnTouched] = useState<boolean>(false);
    const [cvvTouched, setCvvTouched] = useState<boolean>(false);
    const [expTouched, setExpTouched] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(PremiumSubscriptionActions.getPremiumPFToken.request());

        const interval = setInterval(() => {
            dispatch(PremiumSubscriptionActions.getPremiumPFToken.request());
        }, 60 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    const setFieldTouched = (fieldName: string) => {
        switch (fieldName) {
            case 'ccn': {
                return setCcnTouched(true);
            }
            case 'cvv': {
                return setCvvTouched(true);
            }
            case 'exp': {
                return setExpTouched(true);
            }
        }
    };

    const setFieldError = (fieldName: string, errorCode: string) => {
        switch (fieldName) {
            case 'ccn': {
                return setCcnError(errorCode);
            }
            case 'cvv': {
                return setCvvError(errorCode);
            }
            case 'exp': {
                return setExpError(errorCode);
            }
        }
    };

    const removeFieldError = (fieldName: string) => {
        switch (fieldName) {
            case 'ccn': {
                return setCcnError(null);
            }
            case 'cvv': {
                return setCvvError(null);
            }
            case 'exp': {
                return setExpError(null);
            }
        }
    };

    useEffect(() => {
        setCreatePlanButtonDisabled(
            Boolean(
                ccnError ||
                    cvvError ||
                    expError ||
                    !ccnTouched ||
                    !expTouched ||
                    !cvvTouched ||
                    formik.errors?.creditCardInfo?.cardholderName ||
                    !premiumPFToken,
            ),
        );
    }, [ccnError, cvvError, expError, ccnTouched, expTouched, cvvTouched, showCardFields, formik, premiumPFToken]);

    useEffect(() => {
        if (premiumPFToken) {
            const bsObj = {
                token: premiumPFToken,
                '3DS': true,
                onFieldEventHandler: {
                    onFocus: function (fieldName: string) {
                        setFieldTouched(fieldName);
                    },
                    onError: function (
                        fieldName: string,
                        errorCode: string,
                        errorDescription: string /*, eventOrigin*/,
                    ) {
                        setFieldError(fieldName, errorDescription || errorCode);
                    },

                    onValid: function (fieldName: string) {
                        removeFieldError(fieldName);
                    },
                    onType: function () {
                        return;
                    },
                    onBlur: function () {
                        return;
                    },
                },
                style: {
                    input: {
                        'font-size': '14px',
                        'font-family': '"Inter", sans-serif',
                        color: '#3d536c',
                    },
                    '::placeholder': {
                        color: '#9cb4c2',
                    },
                },
                ccnPlaceHolder: '1234 1234 1234 1234',
                cvvPlaceHolder: 'CVC',
                expPlaceHolder: 'MM/YY',
            };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.bluesnap.hostedPaymentFieldsCreate(bsObj);
        }
    }, [premiumPFToken]);

    return (
        <>
            <div className={styles.block}>
                <div className={styles.header}>
                    <div className={styles.textInfo}>
                        <h5 className={styles.headText}>Payment details</h5>
                        <h6 className={styles.descriptionText}>Enter your credit card information below</h6>
                    </div>

                    <img src={paymentSecureImage} className={styles.paymentSecureImage} alt="" />
                </div>

                {children}
            </div>

            <div
                className={classNames(styles.creditCardBlock, {
                    [styles.creditCardBlockVisible]: showCardFields,
                })}
            >
                <div className={styles.header}>
                    <p>Card Information</p>
                    <div className={styles.cardImagesWrapper}>
                        <img src={visaImage} alt="" />
                        <img src={amexImage} alt="" />
                        <img src={masterCardImage} alt="" />
                    </div>
                </div>
                <div className={styles.creditCardFields}>
                    <div className={styles.fieldWrapper}>
                        <SelfListingInput
                            placeholder="Cardholder name"
                            name="creditCardInfo.cardholderName"
                            value={formik.values.creditCardInfo.cardholderName}
                            touched={formik.touched?.creditCardInfo?.cardholderName}
                            error={formik.errors?.creditCardInfo?.cardholderName}
                            onChange={formik.handleChange}
                            onTouch={formik.setFieldTouched}
                        />
                    </div>

                    <div className={classNames(styles.fieldWrapper, styles.bluesnapFieldWrapper)}>
                        <label className={styles.fieldLabel} htmlFor="ccn" />
                        <div className={styles.bluesnapField} data-bluesnap="ccn"></div>
                        {ccnError && <h6 className={styles.errorText}>{ccnError}</h6>}
                    </div>
                    <div className={styles.groupFieldsWrapper}>
                        <div className={styles.bluesnapFieldWrapper}>
                            <label className={styles.fieldLabel} htmlFor="exp" />
                            <div className={styles.bluesnapField} data-bluesnap="exp"></div>
                            {expError && <h6 className={styles.errorText}>{expError}</h6>}
                        </div>
                        <div className={styles.bluesnapFieldWrapper}>
                            <label className={styles.fieldLabel} htmlFor="cvv" />
                            <div className={styles.bluesnapField} data-bluesnap="cvv"></div>
                            {cvvError && <h6 className={styles.errorText}>{cvvError}</h6>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

import React, { FC, ReactElement, useCallback } from 'react';

import { useMediaQuery } from '@material-ui/core';

import classNames from 'classnames';

import { SearchField } from 'components';
import { FOLLOWING_TABLE_TAB_TYPES } from 'components/FollowingPageBody/constants';

import { ITabsControlerWithInputProps } from 'types/interfaces';

import styles from './styles.module.scss';

type IFollowingTabsControllerProps = ITabsControlerWithInputProps & {
    followingOrganizationsCount: number;
    followingPeopleCount: number;
};

export const TabsController: FC<IFollowingTabsControllerProps> = ({
    changeTab,
    tab,
    tabSearchValue,
    onTabSearch,
    followingPeopleCount,
    followingOrganizationsCount,
}: IFollowingTabsControllerProps): ReactElement => {
    const showActiveTab = (pageTab: FOLLOWING_TABLE_TAB_TYPES, tab?: string): boolean => pageTab === tab;
    const isTablet = useMediaQuery('(max-width:767.98px)');

    const onInputChange = useCallback(
        (inputValue: string) => {
            onTabSearch(inputValue);
        },
        [onTabSearch],
    );

    const onTabChange = useCallback(
        (tabSelected: FOLLOWING_TABLE_TAB_TYPES) => {
            changeTab(tabSelected);
            onTabSearch('');
        },
        [changeTab, onTabSearch],
    );

    const setFollowingCountByKey = useCallback(
        (tab: FOLLOWING_TABLE_TAB_TYPES) => {
            if (tab === FOLLOWING_TABLE_TAB_TYPES.PEOPLE) return followingPeopleCount;
            else return followingOrganizationsCount;
        },
        [followingOrganizationsCount, followingPeopleCount],
    );

    return (
        <div className={styles.tabsWrapper}>
            <div className={styles.tabsContainer}>
                {Object.entries(FOLLOWING_TABLE_TAB_TYPES).map(([tabKey, tabItem]) => (
                    <div
                        key={tabKey}
                        className={classNames(styles.tabItem, {
                            [styles.activeTab]: showActiveTab(tabItem, tab),
                        })}
                        onClick={() => tab !== tabItem && onTabChange(tabItem)}
                    >
                        <div className={styles.itemContent}>
                            <span>{tabItem + ` (${setFollowingCountByKey(tabItem)})`}</span>
                            {!isTablet && showActiveTab(tabItem, tab) && (
                                <SearchField inputValue={tabSearchValue} setInputValue={onInputChange} />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { FormatUtils } from 'utils';

import { IOrganizationStatisticsProps } from 'types/interfaces';

export const OrganizationStatistics: FC<IOrganizationStatisticsProps> = ({
    title,
    amount,
    rank,
    rankChanges,
}: IOrganizationStatisticsProps): ReactElement => (
    <div className={styles.statisticsBlock}>
        <div className={styles.organizationInfo}>
            <p className={styles.sum}>
                {FormatUtils.checkIfDataNotExist(amount)
                    ? DATA_NOT_FOUND
                    : FormatUtils.formatNumberShort(amount as number, 1, true)}
            </p>
            <h6 className={styles.title}>{title}</h6>
        </div>
        <div className={styles.rankingsContainer}>
            <div className={styles.rankInfo}>
                <span>
                    {FormatUtils.checkIfDataNotExist(rank)
                        ? DATA_NOT_FOUND
                        : '#' + FormatUtils.formatNumberShort(rank as number, 1)}
                </span>
                <span>RANK</span>
            </div>
            <div className={styles.ranksChangesInfo}>
                <div className={styles.rankChangesDefault}>
                    {!FormatUtils.checkIfDataNotExist(rank) ? (
                        <>
                            <ArrowUpwardIcon
                                className={classNames(styles.arrowIcon, {
                                    [styles.arrowIconPositive]: Number(rankChanges) > 0,
                                    [styles.arrowIconNegative]: Number(rankChanges) < 0,
                                })}
                            />
                            <span className={styles.rankShift}>{rankChanges}</span>
                        </>
                    ) : (
                        <span className={styles.notFoundText}>{DATA_NOT_FOUND}</span>
                    )}
                </div>
                <span className={styles.period}>1 month</span>
            </div>
        </div>
    </div>
);

import React, { FC, ReactElement, useState } from 'react';

import { TabsController, FollowersData, FollowingData } from './components';

import { TAB_TYPES } from './constants';

export const FollowingPageBody: FC = (): ReactElement => {
    const [tab, setTab] = useState<string>(TAB_TYPES.FOLLOWING);

    const onTabChange = (tabSelected: string) => {
        setTab(tabSelected);
    };

    return (
        <>
            <TabsController changeTab={onTabChange} tab={tab} />
            {tab === TAB_TYPES.FOLLOWING ? <FollowingData /> : <FollowersData />}
        </>
    );
};

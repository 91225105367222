import React, { FC, ReactElement } from 'react';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { FeedbackLinks } from './components';

import { SUPPORTING_PAGES_LINKS } from 'constants/supportingPages';

import { threeDotsIcon } from 'assets';
import { useAppDispatch, useAppSelector } from 'store';
import { SidebarActions } from 'store/actions';

export const FeedbackOptions: FC = (): ReactElement => {
    const { aboutSectionOpen } = useAppSelector(({ sidebar }) => sidebar);
    const dispatch = useAppDispatch();

    const onAboutOpen = () => {
        dispatch({ type: SidebarActions.TOGGLE_ABOUT_OPEN });
    };

    return (
        <div className={styles.wrapper}>
            <div
                className={classNames(styles.container, {
                    [styles.containerOpen]: aboutSectionOpen,
                })}
            >
                <div className={styles.visibleContent} onClick={onAboutOpen}>
                    <div>
                        <img src={threeDotsIcon} className={styles.moreIcon} alt="" />
                        <h6 className={styles.title}>About</h6>
                    </div>
                    <div className={styles.arrowIconWrapper}>
                        <ArrowDropDown className={styles.arrowIcon} />
                    </div>
                </div>
                {aboutSectionOpen && (
                    <div className={styles.menuWrapper}>
                        <FeedbackLinks links={SUPPORTING_PAGES_LINKS} />
                    </div>
                )}
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import styles from './styles.module.scss';

import { FormatUtils } from 'utils';

import { IStatsLineGraphHeaderProps, ITotalTreasuryAumItems } from 'types/interfaces';

export const StatsLineGraphHeader: FC<IStatsLineGraphHeaderProps> = (
    props: IStatsLineGraphHeaderProps,
): ReactElement => {
    const {
        totalAum,
        changeMonth,
        changeWeek,
        infoDescription,
        organizationsExtendedInfo = false,
        containerClass,
        headerValues,
    } = props;

    return (
        <div className={styles.wrapper}>
            <div
                className={classNames(styles.container, containerClass, {
                    [styles.organizationsExtendedInfoContainer]: organizationsExtendedInfo,
                })}
            >
                <div className={styles.countersContainer}>
                    <div className={styles.total}>
                        {infoDescription && <h6 className={styles.infoDescription}>{infoDescription}</h6>}
                        {`$ ${FormatUtils.formatNumberShort(+totalAum || 0, 1)}`}
                    </div>
                    <div className={styles.counterValues}>
                        {headerValues?.map(({ name, value }: ITotalTreasuryAumItems) => (
                            <div key={name}>
                                <h6>{name}</h6>
                                <h6>${FormatUtils.formatNumberShort(value || 0, 1)}</h6>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.changesWrapper}>
                    <div className={styles.changesPerPeriod}>
                        <div>
                            <ArrowForwardOutlinedIcon
                                className={classNames(styles.changesDirectionArrowIcon, {
                                    [styles.changesDirectionArrowIconRise]: changeWeek > 0,
                                    [styles.changesDirectionArrowIconDown]: changeWeek < 0,
                                })}
                            />
                            <span className={styles.changes}>{`$ ${FormatUtils.formatNumberShort(
                                +changeWeek || 0,
                                1,
                            )}`}</span>
                        </div>
                        <span className={styles.period}>1 week</span>
                    </div>
                    <hr />
                    <div className={styles.changesPerPeriod}>
                        <div>
                            <ArrowForwardOutlinedIcon
                                className={classNames(styles.changesDirectionArrowIcon, {
                                    [styles.changesDirectionArrowIconRise]: changeMonth > 0,
                                    [styles.changesDirectionArrowIconDown]: changeMonth < 0,
                                })}
                            />
                            <span className={styles.changes}>{`$ ${FormatUtils.formatNumberShort(
                                +changeMonth || 0,
                                1,
                            )}`}</span>
                        </div>
                        <span className={styles.period}>1 month</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

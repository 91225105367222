enum LISTING_TYPE {
    ADDING = 'ADDING',
    EDITING = 'EDITING',
}

enum SELF_LISTING_STATUSES {
    NOTHING_PROVIDED = 'inverification_nothing_provided',
    TOKEN_PROVIDED = 'inverification_token_provided',
    TREASURY_PROVIDED = 'inverification_treasury_provided',
    GOVERNANCE_PROVIDED = 'inverification_gov_provided',
    TOKEN_TREASURY_PROVIDED = 'inverification_token_treasury_provided',
    TOKEN_GOVERNANCE_PROVIDED = 'inverification_token_gov_provided',
    TREASURY_GOVERNANCE_PROVIDED = 'inverification_treasury_gov_provided',
    ALL_PROVIDED = 'inverification_all_provided',
    VERIFIED = 'verified',
}

export { LISTING_TYPE, SELF_LISTING_STATUSES };

import { FormatUtils } from 'utils';

import { DASH_DASH } from 'constants/dataNotFound';

import { ISortedParam, ITopTokensData } from 'types/interfaces';

export const createPeopleTokensTableData = (peopleTokens: ITopTokensData[]): ITopTokensData[] =>
    peopleTokens
        ? peopleTokens.map(
              ({ usd, logo, totalHolders, name, symbol, tokenAddress }: ITopTokensData, index: number) => ({
                  usd,
                  totalHolders,
                  name,
                  logo,
                  symbol,
                  tokenAddress,
                  rank: index + 1,
              }),
          )
        : [];

export const tableDataSort = <T>(data: T[], paramInfo: ISortedParam, alphabetical = false): T[] => {
    const { paramName, descending } = paramInfo;

    return data.sort((a: T, b: T): number => {
        const aValue = a[paramName as keyof T];
        const bValue = b[paramName as keyof T];

        if ((aValue as unknown) === DASH_DASH) {
            return descending ? 1 : -1;
        } else if ((bValue as unknown) === DASH_DASH) {
            return descending ? -1 : 1;
        } else if (typeof aValue === 'string' && typeof bValue === 'string' && !alphabetical) {
            return descending ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
        } else if (typeof aValue === 'string' && typeof bValue === 'string' && alphabetical) {
            return descending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        } else if (
            (typeof aValue === 'number' || typeof aValue === 'bigint') &&
            (typeof bValue === 'number' || typeof bValue === 'bigint')
        ) {
            if (descending) {
                if (aValue < bValue) {
                    return 1;
                } else if (aValue > bValue) {
                    return -1;
                } else {
                    return 0;
                }
            } else {
                if (aValue > bValue) {
                    return 1;
                } else if (aValue < bValue) {
                    return -1;
                } else {
                    return 0;
                }
            }
        } else if (FormatUtils.checkIfDataNotExist(aValue)) {
            return descending ? 1 : -1;
        } else if (FormatUtils.checkIfDataNotExist(bValue)) {
            return descending ? -1 : 1;
        } else {
            return 0;
        }
    });
};

export const tableSearch = <T extends { name: string | null }>(data: T[], value: string): T[] =>
    value ? data.filter(({ name }: T) => name?.toLowerCase()?.includes(value.toLowerCase())) : data;

import React, { FC, ReactElement, memo } from 'react';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import { TableCell, TooltipWrapper } from 'components';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';
import { FRIENDS_AND_ENEMIES_PARAMS } from '../../constants';

import { FormatUtils, LinkUtils } from 'utils';

import { IFriendsAndEnemiesRowProps } from 'types/interfaces';

export const TableRow: FC<IFriendsAndEnemiesRowProps> = memo(
    ({
        coVoter,
        coVoterDaoScore,
        coVoterName,
        friendlyVotes = 0,
        hostileVotes = 0,
        isFriendsTable,
        sortedParamName,
    }: IFriendsAndEnemiesRowProps): ReactElement => (
        <div className={styles.tableRow}>
            <TableCell isHighlighted={sortedParamName === FRIENDS_AND_ENEMIES_PARAMS.DAO_SCORE}>
                <span>{FormatUtils.formatNumberWithCommas(coVoterDaoScore, 0)}</span>
            </TableCell>
            <TableCell isHighlighted={sortedParamName === FRIENDS_AND_ENEMIES_PARAMS.NAME}>
                <TooltipWrapper title={coVoterName || coVoter}>
                    <Link to={LinkUtils.createDynamicLink(coVoter, 'user')} className={styles.link}>
                        <span>{coVoterName || FormatUtils.formatUserAddress(coVoter)}</span>
                    </Link>
                </TooltipWrapper>
            </TableCell>
            <TableCell
                isHighlighted={Boolean(
                    sortedParamName ===
                        (isFriendsTable
                            ? FRIENDS_AND_ENEMIES_PARAMS.FRIENDLY_VOTES
                            : FRIENDS_AND_ENEMIES_PARAMS.HOSTILE_VOTES),
                )}
            >
                <span>
                    {FormatUtils.formatNumberWithCommas(
                        isFriendsTable ? (friendlyVotes as number) : (hostileVotes as number),
                        0,
                    )}
                </span>
            </TableCell>
            <div>
                <div>
                    <span>{DATA_NOT_FOUND}</span>
                </div>
            </div>
        </div>
    ),
);

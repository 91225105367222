import React, { FC, ReactElement, useState } from 'react';

import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import CloseIcon from '@material-ui/icons/Close';

import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';

import styles from './styles.module.scss';

import { getCroppedImg } from './utils';

import { useAppDispatch } from 'store';
import { UserActions } from 'store/actions';

import { IImageCropModalProps } from 'types/interfaces';

export const ImageCropModal: FC<IImageCropModalProps> = ({
    uploadedImageSrc,
    setUploadedImageSrc,
    peopleContactId,
    address,
}: IImageCropModalProps): ReactElement => {
    const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCropedAreaPixels] = useState({} as Area);

    const dispatch = useAppDispatch();

    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setCropedAreaPixels(croppedAreaPixels);
    };

    const showCroppedImage = async () => {
        try {
            const croppedImage = await getCroppedImg(uploadedImageSrc, croppedAreaPixels, 0);
            setUploadedImageSrc('');
            dispatch(UserActions.updateUserAvatar.request({ userId: peopleContactId, data: croppedImage, address }));
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modal}>
                <div className={styles.modalHeader}>
                    <Button className={styles.closeButton} onClick={() => setUploadedImageSrc('')}>
                        <CloseIcon />
                    </Button>
                </div>
                <div className={styles.modalBody}>
                    <div className={styles.cropContainer}>
                        <Cropper
                            image={uploadedImageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={1 / 1}
                            cropShape="round"
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </div>
                    <div className={styles.controls}>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e, zoom) => setZoom(zoom as number)}
                        />
                    </div>
                    <div className={styles.uploadButtonContainer}>
                        <Button className={styles.uploadButton} onClick={showCroppedImage}>
                            Upload
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

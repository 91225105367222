import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ISelfListingInputProps } from 'types/interfaces/selfListing.interfaces';

export const SelfListingInput: FC<ISelfListingInputProps> = ({
    placeholder,
    name,
    value = '',
    type = 'text',
    onChange,
    onTouch,
    error,
    touched,
    required,
    placeholderCapitalize,
    disabled = false,
    shouldValidate = true,
}: ISelfListingInputProps): ReactElement => {
    const [focused, setFocused] = React.useState(false);

    return (
        <div className={styles.inputWrapper}>
            <input
                className={classNames(styles.input, {
                    [styles.requiredField]: required && !value,
                    [styles.placeholderCapitalize]: placeholderCapitalize,
                })}
                placeholder={placeholder}
                id={name}
                name={name}
                value={value as string}
                type={type}
                onChange={onChange}
                onFocus={() => {
                    onTouch(name, true, shouldValidate);
                    setFocused(true);
                }}
                onBlur={() => setFocused(false)}
                disabled={disabled}
            />
            {required && !value && <span className={styles.asteriskSymbol}>*</span>}

            {error && touched && !focused && <h6 className={styles.error}>{error}</h6>}
        </div>
    );
};

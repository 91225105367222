export enum DISCUSSIONS_NOTIFICATIONS {
    BRAINSTORM = 'To Brainstorm you need to connect your wallet and hold the DAO token',
    COMMENT = 'To comment you need to connect your wallet and hold the DAO token',
    REPLY = 'To reply you need to connect your wallet and hold the DAO token',
    VOTE = 'To vote you need to connect your wallet and hold the DAO token',
}

export enum BRAINSTORM_ACTION_TYPE {
    EDITING = 'EDITING',
    ADDING = 'ADDING',
}

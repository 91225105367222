import { call, takeLatest, put, all, select } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { OrganizationsActions } from 'store/actions';
import { OrganizationApi, OrganizationsApi } from 'store/apis';

import { ORGANIZATIONS_GRAPH_NAMES } from 'constants/organizationsGraphNames';
import { ORGANIZATIONS_PAGINATION_LIMIT, ORGANIZATIONS_PAGINATION_BATCH_SIZE } from 'constants/dashboardPagination';

import { IGetMoreOrganizationsAction } from 'types/interfaces';

function* getOrganizationsDataWorker(): SagaIterator {
    try {
        const { orderBy, paginationOrderDescending } = yield select(({ organizations }) => organizations);

        const [
            organizationsData,
            organizationsTotalTreasury,
            organizationsOverThresholdAum,
            organizationsPotentialVoters,
            organizationsActiveMembers,
            organizationsOverThresholdActiveMembers,
            organizationsCategories,
        ] = yield all([
            call(
                OrganizationsApi.getOrganizationsData,
                0,
                ORGANIZATIONS_PAGINATION_LIMIT,
                orderBy,
                paginationOrderDescending,
            ),
            call(OrganizationsApi.getOrganizationsGraphData, ORGANIZATIONS_GRAPH_NAMES.TOTAL_TREASURY),
            call(OrganizationsApi.getOrganizationsGraphData, ORGANIZATIONS_GRAPH_NAMES.ORGS_THRESHOLD_AUM),
            call(OrganizationsApi.getOrganizationsGraphData, ORGANIZATIONS_GRAPH_NAMES.POTENTIAL_VOTERS),
            call(OrganizationsApi.getOrganizationsGraphData, ORGANIZATIONS_GRAPH_NAMES.ACTIVE_MEMBERS),
            call(OrganizationsApi.getOrganizationsGraphData, ORGANIZATIONS_GRAPH_NAMES.ORGS_THRESHOLD_TOKEN_HOLDERS),
            call(OrganizationsApi.getOrganizationsCategories),
        ]);

        const halfOrganizationsData = organizationsData.data.daosSummary.slice(0, ORGANIZATIONS_PAGINATION_BATCH_SIZE);
        const nextHalfOrganizationsData = organizationsData.data.daosSummary.slice(ORGANIZATIONS_PAGINATION_BATCH_SIZE);

        yield put(
            OrganizationsActions.getOrganizationsData.success({
                createdAt: organizationsData.data.createdAt,
                daoEcosystemOverview: organizationsData.data.daoEcosystemOverview,
                organizationsData: halfOrganizationsData,
                nextOrganizationsData: nextHalfOrganizationsData,
                currentOrganizationsListPage: organizationsData.data.currentOrganizationsListPage,
                isEndOfOrganizationsList: organizationsData.data.isEndOfOrganizationsList,
                organizationsTopTokens: organizationsData.data.tokens,
                organizationsTotalTreasury: organizationsTotalTreasury.data,
                organizationsOverThresholdAum: organizationsOverThresholdAum.data,
                organizationsPotentialVoters: organizationsPotentialVoters.data,
                organizationsActiveMembers: organizationsActiveMembers.data,
                organizationsOverThresholdActiveMembers: organizationsOverThresholdActiveMembers.data,
                organizationsCategories: organizationsCategories.data.data,
            }),
        );
    } catch (e) {
        yield put(OrganizationsActions.getOrganizationsData.failure(e));
    }
}

function* getMoreOrganizationsDataWorker({ payload }: IGetMoreOrganizationsAction): SagaIterator {
    try {
        const { currentOrganizationsListPage, isEndOfOrganizationsList, orderBy, paginationOrderDescending } =
            yield select(({ organizations }) => organizations);

        if (isEndOfOrganizationsList) {
            return;
        }

        const nextPage = payload?.isSorting ? 0 : currentOrganizationsListPage + 1;
        const offset = payload?.isSorting ? 0 : nextPage * ORGANIZATIONS_PAGINATION_LIMIT;
        const response = yield call(
            OrganizationsApi.getOrganizationsData,
            offset,
            ORGANIZATIONS_PAGINATION_LIMIT,
            payload?.orderBy || orderBy,
            payload ? payload?.descending : paginationOrderDescending,
        );

        yield put(
            OrganizationsActions.getMoreOrganizationsData.success({
                ...response.data,
                orderBy: payload?.orderBy || orderBy,
                paginationOrderDescending: payload ? payload?.descending : paginationOrderDescending,
                isSorting: Boolean(payload?.isSorting),
            }),
        );
    } catch (e) {
        yield put(OrganizationsActions.getMoreOrganizationsData.failure(e));
    }
}

function* getLastUpdateInfoWorker(): SagaIterator {
    const { hasError, error, data } = yield call(OrganizationApi.getOrganizationMainInfo);

    if (hasError) yield put(OrganizationsActions.getLastUpdateInfo.failure(error));
    else yield put(OrganizationsActions.getLastUpdateInfo.success(data));
}

export const organizationsSaga = function* (): SagaIterator {
    yield takeLatest(OrganizationsActions.GET_ORGANIZATIONS_DATA, getOrganizationsDataWorker);
    yield takeLatest(OrganizationsActions.GET_MORE_ORGANIZATIONS_DATA, getMoreOrganizationsDataWorker);
    yield takeLatest(OrganizationsActions.GET_LAST_UPDATE_INFO, getLastUpdateInfoWorker);
};

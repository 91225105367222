import React, { FC, ReactElement, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { MAX_DESKTOP_DESCRIPTION_LENGTH, MAX_MOBILE_DESCRIPTION_LENGTH } from './constants';

import { FormatUtils } from 'utils';

import { ICardDescriptionSectionProps } from 'types/interfaces';

export const DescriptionSection: FC<ICardDescriptionSectionProps> = ({
    description = '',
}: ICardDescriptionSectionProps): ReactElement => {
    const [showMore, setShowMore] = useState<boolean>(false);
    const isMobile = useMediaQuery('(max-width:575.98px)');

    const maxDescriptionLength = isMobile ? MAX_MOBILE_DESCRIPTION_LENGTH : MAX_DESKTOP_DESCRIPTION_LENGTH;

    const renderDescription = (description?: string | null) => {
        if (description) {
            if (description.length <= maxDescriptionLength) {
                return description;
            } else {
                return showMore ? description : FormatUtils.truncateString(description, maxDescriptionLength);
            }
        } else {
            return 'No description available';
        }
    };

    return (
        <div className={styles.descriptionSection}>
            <div
                className={classNames(styles.description, {
                    [styles.fullDescription]: showMore,
                })}
                dangerouslySetInnerHTML={{
                    __html: FormatUtils.transformTextToHtml(renderDescription(description), window.location.origin),
                }}
            />

            {(description as string).length >= maxDescriptionLength && !showMore && (
                <div className={styles.expandText} onClick={() => setShowMore(!showMore)}>
                    more
                </div>
            )}
        </div>
    );
};

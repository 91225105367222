import React, { FC, ReactElement, useEffect } from 'react';

import styles from './styles.module.scss';

import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { DELEGATES } from '../../../../../../constants/organizationTabs';
import { PremiumTab } from '../../../../../PremiumTab';
import { OrganizationActions, OrganizationTabsActions } from 'store/actions';
import { ProposalsVotesGraph } from './components/ProposalsVotesGraph';
import { VotingActivity } from './components/VotingActivity';
import { organizationTrendsTableHeaders } from './constants/organization-trends.constants';
import { TrendsTable } from './components/TrendsTable';
import { YearByYearTable } from './components/YearByYearTable';
import { TRENDS_TAB, TRENDS_TAB_DESCRIPTIONS } from '../../../../../../constants';

export const OrganizationVoterTrends: FC = (): ReactElement => {
    const {
        organizationTabs,
        oviYearByYear,
        organizationProposalsTrends,
        organizationSuccessfulProposalsTrends,
        organizationVotesTrends,
        organizationVotersTrends,
        organizationAverageVotesPerProposalTrends,
    } = useAppSelector(({ organizationTabs }) => organizationTabs);
    const isTabBlocked = organizationTabs.data.find((t) => t.tab.name === DELEGATES)?.isBlocked;
    const { organizationData } = useAppSelector(({ organization }) => organization);
    const organizationId = organizationData.id;

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(OrganizationActions.getOrganizationActivityData.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationYearByYearVotes.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationProposalsTrends.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationSuccessfulProposalsTrends.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationVotesTrends.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationVotersTrends.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationAverageVotesPerProposalTrends.request({ organizationId }));
    }, [organizationId]);

    return (
        <div className={styles.container}>
            {isTabBlocked ? (
                <div className={styles.premiumWindow}>
                    <PremiumTab title="Voter Trends is a DeepDAO premium tab for this organization" />
                </div>
            ) : (
                <div className={styles.content}>
                    <div className={styles.mainInfoContent}>
                        <ProposalsVotesGraph />
                        <div className={styles.voterActivity}>
                            <VotingActivity />
                        </div>
                        <div className={styles.yearByYearTable}>
                            <YearByYearTable
                                title="Year by Year Voters"
                                infoText={TRENDS_TAB_DESCRIPTIONS[TRENDS_TAB.YEAR_BY_YEAR]}
                                lastUpdate={oviYearByYear.lastUpdateDate}
                                tableRowsData={oviYearByYear.data}
                                loading={oviYearByYear.loading}
                            />
                        </div>
                    </div>
                    <div className={styles.trendsTablesWrapper}>
                        <TrendsTable
                            title="Proposals"
                            infoText={TRENDS_TAB_DESCRIPTIONS[TRENDS_TAB.OTHER_TABLES]}
                            lastUpdate={organizationProposalsTrends.lastUpdate}
                            tableRowsData={organizationProposalsTrends.data}
                            loading={organizationProposalsTrends.loading}
                            tableHeaderData={organizationTrendsTableHeaders}
                        />

                        <TrendsTable
                            title="Successful Proposals"
                            infoText={TRENDS_TAB_DESCRIPTIONS[TRENDS_TAB.OTHER_TABLES]}
                            lastUpdate={organizationSuccessfulProposalsTrends.lastUpdate}
                            tableRowsData={organizationSuccessfulProposalsTrends.data}
                            loading={organizationSuccessfulProposalsTrends.loading}
                            tableHeaderData={organizationTrendsTableHeaders}
                        />

                        <TrendsTable
                            title="Votes"
                            infoText={TRENDS_TAB_DESCRIPTIONS[TRENDS_TAB.OTHER_TABLES]}
                            lastUpdate={organizationVotesTrends.lastUpdate}
                            tableRowsData={organizationVotesTrends.data}
                            loading={organizationVotesTrends.loading}
                            tableHeaderData={organizationTrendsTableHeaders}
                        />

                        <TrendsTable
                            title="Voters"
                            infoText={TRENDS_TAB_DESCRIPTIONS[TRENDS_TAB.OTHER_TABLES]}
                            lastUpdate={organizationVotersTrends.lastUpdate}
                            tableRowsData={organizationVotersTrends.data}
                            loading={organizationVotersTrends.loading}
                            tableHeaderData={organizationTrendsTableHeaders}
                        />

                        <TrendsTable
                            title="Average Votes per Proposal"
                            infoText={TRENDS_TAB_DESCRIPTIONS[TRENDS_TAB.OTHER_TABLES]}
                            lastUpdate={organizationAverageVotesPerProposalTrends.lastUpdate}
                            tableRowsData={organizationAverageVotesPerProposalTrends.data}
                            loading={organizationAverageVotesPerProposalTrends.loading}
                            tableHeaderData={organizationTrendsTableHeaders}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

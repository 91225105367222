import { createContext } from 'react';

interface IOrganizationContextData {
    mainTabSelected: string;
    changeMainTab: (key: string) => void;
    organizationDataTabSelected: string;
    changeOrganizationDataTab: (key: string) => void;
}

export const OrganizationContext = createContext({} as IOrganizationContextData);

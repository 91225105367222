import React, { FC, ReactElement, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import classNames from 'classnames';

import styles from './styles.module.scss';

import {
    MAX_DESKTOP_DESCRIPTION_LENGTH,
    MAX_MOBILE_DESCRIPTION_LENGTH,
    READ_MORE_TEXT,
    READ_LESS_TEXT,
} from './constants';

import { FormatUtils } from 'utils';

import { ICardDescriptionSectionProps } from 'types/interfaces';

export const CardDescriptionSection: FC<ICardDescriptionSectionProps> = ({
    description,
}: ICardDescriptionSectionProps): ReactElement => {
    const [showMore, setShowMore] = useState<boolean>(false);
    const isMobile = useMediaQuery('(max-width:575.98px)');

    const maxDescriptionLength = isMobile ? MAX_MOBILE_DESCRIPTION_LENGTH : MAX_DESKTOP_DESCRIPTION_LENGTH;

    const renderDescription = (description?: string | null) => {
        if (description) {
            if (description.length <= maxDescriptionLength) {
                return description;
            } else {
                return showMore ? description : FormatUtils.truncateString(description, maxDescriptionLength);
            }
        } else {
            return 'No description available';
        }
    };

    return (
        <>
            <h5
                className={classNames(styles.description, {
                    [styles.fullDescription]: showMore,
                })}
            >
                {renderDescription(description)}
            </h5>
            {description && description.length >= maxDescriptionLength && (
                <div className={styles.expandOptions} onClick={() => setShowMore(!showMore)}>
                    <span className={styles.expandText}>{showMore ? READ_LESS_TEXT : READ_MORE_TEXT}</span>
                    <ExpandMoreIcon
                        className={classNames(styles.expandMoreIcon, {
                            [styles.expandLessIcon]: showMore,
                        })}
                    />
                </div>
            )}
        </>
    );
};

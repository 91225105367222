import React, { FC, ReactElement, useState, useMemo } from 'react';

import { Link } from 'react-router-dom';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { DiscussionElementOptions, ImageLoader } from 'components';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { LinkUtils, FormatUtils } from 'utils';

import { IReplyInfoProps } from 'types/interfaces';

export const ReplyInfo: FC<IReplyInfoProps> = ({
    userLogo,
    text,
    createdAt,
    creator,
    onBrainstormBanClick,
    id,
    commentId,
    idxName,
    ensName,
    idxImageUrl,
    ensImageUrl,
    isReplyingAvailable,
    isDiscussionSingle,
    isUserAdminOrEditor,
    userAddress,
    userName,
}: IReplyInfoProps): ReactElement => {
    const [replyHovered, setReplyHovered] = useState<boolean>(false);

    const replierLink = useMemo(
        () => LinkUtils.createDynamicLink(creator || userAddress, 'user'),
        [creator, userAddress],
    );

    const formattedReplierAddress = useMemo(
        () => FormatUtils.formatUserAddress(creator || userAddress),
        [creator, userAddress],
    );

    const creationDate = useMemo(() => FormatUtils.getFormattedLastUpdateDate(createdAt), [createdAt]);

    return (
        <div className={styles.reply}>
            <div className={styles.replyDetails}>
                <Link to={replierLink} className={styles.link}>
                    <ImageLoader
                        src={userLogo || idxImageUrl || ensImageUrl}
                        className={styles.replierLogo}
                        imageType={IMAGE_TYPES.USER_IMAGE}
                    />
                </Link>

                <div
                    className={styles.replyTextBlock}
                    onMouseEnter={() => !isDiscussionSingle && setReplyHovered(true)}
                    onMouseLeave={() => !isDiscussionSingle && setReplyHovered(false)}
                >
                    <div className={styles.replyTextBlockHeader}>
                        <Link to={replierLink} className={styles.replier}>
                            {userName || idxName || ensName || formattedReplierAddress}
                        </Link>
                        <span className={styles.date}>{creationDate}</span>
                    </div>
                    <div
                        className={classNames(styles.text, {
                            [styles.textMarginRight]: isReplyingAvailable && !isDiscussionSingle && isUserAdminOrEditor,
                        })}
                        dangerouslySetInnerHTML={{
                            __html: FormatUtils.transformTextToHtml(text, window.location.origin),
                        }}
                    />

                    {isReplyingAvailable && !isDiscussionSingle && isUserAdminOrEditor && (
                        <DiscussionElementOptions
                            forComment
                            text="comment"
                            hovered={replyHovered}
                            onRemove={() => onBrainstormBanClick(false, 'reply', { replyId: id, commentId })}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import styles from './styles.module.scss';

import { ITabletSearchContainerProps } from 'types/interfaces';

export const TabletSearchContainer: FC<ITabletSearchContainerProps> = ({
    categoriesContainerOpen,
    onTabSearch,
    tabSearchValue,
    toggleCategoriesOpen,
    loading,
}: ITabletSearchContainerProps): ReactElement => (
    <div className={styles.wrapper}>
        <div className={styles.inputContainer}>
            <input className={styles.input} value={tabSearchValue} onChange={(e) => onTabSearch(e.target.value)} />
            <SearchIcon className={styles.searchIcon} />
        </div>
        <Button
            className={classNames(styles.categoriesButton, {
                [styles.categoriesButtonActive]: categoriesContainerOpen,
            })}
            onClick={(event) => toggleCategoriesOpen(event)}
            disabled={loading}
        >
            categories
            <ArrowDropDown className={styles.arrowIcon} />
        </Button>
    </div>
);

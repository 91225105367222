import React, { FC, ReactElement, useCallback, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { IBrainstormSelectProps, ISelectOptions } from 'types/interfaces';

import { useOutsideClick } from 'hooks';

export const BrainstormSelect: FC<IBrainstormSelectProps> = ({
    options,
    value,
    onSelect,
}: IBrainstormSelectProps): ReactElement => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const selectRef = useRef(null);

    useOutsideClick(selectRef, () => setIsOpen(false));

    const handleItemSelect = useCallback(
        (item: ISelectOptions) => {
            setIsOpen((prevValue) => !prevValue);
            onSelect(item);
        },
        [onSelect, isOpen],
    );

    const items = useMemo(() => {
        return options.filter((item) => item.id !== value.id);
    }, [value, options]);

    return (
        <div className={styles.selectWrapper} ref={selectRef}>
            <div
                className={classNames(styles.select, { [styles.selectActive]: isOpen })}
                onClick={() => setIsOpen((prevValue) => !prevValue)}
            >
                <span className={styles.selectTitle}>{value.name}</span>
                <div className={styles.selectToggleIcon} />
            </div>
            <div className={classNames(styles.selectItems, { [styles.selectItemsOpen]: isOpen })}>
                {items.map((item: ISelectOptions) => (
                    <div className={styles.itemWrapper} onClick={() => handleItemSelect(item)} key={item.id}>
                        {item.name}
                    </div>
                ))}
            </div>
        </div>
    );
};

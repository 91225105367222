import { call, takeLatest, put, all } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { SocialFeaturesActions } from 'store/actions';
import { SocialFeaturesApi } from 'store/apis';
import { IUnfollowAction, IRemoveAction, IToggleNotificationsAction } from 'types/interfaces';

function* getFollowingOrganizationsWorker(): SagaIterator {
    try {
        const { data } = yield call(SocialFeaturesApi.getFollowingOrganizations);
        yield put(SocialFeaturesActions.getFollowingOrganizationsData.success(data));
    } catch (e) {
        yield put(SocialFeaturesActions.getFollowingOrganizationsData.failure(e));
    }
}

function* getFollowingPeopleWorker(): SagaIterator {
    try {
        const { data } = yield call(SocialFeaturesApi.getFollowingPeople);
        yield put(SocialFeaturesActions.getFollowingPeopleData.success(data));
    } catch (e) {
        yield put(SocialFeaturesActions.getFollowingPeopleData.failure(e));
    }
}

function* getFollowedPeopleWorker(): SagaIterator {
    try {
        const { data } = yield call(SocialFeaturesApi.getFollowedPeople);
        yield put(SocialFeaturesActions.getFollowedPeopleData.success(data));
    } catch (e) {
        yield put(SocialFeaturesActions.getFollowedPeopleData.failure(e));
    }
}

function* getFollowingDataWorker(): SagaIterator {
    try {
        const { data } = yield call(SocialFeaturesApi.getFollowingAmountData);
        yield put(SocialFeaturesActions.getFollowingData.success(data));
    } catch (e) {
        yield put(SocialFeaturesActions.getFollowingData.failure(e));
    }
}

function* followPersonWorker({ payload }: IUnfollowAction): SagaIterator {
    const { following, isFollowersTable } = payload;
    try {
        yield call(SocialFeaturesApi.followPerson, following);
        yield put(SocialFeaturesActions.getFollowingData.request());

        if (isFollowersTable) {
            yield put(SocialFeaturesActions.getFollowedPeopleData.request());
        } else {
            yield put(SocialFeaturesActions.followPerson.success(following));
        }
    } catch (e) {
        yield put(SocialFeaturesActions.followPerson.failure(e));
    }
}

function* unfollowPersonWorker({ payload }: IUnfollowAction): SagaIterator {
    const { following, isFollowersTable } = payload;
    try {
        yield call(SocialFeaturesApi.unfollowPerson, following);
        yield put(SocialFeaturesActions.getFollowingData.request());
        if (isFollowersTable) {
            yield put(SocialFeaturesActions.getFollowedPeopleData.request());
        } else {
            yield put(SocialFeaturesActions.unfollowPerson.success(following));
        }
    } catch (e) {
        yield put(SocialFeaturesActions.unfollowPerson.failure(e));
    }
}

function* followOrganizationWorker({ payload }: IUnfollowAction): SagaIterator {
    const { following } = payload;
    try {
        yield call(SocialFeaturesApi.followOrganization, following);
        yield put(SocialFeaturesActions.getFollowingData.request());
        yield put(SocialFeaturesActions.followOrganzation.success(following));
    } catch (e) {
        yield put(SocialFeaturesActions.followOrganzation.failure(e));
    }
}

function* unfollowOrganizationWorker({ payload }: IUnfollowAction): SagaIterator {
    const { following } = payload;
    try {
        yield call(SocialFeaturesApi.unfollowOrganization, following);
        yield put(SocialFeaturesActions.getFollowingData.request());
        yield put(SocialFeaturesActions.unfollowOrganzation.success(following));
    } catch (e) {
        yield put(SocialFeaturesActions.unfollowOrganzation.failure(e));
    }
}

function* removePersonWorker({ payload }: IRemoveAction): SagaIterator {
    const { following } = payload;
    try {
        yield call(SocialFeaturesApi.removePerson, following);
        yield put(SocialFeaturesActions.getFollowedPeopleData.request());
        yield put(SocialFeaturesActions.getFollowingData.request());
    } catch (e) {
        yield put(SocialFeaturesActions.removePerson.failure(e));
    }
}

function* togglePersonNotificationWorker({ payload }: IToggleNotificationsAction): SagaIterator {
    const { following, enabledNotification } = payload;
    try {
        yield call(SocialFeaturesApi.togglePersonNotification, following, enabledNotification);
        yield put(SocialFeaturesActions.togglePersonNotification.success(following));
    } catch (e) {
        yield put(SocialFeaturesActions.togglePersonNotification.failure(e));
    }
}

function* toggleOrganizationNotificationWorker({ payload }: IToggleNotificationsAction): SagaIterator {
    const { following, enabledNotification } = payload;
    try {
        yield call(SocialFeaturesApi.toggleOrganizationNotification, following, enabledNotification);
        yield put(SocialFeaturesActions.toggleOrganizationNotification.success(following));
    } catch (e) {
        yield put(SocialFeaturesActions.toggleOrganizationNotification.failure(e));
    }
}

export const socialFeaturesSaga = function* (): SagaIterator {
    yield all([
        takeLatest(SocialFeaturesActions.GET_FOLLOWING_ORGANIZATIONS, getFollowingOrganizationsWorker),
        takeLatest(SocialFeaturesActions.GET_FOLLOWING_PEOPLE, getFollowingPeopleWorker),
        takeLatest(SocialFeaturesActions.GET_FOLLOWED_PEOPLE, getFollowedPeopleWorker),
        takeLatest(SocialFeaturesActions.GET_FOLLOWING_DATA, getFollowingDataWorker),
        takeLatest(SocialFeaturesActions.FOLLOW_PERSON, followPersonWorker),
        takeLatest(SocialFeaturesActions.UNFOLLOW_PERSON, unfollowPersonWorker),
        takeLatest(SocialFeaturesActions.REMOVE_PERSON, removePersonWorker),
        takeLatest(SocialFeaturesActions.FOLLOW_ORGANIZATION, followOrganizationWorker),
        takeLatest(SocialFeaturesActions.UNFOLLOW_ORGANIZATION, unfollowOrganizationWorker),
        takeLatest(SocialFeaturesActions.TOGGLE_PERSON_NOTIFICATION, togglePersonNotificationWorker),
        takeLatest(SocialFeaturesActions.TOGGLE_ORGANIZATION_NOTIFICATION, toggleOrganizationNotificationWorker),
    ]);
};

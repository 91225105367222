import React from 'react';

export const defaultHead: JSX.Element[] = [
    <title key="title">DeepDAO</title>,
    <meta key="charSet" charSet="utf-8" />,
    <meta key="name-viewport" name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />,
    <meta key="name-theme-color" name="theme-color" content="#000000" />,
    <meta
        key="name-description"
        name="description"
        content="Discovery, listing and analytics for all DAOs"
        data-react-helmet="true"
    />,
    <meta key="itemProp-name" itemProp="name" content="DeepDAO" />,
    <meta key="itemProp-image" itemProp="image" content="https://deepdao.io/websites_socials_image.png" />,
    <meta key="property-og:url" property="og:url" content={window.location.href} />,
    <meta key="property-og:type" property="og:type" content="website" />,
    <meta key="property-og:title" property="og:title" content="DeepDAO" />,
    <meta
        key="property-og:description"
        property="og:description"
        content="Discovery, listing and analytics for all DAOs"
    />,
    <meta key="property-og:image" property="og:image" content="https://deepdao.io/websites_socials_image.png" />,
    <meta key="name-twitter:url" property="twitter:url" content={window.location.href} />,
    <meta key="name-twitter:card" name="twitter:card" content="summary_large_image" />,
    <meta key="name-twitter:title" name="twitter:title" content="DeepDAO" />,
    <meta
        key="name-twitter:description"
        name="twitter:description"
        content="Discovery, listing and analytics for all DAOs"
    />,
    <meta key="name-twitter:image" name="twitter:image" content="https://deepdao.io/websites_socials_image.png" />,
];

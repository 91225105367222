import { VOTING_OPTIONS } from 'constants/votingConstants';

export const setMobileVotingStatus = (votingStatus: VOTING_OPTIONS | null): string | null => {
    switch (votingStatus) {
        case VOTING_OPTIONS.AGAINST: {
            return 'against';
        }
        case VOTING_OPTIONS.FOR: {
            return 'for';
        }
        default:
            return null;
    }
};

enum STATUSES {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    ONGOING = 'ONGOING',
    ACTIVE = 'ACTIVE',
    EXECUTED = 'EXECUTED',
    CLOSED = 'CLOSED',
    PENDING = 'PENDING',
    CANCELLED = 'CANCELLED',
    QUEUED = 'QUEUED',
    NOT_FOUND = 'N/A',
}

const PROPOSAL_STATUS = {
    CREATED: 'CREATED',
    QUEUED: 'QUEUED',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    ACTIVE: 'ACTIVE',
    CANCELLED: 'CANCELLED',
    NA: 'N/A',
};

export { STATUSES, PROPOSAL_STATUS };

import React, { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { LinkUtils } from 'utils';

import { INavItemProps } from 'types/interfaces';

export const NavItem: FC<INavItemProps> = ({
    path,
    menuExpanded,
    name,
    pageImage,
    relatedPaths,
}: INavItemProps): ReactElement => (
    <Link
        to={path}
        className={classNames(styles.linkWrapper, {
            [styles.linksWrapperExpanded]: menuExpanded,
            [styles.linkActive]: LinkUtils.isPathNameEqualsTo(relatedPaths as string[]),
        })}
    >
        <div className={styles.content}>
            <img src={pageImage} className={styles.pageImage} alt="" />
            <h6 className={styles.title}>{name}</h6>
        </div>

        {!menuExpanded && (
            <div className={styles.tooltip}>
                <h6>{name}</h6>
            </div>
        )}
    </Link>
);

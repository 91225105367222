import React, { FC, ReactElement, Fragment } from 'react';

import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { GraphCardHeader } from 'components';

import { FormatUtils } from 'utils';

import { IOrganizationsStatsCardProps, IOrganizationsStatsData } from 'types/interfaces';

export const OrganizationsStatsCard: FC<IOrganizationsStatsCardProps> = ({
    title = '',
    totalTitle,
    statsData,
}: IOrganizationsStatsCardProps): ReactElement => (
    <div className={styles.card}>
        {title && <GraphCardHeader title={title} />}

        <div className={styles.cardBody}>
            <h3 className={styles.total}>
                {FormatUtils.formatNumberShort(statsData.total, 1)}
                <div className={styles.totalTitle}>{totalTitle}</div>
            </h3>
            <div className={styles.statsSection}>
                {statsData.data.map((item: IOrganizationsStatsData, idx: number) => (
                    <Fragment key={item.id}>
                        <div className={styles.statsInfo}>
                            <div className={styles.statsInfoTop}>
                                <ArrowForwardOutlinedIcon
                                    className={classNames(styles.arrowIcon, {
                                        [styles.increaseArrowIcon]: item.value > 0,
                                        [styles.decreaseArrowIcon]: item.value < 0,
                                    })}
                                />
                                <h5 className={styles.aum}>{FormatUtils.formatNumberShort(item.value, 1)}</h5>
                            </div>
                            <span className={styles.period}>{item.period}</span>
                        </div>
                        {idx === 0 && <hr />}
                    </Fragment>
                ))}
            </div>
        </div>
    </div>
);

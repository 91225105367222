import React, { FC, ReactElement, useCallback, useState, useEffect, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import { isEmpty } from 'lodash';

import styles from './styles.module.scss';

import { ConfirmationModal, Loader, SubscriptionInfo } from 'components';

import { plansDetailsList } from 'constants/plansItems';

import { useAppDispatch, useAppSelector } from 'store';
import { PremiumSubscriptionActions } from 'store/actions';
import { getPremiumSubscriptionIdFromStorage } from 'web3/storageHelper';
import { useHistoryPush } from 'hooks';

import { IPremiumPlanDetails } from 'types/interfaces';

export const CancelPremiumSubscriptionPage: FC = (): ReactElement => {
    const { bluesnapSubscriptionLoading, cancelSubscriptionError, premiumUserData, premiumUserDataLoading } =
        useAppSelector(({ premiumSubscription }) => premiumSubscription);

    const subscriptionId = getPremiumSubscriptionIdFromStorage();

    useEffect(() => {
        if (isEmpty(premiumUserData)) dispatch(PremiumSubscriptionActions.getPremiumUserData.request());
    }, []);

    const dispatch = useAppDispatch();

    const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

    const { navigateToAccountSettings } = useHistoryPush();

    const onConfirm = useCallback(() => {
        dispatch(
            PremiumSubscriptionActions.cancelPremiumSubscription.request({
                subscriptionId,
                navigate: navigateToAccountSettings,
                paymentProvider: premiumUserData.paymentProvider,
            }),
        );
        setConfirmationModalOpen(false);
    }, [confirmationModalOpen, history]);

    const onModalClose = useCallback(() => {
        setConfirmationModalOpen(false);
    }, [confirmationModalOpen]);

    const currentPlanDetails = useMemo(() => {
        if (!isEmpty(premiumUserData))
            return (
                plansDetailsList.find(
                    ({ planName }: IPremiumPlanDetails) =>
                        planName.toLowerCase() === premiumUserData.planName.toLowerCase(),
                )?.planInfo || []
            );
        return [];
    }, [premiumUserData]);

    return (
        <>
            {premiumUserDataLoading ? (
                <Loader />
            ) : (
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <Link to="/premium_account_settings" className={styles.link}>
                            <ArrowBackIos className={styles.arrowIcon} />

                            <span className={styles.text}>Settings</span>
                        </Link>
                        <h1 className={styles.title}>
                            Current Plan: <span>{premiumUserData.planName}</span>
                        </h1>

                        <div className={styles.card}>
                            <h5 className={styles.headingText}>Your current benefits:</h5>
                            <div className={styles.benefitsBlock}>
                                {currentPlanDetails.map(({ id, text, linkItems }: any) => (
                                    <SubscriptionInfo id={id} key={id} title={text} linkItems={linkItems} />
                                ))}
                            </div>
                            <div className={styles.optionsBlock}>
                                <Button
                                    className={styles.cancelSubscriptionButton}
                                    onClick={() => setConfirmationModalOpen(true)}
                                >
                                    {bluesnapSubscriptionLoading ? (
                                        <Loader className={styles.loader} size={30} />
                                    ) : (
                                        'cancel subscription'
                                    )}
                                </Button>
                                {cancelSubscriptionError && (
                                    <h6 className={styles.errorText}>{cancelSubscriptionError}</h6>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {confirmationModalOpen && (
                <ConfirmationModal
                    onClose={onModalClose}
                    confirmationModalOpen={confirmationModalOpen}
                    textInfo={{
                        title: 'Are you sure you want to cancel this subscription?',
                        confirmationText: 'Confirm',
                        cancelText: 'Cancel',
                    }}
                    onConfirm={onConfirm}
                />
            )}
        </>
    );
};

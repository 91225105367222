import React, { ReactElement, FC, useEffect } from 'react';
import styles from './styles.module.scss';

import { ShareholderCard, CarouselWrapperWithNewDesign } from './components';

import { useAppDispatch, useAppSelector } from 'store';
import { FormatUtils } from 'utils';

import { IOrganizationHoldingToken, IOrganizationTopShareholder } from 'types/interfaces';
import { PremiumTab } from '../../../../../PremiumTab';
import { OrganizationActions, OrganizationTabsActions } from 'store/actions';
import { TOKEN } from '../../../../../../constants/organizationTabs';
import { VotingTokenTable } from './components/VotingTokenTable';
import { CommonVotingTokenGraph } from './components/CommonVotingTokenGraph';
import { InfoCircle } from '../../../../../InfoCircle';
import { HoldingTokenOrganizationCard } from './components/HoldingTokenOrganizationCard';
import { TOKEN_TAB, TOKEN_TAB_DESCRIPTIONS } from '../../../../../../constants';

export const OrganizationToken: FC = (): ReactElement => {
    const {
        organizationTabs,
        organizationVotingToken,
        organizationAvgVotesAndTokenPriceGraph,
        organizationHoldingToken,
    } = useAppSelector(({ organizationTabs }) => organizationTabs);
    const { organizationTopShareholdersByHoldings, topShareholdersByHoldingsLoading, organizationData } =
        useAppSelector(({ organization }) => organization);
    const isTabBlocked = organizationTabs.data.find((t) => t.tab.name === TOKEN)?.isBlocked;
    const organizationId = organizationData.id;

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(OrganizationActions.getOrganizationTopShareholdersByHoldings.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationVotingToken.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationAvgVotesAndTokenPriceGraph.request({ organizationId }));
        dispatch(OrganizationTabsActions.getOrganizationHoldingToken.request({ organizationId }));
    }, [organizationId]);

    return (
        <div className={styles.container}>
            {isTabBlocked ? (
                <div className={styles.premiumWindow}>
                    <PremiumTab title="Token is a DeepDAO premium tab for this organization" />
                </div>
            ) : (
                <div>
                    <div className={styles.votingToken}>
                        <VotingTokenTable
                            organizationVotingToken={organizationVotingToken.data}
                            lastUpdate={organizationVotingToken.lastUpdate}
                            loading={organizationVotingToken.loading}
                        ></VotingTokenTable>
                    </div>
                    <div className={styles.carouselContainer}>
                        <div className={styles.title}>
                            <h4 className={styles.titleText}>{'Organizations holding token'}</h4>
                            <InfoCircle
                                tooltipText={TOKEN_TAB_DESCRIPTIONS[TOKEN_TAB.ORGANIZATIONS_HOLDING_TOKEN]}
                                className={styles.infoIcon}
                            />
                        </div>
                        <div className={styles.carousel}>
                            <CarouselWrapperWithNewDesign
                                data={organizationHoldingToken.data}
                                loading={organizationHoldingToken.loading}
                                wrapperClassName={styles.carouselWrapper}
                                containerClassName={styles.stakeholdersContainer}
                            >
                                {organizationHoldingToken.data.map((oht: IOrganizationHoldingToken) => (
                                    <HoldingTokenOrganizationCard key={oht.organization.id} {...oht} />
                                ))}
                            </CarouselWrapperWithNewDesign>
                        </div>
                    </div>
                    <div className={styles.carouselContainer}>
                        <div className={styles.title}>
                            <h4 className={styles.titleText}>{'Top Token Holders'}</h4>
                            <InfoCircle
                                tooltipText={TOKEN_TAB_DESCRIPTIONS[TOKEN_TAB.TOP_TOKEN_HOLDERS]}
                                className={styles.infoIcon}
                            />
                        </div>
                        <div className={styles.carousel}>
                            <CarouselWrapperWithNewDesign
                                data={organizationTopShareholdersByHoldings}
                                loading={topShareholdersByHoldingsLoading}
                                wrapperClassName={styles.carouselWrapper}
                                containerClassName={styles.stakeholdersContainer}
                            >
                                {organizationTopShareholdersByHoldings.map(
                                    (
                                        { name, address, avatar, tokenSharesPercentage }: IOrganizationTopShareholder,
                                        index: number,
                                    ) => (
                                        <ShareholderCard
                                            key={index}
                                            name={name}
                                            address={address}
                                            avatar={avatar}
                                            percentage={FormatUtils.formatNumberWithCommas(
                                                tokenSharesPercentage || 0,
                                                1,
                                            )}
                                        />
                                    ),
                                )}
                            </CarouselWrapperWithNewDesign>
                        </div>
                    </div>
                    <div className={styles.avgVotesAndTokenPriceGraph}>
                        <CommonVotingTokenGraph
                            data={organizationAvgVotesAndTokenPriceGraph.data}
                            loading={organizationAvgVotesAndTokenPriceGraph.loading}
                            infoText={TOKEN_TAB_DESCRIPTIONS[TOKEN_TAB.TOKEN_PRICE_AVG_VOTES_PER_PROPOSAL]}
                            flDataKey={'avgPrice'}
                            flName={'Token Price'}
                            slDataKey={'avgVotesPerProposal'}
                            slName={'Avg. Votes per Proposal'}
                        ></CommonVotingTokenGraph>
                    </div>
                    <div className={styles.successfulProposalsAndTokenPriceGraph}>
                        <CommonVotingTokenGraph
                            data={organizationAvgVotesAndTokenPriceGraph.data}
                            loading={organizationAvgVotesAndTokenPriceGraph.loading}
                            infoText={TOKEN_TAB_DESCRIPTIONS[TOKEN_TAB.TOKEN_PRICE_AVG_VOTES_PER_PROPOSAL]}
                            flDataKey={'avgPrice'}
                            flName={'Token Price'}
                            slDataKey={'successfulProposals'}
                            slName={'Successful Proposals'}
                        ></CommonVotingTokenGraph>
                    </div>
                    <div className={styles.votesAndTokenPriceGraph}>
                        <CommonVotingTokenGraph
                            data={organizationAvgVotesAndTokenPriceGraph.data}
                            loading={organizationAvgVotesAndTokenPriceGraph.loading}
                            infoText={TOKEN_TAB_DESCRIPTIONS[TOKEN_TAB.TOKEN_PRICE_AVG_VOTES_PER_PROPOSAL]}
                            flDataKey={'avgPrice'}
                            flName={'Token Price'}
                            slDataKey={'votes'}
                            slName={'Votes'}
                        ></CommonVotingTokenGraph>
                    </div>
                    <div className={styles.proposalsAndTokenPriceGraph}>
                        <CommonVotingTokenGraph
                            data={organizationAvgVotesAndTokenPriceGraph.data}
                            loading={organizationAvgVotesAndTokenPriceGraph.loading}
                            infoText={TOKEN_TAB_DESCRIPTIONS[TOKEN_TAB.TOKEN_PRICE_AVG_VOTES_PER_PROPOSAL]}
                            flDataKey={'avgPrice'}
                            flName={'Token Price'}
                            slDataKey={'proposals'}
                            slName={'Proposals'}
                        ></CommonVotingTokenGraph>
                    </div>
                </div>
            )}
        </div>
    );
};

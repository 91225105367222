import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { HrefLink } from 'components';
import { NewsLetterForm } from './components';

import { DEEP_DAO_MAIL } from 'constants/deepDaoLinks';
import { pageLinks } from './constants';

import { arrowIconGreen } from 'assets';

import { IPageLink } from 'types/interfaces';

export const Footer: FC = (): ReactElement => (
    <footer className={styles.footerWrapper}>
        <div className={styles.footerContainer}>
            <div className={styles.contact}>
                <div className={styles.contactInfo}>
                    <h3 className={styles.heading}>Be in touch</h3>
                    <h6>We’re happy to hear from you</h6>
                    <HrefLink href={DEEP_DAO_MAIL} disableTarget={true}>
                        <span className={styles.mail}>info@deepdao.io</span>
                    </HrefLink>
                </div>

                <NewsLetterForm />
            </div>

            <div className={styles.pageLinksContainer}>
                {pageLinks.map(({ id, name, link }: IPageLink) => (
                    <div key={id} className={styles.linkWrapper}>
                        <HrefLink href={link} className={styles.linkContent} disableTarget>
                            <>
                                <span className={styles.pageName}>{name}</span>
                                <img src={arrowIconGreen} className={styles.arrowIcon} alt="" />
                            </>
                        </HrefLink>
                    </div>
                ))}
            </div>
        </div>
    </footer>
);

import React, { FC, ReactElement, useEffect, useState, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { TabsController, UserFriendsAndEnemies, UserVerifiedDaoExperience } from './components';

import { FRIENDS_AND_ENEMIES, TABS_TYPES, VERIFIED_DAO_EXPERIENCE } from './constants';

import { mixpanelService } from 'services';

import { IParamType, IUserPageBodyProps } from 'types/interfaces';

export const UserPageBody: FC<IUserPageBodyProps> = ({
    isAuthenticatedUser,
    address,
}: IUserPageBodyProps): ReactElement => {
    const { tab }: IParamType = useParams();
    const [tabSelected, setTabSelected] = useState(TABS_TYPES[tab] ? tab : VERIFIED_DAO_EXPERIENCE);

    useEffect(() => {
        window.history.replaceState(null, '', tabSelected);
    }, [tabSelected]);

    const changeTab = (key: string) => {
        setTabSelected(key);

        if (key === FRIENDS_AND_ENEMIES) {
            mixpanelService.track(mixpanelService.eventsGenerator.friendsAndEnemies());
            mixpanelService.track(mixpanelService.eventsGenerator.friendsAndEnemiesByAddress(address as string));
        }
    };

    const renderUserComponent = useCallback(() => {
        switch (tabSelected) {
            case VERIFIED_DAO_EXPERIENCE:
                return <UserVerifiedDaoExperience isAuthenticatedUser={isAuthenticatedUser} />;

            case FRIENDS_AND_ENEMIES:
                return <UserFriendsAndEnemies />;

            default:
                return <UserVerifiedDaoExperience isAuthenticatedUser={isAuthenticatedUser} />;
        }
    }, [tabSelected]);

    return (
        <>
            <TabsController tab={tabSelected} changeTab={changeTab} />
            <>{renderUserComponent()}</>
        </>
    );
};

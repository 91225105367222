export interface FollowButtonProps {
    isFollowedBack?: boolean;
    loading?: boolean;
    handleFollow: () => void;
    handleUnfollow: () => void;
}

export enum FollowButtonTypes {
    FOLLOWING = 'Following',
    FOLLOW = 'Follow',
}

import { Reducer, AnyAction } from '@reduxjs/toolkit';

import { SelfListingActions } from 'store/actions';

import { ISelfListingState } from 'types/interfaces';
import { IListedOrganizationData } from 'types/interfaces/selfListing.interfaces';

const defaultState: ISelfListingState = {
    selfListingModalOpen: false,
    selfListingChainsList: [],
    selfListingPlatformsList: [],
    listOrganizationLoading: false,
    selfListingDataLoaded: false,
    listedOrganizationDataLoading: false,
    type: null,
    listedOrganizationData: {} as IListedOrganizationData,
    error: false,
};

export const selfListingReducer: Reducer<ISelfListingState, AnyAction> = (
    state: ISelfListingState = defaultState,
    action: AnyAction,
): ISelfListingState => {
    const { type, payload } = <AnyAction>action;

    switch (type) {
        case SelfListingActions.TOGGLE_SELF_LISTING_MODAL_OPEN: {
            return {
                ...state,
                selfListingModalOpen: !state.selfListingModalOpen,
                listedOrganizationData: {},
                ...payload,
            };
        }

        case SelfListingActions.GET_SELF_LISTING_DATA: {
            return {
                ...state,
            };
        }

        case SelfListingActions.GET_SELF_LISTING_DATA_SUCCESS: {
            return {
                ...state,
                ...payload,
                selfListingDataLoaded: true,
            };
        }

        case SelfListingActions.GET_SELF_LISTING_DATA_FAILURE: {
            return {
                ...state,
                selfListingDataLoaded: true,
            };
        }

        case SelfListingActions.LIST_ORGANIZATION: {
            return {
                ...state,
                listOrganizationLoading: true,
            };
        }

        case SelfListingActions.LIST_ORGANIZATION_SUCCESS: {
            return {
                ...state,
                listOrganizationLoading: false,
                type: null,
            };
        }

        case SelfListingActions.LIST_ORGANIZATION_FAILURE: {
            return {
                ...state,
                error: true,
                listOrganizationLoading: false,
            };
        }

        case SelfListingActions.GET_LISTED_ORGANIZATION_DATA: {
            return {
                ...state,
                listedOrganizationDataLoading: true,
            };
        }

        case SelfListingActions.GET_LISTED_ORGANIZATION_DATA_SUCCESS: {
            return {
                ...state,
                ...payload,
                listedOrganizationDataLoading: false,
            };
        }

        case SelfListingActions.GET_LISTED_ORGANIZATION_DATA_FAILURE: {
            return {
                ...state,
                listedOrganizationDataLoading: false,
            };
        }

        case SelfListingActions.CLEAR_SELF_LISTING_ERROR: {
            return {
                ...state,
                error: false,
            };
        }

        default: {
            return state;
        }
    }
};

import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

export const TableHeader = (): ReactElement => (
    <div className={styles.tableHeader}>
        <div className={styles.numberOfLoyalVoters}>Loyal Voters</div>
        <div className={styles.numberOfMissingVoters}>Missing Voters</div>
        <div className={styles.numberOfNonParticipatingTokenHolder}>Non participating token holders</div>
    </div>
);

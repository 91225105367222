import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { HrefLink } from 'components';

import { arrowIconGreen } from 'assets';
import { IDeepDaoMember, IDeepDaoMembersSectionProps } from 'types/interfaces';

export const DeepDaoMembersSection: FC<IDeepDaoMembersSectionProps> = ({
    data,
    className,
}: IDeepDaoMembersSectionProps): ReactElement => {
    const { origin } = window.location;

    return (
        <div className={classNames(styles.membersSection, className)}>
            {data.map(({ id, avatar, name, role, link }: IDeepDaoMember) => (
                <div key={id} className={styles.cardWrapper}>
                    <div className={styles.memberCard}>
                        <img src={avatar} className={styles.memberAvatar} alt="" />
                        <div className={styles.memberInfo}>
                            <p className={styles.name}>{name}</p>
                            <h6 className={styles.role}>{role}</h6>
                            <HrefLink href={link ? origin + link : undefined} className={styles.profileLink}>
                                <>
                                    <span className={styles.profile}>Profile</span>
                                    <img src={arrowIconGreen} className={styles.arrowIcon} alt="" />
                                </>
                            </HrefLink>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

import { createApiAction } from 'helpers';

export const GET_ALL_RESOURCES_DATA = 'GET_ALL_RESOURCES_DATA';
export const GET_ALL_RESOURCES_DATA_SUCCESS = 'GET_ALL_RESOURCES_DATA_SUCCESS';
export const GET_ALL_RESOURCES_DATA_FAILURE = 'GET_ALL_RESOURCES_DATA_FAILURE';

export const GET_SORTED_RESOURCES_DATA = 'GET_SORTED_RESOURCES_DATA';
export const GET_SORTED_RESOURCES_DATA_SUCCESS = 'GET_SORTED_RESOURCES_DATA_SUCCESS';
export const GET_SORTED_RESOURCES_DATA_FAILURE = 'GET_SORTED_RESOURCES_DATA_FAILURE';

export const CLEAR_RESOURCES_DATA = 'CLEAR_RESOURCES_DATA';

export const getAllResourcesData = createApiAction(GET_ALL_RESOURCES_DATA);
export const getSortedResourcesData = createApiAction(GET_SORTED_RESOURCES_DATA);

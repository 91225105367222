import React, { FC, ReactElement } from 'react';

import { ITableHeaderWithSortingProps } from 'types/interfaces';
import { Loader, TableDataNotFound } from 'components';

import { TableHeader, TableRow } from './components';
import { IOrganizationTableProps } from './constants';

import styles from './styles.module.scss';

export const OrganizationTable: FC<IOrganizationTableProps & ITableHeaderWithSortingProps> = ({
    loading,
    data,
    sortByParam,
    sortedParamName,
}): ReactElement => {
    return (
        <div className={styles.tableWrapper}>
            <div className={styles.tableContainer}>
                <TableHeader sortByParam={sortByParam} sortedParamName={sortedParamName} />
                {loading ? (
                    <Loader className={styles.loader} />
                ) : (
                    <div className={styles.rowsWrapper}>
                        {data?.map((item) => (
                            <TableRow key={item.organizationId} item={item} />
                        ))}
                    </div>
                )}
                {!loading && data.length === 0 && (
                    <TableDataNotFound forTable removeMargins overflowMarginsFirstVariant />
                )}
            </div>
        </div>
    );
};

import { createApiAction } from 'helpers';

export const GET_FEED_DATA = 'GET_FEED_DATA';
export const GET_FEED_DATA_SUCCESS = 'GET_FEED_DATA_SUCCESS';
export const GET_FEED_DATA_FAILURE = 'GET_FEED_DATA_FAILURE';

export const GET_FEED_ORGANIZATIONS_SEARCH = 'GET_FEED_ORGANIZATIONS_SEARCH';
export const GET_FEED_ORGANIZATIONS_SEARCH_SUCCESS = 'GET_FEED_ORGANIZATIONS_SEARCH_SUCCESS';
export const GET_FEED_ORGANIZATIONS_SEARCH_FAILURE = 'GET_FEED_ORGANIZATIONS_SEARCH_FAILURE';

export const GET_FEED_ITEMS = 'GET_FEED_ITEMS';
export const GET_FEED_ITEMS_SUCCESS = 'GET_FEED_ITEMS_SUCCESS';
export const GET_FEED_ITEMS_FAILURE = 'GET_FEED_ITEMS_FAILURE';

export const GET_FEED_STATS = 'GET_FEED_STATS';
export const GET_FEED_STATS_SUCCESS = 'GET_FEED_STATS_SUCCESS';
export const GET_FEED_STATS_FAILURE = 'GET_FEED_STATS_FAILURE';

export const GET_FEED_ACTIVITY = 'GET_FEED_ACTIVITY';
export const GET_FEED_ACTIVITY_SUCCESS = 'GET_FEED_ACTIVITY_SUCCESS';
export const GET_FEED_ACTIVITY_FAILURE = 'GET_FEED_ACTIVITY_FAILURE';

export const CLEAR_ALL_FEED_DATA = 'CLEAR_FEED_DATA';
export const CLEAR_FEED_ITEMS_DATA = 'CLEAR_FEED_ITEMS_DATA';

export const getFeedData = createApiAction(GET_FEED_DATA);
export const getFeedOrganizationsSearch = createApiAction(GET_FEED_ORGANIZATIONS_SEARCH);
export const getFeedItems = createApiAction(GET_FEED_ITEMS);
export const getFeedStats = createApiAction(GET_FEED_STATS);
export const getFeedActivity = createApiAction(GET_FEED_ACTIVITY);

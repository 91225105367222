import React, { FC, ReactElement, useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

import styles from './styles.module.scss';

import { TableCell, ImageLoader } from 'components';
import { MoreInfoTables } from './components';

import { IMAGE_TYPES } from 'constants/imageTypes';

import { FormatUtils, LinkUtils } from 'utils';
import { useAppSelector } from 'store';

import { DAO_EXPERIENCE_PARAMS } from '../../constants';
import { PROPOSAL_STATUS } from 'constants/statuses';

import { IDAOExperienceTableData } from 'types/interfaces';

export const TableRow: FC<IDAOExperienceTableData & { sortedParamName: string }> = ({
    organizationId,
    image,
    name,
    proposalsByUserAmount,
    votesByUserAmount,
    sortedParamName,
}: IDAOExperienceTableData & { sortedParamName: string }): ReactElement => {
    const { userProposalsData, userVotesData } = useAppSelector(({ user }) => user);

    const [expandMore, setExpandMore] = useState(false);
    const [successfulVotesAndProposals, setSuccessfulVotesAndProposals] = useState<{
        proposals: string | number;
        votes: string | number;
    }>({
        proposals: 0,
        votes: 0,
    });

    const organizationLogo = LinkUtils.getOrganizationLogo(image);
    const proposalsData = userProposalsData[organizationId];
    const votesData = userVotesData[organizationId];

    useEffect(() => {
        let successfulProposals = '0';
        let successfulVotes = '0';

        if (proposalsData && proposalsData.length) {
            successfulProposals = FormatUtils.formatNumberShort(
                (proposalsData.filter((proposal) => proposal.status == PROPOSAL_STATUS.ACCEPTED).length /
                    proposalsData.length) *
                    100,
                0,
            );
        }

        if (votesData && votesData.length) {
            successfulVotes = FormatUtils.formatNumberShort(
                (votesData.filter((vote) => vote.successful).length / votesData.length) * 100,
                0,
            );
        }

        setSuccessfulVotesAndProposals({
            proposals: successfulProposals,
            votes: successfulVotes,
        });
    }, [proposalsData, votesData]);

    return (
        <div className={styles.tableRow}>
            <div className={styles.mainRowInfo}>
                <TableCell
                    isHighlighted={sortedParamName === DAO_EXPERIENCE_PARAMS.USER_ORGANIZATION_PARAM}
                    className={styles.organizationContainer}
                >
                    <Button className={styles.expandButton} onClick={() => setExpandMore(!expandMore)}>
                        {expandMore ? <ArrowDropUp /> : <ArrowDropDown />}
                    </Button>
                    <Link to={LinkUtils.createDynamicLink(organizationId, 'organization')}>
                        <div className={styles.organization}>
                            <ImageLoader
                                src={organizationLogo}
                                className={styles.avatar}
                                imageType={IMAGE_TYPES.ORGANIZATION_IMAGE}
                            />
                            <div className={styles.organizationInfo}>
                                <span className={styles.daoName}>{name}</span>
                            </div>
                        </div>
                    </Link>
                </TableCell>
                {/* <div className={styles.votingPower}>
                    <span>{1}%</span>
                </div> */}
                <TableCell
                    className={styles.votes}
                    isHighlighted={sortedParamName === DAO_EXPERIENCE_PARAMS.USER_VOTES_PARAM}
                >
                    <span>{votesByUserAmount}</span>
                </TableCell>
                <div className={styles.successfulVotes}>{successfulVotesAndProposals.votes}%</div>
                <TableCell
                    className={styles.proposals}
                    isHighlighted={sortedParamName === DAO_EXPERIENCE_PARAMS.USER_PROPOSALS_PARAM}
                >
                    <span>{proposalsByUserAmount}</span>
                </TableCell>
                <div className={styles.successProposals}>{successfulVotesAndProposals.proposals}%</div>
            </div>
            {expandMore && <MoreInfoTables proposalsData={proposalsData} votesData={votesData} />}
        </div>
    );
};

import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { GraphCardHeader } from 'components';

import styles from './styles.module.scss';

import { GRAPH_TYPES } from 'constants/organizationsGraphNames';

import { IStatsGraphCard } from 'types/interfaces';

export const StatsGraphCard: FC<IStatsGraphCard> = ({
    children,
    title,
    titleComplement,
    graphType,
    className,
    titleFontIncreased = false,
    infoIconText,
}: IStatsGraphCard): ReactElement => {
    return (
        <div className={styles.statsGraphCard}>
            <GraphCardHeader
                title={title}
                titleComplement={titleComplement}
                titleFontIncreased={titleFontIncreased}
                infoIconText={infoIconText}
            />

            <div
                className={classNames(styles.content, className, {
                    [styles.lineGraphContent]: graphType === GRAPH_TYPES.LINE_GRAPH,
                    [styles.barGraphContent]: graphType === GRAPH_TYPES.BAR_GRAPH,
                })}
            >
                {children}
            </div>
        </div>
    );
};

import { mixpanelService } from 'services';

enum PDF_LINKS {
    TOS = 'https://uploads.deepdao.io/docs/DeepDAOTermsofUse.pdf',
    PRIVACY = 'https://uploads.deepdao.io/docs/DeepDAOPrivacyPolicy.pdf',
    API_TOS = 'https://uploads.deepdao.io/docs/DeepDao_API_Agreement_Sep_2023.pdf',
}

export const setPDFFileByUrl = (pathname: string): PDF_LINKS | null => {
    switch (pathname) {
        case '/tos':
            return PDF_LINKS.TOS;

        case '/privacy':
            return PDF_LINKS.PRIVACY;

        case '/api_tos':
            return PDF_LINKS.API_TOS;

        default:
            return null;
    }
};

export const setMixpanelEventByUrl = (pathname: string): string | null => {
    switch (pathname) {
        case '/tos':
            return mixpanelService.eventsGenerator.tosPage();

        case '/privacy':
            return mixpanelService.eventsGenerator.privacyPage();

        case '/api_tos':
            return mixpanelService.eventsGenerator.apiTos();

        default:
            return null;
    }
};

import React, { FC, ReactElement } from 'react';

import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FaceIcon from '@material-ui/icons/Face';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { DateFilterWrapper } from '../DateFilterWrapper';
import { MembersFilter } from '../MembersFilter';
import { SubstormsFilter } from '../SubstormsFilter';

import { dolphinDown, dolphinUp, diamondIcon } from 'assets';

import { IDiscussionFilters } from 'types/interfaces';

export const DesktopFilters: FC<IDiscussionFilters> = ({
    handleUpdateOrderFilter,
    filter,
    handleUpdateOrderByTotalVotersFilter,
    isLogged,
    handleUpdateOnlyMyFilter,
    onDateListClick,
    datesListOpen,
    dateRangePickerSelectedDates,
    handleUpdateFilterDates,
    handleOpenDateRangePicker,
    isDateRangePickerOpen,
    dateFilterBreakPoints,
    membersSearch,
    setMembersSearch,
    isInputOpen,
    discussionsFilterMembers,
    onInputClick,
    handleUpdateMembersFilter,
    handleUpdateOrderByNumberOfMembersFilter,
    handleUpdateOrderByNumberOfTokensFilter,
    getFormattedDate,
    substormsList,
    handleUpdateSubstormsFilter,
    setSubstormsListOpen,
    substormsListOpen,
}: IDiscussionFilters): ReactElement => (
    <div className={styles.filterWrapper}>
        <h2 className={styles.filterHeader}>
            <FilterListIcon className={styles.filterHeaderIcon} />
            <span>Sort and Filter</span>
        </h2>

        <div className={styles.orderFilterWrapper}>
            <div
                className={styles.orderFilter}
                onClick={() => handleUpdateOrderFilter(filter.order === 'DESC' ? 'ASC' : 'DESC')}
            >
                <ArrowForwardIcon
                    className={classNames(styles.arrowIcon, styles.orderDesc, {
                        [styles.orderAsc]: filter.order === 'ASC',
                    })}
                />
                <span className={classNames([{ [styles.bold]: filter.order === 'ASC' || filter.order === 'DESC' }])}>
                    {filter.order === 'ASC' ? 'Earliest' : 'Latest'}
                </span>
            </div>

            <div
                className={styles.orderFilter}
                onClick={() =>
                    handleUpdateOrderByTotalVotersFilter(filter.orderByTotalVoters === 'DESC' ? 'ASC' : 'DESC')
                }
            >
                <img
                    src={diamondIcon}
                    className={classNames(styles.diamondIcon, {
                        [styles.votersOrderAsc]: filter.orderByTotalVoters === 'ASC',
                    })}
                    alt=""
                />
                <span
                    className={classNames([
                        {
                            [styles.bold]: filter.orderByTotalVoters === 'ASC' || filter.orderByTotalVoters === 'DESC',
                        },
                    ])}
                >
                    {filter.orderByTotalVoters === 'ASC' ? 'Least' : 'Most'} active
                </span>
            </div>

            {isLogged && (
                <div className={styles.orderFilter} onClick={handleUpdateOnlyMyFilter}>
                    <FaceIcon className={styles.discussionsFilterIcon} />
                    <span className={classNames([{ [styles.elementSelected]: filter.isOnlyMyDiscussions }])}>
                        My Brainstorms
                    </span>
                </div>
            )}

            <SubstormsFilter
                substormsList={substormsList}
                handleUpdateSubstormsFilter={handleUpdateSubstormsFilter}
                filter={filter}
                substormsListOpen={substormsListOpen}
                setSubstormsListOpen={setSubstormsListOpen}
            />

            <MembersFilter
                membersSearch={membersSearch}
                setMembersSearch={setMembersSearch}
                isInputOpen={isInputOpen}
                onInputClick={onInputClick}
                discussionsFilterMembers={discussionsFilterMembers}
                handleUpdateMembersFilter={handleUpdateMembersFilter}
                filter={filter}
            />

            <DateFilterWrapper
                setDatesListOpen={onDateListClick}
                datesListOpen={datesListOpen}
                dateRangePickerSelectedDates={dateRangePickerSelectedDates}
                handleUpdateFilterDates={handleUpdateFilterDates}
                handleOpenDateRangePicker={handleOpenDateRangePicker}
                isDateRangePickerOpen={isDateRangePickerOpen}
                dateFilterBreakPoints={dateFilterBreakPoints}
                getFormattedDate={getFormattedDate}
            />
        </div>

        <div className={styles.orderByNumberOfMembersWrapper}>
            <div className={styles.orderByNumberOfMembersHeader}>
                <span>by members</span>
            </div>
            <div
                className={styles.orderByNumberOfMembersDesc}
                onClick={() => handleUpdateOrderByNumberOfMembersFilter('filterByNumberOfMembersSupport')}
            >
                <img src={dolphinUp} alt="dolphinUp" />
                <span
                    className={classNames({
                        [styles.elementSelected]: filter.filterByNumberOfMembersSupport,
                    })}
                >
                    Supported
                </span>
            </div>

            <div
                className={styles.orderByNumberOfMembersAsc}
                onClick={() => handleUpdateOrderByNumberOfMembersFilter('filterByNumberOfMembersAgainst')}
            >
                <img src={dolphinDown} alt="dolphinDown" />
                <span
                    className={classNames({
                        [styles.elementSelected]: filter.filterByNumberOfMembersAgainst,
                    })}
                >
                    Against
                </span>
            </div>
        </div>

        <div className={styles.orderByNumberOfTokensWrapper}>
            <div className={styles.orderByNumberOfMembersHeader}>
                <span>by tokens</span>
            </div>

            <div
                className={styles.orderByNumberOfTokensDesc}
                onClick={() => handleUpdateOrderByNumberOfTokensFilter('filterByTokenBalanceSupport')}
            >
                <img src={dolphinUp} alt="dolphinUp" />
                <span
                    className={classNames({
                        [styles.elementSelected]: filter.filterByTokenBalanceSupport,
                    })}
                >
                    Supported
                </span>
            </div>

            <div
                className={styles.orderByNumberOfTokensAsc}
                onClick={() => handleUpdateOrderByNumberOfTokensFilter('filterByTokenBalanceAgainst')}
            >
                <img src={dolphinDown} alt="dolphinDown" />
                <span
                    className={classNames({
                        [styles.elementSelected]: filter.filterByTokenBalanceAgainst,
                    })}
                >
                    Against
                </span>
            </div>
        </div>
    </div>
);

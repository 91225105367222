import { createApiAction } from 'helpers';

export const GET_ORGANIZATIONS_SEARCHED_DATA = 'GET_ORGANIZATIONS_SEARCHED_DATA';
export const GET_ORGANIZATIONS_SEARCHED_DATA_SUCCESS = 'GET_ORGANIZATIONS_SEARCHED_DATA_SUCCESS';
export const GET_ORGANIZATIONS_SEARCHED_DATA_FAILURE = 'GET_ORGANIZATIONS_SEARCHED_DATA_FAILURE';

export const GET_ORGANIZATIONS_CATEGORIES_DATA = 'GET_ORGANIZATIONS_CATEGORIES_DATA';
export const GET_ORGANIZATIONS_CATEGORIES_DATA_SUCCESS = 'GET_ORGANIZATIONS_CATEGORIES_DATA_SUCCESS';
export const GET_ORGANIZATIONS_CATEGORIES_DATA_FAILURE = 'GET_ORGANIZATIONS_CATEGORIES_DATA_FAILURE';

export const UPDATE_ORGANIZATIONS_SEARCHED_DATA_LIMIT = 'UPDATE_ORGANIZATIONS_SEARCHED_DATA_LIMIT';

export const getOrganizationsSearchedData = createApiAction(GET_ORGANIZATIONS_SEARCHED_DATA);
export const getOrganizationsCategoriesData = createApiAction(GET_ORGANIZATIONS_CATEGORIES_DATA);

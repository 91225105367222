import React, { FC, useState, useRef, useEffect } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { useOutsideClick } from 'hooks';

import { ITopicSelectProps } from 'types/interfaces';

const topicsList: string[] = ['General Feedback', 'Bug Report', 'Feature request'];

export const TopicSelect: FC<ITopicSelectProps> = ({ value, setSelectedValue }: ITopicSelectProps) => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [topicSelected, setTopicSelected] = useState(value);

    const ref = useRef(null);

    useOutsideClick(ref, () => setToggleMenu(false));

    const toggleMenuChange = () => {
        setToggleMenu(!toggleMenu);
    };

    useEffect(() => {
        setTopicSelected(value);
    }, [value]);

    const handleTopicSelect = (topic: string) => {
        setTopicSelected(topic);
        setSelectedValue('topic', topic);
        setToggleMenu(false);
    };

    return (
        <div className={styles.selectWrapper} ref={ref}>
            <div
                className={classNames(styles.select, { [styles.selectActive]: toggleMenu })}
                onClick={toggleMenuChange}
            >
                <span className={styles.selectTitle}>{topicSelected ? topicSelected : '- select topic -'}</span>
                <KeyboardArrowDownIcon className={styles.selectIcon} />
            </div>
            <div className={classNames(styles.selectItems, { [styles.fadeIn]: toggleMenu })}>
                {topicsList.map((item: string) => (
                    <div key={item} className={styles.menuItem} onClick={() => handleTopicSelect(item)}>
                        {item}
                    </div>
                ))}
            </div>
        </div>
    );
};

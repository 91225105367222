import { Reducer } from '@reduxjs/toolkit';

import { DaoTokensActions } from 'store/actions';

import { IDaoTokensState, IDaoTokensActionsType } from 'types/interfaces';
import { DAO_TOKENS_PARAMS } from '../../constants/daoTokensSorting';

const defaultState: IDaoTokensState = {
    error: undefined,
    loading: false,
    paginationLoading: false,
    daoTokens: [],
    nextDaoTokens: [],
    currentPage: 0,
    isEndOfDaoTokensList: false,
    orderBy: DAO_TOKENS_PARAMS.BALANCE_USD,
    paginationOrderDescending: true,
};

export const daoTokensReducer: Reducer<IDaoTokensState, any> = (
    state: IDaoTokensState = defaultState,
    action: IDaoTokensActionsType,
): IDaoTokensState => {
    const { type } = <IDaoTokensActionsType>action;

    switch (type) {
        case DaoTokensActions.GET_DAO_TOKENS: {
            return {
                ...state,
                loading: true,
            };
        }

        case DaoTokensActions.GET_DAO_TOKENS_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        }

        case DaoTokensActions.GET_DAO_TOKENS_FAILURE: {
            const { error } = action;

            return {
                ...state,
                error,
                loading: false,
            };
        }

        // case DaoTokensActions.GET_MORE_DAO_TOKENS: {
        //     return {
        //         ...state,
        //         paginationLoading: true,
        //         daoTokens: [...state.daoTokens, ...state.nextDaoTokens],
        //         nextDaoTokens: [],
        //     };
        // }
        //
        // case DaoTokensActions.GET_MORE_DAO_TOKENS_SUCCESS: {
        //     const { daoTokens, currentPage, orderBy, paginationOrderDescending } = action.payload as IDaoTokensResponse;
        //
        //     return {
        //         ...state,
        //         daoTokens,
        //         nextDaoTokens: [],
        //         currentPage,
        //         paginationLoading: false,
        //         loading: false,
        //         orderBy,
        //         paginationOrderDescending,
        //     };
        // }
        //
        // case DaoTokensActions.GET_MORE_DAO_TOKENS_FAILURE: {
        //     return {
        //         ...state,
        //         paginationLoading: false,
        //         loading: false,
        //     };
        // }

        case DaoTokensActions.CLEAR_DAO_TOKENS: {
            return {
                ...defaultState,
            };
        }

        default: {
            return state;
        }
    }
};

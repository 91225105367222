import { axiosInstance } from 'services';
import { AxiosResponse } from 'axios';

import { IEcosystemCategory, IResourceData } from 'types/interfaces';

export const ResourcesApi = {
    getResourcesData: (categoryId?: string): Promise<AxiosResponse<IResourceData[]>> =>
        axiosInstance.get<IResourceData[]>(`ecosystem-resource${categoryId ? '/sort?cat=' + categoryId : ''}`),
    getResourcesCategoriesData: (): Promise<AxiosResponse<IEcosystemCategory[]>> =>
        axiosInstance.get<IEcosystemCategory[]>('ecosystem-category'),
};

import { Reducer } from '@reduxjs/toolkit';

import { ApiActions } from 'store/actions';

import { IApiActions, IApiItems, IApiState } from 'types/interfaces';

const defaultState: IApiState = {
    apisList: [
        {
            id: 1,
            title: 'People Stats',
            description: '20 tokens / every 3 months',
            optionsList: ['Option1', 'option2', 'option3', 'option4', 'option5', 'option6'],
            relatedCheckboxId: 1,
            checkboxInfo: {
                checkboxId: 1,
                name: 'People',
                selected: true,
            },
        },
        {
            id: 2,
            title: 'People Stats',
            description: '20 tokens / every 3 months',
            optionsList: ['option1', 'option2', 'option3', 'option4', 'option5', 'option6'],
            relatedCheckboxId: 1,
        },
        {
            id: 3,
            title: 'People Stats',
            description: '20 tokens / every 3 months',
            optionsList: ['option1', 'option2', 'option3', 'option4', 'option5', 'option6'],
            relatedCheckboxId: 1,
        },
        {
            id: 4,
            title: 'People Stats',
            description: '20 tokens / every 3 months',
            optionsList: ['option1', 'option2', 'option3', 'option4', 'option5', 'option6'],
            relatedCheckboxId: 1,
        },
        {
            id: 5,
            title: 'People Stats',
            description: '20 tokens / every 3 months',
            optionsList: ['option1', 'option2', 'option3', 'option4', 'option5', 'option6'],
            relatedCheckboxId: 2,
            checkboxInfo: {
                checkboxId: 2,
                name: 'DAOs',
                selected: false,
            },
        },
        {
            id: 6,
            title: 'People Stats',
            description: '20 tokens / every 3 months',
            optionsList: ['option1', 'option2', 'option3', 'option4', 'option5', 'option6'],
            relatedCheckboxId: 2,
        },
        {
            id: 7,
            title: 'People Stats',
            description: '20 tokens / every 3 months',
            optionsList: ['option1', 'option2', 'option3', 'option4', 'option5', 'option6'],
            relatedCheckboxId: 3,
            checkboxInfo: {
                checkboxId: 3,
                name: 'DAO Tokens',
                selected: false,
            },
        },
        {
            id: 8,
            title: 'People Stats',
            description: '20 tokens / every 3 months',
            optionsList: ['Option1', 'option2', 'option3', 'option4', 'option5', 'option6'],
            relatedCheckboxId: 4,
            checkboxInfo: {
                checkboxId: 4,
                name: 'Ecosystem',
                selected: false,
            },
        },
    ],
    apiPlans: [
        {
            id: 1,
            title: 'Free Plan',
            tokensCount: 1122,
            tokensSubscriptionPeriod: '3 month',
            monthCalls: 100,
            description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. ',
            optionsList: ['option1', 'option2', 'option3', 'option4', 'option5', 'option6', 'option7'],
        },
        {
            id: 2,
            title: 'Free Plan',
            tokensCount: 9972,
            tokensSubscriptionPeriod: 'year',
            monthCalls: 213123,
            description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. ',
            optionsList: ['option1', 'option2', 'option3', 'option4', 'option5', 'option6', 'option7'],
        },
        {
            id: 3,
            title: 'Free Plan',
            tokensCount: 7562,
            tokensSubscriptionPeriod: '3 month',
            monthCalls: 123213,
            description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. ',
            optionsList: ['option1', 'option2', 'option3', 'option4', 'option5', 'option6', 'option7'],
        },
    ],
};

export const apiReducer: Reducer<IApiState, IApiActions> = (
    state: IApiState = defaultState,
    action: IApiActions,
): IApiState => {
    const { type, payload } = <IApiActions>action;

    switch (type) {
        case ApiActions.UPDATE_API_LIST: {
            return {
                ...state,
                ...(payload as { apisList: IApiItems[] }),
            };
        }
        default: {
            return state;
        }
    }
};

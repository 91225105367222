import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ILoaderProps } from 'types/interfaces';

export const DotsLoader: FC<ILoaderProps> = ({ className }: ILoaderProps): ReactElement => (
    <div className={classNames(styles.loaderWrapper, className)}>
        <span className={styles.loaderDot} />
        <span className={styles.loaderDot} />
        <span className={styles.loaderDot} />
    </div>
);

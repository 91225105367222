import { call, takeLatest, put } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { ALERT_TYPES, ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/alert';
import { PAYMENT_PROVIDERS } from 'constants/plansItems';

import { PremiumSubscription } from 'store/apis';
import { AlertActions, PremiumSubscriptionActions } from 'store/actions';
import {
    getPremiumSubscriptionIdFromStorage,
    setPremiumSubscriptionIdInStorage,
    setPremiumUserEmailInStorage,
    setPremiumUserRefreshTokenInStorage,
    setPremiumUserTokenInStorage,
    removePremiumSubscriptionIdFromStorage,
} from 'web3/storageHelper';

import {
    IGetSubscriptionAction,
    IPaypalSubscribeAction,
    IBluesnapSubscribeAction,
    IGetApiKeyWorker,
    IPremiumEmailLoginWorker,
    IPremiumOTPLoginWorker,
    IPremiumEmailUpdateWorker,
} from 'types/interfaces';

function* getPaypalSubscriptionWorker({ payload }: IGetSubscriptionAction): SagaIterator {
    try {
        const { data, hasError } = yield call(PremiumSubscription.getPaypalSubscription, payload.id);

        if (hasError) yield put(PremiumSubscriptionActions.getPaypalSubscription.failure());
        else yield put(PremiumSubscriptionActions.getPaypalSubscription.success(data));
    } catch (e) {
        yield put(PremiumSubscriptionActions.getPaypalSubscription.failure(e));
    }
}

function* getBluesnapSubscriptionWorker({ payload }: IGetSubscriptionAction): SagaIterator {
    try {
        const { data, hasError } = yield call(PremiumSubscription.getBluesnapSubscription, payload.id);

        if (hasError) yield put(PremiumSubscriptionActions.getBluesnapSubscription.failure());
        else yield put(PremiumSubscriptionActions.getBluesnapSubscription.success(data));
    } catch (e) {
        yield put(PremiumSubscriptionActions.getBluesnapSubscription.failure(e));
    }
}

function* paypalSubscribeWorker({ payload }: IPaypalSubscribeAction): SagaIterator {
    try {
        const { data: responseData, hasError, error } = yield call(PremiumSubscription.paypalSubscribe, payload.data);

        if (hasError) {
            yield put(
                PremiumSubscriptionActions.paypalSubscribe.failure(
                    error?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ),
            );
        } else {
            removePremiumSubscriptionIdFromStorage();
            responseData.id && setPremiumSubscriptionIdInStorage(responseData.id);
            yield put(PremiumSubscriptionActions.paypalSubscribe.success());
            const [redirectLink] = responseData.links;
            window.location.replace(redirectLink.href);
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.paypalSubscribe.failure(e));
    }
}

function* bluesnapSubscribeWorker({ payload }: IBluesnapSubscribeAction) {
    try {
        const { hasError, error, data, headers } = yield call(PremiumSubscription.bluesnapSubscribe, payload.data);

        if (hasError) {
            yield put(
                PremiumSubscriptionActions.bluesnapSubscribe.failure(
                    error?.message?.length > 0
                        ? Array.isArray(error.message)
                            ? error?.message.map(
                                  ({ description }: { description: string }) =>
                                      description || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                              )
                            : [error.message]
                        : [error?.message?.description || ERROR_MESSAGES.SOMETHING_WENT_WRONG],
                ),
            );

            yield call(getPremiumPFTokenWorker, {
                type: PremiumSubscriptionActions.GET_PREMIUM_PF_TOKEN,
            });
        } else {
            data.subscriptionId && setPremiumSubscriptionIdInStorage(data.subscriptionId);
            setPremiumUserTokenInStorage(headers['access-token']);
            setPremiumUserRefreshTokenInStorage(headers['refresh-token']);
            yield put(PremiumSubscriptionActions.bluesnapSubscribe.success());
            payload.navigate();
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.bluesnapSubscribe.failure(e));
    }
}

function* getApiKeyWorker({ payload }: IGetApiKeyWorker): SagaIterator {
    try {
        const { data, hasError, error } = yield call(PremiumSubscription.getApiKey);

        if (hasError) {
            if (error.statusCode === 404) {
                const { data: apiKeyData, hasError: apiKeyHasError } = yield call(
                    PremiumSubscription.createApiKey,
                    payload.subscriptionId,
                );

                if (apiKeyHasError) yield put(PremiumSubscriptionActions.getApiKey.failure());
                else {
                    yield put(PremiumSubscriptionActions.getApiKey.success(apiKeyData));
                }
            }
            {
                yield put(PremiumSubscriptionActions.getApiKey.failure());
            }
        } else {
            yield put(PremiumSubscriptionActions.getApiKey.success(data));
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.getApiKey.failure(e));
    }
}

function* premiumEmailLoginWorker({ payload }: IPremiumEmailLoginWorker): SagaIterator {
    try {
        const { hasError, error } = yield call(PremiumSubscription.premiumEmailLogin, payload.email);

        if (hasError)
            yield put(
                PremiumSubscriptionActions.premiumEmailLogin.failure(
                    error?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ),
            );
        else {
            setPremiumUserEmailInStorage(payload.email);
            yield put(PremiumSubscriptionActions.premiumEmailLogin.success());
            if (payload.navigate) payload.navigate();
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.premiumEmailLogin.failure());
    }
}

function* premiumOTPLoginWorker({ payload }: IPremiumOTPLoginWorker): SagaIterator {
    try {
        const { hasError, error, data } = yield call(
            PremiumSubscription.premiumOTPLogin,
            payload.email,
            payload.otpCode,
        );

        if (hasError)
            yield put(
                PremiumSubscriptionActions.premiumOTPLogin.failure(
                    error?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ),
            );
        else {
            setPremiumUserTokenInStorage(data.jwt);
            setPremiumUserRefreshTokenInStorage(data.refreshToken);
            removePremiumSubscriptionIdFromStorage();

            yield put(
                PremiumSubscriptionActions.premiumOTPLogin.success({
                    premiumAccessToken: data.jwt,
                    premiumRefreshToken: data.refreshToken,
                }),
            );
            payload.navigate();
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.premiumOTPLogin.failure());
    }
}

function* premiumEmailUpdateWorker({ payload }: IPremiumEmailUpdateWorker): SagaIterator {
    try {
        const { hasError, error, data } = yield call(PremiumSubscription.premiumEmailUpdate, payload.newEmail);

        if (hasError)
            yield put(
                PremiumSubscriptionActions.premiumEmailUpdate.failure(
                    error?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ),
            );
        else {
            setPremiumUserEmailInStorage(payload.newEmail);
            setPremiumUserTokenInStorage(data.jwt);
            setPremiumUserRefreshTokenInStorage(data.refreshToken);

            yield put({
                type: AlertActions.SHOW_ALERT,
                payload: {
                    title: SUCCESS_MESSAGES.EMAIL_SUCCESSFULLY_UPDATED,
                    type: ALERT_TYPES.SUCCESS,
                },
            });

            yield put(
                PremiumSubscriptionActions.premiumEmailUpdate.success({
                    newEmail: payload.newEmail,
                    premiumAccessToken: data.jwt,
                    premiumRefreshToken: data.refreshToken,
                }),
            );
            payload.navigate();
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.premiumEmailUpdate.failure());
    }
}

function* getPremiumUserInvoicesWorker() {
    try {
        const { data, hasError } = yield call(PremiumSubscription.getPremiumUserInvoices);

        if (hasError) yield put(PremiumSubscriptionActions.getPremiumUserInvoices.failure());
        else yield put(PremiumSubscriptionActions.getPremiumUserInvoices.success(data));
    } catch (e) {
        yield put(PremiumSubscriptionActions.getPremiumUserInvoices.failure(e));
    }
}

function* getPremiumUserDataWorker() {
    const subscriptionId = getPremiumSubscriptionIdFromStorage();

    try {
        const { data, hasError } = yield call(PremiumSubscription.getPremiumUserData);

        if (hasError) yield put(PremiumSubscriptionActions.getPremiumUserData.failure({ premiumUserData: {} }));
        else {
            !subscriptionId && data.subscriptionId && setPremiumSubscriptionIdInStorage(data.subscriptionId);
            yield put(PremiumSubscriptionActions.getPremiumUserData.success({ premiumUserData: data }));
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.getPremiumUserData.failure(e));
    }
}

function* cancelPremiumSubscriptionWorker({ payload }: any) {
    try {
        const { hasError, error } = yield call(
            payload.paymentProvider === PAYMENT_PROVIDERS.BLUESNAP
                ? PremiumSubscription.cancelPremiumBluesnapSubscription
                : PremiumSubscription.cancelPremiumPaypalSubscription,
            payload.subscriptionId,
            {
                autoRenew: false,
            },
        );

        if (hasError)
            yield put(
                PremiumSubscriptionActions.cancelPremiumSubscription.failure(
                    error?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ),
            );
        else {
            const { data } = yield call(PremiumSubscription.getPremiumUserData);

            yield put({
                type: AlertActions.SHOW_ALERT,
                payload: {
                    title: SUCCESS_MESSAGES.SUBSCRIPTION_CANCELED,
                    type: ALERT_TYPES.SUCCESS,
                },
            });

            yield put(PremiumSubscriptionActions.cancelPremiumSubscription.success({ premiumUserData: data }));
            payload.navigate();
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.cancelPremiumSubscription.failure(e));
    }
}

function* getPremiumPFTokenWorker(data: any) {
    try {
        const { data, hasError } = yield call(PremiumSubscription.getPremiumPFToken);

        if (hasError) yield put(PremiumSubscriptionActions.getPremiumPFToken.failure());
        else yield put(PremiumSubscriptionActions.getPremiumPFToken.success({ premiumPFToken: data.pfToken }));
    } catch (e) {
        yield put(PremiumSubscriptionActions.getPremiumPFToken.failure(e));
    }
}

function* premiumCreditCardUpdateWorker({ payload }: any): SagaIterator {
    try {
        const { error, hasError, data } = yield call(
            PremiumSubscription.premiumCreditCardUpdate,
            payload.subscriptionId,
            payload.premiumPFToken,
        );

        if (hasError)
            yield put(
                PremiumSubscriptionActions.premiumCreditCardUpdate.failure([
                    error?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ]),
            );
        else {
            yield put({
                type: AlertActions.SHOW_ALERT,
                payload: {
                    title: SUCCESS_MESSAGES.CREDIT_CARD_SUCCESSFULLY_UPDATED,
                    type: ALERT_TYPES.SUCCESS,
                },
            });

            yield put(
                PremiumSubscriptionActions.premiumCreditCardUpdate.success({
                    cardLastFourDigits: data.paymentSource.creditCardInfo.creditCard.cardLastFourDigits,
                }),
            );
            payload.navigate();
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.premiumCreditCardUpdate.failure(e));
    }
}

function* bluesnapSubscriptionUpdateWorker({ payload }: any) {
    try {
        const { hasError: hasCancelBluesnapSubscriptionError, error: cancelBluesnapSubscriptionError } = yield call(
            payload.paymentProvider === PAYMENT_PROVIDERS.BLUESNAP
                ? PremiumSubscription.cancelPremiumBluesnapSubscription
                : PremiumSubscription.cancelPremiumPaypalSubscription,
            payload.subscriptionId,
            {
                status: 'CANCELED',
            },
        );

        if (hasCancelBluesnapSubscriptionError)
            yield put(
                PremiumSubscriptionActions.bluesnapSubscriptionUpdate.failure([
                    cancelBluesnapSubscriptionError?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ]),
            );
        else {
            yield call(bluesnapSubscribeWorker, {
                type: PremiumSubscriptionActions.BLUESNAP_SUBSCRIBE,
                payload,
            });
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.bluesnapSubscriptionUpdate.failure(e));
    }
}

function* paypalSubscriptionUpdateWorker({ payload }: any) {
    try {
        const { hasError: hasCancelPaypalSubscriptionError, error: cancelPaypalSubscriptionError } = yield call(
            payload.paymentProvider === PAYMENT_PROVIDERS.PAYPAL
                ? PremiumSubscription.cancelPremiumPaypalSubscription
                : PremiumSubscription.cancelPremiumBluesnapSubscription,
            payload.subscriptionId,
            {
                status: 'CANCELED',
            },
        );

        if (hasCancelPaypalSubscriptionError)
            yield put(
                PremiumSubscriptionActions.paypalSubscriptionUpdate.failure(
                    cancelPaypalSubscriptionError?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ),
            );
        else {
            yield call(paypalSubscribeWorker, {
                type: PremiumSubscriptionActions.PAYPAL_SUBSCRIBE,
                payload,
            });
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.paypalSubscriptionUpdate.failure(e));
    }
}

export const PremiumSubscriptionsSaga = function* (): SagaIterator {
    yield takeLatest(PremiumSubscriptionActions.PAYPAL_SUBSCRIBE, paypalSubscribeWorker);
    yield takeLatest(PremiumSubscriptionActions.GET_PAYPAL_SUBSCRIPTION, getPaypalSubscriptionWorker);
    yield takeLatest(PremiumSubscriptionActions.GET_BLUESNAP_SUBSCRIPTION, getBluesnapSubscriptionWorker);
    yield takeLatest(PremiumSubscriptionActions.GET_API_KEY, getApiKeyWorker);
    yield takeLatest(PremiumSubscriptionActions.BLUESNAP_SUBSCRIBE, bluesnapSubscribeWorker);
    yield takeLatest(PremiumSubscriptionActions.PREMIUM_EMAIL_LOGIN, premiumEmailLoginWorker);
    yield takeLatest(PremiumSubscriptionActions.PREMIUM_OTP_LOGIN, premiumOTPLoginWorker);
    yield takeLatest(PremiumSubscriptionActions.PREMIUM_EMAIL_UPDATE, premiumEmailUpdateWorker);
    yield takeLatest(PremiumSubscriptionActions.GET_PREMIUM_USER_INVOICES, getPremiumUserInvoicesWorker);
    yield takeLatest(PremiumSubscriptionActions.GET_PREMIUM_USER_DATA, getPremiumUserDataWorker);
    yield takeLatest(PremiumSubscriptionActions.CANCEL_PREMIUM_SUBSCRIPTION, cancelPremiumSubscriptionWorker);
    yield takeLatest(PremiumSubscriptionActions.GET_PREMIUM_PF_TOKEN, getPremiumPFTokenWorker);
    yield takeLatest(PremiumSubscriptionActions.PREMIUM_CREDIT_CARD_UPDATE, premiumCreditCardUpdateWorker);
    yield takeLatest(PremiumSubscriptionActions.BLUESNAP_SUBSCRIPTION_UPDATE, bluesnapSubscriptionUpdateWorker);
    yield takeLatest(PremiumSubscriptionActions.PAYPAL_SUBSCRIPTION_UPDATE, paypalSubscriptionUpdateWorker);
};

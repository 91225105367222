import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { SliderBlock } from './components';

import { supported } from 'pages/AboutPage/constants';

export const AboutEndlessSlider: FC = (): ReactElement => (
    <div className={styles.sliderWrapper}>
        <SliderBlock data={[...supported.thirdSection, ...supported.fourthSection, ...supported.fifthSection]} />
        <SliderBlock data={[...supported.thirdSection, ...supported.fourthSection, ...supported.fifthSection]} />
    </div>
);

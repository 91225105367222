interface IAuthoriaztionHeaders {
    [key: string]: string;
}

export const createAuthorizationHeaders = (
    deepDAOToken: string | null,
    premiumUserToken: string | null,
): IAuthoriaztionHeaders => {
    const data = {} as IAuthoriaztionHeaders;

    if (deepDAOToken) {
        data.wallet_authorization = `Bearer ${deepDAOToken}`;
    }
    if (premiumUserToken) {
        data.password_authorization = `Bearer ${premiumUserToken}`;
    }

    data['x-api-key'] = 'YN9xJRmtxn88knalayQab3QQwKf5EjpJ85rov27s';

    return data;
};

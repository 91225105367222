import { createContext, Dispatch } from 'react';
import { noop } from 'lodash';

interface IHelmetContext {
    metaTags: JSX.Element[];
    setMetaTags: Dispatch<any>;
}

export const HelmetContext = createContext<IHelmetContext>({
    metaTags: [],
    setMetaTags: noop,
});

import React, { FC, ReactElement, useCallback } from 'react';

import { StylesViaJss } from 'substyle-jss';

import { Mention, MentionsInput } from 'react-mentions';

import classNames from 'classnames';

import styles from './styles.module.scss';
import Style from './styles.module.css';

import { getDeepDAOTokenFromStorage } from 'web3/storageHelper';

import { axiosInstance } from 'services';
import { FormatUtils } from 'utils';

import { IDiscussionCommentTextareaProps } from 'types/interfaces';

export const DiscussionCommentTextarea: FC<IDiscussionCommentTextareaProps> = ({
    createComment,
    placeholder,
    value,
    setValue,
    onFocus,
    name = '',
    className,
    brainstormId,
}: IDiscussionCommentTextareaProps): ReactElement => {
    const onKeyDown = useCallback(
        (event) => {
            if (createComment) {
                if (event.key === 'Enter' && !event.shiftKey) {
                    if (!event.shiftKey && value.trim()) {
                        createComment(value);
                        setValue('');
                    }
                    if (!event.shiftKey) {
                        event.preventDefault();
                    }
                }
            }
        },
        [createComment, value],
    );

    const getUsersList = useCallback(async (query: string, callback: any) => {
        const data = await axiosInstance.get(
            `social-features/tagged-data?data=${query}${brainstormId ? `&brainstormId=${brainstormId}` : ''}`,
            {
                headers: { Authorization: `Bearer ${getDeepDAOTokenFromStorage()}` },
            },
        );

        callback(
            data.data.map(({ name, idxName, ensName, address }: any) => ({
                id: address,
                display: name || idxName || ensName || FormatUtils.formatUserAddress(address),
            })),
        );
    }, []);

    return (
        <StylesViaJss>
            <div className={classNames(styles.wrapper, className)}>
                <div className={styles.container}>
                    <MentionsInput
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                        placeholder={placeholder}
                        allowSuggestionsAboveCursor={true}
                        onKeyDown={onKeyDown}
                        classNames={Style}
                        onFocus={onFocus}
                        name={name}
                    >
                        <Mention
                            data={getUsersList}
                            trigger="@"
                            markup="@[__display__,__id__]"
                            className={Style.mentions__mention}
                        />
                    </MentionsInput>
                </div>
            </div>
        </StylesViaJss>
    );
};

import React, { FC, ReactElement, memo } from 'react';

import { DateRangePicker } from 'materialui-daterange-picker';
import DateRangeIcon from '@material-ui/icons/DateRange';

import classNames from 'classnames';

import moment, { Moment } from 'moment';

import styles from './styles.module.scss';

import { DesktopFilterSelectedElement } from '../DesktopFilterSelectedElement';

import { IDateFilterBreakPoint, IDateFilterWrapperProps } from 'types/interfaces';

export const DateFilterWrapper: FC<IDateFilterWrapperProps> = memo(
    ({
        setDatesListOpen,
        datesListOpen,
        dateRangePickerSelectedDates,
        handleUpdateFilterDates,
        handleOpenDateRangePicker,
        isDateRangePickerOpen,
        dateFilterBreakPoints,
        getFormattedDate,
    }: IDateFilterWrapperProps): ReactElement => (
        <div className={styles.dateFilterWrapper}>
            <div className={styles.dateFilterHeader} onClick={() => setDatesListOpen(!datesListOpen)}>
                <DateRangeIcon className={styles.dateFilterHeaderIcon} />
                <span className={classNames([{ [styles.bold]: datesListOpen }])}>Dates</span>
            </div>

            {!datesListOpen && dateRangePickerSelectedDates.label && (
                <div className={styles.selectedDateWrapper}>
                    <DesktopFilterSelectedElement
                        text={
                            dateRangePickerSelectedDates.label === 'Custom'
                                ? `${getFormattedDate(
                                      dateRangePickerSelectedDates.from as Moment,
                                      'MM.DD.YY',
                                  )} - ${getFormattedDate(dateRangePickerSelectedDates.to as Moment, 'MM.DD.YY')}`
                                : dateRangePickerSelectedDates.label
                        }
                        onRemove={() => handleUpdateFilterDates()}
                    />
                </div>
            )}

            {datesListOpen && (
                <>
                    <div className={styles.dateFilterOptions}>
                        {dateFilterBreakPoints.map(({ label, from, to }: IDateFilterBreakPoint) => (
                            <span
                                className={classNames(styles.cursorPointer, styles.dateFilterOption, {
                                    [styles.bold]: label === dateRangePickerSelectedDates.label,
                                })}
                                key={label}
                                onClick={() => handleUpdateFilterDates(label, from, to)}
                            >
                                {label}
                            </span>
                        ))}
                    </div>

                    <div className={styles.rangeWrapper}>
                        <div className={styles.from} onClick={handleOpenDateRangePicker}>
                            <h5>From</h5>
                            <span>
                                {dateRangePickerSelectedDates.from
                                    ? dateRangePickerSelectedDates.label === 'Custom'
                                        ? getFormattedDate(dateRangePickerSelectedDates.from, 'MM/DD/YY')
                                        : 'mm/dd/yy'
                                    : 'mm/dd/yy'}
                            </span>
                        </div>

                        <div className={styles.to} onClick={handleOpenDateRangePicker}>
                            <h5>To</h5>
                            <span>
                                <span>
                                    {dateRangePickerSelectedDates.to
                                        ? dateRangePickerSelectedDates.label === 'Custom'
                                            ? getFormattedDate(dateRangePickerSelectedDates.to, 'MM/DD/YY')
                                            : 'mm/dd/yy'
                                        : 'mm/dd/yy'}
                                </span>
                                <div style={{ position: 'absolute' }} onClick={(e) => e.stopPropagation()}>
                                    <DateRangePicker
                                        open={isDateRangePickerOpen}
                                        toggle={handleOpenDateRangePicker}
                                        onChange={({ startDate, endDate }) =>
                                            handleUpdateFilterDates(
                                                'Custom',
                                                moment(startDate).startOf('day'),
                                                moment(endDate).endOf('day'),
                                            )
                                        }
                                    />
                                </div>
                            </span>
                        </div>
                    </div>
                </>
            )}
        </div>
    ),
);

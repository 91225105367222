import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

export const TableHeader: FC = (): ReactElement => (
    <div className={styles.tableHeader}>
        <div className={styles.number}>#</div>
        <div className={styles.proposalName}>proposal name</div>
        <div className={styles.date}>date</div>
        <div className={styles.voted}>voter choice</div>
        <div className={styles.wonVote}>won</div>
    </div>
);

import React, { FC, ReactElement, memo } from 'react';

import styles from './styles.module.scss';

import { FormatUtils } from 'utils';

import { IPremiumUserInvoiceData } from 'types/interfaces';

export const TableRow: FC<IPremiumUserInvoiceData> = memo(
    ({ planName, paymentProvider, planPeriod, paymentDate, invoiceUrl }: IPremiumUserInvoiceData): ReactElement => {
        return (
            <div className={styles.tableRow}>
                <div>
                    <span className={styles.boldText}>{FormatUtils.getFormattedDate(paymentDate)}</span>
                </div>
                <div>
                    <span className={styles.textUppercase}>{planName}</span>
                </div>
                <div>
                    <span className={styles.textUppercase}>{planPeriod}</span>
                </div>
                <div>
                    <span className={styles.textUppercase}>{paymentProvider}</span>
                </div>
                <div>
                    <a href={invoiceUrl} className={styles.download} rel="noreferrer">
                        download
                    </a>
                </div>
            </div>
        );
    },
);

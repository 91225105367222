import React, { ComponentType, FC, ReactElement, useEffect } from 'react';

import { Route } from 'react-router-dom';

import { withHeader } from 'hoc';

import type { IRouterSwitchProps } from 'types/interfaces';

export const RouterRoute: FC<IRouterSwitchProps> = ({
    path,
    exact,
    component,
    disableHeader,
    backgroundColor,
    alwaysShowFooter,
}: IRouterSwitchProps): ReactElement => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    });

    return (
        <Route
            path={path}
            exact={exact}
            component={withHeader(component, disableHeader, backgroundColor, alwaysShowFooter) as ComponentType}
        />
    );
};

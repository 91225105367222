import { RefObject, useEffect, useCallback } from 'react';
import { IAnonymousFunction } from 'types/interfaces';

export const useOutsideClick = <T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: IAnonymousFunction,
): void => {
    const clickListener = useCallback(
        (event: MouseEvent) => {
            if (!ref.current?.contains(event.target as Node)) {
                handler();
            }
        },
        [ref.current],
    );

    useEffect(() => {
        document.addEventListener('click', clickListener);

        return () => {
            document.removeEventListener('click', clickListener);
        };
    }, []);
};

import React, { FC, ReactElement, useContext } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

import { ADDITIONAL_ORGANIZATION_TABS, BASE_ORGANIZATION_TABS } from 'constants/organizationTabs';

import { OrganizationContext } from 'context/organizationContext';
import { useAppSelector } from '../../../../../../store';
import { LinkUtils } from '../../../../../../utils';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';

export const TabsController: FC = (): ReactElement => {
    const { organizationDataTabSelected, changeOrganizationDataTab } = useContext(OrganizationContext);

    const { organizationTabs } = useAppSelector(({ organizationTabs }) => organizationTabs);
    const {
        organizationData: { id: organizationId },
    } = useAppSelector(({ organization }) => organization);

    const proTabsName = organizationTabs.data.map((ot) => ot.tab.name.toLowerCase());
    const prodTabs = ADDITIONAL_ORGANIZATION_TABS.filter(({ tabName }) => proTabsName.includes(tabName.toLowerCase()));
    const baseTabs = BASE_ORGANIZATION_TABS.filter(({ tabName }) => !proTabsName.includes(tabName.toLowerCase()));
    const tabs = [...baseTabs, ...prodTabs].sort((a, b) => a.order - b.order);

    const isMobile = useMediaQuery('(max-width:575.98px)');
    const isTablet = useMediaQuery('(max-width:767.98px)');
    const tabsCount = isMobile ? 2 : isTablet ? 4 : tabs.length === 5 ? 5 : 6;

    const tabWidth = `calc(100% / ${tabsCount})`;

    return (
        <div className={styles.tabsWrapper}>
            <div className={styles.tabsContainer}>
                {tabs.map(({ tabName, title, premium }) => (
                    <div
                        onClick={() => changeOrganizationDataTab(tabName)}
                        className={classNames(styles.tabItem, {
                            [styles.activeTab]: tabName === organizationDataTabSelected,
                        })}
                        style={{ width: tabWidth }}
                        key={tabName}
                    >
                        <div className={styles.itemContent}>
                            <Link to={LinkUtils.createDynamicLink(organizationId, 'organization-tab', tabName)}>
                                <span>{title}</span>
                            </Link>
                            {premium ? (
                                <div className={styles.premium}>
                                    <span className={styles.text}>PRO</span>
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

import React, { FC, ReactElement, memo } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { ImageLoader } from 'components';

import { ISearchAutocompleteItemsProps, ISearchAutocompleteItems } from 'types/interfaces';

export const SearchAutocompleteItems: FC<ISearchAutocompleteItemsProps> = memo(
    ({
        showTopResults = false,
        data,
        fieldName,
        imageType,
        showMaxFive = false,
    }: ISearchAutocompleteItemsProps): ReactElement => (
        <div
            className={classNames(styles.container, {
                [styles.containerOverflow]: showMaxFive,
            })}
        >
            <h4 className={styles.searchElementName}>
                {showTopResults ? 'top' : ''} {fieldName}
            </h4>
            {data.map(({ id, name, logo, onClick }: ISearchAutocompleteItems) => (
                <div className={styles.info} key={id} onClick={onClick}>
                    <div className={styles.mainInfo}>
                        <ImageLoader className={styles.avatar} src={logo} imageType={imageType} />
                        <h5 className={styles.name}>{name}</h5>
                    </div>
                </div>
            ))}
        </div>
    ),
);

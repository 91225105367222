import { discordIcon, twitterIcon, mediumIcon } from 'assets';

import { IMainLink } from 'types/interfaces';

export const DEEP_DAO_TWITTER_LINK = 'https://twitter.com/DeepDAO_io/';
export const DEEP_DAO_DISCORD_LINK = 'https://discord.com/invite/BQBEUS9V9N';
export const DEEP_DAO_MEDIUM_LINK = 'https://medium.com/deep-dao';

const deepDaoSocialLinks: IMainLink[] = [
    {
        id: 1,
        logo: twitterIcon,
        link: DEEP_DAO_TWITTER_LINK,
    },
    {
        id: 2,
        logo: discordIcon,
        link: DEEP_DAO_DISCORD_LINK,
    },
    {
        id: 3,
        logo: mediumIcon,
        link: DEEP_DAO_MEDIUM_LINK,
    },
];

const DEEP_DAO_MAIL = 'mailto:info@deepdao.io';

export { deepDaoSocialLinks, DEEP_DAO_MAIL };

import React, { FC, ReactElement, useState, useRef, ChangeEvent } from 'react';

import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { TooltipWrapper } from 'components';
import { OrganizationsAutocomplete } from './components';

import { useOutsideClick } from 'hooks';

import { IFeedSearchFilterProps, IFeedSelectedItem } from 'types/interfaces';

export const FeedSearchFilter: FC<IFeedSearchFilterProps> = ({
    inputValue,
    onInputChange,
    selectedItems,
    organizationSelect,
    removeSelectedOrganization,
}: IFeedSearchFilterProps): ReactElement => {
    const [inputActive, setInputActive] = useState<boolean>(false);

    const inputRef = useRef(null);

    useOutsideClick(inputRef, () => setInputActive(false));

    return (
        <div className={styles.filterSection}>
            <div className={styles.filterBox}>
                <div className={styles.inputContainer} onFocus={() => setInputActive(true)} ref={inputRef}>
                    <input
                        className={styles.input}
                        placeholder="Filter by organization"
                        value={inputValue}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => onInputChange(event.target.value)}
                    />
                    <SearchIcon
                        className={classNames(styles.searchIcon, {
                            [styles.searchIconActive]: inputActive,
                        })}
                    />

                    {inputValue.length > 0 && (
                        <CloseIcon className={styles.clearSearchIcon} onClick={() => onInputChange('')} />
                    )}

                    <OrganizationsAutocomplete
                        organizationSelect={organizationSelect}
                        selectedItems={selectedItems}
                        removeSelectedOrganization={removeSelectedOrganization}
                        inputActive={inputActive}
                    />
                </div>
            </div>

            <div className={styles.selectedOrganizationsContainer}>
                {selectedItems.map(({ id, name }: IFeedSelectedItem) => {
                    return (
                        <div key={id} className={styles.selectedOrganization}>
                            <TooltipWrapper title={name}>
                                <span className={styles.organizationName}>{name}</span>
                            </TooltipWrapper>
                            <Button className={styles.removeButton} onClick={() => removeSelectedOrganization(id)}>
                                <CloseIcon className={styles.closeIcon} />
                            </Button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

import React, { ChangeEvent, FC, ReactElement, memo } from 'react';

import styles from './styles.module.scss';
import { ISearchedOrganizationFilter, ISearchedOrganizationFilterQueryData } from 'types/interfaces';

interface IInputsFilterProps {
    filtersData: ISearchedOrganizationFilter[];
    addNewFilter: (filterData: ISearchedOrganizationFilter) => void;
    removeSelectedFilter: (filterId: string) => void;
    selectedFiltersData: ISearchedOrganizationFilter[];
}

export const InputsFilter: FC<IInputsFilterProps> = memo(
    ({ filtersData, addNewFilter, removeSelectedFilter, selectedFiltersData }: IInputsFilterProps): ReactElement => {
        const onChange = (
            e: ChangeEvent<HTMLInputElement>,
            filterData: ISearchedOrganizationFilter,
            fieldKey: string,
        ) => {
            const inputValue = e.target.value;

            inputValue
                ? addNewFilter({
                      ...filterData,
                      title: inputValue,
                      queryData: filterData.queryData.map((query: ISearchedOrganizationFilterQueryData) =>
                          query.key === fieldKey ? { ...query, value: inputValue } : query,
                      ),
                  })
                : removeSelectedFilter(filterData.id);
        };

        return (
            <div className={styles.inputsWrapper}>
                {filtersData.map((filterData: ISearchedOrganizationFilter) =>
                    filterData.queryData.map((queryData: ISearchedOrganizationFilterQueryData) => {
                        const inputValue = selectedFiltersData
                            .find(({ id }: ISearchedOrganizationFilter) => id === filterData.id)
                            ?.queryData.find(({ key }) => key === queryData.key)?.value;

                        return (
                            <input
                                key={queryData.key}
                                value={inputValue || ''}
                                onChange={(e) => onChange(e, filterData, queryData.key)}
                                className={styles.input}
                                autoFocus
                            />
                        );
                    }),
                )}
            </div>
        );
    },
);
